import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useTheme } from '../context/ThemeContext';
import { CheckIcon } from '@heroicons/react/24/outline';
import logo from '../logo.svg';
import { TokenStorage } from '../utils/tokenStorage';

const LinkedInAuthSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isDarkMode } = useTheme();
  const isSignup = location.state?.isSignup ?? false;

  useEffect(() => {
    // Get tokens from URL or state
    const params = new URLSearchParams(window.location.search);
    const linkedinTokens = location.state?.linkedinTokens;

    if (linkedinTokens) {
      // Save tokens securely
      TokenStorage.saveTokens({
        access_token: linkedinTokens.access_token,
        refresh_token: linkedinTokens.refresh_token,
        expires_at: linkedinTokens.expires_at
      });
    }

    const timer = setTimeout(() => {
      navigate('/chat', { replace: true });
    }, 2000);

    return () => clearTimeout(timer);
  }, [navigate, location]);

  return (
    <div className={`fixed inset-0 z-50 ${
      isDarkMode ? 'bg-[#121212]' : 'bg-white'
    }`}>
      <div className="h-full flex items-center justify-center">
        <div className="flex flex-col items-center space-y-8">
          {/* Logo */}
          <motion.img
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="h-12 w-auto"
            src={logo}
            alt="Ghosts Agency"
          />
          
          {/* Success Icon */}
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ type: "spring", stiffness: 200, damping: 20 }}
            className="relative"
          >
            <div className={`w-16 h-16 rounded-full ${
              isDarkMode ? 'bg-green-500' : 'bg-green-600'
            } flex items-center justify-center`}>
              <CheckIcon className="w-8 h-8 text-white" />
            </div>
          </motion.div>

          {/* Message */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.3 }}
            className="text-center"
          >
            <h2 className={`text-xl font-medium mb-1 ${
              isDarkMode ? 'text-white' : 'text-slate-900'
            }`}>
              {isSignup ? 'Successfully Signed Up' : 'Successfully Logged In'}
            </h2>
            <p className={`text-sm ${
              isDarkMode ? 'text-slate-400' : 'text-slate-600'
            }`}>
              Redirecting...
            </p>
          </motion.div>

          {/* Progress Bar */}
          <motion.div 
            className="w-32 h-0.5 overflow-hidden bg-slate-200 rounded-full"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            <motion.div
              className={`h-full ${isDarkMode ? 'bg-green-500' : 'bg-green-600'}`}
              initial={{ width: "0%" }}
              animate={{ width: "100%" }}
              transition={{ duration: 2, ease: "linear" }}
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default LinkedInAuthSuccess; 