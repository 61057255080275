import React, { useState, forwardRef, useRef } from 'react';
import { PlusIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { createPortal } from 'react-dom';
import { motion, AnimatePresence } from 'framer-motion';

const IdeasTagMenu = forwardRef(({ isDarkMode, tags, allTags, onAddTag, onRemoveTag, anchorEl, onClose }, ref) => {
  const [inputValue, setInputValue] = useState('');
  const menuRef = useRef(null);

  const filteredAllTags = allTags.filter(tag => 
    tag.toLowerCase().includes(inputValue.toLowerCase()) && !tags.includes(tag)
  );

  const handleAddTag = (tagToAdd) => {
    const trimmedTag = tagToAdd.trim();
    if (trimmedTag && !tags.includes(trimmedTag)) {
      onAddTag(trimmedTag);
      setInputValue('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddTag(inputValue);
    } else if (e.key === 'Escape') {
      onClose();
    }
  };

  if (!anchorEl) return null;

  return createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Background Overlay */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="absolute inset-0 bg-black/50 backdrop-blur-[2px]"
        onClick={onClose}
      />

      {/* Menu */}
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        transition={{ type: "spring", stiffness: 500, damping: 30 }}
        ref={menuRef}
        className={`
          relative w-[280px] rounded-xl overflow-hidden
          shadow-xl border backdrop-blur-sm
          ${isDarkMode 
            ? 'bg-[#1a1a1a]/95 text-slate-200 border-[#333333]' 
            : 'bg-white/95 text-gray-800 border-gray-200'
          }
        `}
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="p-4 flex flex-col">
          {/* Header with close button */}
          <div className="flex items-center justify-between mb-4">
            <h3 className={`
              text-sm font-medium
              ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}
            `}>
              Manage Tags
            </h3>
            <button
              onClick={onClose}
              className={`
                p-1 rounded-lg transition-colors duration-200
                ${isDarkMode
                  ? 'text-slate-400 hover:bg-[#202020] hover:text-slate-300'
                  : 'text-gray-400 hover:bg-gray-100 hover:text-gray-600'}
              `}
              aria-label="Close menu"
            >
              <XMarkIcon className="w-4 h-4" />
            </button>
          </div>
          
          {/* Search and Add Input */}
          <div className="relative mb-4">
            <div className={`
              flex items-center w-full rounded-lg
              ${isDarkMode 
                ? 'bg-[#151515] border-[#333333]' 
                : 'bg-gray-50 border-gray-200'
              }
              border
            `}>
              <MagnifyingGlassIcon 
                className={`
                  w-3.5 h-3.5 ml-2.5
                  ${isDarkMode ? 'text-slate-500' : 'text-gray-400'}
                `}
              />
              <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Search or add new tag"
                className={`
                  w-full px-2 py-1.5 text-sm bg-transparent
                  ${isDarkMode 
                    ? 'text-slate-200 placeholder-slate-500' 
                    : 'text-gray-900 placeholder-gray-400'
                  }
                  focus:outline-none
                `}
              />
              <button
                onClick={() => handleAddTag(inputValue)}
                className={`
                  p-1.5 mr-1 rounded transition-colors duration-200
                  ${!inputValue.trim()
                    ? 'opacity-30 cursor-not-allowed'
                    : isDarkMode 
                      ? 'text-blue-400 hover:text-blue-300' 
                      : 'text-blue-500 hover:text-blue-600'
                  }
                `}
                disabled={!inputValue.trim()}
              >
                <PlusIcon className="w-3.5 h-3.5" />
              </button>
            </div>
          </div>

          {/* Current Tags Section */}
          <div className="mb-4">
            <h4 className={`
              text-xs font-medium mb-2
              ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}
            `}>
              Current Tags
            </h4>
            <div className="flex flex-wrap gap-1.5">
              {tags.map((tag) => (
                <span
                  key={tag}
                  className={`
                    group px-2 py-1 rounded-md text-xs flex items-center
                    ${isDarkMode 
                      ? 'bg-[#202020] text-slate-300' 
                      : 'bg-gray-100 text-gray-700'}
                  `}
                >
                  <span>{tag}</span>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      onRemoveTag(tag);
                    }}
                    className={`
                      ml-1.5 p-0.5 rounded-full opacity-60 hover:opacity-100
                      transition-opacity duration-200
                    `}
                  >
                    <XMarkIcon className="w-3 h-3" />
                  </button>
                </span>
              ))}
            </div>
          </div>

          {/* Available Tags Section */}
          <div>
            <h4 className={`
              text-xs font-medium mb-2
              ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}
            `}>
              Available Tags
            </h4>
            <div className="max-h-32 overflow-y-auto">
              {filteredAllTags.map((tag) => (
                <button
                  key={tag}
                  onClick={() => handleAddTag(tag)}
                  className={`
                    w-full text-left px-2 py-1.5 text-sm rounded-md
                    transition-colors duration-200 mb-0.5
                    ${isDarkMode
                      ? 'hover:bg-[#202020] text-slate-300'
                      : 'hover:bg-gray-100 text-gray-700'}
                  `}
                >
                  {tag}
                </button>
              ))}
            </div>
          </div>
        </div>
      </motion.div>
    </div>,
    document.body
  );
});

IdeasTagMenu.isClickInsideMenu = (element, menuRef) => {
  if (!menuRef.current) return false;
  return menuRef.current === element || menuRef.current.contains(element);
};

export default IdeasTagMenu;
