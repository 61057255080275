import React, { useState, useContext, useEffect } from 'react';
import { EnvelopeIcon, CameraIcon, ArrowRightOnRectangleIcon, PencilSquareIcon, CheckIcon, XMarkIcon, ChevronRightIcon, EyeIcon, EyeSlashIcon, ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import { AuthContext } from '../context/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { uploadProfilePicture, updateUserProfile } from '../services/userService';
import { disconnectLinkedIn } from '../services/linkedinService';
import api from '../api';
import { Spinner } from './Spinner'; // Updated import
import { useTheme } from '../context/ThemeContext';
import { Dialog } from '@headlessui/react'
import './settings-scrollbar.css';
import toast from 'react-hot-toast';
import { getLinkedInAuthUrl } from '../services/linkedinAuthService';
import supabase from '../services/supabaseAuth';
import { motion } from 'framer-motion';

const AVATAR_CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
const DEFAULT_AVATAR = 'https://ghostscompany.s3.amazonaws.com/userprofile-placeholder.png';

// Add new styles for animations
const fadeInAnimation = `
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
  }
`;

// Update the LogoutConfirmationModal with minimal design
const LogoutConfirmationModal = ({ isOpen, onClose, onConfirm, isDarkMode }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/20 backdrop-blur-sm z-50">
      <div className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[320px] 
        ${isDarkMode ? 'bg-black/90' : 'bg-white/90'} 
        backdrop-blur-xl rounded-2xl p-6 transform transition-all duration-300`}>
        <div className="space-y-4">
          <h3 className={`text-base font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            Sign out of your account?
          </h3>
          <div className="space-y-2">
            <button
              onClick={onConfirm}
              className="w-full py-2 text-sm font-medium text-white bg-red-500/90 
                rounded-full transition-all duration-200 hover:bg-red-500"
            >
              Sign Out
            </button>
            <button
              onClick={onClose}
              className={`w-full py-2 text-sm font-medium rounded-full transition-all duration-200
                ${isDarkMode 
                  ? 'bg-white/10 text-white hover:bg-white/20' 
                  : 'bg-black/5 text-black hover:bg-black/10'}`}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const SuccessModal = ({ isOpen, onClose, message, isDarkMode }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/20 backdrop-blur-sm z-50 flex items-center justify-center">
      <div className={`w-[320px] ${isDarkMode ? 'bg-black/90' : 'bg-white/90'} 
        backdrop-blur-xl rounded-2xl p-6 transform transition-all duration-300 
        animate-[fadeIn_0.2s_ease-out]`}>
        <div className="flex flex-col items-center space-y-4">
          {/* Success Icon with animated ring */}
          <div className="relative">
            <div className={`absolute inset-0 rounded-full 
              ${isDarkMode ? 'bg-green-500/20' : 'bg-green-100'} 
              animate-[ping_1s_cubic-bezier(0,0,0.2,1)_1]`} 
            />
            <div className={`w-14 h-14 rounded-full flex items-center justify-center
              ${isDarkMode ? 'bg-green-500/20' : 'bg-green-100'}`}>
              <CheckIcon className={`w-7 h-7 
                ${isDarkMode ? 'text-green-400' : 'text-green-600'}`} />
            </div>
          </div>
          
          {/* Message */}
          <div className="space-y-1 text-center">
            <h3 className={`text-base font-semibold
              ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              Success!
            </h3>
            <p className={`text-sm
              ${isDarkMode ? 'text-white/60' : 'text-gray-600'}`}>
              {message}
            </p>
          </div>
          
          {/* Done Button */}
          <button
            onClick={onClose}
            className={`w-full py-2 text-sm font-medium rounded-full transition-all duration-200
              ${isDarkMode 
                ? 'bg-green-500/20 text-green-400 hover:bg-green-500/30' 
                : 'bg-green-600 text-white hover:bg-green-700'}`}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

const PasswordStrengthIndicator = ({ password, isDarkMode }) => {
  const requirements = [
    { regex: /.{8,}/, text: "At least 8 characters" },
    { regex: /[A-Z]/, text: "Uppercase letter" },
    { regex: /[a-z]/, text: "Lowercase letter" },
    { regex: /[0-9]/, text: "Number" },
    { regex: /[!@#$%^&*]/, text: "Special character" },
  ];

  if (!password) return null;

  return (
    <div className="mt-2 space-y-2">
      <div className={`text-xs font-medium ${isDarkMode ? 'text-white/60' : 'text-black/60'}`}>
        Password strength:
      </div>
      {requirements.map((req, index) => (
        <div key={index} className="flex items-center text-xs">
          {req.regex.test(password) ? (
            <CheckIcon className="h-3 w-3 text-green-500 mr-2" />
          ) : (
            <XMarkIcon className="h-3 w-3 text-red-500 mr-2" />
          )}
          <span className={isDarkMode ? 'text-white/50' : 'text-black/50'}>
            {req.text}
          </span>
        </div>
      ))}
    </div>
  );
};

const PasswordSection = ({ isDarkMode, onSuccess }) => {
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const [showPasswords, setShowPasswords] = useState({
    currentPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  });
  const [error, setError] = useState('');

  const validatePassword = (password) => {
    if (password.length < 8) return "Password must be at least 8 characters long";
    if (!/[A-Z]/.test(password)) return "Password must contain at least one uppercase letter";
    if (!/[a-z]/.test(password)) return "Password must contain at least one lowercase letter";
    if (!/[0-9]/.test(password)) return "Password must contain at least one number";
    if (!/[!@#$%^&*]/.test(password)) return "Password must contain at least one special character";
    return null;
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData(prev => ({ ...prev, [name]: value }));
    setError('');
  };

  const togglePasswordVisibility = (field) => {
    setShowPasswords(prev => ({ ...prev, [field]: !prev[field] }));
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    setError('');

    // Validate new password
    const passwordError = validatePassword(passwordData.newPassword);
    if (passwordError) {
      setError(passwordError);
      return;
    }

    if (passwordData.newPassword !== passwordData.confirmNewPassword) {
      setError("New passwords don't match");
      return;
    }

    try {
      const response = await api.put('/api/user/change-password', {
        currentPassword: passwordData.currentPassword,
        newPassword: passwordData.newPassword,
      });

      // Clear form
      setPasswordData({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      });
      setShowPasswords({
        currentPassword: false,
        newPassword: false,
        confirmNewPassword: false,
      });

      // Call onSuccess with message
      onSuccess("Password updated successfully");
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Error changing password';
      setError(errorMessage);
    }
  };

  return (
    <form onSubmit={handlePasswordSubmit} className="space-y-4">
      {error && (
        <div className={`rounded-lg p-2 text-sm ${
          isDarkMode ? 'bg-red-500/20 text-red-400' : 'bg-red-50 text-red-500'
        }`}>
          {error}
        </div>
      )}
      
      {/* Password Fields */}
      {[
        { name: 'currentPassword', label: 'Current Password' },
        { name: 'newPassword', label: 'New Password' },
        { name: 'confirmNewPassword', label: 'Confirm New Password' }
      ].map(({ name, label }) => (
        <div key={name} className="space-y-1">
          <label 
            htmlFor={name}
            className={`block text-xs font-medium ${
              isDarkMode ? 'text-white/60' : 'text-black/60'
            }`}
          >
            {label}
          </label>
          <div className="relative">
            <input
              type={showPasswords[name] ? "text" : "password"}
              id={name}
              name={name}
              value={passwordData[name]}
              onChange={handlePasswordChange}
              className={`w-full px-3 py-2 text-sm rounded-lg transition-colors
                ${isDarkMode 
                  ? 'bg-white/10 text-white focus:bg-white/20' 
                  : 'bg-black/5 text-black focus:bg-black/10'}
                border-0 focus:ring-1 focus:ring-blue-500/30`}
              required
            />
            <button
              type="button"
              onClick={() => togglePasswordVisibility(name)}
              className={`absolute right-2 top-1/2 -translate-y-1/2 p-1 rounded-md
                ${isDarkMode ? 'hover:bg-white/10' : 'hover:bg-black/5'}`}
            >
              {showPasswords[name] ? (
                <EyeIcon className="w-4 h-4 opacity-60" />
              ) : (
                <EyeSlashIcon className="w-4 h-4 opacity-60" />
              )}
            </button>
          </div>
        </div>
      ))}

      {/* Password Strength Indicator */}
      {passwordData.newPassword && (
        <PasswordStrengthIndicator 
          password={passwordData.newPassword} 
          isDarkMode={isDarkMode}
        />
      )}

      {/* Submit Button */}
      <button
        type="submit"
        className={`w-full mt-4 py-2 text-sm font-medium rounded-lg transition-all duration-200
          ${isDarkMode 
            ? 'bg-white text-black hover:bg-white/90' 
            : 'bg-black text-white hover:bg-black/90'}`}
      >
        Update Password
      </button>
    </form>
  );
};

const UploadingAvatar = ({ isDarkMode }) => {
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={`absolute inset-0 flex items-center justify-center rounded-full 
        ${isDarkMode 
          ? 'bg-black/60 backdrop-blur-sm' 
          : 'bg-white/60 backdrop-blur-sm'}`}
    >
      <div className="relative">
        {/* Outer rotating ring */}
        <motion.div
          animate={{ 
            rotate: 360,
            scale: [1, 1.1, 1]
          }}
          transition={{ 
            rotate: { duration: 1.5, repeat: Infinity, ease: "linear" },
            scale: { duration: 1, repeat: Infinity }
          }}
          className={`absolute -inset-1 rounded-full border-2 
            ${isDarkMode 
              ? 'border-white/20' 
              : 'border-black/20'}`}
        />
        
        {/* Inner spinning circle */}
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
          className={`w-6 h-6 rounded-full border-2 border-t-transparent
            ${isDarkMode 
              ? 'border-white/60' 
              : 'border-black/60'}`}
        />
        
        {/* Pulsing dot in center */}
        <motion.div
          animate={{ 
            scale: [1, 1.2, 1],
            opacity: [0.5, 1, 0.5]
          }}
          transition={{ 
            duration: 1,
            repeat: Infinity,
            ease: "easeInOut"
          }}
          className={`absolute top-1/2 left-1/2 w-1.5 h-1.5 -ml-[3px] -mt-[3px] rounded-full
            ${isDarkMode 
              ? 'bg-white' 
              : 'bg-black'}`}
        />
      </div>
    </motion.div>
  );
};

const Settings = () => {
  const { user, logout, setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [activeSection, setActiveSection] = useState(() => {
    // Check if we should open LinkedIn section
    if (location.state?.openLinkedIn) {
      return 'linkedin';
    }
    return 'profile';
  });

  // Scroll to LinkedIn section if openLinkedIn is true
  useEffect(() => {
    if (location.state?.openLinkedIn) {
      const linkedInSection = document.getElementById('linkedin-section');
      if (linkedInSection) {
        linkedInSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.state?.openLinkedIn]);

  const [isEditing, setIsEditing] = useState(false);
  const [editedUser, setEditedUser] = useState({
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    about: user?.about || '',
    avatar: user?.avatar || '',
  });
  const [avatarUrl, setAvatarUrl] = useState('');
  const [isAvatarLoading, setIsAvatarLoading] = useState(true);
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [isAboutExpanded, setIsAboutExpanded] = useState(false);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [successModal, setSuccessModal] = useState({ show: false, message: '' });
  const [isDisconnectingLinkedIn, setIsDisconnectingLinkedIn] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isUploadingAvatar, setIsUploadingAvatar] = useState(false);

  const { isDarkMode } = useTheme();

  useEffect(() => {
    const fetchUserAvatar = async () => {
      setIsAvatarLoading(true);
      if (user && user.avatar) {
        // Check if the avatar is a full URL (LinkedIn profile picture)
        if (user.avatar.startsWith('http')) {
          setAvatarUrl(user.avatar);
          setIsAvatarLoading(false);
          return;
        }

        const cachedAvatar = localStorage.getItem(`user_avatar_${user.id}`);
        if (cachedAvatar) {
          const { url, timestamp } = JSON.parse(cachedAvatar);
          if (Date.now() - timestamp < AVATAR_CACHE_DURATION) {
            setAvatarUrl(url);
            setIsAvatarLoading(false);
            return;
          }
        }

        try {
          const response = await api.get(`/api/user/avatar-url`);
          const signedUrl = response.data.signedUrl;
          setAvatarUrl(signedUrl);
          localStorage.setItem(`user_avatar_${user.id}`, JSON.stringify({
            url: signedUrl,
            timestamp: Date.now()
          }));
        } catch (error) {
          console.error('Error fetching user avatar URL:', error);
          setAvatarUrl(DEFAULT_AVATAR);
        }
      } else {
        setAvatarUrl(DEFAULT_AVATAR);
      }
      setIsAvatarLoading(false);
    };

    fetchUserAvatar();
  }, [user]);

  useEffect(() => {
    const checkEmailVerification = async () => {
      try {
        console.log('Checking email verification status...');
        const isVerified = user?.emailVerified || false;
        console.log('Is email verified:', isVerified);
        setIsEmailVerified(isVerified);
      } catch (error) {
        console.error('Error checking email verification:', error);
        toast.error('Failed to check email verification status');
      }
    };

    if (user?.email) {
      checkEmailVerification();
    }
  }, [user]); // Re-run when user changes

  const handleEdit = () => setIsEditing(true);
  const handleCancel = () => {
    setIsEditing(false);
    setEditedUser({
      firstName: user.firstName,
      lastName: user.lastName,
      about: user.about,
      avatar: user.avatar,
    });
  };

  const handleSave = async () => {
    try {
      const updatedUser = await updateUserProfile(editedUser);
      setUser({ ...user, ...updatedUser });
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating profile:', error.message);
      // You might want to show an error message to the user here
      // For example: setErrorMessage(error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedUser(prev => ({ ...prev, [name]: value }));
  };

  const handleAvatarUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check file size (500KB limit)
      const fileSizeKB = file.size / 1024;
      if (fileSizeKB > 500) {
        toast.error('File size exceeds 500KB. Please choose a smaller file.');
        return;
      }

      const formData = new FormData();
      formData.append('avatar', file);
      
      setIsUploadingAvatar(true);
      
      try {
        // Show loading toast
        const loadingToast = toast.loading('Uploading profile picture...');
        
        const response = await uploadProfilePicture(formData);
        setUser({ ...user, avatar: response.avatarKey });
        setAvatarUrl(response.signedUrl);
        
        // Update the cached avatar URL
        localStorage.setItem(`user_avatar_${user.id}`, JSON.stringify({
          url: response.signedUrl,
          timestamp: Date.now()
        }));
        
        // Update loading toast to success
        toast.success('Profile picture updated successfully', {
          id: loadingToast,
        });
        
      } catch (error) {
        console.error('Error uploading avatar:', error);
        toast.error(error.response?.data?.message || 'Failed to upload profile picture');
      } finally {
        setIsUploadingAvatar(false);
      }
    }
  };

  const handleLogout = () => {
    setShowLogoutConfirmation(true);
  };

  const [isEditingAbout, setIsEditingAbout] = useState(false);

  const handleEditAbout = () => setIsEditingAbout(true);
  const handleCancelAbout = () => {
    setIsEditingAbout(false);
    setEditedUser(prev => ({
      ...prev,
      about: user.about
    }));
  };
  const handleSaveAbout = async () => {
    try {
      const updatedUser = await updateUserProfile({ about: editedUser.about });
      setUser({ ...user, ...updatedUser });
      setIsEditingAbout(false);
    } catch (error) {
      console.error('Error updating about:', error.message);
    }
  };

  const handleDisconnectLinkedIn = async () => {
    try {
      setIsDisconnectingLinkedIn(true);
      await disconnectLinkedIn();
      setUser({ ...user, linkedinId: undefined });
      toast.success('LinkedIn account disconnected successfully');
    } catch (error) {
      console.error('Error disconnecting LinkedIn:', error);
      toast.error('Failed to disconnect LinkedIn account');
    } finally {
      setIsDisconnectingLinkedIn(false);
    }
  };

  return (
    <div className={`h-full flex flex-col ${isDarkMode ? 'bg-black' : 'bg-white'}`}>
      {/* Minimal Header - Height 10 (40px) */}
      <div className={`h-10 flex items-center px-4 backdrop-blur-xl border-b 
        ${isDarkMode 
          ? 'bg-black/90 border-white/10' 
          : 'bg-white/90 border-black/5'}`}>
        <h2 className={`text-sm font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
          Settings
        </h2>
        <button 
          onClick={() => setShowLogoutConfirmation(true)}
          className={`ml-auto p-1.5 rounded-full transition-all duration-200
            ${isDarkMode 
              ? 'hover:bg-white/10' 
              : 'hover:bg-black/5'}`}
        >
          <ArrowRightOnRectangleIcon className="w-4 h-4" />
        </button>
      </div>

      {/* Content Area */}
      <div className="flex-grow overflow-y-auto settings-scroll-area">
        <div className="max-w-2xl mx-auto p-4 space-y-4">
          {/* Profile Section */}
          <div className={`p-4 rounded-2xl border relative group
            ${isDarkMode ? 'border-white/10' : 'border-black/5'}`}>
            <div className="flex items-center space-x-4">
              {/* Avatar */}
              <div className="relative">
                {isAvatarLoading ? (
                  <div className="w-16 h-16 rounded-full bg-gray-100 flex items-center justify-center">
                    <Spinner />
                  </div>
                ) : (
                  <div className="relative">
                    <img
                      src={avatarUrl || DEFAULT_AVATAR}
                      alt="Profile"
                      className="w-16 h-16 rounded-full object-cover"
                    />
                    {isEditing && (
                      <>
                        <label 
                          htmlFor="avatar-upload" 
                          className={`absolute bottom-0 right-0 p-1.5 rounded-full transition-colors
                            ${isUploadingAvatar 
                              ? isDarkMode
                                ? 'bg-white/10 cursor-not-allowed' 
                                : 'bg-black/10 cursor-not-allowed'
                              : isDarkMode
                                ? 'bg-blue-500/90 cursor-pointer hover:bg-blue-500' 
                                : 'bg-blue-500 cursor-pointer hover:bg-blue-600'}`}
                        >
                          {isUploadingAvatar ? (
                            <motion.div
                              animate={{ rotate: 360 }}
                              transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                              className={`w-3 h-3 border-2 border-t-transparent rounded-full
                                ${isDarkMode ? 'border-white/60' : 'border-white'}`}
                            />
                          ) : (
                            <CameraIcon className="w-3 h-3 text-white" />
                          )}
                          <input
                            id="avatar-upload"
                            type="file"
                            accept="image/*"
                            className="hidden"
                            onChange={handleAvatarUpload}
                            disabled={isUploadingAvatar}
                          />
                        </label>
                        {isUploadingAvatar && <UploadingAvatar isDarkMode={isDarkMode} />}
                      </>
                    )}
                  </div>
                )}
              </div>

              {/* Profile Info */}
              <div className="flex-grow min-w-0">
                {isEditing ? (
                  <div className="space-y-2">
                    <input
                      type="text"
                      name="firstName"
                      value={editedUser.firstName}
                      onChange={handleChange}
                      className={`w-full text-sm px-2 py-1 rounded-lg transition-all duration-200
                        ${isDarkMode 
                          ? 'bg-white/[0.06] text-white focus:bg-white/[0.07] placeholder-white/30' 
                          : 'bg-white text-black focus:bg-white placeholder-black/30'}
                        border ${isDarkMode 
                          ? 'border-white/10 focus:border-white/[0.15]' 
                          : 'border-black/[0.06] focus:border-black/[0.09]'}
                        focus:ring-0 focus:outline-none`}
                      placeholder="First Name"
                    />
                    <input
                      type="text"
                      name="lastName"
                      value={editedUser.lastName}
                      onChange={handleChange}
                      className={`w-full text-sm px-2 py-1 rounded-lg transition-all duration-200
                        ${isDarkMode 
                          ? 'bg-white/[0.06] text-white focus:bg-white/[0.07] placeholder-white/30' 
                          : 'bg-white text-black focus:bg-white placeholder-black/30'}
                        border ${isDarkMode 
                          ? 'border-white/10 focus:border-white/[0.15]' 
                          : 'border-black/[0.06] focus:border-black/[0.09]'}
                        focus:ring-0 focus:outline-none`}
                      placeholder="Last Name"
                    />
                  </div>
                ) : (
                  <>
                    <h3 className={`text-sm font-medium truncate 
                      ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                      {`${editedUser.firstName} ${editedUser.lastName}`}
                    </h3>
                    <div className={`flex items-center space-x-1 mt-0.5
                      ${isDarkMode ? 'text-white/60' : 'text-black/60'}`}>
                      <EnvelopeIcon className="w-3 h-3" />
                      <span className="text-xs truncate">{user.email}</span>
                      {isEmailVerified && (
                        <div className="flex items-center">
                          <CheckIcon className="w-3 h-3 text-green-500 ml-1" />
                          <span className="text-xs text-green-500 ml-0.5">Verified</span>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>

              {/* Edit Button */}
              <div className="flex items-center space-x-2">
                {isEditing ? (
                  <>
                    <button
                      onClick={handleSave}
                      className={`p-1.5 rounded-full transition-colors
                        ${isDarkMode 
                          ? 'bg-white/10 hover:bg-white/15' 
                          : 'bg-black/5 hover:bg-black/10'}`}
                    >
                      <CheckIcon className={`w-4 h-4 ${isDarkMode ? 'text-white' : 'text-black'}`} />
                    </button>
                    <button
                      onClick={handleCancel}
                      className={`p-1.5 rounded-full transition-colors
                        ${isDarkMode 
                          ? 'bg-white/10 hover:bg-white/15' 
                          : 'bg-black/5 hover:bg-black/10'}`}
                    >
                      <XMarkIcon className={`w-4 h-4 ${isDarkMode ? 'text-white' : 'text-black'}`} />
                    </button>
                  </>
                ) : (
                  <button
                    onClick={handleEdit}
                    className={`p-1.5 rounded-full transition-opacity duration-200 opacity-0 group-hover:opacity-100
                      ${isDarkMode 
                        ? 'hover:bg-white/10' 
                        : 'hover:bg-black/5'}`}
                  >
                    <PencilSquareIcon className={`w-3.5 h-3.5 
                      ${isDarkMode ? 'text-white/60' : 'text-black/60'}`} />
                  </button>
                )}
              </div>
            </div>
          </div>

          {/* About Section with Minimal Design */}
          <div className={`p-4 rounded-2xl border relative group
            ${isDarkMode ? 'border-white/10' : 'border-black/5'}`}>
            <div className="flex justify-between items-center mb-3">
              <h3 className={`text-xs font-medium tracking-wide uppercase
                ${isDarkMode ? 'text-white/60' : 'text-black/60'}`}>
                About
              </h3>
              <div className="flex items-center space-x-2">
                {isEditingAbout ? (
                  <>
                    <button
                      onClick={handleSaveAbout}
                      className={`p-1.5 rounded-full transition-colors
                        ${isDarkMode 
                          ? 'bg-white/10 hover:bg-white/15' 
                          : 'bg-black/5 hover:bg-black/10'}`}
                    >
                      <CheckIcon className={`w-4 h-4 ${isDarkMode ? 'text-white' : 'text-black'}`} />
                    </button>
                    <button
                      onClick={handleCancelAbout}
                      className={`p-1.5 rounded-full transition-colors
                        ${isDarkMode 
                          ? 'bg-white/10 hover:bg-white/15' 
                          : 'bg-black/5 hover:bg-black/10'}`}
                    >
                      <XMarkIcon className={`w-4 h-4 ${isDarkMode ? 'text-white' : 'text-black'}`} />
                    </button>
                  </>
                ) : (
                  <button
                    onClick={handleEditAbout}
                    className={`p-1.5 rounded-full transition-opacity duration-200 opacity-0 group-hover:opacity-100
                      ${isDarkMode 
                        ? 'hover:bg-white/10' 
                        : 'hover:bg-black/5'}`}
                  >
                    <PencilSquareIcon className={`w-3.5 h-3.5 
                      ${isDarkMode ? 'text-white/60' : 'text-black/60'}`} />
                  </button>
                )}
              </div>
            </div>

            {isEditingAbout ? (
              <textarea
                name="about"
                value={editedUser.about}
                onChange={handleChange}
                rows="3"
                className={`w-full text-sm px-3 py-2 rounded-lg transition-all duration-200
                  ${isDarkMode 
                    ? 'bg-white/[0.06] text-white focus:bg-white/[0.07] placeholder-white/30' 
                    : 'bg-white text-black focus:bg-white placeholder-black/30'}
                  border ${isDarkMode 
                    ? 'border-white/10 focus:border-white/[0.15]' 
                    : 'border-black/[0.06] focus:border-black/[0.09]'}
                  focus:ring-0 focus:outline-none resize-y min-h-[72px] max-h-[300px]`}
                placeholder="Tell us about yourself..."
              />
            ) : (
              <div className="space-y-2">
                <div className={`relative transition-all duration-200
                  ${isAboutExpanded ? 'h-[300px]' : 'max-h-[100px] overflow-hidden'}`}>
                  <div className={`text-sm whitespace-pre-line h-full
                    ${isAboutExpanded ? 'overflow-y-auto settings-scroll-area' : ''}
                    ${isDarkMode ? 'text-white/80' : 'text-black/80'}`}>
                    {editedUser.about || "No bio yet"}
                  </div>
                  {!isAboutExpanded && editedUser.about && editedUser.about.length > 100 && (
                    <div className={`absolute bottom-0 left-0 right-0 h-8 bg-gradient-to-t
                      ${isDarkMode ? 'from-black/90' : 'from-white/90'}`} />
                  )}
                </div>
                
                {/* Add Show more/less button */}
                {editedUser.about && editedUser.about.length > 100 && (
                  <button
                    onClick={() => setIsAboutExpanded(!isAboutExpanded)}
                    className={`text-xs font-medium transition-colors
                      ${isDarkMode 
                        ? 'text-white/40 hover:text-white/60' 
                        : 'text-black/40 hover:text-black/60'}`}
                  >
                    {isAboutExpanded ? 'Show less' : 'Show more'}
                  </button>
                )}
              </div>
            )}
          </div>

          {/* Password Section */}
          <div className={`p-4 rounded-2xl border overflow-hidden
            ${isDarkMode ? 'border-white/10' : 'border-black/5'}`}>
            <button
              onClick={() => setShowPasswordChange(!showPasswordChange)}
              className={`w-full flex justify-between items-center`}
            >
              <span className={`text-xs font-medium tracking-wide uppercase
                ${isDarkMode ? 'text-white/60' : 'text-black/60'}`}>
                Password
              </span>
              <ChevronRightIcon 
                className={`w-3 h-3 transition-transform duration-200
                  ${showPasswordChange ? 'rotate-90' : ''}
                  ${isDarkMode ? 'text-white/40' : 'text-black/40'}`} 
              />
            </button>

            {showPasswordChange && (
              <div className="mt-3">
                <PasswordSection 
                  isDarkMode={isDarkMode} 
                  onSuccess={(message) => setSuccessModal({ show: true, message })}
                />
              </div>
            )}
          </div>

          {/* LinkedIn Connection Section */}
          <div className={`p-4 rounded-2xl border overflow-hidden
            ${isDarkMode ? 'border-white/10' : 'border-black/5'}`}>
            <div className="flex justify-between items-center">
              <span className={`text-xs font-medium tracking-wide uppercase
                ${isDarkMode ? 'text-white/60' : 'text-black/60'}`}>
                LinkedIn Connection
              </span>
              {user?.linkedinId ? (
                <div className="flex items-center space-x-2">
                  <span className={`text-xs ${isDarkMode ? 'text-white/40' : 'text-black/40'}`}>
                    Connected
                  </span>
                  <div className={`w-2 h-2 rounded-full bg-green-500`}></div>
                </div>
              ) : (
                <button
                  onClick={async () => {
                    try {
                      const state = `connect-${Date.now()}`;
                      const authUrl = await getLinkedInAuthUrl(state);
                      window.location.href = authUrl;
                    } catch (error) {
                      console.error('Error getting LinkedIn auth URL:', error);
                      toast.error('Failed to initiate LinkedIn connection');
                    }
                  }}
                  className={`px-3 py-1.5 text-xs font-medium rounded-lg transition-all duration-200
                    ${isDarkMode 
                      ? 'bg-[#0A66C2] text-white hover:bg-[#0A66C2]/90' 
                      : 'bg-[#0A66C2] text-white hover:bg-[#0A66C2]/90'}`}
                >
                  Connect LinkedIn
                </button>
              )}
            </div>
            {user?.linkedinId && (
              <div className="mt-3">
                <p className={`text-xs ${isDarkMode ? 'text-white/40' : 'text-black/40'}`}>
                  Your LinkedIn account is connected. You can now publish posts directly to LinkedIn.
                </p>
                <button
                  onClick={handleDisconnectLinkedIn}
                  disabled={isDisconnectingLinkedIn}
                  className={`mt-2 px-3 py-1.5 text-xs font-medium rounded-lg transition-all duration-200
                    ${isDarkMode 
                      ? 'bg-red-500/10 text-red-400 hover:bg-red-500/20' 
                      : 'bg-red-50 text-red-500 hover:bg-red-100'}
                    ${isDisconnectingLinkedIn ? 'opacity-50 cursor-not-allowed' : ''}`}
                  >
                    {isDisconnectingLinkedIn ? (
                      <div className="flex items-center space-x-2">
                        <Spinner className="w-3 h-3" />
                        <span>Disconnecting...</span>
                      </div>
                    ) : (
                      'Disconnect'
                    )}
                  </button>
              </div>
            )}
          </div>

        </div>
      </div>

      <LogoutConfirmationModal
        isOpen={showLogoutConfirmation}
        onClose={() => setShowLogoutConfirmation(false)}
        onConfirm={() => {
          logout();
          navigate('/login');
        }}
        isDarkMode={isDarkMode}
      />

      <SuccessModal
        isOpen={successModal.show}
        onClose={() => setSuccessModal({ show: false, message: '' })}
        message={successModal.message}
        isDarkMode={isDarkMode}
      />
    </div>
  );
};

export default Settings;