import React from "react";
import { cn } from "../../utils/cn"; // We'll create this utility

const buttonVariants = {
  base: "inline-flex items-center justify-center gap-1.5 whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:shrink-0",
  variants: {
    default: "bg-primary text-primary-foreground shadow hover:bg-primary/90",
    destructive: "bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90",
    outline: "border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground",
    secondary: "bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80",
    ghost: "hover:bg-accent hover:text-accent-foreground",
    link: "text-primary underline-offset-4 hover:underline"
  },
  sizes: {
    default: "h-8 px-3 py-1.5 text-xs",
    sm: "h-7 rounded-md px-2.5 text-xs",
    lg: "h-9 rounded-md px-6",
    icon: "h-8 w-8",
    tiny: "h-5 w-5 p-0.5 [&_svg]:size-3.5",
    'tiny-with-text': "h-5 px-1.5 py-0.5 text-xs [&_svg]:size-3.5"
  }
};

const Button = React.forwardRef(({ 
  className, 
  variant = "default", 
  size = "default", 
  children,
  isDarkMode,
  ...props 
}, ref) => {
  const getVariantStyles = () => {
    if (isDarkMode) {
      return {
        default: "bg-slate-700 text-slate-200 hover:bg-slate-600",
        destructive: "bg-red-700 text-red-100 hover:bg-red-600",
        outline: "border-slate-600 bg-slate-800 hover:bg-slate-700",
        secondary: "bg-slate-800 text-slate-200 hover:bg-slate-700",
        ghost: "hover:bg-slate-700 text-slate-200",
        link: "text-slate-200 hover:text-slate-100"
      }[variant];
    }
    return {
      default: "bg-gray-100 text-gray-700 hover:bg-gray-200",
      destructive: "bg-red-500 text-white hover:bg-red-600",
      outline: "border-gray-200 bg-white hover:bg-gray-50",
      secondary: "bg-gray-200 text-gray-700 hover:bg-gray-300",
      ghost: "hover:bg-gray-100 text-gray-700",
      link: "text-blue-500 hover:text-blue-600"
    }[variant];
  };

  return (
    <button
      ref={ref}
      className={cn(
        buttonVariants.base,
        getVariantStyles(),
        buttonVariants.sizes[size],
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
});

Button.displayName = "Button";

export { Button, buttonVariants };
