import React, { useState, useEffect, useRef, useMemo } from 'react';
import { XMarkIcon, MagnifyingGlassIcon, ChevronDownIcon, FunnelIcon, Squares2X2Icon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import { fetchTemplates } from '../api';
import Masonry from 'react-masonry-css';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../context/ThemeContext';
import LinkedInPostPreview from './LinkedInPostPreview';
import { motion, AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';
import jasonVanaImg from './assets/inspirations/jason-vana.jpeg';
import justinWelshImg from './assets/inspirations/justin-welsh.jpeg';

const templateNameOptions = [
  'Industry Term Redefined', 'The Myth Buster', 'The Wake-Up Call',
  'The No-BS Guide', 'The Reality vs. Expectation', 'The Uncomfortable Truth',
  'The Brutal Honesty', 'The Sacred Cow Slaughter', 'The Tough Love',
  'The Contrarian View', 'The Simplification', 'The Reality Check'
];

const inspiredByOptions = [
  { name: 'Jason Vana', image: jasonVanaImg },
  { name: 'Justin Welsh', image: justinWelshImg }
];

const categoryOptions = [
  'Educate', 'Bust Myths', 'Advice', 'Guide', 'Opinion'
];

const toneOptions = [
  'Sassy', 'Provocative', 'Blunt', 'Direct', 'Realistic', 'Confrontational',
  'Brutally Honest', 'Tough Love', 'Contrarian', 'Simplifying'
];

// Update the getBreakpointColumns function for narrower posts
const getBreakpointColumns = (containerWidth) => {
  // Reduce target column width to fit more columns
  const targetColumnWidth = 300; // Reduced from 400 to 300
  
  // Calculate how many columns can fit
  let columns = Math.floor(containerWidth / targetColumnWidth);
  
  // Ensure we have at least 1 column and no more than 5 (increased max columns)
  columns = Math.max(1, Math.min(5, columns));
  
  return columns;
};

const highlightSearchTerm = (content, searchTerm) => {
  if (!searchTerm) return content;
  try {
    const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(`(${escapedSearchTerm})`, 'gi');
    return content.replace(regex, '<mark class="bg-yellow-200 dark:bg-yellow-800 rounded px-0.5">$1</mark>');
  } catch (error) {
    console.error('Error highlighting search term:', error);
    return content;
  }
};

// Change the closeAllPopups event name to be more specific
const closeAllDropdowns = new CustomEvent('closeAllDropdowns');

const dropdownVariants = {
  hidden: { 
    opacity: 0, 
    scale: 0.95,
    y: -10,
    transition: { duration: 0.15, ease: [0.4, 0, 0.2, 1] }
  },
  visible: { 
    opacity: 1, 
    scale: 1,
    y: 0,
    transition: { duration: 0.2, ease: [0.4, 0, 0.2, 1] }
  },
  exit: { 
    opacity: 0, 
    scale: 0.95,
    y: -10,
    transition: { duration: 0.15, ease: [0.4, 0, 0.2, 1] }
  }
};

const FilterDropdown = ({ options, selectedOptions, onChange, label, isDarkMode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    // Change to closeAllDropdowns
    const handleCloseDropdowns = () => setIsOpen(false);

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('closeAllDropdowns', handleCloseDropdowns);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('closeAllDropdowns', handleCloseDropdowns);
    };
  }, []);

  const handleOpen = (e) => {
    e.stopPropagation(); // Prevent event from bubbling up
    document.dispatchEvent(closeAllDropdowns);
    setIsOpen(true);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <motion.button
        whileTap={{ scale: 0.97 }}
        onClick={handleOpen}
        className={`
          inline-flex items-center justify-center
          text-xs font-medium tracking-wide
          h-7 px-3 rounded-md
          border transition-all duration-200
          ${isDarkMode 
            ? `${selectedOptions.length > 0 
                ? 'bg-[#1e1e1e] border-[#333333] text-blue-400' 
                : 'bg-[#1e1e1e] border-[#333333] text-slate-400 hover:bg-[#2a2a2a]'}`
            : `${selectedOptions.length > 0 
                ? 'bg-black border-black text-white' 
                : 'bg-white border-black/10 text-black/70 hover:border-black/20'}`
          }
        `}
      >
        <span className="mr-1.5">{label}</span>
        {selectedOptions.length > 0 && (
          <span className={`
            px-1.5 rounded text-[10px] font-semibold
            ${isDarkMode 
              ? 'bg-indigo-500/30 text-indigo-200' 
              : 'bg-indigo-100 text-indigo-700'}
          `}>
            {selectedOptions.length}
          </span>
        )}
        <ChevronDownIcon className="ml-1.5 h-3 w-3" />
      </motion.button>
      
      <AnimatePresence>
        {isOpen && (
          <motion.div
            variants={dropdownVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className={`
              absolute left-0 top-full mt-1 w-48 rounded-lg
              shadow-lg
              ${isDarkMode 
                ? 'bg-[#151515] border border-[#333333]' 
                : 'bg-white border border-black/5'}
            `}
          >
            <div className="p-2">
              {options.map((option) => (
                <motion.label 
                  key={typeof option === 'string' ? option : option.name}
                  whileHover={{ x: 4 }}
                  whileTap={{ scale: 0.98 }}
                  className={`
                    relative flex cursor-pointer select-none items-center
                    rounded-xl px-3 py-2.5 text-sm
                    transition-colors duration-200
                    ${isDarkMode 
                      ? 'hover:bg-[#1e1e1e]' 
                      : 'hover:bg-black/5'}
                  `}
                >
                  {option.image && (
                    <img 
                      src={option.image} 
                      alt={option.name}
                      className="w-6 h-6 rounded-full object-cover mr-2"
                    />
                  )}
                  <motion.div
                    initial={false}
                    animate={{
                      backgroundColor: selectedOptions.includes(option.name || option) 
                        ? isDarkMode ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)'
                        : 'transparent'
                    }}
                    className={`
                      flex h-4 w-4 items-center justify-center rounded-full border
                      ${isDarkMode 
                        ? 'border-white/30' 
                        : 'border-black/30'}
                    `}
                  >
                    <motion.div
                      initial={{ scale: 0 }}
                      animate={{ 
                        scale: selectedOptions.includes(option.name || option) ? 1 : 0 
                      }}
                      className={`
                        h-2 w-2 rounded-full
                        ${isDarkMode ? 'bg-black' : 'bg-white'}
                      `}
                    />
                  </motion.div>
                  <span className={`
                    ml-3 transition-all duration-200
                    ${isDarkMode ? 'text-slate-200' : 'text-black/90'}
                    ${selectedOptions.includes(option.name || option) 
                      ? 'font-medium translate-x-0.5' 
                      : 'font-normal'}
                  `}>
                    {option.name || option}
                  </span>
                  <input
                    type="checkbox"
                    className="hidden"
                    checked={selectedOptions.includes(option.name || option)}
                    onChange={() => onChange(option.name || option)}
                  />
                </motion.label>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const TopBar = ({ 
  selectedCategories, 
  selectedInspiredBy, 
  selectedTones,
  onSelectCategory, 
  onSelectInspiredBy, 
  onSelectTone,
  isDarkMode,
  searchTerm,
  setSearchTerm,
  totalTemplates,
  filteredCount,
  onClose,
  isSearchOpen,
  setIsSearchOpen,
  isMobile
}) => {
  return (
    <div className={`
      w-full border-b sticky top-0 z-50
      ${isDarkMode ? 'bg-[#121212] border-[#333333]' : 'bg-white border-black/5'}
    `}>
      {/* Title Bar with count */}
      <div className={`
        flex items-center justify-between px-3 h-10 border-b
        ${isDarkMode ? 'border-[#333333]' : 'border-black/5'}
      `}>
        <div className="flex items-center space-x-2.5">
          <Squares2X2Icon className={`h-4 w-4 ${isDarkMode ? 'text-slate-400' : 'text-black/70'}`} />
          <h2 className={`text-xs font-medium ${isDarkMode ? 'text-slate-200' : 'text-black/90'}`}>
            Choose a Template
          </h2>
          <span className={`
            text-[10px] font-medium px-1.5 py-0.5 rounded-full
            ${isDarkMode 
              ? 'bg-[#1e1e1e] text-slate-400 border border-[#333333]' 
              : 'bg-gray-100 text-black/40 border border-gray-200'
            }
          `}>
            {filteredCount} / {totalTemplates}
          </span>
        </div>
        <button 
          onClick={onClose}
          className={`
            p-1 rounded-full transition-colors
            ${isDarkMode ? 'hover:bg-[#1e1e1e]' : 'hover:bg-black/5'}
          `}
        >
          <XMarkIcon className={`w-4 h-4 ${isDarkMode ? 'text-slate-400' : 'text-black/70'}`} />
        </button>
      </div>

      {/* Filters and Search in one row - Scrollable on mobile */}
      <div className={`px-3 py-2 ${isMobile ? 'overflow-x-auto' : ''}`}>
        <div className={`flex items-center gap-3 ${isMobile ? 'min-w-max' : 'justify-between'}`}>
          {/* Filters on the left */}
          <div className="flex items-center gap-1.5">
            <FilterDropdown
              options={categoryOptions}
              selectedOptions={selectedCategories}
              onChange={onSelectCategory}
              label="Category"
              isDarkMode={isDarkMode}
            />
            <FilterDropdown
              options={inspiredByOptions}
              selectedOptions={selectedInspiredBy}
              onChange={onSelectInspiredBy}
              label="Inspired By"
              isDarkMode={isDarkMode}
            />
            <FilterDropdown
              options={toneOptions}
              selectedOptions={selectedTones}
              onChange={onSelectTone}
              label="Tone"
              isDarkMode={isDarkMode}
            />
          </div>

          {/* Search on the right */}
          <div className="relative flex items-center">
            {isSearchOpen ? (
              <div className={`
                flex items-center
                animate-in slide-in-from-right-5 duration-200
              `}>
                <input
                  type="text"
                  placeholder="Search templates..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className={`
                    pl-8 pr-8 py-1 text-xs rounded-md
                    transition-all duration-200
                    ${isDarkMode 
                      ? 'bg-[#1e1e1e] border-[#333333] text-slate-200 placeholder-slate-400' 
                      : 'bg-gray-100 border-gray-200 text-black/90 placeholder-black/30'
                    } border focus:outline-none focus:ring-1 focus:ring-blue-500
                    ${isMobile ? 'w-36' : 'w-48'}
                  `}
                  autoFocus
                />
                <button
                  onClick={() => {
                    setIsSearchOpen(false);
                    setSearchTerm('');
                  }}
                  className={`
                    absolute right-2 p-1 rounded-full
                    ${isDarkMode 
                      ? 'hover:bg-[#2a2a2a] text-slate-400 hover:text-slate-300' 
                      : 'hover:bg-gray-200 text-gray-500 hover:text-gray-700'
                    }
                  `}
                >
                  <XMarkIcon className="w-3.5 h-3.5" />
                </button>
                <MagnifyingGlassIcon className={`
                  absolute left-2 w-4 h-4
                  ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}
                `} />
              </div>
            ) : (
              <button
                onClick={() => setIsSearchOpen(true)}
                className={`
                  p-1.5 rounded-md transition-colors duration-200
                  ${isDarkMode 
                    ? 'hover:bg-[#1e1e1e] text-slate-400 hover:text-slate-300' 
                    : 'hover:bg-gray-100 text-gray-500 hover:text-gray-700'
                  }
                `}
                title="Search templates"
              >
                <MagnifyingGlassIcon className="w-4 h-4" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// Update the TemplateCard component to handle mobile view
const TemplateCard = ({ template, onSelect, isDarkMode, isMobile }) => {
  const authorProfile = inspiredByOptions.find(author => 
    author.name === template.inspiredBy
  );

  return (
    <motion.div 
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 10 }}
      onClick={() => onSelect({
        title: template.templateName,
        content: template.templateContent,
        category: template.category,
        tone: template.tone
      })}
      className={`w-full cursor-pointer ${isMobile ? 'p-0 mb-4' : 'p-2'}`}
    >
      <div className="h-full">
        <LinkedInPostPreview
          content={template.templateContent}
          persona={{
            name: template.inspiredBy || 'Template Author',
            occupation: template.category || 'Content Creator',
            avatarUrl: authorProfile?.image
          }}
          isDarkMode={isDarkMode}
          isMobileView={isMobile}
        />
      </div>
    </motion.div>
  );
};

const NoResultsState = ({ searchTerm, isDarkMode }) => (
  <div className="flex flex-col items-center justify-center h-full p-8 text-center">
    <MagnifyingGlassIcon className={`h-16 w-16 ${isDarkMode ? 'text-[#333333]' : 'text-gray-300'} mb-4`} />
    <h3 className={`text-lg font-medium mb-2 ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>
      No templates found
    </h3>
    <p className={`text-sm max-w-md ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
      {searchTerm 
        ? `We couldn't find any templates matching "${searchTerm}"`
        : "No templates match the selected filters"
      }
    </p>
    {searchTerm && (
      <p className={`text-sm mt-2 ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
        Try adjusting your search terms or filters
      </p>
    )}
  </div>
);

const TemplatePopup = ({ onClose, onSelectTemplate, isMobileView }) => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 639 }) || isMobileView;
  const isTablet = useMediaQuery({ minWidth: 640, maxWidth: 1023 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const [templates, setTemplates] = useState([]);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedInspiredBy, setSelectedInspiredBy] = useState([]);
  const [selectedTones, setSelectedTones] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(isDesktop);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  // Add ref for container width measurement
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);

  // Add ResizeObserver to measure container width
  useEffect(() => {
    if (!containerRef.current) return;

    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        setContainerWidth(entry.contentRect.width);
      }
    });

    resizeObserver.observe(containerRef.current);

    return () => resizeObserver.disconnect();
  }, []);

  // Update the columns calculation for mobile
  const columns = useMemo(() => {
    if (isMobile) return 1; // Force single column on mobile
    return getBreakpointColumns(containerWidth);
  }, [containerWidth, isMobile]);

  useEffect(() => {
    const loadTemplates = async () => {
      try {
        const fetchedTemplates = await fetchTemplates();
        setTemplates(fetchedTemplates);
        setFilteredTemplates(fetchedTemplates);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching templates:', err);
        setError('Failed to load templates. Please try again.');
        setLoading(false);
      }
    };

    loadTemplates();
  }, []);

  useEffect(() => {
    const filtered = templates.filter(template => {
      const searchLower = searchTerm.toLowerCase();
      const searchMatch = 
        template.templateName.toLowerCase().includes(searchLower) ||
        template.templateContent.toLowerCase().includes(searchLower);
      const categoryMatch = selectedCategories.length === 0 || selectedCategories.includes(template.category);
      const inspiredByMatch = selectedInspiredBy.length === 0 || selectedInspiredBy.includes(template.inspiredBy);
      const toneMatch = selectedTones.length === 0 || selectedTones.includes(template.tone);
      
      return searchMatch && categoryMatch && inspiredByMatch && toneMatch;
    });

    setFilteredTemplates(filtered);
  }, [searchTerm, selectedCategories, selectedInspiredBy, selectedTones, templates]);

  const handleCategorySelect = (category) => {
    setSelectedCategories(prev => 
      prev.includes(category) ? prev.filter(c => c !== category) : [...prev, category]
    );
  };

  const handleInspiredBySelect = (inspiredBy) => {
    setSelectedInspiredBy(prev => 
      prev.includes(inspiredBy) ? prev.filter(i => i !== inspiredBy) : [...prev, inspiredBy]
    );
  };

  const handleToneSelect = (tone) => {
    setSelectedTones(prev => 
      prev.includes(tone) ? prev.filter(t => t !== tone) : [...prev, tone]
    );
  };

  useEffect(() => {
    // Remove this effect that was closing the popup
    // const handleClosePopups = () => onClose();
    // document.addEventListener('closeAllPopups', handleClosePopups);
    // return () => document.removeEventListener('closeAllPopups', handleClosePopups);
  }, [onClose]);

  return (
    <motion.div 
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.95 }}
      transition={{ duration: 0.2 }}
      className={`
        flex flex-col h-full overflow-hidden rounded-lg
        ${isDarkMode ? 'bg-[#121212]' : 'bg-white'}
        ${isMobile ? 'max-h-[80vh]' : ''}
      `}
    >
      <TopBar
        selectedCategories={selectedCategories}
        selectedInspiredBy={selectedInspiredBy}
        selectedTones={selectedTones}
        onSelectCategory={handleCategorySelect}
        onSelectInspiredBy={handleInspiredBySelect}
        onSelectTone={handleToneSelect}
        isDarkMode={isDarkMode}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        totalTemplates={templates.length}
        filteredCount={filteredTemplates.length}
        onClose={onClose}
        isSearchOpen={isSearchOpen}
        setIsSearchOpen={setIsSearchOpen}
        isMobile={isMobile}
      />

      <div 
        ref={containerRef}
        className={`
          flex-grow overflow-y-auto px-2
          ${isDarkMode ? 'bg-[#121212]' : 'bg-white'}
          ${isMobile ? 'pb-4' : ''}
        `}
      >
        {loading ? (
          <Masonry
            breakpointCols={columns}
            className="flex -ml-2 w-auto"
            columnClassName="pl-2 bg-clip-padding"
          >
            {[...Array(isMobile ? 3 : 6)].map((_, index) => (
              <div key={index} className="mb-3 animate-pulse opacity-40">
                <LinkedInPostPreview
                  content=""
                  isDarkMode={isDarkMode}
                />
              </div>
            ))}
          </Masonry>
        ) : error ? (
          <div className="flex items-center justify-center h-full p-4">
            <button
              onClick={() => window.location.reload()}
              className={`
                px-4 py-2 rounded-lg text-sm font-medium
                transition-colors duration-200
                ${isDarkMode 
                  ? 'bg-[#1e1e1e] text-slate-200 hover:bg-[#2a2a2a] border border-[#333333]' 
                  : 'bg-black text-white hover:bg-gray-900 border border-black'}
              `}
            >
              Retry
            </button>
          </div>
        ) : filteredTemplates.length === 0 ? (
          <NoResultsState searchTerm={searchTerm} isDarkMode={isDarkMode} />
        ) : (
          <Masonry
            breakpointCols={columns}
            className="flex -ml-2 w-auto"
            columnClassName="pl-2 bg-clip-padding"
          >
            {filteredTemplates.map((template) => (
              <div key={template._id} className={`mb-2 ${isMobile ? 'w-full' : ''}`}>
                <TemplateCard
                  template={template}
                  onSelect={onSelectTemplate}
                  isDarkMode={isDarkMode}
                  isMobile={isMobile}
                />
              </div>
            ))}
          </Masonry>
        )}
      </div>
    </motion.div>
  );
};

TemplatePopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSelectTemplate: PropTypes.func.isRequired,
  isMobileView: PropTypes.bool
};

export default TemplatePopup;