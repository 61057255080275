import React, { useEffect, useState, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { XMarkIcon, TrashIcon } from '@heroicons/react/24/outline';

const CanvasWelcomeActionBar = ({ 
  isVisible, 
  selectedCount, 
  onCancel, 
  onDelete, 
  isDarkMode,
  containerRef
}) => {
  const [position, setPosition] = useState({ bottom: 0, left: 0, width: 400 });

  const updatePosition = useCallback(() => {
    if (!containerRef?.current) return;
    
    const bounds = containerRef.current.getBoundingClientRect();
    const padding = 24;
    const bottomOffset = 30;
    const maxWidth = 400;
    const minWidth = 280;
    
    const availableWidth = bounds.width - (padding * 2);
    const width = Math.min(maxWidth, Math.max(minWidth, availableWidth));
    
    setPosition({
      bottom: window.innerHeight - bounds.bottom + padding + bottomOffset,
      left: bounds.left + (bounds.width - width) / 2,
      width: width
    });
  }, [containerRef]);

  useEffect(() => {
    const handleEscKey = (e) => {
      if (e.key === 'Escape' && isVisible) {
        onCancel();
      }
    };

    if (isVisible) {
      window.addEventListener('keydown', handleEscKey);
    }

    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [isVisible, onCancel]);

  useEffect(() => {
    if (!containerRef?.current || !isVisible) return;
    updatePosition();
    const resizeObserver = new ResizeObserver(() => {
      window.requestAnimationFrame(updatePosition);
    });
    resizeObserver.observe(containerRef.current);
    window.addEventListener('resize', updatePosition, { passive: true });
    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', updatePosition);
    };
  }, [containerRef, isVisible, updatePosition]);

  useEffect(() => {
    if (isVisible) {
      updatePosition();
    }
  }, [isVisible, updatePosition]);

  return createPortal(
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ y: 20, opacity: 0, scale: 0.95 }}
          animate={{ y: 0, opacity: 1, scale: 1 }}
          exit={{ y: 20, opacity: 0, scale: 0.95 }}
          transition={{ duration: 0.2, ease: [0.23, 1, 0.32, 1] }}
          className={`
            fixed
            flex items-center justify-between
            px-4 py-3
            rounded-xl
            ${isDarkMode 
              ? 'bg-slate-900/90 border border-slate-800/60' 
              : 'bg-white/90 border border-slate-200/60'}
            backdrop-blur-md
            z-[60]
            shadow-xl ${isDarkMode ? 'shadow-slate-950/20' : 'shadow-slate-200/20'}
            ring-1 ring-slate-950/5
            min-w-[280px] max-w-[400px]
          `}
          style={{
            bottom: `${position.bottom}px`,
            left: `${position.left}px`,
            width: `${position.width}px`,
            transform: 'none',
            willChange: 'transform',
          }}
        >
          {/* Selection Info - Simplified */}
          <div className="flex flex-col">
            <span className={`
              text-sm font-medium leading-none mb-0.5
              ${isDarkMode ? 'text-slate-200' : 'text-slate-700'}
            `}>
              {selectedCount} item{selectedCount !== 1 ? 's' : ''} selected
            </span>
            <span className={`
              text-xs
              ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}
            `}>
              Press Esc to cancel
            </span>
          </div>

          {/* Actions */}
          <div className="flex items-center gap-2">
            <button
              onClick={onDelete}
              className={`
                px-3 py-2 rounded-lg
                transition-all duration-200
                hover:scale-105 active:scale-95
                text-red-500 hover:text-red-600
                flex items-center gap-2
                ${isDarkMode 
                  ? 'hover:bg-red-500/10' 
                  : 'hover:bg-red-50'}
                focus:outline-none focus:ring-2 focus:ring-red-500
              `}
              title="Delete selected items"
              aria-label="Delete selected items"
            >
              <TrashIcon className="w-5 h-5" />
              <span className="text-sm font-medium">Delete</span>
            </button>

            <div className="w-px h-6 bg-slate-200/20" /> {/* Separator */}

            <button
              onClick={onCancel}
              className={`
                p-2 rounded-lg
                transition-all duration-200
                hover:scale-105 active:scale-95
                ${isDarkMode
                  ? 'text-slate-400 hover:text-slate-300 hover:bg-slate-800/70'
                  : 'text-slate-500 hover:text-slate-600 hover:bg-slate-100/70'}
                focus:outline-none focus:ring-2 focus:ring-slate-400
              `}
              title="Cancel selection"
              aria-label="Cancel selection"
            >
              <XMarkIcon className="w-5 h-5" />
            </button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  );
};

CanvasWelcomeActionBar.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  selectedCount: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
  containerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) })
};

export default CanvasWelcomeActionBar; 