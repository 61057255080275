import React, { useState, useEffect } from 'react';
import { useTheme } from '../../context/ThemeContext';
import { PaperAirplaneIcon, ClipboardDocumentIcon, ArrowLeftIcon, ClockIcon, XMarkIcon, ChatBubbleLeftRightIcon, TrashIcon } from '@heroicons/react/24/outline';
import { ClipboardDocumentIcon as ClipboardDocumentIconSolid } from '@heroicons/react/24/solid';
import { motion, AnimatePresence } from 'framer-motion';
import PersonaDropdown from '../personas/PersonaDropdown';
import TargetAudienceDropdown from '../targetAudiences/TargetAudienceDropdown';
import { ToneDropdown } from './PostComments'; // We'll need to export this from PostComments.js
import { useNavigate } from 'react-router-dom';
import { usePostComments } from '../../context/PostCommentsContext';
import StyledActionButton from '../StyledActionButton';
import { useAuth } from '../../context/AuthContext';
import '../customScrollbar.css';
import ClearIcon from '../icons/ClearIcon';

const TopBar = ({ isDarkMode, onClose }) => (
  <div className={`${isDarkMode ? 'bg-[#151515] border-[#333333]' : 'bg-white/90 border-gray-100'} 
    backdrop-filter backdrop-blur-lg h-10 flex-shrink-0 flex items-center justify-between px-3 w-full border-b`}>
    <div className="flex items-center space-x-2">
      <ChatBubbleLeftRightIcon className={`h-4 w-4 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
      <h2 className={`text-xs font-medium tracking-tight ${isDarkMode ? 'text-slate-200' : 'text-gray-800'}`}>
        Comment Generator
      </h2>
    </div>
    <button
      onClick={onClose}
      className={`p-1.5 rounded-full transition-all duration-300 ${
        isDarkMode ? 'hover:bg-[#1e1e1e]' : 'hover:bg-gray-100'
      }`}
    >
      <ArrowLeftIcon className={`h-4 w-4 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
    </button>
  </div>
);

const styles = `
  @media (min-width: 640px) {
    .icon-text {
      display: inline;
    }
  }
  @media (max-width: 639px) {
    .icon-text {
      display: none;
    }
  }
  .action-buttons {
    transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
    backdrop-filter: blur(8px);
  }
  .action-buttons.hidden {
    opacity: 0;
    transform: translateY(10px) scale(0.98);
    pointer-events: none;
  }
  .apple-input {
    transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  }
  .apple-input:focus {
    transform: translateY(-1px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  }
  .generated-comment {
    transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  }
  .generated-comment:hover {
    transform: translateY(-1px);
  }
`;

const ChatInterfacePostComments = ({ onClose }) => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const [isHovering, setIsHovering] = useState(false);
  const {
    postContent,
    setPostContent,
    selectedTone,
    setSelectedTone,
    generatedComments,
    isLoading,
    error,
    setError,
    handleGenerate,
    copiedComments,
    copyToClipboard,
    selectedPersona,
    setSelectedPersona,
    selectedTargetAudience,
    setSelectedTargetAudience,
    isLoadingAvatar,
    saveToHistory,
    commentToReply,
    setCommentToReply,
  } = usePostComments();
  const { user, isAuthenticated, loading: authLoading } = useAuth();

  useEffect(() => {
    if (!authLoading) {
      if (!isAuthenticated) {
        console.log('User not authenticated');
        // Optionally redirect to login
        // navigate('/login');
      } else {
        console.log('User authenticated:', user);
      }
    }
  }, [authLoading, isAuthenticated, user]);

  const handleGenerateAndSave = async () => {
    try {
      // Log what we're sending to the backend
      console.log('=== SENDING TO BACKEND ===');
      console.log('Post Content:', postContent);
      console.log('Comment to Reply:', commentToReply);
      console.log('Selected Tone:', selectedTone);
      console.log('Selected Persona:', selectedPersona);
      console.log('Selected Target Audience:', selectedTargetAudience);
      console.log('========================');

      const comments = await handleGenerate(commentToReply);
      
      // Log the response
      console.log('=== RECEIVED FROM BACKEND ===');
      console.log('Generated Comments:', comments);
      console.log('===========================');

      if (comments && comments.length > 0) {
        // Save each generated comment to history
        for (const comment of comments) {
          await saveToHistory({
            postContent,
            commentToReply,
            comment,
            tone: selectedTone,
            persona: selectedPersona,
            targetAudience: selectedTargetAudience
          });
        }
      }
    } catch (error) {
      console.error('Error generating and saving comments:', error);
      setError('Failed to generate and save comments');
    }
  };

  return (
    <div className={`flex flex-col h-full ${isDarkMode ? 'bg-[#121212]' : 'bg-gray-50'}`}>
      <style>{styles}</style>
      <TopBar isDarkMode={isDarkMode} onClose={onClose} />
      
      <div className="flex-1 overflow-y-auto px-3 py-4 popup-scrollbar">
        <div className="max-w-2xl mx-auto space-y-4">
          {/* Input Section */}
          <div className={`${isDarkMode ? 'bg-[#151515] border-[#333333]' : 'bg-white border-gray-100/50'} 
            rounded-lg p-4 relative z-30 shadow-sm border`}>
            {/* Move Persona and Audience to top - Updated alignment and z-index */}
            <div className="flex items-center justify-start gap-1.5 mb-4 relative z-[100]">
              <PersonaDropdown
                onSelectPersona={setSelectedPersona}
                selectedPersona={selectedPersona}
                isLoadingAvatar={isLoadingAvatar}
                compact={true}
              />
              <TargetAudienceDropdown
                onSelectAudience={setSelectedTargetAudience}
                selectedAudience={selectedTargetAudience}
                compact={true}
              />
            </div>

            {/* Content Area */}
            <div className="space-y-3">
              {/* Post textarea */}
              <div className="relative">
                <label className={`block text-xs font-medium mb-1.5 
                  ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
                  Post
                </label>
                <textarea
                  value={postContent}
                  onChange={(e) => setPostContent(e.target.value)}
                  className={`w-full p-2.5 text-xs rounded-lg border popup-scrollbar
                    transition-all duration-200 apple-input
                    ${isDarkMode 
                      ? 'bg-[#1e1e1e] border-[#333333] text-white placeholder-slate-400/50' 
                      : 'bg-gray-50/50 border-gray-200/30 text-gray-900 placeholder-gray-400'
                    }
                    focus:outline-none
                    ${isDarkMode 
                      ? 'focus:border-blue-500/50 focus:bg-[#202020]' 
                      : 'focus:border-gray-300/50 focus:bg-white'
                    }
                  `}
                  placeholder="Enter post content..."
                  rows={3}
                />
              </div>

              {/* Reply textarea */}
              <div className="relative mb-3">
                <label className={`block text-xs font-medium mb-1.5 ${
                  isDarkMode ? 'text-slate-400' : 'text-gray-700'
                }`}>
                  Reply to (Optional)
                </label>
                <textarea
                  value={commentToReply}
                  onChange={(e) => setCommentToReply(e.target.value)}
                  className={`w-full h-20 p-2 text-xs rounded-md border popup-scrollbar
                    transition-colors
                    ${isDarkMode 
                      ? 'bg-[#1e1e1e] border-[#333333] text-white placeholder-slate-400/50' 
                      : 'bg-gray-50/50 border-gray-200/50 text-gray-900 placeholder-gray-400'
                    }
                    focus:outline-none
                    ${isDarkMode 
                      ? 'focus:border-blue-500/50' 
                      : 'focus:border-gray-300'
                    }
                  `}
                  placeholder="Enter comment to reply to..."
                />
              </div>

              {error && (
                <p className={`mt-2 mb-3 text-xs ${isDarkMode ? 'text-red-400' : 'text-red-600'}`}>
                  {error}
                </p>
              )}

              {/* Tone Selection and Actions Row */}
              <div className="flex items-center justify-between gap-4 pt-2 relative z-[90]">
                {/* Tone Selection */}
                <div className="flex-1">
                  <ToneDropdown
                    selectedTone={selectedTone}
                    onToneSelect={setSelectedTone}
                    isDarkMode={isDarkMode}
                    className={`
                      w-full px-3 py-1.5 text-xs rounded-md
                      transition-colors duration-200
                      ${isDarkMode 
                        ? 'bg-[#1e1e1e] border-[#333333] text-slate-200' 
                        : 'bg-gray-50 border-gray-200 text-gray-900'
                      } border
                    `}
                  />
                </div>

                {/* Actions */}
                <div className="flex items-center gap-2">
                  <StyledActionButton
                    onClick={handleGenerateAndSave}
                    icon={PaperAirplaneIcon}
                    text="Generate"
                    loadingText="Generating..."
                    isDarkMode={isDarkMode}
                    disabled={isLoading || !postContent.trim()}
                    isLoading={isLoading}
                  />
                  <button
                    onClick={() => {
                      setPostContent('');
                      setCommentToReply('');
                    }}
                    className={`
                      p-1.5 rounded-md
                      transition-colors duration-200
                      ${isDarkMode 
                        ? 'text-slate-400 hover:text-slate-200 hover:bg-[#2a2a2a]' 
                        : 'text-gray-400 hover:text-gray-600 hover:bg-gray-100'
                      }
                    `}
                    title="Clear inputs"
                  >
                    <ClearIcon className="w-4 h-4" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Generated Comments Section */}
          <div className={`rounded-lg p-4 relative z-10`}>
            {generatedComments.length > 0 ? (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="space-y-3 mt-3 popup-scrollbar"
              >
                {generatedComments.map((comment, index) => (
                  <div
                    key={index}
                    className={`p-3.5 rounded-lg border ${
                      isDarkMode 
                        ? 'bg-[#1e1e1e] border-[#333333] hover:bg-[#202020]' 
                        : 'bg-white border-gray-100/50'
                    } relative group mb-6`}
                    onMouseEnter={() => setIsHovering(true)}
                    onMouseLeave={() => setIsHovering(false)}
                  >
                    <div className={`text-sm whitespace-pre-line ${isDarkMode ? 'text-white' : 'text-gray-600'}`}>
                      {comment.split('\n').map((paragraph, i) => (
                        <p key={i} className={i !== 0 ? 'mt-2' : ''}>
                          {paragraph}
                        </p>
                      ))}
                    </div>
                    <div className="absolute left-0 bottom-[-20px] right-0 flex justify-start">
                      <div className="max-w-full overflow-x-auto ml-2">
                        <div className={`action-buttons ${isHovering ? '' : 'hidden'} 
                          inline-flex rounded-full space-x-1 py-1 px-2 ${
                          isDarkMode 
                            ? 'bg-[#2a2a2a] border border-[#333333]' 
                            : 'bg-white/90 border border-gray-100/50 shadow-sm'
                        }`}>
                          <button
                            onClick={() => copyToClipboard(comment, index)}
                            className={`p-1 transition-colors duration-200 flex items-center group ${
                              isDarkMode ? 'text-slate-300 hover:text-white' : 'text-gray-500 hover:text-gray-700'
                            }`}
                            title={copiedComments[index] ? "Copied!" : "Copy to clipboard"}
                          >
                            {copiedComments[index] ? (
                              <ClipboardDocumentIconSolid className="h-3.5 w-3.5" />
                            ) : (
                              <ClipboardDocumentIcon className="h-3.5 w-3.5" />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </motion.div>
            ) : (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className={`flex flex-col items-center justify-center h-32 rounded-lg border border-dashed mt-3
                  ${isDarkMode ? 'border-[#333333] bg-[#1e1e1e]' : 'border-gray-200'}`}
              >
                <p className={`text-sm
                  ${isDarkMode ? 'text-slate-300' : 'text-gray-400'}`}>
                  Add content and press Generate
                </p>
              </motion.div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatInterfacePostComments; 