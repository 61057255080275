import api from '../api';
import imageCompression from 'browser-image-compression';

const AVATAR_CACHE_KEY = 'target_audience_avatars';
const AVATAR_CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours

const isUrlExpired = (timestamp) => {
  return Date.now() - timestamp > AVATAR_CACHE_DURATION;
};

const isValidUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

async function compressImage(imageUrl) {
  try {
    const response = await fetch(imageUrl);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const blob = await response.blob();
    
    const options = {
      maxSizeMB: 0.1, // Compress to 100KB
      maxWidthOrHeight: 400, // Max width or height of 400px
      useWebWorker: true
    };
    
    const compressedFile = await imageCompression(blob, options);
    return URL.createObjectURL(compressedFile);
  } catch (error) {
    console.error('Error compressing image:', error);
    return null;
  }
}

export const saveAvatarToCache = (audienceId, avatarUrl) => {
  const cache = JSON.parse(localStorage.getItem(AVATAR_CACHE_KEY) || '{}');
  
  if (avatarUrl.startsWith('blob:')) {
    fetch(avatarUrl)
      .then(res => res.blob())
      .then(blob => {
        const reader = new FileReader();
        reader.onloadend = function() {
          cache[audienceId] = {
            url: reader.result,
            timestamp: Date.now()
          };
          localStorage.setItem(AVATAR_CACHE_KEY, JSON.stringify(cache));
        };
        reader.readAsDataURL(blob);
      });
  } else {
    cache[audienceId] = {
      url: avatarUrl,
      timestamp: Date.now()
    };
    localStorage.setItem(AVATAR_CACHE_KEY, JSON.stringify(cache));
  }
};

export const getAvatarFromCache = (audienceId) => {
  const cache = JSON.parse(localStorage.getItem(AVATAR_CACHE_KEY) || '{}');
  const cachedAvatar = cache[audienceId];
  if (cachedAvatar && !isUrlExpired(cachedAvatar.timestamp)) {
    if (cachedAvatar.url.startsWith('data:')) {
      const byteString = atob(cachedAvatar.url.split(',')[1]);
      const mimeString = cachedAvatar.url.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], {type: mimeString});
      return URL.createObjectURL(blob);
    }
    return cachedAvatar.url;
  }
  return null;
};

export const fetchAvatarUrl = async (audienceId) => {
  try {
    const cachedUrl = getAvatarFromCache(audienceId);
    if (cachedUrl) {
      if (!isValidUrl(cachedUrl)) {
        console.error(`Invalid cached URL for audience ${audienceId}: ${cachedUrl}`);
        removeAvatarFromCache(audienceId);
      } else {
        return cachedUrl;
      }
    }

    const response = await api.get(`/api/target-audiences/${audienceId}/avatar`);
    if (!response.data || !response.data.signedUrl) {
      throw new Error('Invalid response from server');
    }
    const signedUrl = response.data.signedUrl;
    if (!isValidUrl(signedUrl)) {
      throw new Error(`Invalid signed URL received from server: ${signedUrl}`);
    }
    
    const compressedUrl = await compressImage(signedUrl);
    if (compressedUrl) {
      saveAvatarToCache(audienceId, compressedUrl);
      return compressedUrl;
    } else {
      saveAvatarToCache(audienceId, signedUrl);
      return signedUrl;
    }
  } catch (error) {
    console.error(`Error fetching avatar URL for audience ${audienceId}:`, error);
    return null;
  }
};

export const removeAvatarFromCache = (audienceId) => {
  const cache = JSON.parse(localStorage.getItem(AVATAR_CACHE_KEY) || '{}');
  delete cache[audienceId];
  localStorage.setItem(AVATAR_CACHE_KEY, JSON.stringify(cache));
};

export const refreshExpiredAvatarUrl = async (audienceId) => {
  try {
    console.log(`Refreshing avatar URL for audience ${audienceId} at ${new Date().toISOString()}`);
    const response = await api.get(`/api/target-audiences/${audienceId}/avatar`);
    if (!response.data || !response.data.signedUrl) {
      throw new Error('Invalid response from server');
    }
    const signedUrl = response.data.signedUrl;
    if (!isValidUrl(signedUrl)) {
      throw new Error(`Invalid signed URL received from server: ${signedUrl}`);
    }
    saveAvatarToCache(audienceId, signedUrl);
    console.log(`Successfully refreshed avatar URL for audience ${audienceId} at ${new Date().toISOString()}`);
    return signedUrl;
  } catch (error) {
    console.error(`Error refreshing avatar URL for audience ${audienceId}:`, error);
    return null;
  }
};

export const forceRefreshAvatarUrl = async (audienceId) => {
  console.log(`Force refreshing avatar URL for audience ${audienceId} at ${new Date().toISOString()}`);
  removeAvatarFromCache(audienceId);
  return await fetchAvatarUrl(audienceId);
};
