import React, { useState, useEffect } from 'react';
import { WifiOff, Wifi, X } from 'lucide-react';

const OfflineIndicator = () => {
  const [isOffline, setIsOffline] = useState(!navigator.onLine);
  const [showOnlineMessage, setShowOnlineMessage] = useState(false);
  const [isDismissed, setIsDismissed] = useState(false);

  useEffect(() => {
    const handleOnline = () => {
      setIsOffline(false);
      setShowOnlineMessage(true);
      setIsDismissed(false);
      setTimeout(() => setShowOnlineMessage(false), 3000);
    };
    const handleOffline = () => {
      setIsOffline(true);
      setIsDismissed(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const handleDismiss = () => {
    setIsDismissed(true);
    if (!isOffline) {
      setShowOnlineMessage(false);
    }
  };

  if ((!isOffline && !showOnlineMessage) || isDismissed) return null;

  return (
    <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 z-50">
      <div className={`rounded-lg shadow-lg p-3 flex items-center space-x-3 transition-all duration-300 ${isOffline ? 'bg-red-500' : 'bg-green-500'}`}>
        {isOffline ? (
          <>
            <WifiOff size={20} className="text-white" />
            <span className="text-white font-medium">You're offline</span>
          </>
        ) : (
          <>
            <Wifi size={20} className="text-white" />
            <span className="text-white font-medium">You're back online</span>
          </>
        )}
        <button
          onClick={handleDismiss}
          className="text-white hover:text-gray-200"
          aria-label="Close"
        >
          <X size={20} />
        </button>
      </div>
    </div>
  );
};

export default OfflineIndicator;