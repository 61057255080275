import React, { useState, useContext, useEffect, useMemo, useRef, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Transition } from '@headlessui/react';
import { AuthContext } from '../context/AuthContext';
import { useTheme } from '../context/ThemeContext';
import { useSearch } from '../context/SearchContext';
import Logo from '../logo.svg';
import { 
  Bars3Icon, 
  XMarkIcon, 
  ChevronRightIcon, 
  EllipsisHorizontalIcon, 
  UserCircleIcon, 
  QuestionMarkCircleIcon, 
  SunIcon, 
  MoonIcon, 
  ArrowRightOnRectangleIcon,
  SparklesIcon,
  MagnifyingGlassIcon,
  ChatBubbleLeftEllipsisIcon,
  ChatBubbleLeftRightIcon,
  BookOpenIcon,
  ChevronLeftIcon,
  Cog6ToothIcon,
  HomeIcon,
  MicrophoneIcon,
  ArchiveBoxIcon,
  BellIcon,
  UserIcon,
  CircleStackIcon,
  FolderIcon,
  CheckIcon,
  DocumentTextIcon,
  AcademicCapIcon,
  CpuChipIcon,
  LightBulbIcon,
  PencilSquareIcon,
  ClipboardDocumentListIcon,
  ChevronDownIcon,
  Squares2X2Icon,
  EyeIcon,
  GlobeAltIcon,
  CloudArrowDownIcon,
  BriefcaseIcon,
  UsersIcon,
  LinkIcon,
  HashtagIcon,
  ClockIcon,
  BookmarkIcon,
  PlusIcon,
  CalendarIcon,
  RocketLaunchIcon,
  ChartBarIcon,
  DocumentMagnifyingGlassIcon,
  PencilIcon,
  BoltIcon
} from '@heroicons/react/24/outline';
import { menuItems } from './SideBar';
import { fetchAvatarUrl, refreshAvatarUrl } from '../services/userService';
import { isUrlExpired } from '../utils/imageUtils';
import CreateButton from './CreateButton';
import '../components/scrollbar.css';
import PricingForGhost from './PricingForGhost';
import SearchTrigger from './GlobalSearch/SearchTrigger';
import TasksIcon from './ui/icons/TasksIcon';
import { createPortal } from 'react-dom';

const AVATAR_CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
const DEFAULT_AVATAR = 'https://ghostscompany.s3.amazonaws.com/userprofile-placeholder.png';

const CustomTooltip = ({ children, content, isDarkMode }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const buttonRef = useRef(null);

  const updateTooltipPosition = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setTooltipPosition({
        top: rect.top + window.scrollY + rect.height / 2,
        left: rect.right + window.scrollX + 10,
      });
    }
  };

  useEffect(() => {
    if (isHovered) {
      updateTooltipPosition();
      window.addEventListener('scroll', updateTooltipPosition);
      window.addEventListener('resize', updateTooltipPosition);
    }
    return () => {
      window.removeEventListener('scroll', updateTooltipPosition);
      window.removeEventListener('resize', updateTooltipPosition);
    };
  }, [isHovered]);

  return (
    <div
      className="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      ref={buttonRef}
    >
      {children}
      {isHovered &&
        createPortal(
          <div
            className={`fixed z-50 px-2 py-1 text-sm font-medium rounded-md shadow-lg whitespace-nowrap ${
              isDarkMode ? 'bg-slate-700 text-slate-100' : 'bg-slate-200 text-slate-900'
            }`}
            style={{
              top: `${tooltipPosition.top}px`,
              left: `${tooltipPosition.left}px`,
              transform: 'translateY(-50%)',
            }}
          >
            {content}
          </div>,
          document.body
        )}
    </div>
  );
};

const SparkleEffect = () => (
  <div className="absolute inset-0 overflow-hidden rounded-md">
    <div className="absolute inset-0 animate-shine bg-gradient-to-r from-transparent via-white/10 to-transparent -skew-x-45" />
  </div>
);

const MenuItemBadge = ({ text, isDarkMode }) => (
  <span className={`
    ml-2 px-1.5 py-0.5 text-[10px] font-medium rounded-full
    ${isDarkMode 
      ? 'bg-gradient-to-r from-blue-500/10 to-purple-500/10 text-blue-400 ring-1 ring-blue-500/20' 
      : 'bg-gradient-to-r from-blue-100 to-purple-100 text-blue-600'
    }
    animate-pulse
  `}>
    {text}
  </span>
);

const MinimalSearchBar = ({ isDarkMode }) => {
  const { openSearch } = useSearch();

  return (
    <div className="px-4 mb-4">
      <button
        onClick={openSearch}
        className={`
          w-full h-9
          flex items-center
          rounded-md transition-all duration-200
          ${isDarkMode 
            ? 'bg-[#121212] hover:bg-[#1E1E1E] text-gray-400' 
            : 'bg-slate-50 hover:bg-slate-100 text-gray-500'
          }
        `}
      >
        <div className="flex items-center w-full px-3">
          <MagnifyingGlassIcon className="w-3.5 h-3.5 text-gray-400" />
          <span className="ml-2 text-xs font-medium">Quick search...</span>
          <kbd className={`
            ml-auto hidden md:flex items-center gap-0.5 px-1.5 rounded text-[10px] font-medium
            ${isDarkMode 
              ? 'bg-[#242424] text-gray-500 ring-1 ring-[#333333]' 
              : 'bg-slate-100 text-gray-400 ring-1 ring-slate-200'
            }
          `}>
            <span className="text-[10px]">⌘</span>
            <span className="text-[10px]">K</span>
          </kbd>
        </div>
      </button>
    </div>
  );
};

const UpgradeButton = ({ isDarkMode, onClick }) => (
  <button
    onClick={onClick}
    className={`
      group relative w-full px-3 py-2.5 mb-2 rounded-lg
      transition-all duration-200
      ${isDarkMode
        ? 'bg-gradient-to-r from-purple-500/10 to-blue-500/10 hover:from-purple-500/20 hover:to-blue-500/20 text-white'
        : 'bg-gradient-to-r from-purple-50 to-blue-50 hover:from-purple-100 hover:to-blue-100 text-slate-900'
      }
      border
      ${isDarkMode ? 'border-purple-500/20' : 'border-purple-100'}
    `}
  >
    <div className="flex items-center justify-center gap-2">
      <BoltIcon className={`w-4 h-4 ${isDarkMode ? 'text-purple-400' : 'text-purple-500'}`} />
      <span className="text-xs font-medium">Upgrade to Pro</span>
    </div>
    <SparkleEffect />
  </button>
);

const UserAvatar = ({ user, avatarUrl, isLoading, isDarkMode }) => {
  const avatarClasses = "w-8 h-8 rounded-full flex items-center justify-center overflow-hidden";
  
  if (isLoading) {
    return (
      <div className={`${avatarClasses} ${isDarkMode ? 'bg-slate-700' : 'bg-slate-300'}`}>
        <span className="text-[8px]">...</span>
      </div>
    );
  }

  if (avatarUrl) {
    return (
      <div className={avatarClasses}>
        <img src={avatarUrl} alt={`${user?.firstName} ${user?.lastName}`} className="w-full h-full object-cover" />
      </div>
    );
  }

  const initials = user?.firstName && user?.lastName 
    ? `${user.firstName[0]}${user.lastName[0]}` 
    : user?.firstName?.[0] || 'U';

  return (
    <div className={`${avatarClasses} ${isDarkMode ? 'bg-slate-700' : 'bg-slate-500'} text-white text-xs font-medium`}>
      <span>{initials.toUpperCase()}</span>
    </div>
  );
};

const MobileBar = ({ onTimerClick, onFocusModeToggle, isFocusMode }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { user, logout } = useContext(AuthContext);
  const { isDarkMode, toggleDarkMode } = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [avatarUrl, setAvatarUrl] = useState('');
  const [isAvatarLoading, setIsAvatarLoading] = useState(true);
  const [expandedItems, setExpandedItems] = useState({});
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const userMenuRef = useRef(null);
  const [showPricingModal, setShowPricingModal] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const handleClick = (name, path) => {
    if (path === '/timer') {
      onTimerClick();
    } else if (path) {
      navigate(path);
    }
    setIsMenuOpen(false);
  };

  const toggleExpand = (title) => {
    setExpandedItems(prev => ({ ...prev, [title]: !prev[title] }));
  };

  const refreshAvatarUrlIfNeeded = async () => {
    if (user && user.avatar && avatarUrl) {
      if (isUrlExpired(avatarUrl)) {
        try {
          const newSignedUrl = await refreshAvatarUrl();
          setAvatarUrl(newSignedUrl);
          localStorage.setItem(`user_avatar_${user.id}`, JSON.stringify({
            url: newSignedUrl,
            timestamp: Date.now()
          }));
        } catch (error) {
          console.error('Error refreshing avatar URL:', error);
        }
      }
    }
  };

  useEffect(() => {
    const fetchUserAvatar = async () => {
      setIsAvatarLoading(true);
      if (user && user.avatar) {
        const cachedAvatar = localStorage.getItem(`user_avatar_${user.id}`);
        if (cachedAvatar) {
          const { url, timestamp } = JSON.parse(cachedAvatar);
          if (Date.now() - timestamp < AVATAR_CACHE_DURATION && !isUrlExpired(url)) {
            setAvatarUrl(url);
            setIsAvatarLoading(false);
            return;
          }
        }

        try {
          const signedUrl = await fetchAvatarUrl();
          setAvatarUrl(signedUrl);
          localStorage.setItem(`user_avatar_${user.id}`, JSON.stringify({
            url: signedUrl,
            timestamp: Date.now()
          }));
        } catch (error) {
          console.error('Error fetching user avatar URL:', error);
          setAvatarUrl(DEFAULT_AVATAR);
        }
      } else {
        setAvatarUrl(DEFAULT_AVATAR);
      }
      setIsAvatarLoading(false);
    };

    fetchUserAvatar();

    const refreshInterval = setInterval(refreshAvatarUrlIfNeeded, 5 * 60 * 1000);

    return () => clearInterval(refreshInterval);
  }, [user]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        setIsUserMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const MemoizedUserAvatar = useMemo(() => {
    return (
      <div className="w-8 h-8 rounded-full overflow-hidden">
        {isAvatarLoading ? (
          <div className="w-full h-full bg-gray-300 animate-pulse"></div>
        ) : (
          <img src={avatarUrl} alt={`${user?.firstName} ${user?.lastName}`} className="w-full h-full object-cover" />
        )}
      </div>
    );
  }, [user, avatarUrl, isAvatarLoading]);

  const renderMenuItem = (item) => {
    const hasChildren = item.children && item.children.length > 0;
    const iconClasses = 'w-5 h-5';
    const isActive = location.pathname === item.path;

    // Add special styling for highlighted items
    const getHighlightClasses = () => {
      if (item.highlight) {
        return isDarkMode
          ? 'bg-gradient-to-r from-blue-500/10 via-purple-500/10 to-blue-500/10 hover:from-blue-500/20 hover:via-purple-500/20 hover:to-blue-500/20 ring-1 ring-blue-500/20'
          : 'bg-gradient-to-r from-blue-50 via-purple-50 to-blue-50 hover:from-blue-100 hover:via-purple-100 hover:to-blue-100';
      }
      return '';
    };

    const itemClasses = `
      flex items-center justify-between w-full transition-colors duration-200 rounded-md 
      ${isActive
        ? isDarkMode
          ? 'bg-gradient-to-r from-[#2A2A2A] to-[#1E1E1E] text-white ring-1 ring-[#333333]'
          : 'bg-slate-200 text-slate-900'
        : isDarkMode
          ? 'text-gray-300 hover:bg-[#1E1E1E]'
          : 'text-slate-700 hover:bg-slate-100'
      } 
      p-2
      ${hasChildren || item.path ? 'cursor-pointer' : ''}
      ${getHighlightClasses()}
      ${item.sparkle ? 'relative overflow-hidden' : ''}
    `;

    return (
      <div key={item.title} className="mb-2">
        {hasChildren ? (
          <div>
            <button
              onClick={() => toggleExpand(item.title)}
              className={itemClasses}
            >
              <div className="flex items-center">
                <item.icon className={`${iconClasses} mr-3`} />
                <span className="text-sm">{item.title}</span>
                {item.badge && <MenuItemBadge text={item.badge} isDarkMode={isDarkMode} />}
              </div>
              <ChevronRightIcon 
                className={`w-4 h-4 transition-transform duration-200 ${
                  expandedItems[item.title] ? 'transform rotate-90' : ''
                } ${isDarkMode ? 'text-slate-500' : 'text-slate-400'}`} 
              />
              {item.sparkle && <SparkleEffect />}
            </button>
            {expandedItems[item.title] && (
              <div className="mt-1 space-y-1 ml-4">
                {item.children.map((child) => (
                  <button
                    key={child.name}
                    onClick={() => handleClick(child.name, child.path)}
                    className={`
                      block w-full text-left py-2 px-3 rounded-md text-sm
                      ${location.pathname === child.path
                        ? isDarkMode
                          ? 'bg-slate-700 text-white'
                          : 'bg-slate-200 text-slate-900'
                        : isDarkMode
                          ? 'text-slate-300 hover:bg-slate-800'
                          : 'text-slate-600 hover:bg-slate-200'
                      }
                    `}
                  >
                    <div className="flex items-center">
                      <child.icon className="w-5 h-5 mr-2" />
                      <span>{child.label}</span>
                      {child.soon && (
                        <span className={`ml-2 px-1.5 py-0.5 text-xs font-medium rounded-full ${
                          isDarkMode ? 'bg-slate-700 text-slate-300' : 'bg-slate-200 text-slate-700'
                        }`}>
                          Soon
                        </span>
                      )}
                    </div>
                  </button>
                ))}
              </div>
            )}
          </div>
        ) : (
          <button
            onClick={() => handleClick(item.title, item.path)}
            className={itemClasses}
          >
            <div className="flex items-center">
              <item.icon className={`${iconClasses} mr-3`} />
              <span className="text-sm">{item.title}</span>
              {item.badge && <MenuItemBadge text={item.badge} isDarkMode={isDarkMode} />}
            </div>
            {item.sparkle && <SparkleEffect />}
          </button>
        )}
      </div>
    );
  };

  const handleLogoClick = () => {
    navigate('/');
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  return (
    <>
      <div className={`fixed top-0 left-0 right-0 h-12 flex items-center justify-between px-4 z-50 
        backdrop-blur-md backdrop-saturate-150 ${
          isDarkMode 
            ? 'bg-[#121212]/90 text-slate-200 border-[#333333]' 
            : 'bg-slate-50/90 text-slate-800 border-slate-200'
        } border mx-4 mt-2 rounded-lg`}
      >
        <div className="flex items-center space-x-2 cursor-pointer" onClick={handleLogoClick}>
          <img 
            src={Logo} 
            alt="Ammmplify Logo" 
            className="h-6 w-auto"
          />
          <span className="font-medium text-sm">Ammmplify</span>
        </div>
        
        <div className="flex items-center">
          <button onClick={toggleDarkMode} className="p-1 mr-2">
            {isDarkMode ? (
              <SunIcon className="h-5 w-5" />
            ) : (
              <MoonIcon className="h-5 w-5" />
            )}
          </button>

          <button onClick={toggleMenu} className="p-1">
            {isMenuOpen ? (
              <XMarkIcon className="h-5 w-5" />
            ) : (
              <Bars3Icon className="h-5 w-5" />
            )}
          </button>
        </div>
      </div>

      <div className="h-16"></div>

      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
            className={`fixed top-16 left-0 right-0 bottom-0 flex flex-col ${
              isDarkMode 
                ? 'bg-[#121212] text-slate-200 border-[#333333]' 
                : 'bg-slate-50 text-slate-800 border-slate-200'
            } p-4 overflow-y-auto z-40 mx-4 rounded-lg border`}
          >
            <MinimalSearchBar isDarkMode={isDarkMode} />

            <div className="mb-4">
              <CreateButton
                isDarkMode={isDarkMode}
                isSidebarCollapsed={false}
                onClick={() => handleClick('Create', '/chat')}
                icon={SparklesIcon}
                text="Content Studio"
                className="w-full h-10 text-sm font-medium"
              />
            </div>

            <div className="flex-grow overflow-y-auto custom-scrollbar-overlay">
              <nav className="space-y-1">
                {menuItems.map(renderMenuItem)}
              </nav>
            </div>

            <UpgradeButton 
              isDarkMode={isDarkMode} 
              onClick={() => setShowPricingModal(true)}
            />

            <div className="mt-auto pt-4">
              <div className="flex items-center justify-between p-2">
                <div className="flex items-center">
                  {MemoizedUserAvatar}
                  <span className="ml-2 text-sm font-medium">
                    {user?.firstName} {user?.lastName}
                  </span>
                </div>
                <button
                  onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
                  className="p-1 rounded-md hover:bg-slate-200 dark:hover:bg-slate-700"
                >
                  <EllipsisHorizontalIcon className="w-5 h-5" />
                </button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {showPricingModal && (
        <PricingForGhost 
          onClose={() => setShowPricingModal(false)} 
          isDarkMode={isDarkMode}
        />
      )}

      <AnimatePresence>
        {isUserMenuOpen && (
          <motion.div
            ref={userMenuRef}
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ duration: 0.1 }}
            className={`fixed bottom-20 right-4 w-48 rounded-lg shadow-lg z-50 ${
              isDarkMode 
                ? 'bg-[#121212] border-[#333333]' 
                : 'bg-white border-slate-200'
            } border`}
          >
            <div className="py-1">
              <button
                onClick={toggleDarkMode}
                className={`w-full px-4 py-2 text-left text-sm flex items-center ${
                  isDarkMode 
                    ? 'text-slate-200 hover:bg-[#1E1E1E]' 
                    : 'text-slate-700 hover:bg-slate-100'
                }`}
              >
                {isDarkMode ? (
                  <>
                    <SunIcon className="w-4 h-4 mr-2" />
                    Light Mode
                  </>
                ) : (
                  <>
                    <MoonIcon className="w-4 h-4 mr-2" />
                    Dark Mode
                  </>
                )}
              </button>
              <button
                onClick={logout}
                className={`w-full px-4 py-2 text-left text-sm flex items-center ${
                  isDarkMode 
                    ? 'text-slate-200 hover:bg-[#1E1E1E]' 
                    : 'text-slate-700 hover:bg-slate-100'
                }`}
              >
                <ArrowRightOnRectangleIcon className="w-4 h-4 mr-2" />
                Sign Out
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default MobileBar;
