import React from 'react';
import { motion } from 'framer-motion';

const IdeasSkeleton = ({ isDarkMode, count = 5 }) => {
  return Array(count)
    .fill(0)
    .map((_, index) => (
      <motion.div
        key={index}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          duration: 0.3,
          delay: index * 0.1,
          ease: "easeOut"
        }}
        className={`mb-4 w-full ${
          isDarkMode 
            ? 'bg-[#151515] border-[#333333]' 
            : 'bg-white border-gray-200'
        } rounded-lg p-3 border`}
      >
        {/* Header */}
        <div className="flex items-center justify-between mb-3">
          <div className="flex items-center gap-2">
            <div className={`h-3 w-16 rounded ${
              isDarkMode 
                ? 'bg-[#1a1a1a] animate-pulse' 
                : 'bg-gray-200 animate-pulse'
            }`} />
            <div className={`h-2 w-2 rounded-full ${
              isDarkMode 
                ? 'bg-[#1a1a1a]' 
                : 'bg-gray-200'
            }`} />
            <div className={`h-3 w-12 rounded ${
              isDarkMode 
                ? 'bg-[#1a1a1a] animate-pulse' 
                : 'bg-gray-200 animate-pulse'
            }`} />
          </div>
          <div className="flex items-center gap-1.5">
            {[1, 2, 3].map((btn) => (
              <div
                key={btn}
                className={`h-6 w-6 rounded-lg ${
                  isDarkMode 
                    ? 'bg-[#1a1a1a]' 
                    : 'bg-gray-200'
                }`}
              />
            ))}
          </div>
        </div>

        {/* Title */}
        <div className={`h-5 w-48 rounded mb-3 ${
          isDarkMode 
            ? 'bg-[#1a1a1a] animate-pulse' 
            : 'bg-gray-200 animate-pulse'
        }`} />

        {/* Content lines */}
        <div className="space-y-2 mb-3">
          {[1, 2].map((line) => (
            <div
              key={line}
              className={`h-4 rounded ${
                isDarkMode 
                  ? 'bg-[#1a1a1a] animate-pulse' 
                  : 'bg-gray-200 animate-pulse'
              }`}
              style={{ width: `${Math.random() * 30 + 70}%` }}
            />
          ))}
        </div>

        {/* Tags */}
        <div className="flex gap-2">
          {[1, 2].map((tag) => (
            <div
              key={tag}
              className={`h-6 w-16 rounded-lg ${
                isDarkMode 
                  ? 'bg-[#1a1a1a]' 
                  : 'bg-gray-200'
              }`}
            />
          ))}
        </div>
      </motion.div>
    ));
};

export default IdeasSkeleton;