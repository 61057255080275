import React from 'react';
import { TrashIcon, ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline';

const truncate = (str, n) => {
  if (typeof str !== 'string') return '';
  return str.length > n ? `${str.slice(0, n).trim()}...` : str;
};

const ChatListItem = ({ chat, onClick, formatDate, onDelete, isDarkMode }) => {
  if (!chat) return null;

  const lastMessage = chat.messages && chat.messages.length > 0
    ? chat.messages[chat.messages.length - 1]
    : null;

  const messageCount = chat.messages ? chat.messages.length : 0;

  return (
    <div 
      className={`${
        isDarkMode 
          ? 'bg-[#121212]/95 hover:bg-[#1e1e1e]' 
          : 'bg-white hover:bg-gray-50'
      } rounded-lg shadow-sm p-3 mb-2 cursor-pointer transition-all duration-200 flex flex-col
      border ${
        isDarkMode 
          ? 'border-[#333333] hover:border-[#333333]' 
          : 'border-gray-200 hover:border-gray-300'
      }`}
      onClick={onClick}
    >
      <div className="flex justify-between items-start mb-1">
        <h3 className={`text-sm font-semibold ${
          isDarkMode ? 'text-slate-200' : 'text-gray-900'
        }`}>
          {truncate(chat.title || 'Untitled Chat', 90)}
        </h3>
        <div className="flex items-center">
          <span className={`text-xs mr-2 ${
            isDarkMode ? 'text-slate-400' : 'text-gray-400'
          }`}>
            {formatDate(chat.updatedAt || new Date())}
          </span>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
            className={`p-1 rounded-full transition-colors duration-200 ${
              isDarkMode 
                ? 'hover:bg-[#1e1e1e]' 
                : 'hover:bg-gray-200'
            }`}
          >
            <TrashIcon className={`h-3 w-3 ${
              isDarkMode ? 'text-slate-400' : 'text-gray-500'
            }`} />
          </button>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <p className={`text-xs ${
          isDarkMode ? 'text-slate-400' : 'text-gray-500'
        } flex-grow`}>
          {truncate(lastMessage?.content || 'No messages', 160)}
        </p>
        <div className={`flex items-center ml-2 ${
          isDarkMode ? 'text-slate-400' : 'text-gray-500'
        }`}>
          <ChatBubbleLeftRightIcon className="h-3 w-3 mr-1" />
          <span className="text-xs">{messageCount}</span>
        </div>
      </div>
    </div>
  );
};

export default ChatListItem;