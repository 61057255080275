import React, { useEffect, useState } from 'react'
import { X, CheckCircle, AlertTriangle, Info } from 'lucide-react'
import { useTheme } from '../context/ThemeContext'

export default function Notification({ 
  id, 
  title, 
  message, 
  type = 'info', 
  onClose, 
  duration = 5000, 
  action,
  showLoader = true,
  currentIndex,
  totalCount
}) {
  const [isVisible, setIsVisible] = useState(true)
  const { isDarkMode } = useTheme()

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false)
    }, duration - 300)

    const closeTimer = setTimeout(() => {
      onClose(id)
    }, duration)

    return () => {
      clearTimeout(timer)
      clearTimeout(closeTimer)
    }
  }, [onClose, duration, id])

  const getIcon = () => {
    const iconClass = "w-4 h-4 text-current";
    switch (type) {
      case 'success':
        return <CheckCircle className={iconClass} />;
      case 'error':
        return <AlertTriangle className={iconClass} />;
      default:
        return <Info className={iconClass} />;
    }
  }

  const getTypeStyles = () => {
    switch (type) {
      case 'success':
        return isDarkMode
          ? 'bg-green-800 bg-opacity-90 text-green-100 border border-green-700'
          : 'bg-green-50 text-green-800 border border-green-200';
      case 'error':
        return isDarkMode
          ? 'bg-red-800 bg-opacity-90 text-red-100 border border-red-700'
          : 'bg-red-50 text-red-800 border border-red-200';
      default: // info
        return isDarkMode
          ? 'bg-blue-800 bg-opacity-90 text-blue-100 border border-blue-700'
          : 'bg-blue-50 text-blue-800 border border-blue-200';
    }
  };

  return (
    <div
      className={`fixed top-4 right-4 max-w-xs w-full rounded-md shadow-lg pointer-events-auto overflow-hidden z-50 transform transition-all duration-300 ease-in-out ${
        isVisible ? 'translate-y-0 opacity-100 scale-100' : 'translate-y-[-1rem] opacity-0 scale-95'
      } ${getTypeStyles()}`}
    >
      <div className="pt-2.5 px-2.5 flex items-start">
        <div className="flex-shrink-0 mt-0.5">{getIcon()}</div>
        <div className="ml-2 flex-1 min-w-0">
          <p className="text-xs font-medium">{title}</p>
          <p className="mt-0.5 text-xs opacity-80">{message}</p>
          {totalCount > 1 && (
            <p className="mt-0.5 text-[10px] opacity-60">
              Notification {currentIndex} of {totalCount}
            </p>
          )}
          {action && (
            <div className="mt-1">
              <a
                href={action.url}
                className="text-xs font-medium underline"
              >
                {action.text} <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          )}
        </div>
        <button
          className={`ml-1.5 flex-shrink-0 text-current hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-current rounded-full p-0.5 transition-colors duration-200 ${
            isDarkMode ? 'hover:bg-opacity-10 hover:bg-white' : 'hover:bg-opacity-10 hover:bg-black'
          }`}
          onClick={() => onClose(id)}
        >
          <span className="sr-only">Close</span>
          <X className="h-3.5 w-3.5" />
        </button>
      </div>
      {showLoader && (
        <div className="flex justify-center items-center p-1">
          <div className="loader">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
          </div>
        </div>
      )}
    </div>
  )
}