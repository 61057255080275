import api from '../api';
import imageCompression from 'browser-image-compression';

const AVATAR_TYPES = {
  PERSONA: 'persona_avatars',
  AUDIENCE: 'audience_avatars'
};

const AVATAR_CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours for all avatars

const isUrlExpired = (timestamp) => {
  return Date.now() - timestamp > AVATAR_CACHE_DURATION;
};

async function compressImage(imageUrl) {
  try {
    console.log('Attempting to compress image:', imageUrl);
    const response = await fetch(imageUrl);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const blob = await response.blob();
    
    const options = {
      maxSizeMB: 0.1,
      maxWidthOrHeight: 256,
      useWebWorker: true
    };
    
    const compressedFile = await imageCompression(blob, options);
    const compressedUrl = URL.createObjectURL(compressedFile);
    console.log('Image compressed successfully');
    return compressedUrl;
  } catch (error) {
    console.error('Error compressing image:', error);
    console.log('Falling back to original URL:', imageUrl);
    return imageUrl;
  }
}

// Unified cache management functions
const saveToAvatarCache = (id, url, cacheKey) => {
  const cache = JSON.parse(localStorage.getItem(cacheKey) || '{}');
  cache[id] = {
    url,
    timestamp: Date.now()
  };
  localStorage.setItem(cacheKey, JSON.stringify(cache));
};

const getFromAvatarCache = (id, cacheKey) => {
  const cache = JSON.parse(localStorage.getItem(cacheKey) || '{}');
  const cached = cache[id];
  
  if (cached && !isUrlExpired(cached.timestamp)) {
    return cached.url;
  }
  return null;
};

// Maintain backward compatibility while using new system
export const saveAvatarToCache = (personaId, avatarUrl) => {
  saveToAvatarCache(personaId, avatarUrl, AVATAR_TYPES.PERSONA);
};

export const getAvatarFromCache = (personaId) => {
  return getFromAvatarCache(personaId, AVATAR_TYPES.PERSONA);
};

export const fetchAvatarUrl = async (id, forceRefresh = false) => {
  return handleAvatarUrl(id, 'persona', forceRefresh);
};

export const forceRefreshAvatarUrl = async (id) => {
  return handleAvatarUrl(id, 'persona', true);
};

export const refreshExpiredAvatarUrl = async (id) => {
  return handleAvatarUrl(id, 'persona', true);
};

export const refreshPersonaAvatarUrl = async (id) => {
  return handleAvatarUrl(id, 'persona', true);
};

// New unified avatar handling function
export const handleAvatarUrl = async (id, type, forceRefresh = false) => {
  const cacheKey = type === 'persona' ? AVATAR_TYPES.PERSONA : AVATAR_TYPES.AUDIENCE;
  
  // Check cache unless force refresh
  if (!forceRefresh) {
    const cachedUrl = getFromAvatarCache(id, cacheKey);
    if (cachedUrl) return cachedUrl;
  }

  // Fetch new URL
  try {
    const endpoint = type === 'persona' 
      ? `/api/personas/${id}/avatar`
      : `/api/target-audiences/${id}/avatar`;
    
    const response = await api.get(endpoint);
    const signedUrl = response.data.signedUrl;

    if (!signedUrl) throw new Error('No signed URL received');

    // Compress and cache
    const compressedUrl = await compressImage(signedUrl);
    saveToAvatarCache(id, compressedUrl, cacheKey);

    return compressedUrl;
  } catch (error) {
    console.error(`Error handling ${type} avatar:`, error);
    return null;
  }
};

