import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { CanvasGridIcon } from './icons/CanvasGridIcon';
import CanvasItemMenu from './CanvasItemMenu';
import { useTheme } from '../context/ThemeContext';

const getItemStyles = (viewMode, isDarkMode) => {
  const baseStyles = `
    transition-all duration-200 ease-out
    ${isDarkMode 
      ? 'hover:bg-[#1e1e1e] border-[#333333]' 
      : 'hover:bg-gray-50 border-gray-200'
    }
    cursor-pointer
    w-full min-w-0 max-w-full
  `;

  if (viewMode === 'grid') {
    return `${baseStyles} 
      group relative
      border rounded-lg hover:shadow-sm
      transform hover:-translate-y-0.5
      h-[60px]
      ${isDarkMode 
        ? 'bg-[#151515]' 
        : 'bg-white'}
      overflow-hidden
    `;
  }

  return `${baseStyles} 
    flex items-center p-2.5 rounded-lg border
    hover:shadow-sm
    ${isDarkMode 
      ? 'bg-[#151515]' 
      : 'bg-white'}
    overflow-hidden
  `;
};

const CanvasItem = ({
  item,
  viewMode,
  onSelect,
  onRename,
  onDelete,
  isEditing,
  editingTitle,
  onEditingChange,
  onTitleChange,
  onEditComplete,
  isSelected,
  isSelectionMode,
  onLongPress,
}) => {
  const { isDarkMode } = useTheme();
  const editInputRef = React.useRef(null);
  const longPressRef = useRef(null);
  const touchStartRef = useRef(null);

  const handleMouseDown = () => {
    longPressRef.current = setTimeout(() => {
      onLongPress();
    }, 500); // 500ms for long press
  };

  const handleMouseUp = () => {
    if (longPressRef.current) {
      clearTimeout(longPressRef.current);
    }
  };

  const handleTouchStart = (e) => {
    // Don't start long press if clicking menu or menu content
    if (e.target.closest('[data-menu-trigger]') || e.target.closest('[data-menu-content]')) {
      return;
    }

    touchStartRef.current = {
      time: Date.now(),
      x: e.touches[0].clientX,
      y: e.touches[0].clientY
    };
    
    longPressRef.current = setTimeout(() => {
      onLongPress();
    }, 500);
  };

  const handleTouchEnd = (e) => {
    // Don't handle canvas selection if clicking menu or menu content
    if (e.target.closest('[data-menu-trigger]') || e.target.closest('[data-menu-content]')) {
      return;
    }

    if (longPressRef.current) {
      clearTimeout(longPressRef.current);
    }

    // Only trigger select if it was a short tap (not a long press)
    // and if we're not in selection mode
    if (touchStartRef.current && 
        Date.now() - touchStartRef.current.time < 500 && 
        !isSelectionMode) {
      onSelect(item);
    }
    
    touchStartRef.current = null;
  };

  const handleTouchMove = (e) => {
    if (longPressRef.current) {
      clearTimeout(longPressRef.current);
    }
    touchStartRef.current = null;
  };

  const handleClick = (e) => {
    // Don't handle canvas selection if clicking menu or menu content
    if (e.target.closest('[data-menu-trigger]') || e.target.closest('[data-menu-content]')) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    
    if (!isSelectionMode) {
      onSelect(item);
    }
  };

  useEffect(() => {
    return () => {
      if (longPressRef.current) {
        clearTimeout(longPressRef.current);
      }
    };
  }, []);

  const renderTitle = () => {
    if (isEditing) {
      return (
        <input
          ref={editInputRef}
          type="text"
          value={editingTitle}
          onChange={(e) => onTitleChange(e.target.value)}
          onKeyDown={(e) => {
            e.stopPropagation();
            if (e.key === 'Enter') onEditComplete(item.id, editingTitle);
            if (e.key === 'Escape') onEditingChange(null);
          }}
          onBlur={() => {
            editingTitle.trim() !== item.title 
              ? onEditComplete(item.id, editingTitle)
              : onEditingChange(null);
          }}
          className={`
            w-full bg-transparent border-none outline-none text-[11px] font-medium
            focus:ring-0 rounded-sm py-0.5
            ${isDarkMode 
              ? 'text-slate-200 bg-[#1a1a1a] focus:bg-[#202020]' 
              : 'text-slate-900 bg-slate-50 focus:bg-white'
            }
          `}
          autoFocus
          onClick={(e) => e.stopPropagation()}
        />
      );
    }

    return (
      <h3 
        className={`
          text-[11px] font-medium truncate
          ${isDarkMode ? 'text-slate-200' : 'text-slate-900'}
        `}
        onDoubleClick={(e) => {
          e.stopPropagation();
          onEditingChange(item.id);
          onTitleChange(item.title);
        }}
      >
        {item.title}
      </h3>
    );
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ scale: 1.01 }}
      transition={{ duration: 0.2 }}
      className={`
        group relative flex items-center
        px-3 py-2.5
        rounded-lg md:rounded-xl border
        transition-all duration-200
        md:backdrop-blur-sm
        cursor-pointer
        ${isDarkMode 
          ? 'bg-[#151515] border-[#333333] md:border-[#333333] md:hover:bg-[#1e1e1e]' 
          : 'bg-white border-slate-200 md:border-slate-200/80 md:hover:bg-slate-50/80'
        }
        ${getItemStyles(viewMode, isDarkMode)}
        ${isSelected 
          ? isDarkMode 
            ? 'bg-[#1e1e1e] border-[#333333]' 
            : 'bg-slate-100/80 border-slate-300'
          : ''
        }
      `}
      onClick={handleClick}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <div className={`
        flex items-center justify-between
        w-full min-w-0
        ${isSelectionMode ? 'pl-0' : 'pl-0'}
      `}>
        <div className={`
          flex-1 min-w-0
          flex items-center gap-1.5
        `}>
          <CanvasGridIcon 
            className={`
              w-5 h-5 shrink-0
              transition-colors duration-200
              ${isDarkMode 
                ? 'text-slate-400 md:text-slate-500 md:group-hover:text-slate-400' 
                : 'text-slate-500 md:text-slate-400 md:group-hover:text-slate-500'
              }
            `}
          />
          <div className="flex-1 min-w-0">
            {renderTitle()}
          </div>
        </div>

        <CanvasItemMenu
          item={item}
          onRename={onRename}
          onDelete={onDelete}
          isDarkMode={isDarkMode}
          onToggleSelect={(item) => {
            if (isSelectionMode) {
              onSelect(item);
            }
          }}
          isSelected={isSelected}
          isSelectionMode={isSelectionMode}
          onEnterSelectionMode={() => {
            onLongPress();
            return false;
          }}
        >
          <div className={`
            flex items-center gap-2 
            pl-2 pr-8 py-1 rounded-full
            flex-shrink-0 
            text-[10px] font-medium tracking-wide
            ${isDarkMode 
              ? 'bg-[#1a1a1a] group-hover:bg-[#202020]' 
              : 'bg-slate-100/60 group-hover:bg-slate-100'
            }
            transition-colors duration-200
            relative
          `}>
            <span className={`
              transition-colors duration-200 whitespace-nowrap
              ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
            `}>
              {item.posts?.length || 0} posts
            </span>
            <span className={isDarkMode ? 'text-[#333333]' : 'text-slate-400'}>•</span>
            <span className={`
              transition-colors duration-200 whitespace-nowrap
              ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
            `}>
              {getTimeAgo(item.lastEdited)}
            </span>
          </div>
        </CanvasItemMenu>
      </div>
    </motion.div>
  );
};

const getTimeAgo = (date) => {
  const minutes = Math.floor((new Date() - new Date(date)) / 60000);
  if (minutes < 1) return 'now';
  if (minutes < 60) return `${minutes}m`;
  const hours = Math.floor(minutes / 60);
  if (hours < 24) return `${hours}h`;
  const days = Math.floor(hours / 24);
  if (days < 7) return `${days}d`;
  const weeks = Math.floor(days / 7);
  if (weeks < 4) return `${weeks}w`;
  const months = Math.floor(days / 30);
  if (months < 12) return `${months}mo`;
  return `${Math.floor(months / 12)}y`;
};

CanvasItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    lastEdited: PropTypes.string.isRequired,
    posts: PropTypes.array
  }).isRequired,
  viewMode: PropTypes.oneOf(['grid', 'list']).isRequired,
  onSelect: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  editingTitle: PropTypes.string.isRequired,
  onEditingChange: PropTypes.func.isRequired,
  onTitleChange: PropTypes.func.isRequired,
  onEditComplete: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isSelectionMode: PropTypes.bool.isRequired,
  onLongPress: PropTypes.func.isRequired,
};

export default CanvasItem; 