import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../../../context/ThemeContext';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isDarkMode } = useTheme();

  const glassEffectStyles = `
    ${isDarkMode
      ? 'bg-gradient-to-b from-[#151515]/60 to-[#121212]/40'
      : 'bg-gradient-to-b from-white/60 to-white/40'
    }
  `;

  const containerStyles = `
    ${isDarkMode 
      ? 'bg-[#151515]/30 shadow-lg shadow-[#121212]/20 border border-white/[0.05]' 
      : 'bg-slate-50/30 shadow-lg shadow-slate-200/20 border border-black/[0.03]'
    }
  `;

  return (
    <motion.div
      className={`
        relative rounded-lg overflow-hidden
        ${containerStyles}
        ${isOpen ? 'ring-1 ring-blue-500/20' : ''}
      `}
      whileHover={{ scale: 1.002 }}
      transition={{ duration: 0.2 }}
    >
      {/* Glass effect overlay */}
      <div className={`
        absolute inset-0
        backdrop-blur-xl backdrop-saturate-150
        ${glassEffectStyles}
      `} />

      <div className="relative">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className={`
            w-full flex items-center justify-between group
            px-5 py-4 
            ${isOpen ? 'pb-2' : ''}
          `}
        >
          <span className={`
            text-sm font-medium text-left pr-4
            transition-colors duration-200
            ${isDarkMode 
              ? isOpen ? 'text-blue-400' : 'text-white group-hover:text-blue-400' 
              : isOpen ? 'text-blue-600' : 'text-slate-900 group-hover:text-blue-600'
            }
          `}>
            {question}
          </span>
          <motion.div
            animate={{ rotate: isOpen ? 180 : 0 }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
            className={`
              flex-shrink-0 rounded-full
              transition-colors duration-200
              ${isDarkMode 
                ? isOpen ? 'text-blue-400' : 'text-slate-400 group-hover:text-blue-400' 
                : isOpen ? 'text-blue-600' : 'text-slate-600 group-hover:text-blue-600'
              }
            `}
          >
            <ChevronDownIcon className="h-4 w-4" />
          </motion.div>
        </button>

        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.2, ease: "easeInOut" }}
              className="overflow-hidden"
            >
              <div className="px-5 pb-4">
                <p className={`
                  text-sm leading-relaxed
                  ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
                `}>
                  {answer}
                </p>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {/* Floating effect shadows */}
      <div className={`
        absolute inset-0
        rounded-lg 
        -z-10
        blur-2xl
        opacity-30
        transition-opacity duration-200
        ${isOpen ? 'opacity-50' : 'opacity-30'}
        ${isDarkMode
          ? 'bg-gradient-to-r from-blue-500/5 via-purple-500/5 to-pink-500/5'
          : 'bg-gradient-to-r from-blue-500/10 via-purple-500/10 to-pink-500/10'
        }
      `} />
    </motion.div>
  );
};

export default FAQItem; 