import React, { useState, useEffect } from 'react';
import { useTimer } from '../context/TimerContext';
import { useTheme } from '../context/ThemeContext';
import { ClockIcon, XMarkIcon, PauseIcon, PlayIcon, ArrowsPointingInIcon, ArrowsPointingOutIcon } from '@heroicons/react/24/outline';
import Draggable from 'react-draggable';

const formatTime = (seconds) => {
  if (isNaN(seconds)) return { hours: "00", minutes: "00", seconds: "00" };
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return {
    hours: hours.toString().padStart(2, '0'),
    minutes: minutes.toString().padStart(2, '0'),
    seconds: remainingSeconds.toString().padStart(2, '0')
  };
};

function MinimizedTimerBubble({ time, isRunning, handleMinimizeMaximize, isDarkMode }) {
  const { hours, minutes, seconds } = formatTime(time);

  return (
    <div className={`fixed ${isDarkMode ? 'bg-gradient-to-br from-slate-800 to-slate-900' : 'bg-gradient-to-br from-white to-gray-50'} rounded-full shadow-lg p-2 flex items-center space-x-2 cursor-move z-50 border ${isDarkMode ? 'border-slate-700' : 'border-gray-200'} ${isDarkMode ? 'hover:border-slate-600' : 'hover:border-gray-300'}`}>
      <ClockIcon className={`w-4 h-4 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
      <span className={`text-sm font-semibold ${isDarkMode ? 'text-slate-200' : 'text-gray-800'}`}>
        {hours !== "00" && `${hours}:`}{minutes}:{seconds}
      </span>
      <button onClick={handleMinimizeMaximize} className={`${isDarkMode ? 'text-slate-400 hover:text-slate-200' : 'text-gray-400 hover:text-gray-600'}`}>
        <ArrowsPointingOutIcon className="w-4 h-4" />
      </button>
    </div>
  );
}

function TimerBubble() {
  const { 
    activeTimer, 
    pauseTimer, 
    resumeTimer,
    toggleTimerVisibility, 
    isTimerBubbleVisible,
    isRunning,
    time,
  } = useTimer();
  const { isDarkMode } = useTheme();

  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isMinimized, setIsMinimized] = useState(false);

  useEffect(() => {
    setPosition({ x: 16, y: 690 });
  }, []);

  const handleClose = () => {
    toggleTimerVisibility();
  };

  const handlePauseResumeTimer = () => {
    if (isRunning) {
      pauseTimer();
    } else if (time > 0) {
      resumeTimer();
    }
  };

  const handleMinimizeMaximize = () => {
    setIsMinimized(!isMinimized);
  };

  const handleDragStop = (e, data) => {
    const newPosition = { x: data.x, y: data.y };
    setPosition(newPosition);
    localStorage.setItem('timerBubblePosition', JSON.stringify(newPosition));
  };

  if (!isTimerBubbleVisible) {
    return null;
  }

  const { hours, minutes, seconds } = formatTime(time);

  return (
    <Draggable
      position={position}
      onStop={handleDragStop}
      bounds="parent"
    >
      {isMinimized ? (
        <MinimizedTimerBubble
          time={time}
          isRunning={isRunning}
          handleMinimizeMaximize={handleMinimizeMaximize}
          isDarkMode={isDarkMode}
        />
      ) : (
        <div className={`fixed ${isDarkMode ? 'bg-gradient-to-br from-slate-800 to-slate-900' : 'bg-gradient-to-br from-white to-gray-50'} rounded-xl shadow-lg p-6 flex flex-col items-center space-y-2 w-[225px] cursor-move z-50 border ${isDarkMode ? 'border-slate-700' : 'border-gray-200'} ${isDarkMode ? 'hover:border-slate-600' : 'hover:border-gray-300'}`}>
          <div className="flex justify-between items-center w-full mb-2">
            <div className="flex items-center space-x-2">
              <ClockIcon className={`w-4 h-4 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
              <span className={`text-sm font-semibold ${isDarkMode ? 'text-slate-200' : 'text-gray-800'}`}>Timer</span>
            </div>
            <button onClick={handleClose} className={`${isDarkMode ? 'text-slate-400 hover:text-slate-200' : 'text-gray-400 hover:text-gray-600'}`}>
              <XMarkIcon className="w-4 h-4" />
            </button>
          </div>
          <span className={`text-3xl font-bold ${isDarkMode ? 'text-slate-200' : 'text-gray-800'}`}>
            <span className={hours === "00" ? (isDarkMode ? 'text-slate-500' : 'text-gray-300') : ''}>{hours}</span>
            <span className={isDarkMode ? 'text-slate-500' : 'text-gray-300'}>:</span>
            <span className={minutes === "00" && hours === "00" ? (isDarkMode ? 'text-slate-500' : 'text-gray-300') : ''}>{minutes}</span>
            <span className={isDarkMode ? 'text-slate-500' : 'text-gray-300'}>:</span>
            <span className={seconds === "00" && minutes === "00" && hours === "00" ? (isDarkMode ? 'text-slate-500' : 'text-gray-300') : ''}>{seconds}</span>
          </span>
          <div className="flex space-x-2 mt-2">
            <button onClick={handlePauseResumeTimer} className={`${isDarkMode ? 'bg-slate-700 hover:bg-slate-600 text-slate-200' : 'bg-gray-100 hover:bg-gray-200 text-gray-600'} px-3 py-1 rounded-md text-sm flex items-center`}>
              {isRunning ? (
                <>
                  <PauseIcon className="w-4 h-4 mr-1" /> Pause
                </>
              ) : (
                <>
                  <PlayIcon className="w-4 h-4 mr-1" /> Resume
                </>
              )}
            </button>
            <button onClick={handleMinimizeMaximize} className={`${isDarkMode ? 'bg-slate-700 hover:bg-slate-600 text-slate-200' : 'bg-gray-100 hover:bg-gray-200 text-gray-600'} px-3 py-1 rounded-md text-sm flex items-center`}>
              <ArrowsPointingInIcon className="w-4 h-4 mr-1" /> Minimize
            </button>
          </div>
        </div>
      )}
    </Draggable>
  );
}

export default TimerBubble;