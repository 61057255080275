import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { formatFileSize } from './ItemTooltip';
import { useTheme } from '../context/ThemeContext';

const FileInfoPopup = ({ file, onClose }) => {
  const { isDarkMode } = useTheme();
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className={`${
        isDarkMode ? 'bg-[#121212] text-slate-200' : 'bg-white text-gray-800'
      } rounded-lg p-6 w-96 max-w-full border ${
        isDarkMode ? 'border-[#333333]' : 'border-gray-200'
      }`}>
        <div className="flex justify-between items-center mb-4">
          <h2 className={`text-xl font-semibold ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>
            File Information
          </h2>
          <button
            onClick={onClose}
            className={`p-1 rounded-full ${
              isDarkMode ? 'hover:bg-[#1e1e1e] text-slate-400' : 'hover:bg-gray-100 text-gray-500'
            } transition-colors duration-200`}
          >
            <XMarkIcon className="w-5 h-5" />
          </button>
        </div>
        <div className="space-y-3">
          <div className={`flex justify-between ${isDarkMode ? 'text-slate-200' : 'text-gray-800'}`}>
            <span className="font-medium">Name:</span>
            <span className={isDarkMode ? 'text-slate-400' : 'text-gray-600'}>{file.name}</span>
          </div>
          <div className={`flex justify-between ${isDarkMode ? 'text-slate-200' : 'text-gray-800'}`}>
            <span className="font-medium">Size:</span>
            <span className={isDarkMode ? 'text-slate-400' : 'text-gray-600'}>{formatFileSize(file.size)}</span>
          </div>
          <div className={`flex justify-between ${isDarkMode ? 'text-slate-200' : 'text-gray-800'}`}>
            <span className="font-medium">Type:</span>
            <span className={isDarkMode ? 'text-slate-400' : 'text-gray-600'}>{file.type || 'Unknown'}</span>
          </div>
          <div className={`flex justify-between ${isDarkMode ? 'text-slate-200' : 'text-gray-800'}`}>
            <span className="font-medium">Created:</span>
            <span className={isDarkMode ? 'text-slate-400' : 'text-gray-600'}>
              {new Date(file.createdAt).toLocaleString()}
            </span>
          </div>
          <div className={`flex justify-between ${isDarkMode ? 'text-slate-200' : 'text-gray-800'}`}>
            <span className="font-medium">Last Modified:</span>
            <span className={isDarkMode ? 'text-slate-400' : 'text-gray-600'}>
              {new Date(file.updatedAt).toLocaleString()}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileInfoPopup;