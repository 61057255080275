import React, { useState, useEffect, useCallback, useRef } from 'react';
import StickyNote from './StickyNote';
import EmptyState from './EmptyState';
import { PlusIcon, MagnifyingGlassIcon, XMarkIcon, LightBulbIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../../context/ThemeContext';
import { fetchIdeas, createIdea, updateIdea, deleteIdea, addIdeaTag, removeIdeaTag, editIdeaTag, deleteIdeaTag, getIdeaTagCounts, fetchIdeaTags } from '../../api';
import './CustomQuill.css';
import '../scrollbar.css';
import IdeasTagList from './IdeasTagList';
import IdeasTagManager from './IdeasTagManager';
import IdeasSkeleton from '../IdeasSkeleton';
import { useNavigate } from 'react-router-dom';
import { useRecentActivities } from '../../context/RecentActivitiesContext';
import { socket } from '../../socket';

const LIGHT_MODE_COLOR = '#FFFFFF';
const DARK_MODE_COLOR = '#1E293B';

const TopBar = ({ searchTerm, setSearchTerm, isDarkMode, isSearchExpanded, setIsSearchExpanded, onAddNote }) => (
  <div className={`${isDarkMode ? 'bg-[#121212] border-[#333333]' : 'bg-white border-slate-200'} bg-opacity-90 h-10 flex-shrink-0 flex items-center justify-between px-4 py-2 w-full border-b border-opacity-50`}>
    {/* Left section */}
    <div className="flex items-center">
      <LightBulbIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'} mr-2`} />
      <h2 className={`text-sm font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-800'}`}>Text Notes</h2>
    </div>

    {/* Right section */}
    <div className="flex items-center">
      <ExpandableSearchBar
        isDarkMode={isDarkMode}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        isExpanded={isSearchExpanded}
        setIsExpanded={setIsSearchExpanded}
      />
      <button 
        onClick={onAddNote}
        className={`flex items-center px-2 py-1 rounded-md transition-colors duration-200 text-sm ml-2
                    ${isDarkMode 
                      ? 'bg-[#1a1a1a] hover:bg-[#202020] text-slate-200' 
                      : 'bg-gray-100 hover:bg-gray-200 text-gray-700'}`}
        title="Add New Note"
      >
        <PlusIcon className={`w-4 h-4 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'} mr-1`} />
        Add New Note
      </button>
    </div>
  </div>
);

const ExpandableSearchBar = ({ isDarkMode, searchTerm, setSearchTerm, isExpanded, setIsExpanded }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (isExpanded) {
      inputRef.current?.focus();
    }
  }, [isExpanded]);

  return (
    <div className="relative flex items-center">
      <div className={`relative flex items-center ${isExpanded ? 'w-40' : 'w-8'} transition-all duration-200`}>
        {/* Search Input */}
        <input
          ref={inputRef}
          type="text"
          placeholder="Search ideas..."
          className={`absolute left-0 ${
            isExpanded 
              ? 'w-40 pl-8 opacity-100 pointer-events-auto' 
              : 'w-0 opacity-0 pointer-events-none'
          } pr-8 py-1 text-xs border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-200
          ${isDarkMode 
            ? 'bg-[#151515] text-slate-200 border-[#333333]' 
            : 'bg-white text-gray-900 border-gray-300'}`}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        
        {/* Search Icon Button */}
        <button
          className={`relative z-10 p-1.5 rounded-md transition-all duration-150 ${
            isDarkMode 
              ? 'hover:bg-slate-700 active:bg-slate-600' 
              : 'hover:bg-gray-100 active:bg-gray-200'
          }`}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <MagnifyingGlassIcon 
            className={`h-4 w-4 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} 
          />
        </button>

        {/* Close Button */}
        {isExpanded && (
          <button
            onClick={() => {
              setIsExpanded(false);
              setSearchTerm('');
            }}
            className={`absolute right-2 top-1/2 transform -translate-y-1/2 p-0.5 rounded-full
              ${isDarkMode 
                ? 'hover:bg-slate-600' 
                : 'hover:bg-gray-200'}`}
          >
            <XMarkIcon className={`w-3 h-3 ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`} />
          </button>
        )}
      </div>
    </div>
  );
};

const renderEmptyState = (notes, filteredNotes, isDarkMode, onAddNote, searchTerm) => {
  if (notes.length === 0) {
    return (
      <div className={`flex flex-col items-center justify-center h-full ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}`}>
        <LightBulbIcon className="w-16 h-16 mb-4" />
        <p className="text-xl font-semibold mb-2">No ideas yet</p>
        <p className="text-sm">Start by adding your first idea</p>
      </div>
    );
  } else if (filteredNotes.length === 0) {
    return (
      <div className={`flex flex-col items-center justify-center h-full ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}`}>
        <MagnifyingGlassIcon className="w-16 h-16 mb-4" />
        <p className="text-xl font-semibold mb-2">No matching ideas found</p>
        <p className="text-sm">Try adjusting your search terms or selected tags</p>
      </div>
    );
  }
  return null;
};

const IdeasPage = () => {
  const [notes, setNotes] = useState([]);
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const { isDarkMode } = useTheme();
  const [selectedIdeaTag, setSelectedIdeaTag] = useState('All');
  const [ideaTags, setIdeaTags] = useState([]);
  const [ideaTagCounts, setIdeaTagCounts] = useState({});
  const [isTagManagerOpen, setIsTagManagerOpen] = useState(false);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const navigate = useNavigate();
  const { refreshActivities } = useRecentActivities();

  useEffect(() => {
    loadIdeas();
    fetchIdeaTagsData();
    fetchIdeaTagCounts();
  }, []);

  // Add this new useEffect
  useEffect(() => {
    fetchIdeaTagCounts();
  }, [notes]); // Refetch counts when notes change

  const loadIdeas = async (tags = selectedIdeaTag === 'All' ? [] : [selectedIdeaTag]) => {
    try {
      setIsLoading(true);
      const ideas = await fetchIdeas(tags);
      setNotes(ideas);
      setFilteredNotes(ideas);
      setIsLoading(false);
    } catch (error) {
      console.error('Error loading ideas:', error);
      setIsLoading(false);
    }
  };

  const fetchIdeaTagsData = async () => {
    try {
      const fetchedTags = await fetchIdeaTags();
      setIdeaTags(fetchedTags);
    } catch (error) {
      console.error('Error fetching idea tags:', error);
      // Optionally, you can set an error state here or show a notification to the user
    }
  };

  const fetchIdeaTagCounts = async () => {
    try {
      const counts = await getIdeaTagCounts();
      // Calculate total count for 'All'
      counts['All'] = notes.length;
      
      // Calculate counts for each tag
      ideaTags.forEach(tag => {
        if (!counts[tag]) {
          counts[tag] = notes.filter(note => note.tags.includes(tag)).length;
        }
      });
      
      setIdeaTagCounts(counts);
    } catch (error) {
      console.error('Error fetching idea tag counts:', error);
    }
  };

  const addNote = async () => {
    try {
      const newNote = await createIdea({
        title: 'Untitled Note',
        content: 'Start typing...',
        color: isDarkMode ? DARK_MODE_COLOR : LIGHT_MODE_COLOR,
        darkModeColor: DARK_MODE_COLOR
      });

      setNotes(prevNotes => [newNote, ...prevNotes]);
      await refreshActivities();
      navigate(`/ideas/${newNote._id}`);
    } catch (error) {
      console.error('Error creating note:', error);
    }
  };

  const updateNote = async (id, updates) => {
    try {
      const updatedNote = await updateIdea(id, updates);
      setNotes(prevNotes =>
        prevNotes.map(note =>
          note._id === id ? updatedNote : note
        )
      );
      await refreshActivities();
    } catch (error) {
      console.error('Error updating note:', error);
    }
  };

  const deleteNote = async (id) => {
    try {
      await deleteIdea(id);
      setNotes(notes.filter(note => note._id !== id));
    } catch (error) {
      console.error('Error deleting note:', error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    if (isSearchOpen) {
      setSearchTerm('');
    }
  };

  useEffect(() => {
    if (notes.length > 0) {
      const lowercasedFilter = searchTerm.toLowerCase();
      const filtered = notes.filter(note =>
        note.content.toLowerCase().includes(lowercasedFilter) ||
        note.title.toLowerCase().includes(lowercasedFilter) ||
        note.tags.some(tag => tag.toLowerCase().includes(lowercasedFilter))
      );
      setFilteredNotes(filtered);
    }
  }, [searchTerm, notes]);

  const handleIdeaTagSelect = async (newSelectedTag) => {
    setSelectedIdeaTag(newSelectedTag);
    await loadIdeas(newSelectedTag === 'All' ? [] : [newSelectedTag]);
  };

  const handleAddIdeaTag = async (noteId, newTag) => {
    try {
      const updatedNote = await addIdeaTag(noteId, newTag);
      setNotes(prevNotes =>
        prevNotes.map(note =>
          note._id === noteId ? { ...note, tags: updatedNote.tags } : note
        )
      );
      fetchIdeaTagsData();
      fetchIdeaTagCounts();
    } catch (error) {
      console.error('Error adding idea tag:', error);
    }
  };

  const handleRemoveIdeaTag = async (noteId, tagToRemove) => {
    try {
      await removeIdeaTag(noteId, tagToRemove);
      setNotes(prevNotes =>
        prevNotes.map(note =>
          note._id === noteId
            ? { ...note, tags: note.tags.filter(tag => tag !== tagToRemove) }
            : note
        )
      );
      fetchIdeaTagsData();
      fetchIdeaTagCounts();
    } catch (error) {
      console.error('Error removing idea tag:', error);
    }
  };

  const handleEditIdeaTag = async (oldTag, newTag) => {
    try {
      console.log('Editing tag:', oldTag, 'to', newTag);
      const result = await editIdeaTag(oldTag, newTag);
      console.log('Edit tag result:', result);
      if (result.modifiedCount > 0) {
        setIdeaTags(prevTags => prevTags.map(tag => tag === oldTag ? newTag : tag));
        setNotes(prevNotes => prevNotes.map(note => ({
          ...note,
          tags: note.tags.map(tag => tag === oldTag ? newTag : tag)
        })));
        fetchIdeaTagsData();
        fetchIdeaTagCounts();
      } else {
        console.warn('No ideas were updated. The old tag might not exist.');
        // Optionally, show a warning to the user
      }
    } catch (error) {
      console.error('Error editing idea tag:', error);
      // Show an error message to the user
      // You might want to use a notification system or state to display this error
    }
  };

  const handleDeleteIdeaTag = async (tagToDelete) => {
    try {
      await deleteIdeaTag(tagToDelete);
      setIdeaTags(prevTags => prevTags.filter(tag => tag !== tagToDelete));
      setNotes(prevNotes => prevNotes.map(note => ({
        ...note,
        tags: note.tags.filter(tag => tag !== tagToDelete)
      })));
      setSelectedIdeaTag('All');
      fetchIdeaTagsData();
      fetchIdeaTagCounts();
    } catch (error) {
      console.error('Error deleting idea tag:', error);
    }
  };

  // Add socket listener for real-time updates
  useEffect(() => {
    const handleActivityUpdate = (activity) => {
      if (activity.type === 'text-note') {
        refreshActivities();
      }
    };

    socket.on('newActivity', handleActivityUpdate);

    return () => {
      socket.off('newActivity', handleActivityUpdate);
    };
  }, [refreshActivities]);

  const contentArea = (
    <div className={`flex-grow overflow-y-auto custom-scrollbar relative ${
      isDarkMode 
        ? 'bg-[#121212]'
        : 'bg-white bg-opacity-80'
    }`}>
      <div className="w-full h-full px-4 pb-20">
        {/* Tags section with increased bottom padding */}
        <div className={`sticky top-0 z-10 pt-4 pb-6 ${isDarkMode ? 'bg-[#121212]' : 'bg-white'}`}>
          <div className="max-w-3xl mx-auto">
            <IdeasTagList
              tags={ideaTags}
              selectedTag={selectedIdeaTag}
              onTagSelect={handleIdeaTagSelect}
              isDarkMode={isDarkMode}
              onManageTags={() => setIsTagManagerOpen(true)}
              tagCounts={ideaTagCounts}
            />
          </div>
        </div>

        {/* Main content with standardized top spacing */}
        <div className="max-w-3xl mx-auto pt-4">
          {isLoading ? (
            <IdeasSkeleton isDarkMode={isDarkMode} count={5} />
          ) : (
            <div className="h-full">
              {renderEmptyState(notes, filteredNotes, isDarkMode, addNote, searchTerm) || (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                  {filteredNotes.map(note => (
                    <div
                      key={note._id}
                      className="animate-fade-scale-in aspect-square"
                    >
                      <StickyNote 
                        key={note._id}
                        id={note._id}
                        title={note.title}
                        content={note.content}
                        color={isDarkMode ? DARK_MODE_COLOR : LIGHT_MODE_COLOR}
                        darkModeColor={DARK_MODE_COLOR}
                        onUpdate={updateNote}
                        onDelete={deleteNote}
                        tags={note.tags || []}
                        onAddTag={handleAddIdeaTag}
                        onRemoveTag={handleRemoveIdeaTag}
                        allTags={ideaTags}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className={`flex flex-col h-full relative overflow-hidden bg-transparent`}>
      <TopBar 
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        isDarkMode={isDarkMode}
        isSearchExpanded={isSearchExpanded}
        setIsSearchExpanded={setIsSearchExpanded}
        onAddNote={addNote}
      />

      {/* Content Area */}
      {contentArea}
    </div>
  );
};

export default IdeasPage;
