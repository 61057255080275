export const isUrlExpired = (url) => {
  try {
    const expiresParam = new URL(url).searchParams.get('Expires');
    if (!expiresParam) return false;
    
    const expiresTimestamp = parseInt(expiresParam, 10) * 1000; // Convert to milliseconds
    return Date.now() > expiresTimestamp;
  } catch (error) {
    console.error('Error checking URL expiration:', error);
    return false; // If there's an error parsing the URL, assume it's not expired
  }
};