import React, { createContext, useContext, useState, useEffect } from 'react';
import { fetchRecentActivities } from '../api';
import { useAuth } from './AuthContext';
import { socket } from '../socket';

const RecentActivitiesContext = createContext();

export const useRecentActivities = () => {
  const context = useContext(RecentActivitiesContext);
  if (!context) {
    throw new Error('useRecentActivities must be used within a RecentActivitiesProvider');
  }
  return context;
};

export const RecentActivitiesProvider = ({ children }) => {
  const [activities, setActivities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  const loadActivities = async () => {
    try {
      if (!user) {
        setActivities([]);
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      const data = await fetchRecentActivities();
      setActivities(data);
      setError(null);
    } catch (err) {
      console.error('Error loading activities:', err);
      setError(err.message);
      setActivities([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!user) return;

    const handleNewActivity = (activity) => {
      console.log('Received new activity:', activity);
      if (activity.userId === user.id) {
        setActivities(prev => {
          const filtered = prev.filter(a => {
            const isDuplicate = 
              (a.taskId && a.taskId === activity.taskId) || 
              (a.ideaId && a.ideaId === activity.ideaId) ||
              (a.canvasId && a.canvasId === activity.canvasId && 
               a.postId && a.postId === activity.postId) ||
              (a.id === activity.id);
            return !isDuplicate;
          });
          
          const updated = [activity, ...filtered].slice(0, 20);
          console.log('Updated activities:', updated);
          return updated;
        });
      }
    };

    // Set up socket listener
    if (socket) {
      socket.on('newActivity', handleNewActivity);
      console.log('Socket listener set up for newActivity');
    }

    return () => {
      if (socket) {
        socket.off('newActivity', handleNewActivity);
        console.log('Socket listener cleaned up');
      }
    };
  }, [user]);

  useEffect(() => {
    if (user) {
      loadActivities();
    } else {
      setActivities([]);
      setIsLoading(false);
    }
  }, [user]);

  return (
    <RecentActivitiesContext.Provider 
      value={{ 
        activities, 
        isLoading, 
        error, 
        refreshActivities: loadActivities 
      }}
    >
      {children}
    </RecentActivitiesContext.Provider>
  );
}; 