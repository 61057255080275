import React from 'react';
import { XMarkIcon, ClipboardIcon, BookmarkSlashIcon } from '@heroicons/react/24/outline';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const MessageModal = ({ message, onClose, onCopy, onUnsave, isDarkMode }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
    <div className={`rounded-lg w-full max-w-2xl overflow-hidden relative flex flex-col border
                     ${isDarkMode ? 'bg-slate-900 border-slate-700' : 'bg-white border-gray-200'}`} 
         style={{ height: '90vh' }}>
      {/* Top Bar */}
      <div className={`${isDarkMode ? 'bg-slate-800' : 'bg-white'} bg-opacity-90 !h-10 min-h-10 flex-shrink-0 flex items-center justify-between px-4 py-2 w-full border-b ${isDarkMode ? 'border-slate-700' : 'border-gray-200 border-opacity-50'}`}>
        <h2 className={`text-sm font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-800'}`}>
          {message.role === 'human' ? 'Your Message' : 'AI Response'}
        </h2>
        <button 
          onClick={onClose}
          className={`p-1 rounded-full transition-colors duration-200 ${isDarkMode ? 'hover:bg-slate-700' : 'hover:bg-gray-200'}`}
          title="Close"
        >
          <XMarkIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
        </button>
      </div>

      {/* Content Area */}
      <div className={`p-4 flex-grow overflow-y-auto custom-scrollbar ${isDarkMode ? 'bg-slate-900' : 'bg-white'}`}>
        <div className={`prose max-w-none ${isDarkMode ? 'text-white' : 'text-gray-700'}`}>
          <ReactMarkdown 
            remarkPlugins={[remarkGfm]}
            components={{
              p: ({node, ...props}) => <p className={`${isDarkMode ? 'text-white' : 'text-gray-700'}`} {...props} />,
              h1: ({node, ...props}) => <h1 className={`${isDarkMode ? 'text-white' : 'text-gray-900'}`} {...props} />,
              h2: ({node, ...props}) => <h2 className={`${isDarkMode ? 'text-white' : 'text-gray-900'}`} {...props} />,
              h3: ({node, ...props}) => <h3 className={`${isDarkMode ? 'text-white' : 'text-gray-900'}`} {...props} />,
              h4: ({node, ...props}) => <h4 className={`${isDarkMode ? 'text-white' : 'text-gray-900'}`} {...props} />,
              h5: ({node, ...props}) => <h5 className={`${isDarkMode ? 'text-white' : 'text-gray-900'}`} {...props} />,
              h6: ({node, ...props}) => <h6 className={`${isDarkMode ? 'text-white' : 'text-gray-900'}`} {...props} />,
              ul: ({node, ...props}) => <ul className={`${isDarkMode ? 'text-white' : 'text-gray-700'}`} {...props} />,
              ol: ({node, ...props}) => <ol className={`${isDarkMode ? 'text-white' : 'text-gray-700'}`} {...props} />,
              li: ({node, ...props}) => <li className={`${isDarkMode ? 'text-white' : 'text-gray-700'}`} {...props} />,
              a: ({node, ...props}) => <a className={`${isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-800'}`} {...props} />,
              blockquote: ({node, ...props}) => <blockquote className={`${isDarkMode ? 'border-l-4 border-slate-700 pl-4 text-slate-300' : 'border-l-4 border-gray-200 pl-4 text-gray-600'}`} {...props} />,
              code: ({node, inline, ...props}) => 
                inline 
                  ? <code className={`${isDarkMode ? 'bg-slate-700 text-white' : 'bg-gray-100 text-gray-800'} px-1 py-0.5 rounded`} {...props} />
                  : <code className={`${isDarkMode ? 'bg-slate-700 text-white' : 'bg-gray-100 text-gray-800'} block p-2 rounded`} {...props} />,
              pre: ({node, ...props}) => <pre className={`${isDarkMode ? 'bg-slate-700' : 'bg-gray-100'} p-2 rounded overflow-x-auto`} {...props} />,
            }}
          >
            {message.content}
          </ReactMarkdown>
        </div>
      </div>

      {/* Bottom Bar */}
      <div className={`${isDarkMode ? 'bg-slate-900' : 'bg-white'} px-4 py-4`}>
        <div className="max-w-2xl mx-auto flex justify-end space-x-2">
          <button
            onClick={onCopy}
            className={`px-3 py-1 rounded-md text-xs font-medium transition-colors duration-200 flex items-center
                        ${isDarkMode 
                          ? 'bg-slate-700 text-slate-200 hover:bg-slate-600' 
                          : 'bg-gray-100 text-gray-700 hover:bg-gray-200'}`}
          >
            <ClipboardIcon className="h-4 w-4 mr-1" />
            Copy
          </button>
          <button
            onClick={onUnsave}
            className={`px-3 py-1 rounded-md text-xs font-medium transition-colors duration-200 flex items-center
                        ${isDarkMode 
                          ? 'bg-red-900 text-red-200 hover:bg-red-800' 
                          : 'bg-red-100 text-red-600 hover:bg-red-200'}`}
          >
            <BookmarkSlashIcon className="h-4 w-4 mr-1" />
            Unsave
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default MessageModal;