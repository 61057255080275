import React, { useRef, forwardRef, useEffect } from 'react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';

/**
 * A simple contentEditable component that leverages browser's native capabilities
 */
const CanvasPostContentEditor = forwardRef(({ 
  content, 
  onSave, 
  onCancel, 
  isDarkMode,
  isEditing,
  removeBorderAndPadding = false,
  hideSaveCancelButtons = false,
  onChange,
}, ref) => {
  const contentEditableRef = useRef(null);

  // Set initial content only once when the component mounts or when content prop changes
  useEffect(() => {
    if (contentEditableRef.current && contentEditableRef.current.innerText !== content) {
      contentEditableRef.current.innerText = content || '';
    }
  }, [content]);

  // Expose the contentEditable ref through forwardRef
  React.useImperativeHandle(ref, () => ({
    focus: () => contentEditableRef.current?.focus(),
    getContent: () => contentEditableRef.current?.innerText || '',
    setContent: (text) => {
      if (contentEditableRef.current) {
        contentEditableRef.current.innerText = text;
      }
    }
  }));

  // Handle only special key combinations
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
      e.preventDefault();
      onSave?.();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      onCancel?.();
    }
  };

  const handleChange = (newContent) => {
    if (onChange) {
      onChange(newContent);
    }
  };

  return (
    <div className="relative">
      {!hideSaveCancelButtons && (
        <div className="absolute -top-10 right-0 flex space-x-2">
          <button
            type="button"
            onClick={() => onSave?.()}
            className={`
              px-2.5 py-1.5 rounded-md transition-all duration-200
              ${isDarkMode 
                ? 'bg-[#1a1a1a] hover:bg-[#202020] text-slate-200 active:bg-[#151515]' 
                : 'bg-blue-500 hover:bg-blue-600 text-white active:bg-blue-700'
              }
              text-xs flex items-center gap-1.5
            `}
          >
            <CheckIcon className="h-3.5 w-3.5" />
            <span className="font-medium">Save</span>
          </button>
          <button
            type="button"
            onClick={onCancel}
            className={`
              px-2.5 py-1.5 rounded-md transition-all duration-200
              ${isDarkMode 
                ? 'bg-[#151515] hover:bg-[#1a1a1a] text-slate-400 active:bg-[#121212]' 
                : 'bg-gray-200 hover:bg-gray-300 text-gray-600 active:bg-gray-400'
              }
              text-xs flex items-center gap-1.5
            `}
          >
            <XMarkIcon className="h-3.5 w-3.5" />
            <span className="font-medium">Cancel</span>
          </button>
        </div>
      )}

      <div
        ref={contentEditableRef}
        contentEditable={isEditing}
        suppressContentEditableWarning
        onKeyDown={handleKeyDown}
        onInput={(e) => handleChange(e.currentTarget.innerText)}
        className={`
          outline-none transition-colors duration-200
          ${isDarkMode ? 'text-slate-200' : 'text-gray-700'}
          ${isEditing ? 'cursor-text' : 'cursor-default'}
          ${isEditing && isDarkMode ? 'bg-[#151515]' : ''}
        `}
        style={{
          minHeight: '1.5rem',
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
          WebkitUserModify: 'read-write-plaintext-only'
        }}
      />
    </div>
  );
});

// Add display name for better debugging
CanvasPostContentEditor.displayName = 'CanvasPostContentEditor';

export default CanvasPostContentEditor;
