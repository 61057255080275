import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { ChevronUpDownIcon, MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { fetchAvatarUrl, forceRefreshAvatarUrl } from '../../utils/avatarUtils';
import api from '../../api';
import { useTheme } from '../../context/ThemeContext';
import CompactPersonaCard from './CompactPersonaCard';
import ResetIcon from './ResetIcon';
import { useMediaQuery } from '../../hooks/useMediaQuery';

const DEFAULT_PERSONA = { _id: 'default', name: 'Default Persona' };

const truncateName = (name, maxLength = 8) => 
  name.length > maxLength ? `${name.slice(0, maxLength)}...` : name;

const SkeletonLoader = () => (
  <div className="animate-pulse flex items-center p-2 rounded-md border mb-[5px]">
    <div className="relative mr-3 flex-shrink-0">
      <div className="w-8 h-8 rounded-full bg-gray-300"></div>
    </div>
    <div className="flex-1 min-w-0">
      <div className="h-2 bg-gray-300 rounded w-3/4 mb-1"></div>
      <div className="h-2 bg-gray-300 rounded w-1/2"></div>
    </div>
  </div>
);

const PersonaDropdown = ({ onSelectPersona, selectedPersona: propSelectedPersona, isLoadingAvatar, onAvatarUpdate }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [personas, setPersonas] = useState([]);
  const [filteredPersonas, setFilteredPersonas] = useState([]);
  const dropdownRef = useRef(null);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const searchInputRef = useRef(null);
  const { isDarkMode } = useTheme();
  const initialFetchDone = useRef(false);
  const [dropdownPosition, setDropdownPosition] = useState('bottom');
  const [horizontalPosition, setHorizontalPosition] = useState('left');
  const isMobile = useMediaQuery('(max-width: 640px)');

  const fetchPersonas = useMemo(() => async () => {
    try {
      const response = await api.get('/api/personas');
      return response.data;
    } catch (error) {
      console.error('Error fetching personas:', error);
      return [];
    }
  }, []);

  const loadAvatarUrl = useMemo(() => async (personaId) => {
    if (personaId && personaId !== 'default') {
      const url = await fetchAvatarUrl(personaId);
      setAvatarUrl(url);
    } else {
      setAvatarUrl(null);
    }
  }, []);

  useEffect(() => {
    const initializeComponent = async () => {
      if (!initialFetchDone.current) {
        const fetchedPersonas = await fetchPersonas();
        setPersonas(fetchedPersonas);
        setFilteredPersonas(fetchedPersonas);

        const savedPersona = localStorage.getItem('selectedPersona');
        if (savedPersona) {
          const parsedPersona = JSON.parse(savedPersona);
          onSelectPersona(parsedPersona);
          await loadAvatarUrl(parsedPersona._id);
        }
        initialFetchDone.current = true;
      }
    };

    initializeComponent();
  }, [fetchPersonas, loadAvatarUrl, onSelectPersona]);

  useEffect(() => {
    if (propSelectedPersona) {
      loadAvatarUrl(propSelectedPersona._id);
    }
  }, [propSelectedPersona, loadAvatarUrl]);

  useEffect(() => {
    if (!searchTerm) {
      setFilteredPersonas(personas);
    } else {
      const lowercasedFilter = searchTerm.toLowerCase();
      const filtered = personas.filter(persona =>
        persona && persona.name && persona.name.toLowerCase().includes(lowercasedFilter)
      );
      setFilteredPersonas(filtered);
    }
    setFocusedIndex(-1);
  }, [searchTerm, personas]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handlePersonaSelect = useCallback(async (persona) => {
    if (persona._id === 'default') {
      onSelectPersona(null);
      localStorage.removeItem('selectedPersona');
      setAvatarUrl(null);
    } else {
      onSelectPersona(persona);
      localStorage.setItem('selectedPersona', JSON.stringify(persona));
      const newAvatarUrl = await fetchAvatarUrl(persona._id);
      setAvatarUrl(newAvatarUrl);
    }
    setIsOpen(false);
    setSearchTerm('');
  }, [onSelectPersona]);

  const handleCreateNewPersona = () => {
    setIsOpen(false);
    navigate('/personas/create');
  };

  const handleKeyDown = (e) => {
    if (!isOpen) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setFocusedIndex((prevIndex) => (prevIndex + 1) % (filteredPersonas.length + 2));
        break;
      case 'ArrowUp':
        e.preventDefault();
        setFocusedIndex((prevIndex) => (prevIndex - 1 + filteredPersonas.length + 2) % (filteredPersonas.length + 2));
        break;
      case 'Enter':
        e.preventDefault();
        if (focusedIndex === -1) {
          handlePersonaSelect(DEFAULT_PERSONA);
        } else if (focusedIndex === filteredPersonas.length + 1) {
          handleCreateNewPersona();
        } else if (focusedIndex >= 0 && focusedIndex < filteredPersonas.length) {
          handlePersonaSelect(filteredPersonas[focusedIndex]);
        }
        break;
      case 'Escape':
        e.preventDefault();
        setIsOpen(false);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);

  const handleAvatarError = useCallback(async (personaId) => {
    try {
      const newAvatarUrl = await forceRefreshAvatarUrl(personaId);
      if (newAvatarUrl) {
        setPersonas(prevPersonas => 
          prevPersonas.map(persona => 
            persona._id === personaId ? { ...persona, avatarUrl: newAvatarUrl } : persona
          )
        );
        if (propSelectedPersona && propSelectedPersona._id === personaId) {
          setAvatarUrl(newAvatarUrl);
        }
      }
    } catch (error) {
      console.error('Error refreshing avatar URL:', error);
    }
  }, [propSelectedPersona]);

  // Animation variants
  const dropdownVariants = {
    hidden: { 
      opacity: 0, 
      y: dropdownPosition === 'bottom' ? -5 : 5,
      x: horizontalPosition === 'right' ? 5 : -5,
      scale: 0.95 
    },
    visible: { 
      opacity: 1, 
      y: 0,
      x: 0, 
      scale: 1, 
      transition: { 
        type: "spring", 
        stiffness: 300, 
        damping: 30 
      } 
    },
    exit: { 
      opacity: 0, 
      y: dropdownPosition === 'bottom' ? -5 : 5,
      x: horizontalPosition === 'right' ? 5 : -5,
      scale: 0.95, 
      transition: { duration: 0.1 } 
    }
  };

  const listItemVariants = {
    hidden: { opacity: 0, x: -5 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.1 } }
  };

  // Add this new effect for periodic avatar refreshing
  useEffect(() => {
    let intervalId;
    const refreshSelectedPersonaAvatar = async () => {
      if (propSelectedPersona && propSelectedPersona._id !== 'default') {
        try {
          const newAvatarUrl = await forceRefreshAvatarUrl(propSelectedPersona._id);
          if (newAvatarUrl) {
            setAvatarUrl(newAvatarUrl);
            // Add this line to pass the updated avatarUrl to the parent component
            onAvatarUpdate(newAvatarUrl);
          }
        } catch (error) {
          console.error('Error refreshing avatar URL:', error);
        }
      }
    };

    // Refresh avatar every 55 minutes (considering a 1-hour expiration for signed URLs)
    intervalId = setInterval(refreshSelectedPersonaAvatar, 55 * 60 * 1000);

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [propSelectedPersona, onAvatarUpdate]);

  // Add this new useEffect hook
  useEffect(() => {
    if (propSelectedPersona && propSelectedPersona.avatarUrl) {
      setAvatarUrl(propSelectedPersona.avatarUrl);
    } else {
      setAvatarUrl(null);
    }
  }, [propSelectedPersona]);

  useEffect(() => {
    if (isOpen && dropdownRef.current) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      const dropdownWidth = 192; // w-48 = 12rem = 192px
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      
      // Vertical position check
      const spaceBelow = viewportHeight - dropdownRect.bottom;
      const spaceAbove = dropdownRect.top;
      
      // Horizontal position check
      const spaceRight = viewportWidth - dropdownRect.left;
      
      // Set vertical position
      if (spaceBelow < 300 && spaceAbove > 300) {
        setDropdownPosition('top');
      } else {
        setDropdownPosition('bottom');
      }
      
      // Set horizontal position
      if (spaceRight < dropdownWidth) {
        setHorizontalPosition('right');
      } else {
        setHorizontalPosition('left');
      }
    }
  }, [isOpen]);

  return (
    <div className="relative" ref={dropdownRef} onKeyDown={handleKeyDown}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`group flex items-center justify-between px-2 py-1 text-xs font-medium rounded-md transition-all duration-200 min-w-fit whitespace-nowrap ${
          isDarkMode
            ? 'text-gray-300 hover:bg-[#2A2A2A]/50'
            : 'text-slate-700 hover:bg-slate-100/50'
        } focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-slate-500`}
      >
        <div className="flex items-center">
          {propSelectedPersona ? (
            <>
              {isLoadingAvatar ? (
                <div className="h-4 w-4 rounded-full bg-gray-200 animate-pulse mr-1.5 flex-shrink-0"></div>
              ) : avatarUrl ? (
                <img
                  src={avatarUrl}
                  alt={propSelectedPersona.name}
                  className="h-4 w-4 rounded-full mr-1.5 flex-shrink-0"
                  onError={() => handleAvatarError(propSelectedPersona._id)}
                />
              ) : (
                <span className="mr-1.5" />
              )}
              <span className="truncate max-w-[100px]" title={propSelectedPersona.name}>
                {truncateName(propSelectedPersona.name, isMobile ? 8 : 12)}
              </span>
            </>
          ) : (
            <span>{isMobile ? 'Persona' : 'Pick Persona'}</span>
          )}
        </div>
        <ChevronUpDownIcon className="h-3 w-3 ml-1 flex-shrink-0" />
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={dropdownVariants}
            className={`absolute ${
              dropdownPosition === 'bottom' ? 'top-full mt-1' : 'bottom-full mb-1'
            } ${
              horizontalPosition === 'right' ? 'right-0' : 'left-0'
            } w-48 rounded-md shadow-lg ${
              isDarkMode 
                ? 'bg-[#1E1E1E] ring-1 ring-[#333333]' 
                : 'bg-white/95 ring-1 ring-slate-200'
            } z-[9999]`}
          >
            <div className={`p-2 ${isDarkMode ? 'border-slate-700' : 'border-gray-200'}`}>
              <div className="flex items-center space-x-2 mb-2">
                <div className="relative flex-grow">
                  <input
                    ref={searchInputRef}
                    type="text"
                    className={`w-full px-2 py-1 text-xs border rounded-md pl-2 pr-2 focus:outline-none focus:ring-1 focus:ring-slate-400 ${
                      isDarkMode
                        ? 'bg-[#121212] border-[#333333] text-gray-200 placeholder-gray-500'
                        : 'bg-slate-50 border-slate-200 text-slate-700 placeholder-slate-400'
                    }`}
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <button
                  onClick={() => handlePersonaSelect(DEFAULT_PERSONA)}
                  className={`p-1 rounded-full ${
                    isDarkMode 
                      ? 'hover:bg-[#2A2A2A]' 
                      : 'hover:bg-slate-100'
                  }`}
                  title="Reset to Default"
                >
                  <ResetIcon className={`h-4 w-4 ${
                    isDarkMode ? 'text-gray-400' : 'text-slate-500'
                  }`} />
                </button>
              </div>
              <div className={`${
                isDarkMode ? 'border-slate-700' : 'border-gray-200'
              }`}>
                {filteredPersonas.length === 0 && searchTerm ? (
                  <div className="p-3 text-left">
                    <p className="text-xs text-slate-500 dark:text-slate-400">
                      No results for "<span className="font-medium">{searchTerm}</span>"
                    </p>
                  </div>
                ) : filteredPersonas.length === 0 ? (
                  <div className="p-3">
                    <div className="text-left mb-2">
                      <p className={`text-xs font-medium ${
                        isDarkMode ? 'text-gray-200' : 'text-gray-900'
                      }`}>
                        Start with a Persona
                      </p>
                      <p className={`text-[11px] ${
                        isDarkMode ? 'text-gray-400' : 'text-gray-600'
                      }`} style={{ lineHeight: '1.2', marginTop: '0.5rem' }}>
                        Create a persona to personalize your experience
                      </p>
                    </div>
                    <div className="space-y-1.5">
                      <SkeletonLoader />
                      <SkeletonLoader />
                    </div>
                  </div>
                ) : (
                  <div className="max-h-[120px] overflow-y-auto custom-scrollbar">
                    {filteredPersonas.map((persona, index) => (
                      <motion.div key={persona._id} variants={listItemVariants}>
                        <CompactPersonaCard
                          persona={persona}
                          onPersonaClick={handlePersonaSelect}
                          isSelected={focusedIndex === index}
                        />
                      </motion.div>
                    ))}
                  </div>
                )}
              </div>
              <div className="pt-2">
                <motion.div variants={listItemVariants}>
                  <button
                    className={`flex items-center w-full px-2 py-1 text-xs rounded-md transition-colors duration-200 ${
                      isDarkMode
                        ? 'text-gray-300 hover:bg-[#2A2A2A]'
                        : 'text-slate-700 hover:bg-slate-100'
                    } ${
                      focusedIndex === filteredPersonas.length
                        ? isDarkMode ? 'bg-[#2A2A2A]' : 'bg-slate-100'
                        : ''
                    }`}
                    onClick={handleCreateNewPersona}
                  >
                    <PlusIcon className="h-3 w-3 mr-1" />
                    <span className="font-medium">Create Persona</span>
                  </button>
                </motion.div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default PersonaDropdown;
