import React from 'react';

const ChatHistoryIcon = ({ className }) => {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width="20" 
      height="20" 
      viewBox="0 0 24 24"
      className={className}
    >
      <g fill="none" stroke="#888888" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
        <path d="M5.636 18.364A9 9 0 1 0 3 12.004V14" />
        <path d="m1 12l2 2l2-2m6-4v5h5" />
      </g>
    </svg>
  );
};

export default ChatHistoryIcon; 