import React, { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useSearch } from '../../context/SearchContext';
import { useTheme } from '../../context/ThemeContext';
import { XMarkIcon, PlusIcon } from '@heroicons/react/24/outline';
import * as Icons from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';

const FilterDropdown = ({ isOpen, anchorEl, onClose, children, isDarkMode }) => {
  const dropdownRef = useRef(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (isOpen && anchorEl) {
      const rect = anchorEl.getBoundingClientRect();
      setPosition({
        top: rect.bottom + window.scrollY + 4,
        left: rect.left + window.scrollX,
      });
    }
  }, [isOpen, anchorEl]);

  useEffect(() => {
    if (!isOpen) return;

    const handleClickOutside = (event) => {
      if (
        dropdownRef.current && 
        !dropdownRef.current.contains(event.target) &&
        !anchorEl.contains(event.target)
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose, anchorEl]);

  if (!isOpen) return null;

  return createPortal(
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          ref={dropdownRef}
          style={{
            position: 'absolute',
            top: position.top,
            left: position.left,
            zIndex: 9999,
          }}
          className={`w-56 rounded-lg shadow-lg py-2 overflow-hidden ${
            isDarkMode 
              ? 'bg-[#1a1a1a] border border-gray-800' 
              : 'bg-white border border-gray-200'
          }`}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  );
};

const SearchFilters = () => {
  const { isDarkMode } = useTheme();
  const { 
    availableFilters,
    selectedFilters,
    toggleFilter
  } = useSearch();

  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const buttonRef = useRef(null);

  // Get icon component
  const getIcon = (iconName) => {
    const Icon = Icons[iconName];
    return Icon ? <Icon className="h-4 w-4" /> : null;
  };

  return (
    <div className="flex flex-wrap items-center gap-2">
      {/* "I'm searching for..." text */}
      <span className={`text-sm ${
        isDarkMode ? 'text-gray-400' : 'text-gray-500'
      }`}>I'm searching for...</span>

      <AnimatePresence>
        {selectedFilters.map(filterId => {
          const filter = availableFilters.find(f => f.id === filterId);
          if (!filter) return null;

          return (
            <motion.button
              key={filter.id}
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              onClick={() => toggleFilter(filter.id)}
              className={`group flex items-center gap-1.5 px-2.5 py-1.5 rounded-full text-sm transition-colors duration-150 ${
                isDarkMode
                  ? 'bg-blue-900/30 hover:bg-blue-900/50 text-blue-400'
                  : 'bg-blue-50 hover:bg-blue-100 text-blue-700'
              }`}
            >
              {getIcon(filter.icon)}
              {filter.label}
              <XMarkIcon className={`h-3.5 w-3.5 ${
                isDarkMode
                  ? 'text-blue-500 group-hover:text-blue-400'
                  : 'text-blue-400 group-hover:text-blue-600'
              }`} />
            </motion.button>
          );
        })}
      </AnimatePresence>

      {/* Add Filter button */}
      <div className="relative">
        <button
          ref={buttonRef}
          onClick={() => setShowFilterMenu(!showFilterMenu)}
          className={`flex items-center gap-1 px-2.5 py-1.5 text-sm rounded-full transition-colors duration-150 ${
            isDarkMode
              ? 'text-gray-400 hover:text-gray-200 hover:bg-gray-800'
              : 'text-gray-600 hover:text-gray-900 hover:bg-gray-100'
          }`}
        >
          <PlusIcon className="h-4 w-4" />
          Add Filter
        </button>

        <FilterDropdown
          isOpen={showFilterMenu}
          anchorEl={buttonRef.current}
          onClose={() => setShowFilterMenu(false)}
          isDarkMode={isDarkMode}
        >
          {availableFilters.map(filter => {
            const isSelected = selectedFilters.includes(filter.id);
            return (
              <button
                key={filter.id}
                onClick={() => {
                  toggleFilter(filter.id);
                }}
                className={`flex items-center gap-2 w-full px-3 py-1.5 text-sm ${
                  isSelected 
                    ? isDarkMode
                      ? 'text-blue-400 bg-blue-900/30'
                      : 'text-blue-500 bg-blue-50'
                    : isDarkMode
                      ? 'text-gray-300 hover:bg-gray-800'
                      : 'text-gray-700 hover:bg-gray-50'
                }`}
              >
                <div className="flex items-center justify-center w-5">
                  {isSelected ? (
                    <div className={`h-4 w-4 rounded-sm flex items-center justify-center ${
                      isDarkMode ? 'bg-blue-600' : 'bg-blue-500'
                    }`}>
                      <Icons.CheckIcon className="h-3 w-3 text-white" />
                    </div>
                  ) : (
                    <div className={`h-4 w-4 rounded-sm border ${
                      isDarkMode ? 'border-gray-700' : 'border-gray-300'
                    }`} />
                  )}
                </div>
                {getIcon(filter.icon)}
                {filter.label}
              </button>
            );
          })}

          {selectedFilters.length > 0 && (
            <div className={`mt-2 pt-2 px-3 border-t ${
              isDarkMode ? 'border-gray-800' : 'border-gray-100'
            }`}>
              <button
                onClick={() => {
                  selectedFilters.forEach(filterId => toggleFilter(filterId));
                }}
                className={`text-sm ${
                  isDarkMode
                    ? 'text-gray-400 hover:text-gray-200'
                    : 'text-gray-500 hover:text-gray-700'
                }`}
              >
                Clear All
              </button>
            </div>
          )}
        </FilterDropdown>
      </div>
    </div>
  );
};

export default SearchFilters; 