import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';

const FolderInfoPopup = ({ folder, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-96 max-w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Folder Information</h2>
          <button
            onClick={onClose}
            className="p-1 rounded-full hover:bg-gray-200 transition-colors duration-200"
          >
            <XMarkIcon className="w-6 h-6 text-gray-600" />
          </button>
        </div>
        <div className="space-y-2">
          <p><strong>Name:</strong> {folder.name}</p>
          <p><strong>ID:</strong> {folder._id}</p>
          <p><strong>Created:</strong> {new Date(folder.createdAt).toLocaleString()}</p>
          <p><strong>Last Modified:</strong> {new Date(folder.updatedAt).toLocaleString()}</p>
          <p><strong>Color:</strong> {folder.color || 'Default'}</p>
          {/* Add more folder information as needed */}
        </div>
      </div>
    </div>
  );
};

export default FolderInfoPopup;