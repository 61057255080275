import React from 'react';
import { EyeIcon, ClipboardIcon, TrashIcon } from '@heroicons/react/24/outline';

const MessageCard = ({ message, onView, onCopy, onUnsave, isDarkMode }) => {
  const handleAction = (action, event) => {
    event.preventDefault();
    action();
  };

  return (
    <div className={`rounded-lg shadow-md p-4 flex flex-col h-[200px] overflow-hidden
                     ${isDarkMode ? 'bg-slate-800' : 'bg-white'}`}>
      <h3 className={`text-sm font-medium mb-2 line-clamp-1
                      ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>
        {message.title || 'Untitled'}
      </h3>
      <p className={`text-xs mb-2 flex-grow overflow-hidden line-clamp-5
                     ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
        {message.content}
      </p>
      
      <div className={`flex justify-end items-center mt-auto pt-2 border-t
                       ${isDarkMode ? 'border-slate-700' : 'border-gray-200'}`}>
        <div className="flex space-x-2">
          <button 
            onClick={(e) => handleAction(onView, e)} 
            className={`p-1 rounded-full touch-action-manipulation
                        ${isDarkMode ? 'hover:bg-slate-700' : 'hover:bg-gray-100'}`}
          >
            <EyeIcon className={`h-4 w-4 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
          </button>
          <button 
            onClick={(e) => handleAction(onCopy, e)} 
            className={`p-1 rounded-full touch-action-manipulation
                        ${isDarkMode ? 'hover:bg-slate-700' : 'hover:bg-gray-100'}`}
          >
            <ClipboardIcon className={`h-4 w-4 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
          </button>
          <button 
            onClick={(e) => handleAction(onUnsave, e)} 
            className={`p-1 rounded-full touch-action-manipulation
                        ${isDarkMode ? 'hover:bg-slate-700' : 'hover:bg-gray-100'}`}
          >
            <TrashIcon className={`h-4 w-4 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default MessageCard;