import React, { useEffect, useRef, useState } from 'react';
import { ClockIcon, ArrowsPointingOutIcon, ArrowsPointingInIcon, PlayIcon, PauseIcon, ArrowPathIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { useTimer } from '../context/TimerContext';
import { useTheme } from '../context/ThemeContext';
import { useMediaQuery } from 'react-responsive';

function CustomDropdown({ options, value, onChange, isFullScreen, isMobile, isDarkMode }) {
  const [isOpen, setIsOpen] = useState(false);
  const [openUpwards, setOpenUpwards] = useState(false);
  const dropdownRef = useRef(null);
  const optionsRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    function calculatePosition() {
      if (dropdownRef.current && optionsRef.current) {
        const dropdownRect = dropdownRef.current.getBoundingClientRect();
        const optionsHeight = optionsRef.current.offsetHeight;
        const viewportHeight = window.innerHeight;

        setOpenUpwards(dropdownRect.bottom + optionsHeight > viewportHeight);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('resize', calculatePosition);
    calculatePosition();

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('resize', calculatePosition);
    };
  }, [isOpen]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (optionValue) => {
    onChange({ target: { value: optionValue } });
    setIsOpen(false);
  };

  const selectedOption = options.find(option => option.value === value) || options[0];

  return (
    <div ref={dropdownRef} className="relative w-full">
      <button
        onClick={toggleDropdown}
        className={`w-full py-3 px-4 rounded-lg text-base font-medium transition-all duration-200 
          ${isDarkMode 
            ? 'bg-slate-700 text-slate-200 hover:bg-slate-600' 
            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'} 
          focus:outline-none focus:ring-2 focus:ring-blue-400
          flex justify-between items-center
          ${isFullScreen ? (isMobile ? 'text-base py-3' : 'text-lg py-4') : ''}`}
      >
        <span className={`${selectedOption.value === '' ? (isDarkMode ? 'text-slate-400' : 'text-gray-500') : (isDarkMode ? 'text-slate-200' : 'text-gray-700')}`}>
          {selectedOption.label}
        </span>
        <ChevronDownIcon 
          className={`w-5 h-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'} transition-transform duration-200 ${isOpen ? 'transform rotate-180' : ''}`} 
        />
      </button>
      {isOpen && (
        <div 
          ref={optionsRef}
          className={`absolute z-10 w-full ${isDarkMode ? 'bg-slate-800 border-slate-600' : 'bg-white border-gray-200'} border rounded-lg shadow-lg mt-1
            ${openUpwards ? 'bottom-full mb-1' : 'top-full'}`}
        >
          {options.map((option) => (
            <button
              key={option.value}
              onClick={() => handleOptionClick(option.value)}
              className={`w-full text-left px-4 py-3 ${isDarkMode ? 'hover:bg-slate-700' : 'hover:bg-gray-100'} transition-colors duration-150
                ${option.value === value ? (isDarkMode ? 'bg-slate-700' : 'bg-gray-100') + ' font-semibold' : ''}
                ${isFullScreen ? (isMobile ? 'text-base' : 'text-lg') : 'text-base'} 
                ${option.value === '' 
                  ? (isDarkMode ? 'text-slate-400' : 'text-gray-500') 
                  : (isDarkMode ? 'text-slate-200' : 'text-gray-700')}`}
            >
              {option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

function Timer({ id, onUpdate }) {
  const [isFullScreen, setIsFullScreen] = React.useState(false);
  const [containerWidth, setContainerWidth] = React.useState('max-w-[300px]');
  const { 
    time, 
    isRunning, 
    selectedDuration, 
    startTimer, 
    stopTimer, 
    resetTimer, 
    setDuration,
    setActiveTimer,
    setShowTimerBubble,
  } = useTimer();
  const { isDarkMode } = useTheme();
  
  const containerRef = useRef(null);
  const isMobile = useMediaQuery({ maxWidth: 640 });
  const isTablet = useMediaQuery({ minWidth: 641, maxWidth: 1024 });

  useEffect(() => {
    if (isRunning) {
      setActiveTimer({ id, time });
    } else {
      setActiveTimer(null);
    }
  }, [isRunning, time, id, setActiveTimer]);

  const handleStartStop = () => {
    if (!selectedDuration) return;
    if (isRunning) {
      stopTimer();
    } else {
      startTimer();
    }
    setShowTimerBubble(true);
  };

  const handleReset = () => {
    resetTimer();
  };

  const formatTime = (seconds) => {
    if (isNaN(seconds)) return "00:00:00";
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const durationOptions = [
    { label: 'Select duration', value: '' },
    { label: '5 min', value: 5 },
    { label: '10 min', value: 10 },
    { label: '25 min', value: 25 },
    { label: '45 min', value: 45 },
    { label: '60 min', value: 60 },
    { label: '1.5 hrs', value: 90 },
    { label: '2 hrs', value: 120 },
    { label: '3 hrs', value: 180 },
  ];

  const handleSetDuration = (event) => {
    const minutes = parseInt(event.target.value, 10);
    if (minutes) {
      const newDuration = minutes * 60;
      setDuration(newDuration);
      if (typeof onUpdate === 'function') {
        onUpdate(id, { duration: newDuration });
      }
    }
  };

  if (isFullScreen) {
    return (
      <div 
        className={`absolute inset-0 z-50 flex flex-col transition-all duration-200 ${
          isDarkMode 
            ? 'bg-gradient-to-br from-slate-800 to-slate-900 text-slate-200' 
            : 'bg-gradient-to-br from-white to-gray-50 text-gray-800'
        }`}
      >
        <div className="absolute top-0 left-0 right-0 p-4 sm:p-6 flex items-center justify-between">
          <div className="flex items-center">
            <ClockIcon className="mr-2 h-6 w-6 sm:h-8 sm:w-8" />
            <span className="font-semibold text-xl sm:text-2xl md:text-3xl">
              Timer
            </span>
          </div>
          <button
            onClick={toggleFullScreen}
            className={`${isDarkMode ? 'text-slate-400 hover:text-slate-200' : 'text-gray-600 hover:text-gray-800'} focus:outline-none`}
          >
            <ArrowsPointingInIcon className="h-6 w-6 sm:h-8 sm:w-8" />
          </button>
        </div>

        <div className="flex flex-col items-center justify-center flex-grow px-4 sm:px-8">
          <div className={`font-bold text-center mb-8 sm:mb-12 ${
            isMobile ? 'text-6xl' : isTablet ? 'text-8xl' : 'text-9xl sm:text-[10rem] md:text-[12rem]'
          }`}>
            {formatTime(time)}
          </div>
          <div className="flex justify-center space-x-6 mb-8">
            <button
              className={`${isDarkMode ? 'bg-slate-700 text-slate-200 hover:bg-slate-600' : 'bg-black text-white hover:bg-gray-800'} 
                rounded-full transition-colors duration-200 
                ${selectedDuration ? '' : 'opacity-50 cursor-not-allowed'} 
                focus:outline-none focus:ring-2 focus:ring-gray-400
                text-2xl sm:text-3xl px-8 py-4 sm:px-10 sm:py-5`}
              onClick={handleStartStop}
              disabled={!selectedDuration}
            >
              {isRunning ? <PauseIcon className="h-8 w-8 sm:h-10 sm:w-10" /> : <PlayIcon className="h-8 w-8 sm:h-10 sm:w-10" />}
            </button>
            <button
              className={`${isDarkMode ? 'bg-slate-600 text-slate-200 hover:bg-slate-500' : 'bg-gray-200 text-gray-800 hover:bg-gray-300'} 
                rounded-full transition-colors duration-200 
                focus:outline-none focus:ring-2 focus:ring-gray-400
                text-2xl sm:text-3xl px-8 py-4 sm:px-10 sm:py-5`}
              onClick={handleReset}
            >
              <ArrowPathIcon className="h-8 w-8 sm:h-10 sm:w-10" />
            </button>
          </div>
        </div>

        <div className="w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl mx-auto pb-4 sm:pb-6 px-4 sm:px-8">
          <CustomDropdown
            options={durationOptions}
            value={selectedDuration / 60}
            onChange={handleSetDuration}
            isFullScreen={isFullScreen}
            isMobile={isMobile}
            isDarkMode={isDarkMode}
          />
        </div>
      </div>
    );
  }

  // Updated normal view with reset icon
  return (
    <div 
      ref={containerRef}
      className={`rounded-lg border p-3 relative cursor-pointer flex items-center transition-all duration-200 ease-in-out min-h-[5rem] ${
        isDarkMode
          ? 'bg-slate-800 border-slate-700 hover:bg-slate-700'
          : 'bg-gray-50 border-gray-200 hover:bg-gray-100'
      }`}
    >
      <div className="w-10 h-10 rounded-full mr-3 flex-shrink-0 flex items-center justify-center">
        <ClockIcon className={`w-6 h-6 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
      </div>
      <div className="flex-grow">
        <h2 className={`text-sm font-semibold ${isDarkMode ? 'text-slate-200' : 'text-gray-800'}`}>Timer</h2>
        <p className={`text-xl font-bold ${isDarkMode ? 'text-slate-200' : 'text-gray-700'}`}>{formatTime(time)}</p>
      </div>
      <div className="flex items-center space-x-2">
        <button
          onClick={handleStartStop}
          className={`flex items-center justify-center w-8 h-8 rounded-full transition-colors duration-200 ${
            isDarkMode ? 'hover:bg-slate-600' : 'hover:bg-gray-200'
          }`}
        >
          {isRunning 
            ? <PauseIcon className={`w-5 h-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`} />
            : <PlayIcon className={`w-5 h-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`} />
          }
        </button>
        <button
          onClick={handleReset}
          className={`flex items-center justify-center w-8 h-8 rounded-full transition-colors duration-200 ${
            isDarkMode ? 'hover:bg-slate-600' : 'hover:bg-gray-200'
          }`}
        >
          <ArrowPathIcon className={`w-5 h-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`} />
        </button>
        <button
          onClick={toggleFullScreen}
          className={`flex items-center justify-center w-8 h-8 rounded-full transition-colors duration-200 ${
            isDarkMode ? 'hover:bg-slate-600' : 'hover:bg-gray-200'
          }`}
        >
          <ArrowsPointingOutIcon className={`w-5 h-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`} />
        </button>
      </div>
    </div>
  );
}

export default Timer;