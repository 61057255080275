import React, { useRef, useState } from 'react';

const OrganizationChart = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const chartRef = useRef(null);

  const toggleFullscreen = async () => {
    if (!document.fullscreenElement) {
      await chartRef.current.requestFullscreen();
      setIsFullscreen(true);
    } else {
      await document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  const departments = {
    leadership: [
      { role: 'ADVISOR', name: 'CHRIS', color: 'border-purple-500 bg-purple-500/10' },
      { role: 'CEO', name: '', color: 'border-blue-500 bg-blue-500/10' },
      { role: 'MENTOR', name: 'JORDAN', color: 'border-purple-500 bg-purple-500/10' }
    ],
    executives: [
      { 
        role: 'CRO', 
        name: 'TBD', 
        color: 'border-pink-500 bg-pink-500/10',
        team: [
          { role: 'BU1-NA', name: 'Akshay' },
          { role: 'BU2-EU', name: 'SAHIL' },
          { role: 'BU3-OTHERS', name: 'VINEETA' },
          { role: 'BU4', name: 'TBD' }
        ]
      },
      { 
        role: 'CFO', 
        name: 'MANGESH', 
        color: 'border-green-500 bg-green-500/10',
        team: [
          { role: 'Jnr Accounts executive', name: 'Sonia' },
          { role: 'Compliance', name: 'TBD' }
        ]
      },
      { 
        role: 'DPO', 
        name: 'Martin De Bruin', 
        color: 'border-yellow-500 bg-yellow-500/10',
        team: [
          { role: 'Compliance', name: 'Reena' },
          { role: 'Compliance', name: 'Pavithra' },
          { role: 'Compliance', name: 'Kushal' }
        ]
      },
      { 
        role: 'COO', 
        name: 'JASMEET SAWHNEY', 
        color: 'border-orange-500 bg-orange-500/10',
        departments: [
          {
            name: 'Data',
            team: [
              { role: '', name: 'Sitanshu' }
            ]
          },
          {
            name: 'Quality & Delivery',
            team: [
              { role: '', name: 'Amit' }
            ]
          },
          {
            name: 'Telemarketing',
            team: [
              { role: 'Pune & Guwahati', name: 'Nilesh Ohri' },
              { role: 'Belfast & Lisbon', name: 'Steven Nutt' },
              { 
                role: 'Strategic accounts', 
                subRole: '(Anteriad & Intentsify)',
                name: 'Tamseel' 
              }
            ]
          },
          {
            name: 'Email Ops',
            team: [
              { role: 'QPT & QPT Lite', name: 'Clyde / Milind' },
              { role: 'Non-QPT & Form fills', name: 'Nithya' },
              { role: 'Internal ops', name: 'Chetan Kamble' }
            ]
          }
        ]
      },
      { 
        role: 'CMO', 
        name: 'TBD', 
        color: 'border-cyan-500 bg-cyan-500/10',
        team: [
          { role: 'EVA AHALWAT' },
          { role: 'SEO / SCM' },
          { role: 'BRANDING', name: 'Vinay Mora & Vinodth' }
        ]
      },
      { 
        role: 'CHRO', 
        name: 'PARTHA', 
        color: 'border-indigo-500 bg-indigo-500/10',
        team: [
          { role: 'HRBP - Pune', name: 'Ketki / Rajashree' },
          { role: 'HRBP - BLR / GH / Belfast', name: 'TBD' },
          { role: 'Recruiter', name: 'Aneesha' },
          { role: 'Payroll', name: 'Kajal' },
          { role: 'HR - Consultant', name: 'Shweta' }
        ]
      },
      { 
        role: 'CIO/CTO', 
        name: 'TBD', 
        color: 'border-rose-500 bg-rose-500/10',
        team: [
          { role: 'CORE IT', name: 'Vrunesh' },
          { role: 'DATA ANALYTICS & REPORTING', name: 'Vignesh' },
          { role: 'DEVOPS', name: 'Prashant' }
        ]
      }
    ]
  };

  const Card = ({ role, name, color = 'border-gray-500 bg-gray-900/40' }) => (
    <div className={`relative group rounded-xl border ${color} backdrop-blur-sm hover:shadow-lg hover:shadow-gray-900/20 transition-all duration-300`}>
      <div className={`px-6 py-4 border-b border-gray-800/50 ${color} rounded-t-xl`}>
        <div className="flex items-center gap-3">
          {role === 'CRO' ? (
            <svg className="w-4 h-4 text-pink-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
            </svg>
          ) : role === 'CFO' ? (
            <svg className="w-4 h-4 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          ) : role === 'DPO' ? (
            <svg className="w-4 h-4 text-yellow-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
            </svg>
          ) : role === 'COO' ? (
            <svg className="w-4 h-4 text-orange-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z" />
            </svg>
          ) : role === 'CMO' ? (
            <svg className="w-4 h-4 text-cyan-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" />
            </svg>
          ) : role === 'CHRO' ? (
            <svg className="w-4 h-4 text-indigo-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
            </svg>
          ) : role === 'CIO/CTO' ? (
            <svg className="w-4 h-4 text-rose-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>
          ) : (
            <svg className="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          )}
          <h3 className="text-sm font-semibold text-white">{role}</h3>
        </div>
      </div>
      <div className="p-4">
        <div className="text-sm font-semibold text-white">{name || 'TBD'}</div>
      </div>
    </div>
  );

  const TeamCard = ({ role, name }) => (
    <div className="relative group rounded-xl border border-gray-800/50 bg-gray-900/30 p-2.5 backdrop-blur-sm hover:shadow-lg hover:shadow-gray-900/20 transition-all duration-300">
      <div className="text-[11px] text-gray-400 line-clamp-1">{role}</div>
      {name && Array.isArray(name) ? (
        <div className="space-y-1">
          {name.map((n, i) => (
            <div key={i} className="text-xs font-medium text-gray-300">{n}</div>
          ))}
        </div>
      ) : name ? (
        <div className="text-xs font-medium text-gray-300 mt-1 line-clamp-1">{name}</div>
      ) : null}
    </div>
  );

  const DepartmentCard = ({ name, team }) => (
    <div className="space-y-1.5">
      <div className="text-xs font-medium text-white bg-gray-900/30 p-2 rounded-xl border border-gray-800/50">
        <div className="flex items-center gap-2">
          <span className="w-1 h-3 bg-orange-500/50 rounded-full"></span>
          {name}
        </div>
      </div>
      <div className="space-y-1.5 pl-2 border-l-2 border-orange-500/10">
        {team.map((member, index) => (
          <div key={index} className="bg-gray-900/30 p-2 rounded-xl border border-gray-800/50 backdrop-blur-sm hover:shadow-lg hover:shadow-gray-900/20 transition-all duration-300">
            {member.role && (
              <div className="text-[10px] text-gray-400 line-clamp-1">
                {member.role}
                {member.subRole && (
                  <span className="text-gray-500 ml-1">({member.subRole})</span>
                )}
              </div>
            )}
            <div className="text-[11px] font-medium text-gray-300 mt-0.5 line-clamp-1">{member.name}</div>
          </div>
        ))}
      </div>
    </div>
  );

  const ExecutiveCard = ({ role, name, color, departments }) => (
    <div className="space-y-3">
      <Card role={role} name={name} color={color} />
      {departments && (
        <div className="grid grid-cols-1 gap-3">
          {departments.map((dept, index) => (
            <DepartmentCard key={index} {...dept} />
          ))}
        </div>
      )}
    </div>
  );

  const LeadershipCard = ({ role, name, color = 'border-gray-800/50 bg-gray-900/30' }) => (
    <div className={`relative group rounded-xl backdrop-blur-sm hover:shadow-lg hover:shadow-gray-900/20 ${color} transition-all duration-300 
      ${role === 'CEO' 
        ? 'w-[200px] border-2 shadow-lg shadow-blue-500/20 transform translate-y-2' 
        : 'w-[180px] border opacity-90'}`}>
      <div className={`px-6 py-4 border-b border-gray-800/50 ${color} rounded-t-xl`}>
        <div className="flex items-center gap-3">
          {role === 'CEO' ? (
            <svg className="w-4 h-4 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          ) : role === 'ADVISOR' ? (
            <svg className="w-4 h-4 text-purple-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
            </svg>
          ) : (
            <svg className="w-4 h-4 text-purple-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
            </svg>
          )}
          <h3 className="text-sm font-semibold text-white">{role}</h3>
        </div>
      </div>
      <div className="p-4 text-center">
        <div className="text-sm font-semibold text-white">{name || 'TBD'}</div>
      </div>
      {role === 'CEO' && (
        <div className="absolute -bottom-4 left-1/2 transform -translate-x-1/2 w-px h-4 bg-gray-800/50"></div>
      )}
    </div>
  );

  return (
    <div ref={chartRef} className="bg-black rounded-xl border border-gray-800 max-w-[1600px] mx-auto my-4">
      <div className="flex justify-between items-center px-4 py-3 border-b border-gray-800/50">
        <div className="flex items-center gap-3">
          <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
          <h2 className="text-base text-white font-medium">Organization Structure</h2>
        </div>
        <button
          onClick={toggleFullscreen}
          className="p-2 rounded-md bg-gray-800 hover:bg-gray-700 transition-colors"
          title={isFullscreen ? "Exit fullscreen" : "Enter fullscreen"}
        >
          {isFullscreen ? (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 9L4 4m0 0l5-5M4 4l5 5M15 9l5-5m0 0l-5-5m5 5l-5 5M9 15l-5 5m0 0l5 5M4 20l5-5M15 15l5 5m0 0l-5 5m5-5l-5-5" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5v-4m0 4h-4m4 0l-5-5" />
            </svg>
          )}
        </button>
      </div>

      <div className={`px-12 py-8 ${isFullscreen ? 'h-screen overflow-auto' : ''}`}>
        {/* Leadership Row */}
        <div className="flex justify-center items-start gap-6 mb-12">
          {departments.leadership.map((leader, index) => (
            <LeadershipCard key={index} {...leader} />
          ))}
        </div>

        {/* Executives Grid */}
        <div className="grid grid-cols-7 gap-6">
          {departments.executives.map((exec, index) => (
            <div key={index} className="space-y-3">
              {exec.departments ? (
                <ExecutiveCard {...exec} />
              ) : (
                <>
                  <Card {...exec} />
                  {exec.team && (
                    <div className="space-y-2">
                      {exec.team.map((member, mIndex) => (
                        <TeamCard key={mIndex} {...member} />
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OrganizationChart; 