import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, Route, Routes, Outlet } from 'react-router-dom'; // Add Outlet
import { UserPlusIcon, PencilIcon, TrashIcon, CpuChipIcon, MagnifyingGlassIcon, XMarkIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../../context/ThemeContext';
import api from '../../api';
import AIAgentCreationWizard from './AIAgentCreationWizard';
import ReactDOM from 'react-dom';
import { motion } from 'framer-motion';

// Add this new component at the top of the file, outside of the main AIAgentsPage component
const NoResultsMessage = ({ searchTerm, onCreateClick, isDarkMode }) => (
  <motion.div 
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.4 }}
    className={`
      flex flex-col items-center justify-center p-8 rounded-2xl
      backdrop-blur-xl backdrop-saturate-150 w-full max-w-md mx-auto
      ${isDarkMode 
        ? 'bg-[#1a1a1a]/90 border border-white/10 shadow-black/50' 
        : 'bg-white/90 border border-black/5 shadow-black/10'}
    `}
  >
    <div className={`
      p-4 rounded-full mb-4
      ${isDarkMode 
        ? 'bg-[#252525] text-blue-400/80' 
        : 'bg-slate-100 text-blue-600/80'}
    `}>
      <CpuChipIcon className="w-8 h-8" />
    </div>
    
    <div className="text-center w-full">
      <motion.h3 
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1 }}
        className={`text-lg font-semibold mb-2 tracking-tight
          ${isDarkMode ? 'text-white' : 'text-slate-900'}
        `}
      >
        {searchTerm ? `No results for "${searchTerm}"` : 'Create your first AI agent'}
      </motion.h3>
      
      <motion.p 
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className={`text-sm mb-6
          ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
        `}
      >
        {searchTerm 
          ? 'Try different keywords or create a new agent' 
          : 'Get started by creating a new AI agent'}
      </motion.p>
      
      <motion.button
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        onClick={onCreateClick}
        className={`
          relative group inline-flex items-center gap-2 
          px-4 py-2 rounded-lg text-sm font-medium
          transition-all duration-200
          ${isDarkMode 
            ? 'bg-[#252525] hover:bg-[#2a2a2a] text-slate-200 border border-white/10' 
            : 'bg-slate-900 hover:bg-slate-800 text-white'}
        `}
      >
        <UserPlusIcon className="w-4 h-4" />
        <span>New AI Agent</span>
      </motion.button>
    </div>
  </motion.div>
);

// Update the EmptyState component
const EmptyState = ({ isDarkMode, onCreateClick, searchTerm = '' }) => (
  <motion.div 
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.4 }}
    className={`
      flex flex-col items-center justify-center p-8 rounded-2xl
      backdrop-blur-xl backdrop-saturate-150 w-full max-w-md mx-auto
      ${isDarkMode 
        ? 'bg-[#1a1a1a]/90 border border-white/10 shadow-black/50' 
        : 'bg-white/90 border border-black/5 shadow-black/10'}
    `}
  >
    <div className={`
      p-4 rounded-full mb-4
      ${isDarkMode 
        ? 'bg-[#252525] text-blue-400/80' 
        : 'bg-slate-100 text-blue-600/80'}
    `}>
      <CpuChipIcon className="w-8 h-8" />
    </div>
    
    <div className="text-center w-full">
      <motion.h3 
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1 }}
        className={`text-lg font-semibold mb-2 tracking-tight
          ${isDarkMode ? 'text-white' : 'text-slate-900'}
        `}
      >
        {searchTerm ? `No results for "${searchTerm}"` : 'Create your first AI agent'}
      </motion.h3>
      
      <motion.p 
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className={`text-sm mb-6
          ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
        `}
      >
        {searchTerm 
          ? 'Try different keywords or create a new agent' 
          : 'Get started by creating a new AI agent'}
      </motion.p>
      
      <motion.button
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        onClick={onCreateClick}
        className={`
          relative group inline-flex items-center gap-2 
          px-4 py-2 rounded-lg text-sm font-medium
          transition-all duration-200
          ${isDarkMode 
            ? 'bg-[#252525] hover:bg-[#2a2a2a] text-slate-200 border border-white/10' 
            : 'bg-slate-900 hover:bg-slate-800 text-white'}
        `}
      >
        <UserPlusIcon className="w-4 h-4" />
        <span>New AI Agent</span>
      </motion.button>
    </div>
  </motion.div>
);

// Add this new component at the top, after NoResultsMessage
const DropdownMenu = ({ isDarkMode, onEdit, onDelete, position, menuRef }) => {
  if (!position) return null;

  return ReactDOM.createPortal(
    <div 
      ref={menuRef}
      style={{
        position: 'fixed',
        top: `${position.top}px`,
        left: `${position.left}px`,
        zIndex: 9999,
      }}
      className={`min-w-[8rem] overflow-hidden rounded-md border p-1 shadow-md
                 ${isDarkMode 
                   ? 'bg-[#121212] border-[#333333]' 
                   : 'bg-white border-slate-200'}`}
    >
      <button
        onClick={onEdit}
        className={`flex w-full items-center gap-2 rounded-sm px-3 py-2 text-sm transition-colors
                   ${isDarkMode 
                     ? 'hover:bg-[#1a1a1a] text-slate-400' 
                     : 'hover:bg-slate-100 text-slate-600'}`}
      >
        <PencilIcon className="h-4 w-4" />
        Edit
      </button>
      <button
        onClick={onDelete}
        className={`flex w-full items-center gap-2 rounded-sm px-3 py-2 text-sm transition-colors
                   text-red-500 hover:text-red-600
                   ${isDarkMode ? 'hover:bg-[#1a1a1a]' : 'hover:bg-slate-100'}`}
      >
        <TrashIcon className="h-4 w-4" />
        Delete
      </button>
    </div>,
    document.body
  );
};

// Update the AIAgentCard component
const AIAgentCard = ({ agent, isPopup, onAgentClick, onEdit, onDelete, isDarkMode }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState(null);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target) && 
          buttonRef.current && !buttonRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleMoreOptions = (e) => {
    e.stopPropagation();
    const buttonRect = buttonRef.current.getBoundingClientRect();
    setMenuPosition({
      top: buttonRect.bottom + window.scrollY,
      left: buttonRect.right - 128,
    });
    setIsMenuOpen(!isMenuOpen);
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    onEdit(agent._id);
    setIsMenuOpen(false);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(agent._id);
    setIsMenuOpen(false);
  };

  return (
    <div 
      onClick={() => onAgentClick(agent)}
      className={`${isDarkMode ? 'bg-[#151515] hover:bg-[#1a1a1a]' : 'bg-white hover:bg-gray-50'} 
                  rounded-lg border ${isDarkMode ? 'border-[#333333]' : 'border-gray-200'} 
                  p-3 relative cursor-pointer transition-colors`}
    >
      <div className="flex items-center gap-3">
        {/* Avatar */}
        <div className="relative flex-shrink-0">
          <div className={`w-10 h-10 rounded-full overflow-hidden ${isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-100'}`}>
            {agent.avatarUrl ? (
              <img 
                src={agent.avatarUrl} 
                alt={agent.name}
                className="w-full h-full object-cover"
              />
            ) : (
              <div className="w-full h-full flex items-center justify-center">
                <CpuChipIcon className={`w-6 h-6 ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`} />
              </div>
            )}
          </div>
        </div>

        {/* Content */}
        <div className="flex-1 min-w-0">
          <h2 className={`text-sm font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-800'} truncate`}>
            {agent.name}
          </h2>
          <div className={`text-xs ${isDarkMode ? 'text-slate-400' : 'text-gray-500'} mt-0.5`}>
            {agent.description?.length > 40 
              ? `${agent.description.substring(0, 40)}...` 
              : agent.description}
          </div>
        </div>

        {/* Actions Menu */}
        {!isPopup && (
          <div className="relative">
            <button
              ref={buttonRef}
              onClick={handleMoreOptions}
              className={`rounded-full p-1.5 transition-colors duration-200
                         ${isDarkMode 
                           ? 'hover:bg-[#202020] text-slate-400' 
                           : 'hover:bg-gray-100 text-gray-500'}`}
            >
              <EllipsisHorizontalIcon className="h-4 w-4" />
            </button>

            {isMenuOpen && (
              <DropdownMenu
                isDarkMode={isDarkMode}
                onEdit={handleEdit}
                onDelete={handleDelete}
                position={menuPosition}
                menuRef={menuRef}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const AIAgentsPage = ({ isPopup = false, onSelectAgent }) => {
  const [agents, setAgents] = useState([]);
  const [isInitialFetchDone, setIsInitialFetchDone] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [agentToDelete, setAgentToDelete] = useState(null);
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const [showCreationWizard, setShowCreationWizard] = useState(false); // Add this state
  const [avatars, setAvatars] = useState({});

  const fetchAgents = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get('/api/ai-agents');
      const agentsWithAvatars = await Promise.all(response.data.map(async (agent) => {
        if (agent.avatar) {
          try {
            const avatarResponse = await api.get(`/api/ai-agents/${agent._id}/avatar`);
            return { ...agent, avatarUrl: avatarResponse.data.avatarUrl };
          } catch (error) {
            console.error('Error fetching avatar URL:', error);
            return agent;
          }
        }
        return agent;
      }));
      setAgents(agentsWithAvatars);
    } catch (error) {
      console.error('Error fetching AI agents:', error);
      // Handle error (e.g., show error message to user)
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!isInitialFetchDone) {
      fetchAgents().then(() => setIsInitialFetchDone(true));
    }
  }, [fetchAgents, isInitialFetchDone]);

  const handleEdit = (agentId) => {
    navigate(`edit/${agentId}`);
  };

  const handleDeleteClick = (agentId) => {
    setAgentToDelete(agentId);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    if (agentToDelete) {
      try {
        await api.delete(`/api/ai-agents/${agentToDelete}`);
        setAgents(agents.filter(agent => agent._id !== agentToDelete));
        setShowDeleteModal(false);
        setAgentToDelete(null);
      } catch (error) {
        console.error('Error deleting AI agent:', error);
        alert('Failed to delete AI agent. Please try again.');
      }
    }
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
    setAgentToDelete(null);
  };

  const handleAgentClick = (agent) => {
    if (isPopup && onSelectAgent) {
      onSelectAgent(agent);
    }
  };

  // Add these new state variables at the top of your component
  const [filteredAgents, setFilteredAgents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  // Add these new functions
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    if (isSearchOpen) {
      setSearchTerm('');
    }
  };

  // Add this useEffect to filter agents based on search term
  useEffect(() => {
    if (agents.length > 0) {
      const lowercasedFilter = searchTerm.toLowerCase();
      const filtered = agents.filter(agent =>
        agent.name.toLowerCase().includes(lowercasedFilter) ||
        agent.description.toLowerCase().includes(lowercasedFilter)
      );
      setFilteredAgents(filtered);
    }
  }, [searchTerm, agents]);

  // Update the handleCreateAgent function to work with the new component
  const handleCreateAgent = () => {
    if (isPopup) {
      // Handle creation in popup mode (if needed)
    } else {
      navigate('create');
    }
  };

  const handleSaveAgent = async (agentData) => {
    await fetchAgents();
    navigate('');
  };

  return (
    <Routes>
      <Route path="/" element={
        <div className={`flex flex-col h-full relative overflow-hidden bg-transparent
                        min-w-[300px] w-full max-w-full
                        mx-auto transition-all duration-300 ease-in-out`}>
          {/* Top Bar */}
          <div className={`${isDarkMode ? 'bg-[#121212] bg-opacity-90' : 'bg-white bg-opacity-90'} 
                        !h-10 min-h-10 flex-shrink-0 flex items-center justify-between px-4 py-2 w-full 
                        ${isDarkMode ? 'border-[#333333]' : 'border-gray-200'} border-b`}>
            {/* Left section */}
            <div className="flex items-center">
              <CpuChipIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'} mr-2`} />
              <h2 className={`text-sm font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>AI Agents</h2>
            </div>

            {/* Right section */}
            <div className="flex items-center space-x-2">
              {isSearchOpen ? (
                <div className="relative">
                  <input
                    type="text"
                    className={`w-48 px-3 py-1 text-sm border rounded-md pl-8 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                      isDarkMode
                        ? 'bg-[#1a1a1a] border-[#333333] text-slate-200 placeholder-slate-400'
                        : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                    }`}
                    placeholder="Search AI agents..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  <MagnifyingGlassIcon className={`absolute left-2 top-1.5 h-4 w-4 ${
                    isDarkMode ? 'text-slate-400' : 'text-gray-400'
                  }`} />
                  <button
                    onClick={toggleSearch}
                    className={`absolute right-2 top-1.5 ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`}
                  >
                    <XMarkIcon className="h-4 w-4" />
                  </button>
                </div>
              ) : (
                <button
                  onClick={toggleSearch}
                  className={`p-1 rounded-md ${
                    isDarkMode ? 'hover:bg-slate-700' : 'hover:bg-gray-100'
                  }`}
                >
                  <MagnifyingGlassIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
                </button>
              )}
              <button 
                onClick={handleCreateAgent} // Update this line
                className={`flex items-center px-2 py-1 rounded-md transition-colors duration-200 text-sm
                            ${isDarkMode 
                              ? 'bg-[#1a1a1a] hover:bg-[#202020] text-slate-200' 
                              : 'bg-gray-100 hover:bg-gray-200 text-gray-700'}`}
                title="Create New AI Agent"
              >
                <UserPlusIcon className={`w-4 h-4 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'} mr-1`} />
                Create AI Agent
              </button>
            </div>
          </div>

          {/* Content Area */}
          <div className={`flex-grow overflow-y-auto custom-scrollbar ${isDarkMode ? 'bg-[#121212] bg-opacity-80' : 'bg-white bg-opacity-80'}`}>
            <div className="max-w-3xl mx-auto px-4 pb-20 h-full">
              {isLoading ? (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pt-4">
                  {[...Array(4)].map((_, index) => (
                    <div 
                      key={index}
                      className={`h-[72px] rounded-lg animate-pulse
                                ${isDarkMode ? 'bg-[#151515]' : 'bg-gray-100'}`}
                    />
                  ))}
                </div>
              ) : filteredAgents.length > 0 ? (
                <div className={`grid grid-cols-1 md:grid-cols-${filteredAgents.length === 1 ? '1' : '2'} gap-4 pt-4 ${
                  filteredAgents.length === 1 ? 'max-w-[400px]' : ''
                } mx-auto`}>
                  {filteredAgents.map((agent) => (
                    <AIAgentCard
                      key={agent._id}
                      agent={agent}
                      isPopup={isPopup}
                      onAgentClick={handleAgentClick}
                      onEdit={handleEdit}
                      onDelete={handleDeleteClick}
                      isDarkMode={isDarkMode}
                    />
                  ))}
                </div>
              ) : (
                <div className="h-[calc(100vh-10rem)] flex items-center justify-center px-4">
                  {searchTerm ? (
                    <NoResultsMessage 
                      searchTerm={searchTerm}
                      onCreateClick={handleCreateAgent}
                      isDarkMode={isDarkMode}
                    />
                  ) : (
                    <EmptyState 
                      isDarkMode={isDarkMode}
                      onCreateClick={handleCreateAgent}
                      searchTerm={searchTerm}
                    />
                  )}
                </div>
              )}
            </div>
          </div>

          {/* Delete Confirmation Modal */}
          {showDeleteModal && (
            <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
              <div className={`relative w-full max-w-sm transform overflow-hidden rounded-lg shadow-xl transition-all
                              ${isDarkMode ? 'bg-[#151515]' : 'bg-white'}`}>
                <div className="p-4">
                  {/* Header and Content Combined */}
                  <div className="mb-4">
                    <h3 className={`text-base font-semibold mb-2 
                                  ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>
                      Delete AI Agent
                    </h3>
                    <p className={`text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}`}>
                      Are you sure? This action cannot be undone.
                    </p>
                  </div>

                  {/* Actions */}
                  <div className="flex justify-end gap-2">
                    <button
                      onClick={cancelDelete}
                      className={`px-3 py-1.5 rounded-md text-sm font-medium
                                transition-all duration-200
                                ${isDarkMode 
                                  ? 'bg-[#1a1a1a] text-slate-200 hover:bg-[#202020] border border-[#333333]' 
                                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'}`}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={confirmDelete}
                      className={`px-3 py-1.5 rounded-md text-sm font-medium text-white
                                transition-all duration-200
                                bg-red-500 hover:bg-red-600
                                ${isDarkMode ? 'bg-opacity-90' : ''}`}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      } />
      <Route path="create" element={<AIAgentCreationWizard onSave={handleSaveAgent} />} />
      <Route path="edit/:agentId" element={<AIAgentCreationWizard onSave={handleSaveAgent} />} />
    </Routes>
  );
};

export default AIAgentsPage;