import React, { useState } from 'react';

export const formatFileSize = (bytes) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

const ItemTooltip = ({ children, item }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const content = (
    <div className="absolute z-10 p-2 bg-white border border-gray-200 rounded shadow-lg text-sm">
      <p><strong>Name:</strong> {item.name}</p>
      <p><strong>Type:</strong> {item.type}</p>
      {item.size && <p><strong>Size:</strong> {formatFileSize(item.size)}</p>}
      {item.createdAt && <p><strong>Created:</strong> {new Date(item.createdAt).toLocaleString()}</p>}
      {item.updatedAt && <p><strong>Last modified:</strong> {new Date(item.updatedAt).toLocaleString()}</p>}
    </div>
  );

  return (
    <div 
      className="relative"
      onMouseEnter={() => setIsTooltipVisible(true)}
      onMouseLeave={() => setIsTooltipVisible(false)}
    >
      {children}
      {isTooltipVisible && content}
    </div>
  );
};

export default ItemTooltip;