import React, { useState, useEffect } from 'react';
import { MicrophoneIcon, StopIcon, PauseIcon, PlayIcon, XMarkIcon } from '@heroicons/react/24/solid';
import Draggable from 'react-draggable';
import { createPortal } from 'react-dom';

const MiniAudioRecorder = ({ 
  isRecording, 
  isPaused, 
  recordingTime,
  onStop,
  onPause,
  onResume,
  onDiscard
}) => {
  // Initialize position relative to viewport
  const [position, setPosition] = useState({ 
    x: Math.max(window.innerWidth - 300, 0), 
    y: 20  // Smaller top margin
  });

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  // Add resize handler to keep recorder in bounds
  useEffect(() => {
    const handleResize = () => {
      setPosition(prev => ({
        x: Math.min(prev.x, Math.max(window.innerWidth - 300, 0)),
        y: Math.min(prev.y, Math.max(window.innerHeight - 100, 0))
      }));
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Use createPortal to render the mini recorder at the root level
  return createPortal(
    <Draggable
      position={position}
      onStop={(e, data) => {
        // Add bounds checking when setting new position
        const x = Math.min(Math.max(data.x, 0), window.innerWidth - 300);
        const y = Math.min(Math.max(data.y, 0), window.innerHeight - 100);
        setPosition({ x, y });
      }}
      bounds="body"
    >
      <div className="fixed z-[9999] cursor-move" style={{ position: 'fixed', top: 0, left: 0 }}>
        <div className="flex items-center gap-2 bg-black text-white rounded-full px-3 py-2 shadow-lg">
          <div className="animate-pulse">
            <div className="w-2 h-2 bg-red-500 rounded-full"></div>
          </div>
          <span className="text-sm font-medium">{formatTime(recordingTime)}</span>
          <button
            onClick={isPaused ? onResume : onPause}
            className="p-1 hover:bg-gray-700 rounded-full"
          >
            {isPaused ? (
              <PlayIcon className="w-4 h-4" />
            ) : (
              <PauseIcon className="w-4 h-4" />
            )}
          </button>
          <button
            onClick={onStop}
            className="p-1 hover:bg-gray-700 rounded-full"
          >
            <StopIcon className="w-4 h-4" />
          </button>
          <button
            onClick={onDiscard}
            className="p-1 hover:bg-gray-700 rounded-full"
          >
            <XMarkIcon className="w-4 h-4" />
          </button>
        </div>
      </div>
    </Draggable>,
    document.body
  );
};

export default MiniAudioRecorder;
