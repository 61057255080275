import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useAuth } from './context/AuthContext';
import axios from 'axios';
import api from './api';
import './markdown-styles.css';
import SideBar from './components/SideBar';
import MobileBar from './components/MobileBar'; // Import the new MobileBar component
import Settings from './components/Settings';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { AuthContext, AuthProvider } from './context/AuthContext';
import { ThemeProvider } from './context/ThemeContext';
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery
import Login from './components/Login';
import Register from './components/Register';
import './App.css';
import LoadingOverlay from './components/LoadingOverlay';
import ChatInterface from './components/ChatInterface';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NotesProvider } from './context/NotesContext';
import Help from './components/Help';
import Home from './components/Home';
import { TimerProvider } from './context/TimerContext';
import TimerBubble from './components/TimerBubble';
import TranscriptionScreen from './components/TranscriptionScreen';
import SavedItemsScreen from './components/SavedItemsScreen';
import ChatHistory from './components/ChatHistory';
import AnimatedPage from './components/AnimatedPage';
import { AnimatePresence } from 'framer-motion';
import KnowledgeBaseManager from './components/KnowledgeBaseManager';
import OfflineIndicator from './components/OfflineIndicator';
import { ChatProvider } from './context/ChatContext';
import { useTheme } from './context/ThemeContext';
import LandingPage from './components/LandingPage';
import PersonasPage from './components/personas/PersonasPage';
import PersonaCreationWizard from './components/personas/PersonaCreationWizard';
import AIAgentsPage from './components/agents/AIAgentsPage';
import FullScreenTimer from './components/FullScreenTimer';
import IdeasPage from './components/ideas/IdeasPage';
import FullScreenNote from './components/ideas/FullScreenNote';
import { XMarkIcon } from '@heroicons/react/24/outline';
import LiveDashboard from './components/LiveDashboard';
import TargetAudiencesPage from './components/targetAudiences/TargetAudiencesPage';
import ScheduleLinkedInPosts from './components/ScheduleLinkedInPosts';
import MiniAudioRecorder from './components/MiniAudioRecorder';
import YouIdea from './components/YouIdea/YouIdea';
import UserAnalytics from './components/UserAnalytics';
import TestCharts from './components/TestCharts';
import VoiceNotePage from './components/VoiceNotePage';
import PostComments from './components/PostComments/PostComments';
import CommentHistory from './components/PostComments/CommentHistory';
import { PostCommentsProvider } from './context/PostCommentsContext';
import ErrorBoundary from './components/ErrorBoundary';
import { setViewportHeight } from './utils/viewportFix';
import { RecentActivitiesProvider } from './context/RecentActivitiesContext';
import ContentStrategy from './components/ContentStrategy/ContentStrategy';
import ContentStrategyManager from './components/ContentStrategy/ContentStrategyManager';
import ViewStrategy from './components/ContentStrategy/ViewStrategy';
import IdeasManager from './components/SavedIdeas/IdeasManager';  // Note: capital 'I' in Ideas
import { Toaster } from 'react-hot-toast';
import { SearchProvider, useSearch } from './context/SearchContext';
import SearchModal from './components/GlobalSearch/SearchModal';
import LinkedInAuthSuccess from './components/LinkedInAuthSuccess';
import ProtectedRoute from './components/ProtectedRoute';
import VerificationPage from './components/VerificationPage';
import AuthCallback from './components/AuthCallback';

// Wrapper component to handle keyboard shortcuts
const SearchWrapper = ({ children }) => {
  const { openSearch } = useSearch();

  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
        e.preventDefault();
        openSearch();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [openSearch]);

  return children;
};

// Main App component
function App() {
  const { setUser } = useAuth();
  
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const accessToken = localStorage.getItem('accessToken');
    
    if (storedUser && accessToken) {
      console.log('Initializing user state from localStorage');
      setUser(JSON.parse(storedUser));
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
  }, [setUser]);

  return (
    <Router>
      <ThemeProvider>
        <SearchProvider>
          <SearchModal />
          
          <SearchWrapper>
            <AuthProvider>
              <NotesProvider>
                <TimerProvider>
                  <RecentActivitiesProvider>
                    <ChatProvider>
                      <PostCommentsProvider>
                        {/* Main app content */}
                        <div className="App h-screen h-[100dvh] h-[calc(var(--vh,1vh)*100)] flex flex-col overflow-hidden">
                          <AppContent />
                        </div>
                      </PostCommentsProvider>
                    </ChatProvider>
                  </RecentActivitiesProvider>
                </TimerProvider>
              </NotesProvider>
            </AuthProvider>
          </SearchWrapper>
          <Toaster 
            position="bottom-right"
            toastOptions={{
              duration: 3000,
              style: {
                background: '#333',
                color: '#fff',
              },
              success: {
                duration: 3000,
                theme: {
                  primary: '#4aed88',
                },
              },
            }}
          />
        </SearchProvider>
      </ThemeProvider>
    </Router>
  );
}

// AppContent component to handle the main application logic
function AppContent({ isFullScreenTimerVisible, setIsFullScreenTimerVisible, onTimerClick }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, loading: authLoading } = useContext(AuthContext);
  const { isDarkMode } = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [currentScreen, setCurrentScreen] = useState(() => {
    const savedScreen = localStorage.getItem('currentScreen');
    return savedScreen !== null ? savedScreen : 'chat';
  });
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(() => {
    const savedState = localStorage.getItem('sidebarCollapsed');
    return savedState === null ? false : JSON.parse(savedState);
  });

  const [activeScreen, setActiveScreen] = useState('home');
  const [isFullScreenTimerOpen, setIsFullScreenTimerOpen] = useState(false);
  const [isFocusMode, setIsFocusMode] = useState(false);
  const [sidebarStateBeforeFocusMode, setSidebarStateBeforeFocusMode] = useState(null);
  const [showFocusModeInstruction, setShowFocusModeInstruction] = useState(() => {
    return localStorage.getItem('focusModeInstructionShown') !== 'true';
  });

  const isMobile = useMediaQuery({ maxWidth: 768 }); // Define isMobile here

  const toggleFullScreenTimer = () => {
    setIsFullScreenTimerOpen(prev => !prev);
  };

  useEffect(() => {
    const checkAuth = async () => {
      setIsLoading(false);
    };

    checkAuth();
  }, []);

  useEffect(() => {
    localStorage.setItem('currentScreen', currentScreen);
  }, [currentScreen]);

  useEffect(() => {
    localStorage.setItem('sidebarCollapsed', JSON.stringify(isSidebarCollapsed));
  }, [isSidebarCollapsed]);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        console.log('Fetching initial data');
        console.log('Initial data fetched successfully');
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching initial data:', error);
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    const path = location.pathname;
    if (path === '/') setActiveScreen('home');
    else if (path.startsWith('/chat')) setActiveScreen('chat');
    else if (path === '/notes') setActiveScreen('my vault');
    else if (path === '/files') setActiveScreen('my vault');
    else if (path === '/saved-items') setActiveScreen('my vault');
    else if (path === '/voice-notes') setActiveScreen('voice notes');
    else setActiveScreen('');
  }, [location]);

  // Add this new state
  const [isTransitioning, setIsTransitioning] = useState(false);

  const handleFocusModeToggle = useCallback((isActive) => {
    setIsTransitioning(true);
    if (isActive) {
      // Entering focus mode
      setSidebarStateBeforeFocusMode(isSidebarCollapsed);
      setIsSidebarCollapsed(true);
      
      // Only show instruction if it hasn't been shown before
      if (showFocusModeInstruction) {
        setShowFocusModeInstruction(true);
        localStorage.setItem('focusModeInstructionShown', 'true');
      }
      
      // Request full-screen mode
      const element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) { // Firefox
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) { // Chrome, Safari and Opera
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) { // IE/Edge
        element.msRequestFullscreen();
      }
    } else {
      // Exiting focus mode
      setIsSidebarCollapsed(sidebarStateBeforeFocusMode);
      
      // Exit full-screen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { // Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { // IE/Edge
        document.msExitFullscreen();
      }
    }
    
    setIsFocusMode(isActive);
    setTimeout(() => setIsTransitioning(false), 300); // Match this with your transition duration
  }, [isSidebarCollapsed, sidebarStateBeforeFocusMode, showFocusModeInstruction]);

  const dismissFocusModeInstruction = useCallback(() => {
    setShowFocusModeInstruction(false);
  }, []);

  // New component for focus mode instruction
  const FocusModeInstruction = () => (
    <div className={`fixed top-4 left-1/2 transform -translate-x-1/2 z-50 px-4 py-2 rounded-md shadow-lg ${
      isDarkMode ? 'bg-gray-800 text-gray-200' : 'bg-white text-gray-800'
    }`}>
      <div className="flex items-center">
        <span className="mr-2">Move cursor to left edge to show menu</span>
        <button
          onClick={dismissFocusModeInstruction}
          className={`p-1 rounded-full ${
            isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-200'
          }`}
        >
          <XMarkIcon className="w-5 h-5" />
        </button>
      </div>
    </div>
  );

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement && isFocusMode) {
        // User exited fullscreen using browser controls
        setIsFocusMode(false);
        setIsSidebarCollapsed(sidebarStateBeforeFocusMode);
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [isFocusMode, sidebarStateBeforeFocusMode]);

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape' && isFocusMode) {
        handleFocusModeToggle(false);
      }
    };

    document.addEventListener('keydown', handleEscKey);
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [isFocusMode, handleFocusModeToggle]);

  useEffect(() => {
    const setVH = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    setVH();
    window.addEventListener('resize', setVH);

    return () => window.removeEventListener('resize', setVH);
  }, []);

  // Inside AppContent component, add this new state
  const [activeSubmenu, setActiveSubmenu] = useState(null);

  // Add this handler function
  const handleMenuItemClick = (item, path) => {
    if (item.children) {
      setActiveSubmenu(activeSubmenu === item.title ? null : item.title);
    } else if (path) {
      navigate(path);
      // Close submenu on mobile
      if (window.innerWidth <= 768) {
        setActiveSubmenu(null);
      }
    }
  };

  // Add these new states near the other state declarations in AppContent
  const [isRecording, setIsRecording] = useState(false);
  const [recordingState, setRecordingState] = useState({
    isRecording: false,
    isPaused: false,
    recordingTime: 0,
    onStop: null,
    onPause: null,
    onResume: null,
    onDiscard: null
  });

  // Add this useEffect to handle cleanup when component unmounts
  useEffect(() => {
    return () => {
      // Reset recording state when component unmounts
      setRecordingState({
        isRecording: false,
        isPaused: false,
        recordingTime: 0,
        onStop: null,
        onPause: null,
        onResume: null,
        onDiscard: null
      });
    };
  }, []);

  if (isLoading || authLoading) {
    return (
      <div className="fixed inset-0 w-full h-full min-h-screen">
        <LoadingOverlay onLoadingComplete={() => {
          setIsLoading(false);
        }} />
      </div>
    );
  }

  return (
    <div className={`App h-screen h-[100dvh] h-[calc(var(--vh,1vh)*100)] flex flex-col overflow-hidden ${
      isDarkMode ? 'bg-black' : 'bg-gray-100'
    }`}>
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route
            path="/"
            element={
              user ? (
                <Navigate to="/chat" replace />
              ) : (
                <AnimatedPage><LandingPage /></AnimatedPage>
              )
            }
          />
          <Route path="/login" element={<AnimatedPage><Login /></AnimatedPage>} />
          <Route path="/register" element={<AnimatedPage><Register /></AnimatedPage>} />
          <Route path="/auth/verify-email" element={<AnimatedPage><VerificationPage /></AnimatedPage>} />
          <Route path="/auth/callback" element={<AnimatedPage><AuthCallback /></AnimatedPage>} />
          <Route path="/auth/linkedin/callback" element={<AnimatedPage><Register /></AnimatedPage>} />
          <Route
            path="/*"
            element={
              user ? (
                <PrivateRoutes>
                  <div className={`h-full w-full flex overflow-hidden ${
                    isFocusMode ? 'p-2 gap-0' : 'p-2 gap-2'
                  }`}>
                    {isMobile ? (
                      <MobileBar
                        onTimerClick={onTimerClick}
                        onFocusModeToggle={handleFocusModeToggle}
                        isFocusMode={isFocusMode}
                      />
                    ) : (
                      <SideBar
                        currentScreen={currentScreen}
                        setCurrentScreen={setCurrentScreen}
                        isSidebarCollapsed={isSidebarCollapsed}
                        setIsSidebarCollapsed={setIsSidebarCollapsed}
                        activeScreen={activeScreen}
                        onTimerClick={onTimerClick}
                        onFocusModeToggle={handleFocusModeToggle}
                        isFocusMode={isFocusMode}
                        activeSubmenu={activeSubmenu}
                        onMenuItemClick={handleMenuItemClick}
                      />
                    )}
                    <div className={`flex-grow overflow-hidden transition-all duration-300 ease-in-out ${
                      isDarkMode 
                        ? 'bg-black' 
                        : 'bg-gray-100'
                      } ${isMobile ? 'pt-14 pl-0 pr-2 pb-2' : ''} ${
                      isFocusMode ? 'pl-0' : ''
                    }`}>
                      <div className={`h-full overflow-y-auto ${
                        // Only add rounded corners and border if not on chat or canvas routes
                        !location.pathname.includes('/chat') && !location.pathname.includes('/canvas')
                          ? `rounded-lg border ${
                              isDarkMode 
                                ? 'border-[#333333]' 
                                : 'border-gray-200'
                            }`
                          : ''
                      }`}>
                        <Routes location={location} key={location.pathname}>
                          <Route path="/chat/*" element={
                            <AnimatedPage>
                              <ChatInterface />
                            </AnimatedPage>
                          }>
                            <Route path="canvas/:canvasId" element={null} />
                          </Route>
                          <Route path="/chat/canvas" element={<AnimatedPage><ChatInterface /></AnimatedPage>} />
                          <Route path="/chat/canvas/:canvasId" element={<AnimatedPage><ChatInterface /></AnimatedPage>} />
                          <Route path="/my-profile" element={<AnimatedPage><Settings /></AnimatedPage>} />
                          <Route path="/help" element={<AnimatedPage><Help /></AnimatedPage>} />
                          <Route path="/voice-notes" element={
                            <AnimatedPage>
                              <TranscriptionScreen setRecordingState={setRecordingState} />
                            </AnimatedPage>
                          } />
                          <Route path="/saved-items" element={<AnimatedPage><SavedItemsScreen /></AnimatedPage>} />
                          <Route path="/chats" element={<AnimatedPage><ChatHistory /></AnimatedPage>} />
                          <Route path="/files/*" element={<KnowledgeBaseManager />} />
                          <Route path="/personas" element={<PersonasPage />} />
                          <Route path="/personas/create" element={<PersonaCreationWizard onSave={(personaData) => {
                            // Handle saving the persona data
                            console.log('Saving persona:', personaData);
                            // You'll need to implement the API call to save the persona
                          }} />} />
                          <Route path="/personas/edit/:personaId" element={<PersonaCreationWizard onSave={(personaData) => {
                            // Handle updating the persona data
                            console.log('Updating profile:', personaData);
                            // You'll need to implement the API call to update the persona
                          }} />} />
                          <Route path="/agents/*" element={<AIAgentsPage />} />
                          <Route path="/text-notes" element={<AnimatedPage><IdeasPage /></AnimatedPage>} />
                          <Route path="/live-dashboard" element={<LiveDashboard />} />
                          <Route path="/target-audiences/*" element={<TargetAudiencesPage />} />
                          {/* Add the new route for Schedule */}
                          <Route path="/schedule" element={<AnimatedPage><ScheduleLinkedInPosts /></AnimatedPage>} />
                          <Route path="/youidea" element={<AnimatedPage><YouIdea /></AnimatedPage>} />
                          <Route path="/analytics" element={<UserAnalytics />} />
                          <Route path="/test-charts" element={<AnimatedPage><TestCharts /></AnimatedPage>} />
                          <Route 
                            path="/voice-notes/:id" 
                            element={
                              <TranscriptionScreen>
                                <VoiceNotePage />
                              </TranscriptionScreen>
                            } 
                          />
                          <Route path="/ideas" element={<IdeasPage />} />
                          <Route 
                            path="/ideas/:id" 
                            element={
                              <FullScreenNote 
                                key={location.pathname} // Add this key prop
                                isDarkMode={isDarkMode}
                                // ... other props
                              />
                            } 
                          />
                          <Route path="/post-comments" element={
                            <AnimatedPage>
                              <PostComments />
                            </AnimatedPage>
                          } />
                          <Route path="/comments/history" element={
                            <ErrorBoundary>
                              <CommentHistory />
                            </ErrorBoundary>
                          } />
                          <Route path="/content-strategy" element={
                            <AnimatedPage>
                              <ContentStrategy />
                            </AnimatedPage>
                          } />
                          <Route path="/content-strategy/manager" element={
                            <AnimatedPage>
                              <ContentStrategyManager />
                            </AnimatedPage>
                          } />
                          <Route 
                            path="/content-strategy/view/:id" 
                            element={
                              <AnimatedPage>
                                <ViewStrategy />
                              </AnimatedPage>
                            }
                          />
                          <Route 
                            path="/saved-ideas" 
                            element={
                              <AnimatedPage>
                                <IdeasManager />
                              </AnimatedPage>
                            } 
                          />
                          <Route path="/auth/linkedin/success" element={
                            <ProtectedRoute>
                              <LinkedInAuthSuccess />
                            </ProtectedRoute>
                          } />
                        </Routes>
                      </div>
                    </div>
                  </div>
                </PrivateRoutes>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
        </Routes>
      </AnimatePresence>
      <TimerBubble />
      <ToastContainer />
      <OfflineIndicator />
      {isFullScreenTimerOpen && (
        <FullScreenTimer onClose={toggleFullScreenTimer} />
      )}
      {recordingState.isRecording && (
        <MiniAudioRecorder
          isRecording={recordingState.isRecording}
          isPaused={recordingState.isPaused}
          recordingTime={recordingState.recordingTime}
          onStop={recordingState.onStop}
          onPause={recordingState.onPause}
          onResume={recordingState.onResume}
          onDiscard={recordingState.onDiscard}
        />
      )}
    </div>
  );
}

// PrivateRoutes component to handle authenticated routes
const PrivateRoutes = ({ children }) => {
  const { user, loading } = useContext(AuthContext);
  const location = useLocation();
  
  if (loading) {
    return (
      <div className="fixed inset-0 w-full h-full min-h-screen">
        <LoadingOverlay onLoadingComplete={() => {
          // Handle loading complete if needed
        }} />
      </div>
    );
  }
  
  if (!user) {
    return <Navigate to="/" replace />;
  }

  // Redirect to /home if the user is authenticated and trying to access the root path
  if (user && location.pathname === '/') {
    return <Navigate to="/home" replace />;
  }
  
  return children;
};

export default App;