// Create this new file to manage localStorage operations
export const clearAppStorage = () => {
  // List all app-related localStorage keys
  const appStorageKeys = [
    // Canvas related
    'linkedInPosts',
    'canvasTitle',
    'canvasTabs',
    'tabsState',
    'canvasLastView',
    'lastActiveTabId',
    'hasVisitedCanvas',
    'canvases',
    
    // Chat related
    'chatState',
    'currentChat',
    
    // User preferences and state
    'currentScreen',
    'sidebarCollapsed',
    
    // Avatar caches
    'avatar_cache',
    'user_avatar_',
    
    // Analytics and other data
    'audienceProgress_',
    'newAudienceProgress'
  ];

  // Clear each app-related item
  appStorageKeys.forEach(key => {
    // For keys that are prefixes (ending with _), find and remove all matching items
    if (key.endsWith('_')) {
      Object.keys(localStorage)
        .filter(k => k.startsWith(key))
        .forEach(k => localStorage.removeItem(k));
    } else {
      localStorage.removeItem(key);
    }
  });
};
