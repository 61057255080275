import React, { useState, useRef, useEffect, useCallback } from 'react';
import { MicrophoneIcon, StopIcon, PauseIcon, PlayIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { motion, AnimatePresence } from 'framer-motion';
import Notification from './Notification';
import { useTheme } from '../context/ThemeContext';
import { useNavigate } from 'react-router-dom';
import { cn } from '../lib/utils';
import { v4 as uuidv4 } from 'uuid';

// Add animation variants
const containerVariants = {
  idle: { scale: 1 },
  recording: { scale: 1.02, transition: { duration: 0.2 } }
};

const microphoneVariants = {
  idle: { scale: 1 },
  recording: {
    scale: [1, 1.1, 1],
    transition: {
      repeat: Infinity,
      duration: 1.5,
      ease: "easeInOut"
    }
  }
};

const buttonVariants = {
  initial: { scale: 0.9, opacity: 0 },
  animate: { scale: 1, opacity: 1 },
  exit: { scale: 0.9, opacity: 0 },
  hover: { scale: 1.05 }
};

const timeVariants = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -10 }
};

const storeRecording = async (audioBlob) => {
  const recordingId = uuidv4();
  try {
    // Store the blob in IndexedDB
    const request = indexedDB.open('voiceRecordings', 1);
    
    request.onerror = () => {
      console.error("Error opening IndexedDB");
    };

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains('recordings')) {
        db.createObjectStore('recordings');
      }
    };

    request.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction(['recordings'], 'readwrite');
      const store = transaction.objectStore('recordings');
      store.put(audioBlob, recordingId);
    };

    return recordingId;
  } catch (error) {
    console.error('Error storing recording:', error);
    return null;
  }
};

const getStoredRecording = async (recordingId) => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('voiceRecordings', 1);
    
    request.onerror = () => reject(new Error("Error opening IndexedDB"));

    request.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction(['recordings'], 'readonly');
      const store = transaction.objectStore('recordings');
      const getRequest = store.get(recordingId);
      
      getRequest.onsuccess = () => resolve(getRequest.result);
      getRequest.onerror = () => reject(new Error("Error retrieving recording"));
    };
  });
};

const removeStoredRecording = async (recordingId) => {
  const request = indexedDB.open('voiceRecordings', 1);
  
  request.onsuccess = (event) => {
    const db = event.target.result;
    const transaction = db.transaction(['recordings'], 'readwrite');
    const store = transaction.objectStore('recordings');
    store.delete(recordingId);
  };
};

const AudioRecorder = ({ 
  onRecordingComplete, 
  isLoading, 
  setProcessingStatus,
  startRecordingOnMount
}) => {
  const { isDarkMode } = useTheme(); // Get isDarkMode from theme context
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [error, setError] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const timerRef = useRef(null);
  const [availableWidth, setAvailableWidth] = useState(400);
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const isRecordingRef = useRef(false);
  const [isDiscarded, setIsDiscarded] = useState(false);
  const isDiscardedRef = useRef(false);
  const [uploadError, setUploadError] = useState(null);
  const [storedRecordingId, setStoredRecordingId] = useState(null);
  const [isRetrying, setIsRetrying] = useState(false);

  const addNotification = useCallback((message, type) => {
    setNotifications(prev => [...prev, { id: Date.now(), message, type }]);
  }, []);

  useEffect(() => {
    checkBrowserSupport();
  }, []);

  const checkBrowserSupport = () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      setError('Your browser does not support audio recording. Please try a modern browser like Chrome, Firefox, or Safari.');
    } else {
      setError(null);
    }
  };

  const startRecording = async () => {
    isDiscardedRef.current = false;
    setIsDiscarded(false);
    
    if (error) {
      addNotification(error, 'error');
      return;
    }

    if (isRecordingRef.current) {
      console.log('Already recording');
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunksRef.current = [];
      isRecordingRef.current = true;

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (!isDiscardedRef.current && event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        const stream = mediaRecorderRef.current.stream;
        stream.getTracks().forEach(track => track.stop());
        
        if (!isDiscardedRef.current) {
          const hasAudioData = audioChunksRef.current.length > 0;
          if (hasAudioData) {
            const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
            handleRecordingComplete(audioBlob);
          }
        }
        
        audioChunksRef.current = [];
        isRecordingRef.current = false;
        setIsRecording(false);
        setIsPaused(false);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
      setRecordingTime(0);
    } catch (error) {
      console.error('Error accessing microphone:', error);
      setError(`Error accessing microphone: ${error.message}`);
      addNotification('Error accessing microphone', 'error');
      isRecordingRef.current = false;
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
      setIsRecording(false);
      setIsPaused(false);
      isRecordingRef.current = false;
    }
  };

  const pauseRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.pause();
      setIsPaused(true);
    }
  };

  const resumeRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.resume();
      setIsPaused(false);
    }
  };

  const handleDiscard = () => {
    isDiscardedRef.current = true;
    setIsDiscarded(true);
    
    audioChunksRef.current = [];
    
    if (mediaRecorderRef.current) {
        if (mediaRecorderRef.current.state !== 'inactive') {
            try {
                mediaRecorderRef.current.stop();
            } catch (error) {
                console.error('Error stopping recorder:', error);
            }
        }
        
        if (mediaRecorderRef.current.stream) {
            mediaRecorderRef.current.stream.getTracks().forEach(track => {
                try {
                    track.stop();
                } catch (error) {
                    console.error('Error stopping track:', error);
                }
            });
        }
    }
    
    setIsRecording(false);
    setIsPaused(false);
    isRecordingRef.current = false;
    setRecordingTime(0);
    
    setError(null);
  };

  const handleRecordingComplete = async (audioBlob, audioUrl) => {
    setError(null);
    setUploadError(null);
    
    try {
      // Store the recording first
      const recordingId = await storeRecording(audioBlob);
      setStoredRecordingId(recordingId);

      await uploadRecording(audioBlob, recordingId);
    } catch (error) {
      console.error('Error handling recording:', error);
      setUploadError('Failed to upload recording. Click to retry.');
    }
  };

  const uploadRecording = async (audioBlob, recordingId) => {
    try {
      if (setProcessingStatus) {
        setProcessingStatus('Uploading your voice');
      }
      
      await onRecordingComplete(audioBlob, URL.createObjectURL(audioBlob));
      
      // If successful, remove the stored recording
      if (recordingId) {
        await removeStoredRecording(recordingId);
        setStoredRecordingId(null);
      }
      
      setUploadError(null);
    } catch (error) {
      console.error('Error uploading recording:', error);
      throw error;
    }
  };

  const handleRetryUpload = async () => {
    if (!storedRecordingId) return;
    
    setIsRetrying(true);
    setUploadError(null);
    
    try {
      const audioBlob = await getStoredRecording(storedRecordingId);
      if (!audioBlob) {
        throw new Error('Stored recording not found');
      }
      
      await uploadRecording(audioBlob, storedRecordingId);
    } catch (error) {
      console.error('Error retrying upload:', error);
      setUploadError('Failed to upload recording. Click to retry.');
    } finally {
      setIsRetrying(false);
    }
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        setAvailableWidth(entry.contentRect.width);
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  const isNarrow = availableWidth < 350; // Adjust this threshold as needed

  useEffect(() => {
    if (isRecording && !isPaused) {
      timerRef.current = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(timerRef.current);
    }

    return () => clearInterval(timerRef.current);
  }, [isRecording, isPaused]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    return () => {
      if (mediaRecorderRef.current) {
        if (mediaRecorderRef.current.state !== 'inactive') {
          mediaRecorderRef.current.stop();
        }
        if (mediaRecorderRef.current.stream) {
          mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
        }
      }
      audioChunksRef.current = [];
      clearInterval(timerRef.current);
    };
  }, []);

  return (
    <div className="w-full space-y-4 mb-6">
      {error && (
        <motion.div 
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          className="rounded-lg border border-destructive/50 bg-destructive/10 px-4 py-3 text-sm text-destructive mb-4"
        >
          {error}
        </motion.div>
      )}
      
      {notifications.map((notification, index) => (
        <Notification
          key={notification.id}
          message={notification.message}
          type={notification.type}
          onClose={() => setNotifications(prev => prev.filter(n => n.id !== notification.id))}
          duration={3000}
          currentIndex={index + 1}
          totalCount={notifications.length}
        />
      ))}

      {uploadError && (
        <motion.div 
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          className={cn(
            "rounded-lg px-4 py-3 text-sm mb-4",
            "flex items-center justify-between",
            isDarkMode 
              ? "bg-red-900/20 text-red-400 border border-red-800"
              : "bg-red-50 text-red-600 border border-red-200"
          )}
        >
          <span>{uploadError}</span>
          <button
            onClick={handleRetryUpload}
            disabled={isRetrying}
            className={cn(
              "ml-4 px-3 py-1 rounded-md text-sm transition-colors",
              isDarkMode
                ? "bg-red-900/30 hover:bg-red-900/50 text-red-300"
                : "bg-red-100 hover:bg-red-200 text-red-700"
            )}
          >
            {isRetrying ? (
              <div className="flex items-center">
                <div className="animate-spin rounded-full h-4 w-4 border-2 border-red-500 border-t-transparent mr-2" />
                Retrying...
              </div>
            ) : (
              'Retry Upload'
            )}
          </button>
        </motion.div>
      )}

      <div className="flex justify-center items-center mb-4">
        <motion.div 
          ref={containerRef}
          variants={containerVariants}
          initial="idle"
          animate={isRecording ? "recording" : "idle"}
          className={cn(
            "rounded-lg flex items-center gap-2 transition-all duration-200",
            "bg-background border shadow-sm",
            "hover:shadow-md hover:border-border/80",
            isRecording ? "px-3 py-2" : "p-1",
            "mb-2",
            "bg-white text-white dark:bg-gray-950"
          )}
        >
          <AnimatePresence mode="wait">
            {isLoading ? (
              <motion.div
                key="loading"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="flex items-center justify-center w-full px-4 py-2 text-muted-foreground"
              >
                <div className="animate-spin rounded-full h-4 w-4 border-2 border-primary border-t-transparent"></div>
                <span className="ml-2 text-sm font-medium">Processing...</span>
              </motion.div>
            ) : isRecording ? (
              <motion.div
                key="recording"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="flex items-center gap-2"
              >
                <motion.button
                  variants={buttonVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  whileHover="hover"
                  onClick={handleDiscard}
                  className={cn(
                    "inline-flex items-center justify-center",
                    "text-sm font-medium transition-colors",
                    "hover:bg-accent hover:text-accent-foreground",
                    "h-9 rounded-md px-3",
                    "text-destructive hover:bg-destructive/10"
                  )}
                >
                  Cancel
                </motion.button>

                <motion.span
                  variants={timeVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  className="font-medium text-sm tabular-nums text-foreground"
                >
                  {formatTime(recordingTime)}
                </motion.span>

                <motion.button
                  variants={buttonVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  whileHover="hover"
                  onClick={isPaused ? resumeRecording : pauseRecording}
                  className={cn(
                    "inline-flex items-center justify-center",
                    "rounded-md h-9 w-9",
                    "hover:bg-accent hover:text-accent-foreground",
                    "transition-colors"
                  )}
                >
                  {isPaused ? <PlayIcon className="w-4 h-4" /> : <PauseIcon className="w-4 h-4" />}
                </motion.button>

                <motion.button
                  variants={buttonVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  whileHover="hover"
                  onClick={stopRecording}
                  className={cn(
                    "inline-flex items-center justify-center",
                    "rounded-md px-3 h-9",
                    "bg-primary text-primary-foreground",
                    "hover:bg-primary/90",
                    "text-sm font-medium transition-colors"
                  )}
                >
                  Done
                </motion.button>
              </motion.div>
            ) : (
              <motion.button
                key="start"
                variants={buttonVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                whileHover="hover"
                onClick={startRecording}
                disabled={isLoading}
                className={cn(
                  "inline-flex items-center justify-center",
                  "rounded-md px-4 h-10 w-full",
                  "bg-primary text-primary-foreground",
                  "hover:bg-primary/90",
                  "transition-colors disabled:opacity-50",
                  "text-sm font-medium"
                )}
              >
                <motion.div
                  variants={microphoneVariants}
                  initial="idle"
                  animate={isRecording ? "recording" : "idle"}
                >
                  <MicrophoneIcon className="w-4 h-4 mr-2 text-red-400" />
                </motion.div>
                <span className="whitespace-nowrap">Record a voice note</span>
              </motion.button>
            )}
          </AnimatePresence>
        </motion.div>
      </div>
    </div>
  );
};

export default AudioRecorder;
