import React, { useState } from 'react';
import { FileText } from 'lucide-react';
import { useTheme } from '../context/ThemeContext';
import TemplateHover from './TemplateHover';

const TemplateTag = ({ title, content, category, tone }) => {
  const { isDarkMode } = useTheme();
  const [showContent, setShowContent] = useState(false);

  const template = { title, content, category, tone };

  return (
    <div 
      className={`inline-flex items-center rounded-xl px-2 py-1 text-xs font-medium mr-2 mb-1 relative ${
        isDarkMode 
          ? 'bg-white-400 text-slate-200 border border-gray-700' 
          : 'bg-white text-gray-600 border border-blue-100'
      }`}
      onMouseEnter={() => setShowContent(true)}
      onMouseLeave={() => setShowContent(false)}
    >
      <FileText size={12} className="mr-1" />
      <span> Using template: <b>{title}</b></span>
      {showContent && (
        <div className="absolute z-[9999] bottom-full left-1/2 transform -translate-x-1/2">
          <TemplateHover template={template} />
        </div>
      )}
    </div>
  );
};

export default TemplateTag;