import React, { useState, useEffect, useCallback, useRef } from 'react';
import { XMarkIcon, ArrowLeftIcon, MagnifyingGlassIcon, FolderIcon, DocumentIcon, ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../context/ThemeContext';
import { fetchKnowledgeBases } from '../services/mongoService';
import { motion } from 'framer-motion';
import { formatDistanceToNow } from 'date-fns';
import { formatFileSize } from '../utils/formatters';
import { uploadFile } from '../api';
import { showCustomToast } from './CustomToast';
import AddToChat from './common/AddToChat';

const ItemRow = ({ item, onSelect, isDarkMode, isSelected }) => {
  const [isHovered, setIsHovered] = useState(false);

  if (!item._id || !item.name) {
    console.error('Invalid item:', item);
    return null;
  }

  const styles = {
    row: `w-full text-left p-3 mb-2.5 flex items-center
      transition-colors duration-200 rounded-md
      ${isDarkMode 
        ? 'hover:bg-[#1e1e1e] active:bg-[#202020]' 
        : 'hover:bg-gray-50 active:bg-gray-100'}
      ${isSelected
        ? isDarkMode
          ? 'bg-[#1e1e1e] border border-[#333333]'
          : 'bg-blue-50 border border-blue-200'
        : 'border border-transparent'}`,
    icon: `w-5 h-5 mr-3 flex-shrink-0 ${
      item.type === 'folder' 
        ? isDarkMode ? 'text-yellow-400' : 'text-yellow-500'
        : isDarkMode ? 'text-blue-400' : 'text-blue-500'
    }`,
    text: `${isDarkMode ? 'text-slate-200' : 'text-gray-900'} truncate text-sm`,
    secondaryText: `text-xs ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`,
  };

  return (
    <button
      onClick={() => onSelect(item)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={styles.row}
    >
      {item.type === 'folder' ? (
        <FolderIcon className={styles.icon} />
      ) : (
        <DocumentIcon className={styles.icon} />
      )}
      <div className="flex-grow min-w-0">
        <h3 className={`font-medium text-sm mb-1 truncate ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>
          {item.name}
        </h3>
        {item.updatedAt && (
          <p className={`text-xs ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
            {formatDistanceToNow(new Date(item.updatedAt), { addSuffix: true })}
          </p>
        )}
      </div>
      {item.size && (
        <span className={`text-xs ml-3 ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
          {formatFileSize(item.size)}
        </span>
      )}
    </button>
  );
};

const KnowledgeBaseSelector = ({ 
  onSelect, 
  onClose, 
  initialSelectedFiles = [],
  containerWidth
}) => {
  const { isDarkMode } = useTheme();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFiles, setSelectedFiles] = useState(initialSelectedFiles);
  const [currentFolderId, setCurrentFolderId] = useState(null);
  const [currentPath, setCurrentPath] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(null);
  const fileInputRef = useRef(null);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const loadKnowledgeBases = useCallback(async (folderId = null) => {
    setLoading(true);
    try {
      const data = await fetchKnowledgeBases(folderId);
      setItems(data);
      setCurrentFolderId(folderId);
    } catch (error) {
      console.error('Error fetching knowledge bases:', error);
      setError('Failed to load files. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadKnowledgeBases();
  }, [loadKnowledgeBases]);

  const handleFileSelect = async (file) => {
    if (file.type === 'folder') {
      setCurrentFolderId(file._id);
      setCurrentPath(prev => [...prev, { _id: file._id, name: file.name }]);
      loadKnowledgeBases(file._id);
      return;
    }

    if (!file._id) {
      console.error('File missing ID:', file);
      showCustomToast('Invalid file selected', 'error');
      return;
    }

    setSelectedFiles(prev => {
      const isAlreadySelected = prev.some(selected => selected._id === file._id);
      if (isAlreadySelected) {
        return prev.filter(selected => selected._id !== file._id);
      } else {
        const validatedFile = {
          _id: file._id,
          name: file.name || 'Untitled',
          type: file.type || 'file',
          size: file.size || 0,
          updatedAt: file.updatedAt || new Date().toISOString()
        };
        return [...prev, validatedFile];
      }
    });
  };

  const handleConfirmSelection = () => {
    const validFiles = selectedFiles.filter(file => {
      if (!file._id) {
        console.error('Invalid file in selection:', file);
        return false;
      }
      return true;
    });

    if (validFiles.length !== selectedFiles.length) {
      showCustomToast('Some files are invalid and will be skipped', 'warning');
    }

    if (validFiles.length === 0) {
      showCustomToast('No valid files selected', 'error');
      return;
    }

    onSelect(validFiles);
    onClose();
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setIsUploading(true);
    setUploadProgress(0);

    try {
      const formData = new FormData();
      formData.append('file', file);
      if (currentFolderId) {
        formData.append('parentId', currentFolderId);
      }

      await uploadFile(formData, (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setUploadProgress(percentCompleted);
      });

      await loadKnowledgeBases(currentFolderId);
      showCustomToast('File uploaded successfully', 'success');
    } catch (error) {
      console.error('Failed to upload file:', error);
      showCustomToast('Failed to upload file', 'error');
    } finally {
      setIsUploading(false);
      setUploadProgress(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const navigateToFolder = (path) => {
    const folderId = path.length > 0 ? path[path.length - 1]._id : null;
    setCurrentPath(path);
    setCurrentFolderId(folderId);
    loadKnowledgeBases(folderId);
  };

  const containerStyle = {
    width: containerWidth || '100%',
    transition: 'width 300ms ease-in-out'
  };

  if (error) {
    return (
      <motion.div 
        className={`flex flex-col h-full relative overflow-hidden
          ${isDarkMode ? 'bg-slate-900' : 'bg-white'}
          border-none rounded-none`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
        style={containerStyle}
      >
        <div className="flex-1 flex items-center justify-center p-4">
          <div className="text-center">
            <p className={isDarkMode ? 'text-slate-300' : 'text-gray-600'}>{error}</p>
            <button
              onClick={() => loadKnowledgeBases(currentFolderId)}
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
              Retry
            </button>
          </div>
        </div>
      </motion.div>
    );
  }

  return (
    <motion.div 
      className={`flex flex-col h-full relative overflow-hidden
        ${isDarkMode ? 'bg-[#121212]' : 'bg-white'}
        border-none rounded-none`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      style={{
        width: '100%',
        maxWidth: '100%',
        height: '100%'
      }}
    >
      {/* Header */}
      <div className={`
        ${isDarkMode ? 'bg-[#151515] border-[#333333]' : 'bg-white border-gray-200'} 
        !h-10 min-h-10 flex-shrink-0 flex items-center justify-between px-4 py-2 w-full 
        border-b
      `}>
        <div className="flex items-center">
          <FolderIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'} mr-2`} />
          <h2 className={`text-sm font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>
            Knowledge Base
          </h2>
        </div>
        <div className="flex items-center space-x-2">
          {/* Upload Button */}
          <label
            className={`flex items-center px-2 py-1 rounded text-xs cursor-pointer
              ${isDarkMode 
                ? 'bg-[#1e1e1e] hover:bg-[#2a2a2a] text-slate-200 border border-[#333333]' 
                : 'bg-gray-100 hover:bg-gray-200 text-gray-700 border border-gray-200'
              } transition-colors duration-200`}
            title="Upload File"
          >
            <ArrowUpTrayIcon className="w-3 h-3 mr-1" />
            <span>Upload</span>
            <input
              ref={fileInputRef}
              type="file"
              className="hidden"
              onChange={handleFileUpload}
            />
          </label>

          <button 
            onClick={onClose}
            className={`p-1 rounded-full transition-colors duration-200
              ${isDarkMode ? 'hover:bg-[#1e1e1e]' : 'hover:bg-gray-100'}`}
            title="Back to Chat"
          >
            <ArrowLeftIcon className={`w-5 h-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
          </button>
        </div>
      </div>

      {/* Search and Breadcrumbs */}
      <div className={`
        px-4 py-2 ${isDarkMode ? 'bg-[#151515]' : 'bg-white'} border-b
        ${isDarkMode ? 'border-[#333333]' : 'border-gray-200'}
      `}>
        <div className="flex items-center justify-between">
          {/* Breadcrumbs - Add max-width to prevent overlap */}
          <div className="flex items-center space-x-2 text-sm flex-1 min-w-0 mr-4">
            <div className="flex items-center space-x-2 overflow-x-auto no-scrollbar">
              <button
                onClick={() => navigateToFolder([])}
                className={`hover:underline whitespace-nowrap ${isDarkMode ? 'text-slate-200 hover:text-slate-100' : 'text-gray-600 hover:text-gray-900'}`}
              >
                Home
              </button>
              {currentPath.map((folder, index) => (
                <React.Fragment key={folder._id}>
                  <span className={`whitespace-nowrap ${isDarkMode ? 'text-[#333333]' : 'text-gray-400'}`}>/</span>
                  <button
                    onClick={() => navigateToFolder(currentPath.slice(0, index + 1))}
                    className={`hover:underline whitespace-nowrap ${isDarkMode ? 'text-slate-200 hover:text-slate-100' : 'text-gray-600 hover:text-gray-900'}`}
                  >
                    {folder.name}
                  </button>
                </React.Fragment>
              ))}
            </div>
          </div>

          {/* Search - Now more compact */}
          <div className="relative flex items-center">
            {isSearchOpen ? (
              <div className={`
                flex items-center
                animate-in slide-in-from-right-5 duration-200
              `}>
                <input
                  type="text"
                  placeholder="Search files..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className={`
                    pl-8 pr-8 py-1 text-sm rounded-md w-48
                    transition-all duration-200
                    ${isDarkMode 
                      ? 'bg-[#1e1e1e] text-slate-200 placeholder-slate-400 border-[#333333]' 
                      : 'bg-gray-100 text-gray-900 placeholder-gray-500 border-gray-200'
                    } border focus:outline-none focus:ring-1 focus:ring-blue-500
                  `}
                  autoFocus
                />
                <button
                  onClick={() => {
                    setIsSearchOpen(false);
                    setSearchTerm('');
                  }}
                  className={`
                    absolute right-2 p-1 rounded-full
                    ${isDarkMode 
                      ? 'hover:bg-[#2a2a2a] text-slate-400 hover:text-slate-300' 
                      : 'hover:bg-gray-200 text-gray-500 hover:text-gray-700'
                    }
                  `}
                >
                  <XMarkIcon className="w-3.5 h-3.5" />
                </button>
                <MagnifyingGlassIcon className={`
                  absolute left-2 w-4 h-4
                  ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}
                `} />
              </div>
            ) : (
              <button
                onClick={() => setIsSearchOpen(true)}
                className={`
                  p-1.5 rounded-md transition-colors duration-200
                  ${isDarkMode 
                    ? 'hover:bg-[#1e1e1e] text-slate-400 hover:text-slate-300' 
                    : 'hover:bg-gray-100 text-gray-500 hover:text-gray-700'
                  }
                `}
                title="Search files"
              >
                <MagnifyingGlassIcon className="w-4 h-4" />
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Content Area */}
      <div className={`
        flex-grow overflow-y-auto custom-scrollbar
        ${isDarkMode ? 'bg-[#121212]' : 'bg-white'}
      `}>
        {loading ? (
          <div className="px-4 py-3">
            {Array(5).fill().map((_, i) => (
              <div 
                key={i} 
                className={`animate-pulse h-16 rounded-md mb-2.5 ${
                  isDarkMode ? 'bg-[#1e1e1e]' : 'bg-gray-100'
                }`} 
              />
            ))}
          </div>
        ) : items.length === 0 ? (
          <div className="flex flex-col items-center justify-center h-full p-4">
            <FolderIcon className={`h-10 w-10 mb-3 ${isDarkMode ? 'text-[#333333]' : 'text-gray-400'}`} />
            <p className={`text-center text-sm ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
              This folder is empty
            </p>
          </div>
        ) : (
          <div className="px-4 py-3">
            {items
              .filter(item => 
                item.name.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map(item => (
                <ItemRow
                  key={item._id}
                  item={item}
                  onSelect={handleFileSelect}
                  isDarkMode={isDarkMode}
                  isSelected={selectedFiles.some(file => file._id === item._id)}
                />
              ))
            }
          </div>
        )}
      </div>

      {/* Footer */}
      <div className={`
        px-3 py-2 sticky bottom-0
        ${isDarkMode ? 'bg-[#151515] border-[#333333]' : 'bg-white border-gray-200'}
        border-t
      `}>
        <AddToChat
          onClick={handleConfirmSelection}
          disabled={selectedFiles.length === 0}
          selectedCount={selectedFiles.length}
        />
      </div>

      {/* Upload Progress */}
      {isUploading && (
        <div className={`
          absolute bottom-4 right-4 p-4 rounded-lg shadow-lg
          ${isDarkMode ? 'bg-[#151515]' : 'bg-white'}
          border ${isDarkMode ? 'border-[#333333]' : 'border-gray-200'}
        `}>
          <div className="flex items-center">
            <div className="mr-3">
              <div className={`text-sm font-medium mb-1 ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>
                Uploading...
              </div>
              <div className={`text-xs ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
                {uploadProgress}%
              </div>
            </div>
            <div className="w-24 h-2 bg-gray-200 rounded-full overflow-hidden">
              <div 
                className="h-full bg-blue-500 transition-all duration-300"
                style={{ width: `${uploadProgress}%` }}
              />
            </div>
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default KnowledgeBaseSelector;