import React, { createContext, useState, useCallback, useEffect, useContext } from 'react';
import { fetchNotes as apiFetchNotes, addNote as apiAddNote, updateNote as apiUpdateNote, deleteNote as apiDeleteNote } from '../api';
import { AuthContext } from './AuthContext';

export const NotesContext = createContext();

export const NotesProvider = ({ children }) => {
  const [notes, setNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user, logout } = useContext(AuthContext);

  const fetchNotes = useCallback(async (page = 1, tags = ['All']) => {
    if (!user) return; // Don't fetch if user is not authenticated
    setIsLoading(true);
    try {
      console.log('Fetching notes in NotesContext');
      const fetchedData = await apiFetchNotes(page, tags);
      console.log('Fetched data in NotesContext:', fetchedData);
      if (fetchedData && Array.isArray(fetchedData.notes)) {
        setNotes(prevNotes => {
          const newNotes = fetchedData.notes;
          const updatedNotes = page === 1 ? newNotes : [...prevNotes, ...newNotes];
          console.log('Updated notes state:', updatedNotes);
          return updatedNotes;
        });
        setError(null);
        return fetchedData; // Return the fetched data
      } else {
        console.error('Invalid data structure received from API:', fetchedData);
        throw new Error('Invalid data structure received from API');
      }
    } catch (err) {
      console.error('Error in NotesContext fetchNotes:', err);
      if (err.response && err.response.status === 401) {
        logout(); // Logout user if unauthorized
      } else {
        setError('Failed to fetch notes');
      }
      throw err; // Re-throw the error to be caught in TextNotes.js
    } finally {
      setIsLoading(false);
    }
  }, [user, logout]);

  const addNote = useCallback(async (newNote) => {
    try {
      const addedNote = await apiAddNote(newNote);
      setNotes(prevNotes => [addedNote, ...prevNotes]);
      return addedNote;
    } catch (err) {
      setError('Failed to add note');
      console.error('Error adding note:', err);
      throw err;
    }
  }, []);

  const updateNote = useCallback(async (id, updatedNote) => {
    try {
      const updatedNoteFromServer = await apiUpdateNote(id, updatedNote);
      setNotes(prevNotes => prevNotes.map(note => 
        note._id === id ? { ...note, ...updatedNoteFromServer } : note
      ));
      return updatedNoteFromServer;
    } catch (err) {
      setError('Failed to update note');
      console.error('Error updating note:', err);
      throw err;
    }
  }, []);

  const deleteNote = useCallback(async (id) => {
    try {
      const result = await apiDeleteNote(id);
      if (Array.isArray(id)) {
        setNotes(prevNotes => prevNotes.filter(note => !id.includes(note._id)));
      } else {
        setNotes(prevNotes => prevNotes.filter(note => note._id !== id));
      }
      return result;
    } catch (err) {
      setError('Failed to delete note');
      console.error('Error deleting note:', err);
    }
  }, []);

  useEffect(() => {
    if (user) {
      fetchNotes();
    } else {
      setNotes([]);
    }
  }, [user, fetchNotes]);

  return (
    <NotesContext.Provider value={{ 
      notes: Array.isArray(notes) ? notes : [], 
      setNotes, 
      addNote, 
      updateNote, 
      deleteNote, 
      fetchNotes, 
      isLoading, 
      error 
    }}>
      {children}
    </NotesContext.Provider>
  );
};