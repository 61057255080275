import React from 'react';
import { XMarkIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import FileTypeIcon from './FileTypeIcon';
import { formatFileSize, formatDate } from '../utils/fileUtils';
import { motion } from 'framer-motion';

const PreviewFileforChat = ({ file, isDarkMode, onClose, onDownload, isLoading }) => {
  const handleClose = (e) => {
    e.stopPropagation();
    onClose();
  };

  const handleDownload = (e) => {
    e.stopPropagation();
    if (onDownload && !isLoading) {
      onDownload(file);
    }
  };

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      className={`
        w-full rounded-lg overflow-hidden shadow-xl
        ${isDarkMode ? 'bg-slate-800 border border-slate-700' : 'bg-white border border-gray-200'}
        ${isLoading ? 'opacity-75' : ''}
      `}
    >
      {/* Header */}
      <div className={`
        h-12 flex items-center justify-between px-4 border-b
        ${isDarkMode ? 'border-slate-700 bg-slate-900' : 'border-gray-200 bg-gray-50'}
      `}>
        <div className="flex items-center gap-3">
          {isLoading ? (
            <div className="animate-spin rounded-full h-5 w-5 border-2 border-gray-300 border-t-transparent" />
          ) : (
            <FileTypeIcon fileType={file.type} className={`h-5 w-5 ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}`} />
          )}
          <div>
            <h3 className={`text-sm font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-700'}`}>
              {file.name}
            </h3>
            <div className={`text-xs flex items-center gap-2 ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
              <span>{formatFileSize(file.size)}</span>
              <span>•</span>
              <span>{formatDate(file.createdAt)}</span>
              {isLoading && (
                <>
                  <span>•</span>
                  <span className="text-blue-400">Processing file...</span>
                </>
              )}
            </div>
          </div>
        </div>
        
        <div className="flex items-center gap-2">
          {onDownload && !isLoading && (
            <button
              onClick={handleDownload}
              className={`
                p-2 rounded-md transition-colors duration-200
                ${isDarkMode 
                  ? 'hover:bg-slate-700 text-slate-400 hover:text-slate-300' 
                  : 'hover:bg-gray-200 text-gray-500 hover:text-gray-700'
                }
              `}
              title="Download file"
            >
              <ArrowDownTrayIcon className="h-4 w-4" />
            </button>
          )}
          <button
            onClick={handleClose}
            className={`
              p-2 rounded-md transition-colors duration-200
              ${isDarkMode 
                ? 'hover:bg-slate-700 text-slate-400 hover:text-slate-300' 
                : 'hover:bg-gray-200 text-gray-500 hover:text-gray-700'
              }
            `}
            title="Close preview"
          >
            <XMarkIcon className="h-4 w-4" />
          </button>
        </div>
      </div>

      {/* Content */}
      <div className={`
        p-4
        ${isDarkMode ? 'bg-slate-800' : 'bg-white'}
      `}>
        {/* File Content */}
        <div className={`
          rounded-lg border overflow-hidden
          ${isDarkMode ? 'border-slate-700' : 'border-gray-200'}
        `}>
          {isLoading ? (
            <div className="flex items-center justify-center p-8">
              <div className="flex flex-col items-center gap-3">
                <div className="animate-spin rounded-full h-8 w-8 border-2 border-blue-500 border-t-transparent" />
                <p className={`text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}`}>
                  Converting file content...
                </p>
              </div>
            </div>
          ) : (
            <pre className={`
              whitespace-pre-wrap font-mono text-sm p-4 overflow-auto max-h-[600px]
              ${isDarkMode ? 'bg-slate-900 text-slate-300' : 'bg-gray-50 text-gray-600'}
            `}>
              {file.content}
            </pre>
          )}
        </div>

        {/* Metadata Section */}
        {file.metadata && !isLoading && (
          <div className={`
            mt-4 p-3 rounded-lg text-xs
            ${isDarkMode ? 'bg-slate-900' : 'bg-gray-50'}
          `}>
            <h4 className={`font-medium mb-2 ${isDarkMode ? 'text-slate-300' : 'text-gray-700'}`}>
              File Information
            </h4>
            <div className={`grid gap-1 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`}>
              {file.metadata.contentType && (
                <div>Content Type: {file.metadata.contentType}</div>
              )}
              {file.metadata.lastModified && (
                <div>Last Modified: {formatDate(file.metadata.lastModified)}</div>
              )}
              {file.metadata.encoding && (
                <div>Encoding: {file.metadata.encoding}</div>
              )}
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default PreviewFileforChat; 