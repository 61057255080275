import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../context/ThemeContext';

const LoadingOverlay = ({ onLoadingComplete }) => {
  const initialDarkMode = localStorage.getItem('darkMode') === 'true';
  const theme = useTheme();
  const isDarkMode = theme?.isDarkMode ?? initialDarkMode;
  const [isComplete, setIsComplete] = useState(false);
  const [shouldExit, setShouldExit] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsComplete(true);
      
      setTimeout(() => {
        setShouldExit(true);
        setTimeout(() => {
          onLoadingComplete?.();
        }, 800);
      }, 1000);
    }, 1000);

    return () => clearTimeout(timer);
  }, [onLoadingComplete]);

  const pulseVariants = {
    initial: { scale: 0.95, opacity: 0.5 },
    animate: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.8,
        repeat: isComplete ? 0 : Infinity,
        repeatType: "reverse",
        ease: "easeInOut"
      }
    },
    exit: {
      scale: 1.2,
      opacity: 0,
      transition: { duration: 0.3 }
    }
  };

  const slideVariants = {
    initial: { width: "20%" },
    animate: { 
      width: "100%",
      transition: isComplete ? {
        duration: 0.8,
        ease: [0.4, 0, 0.2, 1]
      } : {
        duration: 1.5,
        repeat: Infinity,
        ease: "easeInOut"
      }
    }
  };

  const overlayVariants = {
    initial: { opacity: 1 },
    exit: {
      opacity: 0,
      transition: { duration: 0.8, ease: "easeInOut" }
    }
  };

  const bgColor = isDarkMode ? 'bg-[#121212]' : 'bg-white';
  const textColor = isDarkMode ? 'text-slate-200' : 'text-slate-800';
  const progressBg = isDarkMode ? 'bg-[#1e1e1e]' : 'bg-slate-200';
  const progressFill = isDarkMode ? 
    (isComplete ? 'bg-green-500/80' : 'bg-blue-500/80') : 
    (isComplete ? 'bg-green-500' : 'bg-blue-500');

  return (
    <AnimatePresence>
      {!shouldExit && (
        <motion.div 
          className={`fixed inset-0 grid place-items-center ${bgColor} transition-colors duration-200`}
          style={{ 
            zIndex: 9999,
            minHeight: '100vh',
            minHeight: '100dvh'
          }}
          variants={overlayVariants}
          initial="initial"
          exit="exit"
        >
          <motion.div 
            className="w-96 max-w-[90vw] mx-auto space-y-6 flex flex-col items-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className={`h-1 w-full rounded-full ${progressBg} overflow-hidden transition-colors duration-200`}>
              <motion.div
                className={`h-full ${progressFill} transition-colors duration-300`}
                variants={slideVariants}
                initial="initial"
                animate="animate"
              />
            </div>

            <motion.div 
              className="text-center"
              animate={{ 
                scale: isComplete ? [1, 1.05, 1] : 1,
                transition: { duration: 0.3 }
              }}
            >
              <p className={`text-lg font-semibold ${textColor} transition-colors duration-200`}>
                {isComplete ? 'Ready!' : 'Refreshing it for you...'}
              </p>
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default LoadingOverlay;
