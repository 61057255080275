import React, { useState, useEffect } from 'react';
import { UserGroupIcon } from '@heroicons/react/24/solid';
import { fetchAvatarUrl } from '../../utils/targetAudienceAvatarUtils';
import { useTheme } from '../../context/ThemeContext';

const FALLBACK_AVATAR = '/default-audience-avatar.png';

const CompactTargetAudienceCard = ({ audience, onAudienceClick, isSelected }) => {
  const { isDarkMode } = useTheme();
  const [avatarUrl, setAvatarUrl] = useState(null);

  useEffect(() => {
    const loadAvatarUrl = async () => {
      if (audience && audience._id) {
        const url = await fetchAvatarUrl(audience._id);
        setAvatarUrl(url);
      }
    };

    loadAvatarUrl();
  }, [audience]);

  const handleAvatarError = () => {
    setAvatarUrl(FALLBACK_AVATAR);
  };

  return (
    <div 
      className={`
        ${isDarkMode 
          ? 'bg-[#1E1E1E] hover:bg-[#2A2A2A] border-[#333333]' 
          : 'bg-white hover:bg-gray-50 border-gray-200'
        } 
        ${isSelected 
          ? isDarkMode 
            ? 'bg-[#2A2A2A]' 
            : 'bg-gray-100'
          : ''
        }
        rounded-md p-2 relative cursor-pointer transition-all duration-200 ease-in-out
        border mb-[5px]
      `}
      onClick={() => onAudienceClick(audience)}
    >
      <div className="flex items-center">
        {/* Avatar container with gradient border */}
        <div className="relative mr-3 flex-shrink-0">
          <div className={`w-8 h-8 rounded-full p-[1px] ${
            isDarkMode 
              ? 'bg-gradient-to-br from-cyan-500 via-blue-500 to-purple-500' 
              : 'bg-gradient-to-br from-green-400 via-blue-500 to-purple-600'
          }`}>
            <div className={`w-full h-full rounded-full overflow-hidden ${
              isDarkMode ? 'bg-[#121212]' : 'bg-white'
            }`}>
              {avatarUrl && avatarUrl !== FALLBACK_AVATAR ? (
                <img 
                  src={avatarUrl} 
                  alt={audience.targetAudienceName}
                  className="w-full h-full object-cover" 
                  onError={handleAvatarError}
                />
              ) : (
                <div className={`w-full h-full flex items-center justify-center ${
                  isDarkMode ? 'bg-[#2A2A2A]' : 'bg-gray-200'
                }`}>
                  <UserGroupIcon className={`w-5 h-5 ${
                    isDarkMode ? 'text-gray-400' : 'text-gray-400'
                  }`} />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Name Column */}
        <div className="flex-1 min-w-0">
          <h2 className={`text-xs font-medium ${
            isDarkMode ? 'text-gray-200' : 'text-gray-800'
          } truncate`}>
            {audience.targetAudienceName}
          </h2>
        </div>
      </div>
    </div>
  );
};

export default CompactTargetAudienceCard;
