import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { 
  PlusIcon, 
  ChevronDownIcon, 
  Squares2X2Icon, 
  ListBulletIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
  TrashIcon,
  ChartBarIcon,
  DocumentDuplicateIcon,
  StarIcon,
  ArrowDownTrayIcon,
  BookOpenIcon
} from '@heroicons/react/24/outline';
import { useTheme } from '../context/ThemeContext';
import { motion, AnimatePresence } from 'framer-motion';
import { createCanvas, getUserCanvases, deleteCanvas, updateCanvasTitle } from '../api';
import { createPortal } from 'react-dom';
import { PencilIcon } from './icons/PencilIcon';
import { CanvasGridIcon } from './icons/CanvasGridIcon';
import CanvasItem from './CanvasItem';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import CanvasWelcomeActionBar from './CanvasWelcomeActionBar';
import StatsBottomBarforCanvasWelcome from './StatsBottomBarforCanvasWelcome';
import '../components/customScrollbar.css';

const ContextMenu = ({ x, y, onClose, children, isDarkMode }) => {
  return createPortal(
    <div 
      className="fixed inset-0 z-[9999]"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClose();
      }}
    >
      <div 
        className={`
          absolute rounded-lg shadow-lg border overflow-hidden
          ${isDarkMode 
            ? 'bg-slate-800 border-slate-700' 
            : 'bg-white border-slate-200'
          }
        `}
        style={{ 
          top: y,
          left: x,
          transform: 'translate(0, 8px)'
        }}
        onClick={e => e.stopPropagation()}
      >
        {children}
      </div>
    </div>,
    document.body
  );
};

// Add these style constants at the top of the file
const baseButtonStyles = `
  flex items-center justify-center
  rounded-md transition-all duration-200
  hover:shadow-sm
  transform hover:scale-[1.02]
  border border-transparent
`;

const baseControlStyles = `
  flex items-center space-x-1.5
  px-3 py-1.5 rounded-md
  transition-colors duration-200
  border
`;

// Move getTimeAgo function here, before any component definitions
const getTimeAgo = (date) => {
  const minutes = Math.floor((new Date() - new Date(date)) / 60000);
  if (minutes < 1) return 'Just now';
  if (minutes === 1) return '1 min ago';
  if (minutes < 60) return `${minutes} mins ago`;
  const hours = Math.floor(minutes / 60);
  if (hours === 1) return '1 hour ago';
  if (hours < 24) return `${hours} hours ago`;
  const days = Math.floor(hours / 24);
  if (days === 1) return '1 day ago';
  if (days < 7) return `${days} days ago`;
  const weeks = Math.floor(days / 7);
  if (weeks === 1) return '1 week ago';
  if (weeks < 4) return `${weeks} weeks ago`;
  const months = Math.floor(days / 30);
  if (months === 1) return '1 month ago';
  if (months < 12) return `${months} months ago`;
  const years = Math.floor(days / 365);
  if (years === 1) return '1 year ago';
  return `${years} years ago`;
};

// Add this new component near the top of the file, after imports
const NoSearchResults = ({ searchQuery, isDarkMode }) => (
  <motion.div 
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.4, ease: "easeOut" }}
    className={`
      flex flex-col items-center justify-center 
      h-[calc(100%-2rem)] 
      ${isDarkMode 
        ? 'bg-[#151515] border-[#333333]' 
        : 'bg-slate-50/50 border-slate-200/50'
      }
      rounded-xl backdrop-blur-sm
      border p-8
    `}
  >
    <div className="flex flex-col items-center max-w-md text-center">
      <motion.div 
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ 
          delay: 0.1,
          duration: 0.4,
          ease: [0.23, 1, 0.32, 1]
        }}
        className={`
          mb-6 p-4 rounded-full
          ${isDarkMode 
            ? 'bg-[#1e1e1e]' 
            : 'bg-slate-100/80'
          }
        `}
      >
        <MagnifyingGlassIcon className={`
          w-8 h-8
          ${isDarkMode 
            ? 'text-slate-400' 
            : 'text-slate-400'
          }
        `} />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2, duration: 0.4 }}
      >
        <h3 className={`
          text-lg font-medium mb-2
          ${isDarkMode ? 'text-slate-200' : 'text-slate-900'}
        `}>
          No matches found
        </h3>
        
        <p className={`
          text-sm mb-1
          ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
        `}>
          No campaigns match "<span className="font-medium">{searchQuery}</span>"
        </p>
      </motion.div>
    </div>
  </motion.div>
);

const QuickActionsMenu = ({ item, onClose, isDarkMode, onDuplicate, onPin, onExport }) => {
  return (
    <div className={`
      py-1 min-w-[160px]
      ${isDarkMode ? 'bg-[#1e1e1e]' : 'bg-white'}
    `}>
      <button
        onClick={() => {
          onDuplicate(item);
          onClose();
        }}
        className={`
          w-full flex items-center gap-2 px-3 py-1.5
          text-sm transition-colors duration-200
          ${isDarkMode 
            ? 'hover:bg-[#202020] text-slate-200' 
            : 'hover:bg-slate-50 text-slate-700'
          }
        `}
      >
        <DocumentDuplicateIcon className="w-4 h-4" />
        <span>Duplicate</span>
      </button>
      
      <button
        onClick={() => {
          onPin(item);
          onClose();
        }}
        className={`
          w-full flex items-center gap-2 px-3 py-1.5
          text-sm transition-colors duration-200
          ${isDarkMode 
            ? 'hover:bg-[#202020] text-slate-200' 
            : 'hover:bg-slate-50 text-slate-700'
          }
        `}
      >
        <StarIcon className="w-4 h-4" />
        <span>Pin to Top</span>
      </button>
      
      <button
        onClick={() => {
          onExport(item);
          onClose();
        }}
        className={`
          w-full flex items-center gap-2 px-3 py-1.5
          text-sm transition-colors duration-200
          ${isDarkMode 
            ? 'hover:bg-[#202020] text-slate-200' 
            : 'hover:bg-slate-50 text-slate-700'
          }
        `}
      >
        <ArrowDownTrayIcon className="w-4 h-4" />
        <span>Export</span>
      </button>
    </div>
  );
};

const CanvasWelcome = ({ 
  onCreateCanvas, 
  existingCanvases = [], 
  setExistingCanvases,
  onSelectCanvas,
  addNotification
}) => {
  const { isDarkMode } = useTheme();
  const [viewMode, setViewMode] = useState('grid');
  const [searchQuery, setSearchQuery] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  // Add these new state declarations
  const [tabs, setTabs] = useState(() => {
    try {
      const savedTabs = localStorage.getItem('canvasTabs');
      return savedTabs ? JSON.parse(savedTabs) : [];
    } catch (error) {
      console.error('Error parsing tabs from localStorage:', error);
      return [];
    }
  });
  
  const [activeTabId, setActiveTabId] = useState(null);
  const [showWelcome, setShowWelcome] = useState(() => {
    const lastView = localStorage.getItem('canvasLastView');
    const lastActiveTabId = localStorage.getItem('lastActiveTabId');
    
    // If there's an active tab, don't show welcome
    if (lastActiveTabId) {
      return false;
    }
    
    return !lastView || lastView === 'welcome';
  });

  // Add this state near your other state declarations
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);

  // Add this new state
  const [activeMenu, setActiveMenu] = useState(null);

  // Add this new state for sort dropdown
  const [showSortDropdown, setShowSortDropdown] = useState(false);

  // Add this new useEffect to fetch canvases on mount
  useEffect(() => {
    const fetchCanvases = async () => {
      try {
        console.log('Fetching canvases from database...');
        const fetchedCanvases = await getUserCanvases();
        console.log('Raw fetched canvases:', fetchedCanvases);
        
        if (!Array.isArray(fetchedCanvases)) {
          console.error('Fetched canvases is not an array:', fetchedCanvases);
          addNotification('Error loading canvases: Invalid data format', 'error');
          return;
        }

        // Transform the fetched canvases to match our local format
        const transformedCanvases = fetchedCanvases
          .filter(canvas => canvas && typeof canvas === 'object')
          .map(canvas => {
            if (!canvas._id || !canvas.title) {
              console.warn('Invalid canvas data:', canvas);
              return null;
            }
            return {
              id: canvas._id,
              type: 'canvas',
              title: canvas.title || 'Untitled Canvas',
              lastEdited: canvas.lastEdited || new Date().toISOString(),
              posts: canvas.posts || []
            };
          })
          .filter(Boolean); // Remove any null entries

        console.log('Transformed canvases:', transformedCanvases);

        setExistingCanvases(prev => {
          // Deep comparison of canvases to prevent unnecessary updates
          const sortAndStringify = (canvases) => {
            if (!Array.isArray(canvases)) return '';
            return JSON.stringify(
              [...canvases]
                .filter(canvas => canvas && canvas.id && canvas.title)
                .sort((a, b) => {
                  if (!a || !b || !a.id || !b.id) return 0;
                  return (a.id || '').toString().localeCompare((b.id || '').toString());
                })
            );
          };

          const prevJson = sortAndStringify(prev);
          const newJson = sortAndStringify(transformedCanvases);
          
          if (prevJson === newJson) {
            console.log('Canvas data unchanged, skipping update');
            return prev;
          }
          
          console.log('Updating canvas data in state');
          localStorage.setItem('canvases', JSON.stringify(transformedCanvases));
          return transformedCanvases;
        });
      } catch (error) {
        console.error('Error fetching canvases:', error);
        if (error.response) {
          console.error('Error response:', error.response.data);
        }
        addNotification('Failed to load canvases. Please try again.', 'error');
        // Set empty array on error
        setExistingCanvases([]);
        localStorage.removeItem('canvases');
      }
    };

    fetchCanvases();
  }, [addNotification, setExistingCanvases]); // Add dependencies

  // Add this useEffect near the top with other useEffect hooks
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (activeMenu && !event.target.closest('.menu-container')) {
        setActiveMenu(null);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [activeMenu]);

  // Get items for current folder
  const getCurrentItems = useCallback(() => {
    if (!Array.isArray(existingCanvases)) return [];
    return existingCanvases.filter(item => item && typeof item === 'object');
  }, [existingCanvases]);

  // Update the getDateGroup function
  const getDateGroup = (date) => {
    const now = new Date();
    const itemDate = new Date(date);
    const diffTime = Math.abs(now - itemDate);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const diffMonths = Math.floor(diffDays / 30);
    const diffYears = Math.floor(diffDays / 365);

    // Today
    if (diffDays === 0) {
      return 'Today';
    }
    
    // Yesterday
    if (diffDays === 1) {
      return 'Yesterday';
    }
    
    // This Week (within 7 days)
    if (diffDays <= 7) {
      return 'This Week';
    }
    
    // This Month (within 30 days)
    if (diffDays <= 30) {
      return 'This Month';
    }
    
    // Last Month (within 60 days)
    if (diffDays <= 60) {
      return 'Last Month';
    }
    
    // This Year
    if (diffYears === 0) {
      const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
                         'July', 'August', 'September', 'October', 'November', 'December'];
      return monthNames[itemDate.getMonth()];
    }
    
    // Previous Years
    return itemDate.getFullYear().toString();
  };

  // Update the groupedItems memo to include search filtering
  const groupedItems = useMemo(() => {
    const items = getCurrentItems();
    if (!Array.isArray(items)) return new Map();

    // First filter and sort the items
    const sortedItems = [...items]
      .filter(item => {
        // Filter by search query if it exists
        if (searchQuery) {
          return item && item.title && 
                 item.title.toLowerCase().includes(searchQuery.toLowerCase());
        }
        return item && item.title && item.type;
      })
      .sort((a, b) => {
        // Ensure we have valid dates
        const dateA = new Date(a.lastEdited || 0);
        const dateB = new Date(b.lastEdited || 0);
        return dateB - dateA; // Sort by most recent first
      });

    // Group the items
    const groups = new Map();
    sortedItems.forEach(item => {
      const group = getDateGroup(item.lastEdited);
      if (!groups.has(group)) {
        groups.set(group, []);
      }
      groups.get(group).push(item);
    });

    return groups;
  }, [getCurrentItems, searchQuery]); // Add searchQuery as a dependency

  const getPreviewBackground = (type, isDarkMode) => {
    if (type === 'folder') {
      return isDarkMode 
        ? 'bg-slate-800/50' 
        : 'bg-slate-100';
    }
    return isDarkMode
      ? 'bg-slate-800/50'
      : 'bg-slate-100';
  };

  const getItemStyles = (viewMode, isDarkMode) => {
    const baseStyles = `
      transition-all duration-200 ease-out
      ${isDarkMode ? 'hover:bg-[#1e1e1e]' : 'hover:bg-gray-50'}
      ${isDarkMode ? 'border-[#333333]' : 'border-gray-200'}
      cursor-default
    `;

    if (viewMode === 'grid') {
      return `${baseStyles} 
        group relative
        border rounded-lg hover:shadow-sm
        transform hover:-translate-y-0.5
        w-full
        h-[60px]
        ${isDarkMode ? 'bg-[#151515]' : 'bg-white'}
      `;
    }

    return `${baseStyles} 
      flex items-center p-2.5 rounded-lg border
      hover:shadow-sm w-full
      ${isDarkMode ? 'bg-[#151515]' : 'bg-white'}
    `;
  };

  const renderPreview = (item) => {
    return (
      <div className="flex items-center">
        <PencilIcon className={`w-5 h-5 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
      </div>
    );
  };

  // Add these new state declarations after other state declarations
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [isSelectionMode, setIsSelectionMode] = useState(false);

  // Add this new function after other handler functions
  const handleSelectionToggle = (itemId) => {
    setSelectedItems(prev => {
      const newSet = new Set(prev);
      if (newSet.has(itemId)) {
        newSet.delete(itemId);
        if (newSet.size === 0) {
          setIsSelectionMode(false);
        }
      } else {
        newSet.add(itemId);
      }
      return newSet;
    });
  };

  // Update the handleDelete function
  const handleDelete = async (items) => {
    try {
      const itemsArray = Array.isArray(items) ? items : [items];
      
      for (const item of itemsArray) {
        if (item.type === 'canvas') {
          await deleteCanvas(item.id);
          
          // Update tabs in localStorage
          const currentTabs = JSON.parse(localStorage.getItem('canvasTabs') || '[]');
          const updatedTabs = currentTabs.filter(tab => tab.id !== item.id);
          localStorage.setItem('canvasTabs', JSON.stringify(updatedTabs));
          
          // If the deleted canvas was the active tab, clear it
          const lastActiveTabId = localStorage.getItem('lastActiveTabId');
          if (lastActiveTabId === item.id) {
            localStorage.removeItem('lastActiveTabId');
            localStorage.setItem('canvasLastView', 'welcome');
          }
        }
      }

      // Update local state after all deletions
      setExistingCanvases(prev => {
        const itemIds = new Set(itemsArray.map(item => item.id));
        const updated = prev.filter(i => !itemIds.has(i.id));
        localStorage.setItem('canvases', JSON.stringify(updated));
        return updated;
      });

      // Dispatch events for all deleted canvases
      itemsArray.forEach(item => {
        const event = new CustomEvent('canvasDeleted', { 
          detail: { canvasId: item.id } 
        });
        window.dispatchEvent(event);
      });

      // Clear selection mode and selected items
      setIsSelectionMode(false);
      setSelectedItems(new Set());

      addNotification(
        itemsArray.length > 1 
          ? `${itemsArray.length} items deleted successfully` 
          : `${itemsArray[0].type === 'folder' ? 'Folder' : 'Canvas'} deleted successfully`, 
        'success'
      );
    } catch (error) {
      console.error('Error deleting items:', error);
      addNotification('Failed to delete items. Please try again.', 'error');
    }
  };

  // Update the handleRename function
  const handleRename = async (itemId, newTitle) => {
    try {
      if (!newTitle?.trim()) return;
      const trimmedTitle = newTitle.trim();

      // Find the item in the existingCanvases array
      const item = existingCanvases.find(i => i.id === itemId);
      if (!item) return;

      // If it's a canvas, update it through the API
      if (item.type === 'canvas') {
        await updateCanvasTitle(itemId, trimmedTitle);
      }
      
      // Update local state
      setExistingCanvases(prev => {
        const updateItemTitle = (items) => {
          return items.map(item => {
            if (item.id === itemId) {
              return { 
                ...item, 
                title: trimmedTitle,
                lastEdited: new Date().toISOString()
              };
            }
            if (item.children) {
              return { ...item, children: updateItemTitle(item.children) };
            }
            return item;
          });
        };

        const updatedCanvases = updateItemTitle(prev);
        localStorage.setItem('canvases', JSON.stringify(updatedCanvases));
        return updatedCanvases;
      });

      // If it's a canvas, update tabs and dispatch event
      if (item.type === 'canvas') {
        // Update tabs in localStorage
        const currentTabs = JSON.parse(localStorage.getItem('canvasTabs') || '[]');
        const updatedTabs = currentTabs.map(tab => 
          tab.id === itemId ? { ...tab, title: trimmedTitle } : tab
        );
        localStorage.setItem('canvasTabs', JSON.stringify(updatedTabs));

        // Dispatch event for tab title update
        const event = new CustomEvent('canvasRenamed', { 
          detail: { canvasId: itemId, newTitle: trimmedTitle } 
        });
        window.dispatchEvent(event);
      }

      setEditingItemId(null);
      setEditingTitle('');
      addNotification('Title updated successfully', 'success');
    } catch (error) {
      console.error('Error updating title:', error);
      addNotification('Failed to update title', 'error');
      setEditingItemId(null);
      setEditingTitle('');
    }
  };

  // Update the renderTitle function with a more minimal editing hint
  const renderTitle = (item) => {
    if (editingItemId === item.id) {
      return (
        <div className="relative flex items-center w-full group">
          <input
            ref={editInputRef}
            type="text"
            value={editingTitle}
            onChange={(e) => setEditingTitle(e.target.value)}
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.key === 'Enter') {
                handleRename(item.id, editingTitle);
              } else if (e.key === 'Escape') {
                setEditingItemId(null);
                setEditingTitle('');
              }
            }}
            onBlur={() => {
              if (editingTitle.trim() !== item.title) {
                handleRename(item.id, editingTitle);
              } else {
                setEditingItemId(null);
                setEditingTitle('');
              }
            }}
            className={`
              w-full bg-transparent border-none outline-none
              font-medium text-sm py-0.5 px-1.5
              focus:ring-0
              ${isDarkMode 
                ? 'text-slate-200 bg-[#1e1e1e]' 
                : 'text-slate-900 bg-slate-100/50'
              }
              rounded-sm
            `}
            autoFocus
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      );
    }

    return (
      <h3 
        className={`
          font-medium text-sm truncate
          py-0.5 px-1.5 -ml-1.5
          rounded-sm
          transition-colors duration-150
          group-hover:bg-opacity-50
          ${isDarkMode 
            ? 'text-slate-200 group-hover:bg-[#202020]' 
            : 'text-slate-900 group-hover:bg-slate-100/50'
          }
        `}
        onDoubleClick={(e) => {
          e.stopPropagation();
          setEditingItemId(item.id);
          setEditingTitle(item.title);
        }}
      >
        {item.title}
      </h3>
    );
  };

  // Update the handleMenuToggle function to capture click coordinates
  const handleMenuToggle = (e, itemId) => {
    e.stopPropagation();
    if (activeMenu?.id === itemId) {
      setActiveMenu(null);
    } else {
      const rect = e.currentTarget.getBoundingClientRect();
      setActiveMenu({
        id: itemId,
        x: rect.left,
        y: rect.bottom
      });
    }
  };

  // Function to filter items based on search query
  const filterItems = (items) => {
    return items.filter(item => {
      const matchesSearch = item.title.toLowerCase().includes(searchQuery.toLowerCase());
      if (item.children) {
        const childMatches = filterItems(item.children).length > 0;
        return matchesSearch || childMatches;
      }
      return matchesSearch;
    });
  };

  // Update the handleCreateCanvas function to return the new canvas ID
  const handleCreateCanvas = async () => {
    try {
      console.log('Creating new canvas...');
      const newCanvas = await createCanvas('Untitled Campaign');
      console.log('Created canvas:', newCanvas);

      const formattedCanvas = {
        id: newCanvas._id,
        type: 'canvas',
        title: newCanvas.title,
        lastEdited: newCanvas.lastEdited || new Date().toISOString(),
        posts: []
      };

      setExistingCanvases(prev => {
        const updated = [...prev, formattedCanvas];
        localStorage.setItem('canvases', JSON.stringify(updated));
        return updated;
      });
      
      addNotification('Canvas created successfully', 'success');
      return newCanvas._id; // Return the new canvas ID
    } catch (error) {
      console.error('Error creating canvas:', error);
      addNotification('Failed to create canvas. Please try again.', 'error');
      return null;
    }
  };

  // Sort canvases safely with null checks
  const sortedCanvases = useMemo(() => {
    if (!Array.isArray(existingCanvases)) return [];
    
    return [...existingCanvases]
      .filter(canvas => canvas && canvas.title) // Filter out null/undefined canvases
      .sort((a, b) => {
        // Safe comparison with fallbacks
        const dateA = a.lastEdited ? new Date(a.lastEdited) : new Date(0);
        const dateB = b.lastEdited ? new Date(b.lastEdited) : new Date(0);
        return dateB - dateA; // Sort by date, most recent first
      });
  }, [existingCanvases]);

  // Update the useEffect that handles initial canvas loading
  useEffect(() => {
    const lastActiveTabId = localStorage.getItem('lastActiveTabId');
    const lastView = localStorage.getItem('canvasLastView');

    // Only attempt to restore if we have an active tab and it's not welcome view
    if (lastActiveTabId && lastView !== 'welcome') {
      const canvas = existingCanvases.find(c => c.id === lastActiveTabId);
      if (canvas) {
        onSelectCanvas(lastActiveTabId);
        setShowWelcome(false);
      }
    }
  }, [existingCanvases, onSelectCanvas, setShowWelcome]);

  // Update the handleSelectCanvas function
  const handleSelectCanvas = (canvasId) => {
    // Save the selected canvas ID
    localStorage.setItem('lastActiveTabId', canvasId);
    localStorage.setItem('canvasLastView', 'canvas');
    
    // Add or activate tab
    setTabs(prev => {
      const existingTab = prev.find(tab => tab.id === canvasId);
      if (!existingTab) {
        const canvas = existingCanvases.find(c => c.id === canvasId);
        const newTab = {
          id: canvasId,
          title: canvas?.title || 'Untitled Canvas'
        };
        const updatedTabs = [...prev, newTab];
        localStorage.setItem('canvasTabs', JSON.stringify(updatedTabs));
        return updatedTabs;
      }
      return prev;
    });
    
    onSelectCanvas(canvasId);
    setShowWelcome(false);
  };

  // Update the handleHomeClick function
  const handleHomeClick = () => {
    // Clear the active tab ID and set view to welcome
    localStorage.removeItem('lastActiveTabId');
    localStorage.setItem('canvasLastView', 'welcome');
    
    setShowWelcome(true);
  };

  // Add this effect to sync tabs with localStorage
  useEffect(() => {
    const savedTabs = localStorage.getItem('canvasTabs');
    if (savedTabs) {
      try {
        const parsedTabs = JSON.parse(savedTabs);
        if (Array.isArray(parsedTabs) && parsedTabs.length > 0) {
          setTabs(parsedTabs);
        }
      } catch (error) {
        console.error('Error parsing saved tabs:', error);
      }
    }
  }, []); // Only run on mount

  // In the toolbar section, update the sort dropdown to match view toggle style:

  <div className="flex items-center justify-between mb-3 sticky top-0 z-10 bg-inherit backdrop-blur-sm">
    {/* Sort Dropdown - Updated to match view toggle style */}
    <div className="relative" data-sort-dropdown> {/* Add data attribute here */}
      <button
        onClick={() => setShowSortDropdown(!showSortDropdown)}
        className={`
          flex items-center space-x-2 px-3 py-1.5 
          rounded-md border text-sm
          transition-colors duration-200
          ${isDarkMode 
            ? 'border-slate-800 hover:bg-slate-800/50 text-slate-300' 
            : 'border-slate-200 hover:bg-slate-50 text-slate-600'
          }
        `}
      >
      </button>
      
      {showSortDropdown && (
        <div 
          className={`
            fixed mt-1 w-40
            rounded-md shadow-lg border
            overflow-hidden
            ${isDarkMode 
              ? 'bg-slate-800 border-slate-700' 
              : 'bg-white border-slate-200'
            }
          `}
          style={{
            zIndex: 9999,
            top: 'calc(100% + 4px)',
            left: '0',
          }}
        >
          {['lastModified', 'name'].map((option) => (
            <button
              key={option}
              className={`
                w-full px-3 py-1.5 text-left text-sm
                transition-colors duration-200
              `}
            >
              {option === 'lastModified' ? 'Last modified' : 'Name'}
            </button>
          ))}
        </div>
      )}
    </div>

    {/* View Toggle - More compact */}
    <div className={`
      flex rounded-full overflow-hidden
      ${isDarkMode 
        ? 'bg-[#1e1e1e]' 
        : 'bg-slate-100'
      }
      transition-colors duration-200
    `}>
      {['grid', 'list'].map((view) => (
        <button
          key={view}
          onClick={() => setViewMode(view)}
          className={`
            p-1.5 transition-all duration-200
            ${viewMode === view
              ? isDarkMode
                ? 'bg-slate-700/50 text-white'
                : 'bg-white text-slate-900 shadow-sm'
              : isDarkMode
                ? 'text-slate-400 hover:text-slate-300 hover:bg-slate-700/30'
                : 'text-slate-600 hover:text-slate-900 hover:bg-white/50'
            }
          `}
        >
          {view === 'grid' 
            ? <Squares2X2Icon className="w-3.5 h-3.5" />
            : <ListBulletIcon className="w-3.5 h-3.5" />
          }
        </button>
      ))}
    </div>
  </div>

  // Update the useEffect for handling clicks outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showSortDropdown && !event.target.closest('[data-sort-dropdown]')) {
        setShowSortDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [showSortDropdown]);

  // Update the state for new menu
  const [showNewOptions, setShowNewOptions] = useState(false);

  // Add effect to check for persisted state
  useEffect(() => {
    const lastActiveTabId = localStorage.getItem('lastActiveTabId');
    const lastView = localStorage.getItem('canvasLastView');

    if (lastActiveTabId && lastView !== 'welcome') {
      onSelectCanvas(lastActiveTabId);
    }
  }, []); // Run only on mount

  // Add this new state for inline editing
  const [editingItemId, setEditingItemId] = useState(null);
  const [editingTitle, setEditingTitle] = useState('');
  const editInputRef = useRef(null);

  // Add this effect to handle click outside for inline editing
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (editingItemId && editInputRef.current && !editInputRef.current.contains(e.target)) {
        handleRename(editingItemId, editingTitle);
        setEditingItemId(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [editingItemId, editingTitle]);

  // Add a ref for the main container
  const containerRef = useRef(null);

  const [pinnedCanvases, setPinnedCanvases] = useState(() => {
    try {
      return new Set(JSON.parse(localStorage.getItem('pinnedCanvases') || '[]'));
    } catch {
      return new Set();
    }
  });

  const handleDuplicateCanvas = async (canvas) => {
    try {
      const newCanvas = await createCanvas(`${canvas.title} (Copy)`);
      const formattedCanvas = {
        id: newCanvas._id,
        type: 'canvas',
        title: newCanvas.title,
        lastEdited: new Date().toISOString(),
        posts: []
      };

      setExistingCanvases(prev => {
        const updated = [...prev, formattedCanvas];
        localStorage.setItem('canvases', JSON.stringify(updated));
        return updated;
      });

      addNotification('Canvas duplicated successfully', 'success');
    } catch (error) {
      console.error('Error duplicating canvas:', error);
      addNotification('Failed to duplicate canvas', 'error');
    }
  };

  const handlePinCanvas = (canvas) => {
    setPinnedCanvases(prev => {
      const newPinned = new Set(prev);
      if (newPinned.has(canvas.id)) {
        newPinned.delete(canvas.id);
      } else {
        newPinned.add(canvas.id);
      }
      localStorage.setItem('pinnedCanvases', JSON.stringify([...newPinned]));
      return newPinned;
    });
  };

  const handleExportCanvas = (canvas) => {
    try {
      const canvasData = {
        title: canvas.title,
        posts: canvas.posts || [],
        lastEdited: canvas.lastEdited,
        id: canvas.id
      };
      
      const blob = new Blob([JSON.stringify(canvasData, null, 2)], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${canvas.title.toLowerCase().replace(/\s+/g, '-')}.json`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      
      addNotification('Canvas exported successfully', 'success');
    } catch (error) {
      console.error('Error exporting canvas:', error);
      addNotification('Failed to export canvas', 'error');
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div 
        ref={containerRef}
        className={`
          w-full h-full flex flex-col overflow-hidden cursor-default relative
          ${isDarkMode 
            ? 'bg-[#121212]'
            : 'bg-white'
          }
        `}
      >
        {/* Compact Top Bar */}
        <div className={`
          px-4 py-2.5
          border-b
          ${isDarkMode 
            ? 'border-slate-800/80' 
            : 'border-slate-200/80'
          }
        `}>
          <div className="flex items-center justify-between">
            {/* Left: Title + Count */}
            <div className="flex items-center gap-2">
              <h1 className={`
                text-xs font-medium
                ${isDarkMode ? 'text-white' : 'text-slate-900'}
              `}>
                My Campaigns
                {existingCanvases.length > 0 && (
                  <span className={`
                    ml-1.5 px-1 py-0.5 rounded
                    text-[10px] tabular-nums
                    ${isDarkMode 
                      ? 'bg-slate-800 text-slate-400' 
                      : 'bg-slate-100 text-slate-600'
                    }
                  `}>
                    {existingCanvases.length}
                  </span>
                )}
              </h1>
            </div>

            {/* Right: Controls */}
            <div className="flex items-center gap-1.5">
              {/* Search */}
              <div className="relative">
                {isSearchExpanded ? (
                  <motion.div
                    initial={{ width: 0, opacity: 0 }}
                    animate={{ width: 180, opacity: 1 }}
                    exit={{ width: 0, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    className={`
                      flex items-center rounded
                      ${isDarkMode 
                        ? 'bg-[#1e1e1e]' 
                        : 'bg-slate-100'
                      }
                    `}
                  >
                    <MagnifyingGlassIcon className="w-3 h-3 ml-2 text-slate-400" />
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className={`
                        w-full bg-transparent border-none outline-none
                        py-1 pl-1.5 pr-2 text-xs
                        ${isDarkMode 
                          ? 'text-white placeholder-slate-500' 
                          : 'text-slate-900 placeholder-slate-400'
                        }
                      `}
                      autoFocus
                    />
                    <button
                      onClick={() => {
                        setSearchQuery('');
                        setIsSearchExpanded(false);
                      }}
                      className={`
                        p-1 rounded
                        ${isDarkMode 
                          ? 'hover:bg-slate-800 text-slate-400' 
                          : 'hover:bg-slate-200 text-slate-500'
                        }
                      `}
                    >
                      <XMarkIcon className="w-3 h-3" />
                    </button>
                  </motion.div>
                ) : (
                  <button
                    onClick={() => setIsSearchExpanded(true)}
                    className={`
                      p-1 rounded
                      ${isDarkMode 
                        ? 'hover:bg-[#1e1e1e] text-slate-400' 
                        : 'hover:bg-slate-100 text-slate-600'
                      }
                    `}
                  >
                    <MagnifyingGlassIcon className="w-3.5 h-3.5" />
                  </button>
                )}
              </div>

              {/* View Toggle */}
              <div className={`
                flex rounded overflow-hidden
                ${isDarkMode ? 'bg-[#1e1e1e]' : 'bg-slate-100'}
              `}>
                {['grid', 'list'].map((view) => (
                  <button
                    key={view}
                    onClick={() => setViewMode(view)}
                    className={`
                      p-1 transition-colors
                      ${viewMode === view
                        ? isDarkMode
                          ? 'bg-slate-800 text-white'
                          : 'bg-white text-slate-900'
                        : isDarkMode
                          ? 'text-slate-400 hover:bg-slate-800'
                          : 'text-slate-600 hover:bg-white'
                      }
                    `}
                  >
                    {view === 'grid' 
                      ? <Squares2X2Icon className="w-3.5 h-3.5" />
                      : <ListBulletIcon className="w-3.5 h-3.5" />
                    }
                  </button>
                ))}
              </div>

              {/* New Button */}
              <button
                onClick={handleCreateCanvas}
                className={`
                  flex items-center gap-1
                  px-2 py-1 rounded
                  text-[10px] font-medium
                  ${isDarkMode
                    ? 'bg-white/10 hover:bg-white/15 text-white'
                    : 'bg-slate-900 hover:bg-slate-800 text-white'
                  }
                `}
              >
                <PlusIcon className="w-3 h-3" />
                <span>New</span>
              </button>
            </div>
          </div>
        </div>

        {/* Wrapper for scrollable content */}
        <div className="flex-1 relative min-h-0">
          {/* Scrollable content area */}
          <div 
            className={`
              absolute inset-0 bottom-[46px]
              custom-scrollbar
              ${isDarkMode 
                ? 'bg-[#121212]'
                : 'bg-white'
              }
              px-6 py-4
            `}
            style={{
              overflowY: 'auto',
              paddingBottom: isSelectionMode ? '5rem' : '3rem',
            }}
          >
            {/* Grid/List Container */}
            <div className={`
              h-full w-full
              ${groupedItems.size === 0 ? 'flex items-center justify-center' : ''}
            `}>
              {searchQuery && groupedItems.size === 0 ? (
                <NoSearchResults searchQuery={searchQuery} isDarkMode={isDarkMode} />
              ) : groupedItems.size > 0 ? (
                <div className={`
                  ${viewMode === 'grid' 
                    ? 'space-y-2 md:grid md:gap-6 md:auto-rows-[60px] md:grid-cols-[repeat(auto-fill,minmax(240px,1fr))]'
                    : 'space-y-2'
                  }
                  px-4
                `}>
                  {Array.from(groupedItems.entries()).map(([group, items]) => (
                    <div key={group} className="space-y-4">
                      <div className={`
                        sticky top-0 
                        z-20
                        py-2
                      `}>
                        {/* Header content */}
                        <motion.div
                          initial={{ opacity: 0, y: -8 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.2 }}
                          className={`
                            relative
                            flex items-center
                            px-1
                          `}
                        >
                          <span className={`
                            px-3 py-1.5 md:px-2.5 md:py-1
                            text-xs font-medium tracking-wide uppercase
                            ${isDarkMode 
                              ? 'text-slate-400 md:bg-[#1e1e1e]/90 md:text-slate-300 md:ring-1 md:ring-slate-800/50' 
                              : 'text-slate-500 md:bg-white/90 md:text-slate-600 md:ring-1 md:ring-slate-200/50'
                            }
                            md:rounded-full
                            md:backdrop-blur-sm
                            md:shadow-sm
                          `}>
                            {group}
                          </span>
                        </motion.div>
                      </div>
                      
                      {/* Items grid/list */}
                      <div className={`
                        ${viewMode === 'grid' 
                          ? 'space-y-2 md:grid md:gap-4 md:auto-rows-[60px] md:grid-cols-[repeat(auto-fill,minmax(240px,1fr))]'
                          : 'space-y-2'
                        }
                        relative
                        z-10
                      `}>
                        {items.map((item) => (
                          <CanvasItem
                            key={item.id}
                            item={item}
                            viewMode={viewMode}
                            onSelect={() => {
                              if (isSelectionMode) {
                                handleSelectionToggle(item.id);
                              } else {
                                onSelectCanvas(item.id);
                              }
                            }}
                            onRename={(item) => {
                              setEditingItemId(item.id);
                              setEditingTitle(item.title);
                            }}
                            onDelete={(item) => {
                              setItemToDelete(item);
                              setShowDeleteConfirm(true);
                            }}
                            isEditing={editingItemId === item.id}
                            editingTitle={editingTitle}
                            onEditingChange={setEditingItemId}
                            onTitleChange={setEditingTitle}
                            onEditComplete={handleRename}
                            isSelected={selectedItems.has(item.id)}
                            isSelectionMode={isSelectionMode}
                            onLongPress={() => {
                              setIsSelectionMode(true);
                              handleSelectionToggle(item.id);
                            }}
                          />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                // Empty state - Enhanced design matching PersonasPage.js
                <motion.div 
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                  className={`
                    flex flex-col items-center justify-center 
                    w-full max-w-md mx-auto my-12
                    ${isDarkMode 
                      ? 'bg-[#151515] border-[#333333]' 
                      : 'bg-slate-50/50 border-slate-200/50'
                    }
                    rounded-xl backdrop-blur-sm
                    border py-8 px-6
                  `}
                >
                  <div className="flex flex-col items-center max-w-sm text-center">
                    <motion.div 
                      initial={{ scale: 0.8, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      transition={{ 
                        delay: 0.1,
                        duration: 0.4,
                        ease: [0.23, 1, 0.32, 1]
                      }}
                      className={`
                        mb-4 p-3.5 rounded-full
                        ${isDarkMode 
                          ? 'bg-[#1e1e1e]' 
                          : 'bg-slate-100/80'
                        }
                      `}
                    >
                      <CanvasGridIcon className={`
                        w-7 h-7
                        transition-colors duration-200
                        ${isDarkMode 
                          ? 'text-slate-400 md:text-slate-500 md:group-hover:text-slate-400' 
                          : 'text-slate-500 md:text-slate-400 md:group-hover:text-slate-500'
                        }
                      `} />
                    </motion.div>

                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.2, duration: 0.4 }}
                    >
                      <h3 className={`
                        text-lg font-medium mb-2
                        ${isDarkMode ? 'text-slate-200' : 'text-slate-900'}
                      `}>
                        Create your first campaign
                      </h3>
                      
                      <p className={`
                        text-sm mb-4
                        ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
                      `}>
                        Start crafting your content strategy with AI-powered campaign creation
                      </p>

                      <motion.button
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        onClick={async () => {
                          const newCanvasId = await handleCreateCanvas();
                          if (newCanvasId) {
                            onSelectCanvas(newCanvasId);
                          }
                        }}
                        className={`
                          inline-flex items-center gap-1.5
                          px-4 py-2 rounded-lg 
                          text-sm font-medium
                          ${isDarkMode 
                            ? 'bg-white/10 hover:bg-white/15 text-white border border-white/10' 
                            : 'bg-slate-900 hover:bg-slate-800 text-white'}
                          transition-colors duration-200
                        `}
                      >
                        <PlusIcon className="w-4 h-4" />
                        <span>New Campaign</span>
                      </motion.button>
                    </motion.div>
                  </div>
                </motion.div>
              )}
            </div>
          </div>

          {/* Action bar - positioned absolutely within wrapper */}
          <CanvasWelcomeActionBar
            isVisible={isSelectionMode}
            selectedCount={selectedItems.size}
            onCancel={() => {
              setIsSelectionMode(false);
              setSelectedItems(new Set());
            }}
            onDelete={() => {
              const itemsToDelete = Array.from(selectedItems).map(id => 
                existingCanvases.find(canvas => canvas.id === id)
              ).filter(Boolean);
              setItemToDelete(itemsToDelete);
              setShowDeleteConfirm(true);
            }}
            isDarkMode={isDarkMode}
            containerRef={containerRef}
          />
        </div>

        {/* Add the Stats bottom bar here */}
        {existingCanvases.length > 0 && (
          <StatsBottomBarforCanvasWelcome 
            canvases={existingCanvases}
            isDarkMode={isDarkMode}
          />
        )}

        {/* Delete Confirmation Modal */}
        {showDeleteConfirm && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className={`
              fixed inset-0 flex items-center justify-center
              ${isDarkMode 
                ? 'bg-black/20' 
                : 'bg-slate-500/10'
              }
              backdrop-blur-[2px] z-50
            `}
            onClick={() => {
              setShowDeleteConfirm(false);
              setItemToDelete(null);
            }}
          >
            <motion.div
              initial={{ opacity: 0, scale: 0.95, y: 10 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.95, y: 10 }}
              transition={{ 
                duration: 0.2,
                ease: [0.23, 1, 0.32, 1]
              }}
              className={`
                w-[380px] rounded-xl shadow-lg
                ${isDarkMode
                  ? 'bg-[#121212]/95 border border-[#333333]'
                  : 'bg-white/95 border border-slate-200/50'
                }
                backdrop-blur-sm
                p-6
              `}
              onClick={e => e.stopPropagation()}
            >
              <div className="flex items-start space-x-4">
                {/* Icon */}
                <div className={`
                  w-10 h-10 rounded-full flex-shrink-0
                  flex items-center justify-center
                  ${isDarkMode ? 'bg-red-500/10' : 'bg-red-50'}
                `}>
                  <motion.div
                    initial={{ rotate: -90, scale: 0.6 }}
                    animate={{ rotate: 0, scale: 1 }}
                    transition={{ 
                      delay: 0.1,
                      duration: 0.4,
                      ease: [0.23, 1, 0.32, 1]
                    }}
                  >
                    <TrashIcon className="w-5 h-5 text-red-500" />
                  </motion.div>
                </div>

                {/* Content */}
                <div className="flex-1 space-y-4">
                  <div>
                    <h3 className={`
                      text-base font-semibold mb-1
                      ${isDarkMode ? 'text-white' : 'text-slate-900'}
                    `}>
                      Delete {Array.isArray(itemToDelete) ? 'Items' : 'Canvas'}
                    </h3>
                    
                    <p className={`
                      text-sm
                      ${isDarkMode ? 'text-slate-300' : 'text-slate-600'}
                    `}>
                      {Array.isArray(itemToDelete) 
                        ? `Are you sure you want to delete ${itemToDelete.length} selected ${
                            itemToDelete.length === 1 ? 'item' : 'items'
                          }?`
                        : `Are you sure you want to delete "${itemToDelete?.title}"?`
                      }
                      <span className={`
                        block mt-1 text-xs
                        ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}
                      `}>
                        This action cannot be undone.
                      </span>
                    </p>
                  </div>

                  {/* Buttons */}
                  <div className="flex items-center justify-end gap-2">
                    <motion.button
                      whileHover={{ scale: 1.01 }}
                      whileTap={{ scale: 0.98 }}
                      onClick={() => {
                        setShowDeleteConfirm(false);
                        setItemToDelete(null);
                      }}
                      className={`
                        px-4 py-2 rounded-lg text-sm font-medium
                        transition-colors duration-200
                        ${isDarkMode
                          ? 'text-slate-300 hover:bg-slate-800'
                          : 'text-slate-600 hover:bg-slate-100'
                        }
                        focus:outline-none
                      `}
                    >
                      Cancel
                    </motion.button>

                    <motion.button
                      whileHover={{ scale: 1.01 }}
                      whileTap={{ scale: 0.98 }}
                      onClick={() => {
                        handleDelete(itemToDelete);
                        setShowDeleteConfirm(false);
                        setItemToDelete(null);
                      }}
                      className={`
                        px-4 py-2 rounded-lg
                        font-medium text-sm text-white
                        bg-red-500 hover:bg-red-600
                        transition-colors duration-200
                        focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2
                        ${isDarkMode ? 'focus:ring-offset-slate-900' : 'focus:ring-offset-white'}
                      `}
                    >
                      Delete
                    </motion.button>
                  </div>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}

        {/* Update the context menu in CanvasItem to include QuickActions */}
        {activeMenu && (
          <ContextMenu
            x={activeMenu.x}
            y={activeMenu.y}
            onClose={() => setActiveMenu(null)}
            isDarkMode={isDarkMode}
          >
            <QuickActionsMenu
              item={existingCanvases.find(c => c.id === activeMenu.id)}
              onClose={() => setActiveMenu(null)}
              isDarkMode={isDarkMode}
              onDuplicate={handleDuplicateCanvas}
              onPin={handlePinCanvas}
              onExport={handleExportCanvas}
            />
          </ContextMenu>
        )}
      </div>
    </DndProvider>
  );
};

// Add prop types validation
CanvasWelcome.propTypes = {
  onCreateCanvas: PropTypes.func.isRequired,
  existingCanvases: PropTypes.array.isRequired,
  setExistingCanvases: PropTypes.func.isRequired,
  onSelectCanvas: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired
};

export default CanvasWelcome;
