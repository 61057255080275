import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Timer from './Timer';
import { useTheme } from '../context/ThemeContext';

const FullScreenTimer = ({ onClose }) => {
  const { isDarkMode } = useTheme();

  return (
    <div className={`fixed inset-0 z-50 flex items-center justify-center ${isDarkMode ? 'bg-gray-900' : 'bg-white'}`}>
      <button
        onClick={onClose}
        className={`absolute top-4 right-4 p-2 rounded-full ${
          isDarkMode ? 'bg-gray-800 text-gray-200 hover:bg-gray-700' : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
        }`}
      >
        <XMarkIcon className="w-6 h-6" />
      </button>
      <div className="w-full max-w-md">
        <Timer fullScreen={true} />
      </div>
    </div>
  );
};

export default FullScreenTimer;