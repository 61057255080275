import React, { useState, useEffect, useCallback, useContext, useRef, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon, MagnifyingGlassIcon, ChatBubbleLeftRightIcon, XMarkIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { fetchChatHistory, deleteChat, downloadAllChats } from '../api';
import LoadingSpinner from './LoadingSpinner';
import { AuthContext } from '../context/AuthContext';
import Notification from './Notification';
import ChatListItem from './ChatListItem';
import { useTheme } from '../context/ThemeContext'; // Add this import
import { motion, AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';
import ChatHistoryIcon from './icons/ChatHistoryIcon';
import DownloadOptionsPortal from './DownloadOptionsPortal';

const SkeletonLoader = ({ isDarkMode, isVisible }) => {
  return (
    <div className={`pl-4 pr-4 pb-20 transition-opacity duration-300 ease-in-out ${
      isVisible ? 'opacity-100' : 'opacity-0'
    }`}>
      {[...Array(9)].map((_, index) => (
        <div key={index} className={`${
          isDarkMode ? 'bg-[#121212]/95' : 'bg-white'
        } rounded-lg shadow-sm p-3 mb-2 flex flex-col animate-pulse border ${
          isDarkMode ? 'border-[#333333]' : 'border-gray-200'
        }`}>
          <div className="flex justify-between items-start mb-1">
            <div className={`h-4 ${
              isDarkMode ? 'bg-[#1e1e1e]' : 'bg-gray-200'
            } rounded w-3/4`}></div>
            <div className="flex items-center">
              <div className={`h-3 w-12 ${
                isDarkMode ? 'bg-[#1e1e1e]' : 'bg-gray-200'
              } rounded mr-2`}></div>
              <div className={`h-5 w-5 ${
                isDarkMode ? 'bg-[#1e1e1e]' : 'bg-gray-200'
              } rounded-full`}></div>
            </div>
          </div>
          <div className="flex items-center justify-between mt-2">
            <div className={`h-3 ${
              isDarkMode ? 'bg-[#1e1e1e]' : 'bg-gray-200'
            } rounded w-1/2`}></div>
            <div className="flex items-center ml-2">
              <div className={`h-3 w-3 ${
                isDarkMode ? 'bg-[#1e1e1e]' : 'bg-gray-200'
              } rounded-full mr-1`}></div>
              <div className={`h-3 w-4 ${
                isDarkMode ? 'bg-[#1e1e1e]' : 'bg-gray-200'
              } rounded`}></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const EmptyState = ({ isDarkMode }) => (
  <div className="flex flex-col items-center justify-center h-full text-center p-4">
    <ChatBubbleLeftRightIcon className={`h-16 w-16 ${isDarkMode ? 'text-slate-600' : 'text-gray-400'} mb-4`} />
    <h3 className={`text-lg font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-900'} mb-2`}>No chats yet</h3>
    <p className={`text-sm ${isDarkMode ? 'text-slate-400' : 'text-gray-500'} max-w-sm`}>
      You haven't started any chats yet. Click the "New Chat" button to begin your first conversation!
    </p>
  </div>
);

// Add this new component near the top of the file, after the EmptyState component
const NoSearchResults = ({ isDarkMode, searchTerm }) => (
  <div className="flex flex-col items-center justify-center h-full text-center p-4">
    <MagnifyingGlassIcon className={`h-16 w-16 ${isDarkMode ? 'text-slate-600' : 'text-gray-400'} mb-4`} />
    <h3 className={`text-lg font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-900'} mb-2`}>No matching chats found</h3>
    <p className={`text-sm ${isDarkMode ? 'text-slate-400' : 'text-gray-500'} max-w-sm`}>
      We couldn't find any chats matching "{searchTerm}". Try a different search term or start a new chat!
    </p>
  </div>
);

// Add this new component for the expandable search bar
const ExpandableSearchBar = ({ isDarkMode, searchTerm, setSearchTerm, isExpanded, setIsExpanded, className }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (isExpanded) {
      inputRef.current?.focus();
    }
  }, [isExpanded]);

  const handleSearchChange = (e) => {
    const newSearchTerm = e.target.value;
    console.log('Search input changed:', newSearchTerm);
    setSearchTerm(newSearchTerm);
  };

  return (
    <div className={`relative ${className}`}>
      {isExpanded ? (
        <div className="flex items-center">
          <input
            ref={inputRef}
            type="text"
            placeholder="Search chats..."
            className={`w-40 pl-8 pr-8 py-1 text-xs border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500/30
                        ${isDarkMode 
                          ? 'bg-[#1e1e1e] text-slate-200 border-[#333333]' 
                          : 'bg-white text-gray-900 border-gray-300'}`}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <MagnifyingGlassIcon className={`absolute left-2 top-1/2 transform -translate-y-1/2 w-4 h-4 ${
            isDarkMode ? 'text-slate-400' : 'text-gray-400'
          }`} />
          <button
            onClick={() => setIsExpanded(false)}
            className="absolute right-2 top-1/2 transform -translate-y-1/2"
          >
            <XMarkIcon className={`w-4 h-4 ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`} />
          </button>
        </div>
      ) : (
        <button
          onClick={() => setIsExpanded(true)}
          className={`p-1 rounded-full transition-colors duration-200 ${
            isDarkMode ? 'hover:bg-[#1e1e1e]' : 'hover:bg-gray-200'
          }`}
        >
          <MagnifyingGlassIcon className={`h-5 w-5 ${
            isDarkMode ? 'text-slate-400' : 'text-slate-600'
          }`} />
        </button>
      )}
    </div>
  );
};

// Add this new component for the mobile search bar
const MobileSearchBar = ({ isDarkMode, searchTerm, setSearchTerm, isVisible }) => {
  return (
    <div className={`w-full px-4 py-2 transition-all duration-300 ease-in-out ${
      isVisible ? 'max-h-16 opacity-100' : 'max-h-0 opacity-0 overflow-hidden'
    } ${isDarkMode ? 'bg-slate-800' : 'bg-white'}`}>
      <div className="relative">
        <input
          type="text"
          placeholder="Search chats..."
          className={`w-full pl-8 pr-8 py-2 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500
                      ${isDarkMode 
                        ? 'bg-slate-700 text-slate-200 border-slate-600' 
                        : 'bg-white text-gray-900 border-gray-300'}`}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <MagnifyingGlassIcon className={`absolute left-2 top-1/2 transform -translate-y-1/2 w-5 h-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`} />
        {searchTerm && (
          <button
            onClick={() => setSearchTerm('')}
            className="absolute right-2 top-1/2 transform -translate-y-1/2"
          >
            <XMarkIcon className={`w-5 h-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`} />
          </button>
        )}
      </div>
    </div>
  );
};

const ChatHistory = ({ 
  onClose = () => {}, 
  onSelectChat = () => {}, 
  className = '' 
}) => {
  const { user } = useContext(AuthContext);
  const [chats, setChats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [notifications, setNotifications] = useState([]);
  const [isSearchVisible, setIsSearchVisible] = useState(false);

  // Add these new state variables
  const [currentChatId, setCurrentChatId] = useState(null);
  const [chatTitle, setChatTitle] = useState('Untitled Chat');
  const [messages, setMessages] = useState([]);
  const [isNewChat, setIsNewChat] = useState(true);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);

  const [filteredChats, setFilteredChats] = useState([]);
  const [showDownloadOptions, setShowDownloadOptions] = useState(false);
  const [downloadButtonPosition, setDownloadButtonPosition] = useState(null);
  const downloadButtonRef = useRef(null);

  const chatListRef = useRef(null);

  // Update these state variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // Add this new state variable
  const [pageSize, setPageSize] = useState(9);

  const [isVisible, setIsVisible] = useState(false);
  const [isSkeletonVisible, setIsSkeletonVisible] = useState(false);

  // Add this new state for mobile search visibility
  const [isMobileSearchVisible, setIsMobileSearchVisible] = useState(false);

  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const observerTarget = useRef(null);

  const { isDarkMode } = useTheme(); // Get isDarkMode from context

  const addNotification = (message, type) => {
    const newNotification = { id: Date.now(), message, type };
    setNotifications(prev => [...prev, newNotification]);
  };

  // Add this new intersection observer effect
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && hasMore && !isLoading && !isLoadingMore) {
          loadMoreChats();
        }
      },
      { threshold: 0.1 }
    );

    const currentTarget = observerTarget.current;
    if (currentTarget) {
      observer.observe(currentTarget);
    }

    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget);
      }
    };
  }, [hasMore, isLoading, isLoadingMore]);

  // Add this function to load more chats
  const loadMoreChats = async () => {
    try {
      setIsLoadingMore(true);
      const nextPage = page + 1;
      const response = await fetchChatHistory(nextPage, pageSize);
      
      if (response.chats && Array.isArray(response.chats)) {
        setChats(prevChats => [...prevChats, ...response.chats]);
        setHasMore(nextPage < response.totalPages);
        setPage(nextPage);
      }
    } catch (error) {
      console.error('Error loading more chats:', error);
      addNotification('Failed to load more chats', 'error');
    } finally {
      setIsLoadingMore(false);
    }
  };

  // Update the loadChats function
  const loadChats = useCallback(async (pageNum) => {
    console.log('Attempting to load chats, page:', pageNum);
    try {
      setIsLoading(true);
      setIsSkeletonVisible(true);
      const response = await fetchChatHistory(pageNum, pageSize);
      console.log('Received chat history response:', response);
      
      if (response && response.chats && Array.isArray(response.chats)) {
        console.log('Valid chats array received, length:', response.chats.length);
        
        const sortedChats = response.chats.sort((a, b) => 
          new Date(b.updatedAt) - new Date(a.updatedAt)
        );
        
        setChats(sortedChats);
        setHasMore(pageNum < response.totalPages);
      } else {
        console.error('Invalid response format:', response);
        throw new Error('Invalid response format');
      }
    } catch (err) {
      console.error('Error loading chats:', err);
      setError('Failed to load chat history. Please try again.');
      addNotification('Failed to load chat history. Please try again.', 'error');
    } finally {
      setTimeout(() => {
        setIsSkeletonVisible(false);
        setIsLoading(false);
      }, 300);
    }
  }, [addNotification, pageSize]);

  const handleDeleteChat = useCallback(async (chatId) => {
    try {
      await deleteChat(chatId);
      setChats(prevChats => prevChats.filter(chat => chat._id !== chatId));
      addNotification('Chat deleted successfully', 'success');
    } catch (error) {
      console.error('Error deleting chat:', error);
      addNotification(error.message || 'Failed to delete chat. Please try again.', 'error');
    }
  }, [addNotification]);

  const handleDownloadChats = async (format) => {
    try {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        addNotification('You need to be logged in to download chats', 'error');
        return;
      }
      const data = await downloadAllChats(format);
      let blob, url, filename;

      switch (format) {
        case 'pdf':
        case 'txt':
          blob = new Blob([data], { type: format === 'pdf' ? 'application/pdf' : 'text/plain' });
          url = window.URL.createObjectURL(blob);
          filename = `chat_history.${format}`;
          break;
        case 'json':
          blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
          url = window.URL.createObjectURL(blob);
          filename = 'chat_history.json';
          break;
        default:
          throw new Error('Invalid format');
      }

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      addNotification(`Chat history downloaded successfully as ${format.toUpperCase()}`, 'success');
    } catch (error) {
      console.error('Error downloading chat history:', error);
      if (error.response && error.response.status === 401) {
        addNotification('Authentication failed. Please log in again.', 'error');
      } else {
        addNotification('Failed to download chat history', 'error');
      }
    }
  };

  useEffect(() => {
    console.log('ChatHistory component mounted');
    loadChats(currentPage);
  }, [currentPage]); // Add currentPage as a dependency

  useEffect(() => {
    console.log('Chats state updated:', chats);
  }, [chats]);

  useEffect(() => {
    console.log('Loading state:', isLoading);
  }, [isLoading]);

  useEffect(() => {
    console.log('Search term:', searchTerm);
    console.log('Chats before filtering:', chats);
    try {
      const filtered = chats.filter(chat =>
        chat && (
          (chat.title && typeof chat.title === 'string' && chat.title.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (chat.messages && Array.isArray(chat.messages) &&
            chat.messages.some(msg => 
              msg && msg.content && typeof msg.content === 'string' &&
              msg.content.toLowerCase().includes(searchTerm.toLowerCase())
            ))
        )
      );
      console.log('Filtered chats:', filtered);
      setFilteredChats(filtered);
    } catch (error) {
      console.error('Error filtering chats:', error);
      setFilteredChats([]);
      addNotification('Error filtering chats. Please try again.', 'error');
    }
  }, [chats, searchTerm]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!showDownloadOptions) return;
      
      if (downloadButtonRef.current?.contains(event.target)) return;
      
      setTimeout(() => {
        setShowDownloadOptions(false);
      }, 100);
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDownloadOptions]);

  // Add this new function to handle page changes
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const navigate = useNavigate();

  const handleChatClick = (chatId) => {
    // Use navigate instead of history.pushState
    navigate(`/chat/${chatId}`);
    
    // Call the onSelectChat prop with the chatId
    onSelectChat(chatId);
    
    // Close the chat history panel
    onClose();
  };

  const handleNewChat = useCallback(() => {
    setCurrentChatId(null);
    setChatTitle('');
    setMessages([]);
    setIsNewChat(true);
    localStorage.removeItem('chatState');
    onSelectChat(null); // This will create a new chat
  }, [onSelectChat]);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleClose = () => {
    onClose();
  };

  useLayoutEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), 50);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    return () => {
      setIsVisible(false);
    };
  }, []);

  // Add this near the top of your component
  useEffect(() => {
    console.log('Search term changed:', searchTerm);
  }, [searchTerm]);

  const listItemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 }
  };

  const handleDownloadClick = (e) => {
    e.stopPropagation();
    const buttonRect = downloadButtonRef.current.getBoundingClientRect();
    setDownloadButtonPosition(buttonRect);
    setShowDownloadOptions(!showDownloadOptions);
  };

  return (
    <motion.div 
      className={`flex flex-col h-full relative overflow-hidden
                  ${isDarkMode ? 'bg-[#121212]' : 'bg-white'}
                  border-none rounded-none ${className}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      {/* Top Bar - Always visible */}
      <div className={`
        ${isDarkMode ? 'bg-[#121212] border-[#333333]' : 'bg-white border-gray-200'} 
        !h-10 min-h-10 flex-shrink-0 flex items-center justify-between px-4 py-2 w-full 
        border-b transition-colors duration-200
      `}>
        {/* Left section */}
        <div className="flex items-center">
          <ChatHistoryIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
          <h2 className={`text-sm font-medium ml-1 ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>Chat History</h2>
        </div>

        {/* Right section */}
        <div className="flex items-center space-x-2">
          <ExpandableSearchBar
            isDarkMode={isDarkMode}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            isExpanded={isSearchExpanded}
            setIsExpanded={setIsSearchExpanded}
            className="hidden sm:block" // Hide on mobile, show on larger screens
          />
          <button 
            onClick={() => setIsMobileSearchVisible(!isMobileSearchVisible)}
            className={`sm:hidden p-1 rounded-full transition-colors duration-200
                        ${isDarkMode ? 'hover:bg-slate-700' : 'hover:bg-gray-100'}`}
            title="Search"
          >
            <MagnifyingGlassIcon className={`w-5 h-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
          </button>
          <div className="relative" ref={downloadButtonRef}>
            <button 
              onClick={handleDownloadClick}
              className={`p-1 rounded-full transition-colors duration-200
                          ${isDarkMode ? 'hover:bg-slate-700' : 'hover:bg-gray-100'}`}
              title="Download Chat History"
            >
              <ArrowDownTrayIcon className={`w-5 h-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
            </button>
            <DownloadOptionsPortal
              isOpen={showDownloadOptions}
              onClose={() => setShowDownloadOptions(false)}
              onDownload={handleDownloadChats}
              isDarkMode={isDarkMode}
              buttonPosition={downloadButtonPosition}
            />
          </div>
          <button 
            onClick={handleClose}
            className={`p-1 rounded-full transition-colors duration-200
                        ${isDarkMode ? 'hover:bg-slate-700' : 'hover:bg-gray-100'}`}
            title="Back to Chat"
          >
            <ArrowLeftIcon className={`w-5 h-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
          </button>
        </div>
      </div>

      {/* Mobile Search Bar */}
      <MobileSearchBar
        isDarkMode={isDarkMode}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        isVisible={isMobileSearchVisible}
      />

      {/* Content Area */}
      <div className={`
        flex-grow overflow-y-auto custom-scrollbar
        ${isDarkMode ? 'bg-[#121212]' : 'bg-white'}
        transition-colors duration-200
      `}>
        {error && (
          <motion.div 
            className="p-4 text-red-500 text-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {error}
          </motion.div>
        )}
        {isLoading ? (
          <SkeletonLoader isDarkMode={isDarkMode} isVisible={isSkeletonVisible} />
        ) : filteredChats.length > 0 ? (
          <AnimatePresence>
            <motion.div className="pl-4 pr-4 pb-20">
              {filteredChats.map((chat) => (
                chat && (
                  <motion.div
                    key={chat._id}
                    variants={listItemVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    transition={{ duration: 0.2 }}
                  >
                    <ChatListItem
                      chat={chat}
                      onClick={() => handleChatClick(chat._id)}
                      formatDate={formatDate}
                      onDelete={() => handleDeleteChat(chat._id)}
                      isDarkMode={isDarkMode}
                    />
                  </motion.div>
                )
              ))}
              {/* Observer target and loading indicator */}
              <div ref={observerTarget} className="h-4 w-full">
                {isLoadingMore && (
                  <div className="flex justify-center py-4">
                    <div className={`animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 ${
                      isDarkMode ? 'border-slate-400' : 'border-gray-400'
                    }`}></div>
                  </div>
                )}
              </div>
            </motion.div>
          </AnimatePresence>
        ) : searchTerm ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <NoSearchResults isDarkMode={isDarkMode} searchTerm={searchTerm} />
          </motion.div>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <EmptyState isDarkMode={isDarkMode} />
          </motion.div>
        )}
      </div>

      {notifications.map(notification => (
        <Notification
          key={notification.id}
          message={notification.message}
          type={notification.type}
          onClose={() => setNotifications(prev => prev.filter(n => n.id !== notification.id))}
        />
      ))}
    </motion.div>
  );
};

// Update the prop types
ChatHistory.propTypes = {
  onClose: PropTypes.func,
  onSelectChat: PropTypes.func,
  className: PropTypes.string,
};

export default ChatHistory;