import React from 'react';
import { useTheme } from '../context/ThemeContext';

const CanvasSkeleton = () => {
  const { isDarkMode } = useTheme();

  return (
    <div className={`
      backdrop-blur-sm rounded-xl w-[80%] mx-auto 
      transition-colors duration-300
      p-4 sm:p-6
      max-w-[95%] sm:max-w-[400px]
      ${isDarkMode 
        ? 'bg-[#121212]/95 border-[#333333]/40' 
        : 'bg-white/90 border-slate-200/40'
      } border
    `}>
      <div className="animate-[pulse_2s_ease-in-out_infinite] space-y-4 sm:space-y-6">
        {/* Header with avatar and text */}
        <div className="flex items-center space-x-3 sm:space-x-5">
          <div className={`
            rounded-full h-12 w-12 sm:h-14 sm:w-14 ring-1
            ${isDarkMode 
              ? 'bg-[#1e1e1e] ring-[#333333]/40' 
              : 'bg-gray-100/80 ring-gray-200/40'
            }
          `}></div>
          <div className="flex-1 space-y-2 sm:space-y-3 py-1">
            <div className={`
              h-3 sm:h-3.5 rounded-full w-3/4 ring-1
              ${isDarkMode 
                ? 'bg-[#1e1e1e] ring-[#333333]/40' 
                : 'bg-gray-100/80 ring-gray-200/40'
              }
            `}></div>
            <div className={`
              h-3 sm:h-3.5 rounded-full w-1/2 ring-1
              ${isDarkMode 
                ? 'bg-[#1e1e1e] ring-[#333333]/40' 
                : 'bg-gray-100/80 ring-gray-200/40'
              }
            `}></div>
          </div>
        </div>

        {/* Content area */}
        <div className="space-y-2 sm:space-y-3">
          <div className={`
            h-3 sm:h-3.5 rounded-full ring-1
            ${isDarkMode 
              ? 'bg-[#1e1e1e] ring-[#333333]/40' 
              : 'bg-gray-100/80 ring-gray-200/40'
            }
          `}></div>
          <div className={`
            h-3 sm:h-3.5 rounded-full w-5/6 ring-1
            ${isDarkMode 
              ? 'bg-[#1e1e1e] ring-[#333333]/40' 
              : 'bg-gray-100/80 ring-gray-200/40'
            }
          `}></div>
        </div>

        {/* Image placeholder */}
        <div className="mt-3 sm:mt-4">
          <div className={`
            rounded-xl sm:rounded-2xl h-40 sm:h-48 w-full ring-1
            ${isDarkMode 
              ? 'bg-[#1e1e1e] ring-[#333333]/40' 
              : 'bg-gray-100/80 ring-gray-200/40'
            }
          `}></div>
        </div>
      </div>
    </div>
  );
};

export default CanvasSkeleton; 