import React from 'react';
import { UserGroupIcon } from '@heroicons/react/24/outline';
import AudienceOptionsMenu from './AudienceOptionsMenu';
import SimplePersonaAvatar from '../personas/SimplePersonaAvatar';

const TargetAudienceCard = ({ audience, onEdit, onDelete, onDownload, isDarkMode, handleAudienceClick, handleAvatarError, isPopup }) => {
  // Check if this is a default audience (you can add more conditions if needed)
  const isDefaultAudience = !audience.isCustom; // Add this flag in your data structure

  return (
    <div 
      className={`
        rounded-lg border
        p-3 relative cursor-pointer
        transition-all duration-200
        select-none
        ${isDarkMode 
          ? 'bg-[#151515] hover:bg-[#1a1a1a] border-[#333333]' 
          : 'bg-white hover:bg-gray-50 border-gray-200'
        }
      `}
      onClick={() => handleAudienceClick(audience)}
    >
      <div className="flex items-center gap-3">
        {/* Avatar */}
        <div className="relative flex-shrink-0">
          <div className={`
            w-10 h-10 rounded-full overflow-hidden
            ${isDarkMode 
              ? 'bg-[#1a1a1a] border border-[#333333]' 
              : 'bg-gray-100'
            }
          `}>
            {audience.signedAvatarUrl && audience.signedAvatarUrl !== '/default-avatar.png' ? (
              <img 
                src={audience.signedAvatarUrl} 
                alt={audience.targetAudienceName}
                className="w-full h-full object-cover" 
                onError={() => handleAvatarError(audience._id)}
              />
            ) : (
              <div className="w-full h-full flex items-center justify-center">
                <UserGroupIcon className={`
                  w-6 h-6
                  ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}
                `} />
              </div>
            )}
          </div>
          
          {/* Associated Persona - Small indicator */}
          {audience.associatedPersona && (
            <div className="absolute -bottom-1 -right-1 w-5 h-5">
              <SimplePersonaAvatar 
                persona={audience.associatedPersona} 
                size="tiny"
                title={audience.associatedPersona.name}
              />
            </div>
          )}
        </div>

        {/* Content */}
        <div className="flex-1 min-w-0">
          <h2 className={`
            text-sm font-medium truncate
            ${isDarkMode ? 'text-slate-200' : 'text-gray-800'}
          `}>
            {audience.targetAudienceName}
          </h2>
          <div className={`
            text-xs truncate mt-0.5
            ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}
          `}>
            {[audience.occupation, audience.primaryLocation, audience.industry]
              .filter(Boolean)
              .join(' • ')}
          </div>
        </div>

        {/* Menu */}
        {!isPopup && (
          <AudienceOptionsMenu
            audience={audience}
            onEdit={onEdit}
            onDelete={onDelete}
            onDownload={onDownload}
            isDarkMode={isDarkMode}
          />
        )}
      </div>
    </div>
  );
};

export default TargetAudienceCard;
