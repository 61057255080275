import React from 'react';
import { 
  DocumentTextIcon, 
  XMarkIcon,
  MicrophoneIcon,
  DocumentIcon,
  ClipboardDocumentIcon 
} from '@heroicons/react/24/outline';

const TextFilePreview = ({ 
  content, 
  name, 
  size, 
  lines, 
  isDarkMode, 
  onRemove, 
  onPreview,
  isLoading,
  icon = 'document-text'
}) => {
  console.log('TextFilePreview props:', { content, name, size, lines, onPreview }); // Debug log

  const formatSize = (bytes) => {
    const kb = bytes / 1024;
    return `${kb.toFixed(1)} KB`;
  };

  const handleClick = () => {
    if (isLoading) return;
    console.log('TextFilePreview clicked, content:', content); // Debug log
    if (onPreview) {
      onPreview({ content, name, size, lines });
    } else {
      console.log('onPreview is not defined'); // Debug log
    }
  };

  const getIcon = () => {
    switch(icon) {
      case 'microphone':
        return <MicrophoneIcon className={`h-4 w-4 ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}`} />;
      case 'document':
        return <DocumentIcon className={`h-4 w-4 ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}`} />;
      case 'clipboard':
        return <ClipboardDocumentIcon className={`h-4 w-4 ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}`} />;
      case 'document-text':
      default:
        return <DocumentTextIcon className={`h-4 w-4 ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}`} />;
    }
  };

  return (
    <div 
      className={`
        group flex items-center gap-2 p-1.5 rounded-md 
        transition-colors duration-200
        ${isLoading ? 'opacity-70 cursor-wait' : 'cursor-pointer'}
        ${isDarkMode 
          ? 'bg-slate-800/50 hover:bg-slate-800 border border-slate-700' 
          : 'bg-gray-50 hover:bg-gray-100 border border-gray-200'
        }
      `}
      onClick={handleClick}
    >
      <div className={`
        flex items-center justify-center w-8 h-8 rounded-md shrink-0
        ${isDarkMode ? 'bg-slate-700' : 'bg-white border border-gray-200'}
      `}>
        {isLoading ? (
          <div className="animate-spin rounded-full h-4 w-4 border-2 border-t-transparent border-blue-500" />
        ) : (
          getIcon()
        )}
      </div>
      
      <div className="min-w-0 flex-1">
        <div className={`text-xs font-medium truncate ${isDarkMode ? 'text-slate-200' : 'text-gray-700'}`}>
          {name || 'Untitled'}
        </div>
        <div className={`text-[10px] ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
          {formatSize(size)} • {lines} {lines === 1 ? 'line' : 'lines'}
        </div>
      </div>

      {onRemove && !isLoading && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            onRemove();
          }}
          className={`
            opacity-0 group-hover:opacity-100
            p-1 rounded-md transition-all duration-200
            ${isDarkMode 
              ? 'hover:bg-slate-700 text-slate-400 hover:text-slate-300' 
              : 'hover:bg-gray-200 text-gray-400 hover:text-gray-600'
            }
          `}
        >
          <XMarkIcon className="h-4 w-4" />
        </button>
      )}
    </div>
  );
};

export default TextFilePreview;