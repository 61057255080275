import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ChartBarIcon } from '@heroicons/react/24/outline';

// Move getTimeAgo helper function to a separate utils file if used elsewhere
const getTimeAgo = (date) => {
  const minutes = Math.floor((new Date() - new Date(date)) / 60000);
  if (minutes < 1) return 'Just now';
  if (minutes === 1) return '1 min ago';
  if (minutes < 60) return `${minutes} mins ago`;
  const hours = Math.floor(minutes / 60);
  if (hours === 1) return '1 hour ago';
  if (hours < 24) return `${hours} hours ago`;
  const days = Math.floor(hours / 24);
  if (days === 1) return '1 day ago';
  if (days < 7) return `${days} days ago`;
  const weeks = Math.floor(days / 7);
  if (weeks === 1) return '1 week ago';
  if (weeks < 4) return `${weeks} weeks ago`;
  const months = Math.floor(days / 30);
  if (months === 1) return '1 month ago';
  if (months < 12) return `${months} months ago`;
  const years = Math.floor(days / 365);
  if (years === 1) return '1 year ago';
  return `${years} years ago`;
};

const StatsBottomBarforCanvasWelcome = ({ canvases, isDarkMode }) => {
  const stats = useMemo(() => {
    return {
      totalCanvases: canvases.length,
      totalPosts: canvases.reduce((sum, canvas) => sum + (canvas.posts?.length || 0), 0),
      lastActivity: canvases.reduce((latest, canvas) => {
        const canvasDate = new Date(canvas.lastEdited);
        return latest > canvasDate ? latest : canvasDate;
      }, new Date(0))
    };
  }, [canvases]);

  return (
    <div className={`
      fixed bottom-0 left-0 right-0
      flex items-center justify-between
      px-6 py-2.5
      border-t
      backdrop-blur-xl
      transition-colors duration-200
      z-50
      ${isDarkMode 
        ? 'bg-[#121212]/95 border-[#333333]' 
        : 'bg-white/95 border-slate-200/50'
      }
    `}>
      <div className="flex items-center gap-6">
        <div className="flex items-center gap-2">
          <ChartBarIcon className={`w-3.5 h-3.5 ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`} />
          <span className={`text-[11px] font-medium ${isDarkMode ? 'text-slate-300' : 'text-slate-600'}`}>
            {stats.totalCanvases} {stats.totalCanvases === 1 ? 'Campaign' : 'Campaigns'}
          </span>
        </div>
        <div className={`w-px h-3 ${isDarkMode ? 'bg-slate-800' : 'bg-slate-200'}`} />
        <div className="flex items-center gap-2">
          <span className={`text-[11px] font-medium ${isDarkMode ? 'text-slate-300' : 'text-slate-600'}`}>
            {stats.totalPosts} {stats.totalPosts === 1 ? 'Post' : 'Posts'}
          </span>
        </div>
      </div>
      <div className="flex items-center">
        <span className={`text-[11px] ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`}>
          Last activity: {getTimeAgo(stats.lastActivity)}
        </span>
      </div>
    </div>
  );
};

StatsBottomBarforCanvasWelcome.propTypes = {
  canvases: PropTypes.arrayOf(PropTypes.shape({
    posts: PropTypes.array,
    lastEdited: PropTypes.string
  })).isRequired,
  isDarkMode: PropTypes.bool.isRequired
};

export default StatsBottomBarforCanvasWelcome; 