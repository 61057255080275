import React, { useRef, useEffect, useCallback, useState } from 'react';
import { XMarkIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';
import CanvasPostContentEditor from './CanvasPostContentEditor';
import { useTheme } from '../context/ThemeContext';
import { motion } from 'framer-motion';
import { CollapseChatIcon } from './icons/ExpandCollapseIcons';

const FullScreenCanvasPostContentEditor = ({ 
  content: initialContent,
  onSave, 
  onClose,
  isOpen,
  title = 'Untitled Post'
}) => {
  const { isDarkMode } = useTheme();
  const editorRef = useRef(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [currentContent, setCurrentContent] = useState(initialContent);

  // Reset hasChanges when editor is opened with new content
  useEffect(() => {
    if (isOpen) {
      setCurrentContent(initialContent);
      setHasChanges(false);
    }
  }, [isOpen, initialContent]);

  // Handle content changes
  const handleContentChange = useCallback((newContent) => {
    setCurrentContent(newContent);
    setHasChanges(newContent !== initialContent);
  }, [initialContent]);

  // Handle keyboard shortcuts
  const handleKeyDown = useCallback((e) => {
    // Close on Escape
    if (e.key === 'Escape') {
      e.preventDefault();
      onClose();
    }
    
    // Save on Cmd/Ctrl + Enter
    if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
      e.preventDefault();
      const content = editorRef.current?.getContent();
      if (content) onSave(content);
    }
  }, [onClose, onSave]);

  useEffect(() => {
    if (isOpen) {
      // Focus the editor
      setTimeout(() => {
        editorRef.current?.focus();
      }, 100);
      
      // Prevent body scroll
      document.body.style.overflow = 'hidden';
      
      // Add keyboard event listener
      window.addEventListener('keydown', handleKeyDown);
    }

    // Cleanup
    return () => {
      document.body.style.overflow = 'unset';
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, handleKeyDown]);

  // Add button animation variants
  const buttonVariants = {
    initial: { 
      opacity: 0,
      y: -4
    },
    animate: { 
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.2,
        ease: 'easeOut'
      }
    },
    hover: {
      backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)',
      transition: {
        duration: 0.2
      }
    },
    tap: {
      scale: 0.98
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[100]">
      {/* Backdrop - using our new color scheme */}
      <div 
        className={`absolute inset-0 transition-colors duration-200 ${
          isDarkMode 
            ? 'bg-[#151515]' 
            : 'bg-[#f8fafc]'
        }`}
      />
      
      {/* Main container */}
      <div className="relative w-full h-full z-[101] flex flex-col animate-in fade-in duration-200">
        {/* Top bar - updated colors */}
        <div className={`
          absolute top-0 left-0 right-0 
          flex items-center justify-between 
          px-6 h-10
          pointer-events-auto
          z-[103]
          border-b
          ${isDarkMode 
            ? 'text-slate-200 border-[#333333] bg-[#1e1e1e]/90' 
            : 'text-gray-800 border-gray-200 bg-white/90'
          }
          backdrop-blur-sm
        `}>
          {/* Close button - moved to the left */}
          <motion.button
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
            className={`
              flex items-center gap-1.5
              px-2 py-1.5
              -ml-2 mr-4
              rounded-md text-[13px]
              font-medium
              transition-all duration-150
              cursor-pointer
              group
              ${isDarkMode 
                ? 'text-slate-400 hover:text-slate-200' 
                : 'text-gray-500 hover:text-gray-700'
              }
            `}
            variants={buttonVariants}
            initial="initial"
            animate="animate"
            whileHover="hover"
            whileTap="tap"
            aria-label="Back to canvas"
          >
            <ChevronLeftIcon 
              className={`
                w-4 h-4 
                transition-transform duration-150 
                group-hover:-translate-x-0.5
              `} 
            />
            <span className="relative top-[0.5px]">Back</span>
          </motion.button>

          {/* Title */}
          <div className="flex-1 truncate">
            <h1 className={`text-[13px] font-medium truncate ${
              isDarkMode ? 'text-slate-200/80' : 'text-gray-800/80'
            }`}>
              {title}
            </h1>
          </div>

          {/* Add keyboard hints before the save button */}
          <div className={`
            hidden md:flex items-center mr-6
            text-[11px] font-light
            ${isDarkMode ? 'text-slate-500' : 'text-gray-400'}
          `}>
            <span className="opacity-40">esc to close</span>
          </div>

          {/* Save button */}
          <motion.button
            onClick={(e) => {
              e.stopPropagation();
              if (hasChanges) {
                onSave(currentContent);
              }
            }}
            className={`
              px-3 py-1 rounded-md text-[13px]
              transition-colors duration-150
              ${hasChanges ? 'cursor-pointer' : 'cursor-not-allowed'}
              ${isDarkMode 
                ? hasChanges 
                  ? 'text-slate-400 hover:text-slate-200 hover:bg-[#333333]' 
                  : 'text-slate-600'
                : hasChanges
                  ? 'text-gray-500 hover:text-gray-800 hover:bg-gray-100'
                  : 'text-gray-300'
              }
            `}
            variants={buttonVariants}
            initial="initial"
            animate="animate"
            whileHover={hasChanges ? "hover" : undefined}
            whileTap={hasChanges ? "tap" : undefined}
            disabled={!hasChanges}
          >
            Save
          </motion.button>
        </div>

        {/* Editor container */}
        <div className="flex-1 overflow-auto px-4 pt-20 z-[102]">
          <div className={`
            max-w-[60ch] mx-auto
            ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}
          `}>
            <CanvasPostContentEditor
              ref={editorRef}
              content={initialContent}
              onSave={onSave}
              onCancel={onClose}
              onChange={handleContentChange}
              isDarkMode={isDarkMode}
              isEditing={true}
              hideSaveCancelButtons={true}
              className="prose-lg"
              style={{
                fontSize: '1.25rem',
                lineHeight: '1.6',
                fontFamily: '-apple-system, BlinkMacSystemFont, system-ui, sans-serif',
                letterSpacing: '-0.01em',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullScreenCanvasPostContentEditor; 