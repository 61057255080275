import React, { useState, useEffect } from 'react';
import { XMarkIcon, ArrowUpTrayIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../context/ThemeContext';

const UploadProgress = ({ progress, isUploading, onCancel, fileName, fileSize, onClose, uploadComplete }) => {
  const { isDarkMode } = useTheme();
  const [showComponent, setShowComponent] = useState(false);
  const [showCompleteState, setShowCompleteState] = useState(false);

  useEffect(() => {
    if (isUploading || progress > 0 || uploadComplete) {
      setShowComponent(true);
    }
  }, [isUploading, progress, uploadComplete]);

  useEffect(() => {
    let timeoutId;
    if (uploadComplete) {
      setShowComponent(true);
      timeoutId = setTimeout(() => {
        setShowCompleteState(true);
      }, 500);

      timeoutId = setTimeout(() => {
        handleClose();
      }, 60000);
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [uploadComplete]);

  const handleClose = () => {
    setShowComponent(false);
    setShowCompleteState(false);
    if (onClose) {
      onClose();
    }
  };

  if (!showComponent) return null;

  const bgColor = isDarkMode ? 'bg-[#121212]' : 'bg-white';
  const textColor = isDarkMode ? 'text-slate-200' : 'text-gray-800';
  const iconColor = isDarkMode ? 'text-slate-400' : 'text-gray-600';
  const progressBgColor = isDarkMode ? 'bg-[#1e1e1e]' : 'bg-gray-200';
  const progressBarColor = isDarkMode ? 'bg-blue-400' : 'bg-blue-500';

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const progressWidth = uploadComplete ? '100%' : `${progress}%`;
  const progressClass = !showCompleteState ? 'animate-pulse' : '';

  return (
    <div
      className={`fixed bottom-4 right-4 p-4 rounded-lg shadow-lg w-80 max-w-full mx-4 transition-all duration-300 ease-in-out ${bgColor} ${textColor} ${isDarkMode ? 'border border-[#333333]' : ''}`}
      role="alert"
      aria-live="polite"
    >
      <div className="flex justify-between items-center mb-3">
        <h2 className="text-sm font-semibold flex items-center">
          {showCompleteState ? (
            <CheckCircleIcon className={`w-5 h-5 mr-2 ${isDarkMode ? 'text-green-400' : 'text-green-500'}`} aria-hidden="true" />
          ) : (
            <ArrowUpTrayIcon className={`w-5 h-5 mr-2 ${iconColor}`} aria-hidden="true" />
          )}
          <span>{showCompleteState ? 'Upload Complete' : 'Uploading'}</span>
        </h2>
        <button
          onClick={handleClose}
          className={`transition-colors ${isDarkMode ? 'text-slate-400 hover:text-slate-200' : 'text-gray-500 hover:text-gray-700'}`}
          aria-label="Close notification"
        >
          <XMarkIcon className="w-5 h-5" aria-hidden="true" />
        </button>
      </div>
      <div className={`w-full h-2 rounded-full overflow-hidden ${progressBgColor}`}>
        <div
          className={`h-full ${progressBarColor} rounded-full transition-all duration-300 ease-in-out ${progressClass}`}
          style={{ width: progressWidth }}
          role="progressbar"
          aria-valuenow={Math.round(uploadComplete ? 100 : progress)}
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <div className="mt-2 text-sm">
        <p className="truncate">{fileName}</p>
        <p className={`${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
          {formatFileSize(fileSize)} • {showCompleteState ? 'Completed' : `${Math.round(progress)}%`}
        </p>
      </div>
      {showCompleteState && (
        <div className={`mt-2 flex items-center ${isDarkMode ? 'text-green-400' : 'text-green-500'} animate-fadeIn`}>
          <CheckCircleIcon className="w-5 h-5 mr-2" aria-hidden="true" />
          <span>Upload successful!</span>
        </div>
      )}
    </div>
  );
};

export default UploadProgress;
