import React, { useRef } from 'react';
import { EllipsisHorizontalIcon, TrashIcon, TagIcon } from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';
import { createPortal } from 'react-dom';

const NoteMenu = ({ isOpen, onToggle, onDelete, isDarkMode, anchorEl, onTagClick }) => {
  const menuRef = useRef(null);

  const handleToggle = (e) => {
    e.stopPropagation();
    onToggle(e.currentTarget);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete();
    onToggle(null);
  };

  const handleTagClick = (e) => {
    e.stopPropagation();
    onTagClick(e);
    onToggle(null);
  };

  const getMenuPosition = () => {
    if (!anchorEl) return {};
    const rect = anchorEl.getBoundingClientRect();
    return {
      top: `${rect.bottom + window.scrollY + 5}px`,
      left: `${rect.left + window.scrollX - 120}px`, // Increased offset for wider menu
    };
  };

  return (
    <>
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={handleToggle}
        className={`p-1.5 rounded-lg transition-colors duration-200 ${
          isDarkMode 
            ? 'hover:bg-gray-700/50 text-gray-400 hover:text-gray-300' 
            : 'hover:bg-gray-100 text-gray-500 hover:text-gray-700'
        }`}
        title="More options"
      >
        <EllipsisHorizontalIcon className="w-3.5 h-3.5" />
      </motion.button>

      {createPortal(
        <AnimatePresence>
          {isOpen && anchorEl && (
            <motion.div
              ref={menuRef}
              initial={{ opacity: 0, y: -5 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -5 }}
              transition={{ duration: 0.15 }}
              className={`fixed z-[100] w-32 rounded-lg border shadow-lg ${
                isDarkMode 
                  ? 'bg-gray-950 border-gray-800' 
                  : 'bg-white border-gray-200'
              }`}
              style={getMenuPosition()}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="py-1">
                {/* Tags Button */}
                <motion.button
                  whileHover={{ backgroundColor: isDarkMode ? 'rgba(55, 65, 81, 0.5)' : 'rgb(243 244 246)' }}
                  className={`flex items-center gap-2 w-full px-3 py-2 text-sm ${
                    isDarkMode 
                      ? 'text-gray-300 hover:text-gray-200' 
                      : 'text-gray-700 hover:text-gray-900'
                  }`}
                  onClick={handleTagClick}
                >
                  <TagIcon className="w-3.5 h-3.5" />
                  <span>Tags</span>
                </motion.button>

                {/* Delete Button */}
                <motion.button
                  whileHover={{ backgroundColor: isDarkMode ? 'rgb(239 68 68 / 0.2)' : 'rgb(239 68 68 / 0.1)' }}
                  className={`flex items-center gap-2 w-full px-3 py-2 text-sm ${
                    isDarkMode 
                      ? 'text-red-400 hover:text-red-300' 
                      : 'text-red-600 hover:text-red-500'
                  }`}
                  onClick={handleDelete}
                >
                  <TrashIcon className="w-3.5 h-3.5" />
                  <span>Delete</span>
                </motion.button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>,
        document.body
      )}
    </>
  );
};

NoteMenu.isClickInsideMenu = (element, menuRef) => {
  return menuRef?.current && menuRef.current.contains(element);
};

export default NoteMenu;
