import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Eye, EyeOff, AlertCircle, Info, CheckCircle, XCircle } from 'lucide-react';
import { motion } from 'framer-motion';
import { useTheme } from '../context/ThemeContext';
import logo from '../logo.svg';
import { getLinkedInAuthUrl, getState, saveState } from '../services/linkedinAuthService';
import { toast } from 'react-hot-toast';
import { SparklesIcon, BoltIcon, MicrophoneIcon, ChartBarIcon } from '@heroicons/react/24/outline';

const VisuallyHidden = ({ children }) => (
  <span className="sr-only">{children}</span>
);

const PasswordStrengthIndicator = ({ password }) => {
  const { isDarkMode } = useTheme();
  const requirements = [
    { regex: /.{8,}/, text: "At least 8 characters" },
    { regex: /[A-Z]/, text: "Uppercase letter" },
    { regex: /[a-z]/, text: "Lowercase letter" },
    { regex: /[0-9]/, text: "Number" },
    { regex: /[!@#$%^&*]/, text: "Special character" },
  ];

  if (!password) return null;

  return (
    <div className="mt-2 space-y-2">
      <div className={`text-xs font-medium ${isDarkMode ? 'text-slate-400' : 'text-slate-700'}`}>
        Password strength:
      </div>
      {requirements.map((req, index) => (
        <div key={index} className="flex items-center text-xs">
          {req.regex.test(password) ? (
            <CheckCircle className="h-4 w-4 text-green-500 mr-2" />
          ) : (
            <XCircle className="h-4 w-4 text-red-500 mr-2" />
          )}
          <span className={isDarkMode ? 'text-slate-400' : 'text-slate-600'}>
            {req.text}
          </span>
        </div>
      ))}
    </div>
  );
};

const Register = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const { register, loginWithLinkedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const location = useLocation();

  useEffect(() => {
    // Check if we're on the callback URL
    const isCallback = window.location.pathname === '/auth/linkedin/callback';
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    const error = params.get('error');
    const errorDescription = params.get('error_description');
    
    console.log('🔷 URL check:', {
      pathname: window.location.pathname,
      isCallback,
      hasCode: !!code,
      error,
      errorDescription
    });
    
    if (isCallback) {
      if (error) {
        console.error('❌ LinkedIn error:', error, errorDescription);
        setError(errorDescription || 'LinkedIn authentication failed');
        navigate('/register', { replace: true });
        return;
      }
      
      if (code) {
        console.log('🔷 Found LinkedIn code, initiating callback');
        handleLinkedInCallback(code);
      }
    }
  }, [location]);

  const handleLinkedInCallback = async (code) => {
    try {
      console.log('🔷 Processing LinkedIn callback');
      const returnedState = new URLSearchParams(location.search).get('state');
      const savedStateData = getState();
      
      if (!savedStateData) {
        console.error('❌ No saved state data found');
        setError('LinkedIn authentication session expired. Please try again.');
        navigate('/register', { replace: true });
        return;
      }
      
      console.log('🔷 State validation:', {
        returnedState,
        savedState: savedStateData.state,
        hasCodeVerifier: !!savedStateData.codeVerifier,
        stateAge: Date.now() - (savedStateData.timestamp || 0)
      });

      if (!savedStateData.state || savedStateData.state !== returnedState) {
        console.error('❌ State mismatch:', {
          savedState: savedStateData.state,
          returnedState
        });
        setError('LinkedIn authentication failed. Please try again.');
        navigate('/register', { replace: true });
        return;
      }

      try {
        const success = await loginWithLinkedIn(code, returnedState);
        if (success) {
          // Clear state data
          localStorage.removeItem('linkedin_auth_state');
          
          // Navigate directly to success page without showing register page
          navigate('/auth/linkedin/success', { 
            replace: true,
            state: { isSignup: true }
          });
          return; // Add return to prevent further execution
        } else {
          throw new Error('Authentication failed');
        }
      } catch (error) {
        console.error('❌ LinkedIn auth error:', error);
        
        if (error.response?.status === 400) {
          if (error.response.data?.error === 'User already exists') {
            setError('You already have an account. Please use the login page instead.');
            navigate('/login', { replace: true });
            return; // Add return to prevent further execution
          } else {
            setError(error.response.data?.error || 'Authentication failed. Please try again.');
          }
        } else {
          setError(error.message || 'Authentication failed. Please try again.');
        }
        navigate('/register', { replace: true });
      }
    } catch (error) {
      console.error('❌ LinkedIn callback error:', error);
      setError('Authentication failed. Please try again.');
      navigate('/register', { replace: true });
    }
  };

  const handleLinkedInSignup = async () => {
    try {
      console.log('🔷 Initiating LinkedIn signup flow');
      
      // Clear any existing state data first
      localStorage.removeItem('linkedin_auth_state');
      
      // Generate state with more entropy
      const state = Array.from(crypto.getRandomValues(new Uint8Array(16)))
        .map(b => b.toString(16).padStart(2, '0'))
        .join('');
      
      console.log('🔷 Generated state:', state);
      
      // Get LinkedIn auth URL
      const linkedInUrl = await getLinkedInAuthUrl(state);
      console.log('🔷 Redirecting to LinkedIn:', linkedInUrl);
      
      // Final state verification before redirect
      const finalCheck = getState();
      if (!finalCheck || finalCheck.state !== state) {
        console.error('❌ Final state verification failed');
        setError('Failed to initialize LinkedIn authentication. Please try again.');
        return;
      }
      
      // Redirect to LinkedIn
      window.location.href = linkedInUrl;
    } catch (error) {
      console.error('❌ Error initiating LinkedIn signup:', error);
      setError('Failed to connect to LinkedIn: ' + error.message);
    }
  };

  const validatePassword = (password) => {
    if (password.length < 8) return "Password must be at least 8 characters long";
    if (!/[A-Z]/.test(password)) return "Password must contain at least one uppercase letter";
    if (!/[a-z]/.test(password)) return "Password must contain at least one lowercase letter";
    if (!/[0-9]/.test(password)) return "Password must contain at least one number";
    if (!/[!@#$%^&*]/.test(password)) return "Password must contain at least one special character";
    return null;
  };

  const handleNextStep = (e) => {
    e.preventDefault();
    if (email) {
      setCurrentStep(2);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    // Validate password
    const passwordError = validatePassword(password);
    if (passwordError) {
      setError(passwordError);
      setIsLoading(false);
      return;
    }

    try {
      const result = await register(firstName, lastName, email, password);
      
      if (result.success) {
        toast.success(result.message, {
          duration: 5000,
          position: 'top-center'
        });
        
        navigate('/auth/verify-email', { 
          state: { 
            email,
            message: result.message 
          }
        });
      } else {
        console.error('Registration failed without error:', result);
        setError('Registration failed. Please try again.');
      }
    } catch (err) {
      console.error('Registration error:', err);
      toast.error(err.message || 'Registration failed. Please try again.', {
        duration: 5000,
        position: 'top-center'
      });
      setError(err.message || 'An unexpected error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={`min-h-screen bg-fixed overflow-x-hidden ${
      isDarkMode 
        ? 'bg-gradient-to-br from-[#121212] via-[#1a1a1a] to-[#121212]' 
        : 'bg-gradient-to-br from-slate-50 via-white to-slate-50'
    }`}>
      <div className="max-w-md mx-auto px-4 sm:px-6 min-h-screen flex flex-col items-center justify-center py-8">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className={`w-full p-6 md:p-8 rounded-2xl shadow-xl ${
            isDarkMode 
              ? 'bg-[#1E1E1E] border border-gray-800' 
              : 'bg-white border border-slate-200'
          }`}
        >
          <div className="text-center mb-6">
            <Link to="/" className="inline-block mb-6">
              <img src={logo} alt="BrandSpin Logo" className="h-8 mx-auto" />
            </Link>
            <h2 className={`text-2xl font-bold mb-2 ${
              isDarkMode ? 'text-white' : 'text-slate-900'
            }`}>
              Welcome to Ammmplify
            </h2>
            <p className={`text-sm ${
              isDarkMode ? 'text-slate-400' : 'text-slate-600'
            }`}>
              Join professionals amplifying their LinkedIn presence
            </p>
          </div>

          {error && (
            <motion.div 
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              className="mb-4 p-3 rounded-lg bg-red-100 border border-red-200 text-red-600 text-sm flex items-center gap-2"
            >
              <AlertCircle className="h-4 w-4 flex-shrink-0" />
              {error}
            </motion.div>
          )}

          <button
            type="button"
            onClick={handleLinkedInSignup}
            className={`w-full flex items-center justify-center gap-2 py-2.5 px-4 rounded-lg font-medium mb-6
              ${isDarkMode 
                ? 'bg-[#2A2A2A] text-white hover:bg-[#333333] border border-gray-700' 
                : 'bg-white text-slate-700 hover:bg-slate-50 border border-slate-200'
              } transition-colors`}
          >
            <img
              className="h-4 w-4 sm:h-5 sm:w-5"
              src="/linkedin-icon.svg"
              alt="LinkedIn logo"
            />
            Continue with LinkedIn
          </button>

          <div className="relative my-6">
            <div className={`absolute inset-0 flex items-center ${
              isDarkMode ? 'text-gray-700' : 'text-gray-300'
            }`}>
              <div className="w-full border-t"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className={`px-2 ${
                isDarkMode ? 'bg-[#1E1E1E] text-gray-400' : 'bg-white text-gray-500'
              }`}>
                Or continue with email
              </span>
            </div>
          </div>

          <form onSubmit={currentStep === 1 ? handleNextStep : handleSubmit} className="space-y-5">
            {currentStep === 1 ? (
              // Step 1: Email Input
              <div>
                <label className="sr-only" htmlFor="email">
                  Work email
                </label>
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Work email"
                  required
                  className={`w-full px-3 py-3 rounded-lg border focus:ring-2 focus:ring-offset-2 transition-colors ${
                    isDarkMode 
                      ? 'bg-[#2A2A2A] border-gray-700 text-white placeholder-gray-500 focus:ring-blue-500/40 focus:border-blue-500' 
                      : 'bg-white border-slate-200 text-slate-900 placeholder-slate-400 focus:ring-blue-500/40 focus:border-blue-500'
                  }`}
                />
              </div>
            ) : (
              // Step 2: Password and Name Fields
              <>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="sr-only" htmlFor="firstName">
                      First name
                    </label>
                    <input
                      id="firstName"
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="First name"
                      required
                      className={`w-full px-3 py-3 rounded-lg border focus:ring-2 focus:ring-offset-2 transition-colors ${
                        isDarkMode 
                          ? 'bg-[#2A2A2A] border-gray-700 text-white placeholder-gray-500 focus:ring-blue-500/40 focus:border-blue-500' 
                          : 'bg-white border-slate-200 text-slate-900 placeholder-slate-400 focus:ring-blue-500/40 focus:border-blue-500'
                      }`}
                    />
                  </div>
                  <div>
                    <label className="sr-only" htmlFor="lastName">
                      Last name
                    </label>
                    <input
                      id="lastName"
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Last name"
                      required
                      className={`w-full px-3 py-3 rounded-lg border focus:ring-2 focus:ring-offset-2 transition-colors ${
                        isDarkMode 
                          ? 'bg-[#2A2A2A] border-gray-700 text-white placeholder-gray-500 focus:ring-blue-500/40 focus:border-blue-500' 
                          : 'bg-white border-slate-200 text-slate-900 placeholder-slate-400 focus:ring-blue-500/40 focus:border-blue-500'
                      }`}
                    />
                  </div>
                </div>

                <div>
                  <label className="sr-only" htmlFor="password">
                    Password
                  </label>
                  <div className="relative">
                    <input
                      id="password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password"
                      required
                      className={`w-full px-3 py-3 rounded-lg border focus:ring-2 focus:ring-offset-2 transition-colors ${
                        isDarkMode 
                          ? 'bg-[#2A2A2A] border-gray-700 text-white placeholder-gray-500 focus:ring-blue-500/40 focus:border-blue-500' 
                          : 'bg-white border-slate-200 text-slate-900 placeholder-slate-400 focus:ring-blue-500/40 focus:border-blue-500'
                      }`}
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className={`absolute right-3 top-1/2 -translate-y-1/2 ${
                        isDarkMode ? 'text-slate-400' : 'text-slate-500'
                      }`}
                    >
                      {showPassword ? (
                        <EyeOff className="h-4 w-4" />
                      ) : (
                        <Eye className="h-4 w-4" />
                      )}
                    </button>
                  </div>
                  <PasswordStrengthIndicator password={password} />
                </div>
              </>
            )}

            <div className="flex items-center gap-3">
              {currentStep === 2 && (
                <button
                  type="button"
                  onClick={() => setCurrentStep(1)}
                  className={`flex-1 py-3 px-4 rounded-lg font-medium text-sm border
                    ${isDarkMode 
                      ? 'border-gray-700 text-slate-300 hover:bg-[#2A2A2A]' 
                      : 'border-slate-200 text-slate-700 hover:bg-slate-50'
                    } transition-colors`}
                >
                  Back
                </button>
              )}
              <button
                type="submit"
                disabled={isLoading}
                className={`flex-1 py-3 px-4 rounded-lg font-semibold text-white text-sm
                  ${isLoading 
                    ? 'bg-blue-400 cursor-not-allowed' 
                    : 'bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700'
                  } transition-all duration-200 transform hover:scale-[1.02] active:scale-[0.98] relative overflow-hidden`}
              >
                {isLoading ? (
                  <span className="flex items-center justify-center gap-2">
                    <svg className="animate-spin h-4 w-4" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                    </svg>
                    Creating account...
                  </span>
                ) : currentStep === 1 ? (
                  'Continue'
                ) : (
                  'Create account'
                )}
              </button>
            </div>

            <p className={`mt-4 text-xs text-center ${
              isDarkMode ? 'text-slate-400' : 'text-slate-500'
            }`}>
              By signing up, you agree to our{' '}
              <a href="#" className="text-blue-500 hover:text-blue-600">Terms</a>
              {' '}and{' '}
              <a href="#" className="text-blue-500 hover:text-blue-600">Privacy Policy</a>
            </p>
          </form>

          <p className={`mt-6 text-sm text-center ${
            isDarkMode ? 'text-slate-400' : 'text-slate-600'
          }`}>
            Already have an account?{' '}
            <Link to="/login" className="text-blue-500 hover:text-blue-600 font-medium">
              Sign in
            </Link>
          </p>
        </motion.div>
      </div>
    </div>
  );
};

const FeatureCard = ({ icon, title, description, isDarkMode }) => (
  <div className={`p-6 rounded-xl ${
    isDarkMode ? 'bg-[#1E1E1E]' : 'bg-white'
  } border ${
    isDarkMode ? 'border-gray-800' : 'border-slate-200'
  }`}>
    <div className={`w-10 h-10 rounded-lg flex items-center justify-center mb-4 ${
      isDarkMode ? 'bg-blue-500/20 text-blue-400' : 'bg-blue-100 text-blue-600'
    }`}>
      {icon}
    </div>
    <h3 className={`text-lg font-semibold mb-2 ${
      isDarkMode ? 'text-white' : 'text-slate-900'
    }`}>{title}</h3>
    <p className={`text-sm ${
      isDarkMode ? 'text-slate-400' : 'text-slate-600'
    }`}>{description}</p>
  </div>
);

export default Register;
