import React from 'react';
import { useTheme } from '../../context/ThemeContext';

const CardSkeleton = () => {
  const { isDarkMode } = useTheme();
  
  return (
    <div className={`${isDarkMode ? 'bg-slate-800/50' : 'bg-white/50'} 
                    rounded-lg border ${isDarkMode ? 'border-slate-700/50' : 'border-gray-200/50'} 
                    p-3 relative overflow-hidden`}>
      <div className="flex items-center gap-3">
        {/* Avatar skeleton */}
        <div className={`flex-shrink-0 w-10 h-10 rounded-full
                      ${isDarkMode ? 'bg-slate-700/30' : 'bg-gray-100/30'}`} />

        {/* Content skeleton */}
        <div className="flex-1">
          {/* Title skeleton */}
          <div className={`h-4 w-24 rounded-md mb-2
                        ${isDarkMode ? 'bg-slate-700/30' : 'bg-gray-200/30'}`} />
          
          {/* Subtitle skeleton */}
          <div className={`h-3 w-48 rounded-md
                        ${isDarkMode ? 'bg-slate-700/30' : 'bg-gray-200/30'}`} />
        </div>

        {/* Menu button skeleton */}
        <div className={`w-8 h-8 rounded-full
                      ${isDarkMode ? 'bg-slate-700/30' : 'bg-gray-200/30'}`} />
      </div>

      {/* Shimmer effect overlay */}
      <div className="absolute inset-0 -translate-x-full animate-[shimmer_2s_infinite]
                    bg-gradient-to-r from-transparent via-white/5 to-transparent" />
    </div>
  );
};

export const GridSkeleton = ({ count = 4 }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pt-4">
      {Array(count).fill(0).map((_, index) => (
        <CardSkeleton key={index} />
      ))}
    </div>
  );
};

// Add this to your tailwind.config.js if not already present:
// keyframes: {
//   shimmer: {
//     '100%': { transform: 'translateX(100%)' }
//   }
// }

export default CardSkeleton; 