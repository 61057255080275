import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { API_URL, default as api } from '../api';
import LoadingOverlay from '../components/LoadingOverlay';
import { clearAppStorage } from '../utils/storageUtils';
import { connectSocket, disconnectSocket } from '../socket';
import { getState } from '../services/linkedinAuthService';
import { signUpWithEmail } from '../services/supabaseAuth';

// Add the LinkedIn state key constant
const LINKEDIN_STATE_KEY = 'linkedin_auth_state';

// Update this section
const API_URLS = process.env.REACT_APP_API_URLS ? 
  process.env.REACT_APP_API_URLS.split(',').map(url => url.trim()) : 
  [
    'https://api.ammmplify.com',  // Primary API domain
    'https://ghost-app-backend.vercel.app',  // Fallback domain
    'http://localhost:3001'  // Local development
  ];

// Log configuration
console.log('Auth Context Configuration:', {
  hostname: window.location.hostname,
  apiUrl: API_URL
});

export const AuthContext = createContext();

// Add this custom hook
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const refreshAccessToken = async (refreshToken) => {
  if (!refreshToken) {
    console.error('No refresh token provided');
    return null;
  }

  try {
    console.log('Attempting to refresh token at:', `${API_URL}/api/auth/refresh-token`);
    
    const response = await api.post('/api/auth/refresh-token', { refreshToken });
    
    if (!response.data || !response.data.accessToken) {
      console.error('Invalid response format from refresh token endpoint:', response.data);
      throw new Error('Invalid response format');
    }
    
    const { accessToken, refreshToken: newRefreshToken } = response.data;
    
    // Update both tokens in localStorage
    localStorage.setItem('accessToken', accessToken);
    if (newRefreshToken) {
      localStorage.setItem('refreshToken', newRefreshToken);
    }
    
    // Update axios default header
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    
    return accessToken;
  } catch (error) {
    console.error('Token refresh failed:', {
      status: error.response?.status,
      statusText: error.response?.statusText,
      data: error.response?.data,
      message: error.message
    });

    if (error.response?.status === 404) {
      console.error('Refresh token endpoint not found. Please check API_URL configuration:', API_URL);
    }

    // Clear tokens only on specific errors
    if (
      error.response?.status === 401 || // Unauthorized
      error.response?.status === 403 || // Forbidden
      error.response?.data?.message === 'Invalid refresh token' ||
      error.response?.status === 404    // Not Found
    ) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      delete axios.defaults.headers.common['Authorization'];
    }

    return null;
  }
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  useEffect(() => {
    const loadUser = async () => {
      const accessToken = localStorage.getItem('accessToken');
      const refreshToken = localStorage.getItem('refreshToken');
      
      if (accessToken && refreshToken) {
        try {
          axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
          const res = await axios.get(`${API_URL}/api/auth/user`);
          setUser(res.data);
        } catch (err) {
          if (err.response?.data?.tokenExpired) {
            const newAccessToken = await refreshAccessToken(refreshToken);
            if (newAccessToken) {
              // Retry the original request with new token
              axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
              const res = await axios.get(`${API_URL}/api/auth/user`);
              setUser(res.data);
            } else {
              setUser(null);
            }
          } else {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            delete axios.defaults.headers.common['Authorization'];
            setUser(null);
          }
        }
      } else {
        setUser(null);
      }
      setLoading(false);
      setInitialLoadComplete(true);
    };
    loadUser();
  }, []);

  const login = async (email, password) => {
    try {
      if (!API_URL) {
        throw new Error('API URL is not configured');
      }

      console.log('Login attempt:', {
        email,
        apiUrl: API_URL,
        endpoint: `${API_URL}/api/auth/login`
      });

      const res = await axios.post(
        `${API_URL}/api/auth/login`, 
        { email, password },
        { 
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      console.log('Login response:', res.data);

      if (res.data.accessToken && res.data.refreshToken && res.data.user) {
        localStorage.setItem('accessToken', res.data.accessToken);
        localStorage.setItem('refreshToken', res.data.refreshToken);
        axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.accessToken}`;
        
        // Fetch full user profile
        const userProfileRes = await axios.get(`${API_URL}/api/auth/user`, {
          headers: { Authorization: `Bearer ${res.data.accessToken}` }
        });
        
        setUser(userProfileRes.data);
        connectSocket(res.data.accessToken);
        return true;
      }

      console.log('Login failed: Invalid response format');
      return false;
    } catch (err) {
      console.error('Login error:', {
        message: err.message,
        response: err.response?.data,
        status: err.response?.status,
        apiUrl: API_URL
      });
      throw err;
    }
  };

  const logout = async () => {
    try {
      console.log('Logging out user');
      
      // Remove auth tokens
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      
      // Clear all app data
      clearAppStorage();
      
      // Clear auth header
      delete axios.defaults.headers.common['Authorization'];
      
      // Clear user state
      setUser(null);
      
      // Disconnect socket
      disconnectSocket();
      
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  // Update the register function
  const register = async (firstName, lastName, email, password) => {
    try {
      console.log('Starting registration process for:', email);
      
      // First, register with Supabase
      const supabaseResponse = await signUpWithEmail(email, password, firstName, lastName);
      console.log('Supabase registration response:', supabaseResponse);
      
      if (!supabaseResponse || !supabaseResponse.supabaseUser) {
        throw new Error('Failed to create Supabase user');
      }

      // Then create user in MongoDB
      const mongoResponse = await axios.post(`${API_URL}/api/auth/register`, { 
        firstName, 
        lastName, 
        email, 
        password,
        supabaseUid: supabaseResponse.supabaseUser.id
      });
      
      console.log('MongoDB registration response:', mongoResponse.data);

      // Return success regardless of MongoDB response structure
      // since we know the user was created in both systems
      return {
        success: true,
        message: supabaseResponse.message || 'Registration successful! Please check your email to verify your account.'
      };
      
    } catch (err) {
      console.error('Registration error details:', {
        error: err,
        response: err.response?.data,
        message: err.message
      });
      
      // Handle specific error cases
      if (err.response?.data?.errors?.[0]?.msg === 'User already exists') {
        throw new Error('An account with this email already exists.');
      }
      
      // If we got a Supabase success but MongoDB error, still proceed
      if (err.message === 'Failed to create user record in database' && 
          err.supabaseSuccess) {
        return {
          success: true,
          message: 'Registration successful! Please check your email to verify your account.'
        };
      }
      
      throw new Error(err.response?.data?.message || err.message || 'Registration failed. Please try again.');
    }
  };

  const refreshUser = async () => {
    try {
      console.log('Refreshing user information...');
      const response = await api.get('/api/user/profile');
      console.log('Refreshed user data:', response.data);
      setUser(response.data);
      console.log('User state updated after refresh');
    } catch (error) {
      console.error('Error refreshing user information:', error);
      if (error.response) {
        console.error('Server responded with:', error.response.data);
      }
    }
  };

  const refreshTokenAndUpdateUser = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
      try {
        const response = await axios.post(`${API_URL}/api/auth/refresh-token`, { refreshToken });
        const { accessToken, user: updatedUser } = response.data;
        localStorage.setItem('accessToken', accessToken);
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        setUser(updatedUser);
        return accessToken;
      } catch (error) {
        console.error('Error refreshing token:', error);
        logout();
        return null;
      }
    }
    return null;
  };

  const loginWithLinkedIn = async (code) => {
    try {
      console.log('🔵 Starting LinkedIn auth process with code:', code.substring(0, 10) + '...');
      const stateData = getState();
      
      if (!stateData) {
        console.error('❌ No state data found');
        throw new Error('Authentication state not found. Please try again.');
      }
      
      console.log('🔵 Making request to:', `${API_URL}/api/auth/linkedin/callback`);
      const response = await axios.post(`${API_URL}/api/auth/linkedin/callback`, { 
        code,
        codeVerifier: stateData.codeVerifier,
        isSignup: window.location.pathname === '/register'
      });
      
      console.log('🔵 LinkedIn auth response:', response.data);
      if (!response.data.accessToken || !response.data.refreshToken || !response.data.user) {
        console.error('❌ Invalid response data:', response.data);
        throw new Error('Invalid response from server. Please try again.');
      }

      localStorage.setItem('accessToken', response.data.accessToken);
      localStorage.setItem('refreshToken', response.data.refreshToken);
      localStorage.setItem('user', JSON.stringify(response.data.user));

      axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.accessToken}`;
      api.defaults.headers.common['Authorization'] = `Bearer ${response.data.accessToken}`;

      setUser(response.data.user);
      console.log('🔵 User state updated:', response.data.user);

      connectSocket(response.data.accessToken);
      
      // Use the constant here
      sessionStorage.removeItem(LINKEDIN_STATE_KEY);
      
      return true;
    } catch (error) {
      console.error('❌ LinkedIn auth error:', error);
      console.error('❌ Error response:', error.response?.data);
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('user');
      // Use the constant here
      sessionStorage.removeItem(LINKEDIN_STATE_KEY);
      setUser(null);
      
      throw error;
    }
  };

  if (loading) {
    return <LoadingOverlay />;
  }

  return (
    <AuthContext.Provider value={{ 
      user, 
      setUser, 
      login, 
      logout, 
      register, 
      loading, 
      refreshUser, 
      initialLoadComplete, 
      refreshTokenAndUpdateUser,
      loginWithLinkedIn 
    }}>
      {children}
    </AuthContext.Provider>
  );
};
