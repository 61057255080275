import React from 'react';
import { CalendarIcon } from '@heroicons/react/24/outline';

const ScheduleLinkedInPosts = ({ isDarkMode }) => {
  return (
    <div className="flex flex-col items-center justify-center h-full text-center p-4">
      <CalendarIcon className={`h-16 w-16 ${isDarkMode ? 'text-slate-600' : 'text-gray-400'} mb-4`} />
      <h3 className={`text-lg font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-900'} mb-2`}>LinkedIn Post Scheduler</h3>
      <p className={`text-sm ${isDarkMode ? 'text-slate-400' : 'text-gray-500'} max-w-sm mb-4`}>
        We're working hard to bring you an amazing LinkedIn post scheduling feature. Stay tuned for updates!
      </p>
      <div className={`px-3 py-1 rounded-full text-sm font-semibold ${
        isDarkMode ? 'bg-blue-900 text-blue-200' : 'bg-blue-100 text-blue-800'
      }`}>
        Coming soon...
      </div>
    </div>
  );
};

export default ScheduleLinkedInPosts;
