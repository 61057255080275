import React, { useState, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '../../context/ThemeContext';
import '../scrollbar.css';
import NoteMenu from './NoteMenu';
import DOMPurify from 'dompurify';
import IdeasTagMenu from './IdeasTagMenu';
import { TagIcon, EllipsisVerticalIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import { motion, AnimatePresence } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { useRecentActivities } from '../../context/RecentActivitiesContext';
import { ExpandIcon } from '../icons/ExpandCollapseIcon';

const countWords = (text) => {
  return text.trim().split(/\s+/).filter(word => word.length > 0).length;
};

const sanitizeHTML = (html) => {
  // First replace \n with <br> tags
  const withLineBreaks = html.replace(/\n/g, '<br>');
  return DOMPurify.sanitize(withLineBreaks, {
    ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a', 'p', 'br'],
    ALLOWED_ATTR: ['href']
  });
};

const extractTextContent = (content) => {
  if (typeof content === 'string') {
    try {
      const parsedContent = JSON.parse(content);
      if (parsedContent && parsedContent.blocks) {
        return parsedContent.blocks
          .map(block => block.data && block.data.text ? block.data.text : '')
          .join('\n');
      }
    } catch (e) {
      // If it's not JSON, return the content as is
      return content;
    }
  }
  return content;
};

const truncateContent = (content) => {
  // Split into lines and take only first 2 lines
  const lines = content.split('\n').slice(0, 2);
  let truncated = lines.join('\n');
  
  // If there were more lines, add ellipsis
  if (content.split('\n').length > 2) {
    truncated += '...';
  }
  
  return truncated;
};

const StickyNote = ({ id, title, content, color, darkModeColor, onUpdate, onDelete, onFocus, tags = [], onAddTag, onRemoveTag, allTags }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isTagMenuOpen, setIsTagMenuOpen] = useState(false);
  const { isDarkMode } = useTheme();
  const tagMenuRef = useRef(null);
  const [tagMenuPosition, setTagMenuPosition] = useState({ top: 0, left: 0 });
  const noteRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [tagButtonElement, setTagButtonElement] = useState(null);
  const [menuButtonElement, setMenuButtonElement] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: 640 });
  const navigate = useNavigate();
  const { refreshActivities } = useRecentActivities();

  const titleColor = isDarkMode ? 'text-gray-100' : 'text-gray-900';
  const contentColor = isDarkMode ? 'text-gray-300' : 'text-gray-600';
  const placeholderColor = isDarkMode ? 'placeholder-gray-400' : 'placeholder-gray-500';

  const noteBackgroundColor = useMemo(() => isDarkMode ? darkModeColor : color, [isDarkMode, darkModeColor, color]);
  const noteBorderColor = isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)';
  const dividerColor = isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)';

  const handleNoteClick = (e) => {
    if (!isTagMenuOpen && !isMenuOpen) {
      navigate(`/ideas/${id}`);
    }
  };

  const extractedContent = useMemo(() => extractTextContent(content), [content]);
  const sanitizedContent = useMemo(() => sanitizeHTML(extractedContent), [extractedContent]);

  const handleTagMenuToggle = (e) => {
    e.stopPropagation();
    if (isTagMenuOpen) {
      setIsTagMenuOpen(false);
      setTagButtonElement(null);
    } else {
      setIsTagMenuOpen(true);
      setTagButtonElement(e.currentTarget);
    }
  };

  const handleAddTag = (newTag) => {
    onAddTag(id, newTag);
    setIsTagMenuOpen(false);
  };

  const handleRemoveTag = (tagToRemove) => {
    onRemoveTag(id, tagToRemove);
  };

  const handleMenuToggle = (buttonElement) => {
    if (buttonElement) {
      setMenuButtonElement(buttonElement);
      setIsMenuOpen(true);
    } else {
      setMenuButtonElement(null);
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Handle tag menu clicks
      if (tagButtonElement && 
          !tagButtonElement.contains(event.target) && 
          !IdeasTagMenu.isClickInsideMenu(event.target, tagMenuRef)) {
        setIsTagMenuOpen(false);
        setTagButtonElement(null);
      }
      // Handle note menu clicks
      if (menuButtonElement && 
          !menuButtonElement.contains(event.target) && 
          !NoteMenu.isClickInsideMenu(event.target, noteRef)) {
        setIsMenuOpen(false);
        setMenuButtonElement(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [tagButtonElement, menuButtonElement]);

  const formattedDate = format(new Date(), 'MMM dd'); // Add date formatting
  const formattedTime = format(new Date(), 'h:mm a');

  const handleUpdate = async (updates) => {
    try {
      await onUpdate(id, updates);
      await refreshActivities();
    } catch (error) {
      console.error('Error updating note:', error);
    }
  };

  return (
    <motion.div 
      layout="position"
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.95 }}
      whileHover={{ y: -2 }}
      transition={{
        layout: { type: "spring", bounce: 0, duration: 0.3 },
        opacity: { duration: 0.2 }
      }}
      className={`flex items-start h-full ${isDarkMode ? 'text-slate-200' : 'text-gray-800'}`}
    >
      <div className={`w-full h-full flex flex-col ${
        isDarkMode 
          ? 'bg-[#151515] border-[#333333]' 
          : 'bg-white border-gray-200'
        } rounded-lg p-3 relative group border`}
      >
        {/* Date and time header with action buttons */}
        <div className="flex items-center justify-between mb-2 flex-shrink-0">
          {/* Left side - Date and time */}
          <div className="flex items-center gap-2">
            <span className={`
              text-xs font-medium tracking-wide
              ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}
            `}>
              {formattedDate}
            </span>
            <span className={`
              text-[11px]
              ${isDarkMode ? 'text-gray-500' : 'text-gray-400'}
            `}>
              •
            </span>
            <span className={`
              text-[11px]
              ${isDarkMode ? 'text-gray-500' : 'text-gray-400'}
            `}>
              {formattedTime}
            </span>
          </div>

          {/* Right side - Action buttons */}
          <div className={`
            flex items-center gap-1.5
            ${isMobile ? '' : 'opacity-0 group-hover:opacity-100 transition-opacity duration-200'}
          `}>
            {/* Expand button */}
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate(`/ideas/${id}`);
              }}
              className={`
                p-1.5 rounded-lg transition-colors duration-200
                ${isDarkMode
                  ? 'text-gray-400 hover:bg-gray-700/50 hover:text-gray-300'
                  : 'text-gray-500 hover:bg-gray-100 hover:text-gray-700'}
              `}
              aria-label="Expand note"
            >
              <ExpandIcon className="w-3.5 h-3.5" />
            </button>

            {/* Menu button */}
            <NoteMenu
              isOpen={isMenuOpen}
              onToggle={handleMenuToggle}
              onDelete={() => onDelete(id)}
              isDarkMode={isDarkMode}
              anchorEl={menuButtonElement}
              onTagClick={handleTagMenuToggle}
            />
          </div>
        </div>

        {/* Rest of the note content */}
        <div onClick={handleNoteClick} className="cursor-pointer flex-1 overflow-hidden">
          <h3 className={`text-sm font-semibold ${titleColor} truncate mb-1.5`}>
            {title || 'Untitled'}
          </h3>
          <div className="overflow-hidden max-h-full">
            <div 
              className={`text-sm ${contentColor} line-clamp-6 whitespace-pre-line`}
              dangerouslySetInnerHTML={{ __html: sanitizedContent }} 
            />
          </div>
        </div>

        {/* Tags section - Updated with just spacing, no border */}
        <div className="mt-3">
          <div className="flex-1 min-w-0">
            <div className="flex items-center gap-2 overflow-x-auto scrollbar-hide">
              {tags.length === 0 ? (
                <span
                  className={`
                    inline-flex items-center px-2 py-1 rounded-lg text-xs
                    ${isDarkMode
                      ? 'bg-blue-600/20 text-blue-300 border border-blue-500/30'
                      : 'bg-blue-100 text-blue-600 border border-blue-200'}
                  `}
                >
                  All
                </span>
              ) : (
                tags.map((tag) => (
                  <span
                    key={tag}
                    className={`
                      inline-flex items-center px-2 py-1 rounded-lg text-xs
                      ${isDarkMode
                        ? 'bg-[#1a1a1a] text-slate-200'
                        : 'bg-gray-100 text-gray-600'}
                    `}
                  >
                    {tag}
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleRemoveTag(tag);
                      }}
                      className={`
                        ml-1.5 p-0.5 rounded-md transition-colors duration-200
                        ${isDarkMode
                          ? 'hover:bg-gray-600 text-gray-400'
                          : 'hover:bg-gray-200 text-gray-500'}
                      `}
                    >
                      <XMarkIcon className="w-3 h-3" />
                    </button>
                  </span>
                ))
              )}
            </div>
          </div>
        </div>

        {/* Tag menu portal */}
        <AnimatePresence>
          {isTagMenuOpen && (
            <IdeasTagMenu
              ref={tagMenuRef}
              isDarkMode={isDarkMode}
              tags={tags}
              allTags={allTags}
              onAddTag={handleAddTag}
              onRemoveTag={handleRemoveTag}
              onClose={() => {
                setIsTagMenuOpen(false);
                setTagButtonElement(null);
              }}
              anchorEl={tagButtonElement}
            />
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

StickyNote.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  darkModeColor: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  onAddTag: PropTypes.func.isRequired,
  onRemoveTag: PropTypes.func.isRequired,
  allTags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

StickyNote.defaultProps = {
  tags: [],
};

export default StickyNote;
