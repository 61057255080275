import React, { useEffect } from 'react';
import { DocumentTextIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';

const PreviewPastedText = ({ content, name, size, lines, isDarkMode, onRemove }) => {
  console.log('PreviewPastedText rendered with:', { content, name, size, lines }); // Debug log

  useEffect(() => {
    console.log('PreviewPastedText mounted/updated with content:', content);
  }, [content]);

  const formatSize = (bytes) => {
    const kb = bytes / 1024;
    return `${kb.toFixed(1)} KB`;
  };

  const handleClose = (e) => {
    e.stopPropagation(); // Prevent event bubbling
    onRemove(); // Just close the preview without clearing the text
  };

  return (
    <div className={`
      w-full rounded-lg overflow-hidden shadow-xl
      ${isDarkMode ? 'bg-slate-800 border border-slate-700' : 'bg-white border border-gray-200'}
    `}>
      <div className={`
        h-10 flex items-center justify-between px-3 border-b
        ${isDarkMode ? 'border-slate-700 bg-slate-900' : 'border-gray-200 bg-gray-50'}
      `}>
        <div className="flex items-center gap-2">
          <DocumentTextIcon className={`h-4 w-4 ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}`} />
          <span className={`text-sm font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-700'}`}>
            {name || 'Pasted text'}
          </span>
          <span className={`text-xs ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
            • {formatSize(size)} • {lines} {lines === 1 ? 'line' : 'lines'}
          </span>
        </div>
        
        <button
          onClick={handleClose}
          className={`
            p-1.5 rounded-md transition-colors duration-200 hover:bg-opacity-80
            ${isDarkMode 
              ? 'hover:bg-slate-700 text-slate-400' 
              : 'hover:bg-gray-200 text-gray-500'
            }
          `}
        >
          <XMarkIcon className="h-4 w-4" />
        </button>
      </div>

      <motion.div 
        initial={{ height: 0 }}
        animate={{ height: 'auto' }}
        className={`
          overflow-y-auto custom-scrollbar
          ${isDarkMode ? 'bg-slate-800' : 'bg-white'}
        `}
        style={{ maxHeight: 'calc(90vh - 100px)' }}
      >
        <pre 
          className={`
            whitespace-pre-wrap font-mono text-sm p-4
            ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}
          `}
        >
          {content}
        </pre>
      </motion.div>
    </div>
  );
};

export default PreviewPastedText;