import React, { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import '../styles/markdown.css';
import { ClipboardDocumentIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import { ClipboardDocumentIcon as ClipboardDocumentIconSolid } from '@heroicons/react/24/solid';
import { useChat } from '../context/ChatContext';
import LinkedInPreviewMockup from './LinkedInPreviewMockup';
import { useRecentActivities } from '../context/RecentActivitiesContext';

// ------------------------------------------------------------------------
// ButtonGroup Component
// Renders copy and "Add to Campaign" buttons with state-dependent styling
// ------------------------------------------------------------------------
const ButtonGroup = React.memo(({ isDarkMode, isCopied, onCopy, onAddToCanvas, addCount, activeTabId }) => {
  // Get appropriate button text based on state
  const getAddButtonText = () => {
    if (!activeTabId) return "Open a Campaign first";
    if (addCount === 0) return "Add to Campaign";
    if (addCount === 1) return "Added once";
    return `Added ${addCount}x`;
  };

  return (
    <div className="absolute top-2 right-2 flex space-x-1 p-1 z-10 opacity-0 group-hover:opacity-100 transition-opacity">
      <button
        onClick={onCopy}
        className={`${
          isDarkMode 
            ? 'bg-slate-700 hover:bg-slate-600 text-slate-300' 
            : 'bg-gray-100 hover:bg-gray-200 text-gray-500'
        } rounded-md p-1.5 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 ${
          isDarkMode ? 'focus:ring-slate-500' : 'focus:ring-gray-400'
        } shadow-sm`}
        title={isCopied ? "Copied!" : "Copy to clipboard"}
        aria-label={isCopied ? "Copied!" : "Copy to clipboard"}
      >
        {isCopied ? (
          <ClipboardDocumentIconSolid className="h-3.5 w-3.5" />
        ) : (
          <ClipboardDocumentIcon className="h-3.5 w-3.5" />
        )}
      </button>

      <button
        onClick={activeTabId ? onAddToCanvas : undefined}
        disabled={!activeTabId}
        className={`${
          isDarkMode 
            ? !activeTabId
              ? 'bg-slate-800/80 text-slate-600 cursor-not-allowed'
              : addCount > 0
                ? 'bg-purple-700/90 hover:bg-purple-600 text-purple-200 ring-1 ring-purple-500/50'
                : 'bg-slate-700/90 hover:bg-slate-600 text-slate-300 hover:text-white ring-1 ring-slate-500/30'
            : !activeTabId
              ? 'bg-gray-100/80 text-gray-400 cursor-not-allowed'
              : addCount > 0
                ? 'bg-purple-100 hover:bg-purple-200 text-purple-700 ring-1 ring-purple-300'
                : 'bg-white hover:bg-gray-50 text-gray-700 hover:text-gray-900 ring-1 ring-gray-200'
        } rounded-md px-2 py-1.5 transition-all duration-200 transform hover:scale-[1.02] focus:outline-none focus:ring-2 focus:ring-offset-2 ${
          isDarkMode 
            ? !activeTabId
              ? 'focus:ring-slate-700'
              : addCount > 0 
                ? 'focus:ring-purple-500 shadow-[0_0_15px_rgba(168,85,247,0.15)]' 
                : 'focus:ring-slate-500'
            : !activeTabId
              ? 'focus:ring-gray-300'
              : addCount > 0 
                ? 'focus:ring-purple-400 shadow-[0_0_15px_rgba(168,85,247,0.1)]' 
                : 'focus:ring-gray-400'
        } shadow-sm flex items-center gap-1.5 text-xs font-medium`}
        title={getAddButtonText()}
        aria-label={getAddButtonText()}
      >
        <PlusCircleIcon className={`h-3.5 w-3.5 transition-transform duration-200 ${activeTabId ? 'group-hover:rotate-90' : ''}`} />
        <span className="whitespace-nowrap">{getAddButtonText()}</span>
      </button>
    </div>
  );
}, (prevProps, nextProps) => {
  return prevProps.isDarkMode === nextProps.isDarkMode && 
         prevProps.isCopied === nextProps.isCopied &&
         prevProps.addCount === nextProps.addCount &&
         prevProps.activeTabId === nextProps.activeTabId;
});

ButtonGroup.displayName = 'ButtonGroup';

// ------------------------------------------------------------------------
// CodeBlockWithCopy Component
// Handles rendering of code blocks with copy functionality and LinkedIn
// post preview integration
// ------------------------------------------------------------------------
const CodeBlockWithCopy = React.memo(({ language, value, isDarkMode, index, copiedMessages, onCopy }) => {
  const { addToCanvas, selectedPersona, activeTabId } = useChat();
  const { refreshActivities } = useRecentActivities();
  const [isCopied, setIsCopied] = useState(false);
  const [addCount, setAddCount] = useState(0);

  // Simplified LinkedIn post detection - if the content is between ``` without a specific language
  // or with linkedin/text/post language identifier
  const isLinkedInPost = 
    !language || 
    ['text', 'linkedin', 'linkedin-post', 'post'].includes(language.toLowerCase());

  // Copy Handler
  const handleCopy = useCallback((e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(value);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  }, [value]);

  // Canvas Addition Handler
  const handleAddToCanvas = useCallback(async () => {
    try {
      await addToCanvas(value, addCount > 0, {
        immediate: true,
        tempTitle: 'Generating title...'
      });
      setAddCount(prevCount => prevCount + 1);
    } catch (error) {
      console.error('Error adding to canvas:', error);
    }
  }, [value, addToCanvas, addCount]);

  const memoizedButtonGroup = useMemo(() => (
    <ButtonGroup
      isDarkMode={isDarkMode}
      isCopied={isCopied}
      onCopy={handleCopy}
      onAddToCanvas={handleAddToCanvas}
      addCount={addCount}
      activeTabId={activeTabId}
    />
  ), [isDarkMode, isCopied, handleCopy, handleAddToCanvas, addCount, activeTabId]);

  // If it's wrapped in ``` without a specific language or with linkedin/text/post identifier,
  // render as LinkedIn preview
  if (isLinkedInPost) {
    return (
      <div className="relative group mt-4">
        {memoizedButtonGroup}
        <LinkedInPreviewMockup
          selectedPersona={selectedPersona}
          content={value}
          isDarkMode={isDarkMode}
        />
      </div>
    );
  }

  // Regular code block rendering
  return (
    <div className={`relative group rounded-md transition-colors duration-200 ${
      isDarkMode 
        ? 'hover:bg-slate-800/50' 
        : 'hover:bg-slate-50'
    }`}>
      {memoizedButtonGroup}
      <pre className={`rounded-md mb-4 overflow-x-auto text-xs markdown-pre ${
        isDarkMode ? 'bg-slate-800' : 'bg-gray-50'
      }`}>
        <code className={`language-${language} markdown-code block`}>
          {value}
        </code>
      </pre>
    </div>
  );
});

CodeBlockWithCopy.displayName = 'CodeBlockWithCopy';

CodeBlockWithCopy.propTypes = {
  language: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  copiedMessages: PropTypes.object.isRequired,
  onCopy: PropTypes.func.isRequired,
};

// ------------------------------------------------------------------------
// Main ChatMessageMarkdown Component
// Configures markdown rendering with custom components and styling
// ------------------------------------------------------------------------
const ChatMessageMarkdown = ({ content, isDarkMode, onSaveToTextNotes, index, copiedMessages, onCopy }) => {
  // Add safety check for content
  const messageContent = typeof content === 'string' 
    ? content 
    : content?.content || content?.message?.content || '';

  const MarkdownComponents = useMemo(() => ({
    // Paragraph handling
    p: ({ children, ...props }) => {
      const isInListItem = props.node?.parent?.type === 'listItem';
      
      return (
        <p 
          className={`markdown-paragraph ${isInListItem ? 'list-item-paragraph' : ''}`}
          style={isInListItem ? { 
            fontFamily: "'Inter', sans-serif",
            fontSize: '13px',
            color: 'inherit'
          } : undefined}
          {...props}
        >
          {children}
        </p>
      );
    },
    
    // Enhanced list handling
    ul: ({ children, ordered, ...props }) => (
      <ul className="markdown-list markdown-ul" {...props}>
        {children}
      </ul>
    ),
    ol: ({ children, ordered, ...props }) => (
      <ol className="markdown-list markdown-ol" {...props}>
        {children}
      </ol>
    ),
    li: ({ children, ...props }) => {
      return (
        <li className="markdown-list-item" style={{
          fontFamily: "'Inter', sans-serif",
          fontSize: '13px',
          color: 'inherit'
        }}>
          {children}
        </li>
      );
    },
    
    // Keep existing code block handling
    code: ({ node, inline, className, children, ...props }) => {
      const match = /language-(\w+)/.exec(className || '');
      const language = match ? match[1] : '';
      if (!inline) {
        return (
          <CodeBlockWithCopy
            language={language}
            value={String(children).replace(/\n$/, '')}
            isDarkMode={isDarkMode}
            index={index}
            copiedMessages={copiedMessages}
            onCopy={onCopy}
          />
        );
      }
      return (
        <code 
          className={`rounded px-1 py-0.5 text-sm markdown-code ${
            isDarkMode ? 'bg-slate-700' : 'bg-blue-200'
          }`} 
          {...props}
        >
          {children}
        </code>
      );
    },
  }), [isDarkMode, index, copiedMessages, onCopy]);

  return (
    <ReactMarkdown 
      remarkPlugins={[remarkGfm]}
      components={MarkdownComponents}
      className={`markdown-content chat-message-content ${isDarkMode ? 'dark' : 'light'}`}
    >
      {messageContent}
    </ReactMarkdown>
  );
};

ChatMessageMarkdown.propTypes = {
  content: PropTypes.string.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
  onSaveToTextNotes: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  copiedMessages: PropTypes.object.isRequired,
  onCopy: PropTypes.func.isRequired,
};

export default React.memo(ChatMessageMarkdown);
