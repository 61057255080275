import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../../../context/ThemeContext';
import useScreenSize from '../../../hooks/useScreenSize';
import { CheckIcon, InformationCircleIcon } from '@heroicons/react/24/outline';

const PricingToggle = ({ isYearly, onToggle, isDarkMode }) => (
  <div className="flex items-center justify-center space-x-4 mb-12">
    <span className={`text-sm font-medium ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}`}>Monthly</span>
    <button
      onClick={onToggle}
      className={`
        relative inline-flex h-6 w-11 items-center rounded-full
        transition-colors duration-300 focus:outline-none
        ${isYearly 
          ? isDarkMode ? 'bg-blue-600' : 'bg-blue-500'
          : isDarkMode ? 'bg-[#1a1a1a] border border-white/10' : 'bg-slate-200'
        }
      `}
    >
      <span
        className={`
          inline-block h-4 w-4 transform rounded-full
          bg-white shadow-lg transition-transform duration-300
          ${isYearly ? 'translate-x-6' : 'translate-x-1'}
        `}
      />
    </button>
    <div className="flex items-center">
      <span className={`text-sm font-medium ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}`}>Yearly</span>
      <span className={`
        ml-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium
        ${isDarkMode ? 'bg-blue-900/20 text-blue-400' : 'bg-blue-100 text-blue-800'}
      `}>
        Save 20%
      </span>
    </div>
  </div>
);

const PricingSection = () => {
  const { isDarkMode } = useTheme();
  const { isMobile } = useScreenSize();
  const [isYearly, setIsYearly] = useState(false);
  
  const plans = [
    {
      name: "Free",
      monthlyPrice: "$0",
      yearlyPrice: "$0",
      period: "forever",
      description: "Try out AI-powered content creation with basic features",
      features: [
        { text: "5 AI-generated posts per month", tooltip: "Create engaging posts using our AI technology" },
        { text: "Basic voice-to-text", tooltip: "Convert speech to text with standard accuracy" },
        { text: "Simple content calendar", tooltip: "Basic scheduling and planning tools" },
        { text: "Community support", tooltip: "Access to our community forums and basic documentation" },
        { text: "1 LinkedIn profile", tooltip: "Connect and manage one LinkedIn account" }
      ],
      cta: "Get Started",
      popular: false,
      gradient: isDarkMode 
        ? "from-[#1a1a1a] via-[#1a1a1a]/90 to-[#1a1a1a]"
        : "from-slate-50 to-white"
    },
    {
      name: "Starter",
      monthlyPrice: "$29",
      yearlyPrice: "$279",
      period: isYearly ? "per year" : "per month",
      description: "Perfect for professionals building their LinkedIn presence",
      features: [
        { text: "20 AI-generated posts per month", tooltip: "Create more content with advanced AI features" },
        { text: "Voice pattern analysis", tooltip: "Advanced voice recognition with pattern matching" },
        { text: "Advanced content calendar", tooltip: "Enhanced scheduling with analytics" },
        { text: "Priority email support", tooltip: "Get faster responses from our support team" },
        { text: "1 LinkedIn profile", tooltip: "Full features for one LinkedIn account" }
      ],
      cta: "Start Free Trial",
      popular: true,
      gradient: isDarkMode 
        ? "from-[#121212] via-[#1a1a1a] to-[#121212]"
        : "from-blue-50 via-blue-100 to-blue-50"
    },
    {
      name: "Professional",
      monthlyPrice: "$79",
      yearlyPrice: "$759",
      period: isYearly ? "per year" : "per month",
      description: "For serious content creators and thought leaders",
      features: [
        { text: "Unlimited AI content generation", tooltip: "No limits on AI-generated content" },
        { text: "Advanced voice matching", tooltip: "Premium voice recognition and matching" },
        { text: "Content scheduling", tooltip: "Advanced scheduling with team collaboration" },
        { text: "Priority support", tooltip: "24/7 priority support with dedicated manager" },
        { text: "3 LinkedIn profiles", tooltip: "Manage multiple LinkedIn accounts" },
        { text: "Custom templates", tooltip: "Create and save your own content templates" },
        { text: "A/B testing", tooltip: "Test different content versions for better engagement" }
      ],
      cta: "Start Free Trial",
      popular: false,
      gradient: isDarkMode 
        ? "from-[#1a1a1a] via-[#121212] to-[#1a1a1a]"
        : "from-slate-50 to-white"
    }
  ];

  const FeatureTooltip = ({ text, tooltip }) => (
    <div className="group relative flex items-center">
      <span className={`${isDarkMode ? 'text-slate-300' : 'text-slate-700'}`}>{text}</span>
      <InformationCircleIcon className={`
        h-4 w-4 ml-1.5 flex-shrink-0 cursor-help
        ${isDarkMode ? 'text-slate-500' : 'text-slate-400'}
      `} />
      <div className={`
        absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-3 py-2
        text-sm rounded-lg whitespace-nowrap opacity-0 invisible
        transform -translate-y-2 transition-all duration-300
        group-hover:opacity-100 group-hover:visible group-hover:translate-y-0
        ${isDarkMode 
          ? 'bg-[#1a1a1a]/90 text-slate-200 border border-white/10' 
          : 'bg-white text-slate-700 shadow-lg shadow-slate-200/50'
        }
      `}>
        {tooltip}
        <div className={`
          absolute top-full left-1/2 -translate-x-1/2 -mt-2
          border-8 border-transparent
          ${isDarkMode ? 'border-t-slate-800' : 'border-t-white'}
        `} />
      </div>
    </div>
  );

  const PricingCard = ({ plan, index }) => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4, delay: index * 0.1 }}
      whileHover={{ y: -5 }}
      className={`
        relative p-8 rounded-2xl text-center h-fit
        transition-all duration-300
        bg-gradient-to-b ${plan.gradient}
        ${isDarkMode 
          ? 'backdrop-blur-xl backdrop-saturate-150 border border-white/10' 
          : 'shadow-xl shadow-slate-200/50 border border-slate-200/50'
        }
        ${plan.popular ? 'scale-105' : ''}
      `}
    >
      {plan.popular && (
        <div className={`
          absolute -top-4 left-1/2 transform -translate-x-1/2
          px-4 py-1 rounded-full text-sm font-medium
          ${isDarkMode
            ? 'bg-blue-600 text-white'
            : 'bg-blue-600 text-white'
          }
        `}>
          Most Popular
        </div>
      )}

      <div className="mb-8">
        <h3 className={`
          text-2xl font-bold mb-2
          ${isDarkMode ? 'text-white' : 'text-slate-900'}
        `}>
          {plan.name}
        </h3>
        <p className={`
          text-sm mb-4
          ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
        `}>
          {plan.description}
        </p>
        <AnimatePresence mode="wait">
          <motion.div
            key={isYearly ? 'yearly' : 'monthly'}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            className="flex items-baseline justify-center"
          >
            <span className={`
              text-4xl font-bold
              ${isDarkMode ? 'text-white' : 'text-slate-900'}
            `}>
              {isYearly ? plan.yearlyPrice : plan.monthlyPrice}
            </span>
            <span className={`
              ml-2 text-sm
              ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
            `}>
              {plan.period}
            </span>
          </motion.div>
        </AnimatePresence>
      </div>

      <ul className="mb-8 space-y-4">
        {plan.features.map((feature, featureIndex) => (
          <motion.li 
            key={featureIndex}
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3, delay: featureIndex * 0.05 }}
            className="flex items-start justify-start text-left"
          >
            <CheckIcon className={`
              h-5 w-5 mr-3 flex-shrink-0 mt-0.5
              ${plan.popular
                ? isDarkMode ? 'text-blue-400' : 'text-blue-600'
                : isDarkMode ? 'text-slate-400' : 'text-slate-600'
              }
            `} />
            <FeatureTooltip {...feature} />
          </motion.li>
        ))}
      </ul>

      <button className={`
        w-full py-3 px-4 rounded-full text-base font-medium
        transition-all duration-300 transform hover:scale-105
        ${plan.monthlyPrice === "$0"
          ? isDarkMode
            ? 'bg-[#1a1a1a] text-slate-200 hover:bg-[#262626] border border-white/10'
            : 'bg-slate-200 text-slate-700 hover:bg-slate-300'
          : plan.popular
            ? isDarkMode
              ? 'bg-blue-600 text-white hover:bg-blue-700'
              : 'bg-blue-600 text-white hover:bg-blue-700'
            : isDarkMode
              ? 'bg-[#1a1a1a] text-slate-200 hover:bg-[#262626] border border-white/10'
              : 'bg-slate-900 text-white hover:bg-slate-800'
        }
      `}>
        {plan.cta}
      </button>
    </motion.div>
  );

  const MobilePricing = () => (
    <div 
      id="pricing-section"
      className={`py-16 px-4 ${isDarkMode ? 'bg-[#121212]/0' : 'bg-white/0'}`}
    >
      <div className="text-center mb-8">
        <span className={`
          inline-block px-3 py-1.5 text-xs font-medium 
          uppercase tracking-wider rounded-full
          ${isDarkMode 
            ? 'bg-gray-900/80 text-gray-300 border border-gray-800' 
            : 'bg-white text-slate-600 border border-slate-200'
          }
        `}>
          Pricing Plans
        </span>
        
        <h2 className={`
          text-3xl font-semibold mt-4 mb-6
          ${isDarkMode ? 'text-white' : 'text-slate-900'}
        `}>
          Simple Pricing,{' '}
          <span className={isDarkMode ? 'text-white/60' : 'text-slate-900/60'}>
            Powerful Features
          </span>
        </h2>

        <PricingToggle 
          isYearly={isYearly}
          onToggle={() => setIsYearly(!isYearly)}
          isDarkMode={isDarkMode}
        />
      </div>

      <div className="space-y-6 [&>*]:h-fit">
        {plans.map((plan, index) => (
          <PricingCard key={index} plan={plan} index={index} />
        ))}
      </div>
    </div>
  );

  const DesktopPricing = () => (
    <div 
      id="pricing-section"
      className={`
        py-24 sm:py-32 scroll-mt-24
        ${isDarkMode ? 'bg-[#121212]/0' : 'bg-white/0'}
      `}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <span className={`
            inline-block px-3 py-1.5 text-xs font-medium 
            uppercase tracking-wider rounded-full
            ${isDarkMode 
              ? 'bg-gray-900/80 text-gray-300 border border-gray-800' 
              : 'bg-white text-slate-600 border border-slate-200'
            }
          `}>
            Pricing Plans
          </span>
          
          <h2 className={`
            text-4xl font-semibold mt-4 mb-6
            ${isDarkMode ? 'text-white' : 'text-slate-900'}
          `}>
            Simple Pricing,{' '}
            <span className={isDarkMode ? 'text-white/60' : 'text-slate-900/60'}>
              Powerful Features
            </span>
          </h2>

          <PricingToggle 
            isYearly={isYearly}
            onToggle={() => setIsYearly(!isYearly)}
            isDarkMode={isDarkMode}
          />
        </div>

        <div className="grid grid-cols-3 gap-8 max-w-7xl mx-auto items-start">
          {plans.map((plan, index) => (
            <PricingCard key={index} plan={plan} index={index} />
          ))}
        </div>
      </div>
    </div>
  );

  return isMobile ? <MobilePricing /> : <DesktopPricing />;
};

export default PricingSection; 