import React, { useContext, useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTimer } from '../context/TimerContext';
import { AuthContext } from '../context/AuthContext';
import { useTheme } from '../context/ThemeContext';
import Notification from './Notification';
import Timer from './Timer';
import FullScreenTimer from './FullScreenTimer';
import { fetchVoiceNotes } from '../api';
import { BellIcon } from '@heroicons/react/24/outline';
import NotificationsPopup from './NotificationsPopup';

function LiveDashboard() {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { isDarkMode } = useTheme();
  const [notifications, setNotifications] = useState([]);
  const [voiceNotesCount, setVoiceNotesCount] = useState(0);
  const [isTimerVisible, setIsTimerVisible] = useState(false);
  const timerRef = useRef(null);
  const [isFullScreenTimerVisible, setIsFullScreenTimerVisible] = useState(false);
  const [greeting, setGreeting] = useState('');
  const [showNotificationsPopup, setShowNotificationsPopup] = useState(false);
  const [companyNotifications, setCompanyNotifications] = useState([]);

  useEffect(() => {
    const getVoiceNotesCount = async () => {
      try {
        const notes = await fetchVoiceNotes();
        setVoiceNotesCount(notes.length);
      } catch (error) {
        console.error('Error fetching voice notes count:', error);
      }
    };
    getVoiceNotesCount();

    // Set the greeting based on the time of day
    const hour = new Date().getHours();
    if (hour < 5) setGreeting('Good late night');
    else if (hour < 12) setGreeting('Good morning');
    else if (hour < 18) setGreeting('Good afternoon');
    else if (hour < 22) setGreeting('Good evening');
    else setGreeting('Good night');
  }, []);

  const { startTimer, setSelectedDuration, setShowTimerBubble } = useTimer();

  const addNotification = (message, type) => {
    setNotifications(prev => [...prev, { id: Date.now(), message, type }]);
  };

  const handleStartNewChat = () => {
    addNotification("Starting a new chat...", "info");
    navigate('/chat');
  };

  const handleKeyPress = useCallback((event) => {
    if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.key === 'C') {
      event.preventDefault();
      handleStartNewChat();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  const fullName = user?.firstName && user?.lastName ? `${user.firstName} ${user.lastName}`.trim() : 'Guest';

  const toggleNotificationsPopup = () => {
    setShowNotificationsPopup(!showNotificationsPopup);
  };

  const fetchCompanyNotifications = async () => {
    try {
      const response = await fetch('/api/notifications');
      const data = await response.json();
      setCompanyNotifications(data);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  useEffect(() => {
    fetchCompanyNotifications();
  }, []);

  return (
    <div className={`flex flex-col h-full relative overflow-hidden rounded-tr-lg rounded-br-lg ${
      isDarkMode ? 'bg-slate-900 text-slate-200' : 'bg-white text-slate-800'
    }`}>
      {/* Title Bar */}
      <div className={`${
        isDarkMode ? 'bg-slate-800 border-slate-700' : 'bg-white border-gray-200'
      } !h-10 min-h-10 flex-shrink-0 flex items-center px-4 py-2 w-full border-b border-opacity-50 relative`}>
        {/* Live Dashboard title on the left */}
        <h1 className={`text-sm font-semibold ${isDarkMode ? 'text-gray-100' : 'text-gray-800'}`}>
          Live Dashboard
        </h1>

        {/* Notifications icon on the right */}
        <div className="ml-auto">
          <button 
            className={`p-1 rounded-full transition-colors duration-200 ${
              isDarkMode ? 'hover:bg-slate-700' : 'hover:bg-gray-200'
            }`}
            title="Notifications"
            onClick={toggleNotificationsPopup}
          >
            <BellIcon className={`h-5 w-5 ${
              isDarkMode ? 'text-slate-400' : 'text-slate-600'
            }`} />
          </button>
        </div>
      </div>

      <main className={`flex-grow p-4 overflow-y-auto ${isDarkMode ? 'text-gray-100' : 'text-gray-800'}`}>
        <div className="w-full max-w-7xl mx-auto">
          {/* Dashboard content */}
          <div className="mt-4">
            <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              {greeting}, <span className="font-bold">{fullName}</span>! Let's get some shit done!
            </p>
            {/* Add your dashboard widgets, charts, or other components here */}
          </div>
        </div>
      </main>

      {Array.isArray(notifications) && notifications.length > 0 && notifications.map((notification, index) => (
        <Notification
          key={notification.id}
          message={notification.message}
          type={notification.type}
          onClose={() => setNotifications(prev => prev.filter(n => n.id !== notification.id))}
          duration={3000}
          currentIndex={index + 1}
          totalCount={notifications.length}
        />
      ))}
      {isFullScreenTimerVisible && (
        <FullScreenTimer onClose={() => setIsFullScreenTimerVisible(false)} />
      )}
      
      {showNotificationsPopup && (
        <NotificationsPopup
          notifications={companyNotifications}
          onClose={() => setShowNotificationsPopup(false)}
          isDarkMode={isDarkMode}
        />
      )}
    </div>
  );
}

export default LiveDashboard;