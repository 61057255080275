import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '../context/ThemeContext';
import CanvasPostContentEditor from './CanvasPostContentEditor';
import TitleForLinkedInPostMockup from './titleForLinkedInPostMockup';
import { CollapseIcon } from './icons/ExpandCollapseIcon';

// Utility functions for random numbers (copied from LinkedInPostMockup)
const getRandomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
const getRandomReactions = () => getRandomNumber(0, 9999);
const getRandomComments = () => getRandomNumber(0, 999);
const getRandomShares = () => getRandomNumber(0, 99);

const CanvasFocusPost = ({
  isOpen,
  onClose,
  content,
  onSave,
  title,
  selectedPersona,
}) => {
  const { isDarkMode } = useTheme();
  const overlayRef = useRef(null);
  
  // Add state for content editing
  const [editedContent, setEditedContent] = useState(content);
  const [isEditing, setIsEditing] = useState(false);
  
  // Add handlers for content editing
  const handleContentSave = () => {
    if (onSave) {
      onSave(editedContent);
    }
    onClose();
  };

  const handleContentCancel = () => {
    setEditedContent(content);
    setIsEditing(false);
    onClose();
  };

  // Add state for reactions (initialized once when modal opens)
  const [reactions] = useState(getRandomReactions());
  const [comments] = useState(getRandomComments());
  const [shares] = useState(getRandomShares());

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        handleContentCancel();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div
      ref={overlayRef}
      className="fixed inset-0 z-50 flex items-start justify-center overflow-y-auto"
      onClick={(e) => {
        if (e.target === overlayRef.current) {
          handleContentCancel();
        }
      }}
    >
      {/* Backdrop */}
      <div className={`fixed inset-0 ${
        isDarkMode ? 'bg-slate-900/90' : 'bg-gray-100/90'
      } backdrop-blur-sm`} />

      {/* Container for both title and content */}
      <div className="relative z-10 w-full max-w-2xl mx-4 flex flex-col select-text cursor-default mt-10 mb-10">
        {/* Title bar and main content in a single column */}
        <div className={`
          rounded-t-xl
          ${isDarkMode ? 'bg-slate-800' : 'bg-white'}
          shadow-2xl
        `}>
          {/* Title bar */}
          <div className="px-6 pt-4">
            <TitleForLinkedInPostMockup
              initialTitle={title}
              onTitleChange={() => {}}
              isDarkMode={isDarkMode}
              onEdit={() => setIsEditing(true)}
              onRemove={handleContentCancel}
              onCopy={() => {}}
              currentIndex={0}
              totalPosts={1}
              isEditing={isEditing}
              onSave={handleContentSave}
              onCancel={handleContentCancel}
              isInteracting={true}
              onFocusMode={handleContentCancel}
              CustomFocusIcon={CollapseIcon}
              isInFocusMode={true}
            />
          </div>
        </div>

        {/* Content section */}
        <div className={`
          rounded-b-xl
          ${isDarkMode ? 'bg-slate-800' : 'bg-white'}
          shadow-2xl
          transform transition-all duration-300 ease-out
        `}>
          <div className={`p-6 ${isDarkMode ? 'text-slate-200' : 'text-gray-800'}`}>
            {/* Author Info */}
            <div className="flex items-center mb-6">
              {selectedPersona?.avatarUrl ? (
                <img 
                  src={selectedPersona.avatarUrl} 
                  alt={selectedPersona.name}
                  className="w-12 h-12 rounded-full object-cover"
                />
              ) : (
                <div className={`w-12 h-12 rounded-full ${
                  isDarkMode ? 'bg-slate-700' : 'bg-gray-200'
                } flex items-center justify-center`}>
                  <span className={`text-2xl ${
                    isDarkMode ? 'text-slate-500' : 'text-gray-400'
                  }`}>?</span>
                </div>
              )}
              <div className="ml-3">
                <div className={`text-sm font-semibold ${
                  isDarkMode ? 'text-slate-200' : 'text-gray-900'
                }`}>
                  {selectedPersona?.name || 'User Name'}
                </div>
                <div className={`text-xs ${
                  isDarkMode ? 'text-slate-400' : 'text-gray-500'
                }`}>
                  {selectedPersona?.occupation || 'Occupation'}
                </div>
                <div className={`flex items-center text-xs ${
                  isDarkMode ? 'text-slate-500' : 'text-gray-400'
                }`}>
                  <span>1h • </span>
                  <svg className="h-3 w-3 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 00-1.668-1.973z" clipRule="evenodd" />
                  </svg>
                </div>
              </div>
            </div>

            {/* Content Editor */}
            <div className={`mb-6 ${
              isDarkMode ? 'text-slate-300' : 'text-gray-700'
            }`}>
              <CanvasPostContentEditor
                content={content}
                onContentChange={setEditedContent}
                isDarkMode={isDarkMode}
                isEditing={true}
                autoFocus={true}
                onSave={handleContentSave}
                onCancel={handleContentCancel}
                className="min-h-[200px] cursor-text select-text"
                hideSaveCancelButtons={true}
                style={{
                  cursor: 'text',
                  userSelect: 'text',
                  WebkitUserSelect: 'text',
                }}
              />
            </div>

            {/* Engagement Stats with random numbers */}
            <div className={`text-xs flex items-center justify-between mt-6 ${
              isDarkMode ? 'text-slate-400' : 'text-gray-500'
            }`}>
              <div className="flex items-center">
                <img className={`${isDarkMode ? 'bg-slate-700' : 'bg-white'} rounded-full`} src="https://static.kleo.so/icons/like.svg" alt="Likes" />
                <img className={`-translate-x-[5px] ${isDarkMode ? 'bg-slate-700' : 'bg-white'} rounded-full`} src="https://static.kleo.so/icons/celebrate.svg" alt="Celebrations" />
                <img className={`-translate-x-[10px] ${isDarkMode ? 'bg-slate-700' : 'bg-white'} rounded-full`} src="https://static.kleo.so/icons/support.svg" alt="Empathy" />
                <span className="-ml-1.5">{reactions.toLocaleString()}</span>
              </div>
              <span>{comments} comments • {shares} reposts</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CanvasFocusPost;
