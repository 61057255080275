import React from 'react';

const LinkedInPostPreview = ({ 
  content = '',
  persona = null,
  isDarkMode = false,
}) => {
  const reactions = Math.floor(Math.random() * 1000);
  const comments = Math.floor(Math.random() * 100);
  const shares = Math.floor(Math.random() * 50);

  return (
    <div className="w-full linkedin-post-container">
      <div 
        className={`
          linkedin-post-preview rounded-lg p-4 w-full transition-colors duration-200
          ${isDarkMode 
            ? 'bg-[#1e1e1e] border border-[#333333] hover:bg-[#2a2a2a]' 
            : 'bg-white border border-gray-200 hover:bg-gray-50'
          }
        `}
      >
        {/* Profile Section */}
        <div className="flex items-center">
          {persona?.avatarUrl ? (
            <img 
              src={persona.avatarUrl} 
              alt={persona?.name || 'User'} 
              className="h-8 w-8 rounded-full object-cover"
            />
          ) : (
            <div className={`
              h-8 w-8 rounded-full flex items-center justify-center text-sm
              ${isDarkMode ? 'bg-[#2a2a2a]' : 'bg-gray-200'}
            `}>
              <span className={`${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`}>
                ?
              </span>
            </div>
          )}
          
          <div className="ml-2 flex-1 min-w-0">
            <div className={`
              text-xs font-semibold truncate leading-tight
              ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}
            `}>
              {persona?.name || 'User Name'}
            </div>
            <div className={`
              text-[11px] truncate leading-tight
              ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}
            `}>
              {persona?.occupation || 'Occupation'}
            </div>
            <div className={`
              flex items-center text-[10px] leading-tight
              ${isDarkMode ? 'text-slate-500' : 'text-gray-400'}
            `}>
              <span>1h • </span>
              <svg className="h-2.5 w-2.5 ml-0.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 00-1.668-1.973z" clipRule="evenodd" />
              </svg>
            </div>
          </div>
        </div>

        {/* Content */}
        <div className="mt-3">
          <div className={`
            text-xs leading-relaxed whitespace-pre-wrap break-words
            ${isDarkMode ? 'text-slate-300' : 'text-gray-700'}
          `}>
            {content || 'Post content will appear here...'}
          </div>
        </div>

        {/* Engagement Metrics */}
        <div className={`
          text-[10px] flex items-center justify-between mt-3
          ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}
        `}>
          <div className="flex items-center space-x-0">
            <div className="flex -space-x-1 mr-1">
              <img 
                className={`${isDarkMode ? 'bg-[#2a2a2a]' : 'bg-white'} rounded-full w-3 h-3`} 
                src="https://static.kleo.so/icons/like.svg" 
                alt="Likes" 
              />
              <img 
                className={`${isDarkMode ? 'bg-[#2a2a2a]' : 'bg-white'} rounded-full w-3 h-3`} 
                src="https://static.kleo.so/icons/celebrate.svg" 
                alt="Celebrations" 
              />
              <img 
                className={`${isDarkMode ? 'bg-[#2a2a2a]' : 'bg-white'} rounded-full w-3 h-3`} 
                src="https://static.kleo.so/icons/support.svg" 
                alt="Empathy" 
              />
            </div>
            <span>{reactions.toLocaleString()}</span>
          </div>
          <span>{comments} comments • {shares} reposts</span>
        </div>
      </div>
    </div>
  );
};

export default LinkedInPostPreview; 