import React, { useState, useEffect, useRef, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../../context/ThemeContext';
import { 
  FolderIcon,
  TagIcon,
  PlusIcon,
  TrashIcon,
  PencilIcon,
  CheckIcon,
  XMarkIcon,
  FunnelIcon,
  MagnifyingGlassIcon,
  DocumentDuplicateIcon,
  ClockIcon,
  AdjustmentsHorizontalIcon,
  ChevronDownIcon,
  InformationCircleIcon,
  EllipsisVerticalIcon,
  ChevronRightIcon,
  Bars3Icon
} from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import { createPortal } from 'react-dom';
import ReactMarkdown from 'react-markdown';
import { 
  fetchSavedIdeas, 
  createClientFolder, 
  getClientFolders, 
  moveIdeaToFolder, 
  updateSavedIdea, 
  deleteSavedIdea,
  updateClientFolder,
  deleteClientFolder
} from '../../api';

const FolderTag = ({ folderName, isDarkMode }) => {
  const displayName = folderName || "All";

  return (
    <div className={`inline-flex items-center gap-1.5 px-2.5 py-1 rounded-full text-xs
      transition-all duration-200 ease-out
      ${isDarkMode 
        ? 'bg-gray-700/50 text-gray-400 ring-1 ring-gray-700/50 hover:ring-gray-700' 
        : 'bg-gray-100/50 text-gray-600 ring-1 ring-gray-200/50 hover:ring-gray-200'}
      hover:-translate-y-0.5`}
    >
      <FolderIcon className="w-3.5 h-3.5" />
      <span className="truncate max-w-[150px] font-medium tracking-[-0.011em]">{displayName}</span>
    </div>
  );
};

// Add this new component for the desktop status filter dropdown
const StatusFilterDropdown = ({ value, onChange, isDarkMode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const options = [
    { 
      value: 'all', 
      label: 'All Ideas',
      icon: FolderIcon
    },
    { 
      value: 'pending', 
      label: 'Pending',
      icon: ClockIcon
    },
    { 
      value: 'in-progress', 
      label: 'In Progress',
      icon: AdjustmentsHorizontalIcon
    },
    { 
      value: 'completed', 
      label: 'Completed',
      icon: CheckIcon
    }
  ];

  const selectedOption = options.find(opt => opt.value === value);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`flex items-center gap-2 pl-2.5 pr-2 py-1 text-sm rounded-md border
          transition-all duration-200
          ${isDarkMode 
            ? 'bg-[#151515] border-[#333333] text-slate-200 hover:bg-[#202020]' 
            : 'bg-white border-gray-200 text-gray-700 hover:bg-gray-50'}`}
      >
        <selectedOption.icon className={`w-4 h-4 ${
          isDarkMode ? 'text-slate-400' : 'text-gray-500'
        }`} />
        <span>{selectedOption.label}</span>
        <ChevronDownIcon className={`w-4 h-4 ${
          isDarkMode ? 'text-slate-400' : 'text-gray-400'
        } transition-transform ${isOpen ? 'rotate-180' : ''}`} />
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.15 }}
            className={`absolute right-0 mt-1 w-44 rounded-lg shadow-lg 
              backdrop-blur-xl backdrop-saturate-150
              ${isDarkMode 
                ? 'bg-[#151515]/95 ring-1 ring-[#333333] border border-[#333333]' 
                : 'bg-white/95 ring-1 ring-gray-200 border border-gray-200'}`}
          >
            <div className="py-1">
              {options.map(option => (
                <button
                  key={option.value}
                  onClick={() => {
                    onChange(option.value);
                    setIsOpen(false);
                  }}
                  className={`flex items-center gap-2 w-full px-3 py-1.5 text-sm
                    transition-colors duration-200
                    ${value === option.value
                      ? isDarkMode
                        ? 'bg-[#202020] text-slate-200'
                        : 'bg-gray-100 text-gray-900'
                      : isDarkMode
                        ? 'text-slate-400 hover:bg-[#202020]/50'
                        : 'text-gray-600 hover:bg-gray-50'
                    }`}
                >
                  <option.icon className={`w-4 h-4 ${
                    value === option.value
                      ? isDarkMode
                        ? 'text-slate-300'
                        : 'text-gray-700'
                      : isDarkMode
                        ? 'text-slate-400'
                        : 'text-gray-400'
                  }`} />
                  <span className="flex-1 text-left">{option.label}</span>
                  {value === option.value && (
                    <CheckIcon className={`w-4 h-4 ${
                      isDarkMode ? 'text-slate-300' : 'text-gray-700'
                    }`} />
                  )}
                </button>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

// Update the TopBar component
const TopBar = ({ isDarkMode, search, setSearch, filter, setFilter, onToggleSidebar }) => {
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  return (
    <div className={`${isDarkMode ? 'bg-[#121212]/95 border-[#333333]' : 'bg-white/90 border-gray-200'} 
      backdrop-blur-xl sticky top-0 z-20 flex flex-col w-full border-b border-opacity-50`}
    >
      {/* Main toolbar */}
      <div className="h-10 flex items-center justify-between px-4">
        {/* Left section with menu and title */}
        <div className="flex items-center gap-2">
          <button
            onClick={onToggleSidebar}
            className={`md:hidden p-1.5 rounded-lg transition-colors ${
              isDarkMode ? 'hover:bg-[#202020]' : 'hover:bg-gray-100/50'
            }`}
          >
            <Bars3Icon className="w-5 h-5" />
          </button>
          <div className="flex items-center">
            <FolderIcon className={`h-5 w-5 ${
              isDarkMode ? 'text-slate-400' : 'text-gray-600'
            } mr-2`} />
            <h2 className={`text-sm font-medium ${
              isDarkMode ? 'text-slate-200' : 'text-gray-800'
            }`}>
              Saved Ideas
            </h2>
          </div>
        </div>

        {/* Desktop search and filter */}
        <div className="hidden md:flex items-center gap-3">
          {/* Search */}
          <div className="relative group">
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search ideas..."
              className={`w-48 pl-8 pr-3 py-1 text-sm rounded-md border
                transition-all duration-200
                ${isDarkMode 
                  ? 'bg-[#151515] border-[#333333] text-slate-200 placeholder-slate-400' 
                  : 'bg-white border-gray-300 text-gray-900 placeholder-gray-400'
                }
                focus:outline-none focus:ring-0`}
            />
            <MagnifyingGlassIcon className="w-4 h-4 absolute left-2 top-1/2 -translate-y-1/2 text-gray-400" />
            {search && (
              <button
                onClick={() => setSearch('')}
                className="absolute right-2 top-1/2 -translate-y-1/2"
              >
                <XMarkIcon className="w-4 h-4 text-gray-400 hover:text-gray-500" />
              </button>
            )}
          </div>

          {/* Status Filter Dropdown */}
          <StatusFilterDropdown
            value={filter}
            onChange={setFilter}
            isDarkMode={isDarkMode}
          />
        </div>

        {/* Mobile action buttons */}
        <div className="flex md:hidden items-center gap-1">
          <button
            onClick={() => {
              setIsSearchVisible(!isSearchVisible);
              setIsFilterVisible(false);
            }}
            className={`p-1.5 rounded-lg transition-colors ${
              isDarkMode 
                ? `${isSearchVisible ? 'bg-gray-700/50' : ''} hover:bg-gray-700/50` 
                : `${isSearchVisible ? 'bg-gray-100/50' : ''} hover:bg-gray-100/50`
            }`}
          >
            <MagnifyingGlassIcon className="w-5 h-5" />
          </button>
          <button
            onClick={() => {
              setIsFilterVisible(!isFilterVisible);
              setIsSearchVisible(false);
            }}
            className={`p-1.5 rounded-lg transition-colors ${
              isDarkMode 
                ? `${isFilterVisible ? 'bg-gray-700/50' : ''} hover:bg-gray-700/50` 
                : `${isFilterVisible ? 'bg-gray-100/50' : ''} hover:bg-gray-100/50`
            }`}
          >
            <FunnelIcon className="w-5 h-5" />
          </button>
        </div>
      </div>

      {/* Mobile panels remain the same */}
      {/* ... rest of the mobile panels code stays unchanged ... */}
    </div>
  );
};

const InfoPopover = ({ idea, isDarkMode, isVisible, position, onClose }) => {
  const popoverRef = useRef(null);

  // Add click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  if (!isVisible) return null;

  return createPortal(
    <motion.div
      ref={popoverRef}
      initial={{ opacity: 0, y: -5 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -5 }}
      className={`fixed z-50 w-72 rounded-lg shadow-lg ${
        isDarkMode 
          ? 'bg-[#151515] ring-1 ring-[#333333]' 
          : 'bg-white ring-1 ring-gray-200'
      }`}
      style={{
        top: `${position.top + 30}px`,
        left: `${Math.min(position.left, window.innerWidth - 288)}px`, // 288 = width + margin
      }}
    >
      <div className="p-3 space-y-2">
        {/* Details */}
        {idea.details && (
          <div className={`text-xs ${
            isDarkMode ? 'text-slate-400' : 'text-gray-500'
          }`}>
            {idea.details}
          </div>
        )}

        {/* Metadata */}
        <div className="flex flex-wrap gap-2 items-center text-xs">
          {/* Date */}
          <span className={`flex items-center gap-1 ${
            isDarkMode ? 'text-gray-400' : 'text-gray-500'
          }`}>
            <ClockIcon className="w-3.5 h-3.5" />
            {new Date(idea.createdAt).toLocaleDateString()}
          </span>

          {/* Source */}
          {idea.source && (
            <span className={`flex items-center gap-1 ${
              isDarkMode ? 'text-gray-400' : 'text-gray-500'
            }`}>
              <FunnelIcon className="w-3.5 h-3.5" />
              {idea.source}
            </span>
          )}
        </div>

        {/* Tags and Categories - Only show if not from you-idea source */}
        {idea.source !== 'you-idea' && (idea.category || idea.tags?.length > 0) && (
          <div className="flex items-center gap-2 flex-wrap pt-1">
            {idea.category && (
              <span className={`text-xs px-2 py-1 rounded-full ${
                isDarkMode 
                  ? 'bg-gray-700 text-gray-300' 
                  : 'bg-gray-100 text-gray-700'
              }`}>
                {idea.category}
              </span>
            )}
            {idea.tags?.map((tag, index) => (
              <span
                key={index}
                className={`text-xs px-2 py-1 rounded-full ${
                  isDarkMode 
                    ? 'bg-gray-700/50 text-gray-400' 
                    : 'bg-gray-100/50 text-gray-600'
                }`}
              >
                {tag}
              </span>
            ))}
          </div>
        )}
      </div>
    </motion.div>,
    document.body
  );
};

// Update the MoveToFolderPopover component
const MoveToFolderPopover = ({ 
  isDarkMode, 
  isVisible, 
  position, 
  folders, 
  onMove, 
  onClose,
  onMouseEnter,
  onMouseLeave,
  anchorRect
}) => {
  const popoverRef = useRef(null);
  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });

  // Calculate optimal position when popover or anchor position changes
  useEffect(() => {
    if (popoverRef.current && anchorRect) {
      const popover = popoverRef.current.getBoundingClientRect();
      const viewport = {
        width: window.innerWidth,
        height: window.innerHeight
      };

      let top = anchorRect.top;
      let left = anchorRect.right + 8; // 8px gap between button and popover

      // Check if popover would go below viewport
      if (top + popover.height > viewport.height) {
        top = viewport.height - popover.height - 16; // 16px padding from bottom
      }

      // Check if popover would go off right edge
      if (left + popover.width > viewport.width) {
        left = anchorRect.left - popover.width - 8; // Position to the left of the button
      }

      // Ensure popover doesn't go above viewport
      if (top < 16) { // 16px padding from top
        top = 16;
      }

      setPopoverPosition({ top, left });
    }
  }, [anchorRect, isVisible]);

  if (!isVisible) return null;

  return createPortal(
    <motion.div
      ref={popoverRef}
      data-move-popover
      initial={{ opacity: 0, y: -5 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -5 }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={`fixed z-[60] w-56 rounded-lg shadow-lg ${
        isDarkMode 
          ? 'bg-gray-800 ring-1 ring-gray-700' 
          : 'bg-white ring-1 ring-gray-200'
      }`}
      style={{
        top: `${popoverPosition.top}px`,
        left: `${popoverPosition.left}px`,
      }}
    >
      <div className="py-2">
        <div className={`px-3 py-2 text-xs font-medium ${
          isDarkMode ? 'text-gray-400' : 'text-gray-500'
        }`}>
          Move to folder
        </div>
        
        {/* All Ideas option (renamed from No Folder) */}
        <button
          onClick={() => onMove(null)}
          className={`w-full flex items-center gap-2 px-3 py-2 text-sm transition-colors ${
            isDarkMode 
              ? 'hover:bg-gray-700 text-gray-300' 
              : 'hover:bg-gray-100 text-gray-700'
          }`}
        >
          <FolderIcon className="w-4 h-4" />
          All Ideas
        </button>

        {/* Folder list */}
        {folders.map(folder => (
          <button
            key={folder._id}
            onClick={() => onMove(folder._id)}
            className={`w-full flex items-center gap-2 px-3 py-2 text-sm transition-colors ${
              isDarkMode 
                ? 'hover:bg-gray-700 text-gray-300' 
                : 'hover:bg-gray-100 text-gray-700'
            }`}
          >
            <FolderIcon className="w-4 h-4" />
            {folder.name}
          </button>
        ))}
      </div>
    </motion.div>,
    document.body
  );
};

// Update the ActionsPopover component
const ActionsPopover = ({ 
  idea, 
  isDarkMode, 
  isVisible, 
  position, 
  onCopy, 
  onEdit, 
  onDelete, 
  folders, 
  onMoveToFolder,
  onClose 
}) => {
  const [showMovePopover, setShowMovePopover] = useState(false);
  const popoverRef = useRef(null);
  const moveButtonRef = useRef(null);
  const [moveButtonRect, setMoveButtonRect] = useState(null);
  const hoverTimeoutRef = useRef(null);

  // Add click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  if (!isVisible) return null;

  // Handle move to folder action
  const handleMove = (folderId) => {
    onMoveToFolder(idea._id, folderId);
    setShowMovePopover(false);
    onClose();
  };

  return createPortal(
    <motion.div
      ref={popoverRef}
      initial={{ opacity: 0, y: -5 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -5 }}
      className={`fixed z-50 w-36 rounded-lg shadow-lg ${
        isDarkMode 
          ? 'bg-[#151515] ring-1 ring-[#333333]' 
          : 'bg-white ring-1 ring-gray-200'
      }`}
      style={{
        top: `${position.top + 30}px`,
        left: `${Math.min(position.left - 120, window.innerWidth - 144)}px`, // 144 = width + margin
      }}
    >
      <div className="py-1">
        <button
          ref={moveButtonRef}
          data-move-button
          onMouseEnter={() => {
            if (hoverTimeoutRef.current) {
              clearTimeout(hoverTimeoutRef.current);
            }
            if (moveButtonRef.current) {
              setMoveButtonRect(moveButtonRef.current.getBoundingClientRect());
            }
            setShowMovePopover(true);
          }}
          onMouseLeave={() => {
            hoverTimeoutRef.current = setTimeout(() => {
              setShowMovePopover(false);
            }, 100);
          }}
          className={`w-full flex items-center justify-between px-3 py-2 text-xs transition-colors ${
            isDarkMode 
              ? `${showMovePopover ? 'bg-[#202020]' : ''} hover:bg-[#202020] text-slate-300` 
              : `${showMovePopover ? 'bg-gray-100' : ''} hover:bg-gray-100 text-gray-700`
          }`}
        >
          <div className="flex items-center gap-2">
            <FolderIcon className="w-4 h-4" />
            Move to
          </div>
          <ChevronRightIcon className="w-3 h-3" />
        </button>

        {/* Other action buttons */}
        <button
          onClick={(e) => {
            e.stopPropagation();
            onCopy();
          }}
          className={`w-full flex items-center gap-2 px-3 py-2 text-xs transition-colors ${
            isDarkMode 
              ? 'hover:bg-gray-700 text-gray-300' 
              : 'hover:bg-gray-100 text-gray-700'
          }`}
        >
          <DocumentDuplicateIcon className="w-4 h-4" />
          Copy
        </button>
        <button
          onClick={(e) => {
            if (e && e.stopPropagation) {
              e.stopPropagation();
            }
            onEdit(e);
          }}
          className={`w-full flex items-center gap-2 px-3 py-2 text-xs transition-colors ${
            isDarkMode 
              ? 'hover:bg-gray-700 text-gray-300' 
              : 'hover:bg-gray-100 text-gray-700'
          }`}
        >
          <PencilIcon className="w-4 h-4" />
          Edit
        </button>
        <button
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
          className={`w-full flex items-center gap-2 px-3 py-2 text-xs transition-colors ${
            isDarkMode 
              ? 'hover:bg-gray-700 text-red-400' 
              : 'hover:bg-gray-100 text-red-600'
          }`}
        >
          <TrashIcon className="w-4 h-4" />
          Delete
        </button>

        {/* Update the MoveToFolderPopover */}
        <AnimatePresence>
          {showMovePopover && (
            <MoveToFolderPopover
              isDarkMode={isDarkMode}
              isVisible={showMovePopover}
              position={position}
              folders={folders}
              onMove={handleMove}
              onClose={() => setShowMovePopover(false)}
              onMouseEnter={() => {
                if (hoverTimeoutRef.current) {
                  clearTimeout(hoverTimeoutRef.current);
                }
              }}
              onMouseLeave={() => {
                hoverTimeoutRef.current = setTimeout(() => {
                  setShowMovePopover(false);
                }, 100);
              }}
              anchorRect={moveButtonRect}
            />
          )}
        </AnimatePresence>
      </div>
    </motion.div>,
    document.body
  );
};

// Update the content section in IdeaPopup component
const IdeaPopup = ({ idea, isDarkMode, onClose, folders, onMoveToFolder, onEdit, onDelete }) => {
  const [showInfo, setShowInfo] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const infoButtonRef = useRef(null);
  const actionsButtonRef = useRef(null);
  const popupRef = useRef(null);

  // Close popup on escape key
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') onClose();
    };
    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, [onClose]);

  // Update handleEdit to not expect an event
  const handleEdit = () => {
    onEdit(idea);
    setShowActions(false);
    onClose();
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(idea.content);
      toast.success('Idea copied to clipboard');
      setShowActions(false);
    } catch (error) {
      console.error('Failed to copy:', error);
      toast.error('Failed to copy idea');
    }
  };

  return createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 md:p-6">
      {/* Backdrop */}
      <div 
        className="absolute inset-0 bg-black/30 backdrop-blur-sm"
        onClick={onClose}
      />
      
      {/* Popup */}
      <motion.div
        ref={popupRef}
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        transition={{ duration: 0.2 }}
        className={`relative w-full max-w-2xl max-h-[85vh] rounded-xl shadow-2xl 
          flex flex-col
          overflow-hidden ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}
      >
        {/* Header */}
        <div className={`flex items-center justify-between px-4 py-3 border-b
          ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
          <div className="flex items-center gap-3">
            <button
              onClick={onClose}
              className={`p-1.5 rounded-full transition-colors
                ${isDarkMode 
                  ? 'hover:bg-gray-700 text-gray-400' 
                  : 'hover:bg-gray-100 text-gray-500'}`}
            >
              <XMarkIcon className="w-5 h-5" />
            </button>
            <FolderTag 
              folderName={idea.clientFolderDetails?.name} 
              isDarkMode={isDarkMode} 
            />
          </div>
          
          <div className="flex items-center gap-1">
            <button
              ref={infoButtonRef}
              onClick={() => {
                setShowInfo(!showInfo);
                setShowActions(false);
              }}
              className={`p-1.5 rounded-full transition-colors
                ${isDarkMode 
                  ? showInfo 
                    ? 'bg-gray-700 text-gray-300' 
                    : 'text-gray-400 hover:bg-gray-700'
                  : showInfo
                    ? 'bg-gray-100 text-gray-700'
                    : 'text-gray-500 hover:bg-gray-100'}`}
            >
              <InformationCircleIcon className="w-5 h-5" />
            </button>
            <button
              ref={actionsButtonRef}
              onClick={() => {
                setShowActions(!showActions);
                setShowInfo(false);
              }}
              className={`p-1.5 rounded-full transition-colors
                ${isDarkMode 
                  ? showActions 
                    ? 'bg-gray-700 text-gray-300' 
                    : 'text-gray-400 hover:bg-gray-700'
                  : showActions
                    ? 'bg-gray-100 text-gray-700'
                    : 'text-gray-500 hover:bg-gray-100'}`}
            >
              <EllipsisVerticalIcon className="w-5 h-5" />
            </button>
          </div>
        </div>

        {/* Content - Updated with consistent text colors */}
        <div className="flex-1 overflow-y-auto popup-scrollbar">
          <div className="p-4 md:p-6">
            <div className={`prose prose-sm max-w-none
              ${isDarkMode 
                ? 'prose-invert prose-p:text-gray-200 prose-headings:text-gray-200 prose-strong:text-gray-200 prose-em:text-gray-300 prose-code:text-gray-200 prose-ul:text-gray-200 prose-ol:text-gray-200 prose-li:text-gray-200 prose-blockquote:text-gray-200 prose-a:text-blue-400' 
                : 'prose-p:text-gray-800 prose-headings:text-gray-900'}`}
            >
              <ReactMarkdown components={{
                // Text components
                p: ({ children }) => (
                  <p className={`!m-0 !leading-relaxed ${isDarkMode ? '!text-gray-200' : '!text-gray-800'}`}>
                    {children}
                  </p>
                ),
                h1: ({ children }) => (
                  <h1 className={`!mt-0 ${isDarkMode ? '!text-gray-200' : '!text-gray-900'}`}>
                    {children}
                  </h1>
                ),
                h2: ({ children }) => (
                  <h2 className={`!mt-0 ${isDarkMode ? '!text-gray-200' : '!text-gray-900'}`}>
                    {children}
                  </h2>
                ),
                h3: ({ children }) => (
                  <h3 className={`!mt-0 ${isDarkMode ? '!text-gray-200' : '!text-gray-900'}`}>
                    {children}
                  </h3>
                ),
                // Inline elements
                strong: ({ children }) => (
                  <strong className={isDarkMode ? '!text-gray-200' : '!text-gray-900'}>
                    {children}
                  </strong>
                ),
                em: ({ children }) => (
                  <em className={isDarkMode ? '!text-gray-300' : '!text-gray-800'}>
                    {children}
                  </em>
                ),
                code: ({ children }) => (
                  <code className={`px-1 py-0.5 rounded-md ${
                    isDarkMode 
                      ? '!text-gray-200 bg-gray-700' 
                      : '!text-gray-800 bg-gray-100'
                  }`}>
                    {children}
                  </code>
                ),
                // List elements
                ul: ({ children }) => (
                  <ul className={`!mt-2 !mb-2 ${isDarkMode ? '!text-gray-200' : '!text-gray-800'}`}>
                    {children}
                  </ul>
                ),
                ol: ({ children }) => (
                  <ol className={`!mt-2 !mb-2 ${isDarkMode ? '!text-gray-200' : '!text-gray-800'}`}>
                    {children}
                  </ol>
                ),
                li: ({ children }) => (
                  <li className={isDarkMode ? '!text-gray-200' : '!text-gray-800'}>
                    {children}
                  </li>
                ),
                // Block elements
                blockquote: ({ children }) => (
                  <blockquote className={`!border-l-4 !pl-4 ${
                    isDarkMode 
                      ? '!border-gray-700 !text-gray-200' 
                      : '!border-gray-200 !text-gray-800'
                  }`}>
                    {children}
                  </blockquote>
                ),
                // Links
                a: ({ children, href }) => (
                  <a 
                    href={href}
                    className={`!font-medium ${
                      isDarkMode ? '!text-blue-400' : '!text-blue-600'
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {children}
                  </a>
                ),
                // Wrapper
                wrapper: ({ children }) => (
                  <div className="!m-0 !p-0">
                    {children}
                  </div>
                )
              }}>
                {idea.content}
              </ReactMarkdown>
            </div>
          </div>
        </div>

        {/* Popovers */}
        <AnimatePresence>
          {showInfo && (
            <InfoPopover
              idea={idea}
              isDarkMode={isDarkMode}
              isVisible={showInfo}
              position={infoButtonRef.current?.getBoundingClientRect() || { top: 0, left: 0 }}
              onClose={() => setShowInfo(false)}
            />
          )}
        </AnimatePresence>

        <AnimatePresence>
          {showActions && (
            <ActionsPopover
              idea={idea}
              isDarkMode={isDarkMode}
              isVisible={showActions}
              position={actionsButtonRef.current?.getBoundingClientRect() || { top: 0, left: 0 }}
              onCopy={handleCopy}
              onEdit={() => handleEdit()}
              onDelete={() => onDelete(idea._id)}
              folders={folders}
              onMoveToFolder={onMoveToFolder}
              onClose={() => setShowActions(false)}
            />
          )}
        </AnimatePresence>
      </motion.div>
    </div>,
    document.body
  );
};

// Update the IdeaCard component to include the missing functions
const IdeaCard = ({ idea, isDarkMode, onEdit, onDelete, folders, onMoveToFolder }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const infoButtonRef = useRef(null);
  const actionsButtonRef = useRef(null);

  // Get folder name from idea's details or folders array
  const folderName = useMemo(() => {
    if (idea.clientFolderDetails) {
      return idea.clientFolderDetails.name;
    }
    if (idea.clientFolder) {
      const folder = folders.find(f => f._id === idea.clientFolder);
      return folder?.name;
    }
    return null;
  }, [idea.clientFolderDetails, idea.clientFolder, folders]);

  // Add handler for button clicks
  const handleButtonClick = (e, action) => {
    e.stopPropagation(); // Prevent card click
    action();
  };

  // Add handleCopy function
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(idea.content);
      toast.success('Idea copied to clipboard');
      setShowActions(false);
    } catch (error) {
      console.error('Failed to copy:', error);
      toast.error('Failed to copy idea');
    }
  };

  // Update handleEdit to handle the event properly
  const handleEdit = (e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    onEdit(idea);
    setShowActions(false);
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        onClick={() => setIsPopupOpen(true)}
        className={`group relative flex flex-col rounded-xl overflow-visible cursor-pointer
          transition-all duration-200 ease-out
          ${isDarkMode 
            ? 'bg-[#151515]/90 hover:bg-[#1a1a1a] ring-1 ring-[#333333]/50 hover:ring-[#333333]' 
            : 'bg-white hover:bg-gray-50 ring-1 ring-gray-200/50 hover:ring-gray-200'}
          hover:shadow-lg hover:-translate-y-0.5
          backdrop-blur-sm`}
      >
        {/* Top metadata bar */}
        <div className={`px-4 pt-3 pb-2 flex items-center justify-between relative z-20`}>
          <FolderTag folderName={folderName} isDarkMode={isDarkMode} />
          
          <div className={`flex items-center gap-1.5 
            transition-all duration-200 ease-out transform
            ${isDarkMode ? 'bg-gray-700/90' : 'bg-gray-50/90'} 
            backdrop-blur-sm rounded-full px-1.5 py-1
            opacity-0 group-hover:opacity-100 scale-95 group-hover:scale-100`}
          >
            <button
              ref={infoButtonRef}
              onClick={(e) => handleButtonClick(e, () => {
                setShowInfo(!showInfo);
                setShowActions(false);
              })}
              className={`p-1.5 rounded-full transition-colors
                ${isDarkMode 
                  ? showInfo 
                    ? 'bg-gray-600 text-gray-300' 
                    : 'text-gray-400 hover:bg-gray-600 hover:text-gray-300'
                  : showInfo
                    ? 'bg-gray-100 text-gray-700'
                    : 'text-gray-500 hover:bg-gray-100 hover:text-gray-700'}`}
            >
              <InformationCircleIcon className="w-4 h-4" />
            </button>
            <button
              ref={actionsButtonRef}
              onClick={(e) => handleButtonClick(e, () => {
                setShowActions(!showActions);
                setShowInfo(false);
              })}
              className={`p-1.5 rounded-full transition-colors
                ${isDarkMode 
                  ? showActions 
                    ? 'bg-gray-600 text-gray-300' 
                    : 'text-gray-400 hover:bg-gray-600 hover:text-gray-300'
                  : showActions
                    ? 'bg-gray-100 text-gray-700'
                    : 'text-gray-500 hover:bg-gray-100 hover:text-gray-700'}`}
            >
              <EllipsisVerticalIcon className="w-4 h-4" />
            </button>
          </div>
        </div>

        {/* Content section - Updated with dark mode text color */}
        <div className="px-4 pb-3 relative z-10">
          <div className={`text-sm leading-relaxed tracking-[-0.011em] line-clamp-3
            prose prose-sm max-w-none
            ${isDarkMode 
              ? 'prose-invert text-gray-200 prose-p:text-gray-200 prose-headings:text-gray-200' 
              : 'text-gray-800 prose-p:text-gray-800 prose-headings:text-gray-900'}`}
          >
            <ReactMarkdown components={{
              p: ({ children }) => (
                <p className={`!m-0 !leading-relaxed ${
                  isDarkMode ? '!text-gray-200' : '!text-gray-800'
                }`}>
                  {children}
                </p>
              ),
              wrapper: ({ children }) => (
                <div className="!m-0 !p-0">
                  {children}
                </div>
              )
            }}>
              {idea.content}
            </ReactMarkdown>
          </div>
        </div>

        {/* Metadata footer */}
        <div className={`px-4 pb-3 pt-1 flex items-center justify-between text-xs relative z-10
          ${isDarkMode ? 'text-slate-500' : 'text-gray-400'}`}
        >
          <div className="flex items-center gap-2">
            <span>{new Date(idea.createdAt).toLocaleDateString()}</span>
            {idea.category && (
              <>
                <span>•</span>
                <span>{idea.category}</span>
              </>
            )}
          </div>
          {idea.tags?.length > 0 && (
            <div className="flex items-center gap-1">
              <TagIcon className="w-3.5 h-3.5" />
              <span>{idea.tags.length}</span>
            </div>
          )}
        </div>

        {/* Popovers - Updated with proper function references */}
        <AnimatePresence>
          {showInfo && (
            <InfoPopover
              idea={idea}
              isDarkMode={isDarkMode}
              isVisible={showInfo}
              position={infoButtonRef.current?.getBoundingClientRect() || { top: 0, left: 0 }}
              onClose={() => setShowInfo(false)}
            />
          )}
        </AnimatePresence>

        <AnimatePresence>
          {showActions && (
            <ActionsPopover
              idea={idea}
              isDarkMode={isDarkMode}
              isVisible={showActions}
              position={actionsButtonRef.current?.getBoundingClientRect() || { top: 0, left: 0 }}
              onCopy={handleCopy}
              onEdit={handleEdit}
              onDelete={() => onDelete(idea._id)}
              folders={folders}
              onMoveToFolder={onMoveToFolder}
              onClose={() => setShowActions(false)}
            />
          )}
        </AnimatePresence>
      </motion.div>

      {/* Popup */}
      <AnimatePresence>
        {isPopupOpen && (
          <IdeaPopup
            idea={idea}
            isDarkMode={isDarkMode}
            onClose={() => {
              setIsPopupOpen(false);
              setShowInfo(false);
              setShowActions(false);
            }}
            folders={folders}
            onMoveToFolder={onMoveToFolder}
            onEdit={handleEdit}
            onDelete={onDelete}
          />
        )}
      </AnimatePresence>
    </>
  );
};

// Update the EditIdeaModal component with Apple-inspired design
const EditIdeaModal = ({ idea, isDarkMode, isOpen, onClose, onSave }) => {
  const [editedIdea, setEditedIdea] = useState({
    content: idea?.content || '',
    details: idea?.details || '',
    status: idea?.status || 'pending',
    category: idea?.category || '',
    tags: idea?.tags || []
  });
  const [newTag, setNewTag] = useState('');
  const modalRef = useRef(null);

  // Reset form when idea changes
  useEffect(() => {
    if (idea) {
      setEditedIdea({
        content: idea.content || '',
        details: idea.details || '',
        status: idea.status || 'pending',
        category: idea.category || '',
        tags: idea.tags || []
      });
    }
  }, [idea]);

  // Handle escape key
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') onClose();
    };
    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, [onClose]);

  const handleSave = async () => {
    try {
      await onSave(editedIdea);
      onClose();
    } catch (error) {
      console.error('Error saving idea:', error);
      toast.error('Failed to save changes');
    }
  };

  const addTag = () => {
    if (newTag.trim()) {
      setEditedIdea(prev => ({
        ...prev,
        tags: [...new Set([...prev.tags, newTag.trim()])]
      }));
      setNewTag('');
    }
  };

  const removeTag = (tagToRemove) => {
    setEditedIdea(prev => ({
      ...prev,
      tags: prev.tags.filter(tag => tag !== tagToRemove)
    }));
  };

  if (!isOpen) return null;

  return createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 md:p-6">
      {/* Backdrop */}
      <div 
        className="absolute inset-0 bg-black/30 backdrop-blur-sm"
        onClick={onClose}
      />
      
      {/* Modal */}
      <motion.div
        ref={modalRef}
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        transition={{ duration: 0.2 }}
        className={`relative w-full max-w-2xl max-h-[85vh] flex flex-col
          overflow-hidden rounded-xl shadow-2xl
          ${isDarkMode ? 'bg-[#151515]' : 'bg-white'}`}
      >
        {/* Header */}
        <div className={`flex items-center justify-between px-4 py-3 border-b
          ${isDarkMode ? 'border-[#333333]' : 'border-gray-200'}`}
        >
          <div className="flex items-center gap-3">
            <button
              onClick={onClose}
              className={`p-1.5 rounded-full transition-colors
                ${isDarkMode 
                  ? 'hover:bg-[#202020] text-slate-400' 
                  : 'hover:bg-gray-100 text-gray-500'}`}
            >
              <XMarkIcon className="w-5 h-5" />
            </button>
            <h3 className={`text-base font-medium tracking-[-0.011em] ${
              isDarkMode ? 'text-slate-200' : 'text-gray-900'
            }`}>
              Edit Idea
            </h3>
          </div>
          
          <button
            onClick={handleSave}
            className="px-3 py-1.5 text-sm font-medium rounded-full bg-blue-500 
              text-white hover:bg-blue-600 transition-colors"
          >
            Save Changes
          </button>
        </div>

        {/* Content */}
        <div className="flex-1 overflow-y-auto popup-scrollbar">
          <div className="p-4 md:p-6 space-y-6">
            {/* Main content */}
            <div>
              <label className={`block text-sm font-medium mb-2 tracking-[-0.011em] ${
                isDarkMode ? 'text-gray-300' : 'text-gray-700'
              }`}>
                Content
              </label>
              <textarea
                value={editedIdea.content}
                onChange={(e) => setEditedIdea(prev => ({ ...prev, content: e.target.value }))}
                className={`w-full px-3.5 py-2.5 text-sm rounded-lg border
                  transition-all duration-200 min-h-[120px]
                  ${isDarkMode 
                    ? 'bg-[#1a1a1a] border-[#333333] text-slate-200 focus:bg-[#202020] focus:border-[#404040]' 
                    : 'bg-gray-50/50 border-gray-200/50 text-gray-900 focus:bg-white focus:border-gray-300'
                  }
                  focus:outline-none focus:ring-0`}
                placeholder="Write your idea here..."
              />
            </div>

            {/* Details */}
            <div>
              <label className={`block text-sm font-medium mb-2 tracking-[-0.011em] ${
                isDarkMode ? 'text-gray-300' : 'text-gray-700'
              }`}>
                Details
              </label>
              <textarea
                value={editedIdea.details}
                onChange={(e) => setEditedIdea(prev => ({ ...prev, details: e.target.value }))}
                className={`w-full px-3.5 py-2.5 text-sm rounded-lg border
                  transition-all duration-200
                  ${isDarkMode 
                    ? 'bg-gray-700/50 border-gray-600/50 text-gray-200 focus:bg-gray-700 focus:border-gray-500' 
                    : 'bg-gray-50/50 border-gray-200/50 text-gray-900 focus:bg-white focus:border-gray-300'
                  }
                  focus:outline-none focus:ring-0`}
                rows={2}
                placeholder="Add any additional details..."
              />
            </div>

            {/* Status and Category row */}
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className={`block text-sm font-medium mb-2 tracking-[-0.011em] ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-700'
                }`}>
                  Status
                </label>
                <select
                  value={editedIdea.status}
                  onChange={(e) => setEditedIdea(prev => ({ ...prev, status: e.target.value }))}
                  className={`w-full px-3.5 py-2.5 text-sm rounded-lg border
                    transition-all duration-200 appearance-none
                    ${isDarkMode 
                      ? 'bg-gray-700/50 border-gray-600/50 text-gray-200 focus:bg-gray-700 focus:border-gray-500' 
                      : 'bg-gray-50/50 border-gray-200/50 text-gray-900 focus:bg-white focus:border-gray-300'
                    }
                    focus:outline-none focus:ring-0`}
                  >
                    <option value="pending">Pending</option>
                    <option value="in-progress">In Progress</option>
                    <option value="completed">Completed</option>
                  </select>
              </div>

              <div>
                <label className={`block text-sm font-medium mb-2 tracking-[-0.011em] ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-700'
                }`}>
                  Category
                </label>
                <input
                  type="text"
                  value={editedIdea.category}
                  onChange={(e) => setEditedIdea(prev => ({ ...prev, category: e.target.value }))}
                  className={`w-full px-3.5 py-2.5 text-sm rounded-lg border
                    transition-all duration-200
                    ${isDarkMode 
                      ? 'bg-gray-700/50 border-gray-600/50 text-gray-200 focus:bg-gray-700 focus:border-gray-500' 
                      : 'bg-gray-50/50 border-gray-200/50 text-gray-900 focus:bg-white focus:border-gray-300'
                    }
                    focus:outline-none focus:ring-0`}
                  placeholder="Add a category..."
                />
              </div>
            </div>

            {/* Tags section */}
            <div>
              <label className={`block text-sm font-medium mb-2 tracking-[-0.011em] ${
                isDarkMode ? 'text-gray-300' : 'text-gray-700'
              }`}>
                Tags
              </label>
              
              {/* Tag input */}
              <div className="flex gap-2 mb-3">
                <input
                  type="text"
                  value={newTag}
                  onChange={(e) => setNewTag(e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && addTag()}
                  placeholder="Add a tag..."
                  className={`flex-1 px-3.5 py-2.5 text-sm rounded-lg border
                    transition-all duration-200
                    ${isDarkMode 
                      ? 'bg-gray-700/50 border-gray-600/50 text-gray-200 focus:bg-gray-700 focus:border-gray-500' 
                      : 'bg-gray-50/50 border-gray-200/50 text-gray-900 focus:bg-white focus:border-gray-300'
                    }
                    focus:outline-none focus:ring-0`}
                />
                <button
                  onClick={addTag}
                  className={`px-3.5 py-2 rounded-lg text-sm font-medium transition-colors
                    ${isDarkMode
                      ? 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                    }`}
                >
                  Add
                </button>
              </div>

              {/* Tags display */}
              <div className="flex flex-wrap gap-2">
                {editedIdea.tags.map((tag, index) => (
                  <span
                    key={index}
                    className={`inline-flex items-center gap-1.5 px-2.5 py-1 rounded-full text-xs
                      transition-colors
                      ${isDarkMode 
                        ? 'bg-gray-700/70 text-gray-300 ring-1 ring-gray-600/50' 
                        : 'bg-gray-100/70 text-gray-700 ring-1 ring-gray-200/50'}`}
                  >
                    <TagIcon className="w-3.5 h-3.5" />
                    {tag}
                    <button
                      onClick={() => removeTag(tag)}
                      className={`p-0.5 rounded-full hover:bg-opacity-80
                        ${isDarkMode ? 'hover:bg-gray-600' : 'hover:bg-gray-200'}`}
                    >
                      <XMarkIcon className="w-3.5 h-3.5" />
                    </button>
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>,
    document.body
  );
};

// Add this new component near the top with other components
const DeleteConfirmDialog = ({ isOpen, onClose, onConfirm, folderName, isDarkMode }) => {
  if (!isOpen) return null;

  return createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Backdrop */}
      <div 
        className="absolute inset-0 bg-black/50 backdrop-blur-sm"
        onClick={onClose}
      />
      
      {/* Dialog */}
      <div className={`relative w-full max-w-sm p-4 rounded-lg shadow-lg ${
        isDarkMode ? 'bg-[#151515]' : 'bg-white'
      }`}>
        <div className="flex flex-col gap-4">
          <div className="flex items-start gap-3">
            <div className={`p-2 rounded-full ${
              isDarkMode ? 'bg-red-500/10' : 'bg-red-50'
            }`}>
              <TrashIcon className="w-5 h-5 text-red-500" />
            </div>
            <div>
              <h3 className={`text-sm font-medium ${
                isDarkMode ? 'text-slate-200' : 'text-gray-900'
              }`}>
                Delete folder
              </h3>
              <p className={`mt-1 text-xs ${
                isDarkMode ? 'text-slate-300' : 'text-gray-600'
              }`}>
                Are you sure you want to delete "{folderName}"? Ideas in this folder will be moved to All Ideas.
              </p>
            </div>
          </div>

          <div className="flex justify-end gap-2">
            <button
              onClick={onClose}
              className={`px-3 py-1.5 text-xs rounded-md ${
                isDarkMode 
                  ? 'bg-gray-700 text-gray-300 hover:bg-gray-600' 
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              Cancel
            </button>
            <button
              onClick={onConfirm}
              className="px-3 py-1.5 text-xs rounded-md bg-red-500 text-white hover:bg-red-600"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

// Update the FolderSidebar styling
const FolderSidebar = ({ folders, selectedFolder, onSelectFolder, onCreateFolder, loadFolders, isOpen, onClose }) => {
  const [isCreating, setIsCreating] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [editingFolderId, setEditingFolderId] = useState(null);
  const [editingFolderName, setEditingFolderName] = useState('');
  const [deletingFolder, setDeletingFolder] = useState(null); // Add this state
  const { isDarkMode } = useTheme();

  // Update the delete handler
  const handleDeleteFolder = async (folder) => {
    setDeletingFolder(folder);
  };

  // Add this function to handle delete confirmation
  const handleConfirmDelete = async () => {
    try {
      await deleteClientFolder(deletingFolder._id);
      // If the deleted folder was selected, switch to All Ideas
      if (selectedFolder === deletingFolder._id) {
        onSelectFolder(null);
      }
      // Refresh folders list
      await loadFolders();
      toast.success('Folder deleted successfully');
    } catch (error) {
      console.error('Error deleting folder:', error);
      toast.error('Failed to delete folder');
    } finally {
      setDeletingFolder(null);
    }
  };

  const handleCreateFolder = async () => {
    if (newFolderName.trim()) {
      try {
        await createClientFolder({ name: newFolderName.trim() });
        // Refresh folders list
        await loadFolders();
        setNewFolderName('');
        setIsCreating(false);
        toast.success('Folder created successfully');
      } catch (error) {
        console.error('Failed to create folder:', error);
        toast.error('Failed to create folder');
      }
    }
  };

  const handleStartEdit = (folder) => {
    setEditingFolderId(folder._id);
    setEditingFolderName(folder.name);
  };

  const handleSaveEdit = async (folderId) => {
    if (editingFolderName.trim() && editingFolderName !== folders.find(f => f._id === folderId)?.name) {
      try {
        await updateClientFolder(folderId, { name: editingFolderName.trim() });
        // Refresh folders list
        await loadFolders();
        setEditingFolderId(null);
        setEditingFolderName('');
        toast.success('Folder name updated successfully');
      } catch (error) {
        console.error('Error updating folder name:', error);
        toast.error('Failed to update folder name');
      }
    } else {
      setEditingFolderId(null);
      setEditingFolderName('');
    }
  };

  const handleCancelEdit = () => {
    setEditingFolderId(null);
    setEditingFolderName('');
  };

  return (
    <div className={`fixed md:relative inset-y-0 left-0 z-30 w-64 transform 
      ${isOpen ? 'translate-x-0' : '-translate-x-full md:translate-x-0'}
      transition-all duration-200 ease-out
      ${isDarkMode 
        ? 'bg-[#121212]/95 border-[#333333]' 
        : 'bg-white/95 border-gray-200/60'}
      backdrop-blur-xl border-r`}
    >
      {/* Close button for mobile */}
      <button
        onClick={onClose}
        className={`md:hidden absolute top-3 right-3 p-1.5 rounded-full 
          transition-colors
          ${isDarkMode 
            ? 'text-slate-400 hover:bg-[#202020] hover:text-slate-300' 
            : 'text-gray-500 hover:bg-gray-100 hover:text-gray-600'}`}
      >
        <XMarkIcon className="w-5 h-5" />
      </button>

      <div className="h-full overflow-y-auto popup-scrollbar p-4 pt-14 md:pt-4">
        <div className="flex items-center justify-between mb-6">
          <h3 className={`text-sm font-medium tracking-[-0.011em] ${
            isDarkMode ? 'text-slate-200' : 'text-gray-900'
          }`}>Folders</h3>
          <button
            onClick={() => setIsCreating(true)}
            className={`p-1.5 rounded-full transition-colors ${
              isDarkMode 
                ? 'hover:bg-gray-800 text-gray-400 hover:text-gray-300' 
                : 'hover:bg-gray-100 text-gray-500 hover:text-gray-600'
            }`}
          >
            <PlusIcon className="w-4 h-4" />
          </button>
        </div>

        {/* Folder creation form */}
        {isCreating && (
          <div className="mb-6 space-y-2">
            <input
              type="text"
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              placeholder="Folder name"
              className={`w-full px-3.5 py-2 text-sm rounded-lg border
                transition-all duration-200
                ${isDarkMode 
                  ? 'bg-[#151515] border-[#333333] text-slate-200 placeholder-slate-400' 
                  : 'bg-gray-50/50 border-gray-200/50 text-gray-900 placeholder-gray-400'
                }
                focus:outline-none focus:ring-0`}
              onKeyPress={(e) => e.key === 'Enter' && handleCreateFolder()}
              autoFocus
            />
            <div className="flex gap-2">
              <button
                onClick={handleCreateFolder}
                className={`text-xs font-medium px-3 py-1.5 rounded-full transition-colors ${
                  isDarkMode 
                    ? 'bg-[#202020] text-slate-300 hover:bg-[#252525]' 
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
              >
                Create
              </button>
              <button
                onClick={() => setIsCreating(false)}
                className={`text-xs font-medium px-3 py-1.5 rounded-full transition-colors ${
                  isDarkMode 
                    ? 'text-slate-400 hover:bg-[#202020]' 
                    : 'text-gray-500 hover:bg-gray-100'
                }`}
              >
                Cancel
              </button>
            </div>
          </div>
        )}

        {/* Folder list */}
        <div className="space-y-0.5">
          <button
            onClick={() => onSelectFolder(null)}
            className={`w-full flex items-center px-3.5 py-2 rounded-lg text-sm
              transition-all duration-200
              ${selectedFolder === null
                ? isDarkMode 
                  ? 'bg-[#202020] text-slate-200 ring-1 ring-[#333333]' 
                  : 'bg-gray-100/80 text-gray-900 ring-1 ring-gray-200/50'
                : isDarkMode
                  ? 'text-slate-400 hover:bg-[#202020]/50'
                  : 'text-gray-600 hover:bg-gray-100/50'
              }`}
          >
            <FolderIcon className="w-4 h-4 mr-2.5" />
            <span className="font-medium tracking-[-0.011em]">All Ideas</span>
          </button>

          {/* Individual folder items */}
          {folders.map(folder => (
            <div
              key={folder._id}
              className="group relative flex items-center"
            >
              {editingFolderId === folder._id ? (
                <div className="w-full flex items-center gap-2 px-2">
                  <input
                    type="text"
                    value={editingFolderName}
                    onChange={(e) => setEditingFolderName(e.target.value)}
                    className={`flex-1 px-3 py-1.5 text-sm rounded-lg border
                      transition-all duration-200
                      ${isDarkMode 
                        ? 'bg-gray-800/50 border-gray-700/50 text-gray-200 focus:bg-gray-800 focus:border-gray-600' 
                        : 'bg-gray-50/50 border-gray-200/50 text-gray-900 focus:bg-white focus:border-gray-300'
                      }
                      focus:outline-none focus:ring-0`}
                    onKeyPress={(e) => e.key === 'Enter' && handleSaveEdit(folder._id)}
                    autoFocus
                  />
                  <div className="flex gap-1">
                    <button
                      onClick={() => handleSaveEdit(folder._id)}
                      className={`p-1.5 rounded-full transition-colors ${
                        isDarkMode 
                          ? 'hover:bg-gray-800 text-gray-400 hover:text-gray-300' 
                          : 'hover:bg-gray-100 text-gray-500 hover:text-gray-600'
                      }`}
                    >
                      <CheckIcon className="w-4 h-4" />
                    </button>
                    <button
                      onClick={handleCancelEdit}
                      className={`p-1.5 rounded-full transition-colors ${
                        isDarkMode 
                          ? 'hover:bg-gray-800 text-gray-400 hover:text-gray-300' 
                          : 'hover:bg-gray-100 text-gray-500 hover:text-gray-600'
                      }`}
                    >
                      <XMarkIcon className="w-4 h-4" />
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <button
                    onClick={() => onSelectFolder(folder._id)}
                    className={`w-full flex items-center px-3.5 py-2 rounded-lg text-sm
                      transition-all duration-200
                      ${selectedFolder === folder._id
                        ? isDarkMode 
                          ? 'bg-[#202020] text-slate-200 ring-1 ring-[#333333]' 
                          : 'bg-gray-100/80 text-gray-900 ring-1 ring-gray-200/50'
                        : isDarkMode
                          ? 'text-slate-400 hover:bg-[#202020]/50'
                          : 'text-gray-600 hover:bg-gray-100/50'
                        }`}
                  >
                    <FolderIcon className="w-4 h-4 mr-2.5" />
                    <span className="font-medium tracking-[-0.011em]">{folder.name}</span>
                  </button>
                  <div className="absolute right-2 opacity-0 group-hover:opacity-100 
                    transition-opacity flex gap-1 px-1 rounded-full
                    ${isDarkMode ? 'bg-[#202020]/90' : 'bg-gray-50/90'} 
                    backdrop-blur-sm"
                  >
                    <button
                      onClick={() => handleStartEdit(folder)}
                      className={`p-1.5 rounded-full transition-colors ${
                        isDarkMode 
                          ? 'hover:bg-[#252525] text-slate-400 hover:text-slate-300' 
                          : 'hover:bg-gray-200 text-gray-500 hover:text-gray-600'
                      }`}
                    >
                      <PencilIcon className="w-3.5 h-3.5" />
                    </button>
                    <button
                      onClick={() => handleDeleteFolder(folder)}
                      className={`p-1.5 rounded-full transition-colors ${
                        isDarkMode 
                          ? 'hover:bg-[#252525] text-red-400 hover:text-red-300' 
                          : 'hover:bg-gray-200 text-red-500 hover:text-red-600'
                      }`}
                    >
                      <TrashIcon className="w-3.5 h-3.5" />
                    </button>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>

        {/* Delete confirmation dialog remains the same */}
        <DeleteConfirmDialog
          isOpen={!!deletingFolder}
          onClose={() => setDeletingFolder(null)}
          onConfirm={handleConfirmDelete}
          folderName={deletingFolder?.name}
          isDarkMode={isDarkMode}
        />
      </div>
    </div>
  );
};

// Update the NoIdeasMessage component
const NoIdeasMessage = ({ searchTerm, selectedFolder, isDarkMode }) => (
  <motion.div 
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.4 }}
    className={`
      flex flex-col items-center justify-center p-8 rounded-2xl
      backdrop-blur-xl backdrop-saturate-150
      ${isDarkMode 
        ? 'bg-[#1a1a1a]/90 border border-white/10 shadow-black/50' 
        : 'bg-white/90 border border-black/5 shadow-black/10'}
    `}
  >
    <div className={`
      p-4 rounded-full mb-4
      ${isDarkMode 
        ? 'bg-[#252525] text-blue-400/80' 
        : 'bg-slate-100 text-blue-600/80'}
    `}>
      <FolderIcon className="w-8 h-8" />
    </div>
    
    <div className="text-center max-w-sm">
      <motion.h3 
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1 }}
        className={`text-lg font-semibold mb-2 tracking-tight
          ${isDarkMode ? 'text-white' : 'text-slate-900'}
        `}
      >
        {searchTerm 
          ? `No results for "${searchTerm}"`
          : selectedFolder
            ? 'This folder is empty'
            : 'No saved ideas yet'
        }
      </motion.h3>
      
      <motion.p 
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className={`text-sm
          ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
        `}
      >
        {searchTerm 
          ? 'Try different keywords or create a new idea'
          : selectedFolder
            ? 'Move some ideas to this folder or create a new one'
            : 'Save and organize your ideas'}
      </motion.p>
    </div>
  </motion.div>
);

// Update the main content area in IdeasManager component
const IdeasManager = () => {
  const { isDarkMode } = useTheme();
  const [ideas, setIdeas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('all');
  const [editingIdea, setEditingIdea] = useState(null);
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Define loadFolders here so it can be passed to FolderSidebar
  const loadFolders = async () => {
    try {
      const data = await getClientFolders();
      setFolders(data);
    } catch (error) {
      console.error('Failed to load folders:', error);
      toast.error('Failed to load folders');
    }
  };

  useEffect(() => {
    loadFolders();
    loadIdeas();
  }, []);

  const loadIdeas = async () => {
    try {
      setLoading(true);
      const data = await fetchSavedIdeas();
      
      // Ensure folder details are preserved when refreshing ideas
      const ideasWithFolderDetails = data.map(idea => ({
        ...idea,
        clientFolderDetails: idea.clientFolder 
          ? folders.find(f => f._id === idea.clientFolder)
          : null
      }));
      
      setIdeas(ideasWithFolderDetails);
    } catch (error) {
      console.error('Failed to load ideas:', error);
      toast.error('Failed to load ideas');
    } finally {
      setLoading(false);
    }
  };

  // Update the handleMoveIdeaToFolder function
  const handleMoveIdeaToFolder = async (ideaId, folderId) => {
    try {
      const targetFolder = folderId ? folders.find(f => f._id === folderId) : null;
      const originalIdea = ideas.find(i => i._id === ideaId);
      
      // Optimistically update the UI first
      setIdeas(prevIdeas => prevIdeas.map(idea => 
        idea._id === ideaId 
          ? {
              ...idea,
              clientFolder: folderId,
              clientFolderDetails: targetFolder,
              // Add a timestamp to ensure we can track the latest update
              lastMoved: new Date().toISOString()
            }
          : idea
      ));

      // Make the API call
      const updatedIdea = await moveIdeaToFolder(ideaId, folderId);

      // Update with server response and ensure folder details
      setIdeas(prevIdeas => prevIdeas.map(idea => 
        idea._id === ideaId 
          ? {
              ...updatedIdea,
              clientFolderDetails: targetFolder,
              lastMoved: new Date().toISOString()
            }
          : idea
      ));

      // Remove the automatic folder switching
      // if (folderId) {
      //   setSelectedFolder(folderId);
      // }

      const folderName = targetFolder?.name || 'All Ideas';
      toast.success(`Idea moved to ${folderName}`);

    } catch (error) {
      console.error('Failed to move idea:', error);
      
      // Revert to original state on error
      setIdeas(prevIdeas => {
        const originalIdea = prevIdeas.find(i => i._id === ideaId);
        return prevIdeas.map(idea => 
          idea._id === ideaId 
            ? {
                ...originalIdea,
                clientFolderDetails: folders.find(f => f._id === originalIdea.clientFolder)
              }
            : idea
        );
      });
      
      toast.error('Failed to move idea');
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteSavedIdea(id);
      setIdeas(ideas.filter(idea => idea._id !== id));
      toast.success('Idea deleted successfully');
    } catch (error) {
      console.error('Failed to delete idea:', error);
      toast.error('Failed to delete idea');
    }
  };

  const handleEdit = (idea) => {
    setEditingIdea(idea);
  };

  // Update the filteredIdeas logic to be more robust
  const filteredIdeas = useMemo(() => {
    return ideas.filter(idea => {
      // Search matching
      const matchesSearch = idea.content.toLowerCase().includes(search.toLowerCase()) ||
        idea.details?.toLowerCase().includes(search.toLowerCase()) ||
        idea.category?.toLowerCase().includes(search.toLowerCase()) ||
        idea.tags?.some(tag => tag.toLowerCase().includes(search.toLowerCase()));

      // Status filter matching
      const matchesFilter = filter === 'all' || idea.status === filter;
      
      // Folder matching - more precise logic
      const matchesFolder = (() => {
        if (selectedFolder === null) return true; // Show all ideas when no folder selected
        if (!selectedFolder) return !idea.clientFolder; // Show only ideas with no folder
        return idea.clientFolder === selectedFolder; // Show ideas in selected folder
      })();

      return matchesSearch && matchesFilter && matchesFolder;
    });
  }, [ideas, search, filter, selectedFolder]);

  // Update the handleSaveEdit function
  const handleSaveEdit = async (updatedIdea) => {
    try {
      const updatedData = await updateSavedIdea(editingIdea._id, updatedIdea);
      setIdeas(ideas.map(idea => 
        idea._id === editingIdea._id ? updatedData : idea
      ));
      toast.success('Idea updated successfully');
      setEditingIdea(null);
    } catch (error) {
      console.error('Error updating idea:', error);
      toast.error('Failed to update idea');
    }
  };

  // Add this function to create a new folder
  const handleCreateFolder = async (name) => {
    try {
      const newFolder = await createClientFolder({ name });
      setFolders([...folders, newFolder]);
      toast.success('Folder created successfully');
    } catch (error) {
      console.error('Failed to create folder:', error);
      toast.error('Failed to create folder');
    }
  };

  // Add a new effect to handle folder view updates
  useEffect(() => {
    // Only run this effect when we have a selected folder and ideas are loaded
    if (selectedFolder && !loading) {
      // Refresh ideas when viewing a specific folder
      loadIdeas();
    }
  }, [selectedFolder]); // Add loading to dependencies if needed

  // Add backdrop click handler for mobile
  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      setIsSidebarOpen(false);
    }
  };

  return (
    <div className="h-full w-full flex flex-col">
      <TopBar 
        isDarkMode={isDarkMode}
        search={search}
        setSearch={setSearch}
        filter={filter}
        setFilter={setFilter}
        onToggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)}
      />
      
      <div className={`flex-1 flex overflow-hidden relative ${
        isDarkMode ? 'bg-[#0c0c0c]' : 'bg-white'
      }`}>
        {/* Backdrop for mobile sidebar */}
        {isSidebarOpen && (
          <div
            className="fixed inset-0 bg-black/50 backdrop-blur-sm z-20 md:hidden"
            onClick={handleBackdropClick}
          />
        )}

        <FolderSidebar
          folders={folders}
          selectedFolder={selectedFolder}
          onSelectFolder={(folder) => {
            setSelectedFolder(folder);
            setIsSidebarOpen(false);
          }}
          onCreateFolder={handleCreateFolder}
          loadFolders={loadFolders}
          isOpen={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
        />
        
        <div className={`flex-1 overflow-auto ${
          isDarkMode ? 'bg-[#0c0c0c]' : 'bg-white'
        }`}>
          <div className="w-full max-w-4xl mx-auto p-4 md:p-6 h-full"> {/* Add h-full here */}
            {loading ? (
              <div className="flex items-center justify-center py-12">
                <div className={`animate-spin rounded-full h-8 w-8 border-b-2 
                  ${isDarkMode ? 'border-blue-400' : 'border-blue-500'}`} 
                />
              </div>
            ) : filteredIdeas.length === 0 ? (
              <div className="h-full flex items-center justify-center min-h-[calc(100vh-10rem)]"> {/* Update this div */}
                <NoIdeasMessage 
                  searchTerm={search}
                  selectedFolder={selectedFolder}
                  isDarkMode={isDarkMode}
                />
              </div>
            ) : (
              <div className="grid gap-4">
                {filteredIdeas.map(idea => (
                  <IdeaCard
                    key={idea._id}
                    idea={idea}
                    isDarkMode={isDarkMode}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    folders={folders}
                    onMoveToFolder={handleMoveIdeaToFolder}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Add EditIdeaModal */}
      <EditIdeaModal
        idea={editingIdea}
        isDarkMode={isDarkMode}
        isOpen={!!editingIdea}
        onClose={() => setEditingIdea(null)}
        onSave={handleSaveEdit}
      />
    </div>
  );
};

export default IdeasManager; 