import React, { useCallback } from 'react';
import { 
  DevicePhoneMobileIcon, 
  DeviceTabletIcon, 
  ComputerDesktopIcon,
  Squares2X2Icon,
  RectangleStackIcon
} from '@heroicons/react/24/outline';
import { DEVICE_CONFIGS } from '../constants/deviceConfig';
import PropTypes from 'prop-types';

const CanvasFloatingBubbleMenu = ({
  isDarkMode,
  activeDevice,
  setActiveDevice,
  onDeviceChange,
  viewMode,
  onViewModeChange,
  isWelcomeVisible
}) => {
  const handleDeviceChange = useCallback((device) => {
    setActiveDevice(device);
    if (onDeviceChange) {
      onDeviceChange(device);
    }
  }, [setActiveDevice, onDeviceChange]);

  const handleViewModeToggle = useCallback(() => {
    const newMode = viewMode === 'list' ? 'masonry' : 'list';
    onViewModeChange(newMode);
  }, [viewMode, onViewModeChange]);

  const isMobileView = window.innerWidth <= 768; // Standard mobile breakpoint
  
  if (isWelcomeVisible || isMobileView) {
    return null;
  }

  const deviceInfo = {
    mobile: '409px width',
    tablet: '471px width',
    desktop: '555px width'
  };

  return (
    <div className={`
      fixed bottom-6 left-1/2 -translate-x-1/2 
      flex items-center gap-2 p-1.5 rounded-xl 
      ${isDarkMode 
        ? 'bg-[#121212]/95 border-[#333333]' 
        : 'bg-white/90 border-gray-200'
      } 
      border backdrop-blur-sm shadow-lg z-50
      transition-all duration-300 ease-in-out
    `}>
      <div className="flex items-center gap-1 px-2">
        <button
          onClick={() => handleDeviceChange('mobile')}
          className={`group relative p-1.5 rounded-lg transition-all duration-300 ${
            activeDevice === 'mobile'
              ? isDarkMode
                ? 'bg-[#1e1e1e] text-slate-200'
                : 'bg-gray-100 text-gray-900'
              : isDarkMode
                ? 'text-slate-400 hover:text-slate-200 hover:bg-[#1e1e1e]'
                : 'text-gray-500 hover:text-gray-900'
          }`}
          title={deviceInfo.mobile}
        >
          <DevicePhoneMobileIcon className="w-4 h-4" />
          <span className={`absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-2 py-1 text-xs 
            whitespace-nowrap rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity
            ${isDarkMode ? 'bg-[#1e1e1e] text-slate-200' : 'bg-white text-gray-900'}`}>
            {deviceInfo.mobile}
          </span>
        </button>
        <button
          onClick={() => handleDeviceChange('tablet')}
          className={`group relative p-1.5 rounded-lg transition-all duration-300 ${
            activeDevice === 'tablet'
              ? isDarkMode
                ? 'bg-[#1e1e1e] text-slate-200'
                : 'bg-gray-100 text-gray-900'
              : isDarkMode
                ? 'text-slate-400 hover:text-slate-200 hover:bg-[#1e1e1e]'
                : 'text-gray-500 hover:text-gray-900'
          }`}
          title={deviceInfo.tablet}
        >
          <DeviceTabletIcon className="w-4 h-4" />
          <span className={`absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-2 py-1 text-xs 
            whitespace-nowrap rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity
            ${isDarkMode ? 'bg-[#1e1e1e] text-slate-200' : 'bg-white text-gray-900'}`}>
            {deviceInfo.tablet}
          </span>
        </button>
        <button
          onClick={() => handleDeviceChange('desktop')}
          className={`group relative p-1.5 rounded-lg transition-all duration-300 ${
            activeDevice === 'desktop'
              ? isDarkMode
                ? 'bg-[#1e1e1e] text-slate-200'
                : 'bg-gray-100 text-gray-900'
              : isDarkMode
                ? 'text-slate-400 hover:text-slate-200 hover:bg-[#1e1e1e]'
                : 'text-gray-500 hover:text-gray-900'
          }`}
          title={deviceInfo.desktop}
        >
          <ComputerDesktopIcon className="w-4 h-4" />
          <span className={`absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-2 py-1 text-xs 
            whitespace-nowrap rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity
            ${isDarkMode ? 'bg-[#1e1e1e] text-slate-200' : 'bg-white text-gray-900'}`}>
            {deviceInfo.desktop}
          </span>
        </button>
      </div>

      <div className={`w-px h-6 ${isDarkMode ? 'bg-[#333333]' : 'bg-gray-200'}`} />

      <div className="flex items-center gap-1 px-2">
        <button
          onClick={handleViewModeToggle}
          className={`group relative p-1.5 rounded-lg transition-all duration-300 ${
            viewMode === 'list'
              ? isDarkMode
                ? 'bg-[#1e1e1e] text-slate-200'
                : 'bg-gray-100 text-gray-900'
              : isDarkMode
                ? 'text-slate-400 hover:text-slate-200 hover:bg-[#1e1e1e]'
                : 'text-gray-500 hover:text-gray-900'
          }`}
          title={viewMode === 'list' ? 'Switch to Masonry View' : 'Switch to List View'}
        >
          <RectangleStackIcon className="w-4 h-4" />
        </button>
        <button
          onClick={() => onViewModeChange('masonry')}
          className={`group relative p-1.5 rounded-lg transition-all duration-300 ${
            viewMode === 'masonry'
              ? isDarkMode
                ? 'bg-[#1e1e1e] text-slate-200'
                : 'bg-gray-100 text-gray-900'
              : isDarkMode
                ? 'text-slate-400 hover:text-slate-200 hover:bg-[#1e1e1e]'
                : 'text-gray-500 hover:text-gray-900'
          }`}
          title="Masonry View"
        >
          <Squares2X2Icon className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
};

CanvasFloatingBubbleMenu.propTypes = {
  isDarkMode: PropTypes.bool.isRequired,
  activeDevice: PropTypes.string.isRequired,
  setActiveDevice: PropTypes.func.isRequired,
  onDeviceChange: PropTypes.func,
  viewMode: PropTypes.string.isRequired,
  onViewModeChange: PropTypes.func.isRequired,
  isWelcomeVisible: PropTypes.bool.isRequired
};

export default CanvasFloatingBubbleMenu;
