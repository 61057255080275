import React, { useState, useRef, useEffect } from 'react';
import { UserIcon, ChevronDownIcon, PlusIcon, PencilIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import { createPortal } from 'react-dom';
import { fetchAvatarUrl } from '../../utils/avatarUtils';
import api from '../../api';
import { useTheme } from '../../context/ThemeContext';
import { useNavigate } from 'react-router-dom';

const PersonasForContentStrategy = ({ onSelectPersona, selectedPersona }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [personas, setPersonas] = useState([]);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const buttonRef = useRef(null);
  const { isDarkMode } = useTheme();

  useEffect(() => {
    const loadPersonasWithAvatars = async () => {
      try {
        const response = await api.get('/api/personas');
        const personasWithAvatars = await Promise.all(
          response.data.map(async (persona) => {
            if (persona.avatar) {
              try {
                const avatarUrl = await fetchAvatarUrl(persona._id);
                return { ...persona, avatarUrl };
              } catch (error) {
                console.error('Error fetching avatar for persona:', error);
                return persona;
              }
            }
            return persona;
          })
        );
        setPersonas(personasWithAvatars);
      } catch (error) {
        console.error('Error fetching personas:', error);
      }
    };

    loadPersonasWithAvatars();
  }, []);

  useEffect(() => {
    const loadSelectedAvatar = async () => {
      if (selectedPersona?._id) {
        try {
          const url = await fetchAvatarUrl(selectedPersona._id);
          setAvatarUrl(url);
        } catch (error) {
          console.error('Error loading selected persona avatar:', error);
          setAvatarUrl(null);
        }
      } else {
        setAvatarUrl(null);
      }
    };
    
    loadSelectedAvatar();
  }, [selectedPersona]);

  useEffect(() => {
    const updatePosition = () => {
      if (buttonRef.current && isOpen) {
        const rect = buttonRef.current.getBoundingClientRect();
        setDropdownPosition({
          top: rect.bottom + window.scrollY + 5, // 5px offset
          left: rect.left + window.scrollX,
          width: rect.width,
        });
      }
    };

    updatePosition();
    window.addEventListener('scroll', updatePosition);
    window.addEventListener('resize', updatePosition);

    return () => {
      window.removeEventListener('scroll', updatePosition);
      window.removeEventListener('resize', updatePosition);
    };
  }, [isOpen]);

  const handlePersonaSelect = (persona) => {
    onSelectPersona(persona);
    setIsOpen(false);
  };

  const handleAddNew = () => {
    setIsOpen(false);
    navigate('/personas/create');
  };

  const handleAvatarError = async (personaId) => {
    try {
      const newAvatarUrl = await fetchAvatarUrl(personaId, true); // Add force refresh parameter
      if (newAvatarUrl) {
        setPersonas(prevPersonas => 
          prevPersonas.map(persona => 
            persona._id === personaId ? { ...persona, avatarUrl: newAvatarUrl } : persona
          )
        );
      }
    } catch (error) {
      console.error('Error refreshing avatar URL:', error);
    }
  };

  const renderPersonaItem = (persona) => (
    <button
      key={persona._id}
      onClick={() => handlePersonaSelect(persona)}
      className={`block w-full text-left px-3 py-2 text-sm rounded-md
        bg-gradient-to-r transition-all duration-200
        ${isDarkMode
          ? 'bg-[#1a1a1a] hover:bg-[#202020] text-slate-200 border-[#333333]'
          : 'from-slate-50/80 to-indigo-50/20 hover:from-slate-100/80 hover:to-indigo-100/20 text-slate-700 border-indigo-100/50'
        }
        border
      `}
    >
      <div className="flex items-center">
        {persona.avatarUrl ? (
          <img 
            src={persona.avatarUrl} 
            alt=""
            className="w-5 h-5 rounded-full mr-2"
            onError={() => handleAvatarError(persona._id)}
          />
        ) : (
          <UserIcon className="w-5 h-5 mr-2 opacity-50" />
        )}
        <span className="truncate">{persona.name}</span>
      </div>
    </button>
  );

  const renderDropdown = () => (
    <div 
      className="fixed inset-0 z-[1000]" 
      onClick={() => setIsOpen(false)}
    >
      <div 
        className="fixed"
        style={{
          top: dropdownPosition.top,
          left: dropdownPosition.left,
          width: '300px',
        }}
      >
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          className={`rounded-lg shadow-lg backdrop-blur-sm ${
            isDarkMode 
              ? 'bg-[#151515]/95 border border-[#333333]' 
              : 'bg-white/95 border border-indigo-200/50'
          }`}
          onClick={e => e.stopPropagation()}
        >
          {/* Your personas section */}
          <div className="py-3">
            <div className="px-4 mb-2 flex items-center justify-between">
              <div className={`text-xs font-medium ${
                isDarkMode ? 'text-indigo-300/70' : 'text-indigo-500/70'
              }`}>
                Your personas
              </div>
              <div className="flex items-center gap-2">
                <div className={`text-[10px] px-1.5 rounded-full ${
                  isDarkMode 
                    ? 'bg-indigo-500/10 text-indigo-300' 
                    : 'bg-indigo-50 text-indigo-500'
                }`}>
                  {personas.length}
                </div>
                {selectedPersona && (
                  <button
                    onClick={() => {
                      onSelectPersona(null);
                      setIsOpen(false);
                    }}
                    className={`text-[10px] px-1.5 rounded-full transition-colors duration-200 ${
                      isDarkMode
                        ? 'text-red-400/70 hover:text-red-300'
                        : 'text-red-500/70 hover:text-red-500'
                    }`}
                  >
                    Clear
                  </button>
                )}
              </div>
            </div>
            <div className="space-y-1 px-2">
              {/* Existing persona items */}
              {personas.map(renderPersonaItem)}
            </div>
          </div>

          {/* Action buttons - with updated styles */}
          <div className={`px-4 py-3 border-t ${
            isDarkMode ? 'border-indigo-500/20' : 'border-indigo-200/20'
          }`}>
            <button
              onClick={handleAddNew}
              className={`w-full flex items-center justify-center px-3 py-2 rounded-md text-xs
                ${isDarkMode
                  ? 'bg-[#1a1a1a] text-slate-200 hover:bg-[#202020] border border-[#333333]'
                  : 'bg-blue-500 text-white hover:bg-blue-600'
                }
              `}
            >
              <PlusIcon className="w-4 h-4 mr-1.5" />
              Create New Persona
            </button>
          </div>
        </motion.div>
      </div>
    </div>
  );

  return (
    <>
      {/* Main button */}
      <button
        ref={buttonRef}
        onClick={() => setIsOpen(!isOpen)}
        className={`inline-flex items-center px-3 py-1.5 text-sm font-medium rounded-md min-w-[140px] 
          border transition-all duration-200
          ${isDarkMode
            ? 'bg-[#1a1a1a] text-slate-200 hover:bg-[#202020] border-[#333333]'
            : 'bg-gradient-to-r from-slate-50 to-indigo-50/30 text-slate-700 border-indigo-100 hover:border-indigo-200'
          }
        `}
      >
        {selectedPersona ? (
          <>
            {avatarUrl ? (
              <img
                src={avatarUrl}
                alt={selectedPersona.name}
                className="w-5 h-5 rounded-full mr-2"
              />
            ) : (
              <UserIcon className="w-5 h-5 mr-2" />
            )}
            {selectedPersona.name}
          </>
        ) : (
          'Select Persona'
        )}
        <ChevronDownIcon className="w-4 h-4 ml-2" />
      </button>

      {/* Render dropdown */}
      {isOpen && createPortal(renderDropdown(), document.body)}
    </>
  );
};

export default PersonasForContentStrategy; 