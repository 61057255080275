import React from 'react';
import { ChatBubbleLeftRightIcon, SparklesIcon } from '@heroicons/react/24/outline';

const IdeationDropdown = ({ onSelect, isDarkMode }) => {
  return (
    <div className={`
      absolute bottom-full left-0 mb-2 w-56 rounded-lg shadow-lg z-50
      backdrop-blur-md backdrop-saturate-150 overflow-hidden
      border transition-all duration-200
      ${isDarkMode 
        ? 'bg-[#121212]/95 border-[#333333]' 
        : 'bg-white/95 border-gray-200/80'}
    `}>
      <div className={`
        px-2 py-1.5 border-b text-xs font-medium
        ${isDarkMode ? 'border-[#333333] text-slate-400' : 'border-gray-200/80 text-gray-500'}
      `}>
        Select Ideation Method
      </div>
      
      <div className="p-0.5">
        <button
          onClick={() => onSelect('reddit')}
          className={`
            flex items-center gap-2 px-3 py-2 w-full rounded-md
            transition-all duration-200 text-left
            ${isDarkMode 
              ? 'hover:bg-[#1e1e1e] text-slate-200' 
              : 'hover:bg-gray-50 text-gray-700'
            }
          `}
        >
          <ChatBubbleLeftRightIcon className="h-4 w-4" />
          <div className="flex flex-col">
            <span className="text-xs font-medium">Ideate with Reddit</span>
            <span className={`text-[10px] ${
              isDarkMode ? 'text-slate-400' : 'text-gray-500'
            }`}>
              Analyze Reddit discussions
            </span>
          </div>
        </button>

        <button
          onClick={() => onSelect('ai')}
          className={`
            flex items-center gap-2 px-3 py-2 w-full rounded-md
            transition-all duration-200 text-left
            ${isDarkMode 
              ? 'hover:bg-[#1e1e1e] text-slate-200' 
              : 'hover:bg-gray-50 text-gray-700'
            }
          `}
        >
          <SparklesIcon className="h-4 w-4" />
          <div className="flex flex-col">
            <span className="text-xs font-medium">Generate with AI</span>
            <span className={`text-[10px] ${
              isDarkMode ? 'text-slate-400' : 'text-gray-500'
            }`}>
              AI-powered content strategy
            </span>
          </div>
        </button>
      </div>
    </div>
  );
};

export default IdeationDropdown; 