// Canvas-related constants
export const CANVAS_MARGIN = 20;
export const HORIZONTAL_PADDING = 20;
export const VERTICAL_PADDING = 10;
export const COLUMNS = 2;
export const MIN_ZOOM = 0.625;
export const MAX_ZOOM = 1.90;
export const INITIAL_CANVAS_WIDTH = 10000;
export const INITIAL_CANVAS_HEIGHT = 10000;
export const BASE_SCALE = 0.88;
