import React, { createContext, useContext, useState, useCallback, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid'; // Add this import

const ChatContext = createContext();

export const useChat = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context;
};

export const ChatProvider = ({ children }) => {
  // Existing state
  const [chatId, setChatId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [chatFiles, setChatFiles] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [selectedPersona, setSelectedPersona] = useState(null);
  const [activeTabId, setActiveTabId] = useState(() => {
    return localStorage.getItem('lastActiveTabId') || null;
  });

  // Replace canvasContent with canvasContents array
  const [canvasContents, setCanvasContents] = useState([]);
  const [removedContents, setRemovedContents] = useState([]);
  const [newestPostId, setNewestPostId] = useState(null);
  const [canvases, setCanvases] = useState([]);
  const [activeCanvasId, setActiveCanvasId] = useState(null);
  
  const addToCanvasQueue = useRef([]);

  const processQueue = useCallback(() => {
    if (addToCanvasQueue.current.length > 0) {
      setCanvasContents(prevContents => {
        const newContents = [...prevContents];
        addToCanvasQueue.current.forEach(content => {
          newContents.push({ id: uuidv4(), content });
        });
        addToCanvasQueue.current = [];
        return newContents;
      });
    }
  }, []);

  useEffect(() => {
    const timer = setInterval(processQueue, 100); // Process queue every 100ms
    return () => clearInterval(timer);
  }, [processQueue]);

  // Combined and improved addToCanvas function
  const addToCanvas = useCallback((content, allowDuplicate = false) => {
    console.log('ChatContext addToCanvas:', { content, allowDuplicate });
    const postId = `post-${Date.now()}`;
    
    setCanvasContents(prev => {
      // Check for duplicates if not allowed
      if (!allowDuplicate && prev.some(item => item.content === content)) {
        console.log('Duplicate found, returning previous state');
        return prev;
      }

      const newPost = {
        id: postId,
        content: content,
        title: 'Generating title...', // Add temporary title
        timestamp: new Date().toISOString(),
        immediate: true // Flag to indicate immediate addition
      };

      console.log('Adding new content with ID:', postId);
      
      // Set a timeout to remove this post from canvasContents
      setTimeout(() => {
        setCanvasContents(current => 
          current.filter(post => post.id !== postId)
        );
      }, 1000);

      return [...prev, newPost];
    });
  }, []);

  const removeFromCanvas = useCallback((id) => {
    setCanvasContents(prev => {
      const removedItem = prev.find(item => item.id === id);
      if (removedItem) {
        setRemovedContents(prevRemoved => [...prevRemoved, removedItem.content]);
      }
      return prev.filter(item => item.id !== id);
    });
  }, []);

  const addMessage = (message) => {
    setMessages((prevMessages) => [...prevMessages, message]);
  };

  const addFileToChat = (file) => {
    setSelectedFiles(prev => [...prev, file]);
    setAttachedFiles((prevFiles) => [...prevFiles, file]);
    setChatFiles((prevFiles) => [...prevFiles, file]);
  };

  const removeFileFromChat = (fileId) => {
    setSelectedFiles(prev => prev.filter(file => file.id !== fileId));
    setAttachedFiles((prevFiles) => prevFiles.filter((file) => file.id !== fileId));
    setChatFiles((prevFiles) => prevFiles.filter((file) => file.id !== fileId));
  };

  const selectAgent = (agent) => {
    setSelectedAgent(agent);
  };

  return (
    <ChatContext.Provider
      value={{
        chatId,
        setChatId,
        messages,
        addMessage,
        attachedFiles,
        addFileToChat,
        removeFileFromChat,
        chatFiles,
        selectedFiles,
        selectedAgent,
        selectAgent,
        setSelectedAgent,
        selectedPersona,
        setSelectedPersona,
        canvasContents,
        addToCanvas,
        removeFromCanvas,
        newestPostId,
        setNewestPostId,
        canvases,
        setCanvases,
        activeCanvasId,
        setActiveCanvasId,
        activeTabId,
        setActiveTabId,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
