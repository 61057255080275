import React from 'react';
import { Link } from 'react-router-dom';
import {
  UserCircleIcon,
  QuestionMarkCircleIcon,
  SunIcon,
  MoonIcon,
  ArrowRightOnRectangleIcon,
} from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';

const MenuItem = ({ children, onClick, className, isFirst, isLast, isDarkMode }) => {
  return (
    <motion.div
      whileHover={{ 
        x: 2,
      }}
      whileTap={{ scale: 0.98 }}
      transition={{ duration: 0.1 }}
      className="relative"
    >
      {onClick ? (
        <button
          onClick={onClick}
          className={`block w-full text-left px-3 py-1.5 text-xs ${className} flex items-center group transition-all duration-200 relative z-10`}
          role="menuitem"
        >
          {children}
        </button>
      ) : (
        <div className={`block px-3 py-1.5 text-xs ${className} flex items-center group transition-all duration-200 relative z-10`}>
          {children}
        </div>
      )}
      <motion.div
        className={`absolute inset-0 opacity-0 ${
          isDarkMode 
            ? 'bg-gradient-to-r from-[#2A2A2A] to-[#1E1E1E] ring-1 ring-[#333333]' 
            : 'bg-slate-200'
        }`}
        initial={false}
        whileHover={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
      />
    </motion.div>
  );
};

const FocusModeFooterMenuPopup = ({ 
  menuRef,
  isDarkMode,
  toggleDarkMode,
  logout,
  onClose,
  isOpen,
}) => {
  const menuVariants = {
    hidden: { 
      opacity: 0, 
      scale: 0.95,
      y: 10,
    },
    visible: { 
      opacity: 1, 
      scale: 1,
      y: 0,
      transition: {
        duration: 0.15,
        ease: 'easeOut',
      }
    },
    exit: { 
      opacity: 0, 
      scale: 0.95,
      y: 10,
      transition: {
        duration: 0.1,
        ease: 'easeIn',
      }
    }
  };

  const getItemStyle = (isDarkMode) => ({
    normal: isDarkMode 
      ? 'text-gray-300' 
      : 'text-slate-700',
    danger: isDarkMode
      ? 'text-red-400'
      : 'text-red-600',
    icon: isDarkMode 
      ? 'text-slate-400 group-hover:text-white' 
      : 'text-slate-600 group-hover:text-slate-900',
    dangerIcon: isDarkMode
      ? 'text-red-400 group-hover:text-red-300'
      : 'text-red-600 group-hover:text-red-700',
  });

  const styles = getItemStyle(isDarkMode);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div 
          ref={menuRef}
          className={`py-1.5 rounded-md shadow-lg absolute bottom-full left-0 right-0 mb-1 z-10 overflow-hidden ${
            isDarkMode 
              ? 'bg-[#121212] border border-[#333333] shadow-lg shadow-black/20' 
              : 'bg-white border border-slate-200 shadow-lg shadow-slate-200/20'
          }`}
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={menuVariants}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="focus-mode-menu-button"
        >
          <Link to="/my-profile">
            <MenuItem className={styles.normal} isFirst isDarkMode={isDarkMode}>
              <UserCircleIcon className={`w-4 h-4 mr-2.5 transition-all duration-200 ${styles.icon}`} />
              My Profile
            </MenuItem>
          </Link>
          
          <Link to="/help">
            <MenuItem className={styles.normal} isDarkMode={isDarkMode}>
              <QuestionMarkCircleIcon className={`w-4 h-4 mr-2.5 transition-all duration-200 ${styles.icon}`} />
              Help
            </MenuItem>
          </Link>
          
          <MenuItem onClick={toggleDarkMode} className={styles.normal} isDarkMode={isDarkMode}>
            {isDarkMode ? (
              <SunIcon className={`w-4 h-4 mr-2.5 transition-all duration-200 ${styles.icon}`} />
            ) : (
              <MoonIcon className={`w-4 h-4 mr-2.5 transition-all duration-200 ${styles.icon}`} />
            )}
            {isDarkMode ? "Light Mode" : "Dark Mode"}
          </MenuItem>
          
          <MenuItem onClick={logout} className={styles.danger} isLast isDarkMode={isDarkMode}>
            <ArrowRightOnRectangleIcon className={`w-4 h-4 mr-2.5 transition-all duration-200 ${styles.dangerIcon}`} />
            Logout
          </MenuItem>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default FocusModeFooterMenuPopup; 