import React from 'react';

const style = document.createElement('style');
style.textContent = `
  @keyframes tag-glow {
    0%, 100% { box-shadow: 0 0 10px rgba(59, 130, 246, 0.2); }
    50% { box-shadow: 0 0 20px rgba(59, 130, 246, 0.4); }
  }
  .animate-tag-selected {
    animation: tag-glow 2s ease-in-out infinite;
  }
`;
document.head.appendChild(style);

const IdeasTagList = ({ tags, selectedTag, onTagSelect, isDarkMode, onManageTags, tagCounts }) => {
  const getButtonClass = (tag) => `
    whitespace-nowrap px-3 py-1.5 rounded-full text-xs font-medium
    transition-all duration-200
    ${selectedTag === tag
      ? isDarkMode
        ? 'bg-blue-600/20 text-blue-300 border border-blue-500/30 shadow-[0_2px_8px_0_rgba(59,130,246,0.1)]'
        : 'bg-blue-100 text-blue-600 border border-blue-200 shadow-sm'
      : isDarkMode 
        ? 'bg-[#1a1a1a] text-slate-200 hover:bg-[#202020] border border-[#333333] hover:border-[#444444]' 
        : 'bg-gray-100 text-gray-700 hover:bg-gray-200 border border-gray-200/50 hover:border-gray-300/50'
    }
  `;

  return (
    <div className="mb-4">
      <div className="flex items-center w-full pb-2">
        <div className="flex-1 overflow-x-auto custom-scrollbar">
          <div className="flex flex-nowrap space-x-2 py-1">
            <button
              onClick={() => onTagSelect('All')}
              className={`${getButtonClass('All')} transform`}
            >
              All {tagCounts['All'] !== undefined && `(${tagCounts['All']})`}
            </button>
            {tags.map((tag) => (
              <button
                key={tag}
                onClick={() => onTagSelect(tag)}
                className={`${getButtonClass(tag)} transform`}
              >
                {tag} {tagCounts[tag] !== undefined && `(${tagCounts[tag]})`}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IdeasTagList;
