import React, { useState, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../context/ThemeContext';
import PostInsideChatOutput from './PostInsideChatOutput';
import Masonry from 'react-masonry-css';

const LinkedInPostsPopup = ({ posts, onClose, onCopy, onSaveToTextNotes }) => {
  const { isDarkMode } = useTheme();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading time
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const titleText = posts.length === 1
    ? `Let's look at the 1 post we created`
    : `Let's look at the ${posts.length} posts we created`;

  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1
  };

  const SkeletonLoader = () => (
    <div className={`mb-3 p-3 rounded-lg ${isDarkMode ? 'bg-slate-700' : 'bg-gray-100'} animate-pulse`}>
      <div className={`h-4 ${isDarkMode ? 'bg-slate-600' : 'bg-gray-300'} rounded w-3/4 mb-2`}></div>
      <div className="space-y-2">
        <div className={`h-3 ${isDarkMode ? 'bg-slate-600' : 'bg-gray-300'} rounded`}></div>
        <div className={`h-3 ${isDarkMode ? 'bg-slate-600' : 'bg-gray-300'} rounded w-5/6`}></div>
        <div className={`h-3 ${isDarkMode ? 'bg-slate-600' : 'bg-gray-300'} rounded w-4/6`}></div>
      </div>
    </div>
  );

  return (
    <div className={`fixed inset-0 z-50 flex items-center justify-center ${
      isDarkMode ? 'bg-slate-900 bg-opacity-80' : 'bg-white bg-opacity-80'
    }`}>
      <div className={`relative w-full max-w-7xl h-[80vh] overflow-hidden rounded-lg 
        transition-all duration-500 ease-in-out
        ${isDarkMode 
          ? 'bg-slate-800 text-white border border-slate-600 hover:border-slate-500' 
          : 'bg-white text-slate-800 border border-slate-300 hover:border-slate-300'
        } hover:shadow-lg`}>
        <div className={`sticky top-0 z-10 flex justify-between items-center p-3 border-b ${
          isDarkMode ? 'border-slate-600' : 'border-slate-200'
        }`}>
          <h2 className="text-lg font-bold">{titleText}</h2>
          <button onClick={onClose} className={`${
            isDarkMode ? 'text-gray-400 hover:text-gray-200' : 'text-gray-500 hover:text-gray-700'
          }`}>
            <XMarkIcon className="h-5 w-5" />
          </button>
        </div>
        <div className="p-3 h-[calc(100%-3rem)] overflow-y-auto">
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="flex w-auto -ml-3"
            columnClassName="pl-3 bg-clip-padding"
          >
            {isLoading
              ? Array(6).fill().map((_, index) => (
                  <SkeletonLoader key={index} />
                ))
              : posts.map((post, index) => (
                  <PostInsideChatOutput 
                    key={index} 
                    post={post} 
                    index={index} 
                    onCopy={onCopy}
                    onEditInPostLibrary={onSaveToTextNotes}
                  />
                ))
            }
          </Masonry>
        </div>
      </div>
    </div>
  );
};

export default LinkedInPostsPopup;