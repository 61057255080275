import React from 'react';
import { DocumentIcon, XMarkIcon } from '@heroicons/react/24/outline';

const FilePreview = ({ file, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 max-w-2xl w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">{file.name}</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
            <XMarkIcon className="w-6 h-6" />
          </button>
        </div>
        <div className="bg-gray-100 rounded-lg p-4">
          <DocumentIcon className="w-16 h-16 text-gray-400 mx-auto mb-4" />
          <p className="text-center text-gray-600">Preview not available</p>
        </div>
      </div>
    </div>
  );
};

export default FilePreview;