import React, { useState, useRef, useEffect, useCallback, useContext } from 'react';
import { PaperAirplaneIcon, CheckIcon, XMarkIcon, BookmarkIcon as BookmarkPlusIcon, PlusCircleIcon, PlusIcon, ClockIcon, PaperClipIcon, DocumentTextIcon, SparklesIcon, UserIcon, ChatBubbleBottomCenterTextIcon, ClipboardDocumentIcon, BookmarkIcon, BookmarkSlashIcon, ChevronDownIcon, EllipsisVerticalIcon, ChatBubbleLeftRightIcon, Squares2X2Icon, ChevronLeftIcon, ChevronUpDownIcon, PresentationChartBarIcon } from '@heroicons/react/24/outline';
import { ClipboardDocumentIcon as ClipboardDocumentIconSolid } from '@heroicons/react/24/solid';
import { 
  sendChatMessage, 
  createNewChat, 
  loadChat, 
  updateChatTitle, 
  saveMessage, 
  unsaveMessage, 
  sendChatMessageOpenAI,
  sendChatMessageForDecks
} from '../api';
import axios from 'axios';
import './customScrollbar.css';
import { useParams, useNavigate } from 'react-router-dom';
import EditableChatTitle from './EditableChatTitle';
import Notification from './Notification';
import { debounce } from 'lodash';
import logo from '../logo.svg'; // Import the logo
import TypingAnimation from './TypingAnimation';
import TemplatePopup from './TemplatePopup';
import { fetchTemplates } from '../api';
import TemplateAttachment from './TemplateAttachment';
import TemplateTag from './TemplateTag';
import TemplateForm from './TemplateForm';
import WelcomeScreen from './WelcomeScreen';
import AIAgentCreationInterface from './AIAgentCreationInterface';
import { createAIAgent, fetchAIAgents } from '../api';
import AIAgentsListPopup from './AIAgentsListPopup';
import PersonasListPopup from './personas/PersonasListPopup';
import { useTheme } from '../context/ThemeContext';
import FileUploadPopover from './FileUploadPopover';
import VoiceNoteSelector from './VoiceNoteSelector';
import TextNoteSelector from './notes/TextNoteSelector';
import { NotesContext } from '../context/NotesContext';
import { generateChatTitle } from '../services/openaiService';
import VoiceNoteTag from './VoiceNoteTag';
import TextNoteTag from './TextNoteTag';
import { downloadFile } from '../api';
import { convertFileToText } from '../utils/fileConverter'; // Import the existing utility
import { useChat } from '../context/ChatContext';
import { showCustomToast } from './CustomToast'; // Import this if you're using a custom toast component
import FileTag from './FileTag';
import AddContextMenu from './AddContextMenu';
import PersonaDropdown from './personas/PersonaDropdown';
import { fetchAvatarUrl } from '../utils/avatarUtils';
import FileUploadTag from './FileUploadTag';
import { updateChatTemplate } from '../api';
import AIAgentTag from './AIAgentTag';
import '../styles/markdown.css';
import LinkedInPostsPopup from './LinkedInPostsPopup';
import TargetAudienceDropdown from './targetAudiences/TargetAudienceDropdown';
import ChatMessageMarkdown from './ChatMessageMarkdown';
import UserMessageChat from './UserMessageChat';
import AIResponseChat from './AIResponseChat';
import { motion, AnimatePresence } from 'framer-motion';
import Canvas from './Canvas'; // Add this import
import ChatHistory from './ChatHistory'; // Add this import
import { ResizableHandle } from './ResizableHandle'; // We'll create this component
import ChatHistoryIcon from './icons/ChatHistoryIcon';
import ContentStyleTags from './ContentStyleTags';
import PostComments from './PostComments/PostComments';
import { usePostComments } from '../context/PostCommentsContext';
import ChatInterfacePostComments from './PostComments/ChatInterfacePostComments';
import PersonaCreationWizardForChatInterface from './personas/PersonaCreationWizardForChatInterface';
import TextFilePreview from './TextFilePreview';
import PreviewPastedText from './PreviewPastedText';
import VoiceNotePreview from './VoiceNotePreview';
import PreviewVoiceNote from './PreviewVoiceNote';
import TextNotePreview from './TextNotePreview';
import PreviewTextNote from './PreviewTextNote';
import PreviewFileforChat from './PreviewFileforChat';
import FilePreviewforChat from './FilePreviewforChat';
import TranscriptionScreen from './TranscriptionScreen';
import IdeasPage from './ideas/IdeasPage';
import YouIdeaSelector from '../components/YouIdea/YouIdeaSelector';
import KnowledgeBaseSelector from './KnowledgeBaseSelector';
import LoadingSpinner from './LoadingSpinner';
import IdeationDropdown from './IdeationDropdown';
import YouIdea from './YouIdea/YouIdea';
import ContentStrategy from './ContentStrategy/ContentStrategy';
import { LightBulbIcon } from '@heroicons/react/24/outline';
import api from '../api';
import ChatInterfaceMode from './ChatInterfaceMode';

// Add these SVG components at the top of the file
const AnthropicLogo = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" className="inline-block">
    <path
      fill="currentColor"
      d="M17.304 3.541h-3.672l6.696 16.918H24Zm-10.608 0L0 20.459h3.744l1.37-3.553h7.005l1.369 3.553h3.744L10.536 3.541Zm-.371 10.223L8.616 7.82l2.291 5.945Z"
    />
  </svg>
);

const OpenAILogo = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" className="inline-block">
    <path
      fill="currentColor"
      d="M20.562 10.188c.25-.688.313-1.376.25-2.063c-.062-.687-.312-1.375-.625-2c-.562-.937-1.375-1.687-2.312-2.125c-1-.437-2.063-.562-3.125-.312c-.5-.5-1.063-.938-1.688-1.25S11.687 2 11 2a5.17 5.17 0 0 0-3 .938c-.875.624-1.5 1.5-1.813 2.5c-.75.187-1.375.5-2 .875c-.562.437-1 1-1.375 1.562c-.562.938-.75 2-.625 3.063a5.44 5.44 0 0 0 1.25 2.874a4.7 4.7 0 0 0-.25 2.063c.063.688.313 1.375.625 2c.563.938 1.375 1.688 2.313 2.125c1 .438 2.062.563 3.125.313c.5.5 1.062.937 1.687 1.25S12.312 22 13 22a5.17 5.17 0 0 0 3-.937c.875-.625 1.5-1.5 1.812-2.5a4.54 4.54 0 0 0 1.938-.875c.562-.438 1.062-.938 1.375-1.563c.562-.937.75-2 .625-3.062c-.125-1.063-.5-2.063-1.188-2.876m-7.5 10.5c-1 0-1.75-.313-2.437-.875c0 0 .062-.063.125-.063l4-2.312a.5.5 0 0 0 .25-.25a.57.57 0 0 0 .062-.313V11.25l1.688 1v4.625a3.685 3.685 0 0 1-3.688 3.813M5 17.25c-.438-.75-.625-1.625-.438-2.5c0 0 .063.063.125.063l4 2.312a.56.56 0 0 0 .313.063c.125 0 .25 0 .312-.063l4.875-2.812v1.937l-4.062 2.375A3.7 3.7 0 0 1 7.312 19c-1-.25-1.812-.875-2.312-1.75M3.937 8.563a3.8 3.8 0 0 1 1.938-1.626v4.751c0 .124 0 .25.062.312a.5.5 0 0 0 .25.25l4.875 2.813l-1.687 1l-4-2.313a3.7 3.7 0 0 1-1.75-2.25c-.25-.937-.188-2.062.312-2.937M17.75 11.75l-4.875-2.812l1.687-1l4 2.312c.625.375 1.125.875 1.438 1.5s.5 1.313.437 2.063a3.7 3.7 0 0 1-.75 1.937c-.437.563-1 1-1.687 1.25v-4.75c0-.125 0-.25-.063-.312c0 0-.062-.126-.187-.188m1.687-2.5s-.062-.062-.125-.062l-4-2.313c-.125-.062-.187-.062-.312-.062s-.25 0-.313.062L9.812 9.688V7.75l4.063-2.375c.625-.375 1.312-.5 2.062-.5c.688 0 1.375.25 2 .688c.563.437 1.063 1 1.313 1.625s.312 1.375.187 2.062m-10.5 3.5l-1.687-1V7.063c0-.688.187-1.438.562-2C8.187 4.438 8.75 4 9.375 3.688a3.37 3.37 0 0 1 2.062-.313c.688.063 1.375.375 1.938.813c0 0-.063.062-.125.062l-4 2.313a.5.5 0 0 0-.25.25c-.063.125-.063.187-.063.312zm.875-2L12 9.5l2.187 1.25v2.5L12 14.5l-2.188-1.25z"
    />
  </svg>
);

// Update the GrokLogo component
const GrokLogo = () => (
  <svg 
    viewBox="0 0 841.89 595.28" 
    className="inline-block"
    style={{ width: '20px', height: '20px' }}
  >
    <g>
      <polygon 
        fill="currentColor" 
        points="557.09,211.99 565.4,538.36 631.96,538.36 640.28,93.18"
      />
      <polygon 
        fill="currentColor" 
        points="640.28,56.91 538.72,56.91 379.35,284.53 430.13,357.05"
      />
      <polygon 
        fill="currentColor" 
        points="201.61,538.36 303.17,538.36 353.96,465.84 303.17,393.31"
      />
      <polygon 
        fill="currentColor" 
        points="201.61,211.99 430.13,538.36 531.69,538.36 303.17,211.99"
      />
    </g>
  </svg>
);

// Add the Gemini logo component
const GeminiLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" className="inline-block">
    <path
      fill="currentColor"
      d="M21.456 10.154c.123.659.19 1.348.19 2.067c0 5.624-3.764 9.623-9.449 9.623A9.84 9.84 0 0 1 2.353 12a9.84 9.84 0 0 1 9.844-9.844c2.658 0 4.879.978 6.583 2.566l-2.775 2.775V7.49c-1.033-.984-2.344-1.489-3.808-1.489c-3.248 0-5.888 2.744-5.888 5.993s2.64 5.999 5.888 5.999c2.947 0 4.953-1.686 5.365-4h-5.365v-3.839z"
    />
  </svg>
);

const PopupMenu = ({ title, children, onClose }) => (
  <div className="bg-white rounded-lg shadow-lg w-64 p-4 absolute bottom-full mb-2 left-0 z-10">
    <div className="flex justify-between items-center mb-10">
      <h3 className="text-lg font-semibold">{title}</h3>
      <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
        <XMarkIcon className="h-5 w-5" />
      </button>
    </div>
    {children}
  </div>
);

// Add this function back to ChatInterface.js
const extractCodeBlocks = (content) => {
  const codeBlockRegex = /```[\s\S]*?```/g;
  const blocks = content.match(codeBlockRegex) || [];
  return blocks.map(block => block.replace(/```/g, '').trim());
};

// Add this constant near the top with other constants
const DEFAULT_CHAT_WIDTH = 450;

// Add these constants at the top of the file
const MIN_CHAT_WIDTH = 350;
const MIN_CANVAS_WIDTH = 400;
const MAX_CHAT_WIDTH_PERCENTAGE = 0.8;

const ChatInterface = () => {
  const { 
    selectedPersona, 
    setSelectedPersona, 
    selectedAgent, 
    setSelectedAgent 
  } = useChat();

  // Add these new declarations
  const [isResizing, setIsResizing] = useState(false);
  
  const debouncedSaveWidth = useCallback(
    (width) => {
      requestAnimationFrame(() => {
        localStorage.setItem('chatInterfaceWidth', width.toString());
      });
    },
    []
  );

  // Add this near the top with other state declarations
  const [chatWidth, setChatWidth] = useState(() => {
    const savedWidth = localStorage.getItem('chatInterfaceWidth');
    return savedWidth ? parseInt(savedWidth) : DEFAULT_CHAT_WIDTH;
  });

  // Add the handleResize function here
  const handleResize = useCallback((newWidth) => {
    // Ensure width stays within reasonable bounds
    const maxWidth = Math.min(
      window.innerWidth - MIN_CANVAS_WIDTH, // Don't squeeze Canvas below its min width
      window.innerWidth * MAX_CHAT_WIDTH_PERCENTAGE
    );
    const clampedWidth = Math.min(Math.max(newWidth, MIN_CHAT_WIDTH), maxWidth);
    
    setChatWidth(clampedWidth);
    debouncedSaveWidth(clampedWidth);
  }, [debouncedSaveWidth]);

  const navigate = useNavigate();
  const { chatId } = useParams();
  const [chatTitle, setChatTitle] = useState('Untitled Chat');
  const [editedTitle, setEditedTitle] = useState(chatTitle);
  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [input, setInput] = useState('');
  const userName = 'John'; // This should be dynamically set based on the actual user

  const [messages, setMessages] = useState([]);
  const [currentChatId, setCurrentChatId] = useState(null);
  const [inputMessage, setInputMessage] = useState('');
  const [selectedModel, setSelectedModel] = useState(() => {
    // Try to get saved model from localStorage, default to claude-3.5-sonnet if not found
    return localStorage.getItem('selectedAIModel') || 'claude-3.5-sonnet';
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isAITyping, setIsAITyping] = useState(false);
  const [showModelSelector, setShowModelSelector] = useState(false);
  const [showChatModeSelector, setShowChatModeSelector] = useState(false);
  const modelSelectorRef = useRef(null);
  const chatModeSelectorRef = useRef(null);
  const chatMessagesRef = useRef(null);
  const chatInputRef = useRef(null);
  const messagesEndRef = useRef(null);

  // Replace the existing copiedMessages state
  const [copiedMessages, setCopiedMessages] = useState({});

  const { addNote } = useContext(NotesContext);

  // Update the availableModels array
  const availableModels = [
    { id: 'claude-3.5-haiku', name: 'Claude 3.5 Haiku', provider: 'anthropic' },
    { id: 'claude-3.5-sonnet', name: 'Claude 3.5 Sonnet', provider: 'anthropic' },
    { id: 'gpt-4o', name: 'GPT-4o', provider: 'openai' },
    { id: 'grok-beta', name: 'Grok Beta', provider: 'xai' },
    { id: 'gemini-2.0-flash-exp', name: 'Gemini 2.0 Flash', provider: 'google' }
  ];

  const [isTitleLoading, setIsTitleLoading] = useState(true);
  const [titleWidth, setTitleWidth] = useState(0);
  const titleRef = useRef(null);

  const [isNewChat, setIsNewChat] = useState(true);

  const [hoveredMessageIndex, setHoveredMessageIndex] = useState(null);
  const [notifications, setNotifications] = useState([]);

  const [selectedTemplate, setSelectedTemplate] = useState(null);

  // Add these new state declarations
  const [linkedInPosts, setLinkedInPosts] = useState([]);
  const [showLinkedInPopup, setShowLinkedInPopup] = useState(false);

  const [hasBeenEdited, setHasBeenEdited] = useState(false);

  const addNotification = useCallback((message, type) => {
    setNotifications(prev => [...prev, { id: Date.now(), message, type }]);
  }, []);

  const handleSaveMessage = async (message, index) => {
    try {
      if (message.saved) {
        console.log('Attempting to unsave message:', message);
        if (!message.id) {
          console.error('Message marked as saved but has no id:', message);
          throw new Error('Cannot unsave a message without an id');
        }
        if (!currentChatId) {
          console.error('No current chat ID available');
          throw new Error('Cannot unsave a message without a chat ID');
        }
        await unsaveMessage(message.id, currentChatId);
        setMessages(prevMessages => 
          prevMessages.map((msg, i) => 
            i === index ? { ...msg, saved: false } : msg
          )
        );
        addNotification('Message unsaved successfully', 'success');
      } else {
        console.log('Attempting to save message:', message);
        if (!currentChatId) {
          console.error('No current chat ID available');
          addNotification('Cannot save message without an active chat. Please try again.', 'error');
          return; // Exit the function early if there's no chat ID
        }
        const savedDate = new Date().toISOString();
        const savedMessage = await saveMessage({
          content: message.content,
          role: message.role,
          timestamp: message.timestamp,
          savedDate: savedDate,
          chatId: currentChatId,
          messageIndex: index,
        });
        console.log('Received saved message from API:', savedMessage);
        setMessages(prevMessages => 
          prevMessages.map((msg, i) => 
            i === index ? { ...msg, saved: true, id: savedMessage._id, savedDate: savedDate } : msg
          )
        );
        addNotification('Message saved successfully', 'success');
      }
    } catch (error) {
      console.error('Error saving/unsaving message:', error);
      addNotification(error.message || 'Failed to save/unsave message', 'error');
    }
  };

  // Update the copyToClipboard function
  const copyToClipboard = useCallback((text, index) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedMessages(prev => ({ ...prev, [index]: true }));
      setTimeout(() => {
        setCopiedMessages(prev => ({ ...prev, [index]: false }));
      }, 2000);
    }).catch(err => {
      console.error('Failed to copy text: ', err);
      addNotification('Failed to copy message', 'error');
    });
  }, [addNotification]);

  // Update the handleNewChat function
  const handleNewChat = useCallback(() => {
    // Clear chat state
    setCurrentChatId(null);
    setChatTitle('Untitled Chat');
    setMessages([]);
    setIsNewChat(true);
    setSelectedTemplate(null);
    setHasBeenEdited(false);
    setSelectedVoiceNotes([]);
    setSelectedTextNotes([]);
    setAttachedFiles([]);
    setLoadingFiles({});

    // Close chat history
    setShowChatHistory(false);

    // Clear local storage
    localStorage.removeItem('currentChat');
    localStorage.removeItem('selectedVoiceNotes');
    localStorage.removeItem('selectedTextNotes');

    // Update URL without page refresh
    if (window.history && window.history.pushState) {
      window.history.pushState(null, '', '/chat');
    }
  }, []);

  useEffect(() => {
    const loadInitialChat = async () => {
      setIsTitleLoading(true);
      setIsLoading(true);
      
      try {
        if (chatId) {
          console.log('Loading chat from URL parameter:', chatId);
          const chatData = await loadChat(chatId);
          setCurrentChatId(chatId);
          setChatTitle(chatData.title);
          setMessages(chatData.messages);
          setIsNewChat(false);
          setSelectedTemplate(chatData.template || null);
          setHasBeenEdited(true);
          
          saveChatToLocalStorage(chatId, chatData.title, chatData.messages, chatData.template, true);
        } else {
          // Load from localStorage only if we're not actively creating a new chat
          const savedChat = loadChatFromLocalStorage();
          if (savedChat) {
            console.log('Loading chat from localStorage:', savedChat.currentChatId);
            setCurrentChatId(savedChat.currentChatId);
            setChatTitle(savedChat.title);
            setMessages(savedChat.messages);
            setSelectedTemplate(savedChat.template || null);
            setHasBeenEdited(savedChat.hasBeenEdited);
            setIsNewChat(false);
          }
        }
      } catch (error) {
        console.error('Error loading chat:', error);
        handleLoadChatError(error);
      } finally {
        setIsTitleLoading(false);
        setIsLoading(false);
      }
    };

    loadInitialChat();
  }, [chatId, isNewChat]); // Add isNewChat to dependencies

  // Update this effect to include the template in the chat state
  useEffect(() => {
    updateLocalStorage({
      currentChatId,
      title: chatTitle,
      messages,
      template: selectedTemplate
    });
  }, [currentChatId, chatTitle, messages, selectedTemplate]);

  const handleLoadChatError = (error) => {
    if (error.message === 'Chat not found') {
      addNotification('The requested chat could not be found. Starting a new chat.', 'warning');
    } else {
      addNotification('Failed to load the chat. Starting a new chat.', 'error');
    }
    handleNewChat();
  };

  // Add this state to track if we should allow individual message scrolling
  const [isAutoScrolling, setIsAutoScrolling] = useState(false);

  // Update the scroll to bottom function with a more precise control
  const scrollToBottom = useCallback(() => {
    if (!messagesEndRef.current) return;
    
    setIsAutoScrolling(true);
    
    const scrollOptions = {
      behavior: "smooth",
      block: "end",
    };

    messagesEndRef.current.scrollIntoView(scrollOptions);

    // Use requestAnimationFrame for better timing
    requestAnimationFrame(() => {
      // Add a second scroll after a frame to ensure consistency
      messagesEndRef.current?.scrollIntoView(scrollOptions);
      
      // Reset after the scroll animation would complete
      setTimeout(() => {
        setIsAutoScrolling(false);
      }, 300); // Reduced from 1000ms to 300ms for snappier response
    });
  }, []);

  // Update the messages effect to be more specific
  useEffect(() => {
    // Only scroll if this is a new message being added
    const isNewMessage = messages.length > 0 && 
      (messages[messages.length - 1].isLoading || 
       !messages[messages.length - 1].timestamp);
    
    if (isNewMessage) {
      scrollToBottom();
    }
  }, [messages, scrollToBottom]);

  const [resetTitleKey, setResetTitleKey] = useState(0);

  const [selectedVoiceNotes, setSelectedVoiceNotes] = useState([]);
  const [selectedTextNotes, setSelectedTextNotes] = useState([]);

  // Add this useEffect hook to load the stored voice note when the component mounts
  useEffect(() => {
    const storedVoiceNotes = localStorage.getItem('selectedVoiceNotes');
    if (storedVoiceNotes) {
      setSelectedVoiceNotes(JSON.parse(storedVoiceNotes));
    }

    const storedTextNotes = localStorage.getItem('selectedTextNotes');
    if (storedTextNotes) {
      setSelectedTextNotes(JSON.parse(storedTextNotes));
    }

    const storedTemplate = localStorage.getItem('selectedTemplate');
    if (storedTemplate) {
      setSelectedTemplate(JSON.parse(storedTemplate));
    }
  }, []);

  const handleVoiceNoteSelect = (selectedNotes) => {
    setSelectedVoiceNotes(selectedNotes);
    localStorage.setItem('selectedVoiceNotes', JSON.stringify(selectedNotes));
    setShowVoiceNoteSelector(false);
  };

  const handleRemoveVoiceNote = (noteId) => {
    setSelectedVoiceNotes(prevNotes => prevNotes.filter(note => note._id !== noteId));
    localStorage.setItem('selectedVoiceNotes', JSON.stringify(selectedVoiceNotes.filter(note => note._id !== noteId)));
  };

  const handleTextNoteSelect = (selectedNotes) => {
    // Transform the text notes to match the TextFilePreview format
    const formattedNotes = selectedNotes.map(note => ({
      id: note._id,
      name: note.title,
      content: note.content,
      size: new Blob([note.content]).size,
      lines: note.content.split('\n').length,
      createdAt: note.createdAt
    }));
    
    setSelectedTextNotes(formattedNotes);
    localStorage.setItem('selectedTextNotes', JSON.stringify(formattedNotes));
    setShowTextNoteSelector(false);
  };

  const handleRemoveTextNote = (noteId) => {
    setSelectedTextNotes(prev => prev.filter(note => note.id !== noteId));
    localStorage.setItem('selectedTextNotes', 
      JSON.stringify(selectedTextNotes.filter(note => note.id !== noteId))
    );
  };

  const [isGeneratingTitle, setIsGeneratingTitle] = useState(false);

  // Add these new state variables
  const [isFirstMessage, setIsFirstMessage] = useState(true);
  const [activePersona, setActivePersona] = useState(null);

  // Update handleSelectPersona to set both selectedPersona and activePersona
  const handleSelectPersona = useCallback(async (persona) => {
    setIsLoadingAvatar(true);
    try {
      if (persona && persona._id && !persona.avatarUrl) {
        const url = await fetchAvatarUrl(persona._id);
        persona = { ...persona, avatarUrl: url };
      }
      setSelectedPersona(persona);
      setActivePersona(persona);
      setIsFirstMessage(true);
    } catch (error) {
      console.error('Error fetching avatar URL:', error);
      setSelectedPersona(persona);
      setActivePersona(persona);
      setIsFirstMessage(true);
    } finally {
      setIsLoadingAvatar(false);
    }
  }, [setSelectedPersona]);

  const handleSelectTemplate = async (template) => {
    setSelectedTemplate(template);
    if (currentChatId) {
      try {
        await updateChatTemplate(currentChatId, template);
        // Add this line to update localStorage
        updateLocalStorage({ template });
      } catch (error) {
        console.error('Error updating chat template:', error);
        addNotification('Failed to update template', 'error');
      }
    }
    setShowTemplatePopup(false);
  };

  const handleRemoveTemplate = async () => {
    setSelectedTemplate(null);
    if (currentChatId) {
      try {
        await updateChatTemplate(currentChatId, null);
        // Update local storage to remove the template
        updateLocalStorage({ template: null });
      } catch (error) {
        console.error('Error removing chat template:', error);
        addNotification('Failed to remove template', 'error');
      }
    }
  };

  // Add this helper function to update localStorage
  const updateLocalStorage = (updates) => {
    const chatState = JSON.parse(localStorage.getItem('chatState') || '{}');
    Object.assign(chatState, updates);
    localStorage.setItem('chatState', JSON.stringify(chatState));
  };

  // Update the handleSelectAgent function
  const handleSelectAgent = (agent) => {
    setSelectedAgent(agent);
    setShowAIAgentsList(false);
  };

  const [selectedContentStyle, setSelectedContentStyle] = useState(null);

  const [chatMode, setChatMode] = useState(() => {
    // Try to get saved mode from localStorage, default to 'post' if not found
    return localStorage.getItem('chatInterfaceMode') || 'post';
  });

  const [showModeSelector, setShowModeSelector] = useState(false);

  const handleModeSelect = async (mode) => {
    if (mode === chatMode) return;

    // If there are unsaved changes, confirm before switching
    if (messages.length > 0) {
      if (!window.confirm('Switching modes will start a new chat. Continue?')) {
        return;
      }
      handleNewChat();
    }

    setChatMode(mode);
    localStorage.setItem('chatInterfaceMode', mode);
  };

  const handleSendMessage = async (message, formData) => {
    if (!message && !formData && !input.trim() && 
        selectedVoiceNotes.length === 0 && 
        selectedTextNotes.length === 0 && 
        attachedFiles.length === 0 && 
        !pastedText) return;

    const currentInput = message || input.trim();
    setInput('');

    // Clear attachments immediately
    const currentAttachments = {
      voiceNotes: [...selectedVoiceNotes],
      textNotes: [...selectedTextNotes],
      attachedFiles: [...attachedFiles],
      pastedText: pastedText
    };

    // Clear all attachments and related states immediately
    setSelectedVoiceNotes([]);
    setSelectedTextNotes([]);
    setAttachedFiles([]);
    setPastedText(null);
    setLoadingFiles({});

    // Clear localStorage for attachments
    localStorage.removeItem('selectedVoiceNotes');
    localStorage.removeItem('selectedTextNotes');

    try {
      let chatId = currentChatId;
      if (!chatId) {
        const newChat = await createNewChat(chatTitle || 'Untitled Chat', selectedModel);
        chatId = newChat.chatId;
        setCurrentChatId(chatId);
        setIsNewChat(false);
        
        // Update URL without triggering a re-render
        window.history.pushState(null, '', `/chat/${chatId}`);
      }

      const userMessage = {
        content: currentInput,
        role: 'human',
        timestamp: new Date().toISOString(),
        previewedContent: {
          voiceNotes: currentAttachments.voiceNotes.length > 0 ? currentAttachments.voiceNotes.map(note => ({
            title: note.title,
            transcription: note.transcription,
            size: new Blob([note.transcription]).size,
            lines: note.transcription.split('\n').length,
            type: 'voice-note'
          })) : null,
          textNotes: currentAttachments.textNotes.length > 0 ? currentAttachments.textNotes.map(note => ({
            name: note.name,
            content: note.content,
            size: note.size,
            lines: note.lines,
            type: 'text-note'
          })) : null,
          attachedFiles: currentAttachments.attachedFiles.length > 0 ? currentAttachments.attachedFiles.map(file => ({
            name: file.name,
            content: file.text || file.content,
            size: file.size,
            lines: file.lines,
            type: 'file'
          })) : null,
          pastedText: currentAttachments.pastedText ? {
            content: currentAttachments.pastedText.content,
            size: currentAttachments.pastedText.size,
            lines: currentAttachments.pastedText.lines,
            type: 'pasted'
          } : null
        }
      };

      setMessages(prev => [...prev, userMessage]);
      setMessages(prev => [...prev, {
        content: '',
        role: 'assistant',
        isLoading: true
      }]);

      setIsLoading(true);
      setIsAITyping(true);

      let aiMessageContent = currentInput;

      if (currentAttachments.voiceNotes.length > 0) {
        currentAttachments.voiceNotes.forEach(note => {
          aiMessageContent += `\n\n*****Voice Note (${note.title}):\n${note.transcription}*****`;
        });
      }

      if (currentAttachments.textNotes.length > 0) {
        currentAttachments.textNotes.forEach(note => {
          aiMessageContent += `\n\n*****Text Note (${note.name}):\n${note.content}*****`;
        });
      }

      if (currentAttachments.attachedFiles.length > 0) {
        currentAttachments.attachedFiles.forEach(file => {
          const contentToAdd = file.content.startsWith('*****') 
            ? file.content 
            : `*****File (${file.name}):\n${file.content}*****`;
          aiMessageContent += `\n\n${contentToAdd}`;
        });
      }

      if (currentAttachments.pastedText) {
        aiMessageContent += `\n\n*****Pasted Content:\n${currentAttachments.pastedText.content}*****`;
      }

      // Use appropriate service based on chat mode
      const aiResponse = await (chatMode === 'post' 
        ? (selectedModel.toLowerCase().includes('gpt') || selectedModel.toLowerCase().includes('grok')
          ? sendChatMessageOpenAI 
          : sendChatMessage)
        : sendChatMessageForDecks)(
        selectedModel,
        aiMessageContent,
        chatId,
        selectedPersona,
        selectedTemplate,
        selectedAgent,
        selectedTargetAudience,
        selectedContentStyle
      );

      if (!aiResponse) {
        throw new Error('No response received from AI service');
      }

      setMessages(prev => {
        const newMessages = prev.slice(0, -1);
        const aiMessageContent = aiResponse.message?.content || 
                               aiResponse.content || 
                               aiResponse.message || 
                               'No response content available';

        const aiMessage = {
          content: aiMessageContent,
          role: 'assistant',
          timestamp: new Date().toISOString(),
          id: aiResponse.messageId || aiResponse.id || Date.now().toString(),
          templateTag: selectedTemplate?.title || null
        };

        return [...newMessages, aiMessage];
      });

      setInput('');

      const updatedMessages = messages.concat([userMessage, {
        content: aiResponse.message?.content || aiResponse.content || aiResponse.message,
        role: 'assistant',
        timestamp: new Date().toISOString(),
        id: aiResponse.messageId || aiResponse.id || Date.now().toString(),
        templateTag: selectedTemplate?.title || null
      }]);

      if (updatedMessages.length === 2 && chatId) {
        try {
          setIsGeneratingTitle(true);
          const generatedTitle = await generateChatTitle(updatedMessages);
          if (generatedTitle) {
            await handleTitleChange(generatedTitle);
          }
        } catch (titleError) {
          console.error('Error generating title:', titleError);
        } finally {
          setIsGeneratingTitle(false);
        }
      }

      saveChatToLocalStorage(
        chatId,
        chatTitle,
        updatedMessages,
        selectedTemplate,
        true
      );

      scrollToBottom();
    } catch (error) {
      console.error('Error in handleSendMessage:', error);
      addNotification(error.message || 'Failed to send message. Please try again.', 'error');
      setMessages(prev => prev.slice(0, -1));
    } finally {
      setIsLoading(false);
      setIsAITyping(false);
    }
  };

  const createNewChatIfNeeded = async (title) => {
      if (!currentChatId) {
      try {
        const response = await createNewChat(title || 'Untitled Chat', selectedModel);
        setCurrentChatId(response.chatId);
        localStorage.setItem('currentChatId', response.chatId);
        setIsNewChat(false);
        return response.chatId;
      } catch (error) {
        console.error('Error creating new chat:', error);
        addNotification('Failed to create a new chat. Please try again.', 'error');
        throw error;
      }
    }
    return currentChatId;
  };

  const handleTitleChange = async (newTitle) => {
    try {
      if (currentChatId) {
        // Update existing chat title
        await updateChatTitle(currentChatId, newTitle);
        setChatTitle(newTitle);
        setHasBeenEdited(true);
        
        // Save the updated chat to local storage
        saveChatToLocalStorage(currentChatId, newTitle, messages, selectedTemplate, true);
      } else {
        // If there's no currentChatId, just update the local title
        setChatTitle(newTitle);
        setHasBeenEdited(true);
      }
    } catch (error) {
      console.error('Error changing chat title:', error);
      addNotification('Failed to update chat title. Please try again.', 'error');
    }
  };

  const handleCreateNewChat = async (newTitle) => {
    try {
      const newChat = await createNewChat(newTitle, selectedModel);
      setCurrentChatId(newChat.chatId);
      setChatTitle(newTitle);
      setMessages([]);
      setIsNewChat(false);
      setHasBeenEdited(false); // Set to false for a new chat
      saveChatToLocalStorage(newChat.chatId, newTitle, [], selectedTemplate, false);
    } catch (error) {
      console.error('Error creating new chat:', error);
      addNotification('Failed to create new chat. Please try again.', 'error');
    }
  };

  const debouncedSaveTitle = useCallback(
    debounce(async () => {
      if (editedTitle.trim() === '' || editedTitle.trim() === chatTitle) {
        return;
      }
      setIsSaving(true);
      setSaveError(null);
      try {
        const updatedChat = await updateChatTitle(currentChatId, editedTitle);
        setChatTitle(updatedChat.title);
      } catch (error) {
        console.error('Error saving chat title:', error);
        setSaveError('Failed to save title. Please try again.');
      } finally {
        setIsSaving(false);
      }
    }, 500),
    [editedTitle, chatTitle, currentChatId]
  );

  const [showAddKnowledge, setShowAddKnowledge] = useState(false);
  const addKnowledgeRef = useRef(null);

  const [showAIModel, setShowAIModel] = useState(false);
  const aiModelRef = useRef(null);

  const [showAgent, setShowAgent] = useState(false);
  const [showExpandedAgents, setShowExpandedAgents] = useState(false);
  const agentRef = useRef(null);

  const [showFileUpload, setShowFileUpload] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const fileUploadRef = useRef(null);

  const { addFileToChat, removeFileFromChat } = useChat();

  const handleFileUpload = async (processedFiles) => {
    try {
      // Add each processed file to the attachedFiles state
      setAttachedFiles(prevFiles => [...prevFiles, ...processedFiles]);

      // Add loading state for each file
      const newLoadingState = {};
      processedFiles.forEach(file => {
        newLoadingState[file.id] = false;
      });
      setLoadingFiles(prev => ({ ...prev, ...newLoadingState }));
    } catch (error) {
      console.error('Error handling file upload:', error);
      addNotification('Failed to process uploaded files', 'error');
    }
  };

  const removeUploadedFile = (index) => {
    setUploadedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (fileUploadRef.current && !fileUploadRef.current.contains(event.target)) {
        setShowFileUpload(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (addKnowledgeRef.current && !addKnowledgeRef.current.contains(event.target)) {
        setShowAddKnowledge(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (aiModelRef.current && !aiModelRef.current.contains(event.target)) {
        setShowAIModel(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (agentRef.current && !agentRef.current.contains(event.target)) {
        setShowAgent(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Update the handleSelectModel function
  const handleSelectModel = (model) => {
    setSelectedModel(model.id);
    localStorage.setItem('selectedAIModel', model.id);
    setShowModelSelector(false);
  };

  const textareaRef = useRef(null);

  // Update the adjustTextareaHeight function
  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      // Reset height to auto first to get the correct scrollHeight
      textarea.style.height = 'auto';
      // Set a maximum height of 200px (you can adjust this value)
      const maxHeight = 200;
      // Get the scroll height but cap it at maxHeight
      const newHeight = Math.min(textarea.scrollHeight, maxHeight);
      textarea.style.height = `${newHeight}px`;
      // Add scrollbar if content exceeds maxHeight
      textarea.style.overflowY = textarea.scrollHeight > maxHeight ? 'auto' : 'hidden';
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [input]);

  const [isTitleEditing, setIsTitleEditing] = useState(false);
  const [canGenerateTitle, setCanGenerateTitle] = useState(false);

  const titleInputRef = useRef(null);

  useEffect(() => {
    // Check if there's enough content to generate a title
    setCanGenerateTitle(messages.length >= 3);
  }, [messages]);

  const handleTitleEdit = () => {
    setIsTitleEditing(true);
    setTimeout(() => titleInputRef.current?.focus(), 0);
  };

  const handleTitleSave = async () => {
    setIsTitleEditing(false);
    if (currentChatId) {
      try {
        await axios.put(`/api/chat/${currentChatId}`, { title: chatTitle });
        // Refresh the chat list after successful save
        // await fetchChatHistory();
      } catch (error) {
        console.error('Error updating chat title:', error);
        addNotification('Failed to update chat title', 'error');
      }
    }
  };

  const handleTitleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleTitleSave();
    }
  };

  const generateTitle = async () => {
    if (!currentChatId || messages.length === 0) return;

    setIsGeneratingTitle(true);
    try {
      // Use the first exchange to generate the title
      const titleContext = messages.slice(0, 2);
      const generatedTitle = await generateChatTitle(titleContext);
      
      if (generatedTitle) {
        await handleTitleChange(generatedTitle);
      }
    } catch (error) {
      console.error('Error generating title:', error);
      addNotification('Failed to generate title', 'error');
    } finally {
      setIsGeneratingTitle(false);
    }
  };

  const handleSaveTitle = async (newTitle) => {
    console.log('Attempting to save title:', newTitle);
    if (!currentChatId) {
      console.error('No currentChatId available');
      throw new Error('No chat selected');
    }
    try {
      const updatedChat = await updateChatTitle(currentChatId, newTitle);
      setChatTitle(updatedChat.title);
      console.log('Title saved successfully');

      // Update the chat state in local storage
      const chatState = JSON.parse(localStorage.getItem('chatState') || '{}');
      chatState.title = newTitle;
      localStorage.setItem('chatState', JSON.stringify(chatState));
    } catch (error) {
      console.error('Error saving chat title:', error);
      throw error;
    }
  };

  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const [showTemplatePopup, setShowTemplatePopup] = useState(false);
  const [templates, setTemplates] = useState([]);

  const handleSaveToTextNotes = useCallback(async (content) => {
    try {
      let title, noteContent, tags;

      if (typeof content === 'string') {
        // This is a code block
        title = `${chatTitle} - ${new Date().toLocaleString()}`;
        noteContent = `\`\`\`\n${content}\n\`\`\``;
        tags = ['chat', 'code'];
      } else {
        // This is a message
        title = `${chatTitle} - ${new Date().toLocaleString()}`;
        noteContent = content.content;
        tags = ['chat'];
      }

      const newNote = await addNote({
        title: title,
        content: noteContent,
        tags: tags,
      });
      addNotification('Content saved to Text Notes', 'success');
      
      // Navigate to the TextNotes component and open the newly created note
      navigate('/posts-library', { state: { openNoteId: newNote._id } });
    } catch (error) {
      console.error('Error saving content to Text Notes:', error);
      addNotification('Failed to save content to Text Notes', 'error');
    }
  }, [addNote, addNotification, chatTitle, navigate]);

  const handleMessageHover = (index) => {
    setHoveredMessageIndex(index);
  };

  const messageVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -50 }
  };

  const renderMessage = (message, index) => {
    if (message.isLoading) {
      return (
        <TypingAnimation 
          key={`loading-${index}`} 
          selectedPersona={selectedPersona}
          selectedTargetAudience={selectedTargetAudience}
          selectedTemplate={selectedTemplate}
          selectedVoiceNotes={selectedVoiceNotes}
          selectedTextNotes={selectedTextNotes}
          selectedAgent={selectedAgent}
          onComplete={() => {
            // Only mark as not loading if we haven't received the real response yet
            setMessages(prevMessages => 
              prevMessages.map((msg, i) => 
                i === index && msg.isLoading && !msg.content 
                  ? { ...msg, isLoading: true } // Keep it loading if no content
                  : msg
              )
            );
          }}
        />
      );
    }

    const isUserMessage = message.role === 'human';

    return (
      <motion.div
        key={message.id || `${index}-${message.timestamp}`}
        variants={messageVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.3 }}
      >
        {isUserMessage ? (
          <UserMessageChat
            message={message}
            index={index}
            isDarkMode={isDarkMode}
            copiedMessages={copiedMessages}
            onCopy={copyToClipboard}
            onSave={handleSaveMessage}
            onSaveToTextNotes={handleSaveToTextNotes}
            onPreviewText={handlePreviewText}
            onPreviewVoiceNote={handlePreviewVoiceNote}
            onPreviewTextNote={handlePreviewTextNote}
            onPreviewFile={handlePreviewFile}
            voiceNotes={message.voiceNotes}
            isAutoScrolling={isAutoScrolling}
          />
        ) : (
          <AIResponseChat
            message={message}
            index={index}
            isDarkMode={isDarkMode}
            copiedMessages={copiedMessages}
            onCopy={copyToClipboard}
            onSave={handleSaveMessage}
            onSaveToTextNotes={handleSaveToTextNotes}
            onPreviewText={handlePreviewText}
            onPreviewVoiceNote={handlePreviewVoiceNote}
            onPreviewTextNote={handlePreviewTextNote}
            onManagePosts={(codeBlocks) => {
              setLinkedInPosts(codeBlocks);
              setShowLinkedInPopup(true);
            }}
            personaAvatarUrl={personaAvatarUrl}
            personaName={activePersona ? activePersona.name : 'AI Assistant'}
            personaDetails={activePersona}
            logo={logo}
            addNotification={addNotification}
            isAutoScrolling={isAutoScrolling}
          />
        )}
      </motion.div>
    );
  };

  const handlePromptClick = (prompt) => {
    setInput(prompt);
    chatInputRef.current?.focus();
  };

  const [showAgentCreation, setShowAgentCreation] = useState(false);
  const [agents, setAgents] = useState([]);

  const handleCreateAgent = async (agentData) => {
    try {
      const newAgent = await createAIAgent(agentData);
      console.log('New agent created:', newAgent);
      setShowAgentCreation(false);
      // After successfully creating the agent, update the list of available agents
      setAgents(prevAgents => [...prevAgents, newAgent]);
      setSelectedAgent(newAgent.name); // Automatically select the newly created agent
      addNotification('Agent created successfully', 'success');
    } catch (error) {
      console.error('Error creating agent:', error);
      addNotification('Failed to create agent', 'error');
    }
  };

  useEffect(() => {
    const fetchInitialAgents = async () => {
      try {
        const initialAgents = await fetchAIAgents();
        setAgents(initialAgents);
      } catch (error) {
        console.error('Error fetching initial agents:', error);
        addNotification('Failed to fetch agents', 'error');
      }
    };

    fetchInitialAgents();
  }, [addNotification]);

  const [showAIAgentsList, setShowAIAgentsList] = useState(false);

  // Step 2: Use the useTheme hook to access isDarkMode
  const { isDarkMode } = useTheme();

  const [showVoiceNoteSelector, setShowVoiceNoteSelector] = useState(false);

  const { notes } = useContext(NotesContext);

  // Add this line near the top of the component, where other state variables are defined
  const [showTextNoteSelector, setShowTextNoteSelector] = useState(false);

  // Update the getButtonStyle function
  const getButtonStyle = (isSelected = false) => `
    flex items-center px-2 py-1.5 text-xs font-medium rounded-md transition-all duration-200
    ${isSelected
      ? isDarkMode
        ? 'text-blue-400 bg-slate-700 border border-blue-500 hover:bg-slate-600'
        : 'text-blue-600 bg-blue-50 border border-blue-300 hover:bg-blue-100'
      : isDarkMode
        ? 'bg-slate-600 text-slate-200 hover:bg-slate-500' 
        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
    }
  `;

  const [showKnowledgeBase, setShowKnowledgeBase] = useState(false);

  const showToast = useCallback((message, type) => {
    // If you're using a custom toast component:
    showCustomToast(message, type);
    // If you're using a library like react-toastify:
    // toast[type](message);
  }, []);

  const [attachedFiles, setAttachedFiles] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState({});

  // Update the handleFileSelect function
  const handleFileSelect = useCallback(async (items) => {
    try {
      console.log('Selected files:', items);
      
      for (const item of items) {
        if (!item._id) {
          console.error('File missing ID:', item);
          continue;
        }

        // Create a unique loading ID for this file
        const loadingId = `loading-${item._id}`;

        // Add file with loading state
        const newFile = {
          id: item._id,
          name: item.name,
          type: item.type,
          size: item.size,
          url: null,
          text: null,
          isLoading: true,
        };

        setAttachedFiles(prev => [...prev, newFile]);
        setLoadingFiles(prev => ({ ...prev, [item._id]: true }));

        try {
          console.log('Downloading file with ID:', item._id);
          const blob = await downloadFile(item._id);
          
          if (!blob) {
            throw new Error('No data received from download');
          }

          const url = window.URL.createObjectURL(blob);
          const fileObj = new File([blob], item.name, { type: item.type });
          const fileContent = await convertFileToText(fileObj);

          if (fileContent.startsWith('[Error processing file:')) {
            throw new Error('Could not process file content');
          }

          // Wrap the content with stars and type identifier
          const wrappedContent = `*****File (${item.name}):\n${fileContent}*****`;

          // Update file with content and remove loading state
          setAttachedFiles(prev => prev.map(file => 
            file.id === item._id 
              ? { 
                  ...file, 
                  url,
                  content: wrappedContent, // Use wrapped content
                  text: fileContent, // Keep original text for other purposes
                  size: new Blob([fileContent]).size,
                  lines: fileContent.split('\n').length,
                  createdAt: new Date(),
                  isLoading: false,
                } 
              : file
          ));
          
          setLoadingFiles(prev => ({ ...prev, [item._id]: false }));
          addNotification('File added to chat successfully', 'success');
        } catch (downloadError) {
          console.error('Error downloading file:', downloadError);
          setAttachedFiles(prev => prev.filter(file => file.id !== item._id));
          setLoadingFiles(prev => {
            const newState = { ...prev };
            delete newState[item._id];
            return newState;
          });
          throw downloadError;
        }
      }
    } catch (error) {
      console.error('Error handling file selection:', error);
      addNotification(`Error adding file to chat: ${error.message}. Please try again.`, 'error');
    }
  }, [addNotification, downloadFile]);

  const handleRemoveFile = (fileId) => {
    setAttachedFiles(prev => prev.filter(file => file.id !== fileId));
    setLoadingFiles(prev => {
      const newState = { ...prev };
      delete newState[fileId];
      return newState;
    });
  };

  const [showAddContextMenu, setShowAddContextMenu] = useState(false);
  const addContextRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (addContextRef.current && !addContextRef.current.contains(event.target)) {
        setShowAddContextMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handlePersonaSelect = (persona) => {
    const personaInfo = formatPersonaInfo(persona);

    setInput((prevInput) => {
      return prevInput.startsWith('[Persona Information:') 
        ? personaInfo + prevInput.split(']')[1].trim() 
        : personaInfo + prevInput;
    });
    
    setSelectedPersona(persona);
  };

  const formatPersonaInfo = (persona) => {
    const fields = [
      'name', 'age', 'gender', 'genderCustom', 'occupation', 'temperament', 'temperamentCustom',
      'communicationStyle', 'communicationStyleCustom', 'emotionalDisposition', 'emotionalDispositionCustom',
      'interests', 'dislikes', 'personalHistory', 'culturalBackground', 'languageProficiency',
      'languageProficiencyCustom', 'slangOrJargon', 'shortTermGoals', 'longTermAspirations',
      'toneOfVoice', 'formalityLevel', 'formalityLevelCustom', 'responseTime', 'responseTimeCustom'
    ];

    return fields.reduce((acc, field) => {
      if (persona[field]) {
        const value = Array.isArray(persona[field]) ? persona[field].join(', ') : persona[field];
        acc += `${field.charAt(0).toUpperCase() + field.slice(1)}: ${value}\n`;
      }
      return acc;
    }, '[Persona Information:\n') + ']';
  };

  // Add this function near the other handler functions
  const handleRemoveActivePersona = () => {
    setActivePersona(null);
    setSelectedPersona(null);
    setIsFirstMessage(true); // Reset to allow selecting a new persona for the next message
  };

  // Add these state variables
  const [personaAvatarUrl, setPersonaAvatarUrl] = useState(null);

  // Add this useEffect to fetch the avatar URL when a persona is selected
  useEffect(() => {
    const loadPersonaAvatar = async () => {
      if (selectedPersona && selectedPersona._id) {
        const url = await fetchAvatarUrl(selectedPersona._id);
        setPersonaAvatarUrl(url);
      } else {
        setPersonaAvatarUrl(null);
      }
    };

    loadPersonaAvatar();
  }, [selectedPersona]);

  // Update the saveChatToLocalStorage function
  const saveChatToLocalStorage = (chatId, title, messages, template, hasBeenEdited) => {
    if (!chatId) return;
    
    const chatState = {
      currentChatId: chatId,
      title: title,
      messages: messages.map(msg => ({
        ...msg,
        previewedContent: msg.previewedContent ? {
          voiceNotes: msg.previewedContent.voiceNotes || null,
          textNotes: msg.previewedContent.textNotes || null,
          attachedFiles: msg.previewedContent.attachedFiles || null,
          pastedText: msg.previewedContent.pastedText || null
        } : null
      })),
      template: template,
      hasBeenEdited: hasBeenEdited,
      lastAccessed: new Date().toISOString(),
      selectedModel: selectedModel, // Add this line
      selectedPersona: selectedPersona, // Add this line
      selectedTargetAudience: selectedTargetAudience // Add this line
    };
    
    try {
      localStorage.setItem('currentChat', JSON.stringify(chatState));
      // Also store the chat ID separately for quick access
      localStorage.setItem('lastActiveChatId', chatId);
    } catch (error) {
      console.error('Error saving chat to localStorage:', error);
      addNotification('Failed to save chat state', 'error');
    }
  };

  // Update the loadChatFromLocalStorage function
  const loadChatFromLocalStorage = () => {
    const savedChat = localStorage.getItem('currentChat');
    if (!savedChat) return null;

    try {
      const parsedChat = JSON.parse(savedChat);
      
      // Ensure previewedContent is properly restored for each message
      if (parsedChat.messages) {
        parsedChat.messages = parsedChat.messages.map(msg => ({
          ...msg,
          previewedContent: msg.previewedContent ? {
            voiceNotes: msg.previewedContent.voiceNotes || null,
            textNotes: msg.previewedContent.textNotes || null,
            attachedFiles: msg.previewedContent.attachedFiles || null,
            pastedText: msg.previewedContent.pastedText || null
          } : null
        }));
      }

      return parsedChat;
    } catch (error) {
      console.error('Error parsing saved chat:', error);
      return null;
    }
  };

  // Add this new state declaration
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  // Add this near the top of the file with other state declarationsut
  const [selectedTargetAudience, setSelectedTargetAudience] = useState(null);

  // Add this useEffect to load the stored target audience when the component mounts
  useEffect(() => {
    const storedTargetAudience = localStorage.getItem('selectedTargetAudience');
    if (storedTargetAudience) {
      setSelectedTargetAudience(JSON.parse(storedTargetAudience));
    }
  }, []);

  const handleSelectTargetAudience = (audience) => {
    setSelectedTargetAudience(audience);
    localStorage.setItem('selectedTargetAudience', JSON.stringify(audience));
    // You might want to add some logic here to update the chat context
    // or send this information to the backend
    console.log('Selected target audience:', audience);
  };

  // Add this state near other state declarations
  // const [showHistorySavedItems, setShowHistorySavedItems] = useState(false);

  // Add this state for managing the button ref
  // const [menuButtonRef, setMenuButtonRef] = useState(null);

  const [isLoadingAvatar, setIsLoadingAvatar] = useState(false);

  // Add this new state for managing full-screen mode
  const [isFullScreen, setIsFullScreen] = useState(false);

  // Add this function to toggle full-screen mode
  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  // Add this to the existing state declarations
  const [showChatHistory, setShowChatHistory] = useState(false);

  // Add this state near other state declarations
  const [isChatCollapsed, setIsChatCollapsed] = useState(() => {
    // Get the initial state from localStorage, default to false if not set
    return localStorage.getItem('isChatCollapsed') === 'true';
  });

  // Add this useEffect to persist the collapsed state
  useEffect(() => {
    localStorage.setItem('isChatCollapsed', isChatCollapsed);
  }, [isChatCollapsed]);

  // Update the useEffect that handles URL changes
  useEffect(() => {
    const handleUrlChange = async () => {
      const pathParts = window.location.pathname.split('/');
      const urlChatId = pathParts[pathParts.length - 1];
      
      // Only load if it's a valid chat ID and different from current
      if (urlChatId && urlChatId !== 'chat' && urlChatId !== currentChatId) {
        setIsLoading(true);
        try {
          const chatData = await loadChat(urlChatId);
          setCurrentChatId(urlChatId);
          setChatTitle(chatData.title);
          setMessages(chatData.messages);
          setIsNewChat(false);
          setSelectedTemplate(chatData.template || null);
          setHasBeenEdited(true);
          
          saveChatToLocalStorage(
            urlChatId, 
            chatData.title, 
            chatData.messages, 
            chatData.template, 
            true
          );
        } catch (error) {
          console.error('Error loading chat from URL:', error);
          handleLoadChatError(error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    // Handle both initial load and navigation events
    handleUrlChange();
    window.addEventListener('popstate', handleUrlChange);
    
    return () => {
      window.removeEventListener('popstate', handleUrlChange);
    };
  }, [currentChatId]);

  // Update the handleChatSelect function to use navigate
  const handleChatSelect = useCallback(async (chatId) => {
    if (!chatId || chatId === currentChatId) return;

    // Use navigate to update URL - this will trigger the above useEffect
    navigate(`/chat/${chatId}`);
  }, [currentChatId, navigate]);

  // Add this helper function to clear chat state
  const clearChatState = useCallback(() => {
    setMessages([]);
    setInput('');
    setCurrentChatId(null);
    setChatTitle('Untitled Chat');
    setIsNewChat(true);
    setSelectedTemplate(null);
    setHasBeenEdited(false);
    setSelectedVoiceNotes([]);
    setSelectedTextNotes([]);
    setAttachedFiles([]);
    setLoadingFiles({});
    localStorage.removeItem('currentChat');
  }, []);

  // Add this with other state declarations
  const [showYouIdea, setShowYouIdea] = useState(false);

  // Add this new state near other state declarations
  const [showPostComments, setShowPostComments] = useState(false);

  const { setContext } = usePostComments();

  useEffect(() => {
    if (showPostComments) {
      setContext(true);
    }
    return () => setContext(false);
  }, [showPostComments, setContext]);

  // Add this near the top of the component with other state declarations
  const [isMobile, setIsMobile] = useState(false);

  // Add this useEffect to detect mobile devices
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768); // You can adjust this breakpoint
    };

    // Check initially
    checkMobile();

    // Add event listener for window resize
    window.addEventListener('resize', checkMobile);

    // Cleanup
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Add this new state near other state declarations
  const [showContentStyles, setShowContentStyles] = useState(false);

  // Add this function to handle text area focus
  const handleTextAreaFocus = () => {
    setShowContentStyles(true);
  };

  // Add this function to handle text area blur
  const handleTextAreaBlur = () => {
    // Small delay to allow for style selection
    setTimeout(() => {
      setShowContentStyles(false);
    }, 200);
  };

  // Add these to your existing state declarations
  const [editingPersona, setEditingPersona] = useState(null);

  // Add this handler function
  const handleEditPersona = (persona) => {
    setEditingPersona(persona);
  };

  const handleSavePersona = async (updatedPersona) => {
    try {
      // Update the selected persona if it's the one being edited
      if (selectedPersona && selectedPersona._id === updatedPersona._id) {
        setSelectedPersona(updatedPersona);
      }
      setEditingPersona(null);
      // Optionally show a success notification
      addNotification('Profile Updated', 'Profile has been successfully updated.', 'success');
    } catch (error) {
      console.error('Error saving persona:', error);
      addNotification('Update Failed', 'Failed to update profile. Please try again.', 'error');
    }
  };

  // Add this near other state declarations
  const [pastedText, setPastedText] = useState(null);

  // Add this function near other handler functions
  const handlePaste = (e) => {
    const text = e.clipboardData.getData('text');
    if (text.length > 1000) {
      e.preventDefault(); // Prevent the paste from affecting the input
      const lines = text.split('\n');
      setPastedText({
        content: text,
        name: 'Pasted text',
        size: new Blob([text]).size,
        lines: lines.length,
        preview: lines.slice(0, 3).join('\n')
      });
      // Remove this line to preserve the existing input
      // setInput('');
    }
    // If text is shorter than 1000 chars, let the default paste behavior happen
  };

  // Add this to your existing state declarations
  const [previewData, setPreviewData] = useState(null);

  // Add this handler function
  const handlePreviewText = (data) => {
    console.log('handlePreviewText called with:', data);
    setPreviewData(data);
  };

  const [previewVoiceNote, setPreviewVoiceNote] = useState(null);

  const handlePreviewVoiceNote = (voiceNote) => {
    setPreviewVoiceNote(voiceNote);
  };

  // Add this new state for text note preview
  const [previewTextNote, setPreviewTextNote] = useState(null);

  // Add this handler function
  const handlePreviewTextNote = (textNote) => {
    setPreviewTextNote(textNote);
  };

  // Add this state for file preview
  const [previewFile, setPreviewFile] = useState(null);

  // Add this handler function
  const handlePreviewFile = (file) => {
    setPreviewFile(file);
  };

  // Add this useEffect to handle chat collapse
  useEffect(() => {
    if (isChatCollapsed) {
      // Close any open popovers when chat is collapsed
      setShowFileUpload(false);
      setShowVoiceNoteSelector(false);
      setShowTextNoteSelector(false);
      setShowKnowledgeBase(false);
      setShowAddKnowledge(false);
      setShowAIModel(false);
      setShowAgent(false);
      setShowTemplatePopup(false);
      setShowAIAgentsList(false);
      setShowPostComments(false);
    }
  }, [isChatCollapsed]);

  // Add this handler for double-click
  const handleResetWidth = useCallback(() => {
    setChatWidth(DEFAULT_CHAT_WIDTH);
    debouncedSaveWidth(DEFAULT_CHAT_WIDTH);
  }, [debouncedSaveWidth]);

  // Add this useEffect to handle click outside for model selector
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modelSelectorRef.current && !modelSelectorRef.current.contains(event.target)) {
        setShowModelSelector(false);
      }
      if (chatModeSelectorRef.current && !chatModeSelectorRef.current.contains(event.target)) {
        setShowChatModeSelector(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Add these new state variables
  const [activeScreen, setActiveScreen] = useState(null);
  const [recordingState, setRecordingState] = useState({
    isRecording: false,
    isPaused: false,
    recordingTime: 0,
    onStop: null,
    onPause: null,
    onResume: null,
    onDiscard: null
  });

  // Add this handler function
  const handleWelcomeScreenAction = (actionId) => {
    if (actionId === 'voice') {
      setActiveScreen('voice');
    } else if (actionId === 'text') {
      setActiveScreen('text');
    }
  };

  // Add this state
  const [showYouIdeaSelector, setShowYouIdeaSelector] = useState(false);
  const [selectedYouIdea, setSelectedYouIdea] = useState(null);

  // Update this handler
  const handleYouIdeaSelect = (formattedText) => {
    if (!formattedText) return;

    // Clean up the formatted text
    const cleanedText = formattedText
      .replace(/^[-•*\d\.\s]+/gm, '• ') // Replace any bullet points with a consistent format
      .replace(/^💡\s*Content Angles:?/gm, '') // Remove Content Angles label
      .replace(/^Why:\s*/gm, '') // Remove Why: prefix
      .trim();

    // Set the formatted text in the input
    setInput(prevInput => {
      const newInput = prevInput.trim() 
        ? `${prevInput.trim()}\n\n${cleanedText}` 
        : cleanedText;
      
      // Ensure proper spacing and formatting
      return newInput
        .split('\n')
        .map(line => line.trim())
        .filter(line => line) // Remove empty lines
        .join('\n');
    });

    // Close the selector
    setShowYouIdeaSelector(false);
  };

  // Add this function to handle opening the selector
  const handleOpenYouIdeaSelector = () => {
    setShowYouIdeaSelector(true);
  };

  // Add this function to handle closing the selector
  const handleCloseYouIdeaSelector = () => {
    setShowYouIdeaSelector(false);
  };

  const handleRetry = useCallback(async (errorMessage, index) => {
    // Find the last user message before the error
    const messageHistory = messages.slice(0, index);
    const lastUserMessage = [...messageHistory].reverse()
      .find(msg => msg.role === 'user' || msg.role === 'system');

    if (lastUserMessage) {
      // Remove the error message
      const newMessages = messages.slice(0, index);
      setMessages(newMessages);
      
      // Show loading state
      setIsLoading(true);
      
      try {
        // Determine which service to use based on the selected model
        const sendMessageFn = selectedModel.toLowerCase().includes('gpt') || 
                            selectedModel.toLowerCase().includes('grok')
          ? sendChatMessageOpenAI 
          : sendChatMessage;

        // Resend the last user message
        const response = await sendMessageFn(
          selectedModel,
          lastUserMessage.content,
          currentChatId,
          selectedPersona,
          selectedTemplate,
          selectedAgent,
          selectedTargetAudience,
          selectedContentStyle
        );
        
        // Add the new response to messages
        setMessages(prevMessages => [...prevMessages, {
          role: 'assistant',
          content: response.content || response.message?.content,
          id: Date.now(),
          timestamp: new Date().toISOString()
        }]);
      } catch (error) {
        console.error('Error retrying message:', error);
        setMessages(prevMessages => [...prevMessages, {
          role: 'assistant',
          content: error.message || 'Failed to retry message',
          isError: true,
          id: Date.now()
        }]);
      } finally {
        setIsLoading(false);
      }
    } else {
      console.warn('No previous user message found to retry');
    }
  }, [messages, selectedModel, currentChatId, selectedPersona, selectedTemplate, selectedAgent, selectedTargetAudience, selectedContentStyle]);

  // Update the initializeChat function in the existing useEffect
  useEffect(() => {
    const initializeChat = async () => {
      try {
        // Get the chat ID from the URL
        const pathParts = window.location.pathname.split('/');
        const urlChatId = pathParts[pathParts.length - 1];
        
        // If we have a valid chat ID in the URL (not just /chat)
        if (urlChatId && urlChatId !== 'chat') {
          console.log('Loading chat from URL:', urlChatId);
          try {
            const chatData = await loadChat(urlChatId);
            setCurrentChatId(urlChatId);
            setChatTitle(chatData.title);
            setMessages(chatData.messages);
            setIsNewChat(false);
            setSelectedTemplate(chatData.template || null);
            setHasBeenEdited(true);
            
            // Save to localStorage with the current URL chat ID
            saveChatToLocalStorage(
              urlChatId, 
              chatData.title, 
              chatData.messages, 
              chatData.template, 
              true
            );
            return;
          } catch (error) {
            console.error('Error loading chat from URL:', error);
            handleLoadChatError(error);
            return;
          }
        } else {
          // If no valid chat ID in URL, try to load from localStorage
          const savedChat = loadChatFromLocalStorage();
          if (savedChat && savedChat.currentChatId) {
            try {
              const chatData = await loadChat(savedChat.currentChatId);
              setCurrentChatId(savedChat.currentChatId);
              setChatTitle(chatData.title);
              setMessages(chatData.messages);
              setIsNewChat(false);
              setSelectedTemplate(chatData.template || null);
              setHasBeenEdited(true);
              
              // Update URL without triggering a re-render
              window.history.replaceState(
                null, 
                '', 
                `/chat/${savedChat.currentChatId}`
              );
            } catch (error) {
              console.error('Error loading saved chat:', error);
              handleLoadChatError(error);
              clearChatState();
            }
          }
        }
      } catch (error) {
        console.error('Error in chat initialization:', error);
        addNotification('Failed to initialize chat', 'error');
        clearChatState();
      }
    };

    initializeChat();
  }, []);  // Empty dependency array for initial mount only

  // Add a new effect to handle URL changes
  useEffect(() => {
    const handleUrlChange = async () => {
      const pathParts = window.location.pathname.split('/');
      const urlChatId = pathParts[pathParts.length - 1];
      
      if (urlChatId && urlChatId !== 'chat' && urlChatId !== currentChatId) {
        try {
          const chatData = await loadChat(urlChatId);
          setCurrentChatId(urlChatId);
          setChatTitle(chatData.title);
          setMessages(chatData.messages);
          setIsNewChat(false);
          setSelectedTemplate(chatData.template || null);
          setHasBeenEdited(true);
        } catch (error) {
          console.error('Error loading chat from URL:', error);
          handleLoadChatError(error);
        }
      }
    };

    // Listen for popstate events (browser back/forward)
    window.addEventListener('popstate', handleUrlChange);
    
    return () => {
      window.removeEventListener('popstate', handleUrlChange);
    };
  }, [currentChatId]);

  // Add these new state variables in the ChatInterface component
  const [showIdeationDropdown, setShowIdeationDropdown] = useState(false);
  const [activeIdeationTool, setActiveIdeationTool] = useState(null);
  const ideateButtonRef = useRef(null);

  // Add this new handler function
  const handleIdeationSelect = (tool) => {
    setShowIdeationDropdown(false);
    setActiveIdeationTool(tool);
  };

  // Add this effect to handle clicks outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ideateButtonRef.current && !ideateButtonRef.current.contains(event.target)) {
        setShowIdeationDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Update the useEffect that checks for title generation
  useEffect(() => {
    // Check if we should generate a title (first message exchange complete)
    if (messages.length === 2 && currentChatId && chatTitle === 'Untitled Chat') {
      generateTitle();
    }
  }, [messages, currentChatId, chatTitle]);

  // Add this new useEffect near the top of the component, with other useEffects
  useEffect(() => {
    const processPendingMessage = async () => {
      const newChatData = sessionStorage.getItem('newChatData');
      
      if (newChatData) {
        const parsedData = JSON.parse(newChatData);
        
        if (parsedData.pendingMessage && parsedData.forceNew) {
          // Clear the session storage first to prevent re-processing
          sessionStorage.removeItem('newChatData');
          sessionStorage.removeItem('pendingMessage');
          sessionStorage.removeItem('isNewChat');

          // Set the persona and target audience if they exist
          if (parsedData.selectedPersona) {
            setSelectedPersona(parsedData.selectedPersona);
          }
          if (parsedData.selectedTargetAudience) {
            setSelectedTargetAudience(parsedData.selectedTargetAudience);
          }

          // Set current chat ID
          setCurrentChatId(parsedData.chatId);
          
          // Send the message using handleSendMessage to ensure single addition
          await handleSendMessage(parsedData.pendingMessage);
        }
      }
    };

    processPendingMessage();
  }, []); // Empty dependency array means this runs once on mount

  // Add this useEffect to handle initial loading and synchronization
  useEffect(() => {
    // Load saved preferences
    const savedModel = localStorage.getItem('selectedAIModel');
    const savedMode = localStorage.getItem('chatInterfaceMode');

    // Only update if values exist and are different from current state
    if (savedModel && savedModel !== selectedModel) {
      setSelectedModel(savedModel);
    }
    
    if (savedMode && savedMode !== chatMode) {
      setChatMode(savedMode);
    }
  }, []); // Empty dependency array means this runs once on mount

  // Add these new state variables near other state declarations
  const [showFullScreenCanvas, setShowFullScreenCanvas] = useState(false);

  // Update the model logo rendering logic in the component
  const getModelLogo = (provider) => {
    if (!provider) return null;
    
    switch (provider.toLowerCase()) {
      case 'anthropic':
        return <AnthropicLogo />;
      case 'openai':
        return <OpenAILogo />;
      case 'xai':
        return <GrokLogo />;
      case 'google':
        return <GeminiLogo />;
      default:
        return null;
    }
  };

  return (
    <div className="flex h-full w-full overflow-hidden relative">
      <div className={`flex gap-0.4 w-full h-full min-w-0 ${
        isDarkMode ? 'bg-black' : 'bg-gray-100'
      }`}>
        {/* Chat Interface (left column) */}
        <div 
          className={`chat-interface ${
            isChatCollapsed ? 'w-0 border-0' : ''
          } flex flex-col h-full transition-all duration-300 ease-in-out overflow-hidden relative rounded-lg border ${
          isDarkMode ? 'bg-[#121212] border-[#333333]' : 'bg-white border-slate-200'
        }`}
          style={{
            width: isMobile ? '100%' : (isChatCollapsed ? 0 : chatWidth),
            minWidth: isMobile ? '100%' : (isChatCollapsed ? 0 : MIN_CHAT_WIDTH),
            maxWidth: isMobile ? '100vw' : (isChatCollapsed ? 0 : Math.min(
              window.innerWidth - MIN_CANVAS_WIDTH,
              window.innerWidth * MAX_CHAT_WIDTH_PERCENTAGE
            )),
            borderWidth: isChatCollapsed ? 0 : '1px',
            flexShrink: 0,
          }}
        >
          {/* Top Bar - Mode Selector, Persona, Target Audience, New Chat button */}
          <div className={`
            ${isDarkMode ? 'bg-[#1E1E1E] border-[#333333]' : 'bg-white border-gray-200'}
            !h-10 min-h-10 flex-shrink-0 flex items-center justify-between px-1 py-2 w-full border-b border-opacity-50
            relative z-50
            ${isMobile && showFullScreenCanvas ? 'hidden' : ''}  // Add this line
          `}>
            {/* Left section: Mode Selector, Persona and Target Audience dropdowns */}
            <div className="flex items-center space-x-0.5 relative">
              {activeScreen === 'voice' || activeScreen === 'text' ? (
                <button
                  onClick={() => setActiveScreen(null)}
                  className={`
                    flex items-center px-1.5 py-1 rounded-md transition-all duration-200
                    ${isDarkMode 
                      ? 'hover:bg-slate-700 text-slate-300' 
                      : 'hover:bg-gray-200 text-gray-600'}
                  `}
                >
                  <ChevronLeftIcon className="h-4 w-4 mr-0.5" />
                  <span className="text-xs">Back</span>
                </button>
              ) : (
                <>
                  <PersonaDropdown
                    onSelectPersona={handleSelectPersona}
                    selectedPersona={selectedPersona}
                    isLoadingAvatar={isLoadingAvatar}
                  />
                  <TargetAudienceDropdown
                    onSelectAudience={handleSelectTargetAudience}
                    selectedAudience={selectedTargetAudience}
                  />
                </>
              )}
            </div>
            {/* Right section: Canvas button (mobile only), New Chat button and Three-dot menu */}
            <div className="flex items-center space-x-0.5">
              {/* Add Canvas button for mobile */}
              {isMobile && (
                <button 
                  onClick={() => setShowFullScreenCanvas(true)}
                  className={`
                    flex items-center px-1.5 py-1 rounded-md transition-all duration-200 mr-2
                    ${isDarkMode 
                      ? 'bg-gradient-to-r from-[#2A2A2A] to-[#333333] text-gray-300 hover:from-[#333333] hover:to-[#444444]' 
                      : 'bg-gradient-to-r from-gray-100 to-gray-200 text-gray-600 hover:from-gray-200 hover:to-gray-300'
                    }
                    focus:outline-none focus:ring-2 focus:ring-offset-2 
                    ${isDarkMode ? 'focus:ring-blue-500/30 ring-offset-[#1E1E1E]' : 'focus:ring-gray-400'}
                  `}
                  title="Open Canvas"
                  style={{
                    background: isDarkMode 
                      ? 'linear-gradient(90deg, #2A2A2A, #333333)' 
                      : 'linear-gradient(90deg, #f3f4f6, #e5e7eb)',
                    animation: 'gradientAnimation 3s ease infinite'
                  }}
                >
                  <Squares2X2Icon className="h-3 w-3" />
                  <span className="text-xs font-medium ml-0.5">Canvas</span>
                </button>
              )}

              <button 
                onClick={handleNewChat}
                className={`
                  flex items-center px-1.5 py-1 rounded-md transition-all duration-300 ease-in-out
                  ${isDarkMode 
                    ? 'bg-[#1E1E1E] hover:bg-[#2A2A2A] text-gray-300 hover:text-gray-200 ring-1 ring-[#333333]' 
                    : 'bg-white hover:bg-gray-100 text-gray-600 hover:text-gray-800 ring-1 ring-gray-200'
                  }
                  focus:outline-none focus:ring-2 focus:ring-offset-2 
                  ${isDarkMode ? 'focus:ring-blue-500/30 ring-offset-[#1E1E1E]' : 'focus:ring-gray-400'}
                `}
                title="New Chat"
              >
                <PlusIcon className="h-3 w-3" />
                <span className="text-xs font-medium ml-0.5">New</span>
              </button>

              {/* History button */}
              <button 
                onClick={() => setShowChatHistory(!showChatHistory)}
                className={`
                  flex items-center p-1 rounded-md transition-colors duration-200
                  ${isDarkMode 
                    ? 'hover:bg-[#2A2A2A] text-gray-400 hover:text-gray-300' 
                    : 'hover:bg-gray-200 text-slate-600 hover:text-slate-800'
                  }
                `}
                title="Chat History"
              >
                <ChatHistoryIcon className="h-4 w-4" />
              </button>
            </div>
          </div>

          {/* Hidden EditableChatTitle */}
          <div className="hidden">
            <EditableChatTitle
              initialTitle={chatTitle}
              onSave={handleTitleChange}
              onChange={handleTitleChange}
              chatId={currentChatId}
              resetKey={resetTitleKey}
              isNewChat={isNewChat}
              createNewChat={handleCreateNewChat}
              disabled={!currentChatId}
              isGeneratingTitle={isGeneratingTitle}
              setIsGeneratingTitle={setIsGeneratingTitle}
              messages={messages}
              addNotification={addNotification}
            />
          </div>

          {/* Notifications Area */}
          <div className="fixed top-20 left-0 right-0 z-50">
            {notifications.map((notification) => (
              <Notification
                key={notification.id}
                id={notification.id}
                title={notification.title || ''}
                message={notification.message}
                type={notification.type}
                onClose={() => setNotifications(prev => prev.filter(n => n.id !== notification.id))}
                duration={3000}
              />
            ))}
          </div>

          {/* Chat Area */}
          <div className="flex flex-col flex-grow overflow-hidden">
            {showChatHistory ? (
              <ChatHistory 
                onClose={() => setShowChatHistory(false)}
                onSelectChat={handleChatSelect}
                isDarkMode={isDarkMode}
                className="h-full"
              />
            ) : (
              <>
                {/* Show Canvas in mobile view when showMobileCanvas is true */}
                {showFullScreenCanvas && (
                  <div className="fixed inset-0 z-[70]">
                    <div className="w-full h-full">
                      <Canvas 
                        isChatCollapsed={false}
                        onToggleChat={() => setShowFullScreenCanvas(false)}
                        isMobile={isMobile}
                      />
                    </div>
                  </div>
                )}
                {/* Existing messages area code */}
                <div 
                  ref={chatMessagesRef}
                  className={`flex-grow overflow-y-auto chat-scrollbar-overlay pt-4 px-4 ${
                    isDarkMode ? 'bg-[#121212]' : 'bg-white bg-opacity-80'
                  }`}
                >
                  <AnimatePresence>
                    {messages.length === 0 ? (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="h-full flex items-center justify-center"
                      >
                        {activeScreen === 'voice' ? (
                          <TranscriptionScreen 
                            setRecordingState={setRecordingState}
                            onClose={() => setActiveScreen(null)}
                          />
                        ) : activeScreen === 'text' ? (
                          <IdeasPage 
                            onClose={() => setActiveScreen(null)}
                          />
                        ) : (
                          <WelcomeScreen onActionSelect={handleWelcomeScreenAction} />
                        )}
                      </motion.div>
                    ) : (
                      <div className="pb-20">
                        {messages.map((message, index) => renderMessage(message, index))}
                        <div ref={messagesEndRef} />
                      </div>
                    )}
                  </AnimatePresence>
                </div>
              </>
            )}

            {/* Chat Input */}
            <div 
              ref={chatInputRef}
              className={`w-full px-2 sm:px-4 py-2 sm:py-4 ${
                isDarkMode ? 'bg-[#121212]' : 'bg-white bg-opacity-80'
              }`}
            >
              <div className={`${
                isDarkMode ? 'bg-[#151515] border-[#333333]' : 'bg-white border-gray-200'
              } rounded-lg relative border`}>
                {/* Attached Files */}
                {attachedFiles.length > 0 && (
                  <div className={`p-2 border-b ${isDarkMode ? 'border-[#333333]' : 'border-gray-200'}`}>
                    <div className="flex flex-col gap-2">
                      {attachedFiles.map(file => (
                        <TextFilePreview
                          key={file.id}
                          content={file.text || file.content}
                          name={file.name}
                          size={file.size}
                          lines={file.lines}
                          isDarkMode={isDarkMode}
                          onRemove={() => handleRemoveFile(file.id)}
                          onPreview={() => handlePreviewText({
                            content: file.text || file.content,
                            name: file.name,
                            size: file.size,
                            lines: file.lines
                          })}
                          isLoading={file.isLoading} // Use the file's own loading state
                        />
                      ))}
                    </div>
                  </div>
                )}
                {selectedTemplate && (
                  <div className="px-2 pt-2 w-full">
                    <TemplateAttachment
                      template={selectedTemplate}
                      onRemove={handleRemoveTemplate}
                    />
                  </div>
                )}
                {/* Display selected voice notes */}
                {selectedVoiceNotes.length > 0 && (
                  <div className="px-2 pt-2 w-full">
                    <div className="flex flex-col gap-2">
                      {selectedVoiceNotes.map(note => (
                        <TextFilePreview
                          key={note._id}
                          content={note.transcription}
                          name={note.title}
                          size={new Blob([note.transcription]).size}
                          lines={note.transcription.split('\n').length}
                          isDarkMode={isDarkMode}
                          onRemove={() => handleRemoveVoiceNote(note._id)}
                          onPreview={() => handlePreviewText({
                            content: note.transcription,
                            name: note.title,
                            size: new Blob([note.transcription]).size,
                            lines: note.transcription.split('\n').length
                          })}
                        />
                      ))}
                    </div>
                  </div>
                )}
                {/* Display selected text notes */}
                {selectedTextNotes.length > 0 && (
                  <div className="px-2 pt-2 w-full">
                    <div className="flex flex-col gap-2">
                      {selectedTextNotes.map(note => (
                        <TextFilePreview
                          key={note.id}
                          content={note.content}
                          name={note.name}
                          size={note.size}
                          lines={note.lines}
                          isDarkMode={isDarkMode}
                          onRemove={() => handleRemoveTextNote(note.id)}
                          onPreview={() => handlePreviewText({
                            content: note.content,
                            name: note.name,
                            size: note.size,
                            lines: note.lines
                          })}
                        />
                      ))}
                    </div>
                  </div>
                )}
                {/* Add this new section for the selected AI Agent */}
                {selectedAgent && (
                  <div className="px-2 pt-2 w-full">
                    <AIAgentTag
                      agent={selectedAgent}
                      onRemove={() => setSelectedAgent(null)}
                    />
                  </div>
                )}
                <div className="flex flex-col items-center p-2">
                  {selectedTemplate ? (
                    <div className="w-full transition-all duration-300 ease-in-out mt-0">
                      <TemplateForm
                        onSubmit={(formData, template) => handleSendMessage('', formData)}
                        template={selectedTemplate}
                        onClose={handleRemoveTemplate}
                      />
                    </div>
                  ) : (
                    <div className="flex flex-col w-full">
                      {/* Show TextFilePreview above the input */}
                      {pastedText && (
                        <div className="mb-2 -mt-1">
                          <TextFilePreview
                            content={pastedText.content}
                            name={pastedText.name}
                            size={pastedText.size}
                            lines={pastedText.lines}
                            isDarkMode={isDarkMode}
                            onRemove={() => {
                              console.log('Removing pasted text'); // Debug log
                              setPastedText(null);
                              setInput('');
                            }}
                            onPreview={(data) => {
                              console.log('Preview clicked with data:', data); // Debug log
                              handlePreviewText(data);
                            }}
                          />
                        </div>
                      )}
                      
                      {/* Input area with model picker and textarea */}
                      <div className="flex items-start w-full mb-2">
                        <div className="flex-grow relative">
                          <textarea
                            ref={textareaRef}
                            className={`w-full p-2 bg-transparent outline-none resize-none text-sm chat-scrollbar-overlay ${
                              isDarkMode 
                                ? 'text-slate-200 placeholder-slate-500' 
                                : 'text-slate-800 placeholder-slate-400'
                            }`}
                            value={input}
                            onChange={(e) => {
                              setInput(e.target.value);
                              adjustTextareaHeight();
                            }}
                            onPaste={handlePaste}
                            onFocus={handleTextAreaFocus}
                            onBlur={handleTextAreaBlur}
                            onKeyPress={(e) => {
                              if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();
                                handleSendMessage();
                              }
                            }}
                            placeholder="Ask something..."
                            rows={1}
                            style={{
                              minHeight: '40px',
                              maxHeight: '200px',
                            }}
                          />
                        </div>

                        <button 
                          className={`
                            px-3 py-2 rounded-md ml-2 transition-all duration-300 ease-in-out
                            flex items-center justify-center gap-2
                            ${input.trim() === '' || isLoading
                              ? isDarkMode 
                                ? 'bg-[#1a1a1a] cursor-not-allowed opacity-50' 
                                : 'bg-gray-300 cursor-not-allowed opacity-50'
                              : isDarkMode 
                                ? 'bg-gradient-to-r from-[#1a1a1a] to-[#151515] hover:from-[#202020] hover:to-[#1a1a1a] ring-1 ring-[#333333]' 
                                : 'bg-gradient-to-r from-gray-700 to-black hover:from-gray-800 hover:to-gray-900'
                            }
                            shadow-lg hover:shadow-xl
                            focus:outline-none focus:ring-2 focus:ring-offset-2 
                            ${isDarkMode ? 'focus:ring-blue-500/30 ring-offset-[#151515]' : 'focus:ring-gray-400'}
                          `}
                          onClick={() => handleSendMessage(input)}
                          disabled={isLoading || input.trim() === ''}
                        >
                          {isLoading ? (
                            <LoadingSpinner 
                              size={20}
                              color={isDarkMode ? '#ffffff' : '#ffffff'}
                              className="opacity-100"
                            />
                          ) : (
                            <PaperAirplaneIcon className={`h-4 w-4 transform group-hover:translate-x-[2px] transition-transform duration-300 ${
                              input.trim() === '' || isLoading
                                ? isDarkMode 
                                  ? 'text-gray-400' 
                                  : 'text-gray-500'
                                : isDarkMode
                                  ? 'text-white'
                                  : 'text-white/90'
                            }`} />
                          )}
                        </button>
                      </div>

                      {/* Context buttons row */}
                      <div className="flex flex-wrap items-center gap-1.5 px-1.5">
                        {/* Chat Mode Selector */}
                        <ChatInterfaceMode
                          chatMode={chatMode}
                          onModeSelect={handleModeSelect}
                          isDarkMode={isDarkMode}
                          className="h-7"
                        />

                        {/* Model picker button and dropdown */}
                        <div className="relative" ref={modelSelectorRef}>
                          <button
                            onClick={() => setShowModelSelector(!showModelSelector)}
                            className={`
                              flex items-center gap-1.5 px-2 h-7 text-xs font-medium rounded-lg
                              transition-all duration-200 border
                              ${isDarkMode 
                                ? 'bg-[#121212] text-slate-200 hover:bg-[#1e1e1e] border-[#333333]' 
                                : 'bg-gray-50 text-gray-700 hover:bg-gray-100/80 border-gray-200/80'}
                              backdrop-blur-sm
                              ${isMobile ? 'max-w-[150px]' : ''}
                            `}
                            title={availableModels.find(m => m.id === selectedModel)?.name || selectedModel}
                          >
                            {getModelLogo(availableModels.find(m => m.id === selectedModel)?.provider)}
                            <span className={`whitespace-nowrap ${isMobile ? 'truncate max-w-[80px]' : ''}`}>
                              {availableModels.find(m => m.id === selectedModel)?.name || selectedModel}
                            </span>
                            <ChevronUpDownIcon className="h-3.5 w-3.5 ml-1 flex-shrink-0" />
                          </button>

                          {showModelSelector && (
                            <div 
                              className={`
                                absolute bottom-full left-0 mb-2 w-56 rounded-lg shadow-lg z-50
                                backdrop-blur-md backdrop-saturate-150 overflow-hidden
                                border transition-all duration-200
                                ${isDarkMode 
                                  ? 'bg-[#121212]/95 border-[#333333]' 
                                  : 'bg-white/95 border-gray-200/80'}
                              `}
                              style={{
                                left: isMobile ? '-4rem' : '0', // Add left offset for mobile
                              }}
                            >
                              <div className={`
                                px-2 py-1.5 border-b text-xs font-medium
                                ${isDarkMode ? 'border-[#333333] text-slate-400' : 'border-gray-200/80 text-gray-500'}
                              `}>
                                Select AI Model
                              </div>
                              
                              <div className="p-0.5">
                                {availableModels.map((model) => (
                                  <button
                                    key={model.id}
                                    onClick={() => handleSelectModel(model)}
                                    className={`
                                      flex items-center gap-2 px-2 py-1.5 w-full rounded-md
                                      transition-all duration-200 relative group
                                      ${selectedModel === model.id
                                        ? isDarkMode 
                                          ? 'bg-[#1e1e1e] text-blue-400' 
                                          : 'bg-blue-50 text-blue-600'
                                        : isDarkMode 
                                          ? 'hover:bg-[#1e1e1e] text-slate-200' 
                                          : 'hover:bg-gray-50 text-gray-700'
                                      }
                                    `}
                                  >
                                    <div className={`
                                      flex items-center justify-center w-6 h-6 rounded-md
                                      transition-all duration-200
                                      ${selectedModel === model.id
                                        ? isDarkMode 
                                          ? 'bg-[#2a2a2a]' 
                                          : 'bg-blue-100/50'
                                        : isDarkMode 
                                          ? 'bg-[#1e1e1e]' 
                                          : 'bg-gray-100/50'
                                      }
                                    `}>
                                      {model.provider === 'anthropic' ? (
                                        <AnthropicLogo className="h-4 w-4" />
                                      ) : model.provider === 'xai' ? (
                                        <GrokLogo className="h-4 w-4" />
                                      ) : model.provider === 'google' ? (
                                        <GeminiLogo className="h-4 w-4" />
                                      ) : (
                                        <OpenAILogo className="h-4 w-4" />
                                      )}
                                    </div>
                                    
                                    <div className="flex flex-col items-start min-w-0 gap-0.5">
                                      <span className="text-xs font-medium truncate">
                                        {model.name}
                                      </span>
                                      <span className={`
                                        text-[10px] leading-none
                                        ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}
                                      `}>
                                        {model.provider === 'anthropic' 
                                          ? 'Anthropic' 
                                          : model.provider === 'xai'
                                            ? 'xAI'
                                            : model.provider === 'google'
                                              ? 'Google'
                                              : 'OpenAI'
                                        }
                                      </span>
                                    </div>

                                    {selectedModel === model.id && (
                                      <div className={`
                                        absolute right-2 flex items-center justify-center
                                        w-4 h-4 rounded-full
                                        ${isDarkMode ? 'bg-blue-500' : 'bg-blue-600'}
                                      `}>
                                        <CheckIcon className="h-2.5 w-2.5 text-white" />
                                      </div>
                                    )}
                                  </button>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>

                        {/* Add Context button */}
                        <div className="relative" ref={addContextRef}>
                          <button 
                            className={`
                              flex items-center gap-1.5 px-2 h-7 text-xs font-medium rounded-lg
                              transition-all duration-200 border
                              ${isDarkMode 
                                ? 'bg-[#121212] text-slate-200 hover:bg-[#1e1e1e] border-[#333333]' 
                                : 'bg-gray-50 text-gray-700 hover:bg-gray-100/80 border-gray-200/80'}
                              backdrop-blur-sm
                            `}
                            onClick={() => setShowAddContextMenu(!showAddContextMenu)}
                            title="Add Context"
                          >
                            <PlusCircleIcon className="h-3.5 w-3.5" />
                            <span className="whitespace-nowrap">Add Context</span>
                            <ChevronUpDownIcon className="h-3.5 w-3.5 ml-1" />
                          </button>
                          {showAddContextMenu && (
                            <AddContextMenu
                              onSelectVoiceNote={() => setShowVoiceNoteSelector(true)}
                              onSelectTextNote={() => setShowTextNoteSelector(true)}
                              onSelectKnowledgeBase={() => setShowKnowledgeBase(true)}
                              onSelectFileUpload={() => setShowFileUpload(true)}
                              isDarkMode={isDarkMode}
                            />
                          )}
                        </div>

                        {/* Ideate button */}
                        <div className="relative" ref={ideateButtonRef}>
                          <button 
                            className={`
                              flex items-center gap-1.5 px-2 h-7 text-xs font-medium rounded-lg
                              transition-all duration-200 border
                              ${activeIdeationTool
                                ? isDarkMode 
                                  ? 'bg-[#1e1e1e] text-blue-400 border-[#333333]' 
                                  : 'bg-blue-50 text-blue-600 border-blue-200/80'
                                : isDarkMode 
                                  ? 'bg-[#121212] text-slate-200 hover:bg-[#1e1e1e] border-[#333333]' 
                                  : 'bg-gray-50 text-gray-700 hover:bg-gray-100/80 border-gray-200/80'
                              }
                              backdrop-blur-sm
                            `}
                            onClick={() => setShowIdeationDropdown(!showIdeationDropdown)}
                            title="Ideation Tools"
                          >
                            <LightBulbIcon className="h-3.5 w-3.5" />
                            <span className="whitespace-nowrap">
                              {activeIdeationTool ? 'Ideating...' : 'Ideate'}
                            </span>
                            <ChevronUpDownIcon className="h-3.5 w-3.5 ml-1" />
                          </button>

                          {showIdeationDropdown && (
                            <IdeationDropdown
                              onSelect={handleIdeationSelect}
                              isDarkMode={isDarkMode}
                            />
                          )}
                        </div>

                        {/* Use Insights button - Moved here */}
                        <div className="relative">
                          <button 
                            className={`
                              flex items-center gap-1.5 px-2 h-7 text-xs font-medium rounded-lg
                              transition-all duration-200 border
                              ${selectedYouIdea
                                ? isDarkMode 
                                  ? 'bg-[#1e1e1e] text-blue-400 border-[#333333]' 
                                  : 'bg-blue-50 text-blue-600 border-blue-200/80'
                                : isDarkMode 
                                  ? 'bg-[#121212] text-slate-200 hover:bg-[#1e1e1e] border-[#333333]' 
                                  : 'bg-gray-50 text-gray-700 hover:bg-gray-100/80 border-gray-200/80'
                              }
                              backdrop-blur-sm
                            `}
                            onClick={() => setShowYouIdeaSelector(true)}
                            title="Add Insights"
                          >
                            <SparklesIcon className="h-3.5 w-3.5" />
                            <span className="whitespace-nowrap">Use Insights</span>
                          </button>
                        </div>

                        {/* Templates button - Moved before AI Templates */}
                        <div className="relative">
                          <button 
                            className={`
                              flex items-center gap-1.5 px-2 h-7 text-xs font-medium rounded-lg
                              transition-all duration-200 border
                              ${selectedTemplate
                                ? isDarkMode 
                                  ? 'bg-[#1e1e1e] text-blue-400 border-[#333333]' 
                                  : 'bg-blue-50 text-blue-600 border-blue-200/80'
                                : isDarkMode 
                                  ? 'bg-[#121212] text-slate-200 hover:bg-[#1e1e1e] border-[#333333]' 
                                  : 'bg-gray-50 text-gray-700 hover:bg-gray-100/80 border-gray-200/80'
                              }
                              backdrop-blur-sm
                            `}
                            onClick={() => setShowTemplatePopup(true)}
                            title={selectedTemplate ? selectedTemplate.title : "Post Templates"}
                          >
                            <Squares2X2Icon className="h-3.5 w-3.5" />
                            <span className="whitespace-nowrap">
                              {selectedTemplate ? 'Template Selected' : 'Post Templates'}
                            </span>
                          </button>
                        </div>

                        {/* Agents button */}
                        <div className="relative">
                          <button 
                            className={`
                              flex items-center gap-1.5 px-2 h-7 text-xs font-medium rounded-lg
                              transition-all duration-200 border
                              ${selectedAgent
                                ? isDarkMode 
                                  ? 'bg-[#1e1e1e] text-blue-400 border-[#333333]' 
                                  : 'bg-blue-50 text-blue-600 border-blue-200/80'
                                : isDarkMode 
                                  ? 'bg-[#121212] text-slate-200 hover:bg-[#1e1e1e] border-[#333333]' 
                                  : 'bg-gray-50 text-gray-700 hover:bg-gray-100/80 border-gray-200/80'
                              }
                              backdrop-blur-sm
                            `}
                            onClick={() => setShowAIAgentsList(true)}
                            title={selectedAgent ? selectedAgent.name : "AI Agents"}
                          >
                            <ChatBubbleBottomCenterTextIcon className="h-3.5 w-3.5" />
                            <span className="whitespace-nowrap">
                              {selectedAgent ? selectedAgent.name : 'AI Templates'}
                            </span>
                          </button>
                        </div>

                        {/* Reply to Posts button */}
                        <div className="relative">
                          <button 
                            className={`
                              flex items-center gap-1.5 px-2 h-7 text-xs font-medium rounded-lg
                              transition-all duration-200 border
                              ${showPostComments
                                ? isDarkMode 
                                  ? 'bg-[#1e1e1e] text-blue-400 border-[#333333]' 
                                  : 'bg-blue-50 text-blue-600 border-blue-200/80'
                                : isDarkMode 
                                  ? 'bg-[#121212] text-slate-200 hover:bg-[#1e1e1e] border-[#333333]' 
                                  : 'bg-gray-50 text-gray-700 hover:bg-gray-100/80 border-gray-200/80'
                              }
                              backdrop-blur-sm
                            `}
                            onClick={() => setShowPostComments(true)}
                            aria-label="Comment Generator"
                          >
                            <ChatBubbleLeftRightIcon className="h-3.5 w-3.5" />
                            <span className="whitespace-nowrap">Reply to Posts</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {showAgentCreation && (
            <AIAgentCreationInterface
              onClose={() => setShowAgentCreation(false)}
              onSave={handleCreateAgent}
            />
          )}

          {showAIAgentsList && (
            <AIAgentsListPopup
              isOpen={showAIAgentsList}
              onClose={() => setShowAIAgentsList(false)}
              onSelectAgent={handleSelectAgent}
            />
          )}

          {showVoiceNoteSelector && (
            <div className="absolute inset-0 z-50 flex">
              <div className={`${
                isChatCollapsed 
                  ? 'w-full' 
                  : 'flex-1'
              } h-full transition-all duration-300 ease-in-out relative ml-auto`}>
                <VoiceNoteSelector
                  onSelect={handleVoiceNoteSelect}
                  onClose={() => setShowVoiceNoteSelector(false)}
                  isDarkMode={isDarkMode}
                  initialSelectedNotes={selectedVoiceNotes}
                />
              </div>
            </div>
          )}

          {showTextNoteSelector && (
            <div className="absolute inset-0 z-50 flex">
              <div className={`${
                isChatCollapsed 
                  ? 'w-full' 
                  : 'flex-1'
              } h-full transition-all duration-300 ease-in-out relative ml-auto`}>
                <TextNoteSelector
                  notes={notes}
                  onSelect={handleTextNoteSelect}
                  onClose={() => setShowTextNoteSelector(false)}
                  isDarkMode={isDarkMode}
                  initialSelectedNotes={selectedTextNotes}
                />
              </div>
            </div>
          )}

          {/* Knowledge Base Popup */}
          {showKnowledgeBase && (
            <div className="absolute inset-0 z-50 flex">
              <div 
                className="w-full h-full"
                style={{
                  width: isChatCollapsed ? '100%' : chatWidth,
                  transition: 'width 300ms ease-in-out'
                }}
              >
                <KnowledgeBaseSelector
                  onSelect={handleFileSelect}
                  onClose={() => setShowKnowledgeBase(false)}
                  initialSelectedFiles={attachedFiles}
                  containerWidth={isChatCollapsed ? '100%' : chatWidth}
                />
              </div>
            </div>
          )}

          {showLinkedInPopup && (
            <LinkedInPostsPopup
              posts={linkedInPosts}
              onClose={() => setShowLinkedInPopup(false)}
              onCopy={copyToClipboard}
              onSaveToTextNotes={handleSaveToTextNotes}
            />
          )}

          {showPostComments && (
            <div className="absolute inset-0 z-50 flex">
              <div className={`${
                isChatCollapsed 
                  ? 'w-full' 
                  : 'flex-1'
              } h-full transition-all duration-300 ease-in-out relative ml-auto`}>
                <ChatInterfacePostComments
                  onClose={() => setShowPostComments(false)}
                />
              </div>
            </div>
          )}

          {showYouIdeaSelector && (
            <div className="absolute inset-0 z-50 flex"> {/* Added z-50 to match other overlays */}
              <div 
                className="w-full h-full"
                style={{
                  width: isChatCollapsed ? '100%' : chatWidth,
                  transition: 'width 300ms ease-in-out'
                }}
              >
                <YouIdeaSelector
                  onSelect={handleYouIdeaSelect}
                  onClose={() => setShowYouIdeaSelector(false)}
                />
              </div>
            </div>
          )}
        </div>

        {/* Resize Handle - Hidden on mobile */}
        {!isMobile && (
          <ResizableHandle
            onResize={handleResize}
            onDoubleClick={handleResetWidth}
            isDarkMode={isDarkMode}
          />
        )}

        {/* Right Column: Canvas or Ideation Tools - Hidden on mobile */}
        <div className={`${
          isChatCollapsed 
            ? 'w-full' 
            : 'flex-1'
        } h-full transition-all duration-300 ease-in-out relative rounded-lg border min-w-0 ${
          isDarkMode 
            ? 'bg-[#121212] border-[#333333]' 
            : 'bg-white border-gray-200'
        } ${isMobile ? 'hidden' : ''}`}
        style={{
          // Add this style object
          minWidth: isMobile ? '100%' : (isChatCollapsed ? '100%' : MIN_CANVAS_WIDTH),
          flex: isChatCollapsed ? '1 0 100%' : '1 0 0%'
        }}
        >
          {activeIdeationTool && !isMobile ? (
            activeIdeationTool === 'reddit' ? (
              <YouIdea 
                onClose={() => setActiveIdeationTool(null)} 
                isInChatInterface={true} 
              />
            ) : activeIdeationTool === 'ai' ? (
              <ContentStrategy 
                onClose={() => setActiveIdeationTool(null)}
                isInChatInterface={true}
              />
            ) : null
          ) : showTemplatePopup && !isMobile ? (
            <TemplatePopup
              onSelectTemplate={handleSelectTemplate}
              onClose={() => setShowTemplatePopup(false)}
            />
          ) : (
            <Canvas 
              isDarkMode={isDarkMode} 
              isChatCollapsed={isChatCollapsed}
              onToggleChat={() => setIsChatCollapsed(!isChatCollapsed)}
              isMobile={isMobile}
            />
          )}
        </div>
      </div>

      {/* Preview Popup - Move this outside other containers */}
      <AnimatePresence>
        {previewData && (
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-[60] flex items-center justify-center bg-white/40"
            onClick={(e) => {
              if (e.target === e.currentTarget) {
                setPreviewData(null);
              }
            }}
          >
            <motion.div 
              initial={{ scale: 0.95 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.95 }}
              className="w-full max-w-3xl max-h-[90vh] m-4"
              onClick={(e) => e.stopPropagation()}
            >
              <PreviewPastedText
                {...previewData}
                isDarkMode={isDarkMode}
                onRemove={() => setPreviewData(null)}
              />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {previewVoiceNote && (
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-[60] flex items-center justify-center bg-white/40"
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              setPreviewVoiceNote(null);
            }
          }}
        >
          <motion.div 
            initial={{ scale: 0.95 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.95 }}
            className="w-full max-w-3xl max-h-[90vh] m-4"
            onClick={(e) => e.stopPropagation()}
          >
            <PreviewVoiceNote
              voiceNote={previewVoiceNote}
              isDarkMode={isDarkMode}
              onClose={() => setPreviewVoiceNote(null)}
            />
          </motion.div>
        </motion.div>
      )}

      <AnimatePresence>
        {previewTextNote && (
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-[60] flex items-center justify-center bg-white/40"
            onClick={(e) => {
              if (e.target === e.currentTarget) {
                setPreviewTextNote(null);
              }
            }}
          >
            <motion.div 
              initial={{ scale: 0.95 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.95 }}
              className="w-full max-w-3xl max-h-[90vh] m-4"
              onClick={(e) => e.stopPropagation()}
            >
              <PreviewTextNote
                textNote={previewTextNote}
                isDarkMode={isDarkMode}
                onClose={() => setPreviewTextNote(null)}
              />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* File Preview Modal */}
      <AnimatePresence>
        {previewFile && (
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-[60] flex items-center justify-center bg-white/40"
            onClick={(e) => {
              if (e.target === e.currentTarget) {
                setPreviewFile(null);
              }
            }}
          >
            <motion.div 
              initial={{ scale: 0.95 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.95 }}
              className="w-full max-w-3xl max-h-[90vh] m-4"
              onClick={(e) => e.stopPropagation()}
            >
              <PreviewFileforChat
                file={previewFile}
                isDarkMode={isDarkMode}
                onClose={() => setPreviewFile(null)}
              />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* FileUploadPopover - Update to check for isChatCollapsed */}
      {showFileUpload && !isChatCollapsed && (
        <div 
          className={`
            fixed inset-0 z-[60] 
            flex items-center justify-center 
            ${isDarkMode ? 'bg-slate-900/80' : 'bg-white/80'}
            backdrop-blur-sm
          `}
          style={{
            width: isMobile ? '100vw' : chatWidth,
            left: 0,
          }}
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              setShowFileUpload(false);
            }
          }}
        >
          <div className="relative w-full transform transition-all">
            <FileUploadPopover
              onClose={() => setShowFileUpload(false)}
              onSubmit={handleFileUpload}
              isDarkMode={isDarkMode}
              containerWidth={isMobile ? window.innerWidth : chatWidth}
            />
          </div>
        </div>
      )}

      {/* Add Template Popup in Chat Interface for Mobile */}
      {showTemplatePopup && isMobile && (
        <div className="fixed inset-0 z-50 flex items-stretch justify-center rounded-lg"
             style={{ backgroundColor: isDarkMode ? 'rgb(0,0,0)' : 'rgb(255,255,255)' }}>
          <div className="w-full h-full">
            <TemplatePopup
              onSelectTemplate={handleSelectTemplate}
              onClose={() => setShowTemplatePopup(false)}
              isMobileView={true}
            />
          </div>
        </div>
      )}

      {/* Ideation Tools in Mobile View */}
      {activeIdeationTool && isMobile && (
        <div className="fixed inset-0 z-50 flex items-stretch justify-center"
             style={{ backgroundColor: isDarkMode ? 'rgb(0,0,0)' : 'rgb(255,255,255)' }}>
          <div className="w-full h-full">
            {activeIdeationTool === 'reddit' ? (
              <YouIdea 
                onClose={() => setActiveIdeationTool(null)} 
                isInChatInterface={true}
                isMobileView={true}
              />
            ) : activeIdeationTool === 'ai' ? (
              <ContentStrategy 
                onClose={() => setActiveIdeationTool(null)}
                isInChatInterface={true}
                isMobileView={true}
              />
            ) : null}
          </div>
        </div>
      )}

      {/* Ideation Tools in Desktop View */}
      {showYouIdeaSelector && !isMobile && (
        <div className="absolute inset-0 z-50 flex">
          <div 
            className="w-full h-full"
            style={{
              width: isChatCollapsed ? '100%' : chatWidth,
              transition: 'width 300ms ease-in-out'
            }}
          >
            <YouIdeaSelector
              onSelect={handleYouIdeaSelect}
              onClose={() => setShowYouIdeaSelector(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatInterface;