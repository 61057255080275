import React, { useState } from 'react';
import { XMarkIcon, PencilIcon, TrashIcon, CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';

const NotificationMessage = ({ message, type, isDarkMode }) => (
  <div className={`
    fixed top-4 right-4 flex items-center space-x-2 px-4 py-3 rounded-lg shadow-lg
    transform transition-all duration-300 ease-out
    ${type === 'success' 
      ? isDarkMode 
        ? 'bg-green-500/20 text-green-300 border border-green-500/30'
        : 'bg-green-100 text-green-800 border border-green-200'
      : isDarkMode
        ? 'bg-red-500/20 text-red-300 border border-red-500/30'
        : 'bg-red-100 text-red-800 border border-red-200'
    }
  `}>
    {type === 'success' 
      ? <CheckCircleIcon className="w-5 h-5" />
      : <ExclamationCircleIcon className="w-5 h-5" />
    }
    <span className="text-sm font-medium">{message}</span>
  </div>
);

const ConfirmDialog = ({ message, onConfirm, onCancel, isDarkMode }) => (
  <div className="fixed inset-0 z-[60] flex items-center justify-center bg-black/50 backdrop-blur-sm">
    <div className={`
      relative w-full max-w-sm p-6 rounded-lg shadow-xl border
      ${isDarkMode 
        ? 'bg-[#1a1a1a]/95 text-slate-200 border-[#333333]' 
        : 'bg-white/95 text-gray-800 border-gray-200'
      }
    `}>
      <p className="text-sm mb-4">{message}</p>
      <div className="flex justify-end space-x-2">
        <button
          onClick={onCancel}
          className={`
            px-3 py-1.5 text-xs font-medium rounded-lg transition-colors duration-200
            ${isDarkMode
              ? 'bg-[#202020] text-slate-300 hover:bg-[#252525]'
              : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }
          `}
        >
          Cancel
        </button>
        <button
          onClick={onConfirm}
          className={`
            px-3 py-1.5 text-xs font-medium rounded-lg transition-colors duration-200
            ${isDarkMode
              ? 'bg-red-500/20 text-red-300 hover:bg-red-500/30'
              : 'bg-red-100 text-red-600 hover:bg-red-200'
            }
          `}
        >
          Delete
        </button>
      </div>
    </div>
  </div>
);

const TagManager = ({ tags, onClose, onEditTag, onDeleteTag, isDarkMode }) => {
  const [editingTag, setEditingTag] = useState(null);
  const [editedTagName, setEditedTagName] = useState('');
  const [notification, setNotification] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(null);

  const showNotification = (message, type = 'success') => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 3000);
  };

  const handleEditClick = (tag) => {
    if (editingTag) {
      showNotification('Please finish editing the current tag first.', 'error');
      return;
    }
    setEditingTag(tag);
    setEditedTagName(tag);
  };

  const handleSaveEdit = async () => {
    if (!editedTagName.trim()) {
      showNotification('Tag name cannot be empty.', 'error');
      return;
    }

    if (editedTagName.trim() === editingTag) {
      setEditingTag(null);
      setEditedTagName('');
      return;
    }

    try {
      await onEditTag(editingTag, editedTagName.trim());
      setEditingTag(null);
      setEditedTagName('');
      showNotification(`Tag "${editingTag}" successfully renamed to "${editedTagName.trim()}"`);
    } catch (error) {
      console.error('Error saving tag edit:', error);
      showNotification(error.response?.data?.error || 'Failed to edit tag. Please try again.', 'error');
    }
  };

  const handleCancelEdit = () => {
    setEditingTag(null);
    setEditedTagName('');
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSaveEdit();
    } else if (e.key === 'Escape') {
      handleCancelEdit();
    }
  };

  const handleDeleteClick = async (tag) => {
    setConfirmDialog({
      message: `Are you sure you want to delete the tag "${tag}"? This action cannot be undone.`,
      onConfirm: async () => {
        try {
          await onDeleteTag(tag);
          showNotification(`Tag "${tag}" successfully deleted`);
        } catch (error) {
          console.error('Error deleting tag:', error);
          showNotification('Failed to delete tag. Please try again.', 'error');
        }
        setConfirmDialog(null);
      },
      onCancel: () => setConfirmDialog(null)
    });
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm">
      {notification && (
        <NotificationMessage
          message={notification.message}
          type={notification.type}
          isDarkMode={isDarkMode}
        />
      )}
      
      {confirmDialog && (
        <ConfirmDialog
          message={confirmDialog.message}
          onConfirm={confirmDialog.onConfirm}
          onCancel={confirmDialog.onCancel}
          isDarkMode={isDarkMode}
        />
      )}

      <div className={`
        relative w-full max-w-md p-6 rounded-lg shadow-xl border
        ${isDarkMode 
          ? 'bg-[#1a1a1a]/95 text-slate-200 border-[#333333]' 
          : 'bg-white/95 text-gray-800 border-gray-200'
        }
      `}>
        <button
          onClick={onClose}
          className={`
            absolute top-2 right-2 p-1.5 rounded-lg transition-colors duration-200
            ${isDarkMode 
              ? 'text-slate-400 hover:bg-[#202020] hover:text-slate-300' 
              : 'text-gray-600 hover:bg-gray-100 hover:text-gray-700'
            }
          `}
        >
          <XMarkIcon className="w-5 h-5" />
        </button>
        <h2 className="text-xl font-semibold mb-4">Manage Tags</h2>
        <div className="space-y-2 max-h-96 overflow-y-auto custom-scrollbar">
          {tags.map((tag) => (
            <div key={tag} className={`
              flex items-center justify-between p-2 rounded-lg
              ${isDarkMode ? 'hover:bg-[#202020]' : 'hover:bg-gray-50'}
            `}>
              {editingTag === tag ? (
                <input
                  type="text"
                  value={editedTagName}
                  onChange={(e) => setEditedTagName(e.target.value)}
                  onKeyDown={handleKeyPress}
                  className={`
                    flex-grow px-3 py-1.5 rounded-lg border text-sm
                    transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-indigo-500
                    ${isDarkMode 
                      ? 'bg-[#151515] text-slate-200 border-[#333333]' 
                      : 'bg-white text-gray-900 border-gray-300'
                    }
                  `}
                  autoFocus
                />
              ) : (
                <span className="text-sm">{tag}</span>
              )}
              <div className="flex space-x-1">
                {editingTag === tag ? (
                  <>
                    <button
                      onClick={handleSaveEdit}
                      className={`
                        px-3 py-1.5 text-xs font-medium rounded-lg transition-colors duration-200
                        ${isDarkMode
                          ? 'bg-indigo-600/20 text-indigo-300 hover:bg-indigo-600/30'
                          : 'bg-indigo-100 text-indigo-600 hover:bg-indigo-200'
                        }
                      `}
                    >
                      Save
                    </button>
                    <button
                      onClick={handleCancelEdit}
                      className={`
                        px-3 py-1.5 text-xs font-medium rounded-lg transition-colors duration-200
                        ${isDarkMode
                          ? 'bg-[#202020] text-slate-300 hover:bg-[#252525]'
                          : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                        }
                      `}
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <button
                    onClick={() => handleEditClick(tag)}
                    className={`
                      p-1.5 rounded-lg transition-colors duration-200
                      ${isDarkMode
                        ? 'text-slate-400 hover:bg-[#202020] hover:text-slate-300'
                        : 'text-gray-600 hover:bg-gray-100 hover:text-gray-700'
                      }
                    `}
                  >
                    <PencilIcon className="w-4 h-4" />
                  </button>
                )}
                <button
                  onClick={() => handleDeleteClick(tag)}
                  className={`
                    p-1.5 rounded-lg transition-colors duration-200
                    ${isDarkMode
                      ? 'text-red-400 hover:bg-[#202020] hover:text-red-300'
                      : 'text-red-600 hover:bg-gray-100 hover:text-red-700'
                    }
                  `}
                >
                  <TrashIcon className="w-4 h-4" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TagManager;