import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../../context/ThemeContext';
import { useNavigate } from 'react-router-dom';

const TargetAudienceOverviewPopup = ({ audience, onClose }) => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();

  const handleEdit = () => {
    navigate(`/target-audiences/edit/${audience._id}`);
    onClose();
  };

  const fieldGroups = [
    {
      title: 'Basic Information',
      fields: [
        { label: 'Description', value: audience.describeYourAudience },
        { label: 'Age Range', value: audience.ageRange },
        { label: 'Location', value: audience.primaryLocation },
        { label: 'Industry', value: audience.industry }
      ]
    },
    {
      title: 'Professional Context',
      fields: [
        { label: 'Career Stage', value: audience.careerStage },
        { label: 'Knowledge Level', value: audience.knowledgeLevel },
        { label: 'Primary Goal', value: audience.primaryGoal }
      ]
    },
    {
      title: 'Content Preferences',
      fields: [
        { label: 'Learning Style', value: audience.preferredLearningStyle },
        { label: 'Platforms', value: audience.topPlatforms },
        { label: 'Formats', value: audience.preferredFormats },
        { label: 'Tone', value: audience.contentTone }
      ]
    },
    {
      title: 'Behavioral Insights',
      fields: [
        { label: 'Motivations', value: audience.keyMotivations },
        { label: 'Pain Points', value: audience.painPoints },
        { label: 'Core Values', value: audience.coreValues }
      ]
    }
  ];

  const renderValue = (value) => {
    if (!value) return null;
    if (Array.isArray(value)) {
      return value.map((item, i) => (
        <span 
          key={i} 
          className={`
            inline-block px-2 py-1 mr-2 mb-2 rounded-md text-xs
            ${isDarkMode 
              ? 'bg-[#1a1a1a] text-slate-300 border border-[#333333]' 
              : 'bg-gray-100 text-gray-700'
            }
          `}
        >
          {item}
        </span>
      ));
    }
    return (
      <p className={`
        text-sm
        ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}
      `}>
        {value}
      </p>
    );
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className={`
        relative w-full max-w-3xl max-h-[85vh] rounded-lg shadow-xl
        border popup-scrollbar overflow-y-auto
        ${isDarkMode 
          ? 'bg-[#121212] border-[#333333]' 
          : 'bg-white border-gray-200'
        }
      `}>
        {/* Header */}
        <div className={`
          sticky top-0 z-10 flex items-center justify-between p-4 border-b
          backdrop-blur-xl
          ${isDarkMode 
            ? 'bg-[#121212]/95 border-[#333333]' 
            : 'bg-white/95 border-gray-200'
          }
        `}>
          <h2 className={`
            text-lg font-medium
            ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}
          `}>
            {audience.targetAudienceName}
          </h2>
          <div className="flex items-center gap-2">
            <button
              onClick={handleEdit}
              className={`
                px-3 py-1.5 rounded-md text-sm font-medium
                transition-all duration-200
                ${isDarkMode 
                  ? 'text-slate-200 hover:bg-[#1e1e1e] border border-[#333333]' 
                  : 'text-gray-700 hover:bg-gray-100'
                }
              `}
            >
              Edit
            </button>
            <button
              onClick={onClose}
              className={`
                rounded-full p-1.5
                transition-all duration-200
                ${isDarkMode 
                  ? 'text-slate-400 hover:bg-[#1e1e1e]' 
                  : 'text-gray-500 hover:bg-gray-100'
                }
              `}
            >
              <XMarkIcon className="w-4 h-4" />
            </button>
          </div>
        </div>

        {/* Content */}
        <div className="p-6 space-y-8">
          {fieldGroups.map((group, index) => (
            <div key={index} className="space-y-4">
              <h3 className={`
                text-sm font-medium
                ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}
              `}>
                {group.title}
              </h3>
              <div className={`
                grid grid-cols-1 md:grid-cols-2 gap-6 p-4 rounded-lg
                ${isDarkMode 
                  ? 'bg-[#151515] border border-[#333333]' 
                  : 'bg-gray-50'
                }
              `}>
                {group.fields.map((field, fieldIndex) => (
                  field.value && (
                    <div key={fieldIndex} className="space-y-2">
                      <label className={`
                        text-xs font-medium
                        ${isDarkMode ? 'text-slate-500' : 'text-gray-600'}
                      `}>
                        {field.label}
                      </label>
                      <div className="min-h-[1.5rem]">
                        {renderValue(field.value)}
                      </div>
                    </div>
                  )
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TargetAudienceOverviewPopup;
