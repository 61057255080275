import React, { useState, useEffect } from 'react';
import { UserIcon } from '@heroicons/react/24/solid';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../../context/ThemeContext';
import { fetchAvatarUrl } from '../../utils/avatarUtils';
import { useNavigate } from 'react-router-dom';

const FALLBACK_AVATAR = '/default-avatar.png';

const CompactPersonaCard = ({ persona, onPersonaClick, isSelected, isInChatInterface, onEditInChat }) => {
  const { isDarkMode } = useTheme();
  const [avatarUrl, setAvatarUrl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const loadAvatarUrl = async () => {
      if (persona && persona._id) {
        const url = await fetchAvatarUrl(persona._id);
        setAvatarUrl(url);
      }
    };

    loadAvatarUrl();
  }, [persona]);

  const handleAvatarError = () => {
    setAvatarUrl(FALLBACK_AVATAR);
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    console.log('CompactPersonaCard: handleEditClick', { isInChatInterface, persona });
    if (isInChatInterface && onEditInChat) {
      onEditInChat(persona);
    } else {
      navigate(`/personas/edit/${persona._id}`);
    }
  };

  return (
    <div 
      className={`
        ${isDarkMode 
          ? 'bg-[#1E1E1E] hover:bg-[#2A2A2A] border-[#333333]' 
          : 'bg-white hover:bg-gray-50 border-gray-200'
        } 
        ${isSelected 
          ? isDarkMode 
            ? 'bg-[#2A2A2A]' 
            : 'bg-gray-100'
          : ''
        }
        rounded-md p-2 relative cursor-pointer transition-all duration-200 ease-in-out
        border mb-[5px] group
      `}
      onClick={() => onPersonaClick(persona)}
    >
      <div className="flex items-center">
        {/* Avatar container with gradient border */}
        <div className="relative mr-3 flex-shrink-0">
          <div className={`w-8 h-8 rounded-full p-[1px] ${
            isDarkMode 
              ? 'bg-gradient-to-br from-pink-500 via-red-500 to-yellow-500' 
              : 'bg-gradient-to-br from-pink-400 via-orange-400 to-yellow-400'
          }`}>
            <div className={`w-full h-full rounded-full overflow-hidden ${
              isDarkMode ? 'bg-[#121212]' : 'bg-white'
            }`}>
              {avatarUrl && avatarUrl !== FALLBACK_AVATAR ? (
                <img 
                  src={avatarUrl} 
                  alt={persona.name}
                  className="w-full h-full object-cover" 
                  onError={handleAvatarError}
                />
              ) : (
                <div className={`w-full h-full flex items-center justify-center ${
                  isDarkMode ? 'bg-[#2A2A2A]' : 'bg-gray-200'
                }`}>
                  <UserIcon className={`w-5 h-5 ${
                    isDarkMode ? 'text-gray-400' : 'text-gray-400'
                  }`} />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Name and Occupation Column */}
        <div className="flex-1 min-w-0">
          <h2 className={`text-xs font-medium ${
            isDarkMode ? 'text-gray-200' : 'text-gray-800'
          } truncate`}>
            {persona.name}
          </h2>
          <p className={`text-xs ${
            isDarkMode ? 'text-gray-400' : 'text-gray-600'
          } truncate`}>
            {persona.occupation}
          </p>
        </div>

        {/* Edit Button */}
        <button
          onClick={handleEditClick}
          className={`ml-2 p-1.5 rounded-md opacity-0 group-hover:opacity-100
            transition-all duration-200 ease-in-out
            ${isDarkMode 
              ? 'hover:bg-[#2A2A2A] text-gray-400 hover:text-gray-200' 
              : 'hover:bg-gray-100 text-gray-400 hover:text-gray-600'
            }`}
          title="Edit profile"
        >
          <PencilSquareIcon className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
};

export default CompactPersonaCard;
