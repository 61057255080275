import React, { useState, useCallback, useEffect } from 'react';
import { ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import { ClipboardDocumentIcon as ClipboardDocumentIconSolid, ArrowPathIcon } from '@heroicons/react/24/solid';
import ChatMessageMarkdown from './ChatMessageMarkdown';
import TemplateTag from './TemplateTag';
import '../styles/markdown.css';

// ------------------------------------------------------------------------
// AIResponseChat Component
// ------------------------------------------------------------------------
// This component renders AI responses in a chat interface with support for
// markdown content, error states, copying, and retry functionality
// ------------------------------------------------------------------------

const AIResponseChat = ({ message, index, isDarkMode, copiedMessages, onCopy, onSave, onSaveToTextNotes, personaAvatarUrl, logo, onRetry = () => {}, isAutoScrolling }) => {
  const [isHovering, setIsHovering] = useState(false);
  
  useEffect(() => {
    console.log('AIResponseChat received message:', {
      fullMessage: message,
      content: message?.content,
      messageContent: message?.message?.content,
      structure: {
        hasDirectContent: Boolean(message?.content),
        hasNestedContent: Boolean(message?.message?.content),
        role: message?.role,
        id: message?.id || message?.message?.id || message?._id
      }
    });
  }, [message]);

  const handleFullMessageCopy = useCallback(() => {
    onCopy(message?.content, index);
  }, [message?.content, index, onCopy]);

  const handleRetry = useCallback((content) => {
    if (typeof onRetry === 'function') {
      onRetry(content, index);
    } else {
      console.warn('onRetry prop is not provided to AIResponseChat component');
    }
  }, [onRetry, index]);

  // These lines already handle both formats correctly
  const messageContent = message?.content || message?.message?.content;
  const messageId = message?.id || message?.message?.id || message?._id;
  
  // Add null check for message
  if (!message || !messageContent) {
    console.warn('No valid message content found:', {
      message,
      contentPath1: message?.content,
      contentPath2: message?.message?.content
    });
    return null;
  }

  return (
    <>
      <div 
        id={`ai-message-${messageId || index}`}
        className="mb-7 relative w-full scroll-mt-16"
        data-message-id={messageId}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <div className="flex flex-col items-start">
          <div className="mb-2 z-10">
            <div className={`w-8 h-8 flex items-center justify-center rounded-full overflow-hidden ${
              isDarkMode ? 'bg-[#151515]' : 'bg-white'
            }`}>
              {personaAvatarUrl ? (
                <img
                  src={personaAvatarUrl}
                  alt="Persona Avatar"
                  className="w-full h-full object-cover"
                />
              ) : (
                <img
                  src={logo}
                  alt="AI Avatar"
                  className="w-full h-full object-contain"
                />
              )}
            </div>
          </div>
          <div className={`chat-bubble rounded-xl break-words w-full ${
            isDarkMode
              ? 'bg-[#151515] text-slate-200 border border-white/[0.05]'
              : 'bg-white text-[#1E293B] border border-gray-100'
          }`}>
            <div className="flex items-start px-4 py-3">
              <div className="flex-grow text-left overflow-hidden">
                {message.isError ? (
                  <div className="text-red-500 flex items-center">
                    Error: {message.content}
                    {typeof onRetry === 'function' && (
                      <button
                        className="ml-2 text-blue-500 hover:text-blue-700"
                        onClick={() => handleRetry(message.content)}
                        title="Retry"
                      >
                        <ArrowPathIcon className="h-4 w-4" />
                      </button>
                    )}
                  </div>
                ) : (
                  <>
                    {message.templateTag && (
                      <div className="mb-3">
                        <TemplateTag title={message.templateTag} />
                      </div>
                    )}
                    <div className="message-content overflow-hidden markdown-list-container">
                      <ChatMessageMarkdown
                        content={message.content}
                        isDarkMode={isDarkMode}
                        onSaveToTextNotes={onSaveToTextNotes}
                        index={index}
                        copiedMessages={copiedMessages}
                        onCopy={onCopy}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="absolute left-0 bottom-[-20px] right-0 flex justify-end">
          <div className="mt-1 pr-2">
            <div className={`action-buttons ${isHovering ? 'opacity-100' : 'opacity-0'} transition-opacity duration-200`}>
              {/* Copy button */}
              <button
                className={`p-0.5 transition-colors duration-200 rounded-sm ${
                  isDarkMode ? 'text-slate-400 hover:text-slate-200' : 'text-gray-400 hover:text-gray-600'
                }`}
                onClick={handleFullMessageCopy}
                title={copiedMessages[index] ? "Copied!" : "Copy to clipboard"}
              >
                {copiedMessages[index] ? (
                  <ClipboardDocumentIconSolid className="h-3.5 w-3.5" />
                ) : (
                  <ClipboardDocumentIcon className="h-3.5 w-3.5" />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AIResponseChat;
