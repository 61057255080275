import api from '../api';

export const disconnectLinkedIn = async () => {
  try {
    const response = await api.post('/api/linkedin/disconnect');
    return response.data;
  } catch (error) {
    console.error('Error disconnecting LinkedIn:', error);
    throw error;
  }
}; 