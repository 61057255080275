export const ExpandIcon = ({ className }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 24 24"
    className={className}
  >
    <path 
      fill="currentColor" 
      d="M4.808 20q-.343 0-.576-.232T4 19.192V13.5q0-.213.144-.356T4.501 13t.356.144T5 13.5v4.812L18.312 5H13.5q-.213 0-.356-.144T13 4.499t.144-.356T13.5 4h5.692q.344 0 .576.232t.232.576V10.5q0 .213-.144.356t-.357.144t-.356-.144T19 10.5V5.689L5.689 19H10.5q.213 0 .356.144t.144.357t-.144.356T10.5 20z"
    />
  </svg>
);

export const CollapseIcon = ({ className }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 24 24"
    className={className}
  >
    <path 
      fill="currentColor" 
      d="M3.689 21L3 20.312L10.312 13H5v-1h7v7h-1v-5.312zM12 12V5h1v5.312L20.312 3l.688.688L13.688 11H19v1z"
    />
  </svg>
);
