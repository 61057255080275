import React from 'react';
import { XMarkIcon, UserIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../../context/ThemeContext';
import { useNavigate } from 'react-router-dom';

const FALLBACK_AVATAR = '/default-avatar.png';

const PersonaDetailsPopup = ({ persona, onClose }) => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();

  const handleEdit = () => {
    navigate(`/personas/edit/${persona._id}`);
    onClose();
  };

  const fieldGroups = [
    {
      title: 'Basic Identity',
      description: 'Core characteristics of the writing profile.',
      fields: [
        { label: 'Name', value: persona.name },
        { label: 'Professional Role', value: persona.occupation },
        { label: 'Content Goals', value: persona.contentGoals }
      ]
    },
    {
      title: 'Background & Expertise',
      description: 'Knowledge base and perspective.',
      fields: [
        { label: 'Professional Background', value: persona.personalHistory },
        { label: 'Cultural Perspective', value: persona.culturalBackground },
        { label: 'Areas of Expertise', value: persona.interests },
        { label: 'Topics to Avoid', value: persona.dislikes }
      ]
    },
    {
      title: 'Writing Voice',
      description: 'Expression and communication style.',
      fields: [
        { 
          label: 'Writing Style', 
          value: persona.writingStyle === 'custom' 
            ? persona.writingStyleCustom 
            : persona.writingStyle 
        },
        { 
          label: 'Tone of Voice', 
          value: persona.toneOfVoice === 'custom' 
            ? persona.toneOfVoiceCustom 
            : persona.toneOfVoice 
        }
      ]
    }
  ];

  // Add training examples section if they exist
  if (persona.trainingExamples && persona.trainingExamples.length > 0) {
    fieldGroups.push({
      title: 'Training Examples',
      description: 'Example conversations for the profile.',
      fields: persona.trainingExamples.map((example, index) => ({
        label: `Example ${index + 1}`,
        value: example,  // Pass the example object directly
        isExample: true
      }))
    });
  }

  const renderValue = (value) => {
    if (!value) return null;
    
    // Handle training examples differently
    if (typeof value === 'object' && 'input' in value && 'output' in value) {
      return (
        <div className="space-y-2">
          <div className={`
            p-2 rounded-md border
            ${isDarkMode 
              ? 'bg-[#1a1a1a] border-[#333333]' 
              : 'bg-gray-100 border-gray-200'
            }
          `}>
            <p className={`
              text-xs font-medium mb-1
              ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}
            `}>
              User Input:
            </p>
            <p className={`
              text-sm whitespace-pre-wrap break-words
              ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}
            `}>
              {value.input || ''}
            </p>
          </div>
          <div className={`
            p-2 rounded-md border
            ${isDarkMode 
              ? 'bg-[#1a1a1a] border-[#333333]' 
              : 'bg-gray-100 border-gray-200'
            }
          `}>
            <p className={`
              text-xs font-medium mb-1
              ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}
            `}>
              Profile Response:
            </p>
            <p className={`
              text-sm whitespace-pre-wrap break-words
              ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}
            `}>
              {value.output || ''}
            </p>
          </div>
        </div>
      );
    }

    // Handle regular text values
    if (Array.isArray(value)) {
      return value.map((item, i) => (
        <span 
          key={i} 
          className={`
            inline-block px-2 py-1 mr-2 mb-2 rounded-md text-xs
            ${isDarkMode 
              ? 'bg-[#1a1a1a] text-slate-300 border border-[#333333]' 
              : 'bg-gray-100 text-gray-700'
            }
          `}
        >
          {item}
        </span>
      ));
    }
    return (
      <p className={`
        text-sm whitespace-pre-wrap break-words
        ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}
      `}>
        {String(value)}
      </p>
    );
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className={`
        relative w-full max-w-3xl max-h-[85vh] rounded-lg shadow-xl
        border popup-scrollbar overflow-y-auto
        ${isDarkMode 
          ? 'bg-[#121212] border-[#333333]' 
          : 'bg-white border-gray-200'
        }
      `}>
        {/* Header */}
        <div className={`
          sticky top-0 z-10 flex items-center justify-between p-4 border-b
          backdrop-blur-xl
          ${isDarkMode 
            ? 'bg-[#121212]/95 border-[#333333]' 
            : 'bg-white/95 border-gray-200'
          }
        `}>
          <div className="flex items-center gap-4">
            {/* Avatar */}
            <div className={`
              h-12 w-12 rounded-full overflow-hidden border
              ${isDarkMode 
                ? 'bg-[#1a1a1a] border-[#333333]' 
                : 'bg-gray-100 border-gray-200'
              }
            `}>
              {persona.signedAvatarUrl && persona.signedAvatarUrl !== FALLBACK_AVATAR ? (
                <img 
                  src={persona.signedAvatarUrl} 
                  alt={persona.name}
                  className="h-full w-full object-cover"
                />
              ) : (
                <div className="h-full w-full flex items-center justify-center">
                  <UserIcon className={`h-6 w-6 ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`} />
                </div>
              )}
            </div>
            <div>
              <h2 className={`
                text-lg font-medium
                ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}
              `}>
                {persona.name}
              </h2>
              <p className={`
                text-sm
                ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}
              `}>
                {persona.occupation}
              </p>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <button
              onClick={handleEdit}
              className={`
                px-3 py-1.5 rounded-md text-sm font-medium
                transition-all duration-200
                ${isDarkMode 
                  ? 'text-slate-200 hover:bg-[#1e1e1e] border border-[#333333]' 
                  : 'text-gray-700 hover:bg-gray-100'
                }
              `}
            >
              Edit
            </button>
            <button
              onClick={onClose}
              className={`
                rounded-full p-1.5
                transition-all duration-200
                ${isDarkMode 
                  ? 'text-slate-400 hover:bg-[#1e1e1e]' 
                  : 'text-gray-500 hover:bg-gray-100'
                }
              `}
            >
              <XMarkIcon className="w-4 h-4" />
            </button>
          </div>
        </div>

        {/* Content */}
        <div className="p-6 space-y-8">
          {fieldGroups.map((group, index) => (
            <div key={index} className="space-y-4">
              <div className="space-y-1">
                <h3 className={`
                  text-sm font-medium
                  ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}
                `}>
                  {group.title}
                </h3>
                <p className={`
                  text-xs
                  ${isDarkMode ? 'text-slate-500' : 'text-gray-400'}
                `}>
                  {group.description}
                </p>
              </div>
              <div className={`
                grid grid-cols-1 
                ${group.title !== 'Training Examples' ? 'md:grid-cols-2' : ''} 
                gap-6 p-4 rounded-lg border
                ${isDarkMode 
                  ? 'bg-[#151515] border-[#333333]' 
                  : 'bg-gray-50 border-gray-200'
                }
              `}>
                {group.fields.map((field, fieldIndex) => (
                  field.value && (
                    <div key={fieldIndex} className={`
                      space-y-2 
                      ${field.isExample ? 'col-span-full' : ''}
                    `}>
                      <label className={`
                        text-xs font-medium
                        ${isDarkMode ? 'text-slate-500' : 'text-gray-600'}
                      `}>
                        {field.label}
                      </label>
                      <div className="min-h-[1.5rem]">
                        {renderValue(field.value)}
                      </div>
                    </div>
                  )
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PersonaDetailsPopup;