import api from '../api';

export const generateChatTitle = async (messages) => {
  try {
    const response = await api.post('/api/chat/generate-title', { messages });
    // Remove any quotation marks from the generated title
    const cleanTitle = response.data.title.replace(/["']/g, '');
    return cleanTitle;
  } catch (error) {
    console.error('Error generating chat title:', error);
    throw error;
  }
};