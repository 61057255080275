import axios from 'axios';
import api from '../api';

export const fetchKnowledgeBases = async (folderId = null, userId) => {
  try {
    const response = await api.get('/api/knowledge-bases/knowledge-bases', { 
      params: { 
        parentId: folderId,
        userId: userId 
      } 
    });
    return response.data; // Make sure the size property is included in the returned data
  } catch (error) {
    console.error('Error fetching knowledge bases:', error);
    throw error;
  }
};

export const createFolder = async (parentId, name) => {
  const response = await api.post('/api/knowledge-bases/folder', { parentId, name });
  return response.data;
};

export const createKnowledgeBase = async (parentId, name) => {
  const response = await api.post('/api/knowledge-bases', { parentId, name });
  return response.data;
};

export const deleteItem = async (id) => {
  if (!id) {
    throw new Error('Invalid id: cannot delete item');
  }
  try {
    const response = await api.delete(`/api/knowledge-bases/${id}`);
    if (response.status !== 200) {
      throw new Error('Failed to delete item');
    }
    return response.data;
  } catch (error) {
    console.error('Error deleting item:', error);
    throw error;
  }
};

export const updateItem = async (itemId, updateData) => {
  try {
    const response = await api.put(`/api/knowledge-bases/${itemId}`, updateData);
    return response.data;
  } catch (error) {
    console.error('Error updating item:', error);
    throw error;
  }
};

export const fetchFolderById = async (folderId) => {
  try {
    console.log(`Fetching folder with ID: ${folderId}`);
    const response = await api.get(`/api/knowledge-bases/${folderId}`);
    if (!response.data) {
      throw new Error('Folder not found');
    }
    return response.data;
  } catch (error) {
    console.error('Error fetching folder:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
      throw new Error(`Server error: ${error.response.status} - ${JSON.stringify(error.response.data)}`);
    } else if (error.request) {
      console.error('No response received:', error.request);
      throw new Error('No response received from server');
    } else {
      console.error('Error details:', error.message);
      throw new Error(`Error fetching folder: ${error.message}`);
    }
  }
};

export const saveFileMetadata = async (fileData) => {
  try {
    // First, check if a file with the same name and parentId already exists
    const checkResponse = await api.get('/api/checkFileMetadata', {
      params: { name: fileData.name, parentId: fileData.parentId }
    });

    if (checkResponse.data.exists) {
      // If file exists, generate a new unique name
      fileData.name = `${fileData.name} (${Date.now()})`;
    }

    // Now save the file metadata
    const response = await api.post('/api/saveFileMetadata', fileData);

    if (!response.data) {
      throw new Error('Failed to save file metadata');
    }

    return response.data;
  } catch (error) {
    console.error('Error saving file metadata:', error);
    throw error;
  }
};

export const getAllFolders = async () => {
  try {
    const response = await axios.get('/api/knowledge-base/folders');
    return response.data;
  } catch (error) {
    console.error('Error fetching all folders:', error);
    throw error;
  }
};

export const getStorageUsage = async () => {
  try {
    const response = await api.get('/api/knowledge-bases/storage-usage');
    return response.data;
  } catch (error) {
    console.error('Error fetching storage usage:', error);
    throw error;
  }
};
