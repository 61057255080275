import api from '../api';
import { TokenStorage } from '../utils/tokenStorage';

/**
 * Publishes a post to LinkedIn
 * @param {string} content - The content of the post
 * @param {string} title - The title of the post (optional)
 * @returns {Promise} - A promise that resolves when the post is published
 */
export const publishToLinkedIn = async (content, title = '', retryCount = 0) => {
  try {
    // First check if user is connected to LinkedIn
    const statusResponse = await isLinkedInConnected();
    console.log('LinkedIn status check:', statusResponse);
    
    if (!statusResponse.isConnected || statusResponse.needsReconnect) {
      // Add more detailed error information
      const errorDetails = {
        isConnected: statusResponse.isConnected,
        needsReconnect: statusResponse.needsReconnect,
        linkedinId: statusResponse.linkedinId,
        originalError: statusResponse.error
      };
      console.error('LinkedIn connection issue:', errorDetails);

      // Prevent infinite recursion
      if (retryCount >= 1) {
        throw new Error('Failed to establish LinkedIn connection after retry');
      }

      // Try to refresh the connection
      try {
        const refreshResponse = await api.post('/api/linkedin/refresh');
        if (refreshResponse.data.success) {
          console.log('Successfully refreshed LinkedIn connection');
          return publishToLinkedIn(content, title, retryCount + 1);
        }
      } catch (refreshError) {
        console.error('LinkedIn refresh failed:', refreshError);
      }

      throw new Error(statusResponse.error || 'LinkedIn connection required. Please reconnect your account.');
    }

    // Add request logging
    console.log('Attempting to publish to LinkedIn:', {
      contentLength: content?.length,
      hasTitle: !!title,
      timestamp: new Date().toISOString()
    });

    const response = await api.post('/api/linkedin/publish', {
      content,
      title
    });
    
    console.log('LinkedIn publish success:', response.data);
    return response.data;
  } catch (error) {
    console.error('LinkedIn publish error:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status
    });
    
    // Enhanced error handling
    if (error.response?.status === 401) {
      throw new Error('LinkedIn authentication expired. Please reconnect your account.');
    }
    
    throw new Error(error.response?.data?.error || error.message || 'Failed to publish to LinkedIn');
  }
};

/**
 * Checks if the user is connected to LinkedIn
 * @returns {Promise<{isConnected: boolean, needsReconnect: boolean}>} - A promise that resolves to connection status
 */
export const isLinkedInConnected = async () => {
  try {
    console.log('Checking LinkedIn connection status...');
    const response = await api.get('/api/linkedin/status');
    
    // Add more detailed logging
    console.log('LinkedIn status response:', {
      isConnected: response.data.isConnected,
      needsReconnect: response.data.needsReconnect,
      hasLinkedinId: !!response.data.linkedinId,
      error: response.data.error
    });

    return {
      isConnected: response.data.isConnected,
      needsReconnect: response.data.needsReconnect,
      linkedinId: response.data.linkedinId,
      error: response.data.error,
      message: response.data.message
    };
  } catch (error) {
    console.error('LinkedIn status check error:', error);
    return {
      isConnected: false,
      needsReconnect: true,
      linkedinId: null,
      error: error.message || 'Failed to check LinkedIn connection status'
    };
  }
}; 