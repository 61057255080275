import React, { useState, useRef, useEffect } from 'react';
import { useTheme } from '../context/ThemeContext';
import { useRecentActivities } from '../context/RecentActivitiesContext';
import { socket } from '../socket';
import { 
  PencilIcon, 
  TrashIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  CheckIcon,
  DocumentDuplicateIcon,
  ClipboardDocumentCheckIcon,
  ShareIcon,
  EllipsisVerticalIcon
} from '@heroicons/react/24/outline';
import { DocumentDuplicateIcon as DocumentDuplicateSolidIcon } from '@heroicons/react/24/solid';
import { Button } from './ui/button';
import ResetIcon from './personas/ResetIcon';
import { publishToLinkedIn, isLinkedInConnected } from '../services/linkedinPublishService';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { getLinkedInAuthUrl } from '../services/linkedinAuthService';
import Portal from './ui/Portal';
import { ExpandChatIcon } from './icons/ExpandCollapseIcons';

const TitleForLinkedInPostMockup = ({ 
  initialTitle, 
  onTitleChange,
  onEdit,
  onRemove,
  onCopy,
  currentIndex,
  totalPosts,
  onNavigateNext,
  onNavigatePrevious,
  isFocusMode = false,
  isEditing = false,
  onSave,
  onCancel,
  isInCanvasExperience = false,
  isInteracting = false,
  onDelete,
  isHovered = false,
  canvasId,
  postId,
  selectedPersona,
  onFullScreen,
  content
}) => {
  const { isDarkMode } = useTheme();
  const { refreshActivities } = useRecentActivities();
  const [postTitle, setPostTitle] = useState(initialTitle || '');
  const [editingTitle, setEditingTitle] = useState(initialTitle || '');
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const [copySuccess, setCopySuccess] = useState(false);
  const copyTimeoutRef = useRef(null);
  const [showHyphenWarning, setShowHyphenWarning] = useState(false);
  const warningTimeoutRef = useRef(null);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isLinkedInEnabled, setIsLinkedInEnabled] = useState(false);
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const buttonRef = useRef(null);

  // Update this effect to use a transition
  useEffect(() => {
    if (initialTitle !== postTitle) {
      setPostTitle(initialTitle || '');
      setEditingTitle(initialTitle || '');
    }
  }, [initialTitle, postTitle]);

  // Check LinkedIn connection status
  useEffect(() => {
    const checkLinkedInStatus = async () => {
      const isConnected = await isLinkedInConnected();
      setIsLinkedInEnabled(isConnected);
    };
    checkLinkedInStatus();
  }, []);

  const handleTitleChange = (e) => {
    const hasHyphen = e.target.value.includes('-');
    if (hasHyphen) {
      setShowHyphenWarning(true);
      // Clear any existing timeout
      if (warningTimeoutRef.current) {
        clearTimeout(warningTimeoutRef.current);
      }
      // Hide warning after 2 seconds
      warningTimeoutRef.current = setTimeout(() => {
        setShowHyphenWarning(false);
      }, 2000);
    }
    const sanitizedValue = e.target.value.replace(/-/g, '');
    setEditingTitle(sanitizedValue);
  };

  const handleTitleSave = async () => {
    const newTitle = editingTitle.trim();
    if (newTitle && newTitle !== postTitle) {
      const sanitizedTitle = newTitle.replace(/-/g, '');
      setPostTitle(sanitizedTitle);
      onTitleChange(sanitizedTitle);
    }
    setIsEditingTitle(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleTitleSave();
    } else if (e.key === 'Escape') {
      setEditingTitle(initialTitle);
      setIsEditingTitle(false);
    }
  };

  useEffect(() => {
    if (isEditingTitle && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isEditingTitle]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        handleTitleSave();
      }
    };

    if (isEditingTitle) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditingTitle]);

  const titleStyles = `
    py-1 text-xs font-medium w-full truncate
    ${isDarkMode 
      ? 'text-[#e8e8e8] bg-transparent' 
      : 'text-gray-800 bg-transparent'}
    ${isEditing ? 'cursor-text' : 'cursor-pointer'}
  `;

  const placeholderStyles = `
    ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}
  `;

  // Update this function to handle raw title without formatting
  const formatTitle = (title) => {
    const customTitle = title.trim() ? title : 'Add post title';
    // Only add the "Post X -" prefix when displaying, not editing
    return isEditingTitle ? customTitle : `Post ${currentIndex + 1} - ${customTitle}`;
  };

  const staticPartStyles = `
    ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}
    mr-1
  `;

  useEffect(() => {
    if (copySuccess) {
      if (copyTimeoutRef.current) {
        clearTimeout(copyTimeoutRef.current);
      }
      copyTimeoutRef.current = setTimeout(() => {
        setCopySuccess(false);
      }, 2000); // Increased from 1000 to 2000ms for better visibility
    }
    return () => {
      if (copyTimeoutRef.current) {
        clearTimeout(copyTimeoutRef.current);
      }
    };
  }, [copySuccess]);

  // Update the save handler
  const handleSave = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    
    // Just call onSave since the content is managed by the parent component
    if (onSave) {
      onSave();
    }
  };

  const handleFullScreen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onFullScreen();
  };

  // Clean up timeout on unmount
  useEffect(() => {
    return () => {
      if (warningTimeoutRef.current) {
        clearTimeout(warningTimeoutRef.current);
      }
    };
  }, []);

  // Add this near the top with other style constants
  const tooltipStyles = `
    absolute -top-8 left-0
    px-2 py-1
    text-xs text-white
    bg-gray-800 dark:bg-gray-700
    rounded-md shadow-sm
    transition-opacity duration-200
    z-10
    whitespace-nowrap
    after:content-['']
    after:absolute
    after:top-full
    after:left-4
    after:border-4
    after:border-transparent
    after:border-t-gray-800
    dark:after:border-t-gray-700
  `;

  // Add logging for content prop
  useEffect(() => {
    console.log('TitleForLinkedInPostMockup received content:', {
      hasContent: !!content,
      contentLength: content?.length,
      contentPreview: content?.substring(0, 50)
    });
  }, [content]);

  // Add reconnect handler
  const handleReconnectLinkedIn = async () => {
    try {
      const state = `reconnect-${Date.now()}`;
      const authUrl = await getLinkedInAuthUrl(state);
      window.location.href = authUrl;
    } catch (error) {
      console.error('Error getting LinkedIn auth URL:', error);
      toast.error('Failed to initiate LinkedIn reconnection');
    }
  };

  // Update publish handler
  const handlePublish = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    console.log('Attempting to publish content:', {
      hasContent: !!content,
      contentLength: content?.length,
      contentPreview: content?.substring(0, 50),
      postTitle
    });

    if (!content) {
      toast.error('Cannot publish empty content');
      return;
    }

    try {
      setIsPublishing(true);
      
      // First check LinkedIn connection status
      const status = await isLinkedInConnected();
      console.log('LinkedIn connection status:', status);
      
      if (!status.isConnected || status.needsReconnect) {
        const message = status.error || 
          (status.needsReconnect 
            ? 'LinkedIn authentication has expired' 
            : 'Please connect your LinkedIn account first');
        
        toast.error(
          <div className="flex flex-col space-y-2">
            <p>{message}</p>
            <button
              onClick={handleReconnectLinkedIn}
              className="text-sm px-4 py-1.5 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            >
              {status.needsReconnect ? 'Reconnect LinkedIn' : 'Connect LinkedIn'}
            </button>
          </div>,
          { duration: 8000 }
        );
        return;
      }
      
      const result = await publishToLinkedIn(content, postTitle);
      console.log('Publish result:', result);
      toast.success('Successfully published to LinkedIn!');
      
      if (refreshActivities) {
        refreshActivities();
      }
    } catch (error) {
      console.error('Error publishing to LinkedIn:', error);
      
      const errorMessage = error.message || 'Failed to publish to LinkedIn';
      const needsReconnect = errorMessage.includes('expired') || 
                            errorMessage.includes('connect') ||
                            errorMessage.includes('authentication');
      
      if (needsReconnect) {
        toast.error(
          <div className="flex flex-col space-y-2">
            <p>{errorMessage}</p>
            <button
              onClick={handleReconnectLinkedIn}
              className="text-sm px-4 py-1.5 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            >
              {errorMessage.includes('expired') ? 'Reconnect LinkedIn' : 'Connect LinkedIn'}
            </button>
          </div>,
          { duration: 8000 }
        );
      } else {
        toast.error(errorMessage);
      }
    } finally {
      setIsPublishing(false);
    }
  };

  // Add this tooltip component
  const Tooltip = ({ children, content }) => (
    <div className="group relative">
      {children}
      <div className={`absolute left-1/2 -translate-x-1/2 -top-8 px-2 py-1 bg-gray-900 text-white text-xs rounded 
        opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap pointer-events-none
        ${isDarkMode ? 'bg-gray-800' : 'bg-gray-900'}`}>
        {content}
        <div className="absolute -bottom-1 left-1/2 -translate-x-1/2 border-4 border-transparent border-t-gray-900"></div>
      </div>
    </div>
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (buttonRef.current?.contains(event.target)) {
        return;
      }
      
      if (!event.target.closest('.dropdown-menu')) {
        setShowDropdown(false);
      }
    };

    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [showDropdown]);

  const updateDropdownPosition = () => {
    if (!buttonRef.current) return;

    const buttonRect = buttonRef.current.getBoundingClientRect();
    
    let top = buttonRect.bottom + 5;
    let left = buttonRect.left;

    const dropdownWidth = 140;
    if (left + dropdownWidth > window.innerWidth) {
      left = window.innerWidth - dropdownWidth - 10;
    }

    if (left < 10) {
      left = 10;
    }

    const dropdownHeight = 82;
    if (top + dropdownHeight > window.innerHeight) {
      top = buttonRect.top - dropdownHeight - 5;
    }

    setDropdownPosition({ top, left });
  };

  const handleDropdownToggle = (e) => {
    e.stopPropagation();
    if (!showDropdown) {
      updateDropdownPosition();
    }
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    if (showDropdown) {
      const handleScroll = () => {
        if (showDropdown) {
          updateDropdownPosition();
        }
      };

      const handleResize = () => {
        if (showDropdown) {
          updateDropdownPosition();
        }
      };

      window.addEventListener('scroll', handleScroll, true);
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('scroll', handleScroll, true);
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [showDropdown]);

  return (
    <div 
      className="m-2 ml-0 px-0 pl-2"
    >
      <div ref={containerRef} className="flex items-center justify-between w-full">
        <div className="flex-1 flex items-center min-w-0">
          {isEditingTitle ? (
            <div className="flex flex-col w-full relative">
              {showHyphenWarning && (
                <div className={tooltipStyles}>
                  Hyphens are not allowed in the title
                </div>
              )}
              <div className={`${titleStyles} flex items-center`}>
                <input
                  ref={inputRef}
                  type="text"
                  value={editingTitle}
                  onChange={handleTitleChange}
                  onBlur={handleTitleSave}
                  onKeyDown={handleKeyPress}
                  className="bg-transparent focus:outline-none w-full"
                  placeholder="Add title"
                />
              </div>
            </div>
          ) : (
            <div
              onClick={() => setIsEditingTitle(true)}
              className={`${titleStyles} cursor-pointer truncate`}
            >
              <span 
                className={`${postTitle.trim() ? '' : placeholderStyles} truncate`}
              >
                {formatTitle(postTitle)}
              </span>
            </div>
          )}
        </div>

        {/* Update the action buttons container by removing the opacity transition */}
        <div className={`
          flex items-center space-x-2
          ${isEditing ? 'opacity-100' : 'opacity-100'}
        `}>
          {isEditing ? (
            <>
              <Button
                onClick={handleSave}
                variant="ghost"
                size="tiny-with-text"
                isDarkMode={isDarkMode}
                aria-label="Save changes"
                className="flex items-center gap-1"
              >
                <CheckIcon />
                <span>Save</span>
              </Button>
              <Button
                onClick={onCancel}
                variant="ghost"
                size="tiny"
                isDarkMode={isDarkMode}
                aria-label="Cancel changes"
              >
                <ResetIcon className="w-5 h-5" />
              </Button>
              <Button
                onClick={handleFullScreen}
                variant="ghost"
                size="tiny"
                isDarkMode={isDarkMode}
                aria-label="Edit in full screen"
              >
                <ExpandChatIcon />
              </Button>
            </>
          ) : (
            <>
              <Button
                onClick={handlePublish}
                variant="ghost"
                size="tiny-with-text"
                isDarkMode={isDarkMode}
                aria-label="Share to LinkedIn"
                className="flex items-center gap-1 hover:bg-blue-500/10"
              >
                <ShareIcon className="w-4 h-4 text-blue-500" />
                <span className="text-blue-500">Publish</span>
              </Button>

              <Button
                onClick={handleFullScreen}
                variant="ghost"
                size="tiny"
                isDarkMode={isDarkMode}
                aria-label="Edit in full screen"
              >
                <ExpandChatIcon />
              </Button>

              <div className="relative dropdown-container inline-block">
                <Button
                  ref={buttonRef}
                  onClick={handleDropdownToggle}
                  variant="ghost"
                  size="tiny"
                  isDarkMode={isDarkMode}
                  aria-label="More options"
                >
                  <EllipsisVerticalIcon className="w-5 h-5" />
                </Button>

                {showDropdown && (
                  <Portal>
                    <div 
                      className="fixed inset-0 z-[999]" 
                      onClick={(e) => {
                        if (e.target === e.currentTarget) {
                          setShowDropdown(false);
                        }
                      }}
                    >
                      <div
                        className={`
                          fixed
                          min-w-[140px]
                          ${isDarkMode 
                            ? 'bg-[#1e1e1e] border-[#333333]' 
                            : 'bg-white border-gray-200'
                          }
                          rounded-lg shadow-lg 
                          border
                          z-[1000]
                          dropdown-menu
                          animate-dropdown-fade-in
                          ${isDarkMode 
                            ? 'ring-1 ring-slate-600/50 shadow-xl shadow-black/20' 
                            : 'ring-1 ring-gray-200/50 shadow-xl shadow-black/10'
                          }
                        `}
                        style={{
                          top: `${dropdownPosition.top}px`,
                          left: `${dropdownPosition.left}px`,
                        }}
                      >
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            onEdit();
                            setShowDropdown(false);
                          }}
                          className={`
                            flex items-center px-3 py-2 text-sm w-full
                            ${isDarkMode 
                              ? 'hover:bg-slate-700/50 text-slate-200' 
                              : 'hover:bg-gray-100/80 text-gray-700'
                            }
                            transition-colors duration-150
                            first:rounded-t-lg
                          `}
                        >
                          <PencilIcon className="w-4 h-4 mr-2" />
                          <span className="whitespace-nowrap text-xs font-medium">
                            Edit post
                          </span>
                        </button>

                        <div className={`h-[1px] mx-2 ${isDarkMode ? 'bg-[#333333]' : 'bg-gray-200'}`} />

                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            onCopy();
                            setCopySuccess(true);
                            toast.success('Post copied to clipboard');
                            setTimeout(() => setShowDropdown(false), 500);
                          }}
                          className={`
                            flex items-center px-3 py-2 text-sm w-full
                            ${isDarkMode 
                              ? 'hover:bg-slate-700/50 text-slate-200' 
                              : 'hover:bg-gray-100/80 text-gray-700'
                            }
                            transition-colors duration-150
                          `}
                        >
                          {copySuccess ? (
                            <DocumentDuplicateSolidIcon className="w-4 h-4 mr-2" />
                          ) : (
                            <DocumentDuplicateIcon className="w-4 h-4 mr-2" />
                          )}
                          <span className="whitespace-nowrap text-xs font-medium">
                            {copySuccess ? 'Copied!' : 'Copy post'}
                          </span>
                        </button>

                        <div className={`h-[1px] mx-2 ${isDarkMode ? 'bg-[#333333]' : 'bg-gray-200'}`} />

                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            if (window.confirm('Are you sure you want to delete this post?')) {
                              onRemove();
                              toast.success('Post deleted');
                              setShowDropdown(false);
                            }
                          }}
                          className={`
                            flex items-center px-3 py-2 text-sm w-full
                            ${isDarkMode 
                              ? 'hover:bg-slate-700/50 text-red-400' 
                              : 'hover:bg-gray-100/80 text-red-500'
                            }
                            transition-colors duration-150
                            last:rounded-b-lg
                          `}
                        >
                          <TrashIcon className="w-4 h-4 mr-2" />
                          <span className="whitespace-nowrap text-xs font-medium">
                            Delete post
                          </span>
                        </button>
                      </div>
                    </div>
                  </Portal>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(TitleForLinkedInPostMockup); // Add memo to prevent unnecessary re-renders