import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import YouIdeaItem from './YouIdeaItem';
import RedditDataModal from './RedditDataModal';
import { LightBulbIcon } from '@heroicons/react/24/outline';

// Add the EmptyState component
const EmptyState = ({ isDarkMode, searchTerm }) => (
  <motion.div 
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.4, ease: "easeOut" }}
    className={`
      flex flex-col items-center justify-center 
      w-full max-w-[480px] mx-auto
      aspect-[4/3]
      ${isDarkMode 
        ? 'bg-[#151515] border-[#333333]' 
        : 'bg-white border-slate-200'
      }
      rounded-xl
      border
      shadow-sm
    `}
  >
    <div className="flex flex-col items-center max-w-[280px] text-center">
      <motion.div 
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ 
          delay: 0.2,
          duration: 0.5,
          ease: [0.23, 1, 0.32, 1]
        }}
        className={`
          mb-6 p-4 rounded-full
          ${isDarkMode 
            ? 'bg-[#1e1e1e]' 
            : 'bg-slate-50'
          }
        `}
      >
        <LightBulbIcon className={`
          w-8 h-8
          ${isDarkMode 
            ? 'text-yellow-400/80' 
            : 'text-yellow-500/80'
          }
        `} />
      </motion.div>
      
      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3, duration: 0.4 }}
        className="space-y-3"
      >
        <h2 className={`
          text-lg font-medium
          ${isDarkMode ? 'text-slate-200' : 'text-slate-900'}
        `}>
          {searchTerm 
            ? 'No matching insights found'
            : 'No insights yet'}
        </h2>
        
        <p className={`
          text-sm
          ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}
        `}>
          {searchTerm 
            ? `No insights match "${searchTerm}"`
            : 'Generate your first insight to get started'}
        </p>
      </motion.div>
    </div>
  </motion.div>
);

const AllInsightsView = ({ 
  ideas, 
  isDarkMode, 
  searchTerm, 
  onToggleFavorite, 
  onDelete,
  markdownStyles,
  onViewRedditData
}) => {
  const newItemRef = useRef(null);
  const [redditModalData, setRedditModalData] = useState(null);

  // Scroll to the new item when it's rendered
  useEffect(() => {
    if (newItemRef.current) {
      newItemRef.current.scrollIntoView({ 
        behavior: 'smooth', 
        block: 'start'
      });
    }
  }, []);

  // Handler for opening Reddit data modal
  const handleOpenRedditData = (redditData) => {
    setRedditModalData(redditData);
  };

  // Handler for closing Reddit data modal
  const handleCloseRedditData = () => {
    setRedditModalData(null);
  };

  // Group ideas by timestamp (rounded to the nearest minute to group ideas generated together)
  const groupedIdeas = ideas.reduce((groups, idea) => {
    const timestamp = new Date(idea.createdAt);
    timestamp.setSeconds(0, 0); // Round to nearest minute
    const key = timestamp.getTime();
    if (!groups[key]) groups[key] = [];
    groups[key].push(idea);
    return groups;
  }, {});

  const sortedGroups = Object.entries(groupedIdeas)
    .sort(([timeA], [timeB]) => Number(timeB) - Number(timeA));

  return (
    <div className="flex-1 overflow-y-auto custom-scrollbar">
      <div className={`
        w-full h-full min-h-[calc(100vh-4rem)]
        flex flex-col
      `}>
        {ideas.length === 0 ? (
          <div className="flex-1 flex items-center justify-center p-6">
            <EmptyState isDarkMode={isDarkMode} searchTerm={searchTerm} />
          </div>
        ) : (
          <div className="max-w-4xl mx-auto w-full p-6 space-y-12">
            {sortedGroups.map(([timestamp, groupIdeas], groupIndex) => {
              const isNewGroup = groupIdeas.some(idea => idea.isNew);
              const formattedDate = new Date(Number(timestamp)).toLocaleString();
              
              return (
                <motion.div
                  key={timestamp}
                  initial={isNewGroup ? { opacity: 0, y: 20 } : false}
                  animate={isNewGroup ? { opacity: 1, y: 0 } : false}
                  transition={{ duration: 0.5 }}
                  ref={isNewGroup ? newItemRef : null}
                  className={`
                    space-y-4 p-6 rounded-xl
                    ${isNewGroup 
                      ? isDarkMode
                        ? 'bg-[#1a1a1a] border border-[#333333] shadow-[0_0_15px_rgba(234,88,12,0.1)]'
                        : 'bg-orange-50/30 shadow-[0_0_15px_rgba(234,88,12,0.05)]'
                      : isDarkMode
                        ? 'bg-[#151515]/20'
                        : 'bg-white/20'
                    }
                  `}
                >
                  <div className={`text-xs font-medium ${
                    isDarkMode ? 'text-slate-400' : 'text-gray-500'
                  }`}>
                    {formattedDate}
                    {isNewGroup && (
                      <span className={`
                        ml-2 px-2 py-0.5 rounded-full text-xs
                        ${isDarkMode
                          ? 'bg-[#1a1a1a] text-orange-300 border border-orange-500/30'
                          : 'bg-orange-100/50 text-orange-600'
                        }
                      `}>
                        New
                      </span>
                    )}
                  </div>
                  
                  <div className="space-y-4">
                    {groupIdeas.map((idea) => (
                      <YouIdeaItem
                        key={idea._id}
                        idea={idea}
                        isDarkMode={isDarkMode}
                        onToggleFavorite={() => onToggleFavorite(idea._id)}
                        onDelete={() => onDelete(idea._id)}
                        markdownStyles={markdownStyles}
                        isHighlighted={idea.isNew}
                        searchTerm={searchTerm}
                        onViewRedditData={onViewRedditData}
                      />
                    ))}
                  </div>
                </motion.div>
              );
            })}
          </div>
        )}
      </div>

      {/* Reddit Data Modal */}
      <RedditDataModal
        isOpen={redditModalData !== null}
        onClose={handleCloseRedditData}
        redditData={redditModalData || []}
        isDarkMode={isDarkMode}
      />
    </div>
  );
};

export default AllInsightsView; 