import React, { useEffect } from 'react';
import { useTheme } from '../../context/ThemeContext';
import { usePostComments } from '../../context/PostCommentsContext';
import { motion } from 'framer-motion';
import { 
  ClockIcon, 
  TrashIcon, 
  ClipboardDocumentIcon,
  ArrowLeftIcon,
  ClipboardDocumentIcon as ClipboardDocumentIconSolid,
  MagnifyingGlassIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { createPortal } from 'react-dom';
import Masonry from 'react-masonry-css';
import { useInView } from 'react-intersection-observer';
import { useAuth } from '../../context/AuthContext';

const LoadingSpinner = () => (
  <div className="flex items-center justify-center space-x-1">
    <div className="w-2 h-2 rounded-full bg-blue-500 animate-[bounce_0.9s_infinite]" />
    <div className="w-2 h-2 rounded-full bg-blue-500 animate-[bounce_0.9s_0.3s_infinite]" />
    <div className="w-2 h-2 rounded-full bg-blue-500 animate-[bounce_0.9s_0.6s_infinite]" />
  </div>
);

const TopBar = ({ searchTerm, setSearchTerm, isDarkMode, isSearchExpanded, setIsSearchExpanded, onBack }) => (
  <div className={`${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} bg-opacity-90 h-10 flex-shrink-0 flex items-center justify-between px-4 py-2 w-full border-b border-opacity-50`}>
    {/* Left section */}
    <div className="flex items-center">
      <button 
        onClick={onBack}
        className={`mr-2 p-1 rounded-lg transition-colors duration-200 ${
          isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'
        }`}
      >
        <ArrowLeftIcon className={`h-5 w-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`} />
      </button>
      <ClockIcon className={`h-5 w-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'} mr-2`} />
      <h2 className={`text-sm font-medium ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>Comment History</h2>
    </div>

    {/* Right section - Search */}
    <div className="flex items-center">
      <ExpandableSearchBar
        isDarkMode={isDarkMode}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        isExpanded={isSearchExpanded}
        setIsExpanded={setIsSearchExpanded}
      />
    </div>
  </div>
);

const ExpandableSearchBar = ({ isDarkMode, searchTerm, setSearchTerm, isExpanded, setIsExpanded }) => {
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if (isExpanded) {
      inputRef.current?.focus();
    }
  }, [isExpanded]);

  return (
    <div className="relative">
      {isExpanded ? (
        <div className="flex items-center">
          <input
            ref={inputRef}
            type="text"
            placeholder="Search history..."
            className={`w-40 pl-8 pr-8 py-1 text-xs border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500
                        ${isDarkMode 
                          ? 'bg-gray-700 text-gray-200 border-gray-600' 
                          : 'bg-white text-gray-900 border-gray-300'}`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <MagnifyingGlassIcon className={`absolute left-2 top-1/2 transform -translate-y-1/2 w-4 h-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-400'}`} />
          <button
            onClick={() => setIsExpanded(false)}
            className="absolute right-2 top-1/2 transform -translate-y-1/2"
          >
            <XMarkIcon className={`w-4 h-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-400'}`} />
          </button>
        </div>
      ) : (
        <button
          onClick={() => setIsExpanded(true)}
          className={`p-1 rounded-full transition-colors duration-200 ${
            isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-200'
          }`}
        >
          <MagnifyingGlassIcon className={`h-5 w-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`} />
        </button>
      )}
    </div>
  );
};

const masonryBreakpoints = {
  default: 3,
  1100: 2,
  700: 1
};

const styles = `
  .masonry-grid {
    display: flex;
    width: auto;
    margin-left: -16px; /* gutter size offset */
  }

  .masonry-grid_column {
    padding-left: 16px; /* gutter size */
    background-clip: padding-box;
  }

  .masonry-grid_column > div {
    margin-bottom: 16px;
  }

  /* Optional fade-in animation for items */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .masonry-item {
    animation: fadeInUp 0.3s ease forwards;
  }
`;

const CommentHistory = () => {
  const { 
    commentHistory, 
    deleteHistoryItem,
    copyToClipboard,
    pagination = { page: 1, pages: 1 },
    loadMoreComments,
    isLoadingMore = false,
    deletingComments = new Set(),
    fetchCommentHistory,
    isLoading,
    error
  } = usePostComments();
  
  const { ref: loadMoreRef, inView } = useInView({
    threshold: 0.5,
    triggerOnce: false
  });

  const { isAuthenticated } = useAuth();

  useEffect(() => {
    const loadHistory = async () => {
      if (!isAuthenticated) {
        return;
      }
      
      try {
        await fetchCommentHistory(1);
      } catch (error) {
        console.error('Error loading comment history:', error);
      }
    };

    loadHistory();
  }, [fetchCommentHistory, isAuthenticated]);

  useEffect(() => {
    if (inView && !isLoadingMore && pagination?.page < pagination?.pages) {
      loadMoreComments();
    }
  }, [inView, isLoadingMore, pagination, loadMoreComments]);

  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const [copiedComments, setCopiedComments] = React.useState({});
  const [searchTerm, setSearchTerm] = React.useState('');
  const [isSearchExpanded, setIsSearchExpanded] = React.useState(false);
  const [filteredHistory, setFilteredHistory] = React.useState(commentHistory);

  React.useEffect(() => {
    if (searchTerm) {
      const filtered = commentHistory.filter(item => 
        item.postContent.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.comment.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredHistory(filtered);
    } else {
      setFilteredHistory(commentHistory);
    }
  }, [searchTerm, commentHistory]);

  const handleCopy = async (text, id) => {
    await copyToClipboard(text);
    setCopiedComments(prev => ({ ...prev, [id]: true }));
    setTimeout(() => {
      setCopiedComments(prev => ({ ...prev, [id]: false }));
    }, 2000);
  };

  const handleDelete = async (id) => {
    try {
      await deleteHistoryItem(id);
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };

  return (
    <div className={`h-full ${isDarkMode ? 'bg-gray-900 text-gray-100' : 'bg-white text-gray-900'}`}>
      <style>{styles}</style>
      <TopBar 
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        isDarkMode={isDarkMode}
        isSearchExpanded={isSearchExpanded}
        setIsSearchExpanded={setIsSearchExpanded}
        onBack={() => navigate('/post-comments')}
      />
      
      <div className="max-w-7xl mx-auto px-4 pt-6">
        {!isAuthenticated ? (
          <div className={`text-center py-12 ${
            isDarkMode ? 'text-gray-300' : 'text-gray-700'
          }`}>
            <p>Please log in to view your comment history.</p>
          </div>
        ) : error ? (
          <div className={`mb-4 p-3 rounded-lg ${
            isDarkMode 
              ? 'bg-red-900/20 border border-red-800/50 text-red-400' 
              : 'bg-red-50 border border-red-200 text-red-600'
          }`}>
            <p className="text-sm">{error}</p>
          </div>
        ) : isLoading && filteredHistory.length === 0 ? (
          <div className="flex justify-center py-12">
            <LoadingSpinner />
          </div>
        ) : filteredHistory.length === 0 ? (
          <EmptyState searchTerm={searchTerm} isDarkMode={isDarkMode} error={error} />
        ) : (
          <Masonry
            breakpointCols={masonryBreakpoints}
            className="masonry-grid"
            columnClassName="masonry-grid_column"
          >
            {filteredHistory.map((item) => (
              <CommentCard
                key={item._id}
                item={item}
                isDarkMode={isDarkMode}
                onDelete={handleDelete}
                onCopy={handleCopy}
                copiedComments={copiedComments}
                isDeleting={deletingComments?.has(item._id)}
              />
            ))}
          </Masonry>
        )}
        
        {/* Infinite scroll trigger */}
        {!isLoadingMore && pagination?.page < pagination?.pages && (
          <div ref={loadMoreRef} className="h-10" />
        )}
        
        {/* Loading indicator */}
        {isLoadingMore && (
          <div className="flex justify-center py-4">
            <LoadingSpinner />
          </div>
        )}
      </div>
    </div>
  );
};

const EmptyState = ({ searchTerm, isDarkMode, error }) => (
  <div className="text-center py-12">
    <ClockIcon className="h-12 w-12 mx-auto text-gray-400 mb-4" />
    <p className="text-gray-500 dark:text-gray-400">
      {searchTerm ? 'No matching results found' : 'No comment history yet'}
    </p>
    {error && (
      <div className="mt-2 p-2 rounded-lg bg-red-50 border border-red-200 text-red-600">
        <p className="text-sm">{error}</p>
      </div>
    )}
  </div>
);

const CommentCard = ({ 
  item, 
  isDarkMode, 
  onDelete, 
  onCopy, 
  copiedComments,
  isDeleting 
}) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ 
      opacity: isDeleting ? 0 : 1,
      y: isDeleting ? -20 : 0,
      scale: isDeleting ? 0.95 : 1
    }}
    exit={{ opacity: 0, y: -20 }}
    transition={{ duration: 0.3 }}
    className={`masonry-item p-4 rounded-xl border ${
      isDarkMode 
        ? 'bg-gray-800/50 border-gray-700 hover:bg-gray-800/70' 
        : 'bg-white border-gray-200 hover:bg-gray-50'
    } relative group transition-colors duration-200 ${
      isDeleting ? 'opacity-50' : ''
    }`}
  >
    {/* Post Content */}
    <div className="mb-4">
      <div className="flex items-center justify-between mb-2">
        <h3 className="font-medium text-sm">Original Post</h3>
        <span className="text-xs text-gray-500">
          {format(new Date(item.createdAt), 'MMM d, yyyy')}
        </span>
      </div>
      <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
        {item.postContent}
      </p>
    </div>

    {/* Generated Comment */}
    <div className="relative">
      <h3 className="font-medium text-sm mb-2">Generated Comment</h3>
      <div 
        className={`text-sm whitespace-pre-line ${
          isDarkMode ? 'text-gray-300' : 'text-gray-700'
        }`}
      >
        {item.comment.split('\n').map((paragraph, i) => (
          <p key={i} className={i !== 0 ? 'mt-4' : ''}>
            {paragraph}
          </p>
        ))}
      </div>
    </div>

    {/* Metadata and Actions */}
    <div className="mt-4 space-y-3">
      {/* Tags */}
      <div className="flex flex-wrap items-center gap-2 text-xs text-gray-500">
        <span className={`px-2 py-1 rounded-full ${
          isDarkMode ? 'bg-gray-700/50' : 'bg-gray-100'
        }`}>
          {item.tone}
        </span>
        {item.persona && (
          <span className={`px-2 py-1 rounded-full ${
            isDarkMode ? 'bg-gray-700/50' : 'bg-gray-100'
          }`}>
            {item.persona.name}
          </span>
        )}
        {item.targetAudience && (
          <span className={`px-2 py-1 rounded-full ${
            isDarkMode ? 'bg-gray-700/50' : 'bg-gray-100'
          }`}>
            {item.targetAudience.name}
          </span>
        )}
      </div>

      {/* Action Buttons */}
      <div className={`flex items-center justify-between pt-3 border-t ${
        isDarkMode ? 'border-gray-700' : 'border-gray-200'
      }`}>
        <button
          onClick={() => onCopy(item.comment, item._id)}
          disabled={isDeleting}
          className={`flex items-center gap-2 px-3 py-1.5 rounded-md text-xs font-medium transition-colors duration-200
            ${isDarkMode 
              ? copiedComments[item._id]
                ? 'bg-green-500/10 text-green-400'
                : 'hover:bg-gray-700 text-gray-400 hover:text-gray-200' 
              : copiedComments[item._id]
                ? 'bg-green-50 text-green-600'
                : 'hover:bg-gray-100 text-gray-600 hover:text-gray-900'
            } ${isDeleting ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          {copiedComments[item._id] ? (
            <>
              <ClipboardDocumentIconSolid className="h-4 w-4" />
              <span>Copied!</span>
            </>
          ) : (
            <>
              <ClipboardDocumentIcon className="h-4 w-4" />
              <span>Copy comment</span>
            </>
          )}
        </button>

        <button
          onClick={() => onDelete(item._id)}
          disabled={isDeleting}
          className={`flex items-center gap-2 px-3 py-1.5 rounded-md text-xs font-medium text-red-500 transition-colors duration-200
            ${isDarkMode 
              ? 'hover:bg-red-500/10' 
              : 'hover:bg-red-50'
            } ${isDeleting ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          <TrashIcon className={`h-4 w-4 ${isDeleting ? 'animate-spin' : ''}`} />
          <span>{isDeleting ? 'Deleting...' : 'Delete'}</span>
        </button>
      </div>
    </div>
  </motion.div>
);

export default CommentHistory; 