import React from 'react';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

const Help = () => {
  return (
    <div className="fixed bottom-4 right-4">
      <button
        className="bg-blue-500 hover:bg-blue-600 text-white rounded-full p-2 shadow-lg transition-colors duration-200"
        title="Help"
      >
        <QuestionMarkCircleIcon className="h-6 w-6" />
      </button>
    </div>
  );
};

export default Help;