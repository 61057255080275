import React from 'react';
import { MicrophoneIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import { format } from 'date-fns';

const PreviewVoiceNote = ({ voiceNote, isDarkMode, onRemove, onClose }) => {
  const formatSize = (transcription) => {
    if (!transcription) return '0 KB';
    const bytes = new Blob([transcription]).size;
    const kb = bytes / 1024;
    return `${kb.toFixed(1)} KB`;
  };

  const handleClose = (e) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <div className={`
      w-full rounded-lg overflow-hidden shadow-xl
      ${isDarkMode ? 'bg-slate-800 border border-slate-700' : 'bg-white border border-gray-200'}
    `}>
      {/* Header */}
      <div className={`
        h-10 flex items-center justify-between px-3 border-b
        ${isDarkMode ? 'border-slate-700 bg-slate-900' : 'border-gray-200 bg-gray-50'}
      `}>
        <div className="flex items-center gap-2">
          <MicrophoneIcon className={`h-4 w-4 ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}`} />
          <span className={`text-sm font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-700'}`}>
            {voiceNote.title}
          </span>
          <span className={`text-xs ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
            • {formatSize(voiceNote.transcription)}
          </span>
          <span className={`text-xs ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
            • {format(new Date(voiceNote.createdAt), 'MMM d, yyyy')}
          </span>
        </div>
        
        <button
          onClick={handleClose}
          className={`
            p-1.5 rounded-md transition-colors duration-200 hover:bg-opacity-80
            ${isDarkMode 
              ? 'hover:bg-slate-700 text-slate-400' 
              : 'hover:bg-gray-200 text-gray-500'
            }
          `}
        >
          <XMarkIcon className="h-4 w-4" />
        </button>
      </div>

      {/* Transcription */}
      <div className={`
        p-4 border-t
        ${isDarkMode ? 'border-slate-700 bg-slate-800' : 'border-gray-200 bg-white'}
      `}>
        <h3 className={`text-sm font-medium mb-2 ${isDarkMode ? 'text-slate-200' : 'text-gray-700'}`}>
          Transcription
        </h3>
        <pre className={`
          whitespace-pre-wrap font-mono text-sm
          ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}
        `}>
          {voiceNote.transcription}
        </pre>
      </div>
    </div>
  );
};

export default PreviewVoiceNote; 