import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import debounce from 'lodash/debounce';

const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [error, setError] = useState(null);
  const [searchMetadata, setSearchMetadata] = useState(null);
  const [recentSearches, setRecentSearches] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  
  const navigate = useNavigate();
  const ITEMS_PER_PAGE = 10;
  const MAX_RECENT_SEARCHES = 5;

  // Available filter types
  const availableFilters = [
    { id: 'chats', label: 'Chats', icon: 'ChatBubbleLeftIcon' },
    { id: 'tasks', label: 'Tasks', icon: 'CheckCircleIcon' },
    { id: 'ideas', label: 'Ideas', icon: 'LightBulbIcon' },
    { id: 'voice-notes', label: 'Voice Notes', icon: 'MicrophoneIcon' },
    { id: 'personas', label: 'Personas', icon: 'UserGroupIcon' },
    { id: 'target-audiences', label: 'Target Audiences', icon: 'UsersIcon' },
    { id: 'ai-agents', label: 'AI Agents', icon: 'CpuChipIcon' }
  ];

  // Load recent searches from localStorage
  useEffect(() => {
    const savedSearches = localStorage.getItem('recentSearches');
    if (savedSearches) {
      setRecentSearches(JSON.parse(savedSearches));
    }
  }, []);

  // Save recent searches to localStorage
  const updateRecentSearches = useCallback((newQuery) => {
    if (!newQuery.trim()) return;
    
    setRecentSearches(prev => {
      const updated = [
        newQuery,
        ...prev.filter(item => item !== newQuery)
      ].slice(0, MAX_RECENT_SEARCHES);
      
      localStorage.setItem('recentSearches', JSON.stringify(updated));
      return updated;
    });
  }, []);

  // Toggle filter selection
  const toggleFilter = useCallback((filterId) => {
    setSelectedFilters(prev => {
      const updated = prev.includes(filterId)
        ? prev.filter(f => f !== filterId)
        : [...prev, filterId];
      
      // Reset pagination when filters change
      setCurrentPage(1);
      return updated;
    });
  }, []);

  // Clear error state
  const clearError = useCallback(() => {
    setError(null);
  }, []);

  // Perform search with enhanced functionality
  const performSearch = useCallback(async (searchQuery, page = 1) => {
    if (!searchQuery.trim()) {
      setResults({});
      setSearchMetadata(null);
      return;
    }

    setLoading(true);
    setError(null);
    
    try {
      const params = new URLSearchParams({
        q: searchQuery,
        page: page.toString(),
        limit: ITEMS_PER_PAGE.toString(),
        ...(selectedFilters.length && { filters: selectedFilters.join(',') })
      });

      const startTime = Date.now();
      const searchRes = await api.get(`/api/search?${params}`);
      const duration = Date.now() - startTime;

      const { results: searchResults, metadata, pagination } = searchRes.data;

      // Update results based on pagination
      if (page === 1) {
        setResults(searchResults);
      } else {
        setResults(prev => {
          const merged = { ...prev };
          Object.keys(searchResults).forEach(type => {
            merged[type] = [...(prev[type] || []), ...searchResults[type]];
          });
          return merged;
        });
      }

      // Update metadata and pagination
      setSearchMetadata({
        ...metadata,
        duration,
        success: true
      });
      setHasMore(pagination.total > page * ITEMS_PER_PAGE);
      setCurrentPage(page);
      
      // Update recent searches
      updateRecentSearches(searchQuery);

    } catch (error) {
      console.error('Search error:', error);
      setError(error.response?.data?.message || 'An error occurred while searching');
      if (error.response) {
        console.error('Error response:', error.response.data);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
    } finally {
      setLoading(false);
    }
  }, [selectedFilters, updateRecentSearches]);

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((searchQuery) => performSearch(searchQuery), 300),
    [performSearch]
  );

  // Load more results
  const loadMore = useCallback(() => {
    if (!loading && hasMore) {
      performSearch(query, currentPage + 1);
    }
  }, [loading, hasMore, query, currentPage, performSearch]);

  // Get all results as flat array
  const getAllResults = useCallback(() => {
    const flatResults = Object.entries(results).flatMap(([type, items]) => 
      items.map(item => ({ ...item, type }))
    );
    return flatResults;
  }, [results]);

  // Handle result selection
  const handleSelect = useCallback((item) => {
    if (!item) return;

    closeSearch();

    // Handle regular results
    switch (item.type) {
      case 'tasks':
        navigate(`/tasks/${item._id}`);
        break;
      case 'ideas':
        navigate(`/ideas/${item._id}`);
        break;
      case 'chats':
        navigate(`/chat/${item._id}`);
        break;
      case 'voice-notes':
        navigate(`/voice-notes/${item._id}`);
        break;
      default:
        break;
    }
  }, [navigate]);

  // Navigate results with keyboard
  const navigateResults = useCallback((direction) => {
    const totalResults = getAllResults().length;
    if (direction === 'up') {
      setActiveIndex(prev => (prev > 0 ? prev - 1 : totalResults - 1));
    } else {
      setActiveIndex(prev => (prev < totalResults - 1 ? prev + 1 : 0));
    }
  }, [getAllResults]);

  // Handle keyboard shortcuts
  const handleKeyDown = useCallback((e) => {
    if (!isOpen) return;

    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        navigateResults('up');
        break;
      case 'ArrowDown':
        e.preventDefault();
        navigateResults('down');
        break;
      case 'Enter':
        e.preventDefault();
        const allResults = getAllResults();
        if (activeIndex >= 0 && activeIndex < allResults.length) {
          handleSelect(allResults[activeIndex]);
        }
        break;
      case 'Escape':
        setIsOpen(false);
        break;
      default:
        break;
    }
  }, [isOpen, navigateResults, activeIndex, getAllResults, handleSelect]);

  // Clear all filters
  const clearFilters = useCallback(() => {
    setSelectedFilters([]);
    setCurrentPage(1);
    performSearch(query, 1);
  }, [query, performSearch]);

  // Clear recent searches
  const clearRecentSearches = useCallback(() => {
    setRecentSearches([]);
    localStorage.removeItem('recentSearches');
  }, []);

  // Open search modal
  const openSearch = useCallback(() => {
    setIsOpen(true);
    setQuery('');
    setResults({});
    setActiveIndex(0);
    setError(null);
    setCurrentPage(1);
    setHasMore(true);
  }, []);

  // Close search modal
  const closeSearch = useCallback(() => {
    setIsOpen(false);
    setQuery('');
    setResults({});
    setSelectedFilters([]);
    setError(null);
    setSearchMetadata(null);
    setCurrentPage(1);
    setHasMore(true);
  }, []);

  // Global keyboard shortcuts
  useEffect(() => {
    const handleGlobalKeyDown = (e) => {
      // Check for both Command/Control + K
      if ((e.metaKey || e.ctrlKey) && e.key.toLowerCase() === 'k') {
        e.preventDefault(); // Prevent default browser behavior
        e.stopPropagation(); // Stop event propagation
        setIsOpen(prev => !prev);
      }
    };

    // Add capture phase to ensure this handler runs first
    document.addEventListener('keydown', handleGlobalKeyDown, true);
    return () => {
      document.removeEventListener('keydown', handleGlobalKeyDown, true);
    };
  }, []);

  const value = {
    isOpen,
    query,
    results,
    loading,
    selectedFilters,
    activeIndex,
    availableFilters,
    error,
    searchMetadata,
    recentSearches,
    hasMore,
    currentPage,
    setQuery,
    performSearch,
    debouncedSearch,
    toggleFilter,
    clearFilters,
    clearError,
    handleKeyDown,
    openSearch,
    closeSearch,
    handleSelect,
    getAllResults,
    loadMore,
    clearRecentSearches
  };

  return (
    <SearchContext.Provider value={value}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error('useSearch must be used within a SearchProvider');
  }
  return context;
};

export default SearchContext; 