import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { 
  ArrowLeftIcon, 
  ArrowRightIcon, 
  UserGroupIcon, 
  CheckIcon, 
  UserIcon, 
  MapPinIcon, 
  BriefcaseIcon, 
  AcademicCapIcon, 
  BuildingOfficeIcon, 
  FlagIcon, 
  BookOpenIcon, 
  ClockIcon, 
  ExclamationCircleIcon, 
  SpeakerWaveIcon, 
  BoltIcon, 
  MagnifyingGlassIcon, 
  NewspaperIcon, 
  LightBulbIcon, 
  ChatBubbleBottomCenterTextIcon, 
  PlusIcon, 
  XMarkIcon, 
  CurrencyDollarIcon, 
  ChartBarIcon,
  GlobeAltIcon,
  StarIcon,
  ArrowUpTrayIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import { CheckCircleIcon, StarIcon as SolidStarIcon } from '@heroicons/react/24/solid';
import { useTheme } from '../../context/ThemeContext';
import api from '../../api';
import Notification from '../Notification';
import { motion, AnimatePresence } from 'framer-motion';
import '../scrollbar.css';
import './TargetAudienceStyles.css';
import { fetchAvatarUrl, getAvatarFromCache, saveAvatarToCache, refreshExpiredAvatarUrl, forceRefreshAvatarUrl } from '../../utils/targetAudienceAvatarUtils';
import PersonaSelector from '../personas/PersonaSelector';
import PropTypes from 'prop-types';
import imageCompression from 'browser-image-compression';
import MultiSelectDropdown from '../common/MultiSelectDropdown';
import { createPortal } from 'react-dom';

// Add the CustomDropdown component here (similar to PersonaCreationWizard)
const CustomDropdown = ({ options, value, onChange, placeholder, isDarkMode, hasError }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const triggerRef = useRef(null);
  const dropdownRef = useRef(null);
  const searchInputRef = useRef(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 });

  // Filter options based on search term
  const filteredOptions = useMemo(() => {
    return options.filter(option => 
      option.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [options, searchTerm]);

  // Focus search input when dropdown opens
  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);

  // Reset search when dropdown closes
  useEffect(() => {
    if (!isOpen) {
      setSearchTerm('');
    }
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (triggerRef.current && 
          !triggerRef.current.contains(event.target) && 
          dropdownRef.current && 
          !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    const updatePosition = () => {
      if (triggerRef.current && isOpen) {
        const rect = triggerRef.current.getBoundingClientRect();
        const scrollContainer = triggerRef.current.closest('.custom-scrollbar');
        const scrollContainerRect = scrollContainer?.getBoundingClientRect();
        
        // Calculate available space below
        const spaceBelow = window.innerHeight - rect.bottom;
        const spaceAbove = rect.top;
        
        // Determine if dropdown should open upward
        const shouldOpenUpward = spaceBelow < 200 && spaceAbove > 200;
        
        setDropdownPosition({
          top: shouldOpenUpward 
            ? rect.top + window.scrollY - 204 // 200px height + 4px gap
            : rect.bottom + window.scrollY + 4,
          left: rect.left,
          width: rect.width,
          maxHeight: Math.min(200, shouldOpenUpward ? spaceAbove - 4 : spaceBelow - 4),
          transformOrigin: shouldOpenUpward ? 'bottom' : 'top'
        });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('scroll', updatePosition, true);
    window.addEventListener('resize', updatePosition);

    updatePosition();

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', updatePosition, true);
      window.removeEventListener('resize', updatePosition);
    };
  }, [isOpen]);

  const renderDropdown = () => {
    if (!isOpen) return null;

    return createPortal(
      <div
        ref={dropdownRef}
        style={{
          position: 'fixed',
          top: `${dropdownPosition.top}px`,
          left: `${dropdownPosition.left}px`,
          width: `${dropdownPosition.width}px`,
          maxHeight: `${dropdownPosition.maxHeight}px`,
          transformOrigin: dropdownPosition.transformOrigin,
          minHeight: '40px',
          maxWidth: 'calc(100vw - 2rem)'
        }}
        className={`z-[9999] overflow-hidden rounded-md shadow-lg border flex flex-col
                   ${isDarkMode 
                     ? 'bg-[#151515] border-[#333333]' 
                     : 'bg-white border-gray-200'}
                   animate-in fade-in-0 zoom-in-95`}
      >
        {/* Search input */}
        <div className={`sticky top-0 p-2 border-b ${isDarkMode ? 'border-[#333333]' : 'border-gray-100'}
                        ${isDarkMode ? 'bg-[#151515]' : 'bg-white'}`}>
          <div className="relative">
            <MagnifyingGlassIcon className={`absolute left-2 top-1/2 -translate-y-1/2 h-4 w-4
                                         ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`} />
            <input
              ref={searchInputRef}
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className={`w-full pl-8 pr-3 py-1.5 text-sm rounded-md border transition-colors duration-200
                       ${isDarkMode 
                         ? 'bg-[#1a1a1a] border-[#333333] text-slate-200' 
                         : 'bg-gray-50 border-gray-200 text-gray-900'}
                       focus:outline-none
                       dark:[&:-webkit-autofill]:shadow-[0_0_0_30px_#1a1a1a_inset]
                       dark:[&:-webkit-autofill]:shadow-[0_0_0_50px_#1a1a1a_inset]
                       [&:-webkit-autofill]:shadow-[0_0_0_30px_rgb(255,255,255)_inset]
                       [&:-webkit-autofill]:[-webkit-text-fill-color:rgb(17,24,39)]
                       [&:-webkit-autofill]:[transition:background-color_9999s_ease-in-out_0s]
                       dark:[&:-webkit-autofill]:border-[#333333]
                       [&:-webkit-autofill]:border-gray-200`}
              placeholder="Search options..."
            />
          </div>
        </div>

        {/* Scrollable options */}
        <div 
          className="overflow-y-auto flex-1 py-1 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent"
        >
          {filteredOptions.length === 0 ? (
            <div className={`px-2.5 py-1.5 text-sm text-center
                          ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
              No matching options
            </div>
          ) : (
            filteredOptions.map((option) => (
              <div
                key={option}
                className={`px-2.5 py-1.5 text-sm cursor-default select-none relative
                          transition-colors duration-100
                          ${isDarkMode
                            ? 'hover:bg-[#1a1a1a] text-slate-200'
                            : 'hover:bg-gray-50 text-gray-900'}
                          ${value === option 
                            ? (isDarkMode 
                                ? 'bg-[#1a1a1a] text-slate-200' 
                                : 'bg-gray-50 text-gray-900')
                            : ''}`}
                onClick={() => {
                  onChange(option);
                  setIsOpen(false);
                }}
              >
                <span className={`block truncate ${value === option ? 'font-medium' : 'font-normal'}`}>
                  {option}
                </span>
                {value === option && (
                  <span className={`absolute inset-y-0 right-2 flex items-center
                                  ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>
                    <CheckIcon className="h-4 w-4" />
                  </span>
                )}
              </div>
            ))
          )}
        </div>

        {/* Sticky custom option at bottom */}
        <div className={`sticky bottom-0 border-t 
          ${isDarkMode ? 'border-[#333333] bg-[#151515]' : 'border-gray-100 bg-white'}`}>
          <div
            className={`px-2.5 py-1.5 text-sm cursor-default select-none relative
              transition-colors duration-100 flex items-center
              ${isDarkMode
                ? 'hover:bg-[#1a1a1a] text-slate-300'
                : 'hover:bg-gray-50/50 text-gray-600'}
              ${value === 'custom' 
                ? (isDarkMode 
                    ? 'bg-[#1a1a1a] text-slate-200' 
                    : 'bg-gray-50/50 text-gray-900')
                : ''}`}
            onClick={() => {
              onChange('custom');
              setIsOpen(false);
            }}
          >
            <PlusIcon className={`h-4 w-4 mr-2 
                              ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`} />
            <span className={`block truncate ${value === 'custom' ? 'font-medium' : 'font-normal'}`}>
              Add Custom Option
            </span>
            {value === 'custom' && (
              <span className={`absolute inset-y-0 right-2 flex items-center
                              ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>
                <CheckIcon className="h-4 w-4" />
              </span>
            )}
          </div>
        </div>
      </div>,
      document.body
    );
  };

  return (
    <div className="relative" ref={triggerRef}>
      <button
        type="button"
        className={`relative w-full text-left cursor-default rounded-md py-2 pl-3 pr-10 text-sm
                   border transition-colors duration-200 outline-none min-h-[38px]
                   ${isDarkMode 
                     ? 'bg-[#1a1a1a] border-[#333333] text-slate-200 hover:border-[#202020]' 
                     : 'bg-white border-gray-200 text-gray-900 hover:border-gray-300'}
                   ${hasError ? 'border-red-500' : ''}
                   ${isDarkMode ? 'focus:border-blue-500' : 'focus:border-indigo-500'}
                   focus:outline-none
                   disabled:cursor-not-allowed disabled:opacity-50`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className={`block truncate text-[12px] 
                       ${!value ? 'text-slate-400/50 dark:text-slate-500/50' : 'text-sm'}`}>
          {value || placeholder || 'Select an option...'}
        </span>
        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <svg 
            className={`h-4 w-4 transition-transform duration-200
                     ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}
                     ${isOpen ? 'rotate-180' : ''}`}
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 20 20" 
            fill="currentColor"
          >
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </span>
      </button>
      {renderDropdown()}
    </div>
  );
};

// Add this component inside the same file, before the main TargetAudienceCreationWizard component
const LeftColumn = ({ steps, currentStep, sectionProgress, isDarkMode, onSectionClick }) => {
  return (
    <div className={`w-1/4 ${isDarkMode ? 'bg-[#121212]' : 'bg-gray-50'} p-4 rounded-l-lg flex flex-col`}>
      <h2 className={`text-lg font-semibold mb-2 ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>
        Target Audience Wizard
      </h2>
      <p className={`text-xs mb-4 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`}>
        Create a detailed profile of your target audience.
      </p>
      <div className="space-y-2 flex-grow overflow-y-auto custom-scrollbar">
        {steps.map((step, index) => (
          <div 
            key={index} 
            className={`flex items-start p-2 rounded-md cursor-pointer transition-colors duration-200
              ${currentStep === index 
                ? (isDarkMode ? 'bg-[#1e1e1e]' : 'bg-white shadow-sm') 
                : (isDarkMode ? 'hover:bg-[#1e1e1e]' : 'hover:bg-white hover:shadow-sm')}`}
            onClick={() => onSectionClick(index)}
          >
            {sectionProgress[index] && sectionProgress[index].status === 'completed' ? (
              <CheckCircleIcon className="w-4 h-4 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
            ) : (
              <div className={`w-4 h-4 border-2 rounded-full mr-2 flex-shrink-0 mt-0.5 
                ${isDarkMode ? 'border-[#333333]' : 'border-gray-300'}`} />
            )}
            <span className={`text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-700'}`}>
              {step.title}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

const MultipleTextInput = ({ items, onItemsChange, placeholder, isDarkMode }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyPress = (e) => {
    if (e.key === 'Enter' && inputValue.trim() !== '') {
      onItemsChange([...items, inputValue.trim()]);
      setInputValue('');
    }
  };

  const handleRemoveItem = (index) => {
    const newItems = items.filter((_, i) => i !== index);
    onItemsChange(newItems);
  };

  return (
    <div>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyPress={handleInputKeyPress}
        placeholder={placeholder}
        className={`mt-1 block w-full px-3 py-2 rounded-md transition-colors duration-200
          ${isDarkMode
            ? 'bg-[#1e1e1e] border-[#333333] text-slate-200 focus:border-[#333333]'
            : 'bg-white border-gray-300 text-gray-900'
          } text-sm placeholder-opacity-50`}
      />
      <div className="mt-2 space-y-2">
        {items.map((item, index) => (
          <div key={index} className="flex items-center">
            <span className={`flex-grow ${isDarkMode ? 'text-slate-200' : 'text-gray-700'}`}>{item}</span>
            <button
              onClick={() => handleRemoveItem(index)}
              className={`ml-2 p-1 rounded-full 
                ${isDarkMode ? 'hover:bg-[#1e1e1e]' : 'hover:bg-gray-200'}`}
            >
              <XMarkIcon className="w-4 h-4" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

// Remove KnowledgeLevelInput and AttentionSpanInput components

// Add this function before the TargetAudienceCreationWizard component
const isFieldComplete = (field, value, avatarUrl, selectedPersona) => {
  let isComplete = false;
  switch (field) {
    case 'audienceImage':
      isComplete = avatarUrl !== null && avatarUrl !== '';
      break;
    case 'associatedPersona':
      isComplete = selectedPersona !== null;
      break;
    case 'targetAudienceName':
    case 'describeYourAudience':
    case 'ageRange':
    case 'primaryLocation':
    case 'careerStage':
    case 'industry':
    case 'primaryGoal':
    case 'preferredLearningStyle':
    case 'contentConsumptionFrequency':
    case 'keyMotivations':
    case 'coreValues':
    case 'contentTone':
    case 'decisionFactors':
    case 'kpis':
    case 'topPlatforms':
    case 'preferredFormats':
    case 'engagementTriggers':
    case 'primaryDiscoveryMethods':
    case 'keyCharacteristics':
    case 'unmetNeeds':
    case 'buyingNuances':
    case 'expectedBuyingCycle':
    case 'influentialFigures':
    case 'currentGoToSources':
    case 'mostEngagingPastTopics':
    case 'requestedTopics':
    case 'painPoints':
      isComplete = typeof value === 'string' && value.trim() !== '';
      break;
    case 'engagementMetrics':
      isComplete = (
        typeof value === 'object' &&
        value !== null &&
        'averageViewDuration' in value &&
        'commentRate' in value &&
        'shareRate' in value &&
        Object.values(value).every(metric => typeof metric === 'string' && metric.trim() !== '')
      );
      break;
    default:
      isComplete = false;
  }
  console.log(`isFieldComplete - Field: ${field}, Value:`, value, `Complete:`, isComplete);
  return isComplete;
};

// Add this function before the TargetAudienceCreationWizard component

const defaultOptions = {
  ageRange: ['18-24', '25-34', '35-44', '45-54', '55+'],
  careerStage: ['Student', 'Entry-level', 'Mid-career', 'Senior', 'Executive'],
  industry: [
    'Information Technology',
    'Software and SaaS',
    'Financial Services',
    'Manufacturing',
    'Healthcare and Pharmaceuticals',
    'Professional Services',
    'Telecommunications',
    'Energy and Utilities',
    'Logistics and Supply Chain',
    'Construction and Engineering',
    'Aerospace and Defense',
    'Automotive',
    'Education and E-learning',
    'Retail and E-commerce',
    'Media and Entertainment',
    'Agriculture and Agtech',
    'Cybersecurity',
    'Artificial Intelligence and Machine Learning',
    'Internet of Things (IoT)',
    'Blockchain and Cryptocurrency',
    'Cleantech and Renewable Energy',
    'Biotechnology',
    'Real Estate and PropTech',
    'Human Resources and Recruitment',
    'Marketing and Advertising',
    'B2B Media Agency',
    'B2B Publishing Agency'
  ],
  primaryGoal: [
    'Increase operational efficiency',
    'Expand market share',
    'Improve customer retention',
    'Accelerate digital transformation',
    'Enhance data security and compliance',
    'Optimize supply chain',
    'Develop new products or services',
    'Reduce costs',
    'Improve employee productivity',
    'Enter new markets'
  ],
  contentConsumptionFrequency: ['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Occasionally'],
  preferredLearningStyle: ['Visual', 'Auditory', 'Reading/Writing', 'Kinesthetic', 'Mixed'],
  contentTone: [
    'Professional',
    'Authoritative',
    'Conversational',
    'Technical',
    'Educational',
    'Inspirational',
    'Analytical'
  ],
  decisionFactors: [
    'Price',
    'Quality',
    'Brand reputation',
    'Customer service',
    'Features',
    'Implementation time',
    'ROI',
    'Security',
    'Scalability',
    'Integration capabilities'
  ],
  kpis: [
    'Revenue Growth',
    'Customer Acquisition Cost',
    'Customer Lifetime Value',
    'Return on Investment',
    'Market Share',
    'Brand Awareness',
    'Lead Generation',
    'Conversion Rate',
    'Customer Satisfaction',
    'Employee Productivity'
  ],
  topPlatforms: [
    'LinkedIn',
    'Twitter',
    'YouTube',
    'Instagram',
    'Facebook',
    'TikTok',
    'Medium',
    'Industry Forums',
    'Slack Communities',
    'Discord Servers'
  ],
  preferredFormats: [
    'Blog posts',
    'White papers',
    'Case studies',
    'Video tutorials',
    'Webinars',
    'Podcasts',
    'Infographics',
    'eBooks',
    'Social media posts',
    'Email newsletters'
  ],
  keyMotivations: [
    'Professional growth',
    'Innovation',
    'Problem solving',
    'Industry leadership',
    'Efficiency gains',
    'Cost reduction',
    'Risk management',
    'Competitive advantage',
    'Digital transformation',
    'Sustainability'
  ],
  coreValues: [
    'Innovation',
    'Quality',
    'Customer focus',
    'Integrity',
    'Collaboration',
    'Excellence',
    'Sustainability',
    'Diversity',
    'Accountability',
    'Continuous learning'
  ],
  occupation: [
    // C-Level & Executive
    'CEO/Founder',
    'CTO/CIO',
    'CMO',
    'CFO',
    'COO',
    'Chief Digital Officer',
    'Chief Innovation Officer',
    'VP of Technology',
    'VP of Marketing',
    'VP of Sales',
    'VP of Operations',
    
    // Technology & Engineering
    'Software Engineer',
    'Full Stack Developer',
    'Frontend Developer',
    'Backend Developer',
    'DevOps Engineer',
    'Cloud Architect',
    'Data Scientist',
    'Machine Learning Engineer',
    'Security Engineer',
    'Systems Architect',
    'Technical Lead',
    'Engineering Manager',
    
    // Product & Design
    'Product Manager',
    'Product Owner',
    'UX Designer',
    'UI Designer',
    'Product Designer',
    'Design Lead',
    'Research Director',
    
    // Marketing & Communications
    'Marketing Manager',
    'Digital Marketing Manager',
    'Content Strategist',
    'SEO Specialist',
    'Social Media Manager',
    'Brand Manager',
    'Communications Director',
    'Growth Manager',
    
    // Sales & Business Development
    'Sales Director',
    'Account Executive',
    'Business Development Manager',
    'Sales Operations Manager',
    'Customer Success Manager',
    'Partnership Manager',
    
    // Operations & Project Management
    'Project Manager',
    'Program Manager',
    'Operations Manager',
    'Business Analyst',
    'Process Improvement Manager',
    'Quality Assurance Manager',
    
    // Finance & Strategy
    'Financial Analyst',
    'Business Strategy Manager',
    'Investment Manager',
    'Risk Manager',
    'Corporate Development Manager',
    
    // HR & People
    'HR Manager',
    'Talent Acquisition Manager',
    'Learning & Development Manager',
    'People Operations Manager',
    
    // Research & Analytics
    'Research Analyst',
    'Data Analyst',
    'Market Research Manager',
    'Analytics Manager',
    'Business Intelligence Manager',
    
    // Consulting & Advisory
    'Management Consultant',
    'Technology Consultant',
    'Strategy Consultant',
    'Digital Transformation Consultant',
    'Innovation Consultant',
    
    // Industry Specific
    'Healthcare Administrator',
    'Education Technology Specialist',
    'Financial Services Manager',
    'Manufacturing Manager',
    'Retail Operations Manager',
    'Supply Chain Manager'
  ],
};

const getOptionsForField = (field, customOptions) => {
  const defaultPlaceholders = {
    ageRange: 'Select age range...',
    careerStage: 'Select career level...',
    industry: 'Select industry...',
    primaryGoal: 'Select business goals...',
    preferredLearningStyle: 'Select learning style...',
    contentConsumptionFrequency: 'Select content frequency...',
    keyMotivations: 'Select motivations...',
    coreValues: 'Select core values...',
    contentTone: 'Select content tone...',
    decisionFactors: 'Select decision factors...',
    kpis: 'Select success metrics...',
    topPlatforms: 'Select preferred platforms...',
    preferredFormats: 'Select content formats...',
    engagementTriggers: 'Select engagement drivers...',
    primaryDiscoveryMethods: 'Select discovery channels...',
    buyingNuances: 'Describe buying behavior...',
    expectedBuyingCycle: 'Describe buying cycle...',
    influentialFigures: 'List influential figures...',
    currentGoToSources: 'List current sources...',
    mostEngagingPastTopics: 'List engaging topics...',
    requestedTopics: 'List requested topics...',
    painPoints: 'Describe pain points...',
    unmetNeeds: 'Describe unmet needs...',
    occupation: 'Select job role...',
  };

  // Return both options and placeholder
  return {
    options: [...(defaultOptions[field] || []), ...(customOptions[field] || [])],
    placeholder: defaultPlaceholders[field] || `Select ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}...`
  };
};

const FALLBACK_AVATAR = '/default-avatar.png'; // Ensure this path is correct and the image exists

// Update the AudienceImageAndPersonaSelector component
const AudienceImageAndPersonaSelector = ({ audienceId, avatarUrl, isUploading, handleFileChange, selectedPersona, handlePersonaSelect, isDarkMode }) => {
  return (
    <div className={`flex flex-col sm:flex-row items-start sm:items-center justify-between 
      mb-6 p-3 sm:p-4 rounded-lg gap-4 sm:gap-0
      ${isDarkMode ? 'bg-[#151515]' : 'bg-gray-50'}`}>
      <div className="flex items-center w-full sm:w-auto sm:flex-1 sm:mr-4">
        <div className={`w-16 h-16 rounded-lg mr-4 flex items-center justify-center overflow-hidden
          ${isDarkMode 
            ? 'bg-[#1a1a1a] border-[#333333]' 
            : 'bg-white border-gray-300'} border`}>
          {isUploading ? (
            <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-900"></div>
          ) : avatarUrl ? (
            <img 
              src={avatarUrl} 
              alt="Target Audience" 
              className="w-full h-full object-cover" 
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/logo.svg';
              }}
            />
          ) : (
            <UserGroupIcon className={`w-8 h-8 ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`} />
          )}
        </div>
        <div className="flex-1 sm:flex-initial">
          <h3 className={`text-sm font-medium mb-1 ${isDarkMode ? 'text-slate-200' : 'text-gray-800'}`}>
            Audience Avatar
          </h3>
          {/* Update upload button styles to match PersonaSelector button */}
          <label className={`cursor-pointer inline-flex items-center px-3 py-1.5 rounded-md text-sm
            ${isDarkMode 
              ? 'bg-[#1a1a1a] hover:bg-[#202020] text-slate-200 border border-[#333333]' 
              : 'bg-white hover:bg-gray-50 text-gray-700 border border-gray-300'}`}>
            <ArrowUpTrayIcon className="w-4 h-4 mr-2" />
            {avatarUrl ? 'Change Image' : 'Upload Image'}
            <input type="file" className="hidden" onChange={handleFileChange} accept="image/*" />
          </label>
        </div>
      </div>
      <div className="w-full sm:w-auto sm:flex-1">
        <PersonaSelector
          selectedPersona={selectedPersona}
          onSelectPersona={handlePersonaSelect}
          isDarkMode={isDarkMode}
        />
      </div>
    </div>
  );
};

// Add this to the component's props
const TargetAudienceCreationWizard = ({ onAvatarUpdate }) => {
  const { audienceId } = useParams();
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const [step, setStep] = useState(0);
  const [audienceData, setAudienceData] = useState({
    targetAudienceName: '',
    occupation: '',
    ageRange: '',
    primaryLocation: '',
    careerStage: '',
    industry: '',
    primaryGoal: [],
    preferredLearningStyle: [],
    contentConsumptionFrequency: '',
    keyMotivations: [],
    coreValues: [],
    painPoints: '',
    contentTone: [],
    associatedPersona: null,
  });
  const [isSaving, setIsSaving] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isSlowInternet, setIsSlowInternet] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [sectionProgress, setSectionProgress] = useState([]);
  const [customOptions, setCustomOptions] = useState({
    preferredLearningStyle: [],
    topPlatforms: [],
    preferredFormats: [],
    coreValues: [],
    keyMotivations: [],
    contentTone: [],
    engagementTriggers: [],
    primaryDiscoveryMethods: [],
    ageRange: [],
    careerStage: [],
    industry: [],
    primaryGoal: [],
    contentConsumptionFrequency: [],
    keyCharacteristics: [],
    decisionFactors: [],
    kpis: [],
  });
  const [showCustomInput, setShowCustomInput] = useState({});
  const [customAgeRange, setCustomAgeRange] = useState('');
  const [audienceImageUrl, setAudienceImageUrl] = useState('');
  const [imageError, setImageError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedPersona, setSelectedPersona] = useState(null);
  const [existingAvatarUrl, setExistingAvatarUrl] = useState('');
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const saveButtonRef = useRef(null);

  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);

  const getOptions = useCallback((field) => getOptionsForField(field, customOptions), [customOptions]);

  useEffect(() => {
    if (audienceId) {
      fetchAudience();
    } else {
      loadProgress();
    }
  }, [audienceId]);

  const initializeSectionProgress = () => {
    setSectionProgress(steps.map(step => ({ title: step.title, status: 'pending' })));
  };

  const fetchAudience = async () => {
    try {
      const response = await api.get(`/api/target-audiences/${audienceId}`);
      setAudienceData(response.data);
      if (response.data.sectionProgress && response.data.sectionProgress.length > 0) {
        setSectionProgress(response.data.sectionProgress);
      } else {
        initializeSectionProgress();
      }
      updateAllSectionsProgress(response.data);

      // Fetch and set the avatar URL
      if (response.data.avatar) {
        try {
          const avatarUrl = await fetchAvatarUrl(audienceId);
          if (avatarUrl) {
            setAvatarUrl(avatarUrl);
            saveAvatarToCache(audienceId, avatarUrl);
          }
        } catch (error) {
          console.error('Error fetching audience avatar:', error);
          addNotification('Error', 'Failed to load audience avatar', 'error');
        }
      }

      // Set the associated persona
      if (response.data.associatedPersona) {
        setSelectedPersona(response.data.associatedPersona);
      }

      // Clear new audience data from local storage when editing an existing audience
      localStorage.removeItem('newAudienceData');
      localStorage.removeItem('newAudienceStep');
    } catch (error) {
      console.error('Error fetching target audience:', error);
      addNotification('Error', 'Failed to fetch target audience data', 'error');
      initializeSectionProgress();
    }
  };

  const updateAllSectionsProgress = (data) => {
    const updatedProgress = steps.map((step, index) => {
      const isStepComplete = step.fields.every(field => {
        if (typeof data[field] === 'string') {
          return data[field].trim() !== '';
        } else if (Array.isArray(data[field])) {
          return data[field].length > 0;
        } else if (typeof data[field] === 'object') {
          return Object.values(data[field]).some(value => value !== '');
        }
        return false;
      });
      return { title: step.title, status: isStepComplete ? 'completed' : 'incomplete' };
    });
    setSectionProgress(updatedProgress);
  };

  // Update the requiredFields array to include contentConsumptionFrequency
  const requiredFields = [
    'targetAudienceName',
    'ageRange', 
    'primaryLocation',
    'careerStage', 
    'industry', 
    'primaryGoal', 
    'coreValues', 
    'painPoints',
    'contentConsumptionFrequency'  // Add this
  ];

  const countFilledRequiredFields = useMemo(() => {
    return requiredFields.filter(field => audienceData[field] && audienceData[field].length > 0).length;
  }, [audienceData]);

  const steps = [
    { title: 'Core Audience Information', fields: ['targetAudienceName', 'describeYourAudience', 'audienceImage', 'associatedPersona'] },
    { title: 'Demographic Details', fields: ['ageRange', 'primaryLocation', 'careerStage', 'industry'] },
    { title: 'Content Strategy Alignment', fields: ['primaryGoal', 'preferredLearningStyle', 'contentConsumptionFrequency'] },
    { title: 'Platform & Format Preferences', fields: ['topPlatforms', 'preferredFormats'] },
    { title: 'Psychographic Insights', fields: ['keyMotivations', 'coreValues', 'painPoints'] },
    { title: 'Engagement Boosters', fields: ['contentTone', 'engagementTriggers'] },
    { title: 'Content Discovery', fields: ['primaryDiscoveryMethods', 'influentialFigures'] },
    { title: 'Competitive Analysis', fields: ['currentGoToSources', 'unmetNeeds'] },
    { title: 'Feedback Loop', fields: ['mostEngagingPastTopics', 'requestedTopics'] },
    { title: 'Business Insights', fields: ['keyCharacteristics', 'buyingNuances', 'expectedBuyingCycle', 'decisionFactors', 'kpis'] },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    if (name.endsWith('Custom')) {
      const mainField = name.replace('Custom', '');
      setAudienceData(prevData => ({
        ...prevData,
        [name]: value,
        [mainField]: 'custom' // Ensure the main field is set to 'custom'
      }));
    } else {
      setAudienceData(prevData => ({
        ...prevData,
        [name]: value,
        [`${name}Custom`]: value === 'custom' ? prevData[`${name}Custom`] || '' : ''
      }));
    }

    if (errors[name]) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: undefined
      }));
    }
  };

  const handleMultipleChoiceChange = (field, value) => {
    setAudienceData(prevData => {
      const currentValue = Array.isArray(prevData[field]) ? prevData[field] : [];
      return {
        ...prevData,
        [field]: currentValue.includes(value)
          ? currentValue.filter(item => item !== value)
          : [...currentValue, value]
      };
    });
  };

  const handleSliderChange = (field, value) => {
    setAudienceData(prevData => ({
      ...prevData,
      [field]: parseInt(value, 10)
    }));
  };

  const addNotification = (title, message, type = 'info') => {
    const id = Date.now();
    setNotifications(prev => [...prev, { id, title, message, type }]);
  };

  const removeNotification = (id) => {
    setNotifications(prev => prev.filter(notification => notification.id !== id));
  };

  const findStepWithMissingFields = (missingFields) => {
    return steps.findIndex(step => 
      step.fields.some(field => missingFields.includes(field))
    );
  };

  // Update this function
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        let options = {
          maxSizeMB: 0.1, // Start with 100KB target
          maxWidthOrHeight: 1920,
          useWebWorker: true
        };

        let compressedFile = await imageCompression(file, options);
        let fileSizeKB = compressedFile.size / 1024;

        // If the file is still over 100KB, compress further
        if (fileSizeKB > 100) {
          options.maxSizeMB = 0.09; // Reduce target size
          compressedFile = await imageCompression(compressedFile, options);
          fileSizeKB = compressedFile.size / 1024;
        }

        addNotification('File Size', `Compressed image size: ${fileSizeKB.toFixed(2)}KB`, 'info');

        setSelectedFile(compressedFile);
        const objectUrl = URL.createObjectURL(compressedFile);
        setAvatarUrl(objectUrl);
        setImageError(false);

        // Instantly update local storage
        if (audienceId) {
          saveAvatarToCache(audienceId, objectUrl);
        }

        addNotification('Image Selected', 'Target audience image has been selected and compressed. It will be uploaded when you save.', 'success');
      } catch (error) {
        console.error('Error handling file:', error);
        addNotification('File Error', 'An error occurred while processing the file.', 'error');
      }
    }
  };

  // Update this function
  const uploadAudienceImage = async (audienceId) => {
    if (selectedFile) {
      try {
        setIsUploading(true);
        addNotification('Uploading Image', 'Please wait while we upload your target audience image.', 'info');
        
        const imageFormData = new FormData();
        imageFormData.append('audienceImage', selectedFile, selectedFile.name);

        const slowInternetTimeout = setTimeout(() => {
          setIsSlowInternet(true);
          addNotification('Slow Internet Detected', 'The upload is taking longer than expected. Please be patient.', 'warning');
        }, 10000);

        const imageResponse = await Promise.race([
          api.post(`/api/target-audiences/${audienceId}/upload-image`, imageFormData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            timeout: 30000
          }),
          new Promise((_, reject) => 
            setTimeout(() => reject(new Error('Upload timed out')), 30000)
          ), // Added comma here
        ]);

        clearTimeout(slowInternetTimeout);
        setIsSlowInternet(false);

        console.log('Image upload response:', imageResponse);

        if (imageResponse && imageResponse.data && imageResponse.data.imageKey) {
          const newAvatarUrl = await fetchAvatarUrl(audienceId);
          setAvatarUrl(newAvatarUrl);
          saveAvatarToCache(audienceId, newAvatarUrl);
          addNotification('Image Uploaded', 'Your target audience image has been successfully uploaded.', 'success');
          return imageResponse.data.imageKey;
        } else {
          throw new Error('Unexpected server response');
        }
      } catch (error) {
        console.error('Error uploading target audience image:', error);
        console.error('Error details:', error.response ? error.response.data : error.message);
        if (error.message === 'Upload timed out' || error.code === 'ECONNABORTED') {
          addNotification('Upload Failed', 'The image upload failed due to slow internet. Please try again.', 'error');
        } else if (error.response && error.response.status === 500) {
          addNotification('Server Error', 'There was a problem on our end. Please try again later.', 'error');
        } else {
          addNotification('Upload Failed', 'Failed to upload audience image. Please try again.', 'error');
        }
      } finally {
        setIsUploading(false);
        setIsSlowInternet(false);
      }
    }
    return null;
  };

  const handleAvatarError = async () => {
    setImageError(true);
    if (audienceId) {
      try {
        const newAvatarUrl = await forceRefreshAvatarUrl(audienceId);
        if (newAvatarUrl) {
          setAvatarUrl(newAvatarUrl);
          setImageError(false);
        }
      } catch (error) {
        console.error('Error refreshing avatar URL:', error);
      }
    }
  };

  // Update the handleSave function
  const handleSave = async () => {
    const newErrors = validateRequiredFields();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors); // Only set errors when save is attempted
      addNotification('Missing Fields', 'Please fill in all required fields.', 'error');
      return;
    }

    try {
      setIsSaving(true);
      const missingFields = requiredFields.filter(field => {
        if (Array.isArray(audienceData[field])) {
          return audienceData[field].length === 0;
        }
        return !audienceData[field];
      });
      
      if (missingFields.length > 0) {
        const missingFieldsMessage = missingFields.map(field => getFieldTitle(field)).join(', ');
        addNotification('Missing Required Fields', `Please fill in the following required fields: ${missingFieldsMessage}`, 'error');
        
        const stepWithMissingField = findStepWithMissingFields(missingFields);
        
        if (stepWithMissingField !== -1) {
          setStep(stepWithMissingField);
        }
        
        setIsSaving(false);
        return;
      }

      const updatedSectionProgress = sectionProgress.map((section, index) => ({
        title: section.title || steps[index].title,
        status: section.status
      }));

      const dataToSave = { 
        ...audienceData, 
        sectionProgress: updatedSectionProgress,
        associatedPersona: selectedPersona ? selectedPersona._id : null
      };

      console.log('Sending data to save:', dataToSave);
      let response;
      if (audienceId) {
        response = await api.put(`/api/target-audiences/${audienceId}`, dataToSave);
      } else {
        response = await api.post('/api/target-audiences/create', dataToSave);
      }
      console.log('Target audience saved successfully:', response.data);

      // Upload image if there's a selected file
      if (selectedFile) {
        const imageFormData = new FormData();
        imageFormData.append('avatar', selectedFile);
        await api.post(`/api/target-audiences/${response.data._id}/avatar`, imageFormData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      }

      // Clear new audience data from local storage after successful save
      localStorage.removeItem('newAudienceData');
      localStorage.removeItem('newAudienceStep');

      addNotification('Success', 'Target audience saved successfully', 'success');
      navigate('/target-audiences');
    } catch (error) {
      console.error('Error saving target audience:', error);
      console.error('Error details:', error.response ? error.response.data : error.message);
      addNotification('Error', `Failed to save target audience: ${error.message}`, 'error');
    } finally {
      setIsSaving(false);
    }
  };

  // Update the updateSectionProgress function
  const updateSectionProgress = (currentStep) => {
    setSectionProgress(prevProgress => {
      const newProgress = [...prevProgress];
      const currentStepFields = steps[currentStep].fields;
      
      console.log(`Checking progress for step ${currentStep}: ${steps[currentStep].title}`);
      
      const isStepComplete = currentStepFields.every(field => {
        const value = audienceData[field];
        const complete = isFieldComplete(field, value, avatarUrl, selectedPersona);
        console.log(`Field: ${field}, Value:`, value, `Complete:`, complete);
        return complete;
      });

      console.log(`Step ${currentStep} complete:`, isStepComplete);

      newProgress[currentStep] = { 
        title: steps[currentStep].title, 
        status: isStepComplete ? 'completed' : 'incomplete' 
      };
      return newProgress;
    });
  };

  const handleNext = () => {
    updateSectionProgress(step);
    if (step < steps.length - 1) {
      setStep(step + 1);
    } else {
      handleSave();
    }
  };

  const handleBack = () => {
    updateSectionProgress(step);
    if (step > 0) {
      setStep(step - 1);
    } else {
      navigate('/target-audiences');
    }
  };

  // Update the handleCustomDropdownChange function to ensure proper handling
  const handleCustomDropdownChange = (field, value) => {
    setAudienceData(prevData => {
      // If the field is contentConsumptionFrequency, store it as a string
      if (field === 'contentConsumptionFrequency') {
        return {
          ...prevData,
          [field]: value === 'custom' ? prevData[`${field}Custom`] || '' : value
        };
      }
      
      // Handle other fields as before
      return {
        ...prevData,
        [field]: value,
        [`${field}Custom`]: value === 'custom' ? prevData[`${field}Custom`] || '' : ''
      };
    });
    
    if (errors[field]) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [field]: undefined
      }));
    }
  };

  const handleAddCustomOption = (field, value) => {
    setCustomOptions(prevOptions => ({
      ...prevOptions,
      [field]: [...(prevOptions[field] || []), value]
    }));
    handleCustomDropdownChange(field, value);
  };

  // Add this new function to handle custom age range input
  const handleCustomAgeRangeChange = (e) => {
    const value = e.target.value;
    setCustomAgeRange(value);
    setAudienceData(prevData => ({
      ...prevData,
      ageRange: value
    }));
  };

  const getFieldDescription = (field) => {
    const descriptions = {
      targetAudienceName: "A clear identifier helps you differentiate multiple audience segments and makes it easier to reference in your content strategy.",
      occupation: "Understanding their professional role helps tailor content to their daily responsibilities and challenges.",
      ageRange: "Age demographics influence communication style, cultural references, and platform preferences.",
      primaryLocation: "Geographic location affects content timing, cultural context, and regional pain points.",
      careerStage: "Career level impacts decision-making authority, budget control, and professional priorities.",
      industry: "Industry context shapes pain points, technical knowledge, and regulatory considerations.",
      primaryGoal: "Business objectives drive decision-making and determine how your solution adds value.",
      preferredLearningStyle: "Learning preferences affect how effectively your audience absorbs and retains information.",
      contentConsumptionFrequency: "Knowing consumption habits helps optimize content delivery schedule and format choices.",
      keyMotivations: "Understanding what drives their decisions helps create more compelling, relevant content.",
      coreValues: "Aligning with their values builds trust and strengthens brand relationship.",
      painPoints: "Current challenges represent opportunities where your solution can add value.",
      contentTone: "Tone alignment builds rapport and makes content more relatable.",
      influentialFigures: "Key influencers shape your audience's perspectives and decision-making.",
      currentGoToSources: "Existing information sources reveal preferred formats and potential distribution channels.",
      unmetNeeds: "What solutions are they missing?",
      mostEngagingPastTopics: "What topics have worked well before?",
      requestedTopics: "What content have they asked for?",
      keyCharacteristics: "What defines their behavior and preferences?",
      buyingNuances: "How do they approach purchases?",
      expectedBuyingCycle: "How long is their typical buying process?",
      decisionFactors: "What influences their final decisions?",
      kpis: "How do they measure success?",
      associatedPersona: "Linked persona profile",
    };
    return descriptions[field] || "";
  };

  const getFieldIcon = (field) => {
    const icons = {
      targetAudienceName: UserIcon,
      describeYourAudience: ChatBubbleBottomCenterTextIcon,
      ageRange: UserGroupIcon,
      primaryLocation: MapPinIcon,
      careerStage: BriefcaseIcon,
      industry: BuildingOfficeIcon,
      primaryGoal: FlagIcon,
      preferredLearningStyle: BookOpenIcon,
      contentConsumptionFrequency: NewspaperIcon,
      topPlatforms: GlobeAltIcon, // Change this to GlobeAltIcon
      preferredFormats: SpeakerWaveIcon,
      keyMotivations: BoltIcon,
      coreValues: UserIcon,
      painPoints: ExclamationCircleIcon,
      contentTone: SpeakerWaveIcon,
      engagementTriggers: BoltIcon,
      primaryDiscoveryMethods: MagnifyingGlassIcon,
      influentialFigures: UserGroupIcon,
      currentGoToSources: NewspaperIcon,
      unmetNeeds: LightBulbIcon,
      mostEngagingPastTopics: ChatBubbleBottomCenterTextIcon,
      requestedTopics: ChatBubbleBottomCenterTextIcon,
      keyCharacteristics: UserIcon,
      buyingNuances: CurrencyDollarIcon,
      expectedBuyingCycle: ClockIcon,
      decisionFactors: UserIcon,
      kpis: ChartBarIcon,
      associatedPersona: UserIcon,
    };
    const IconComponent = icons[field] || UserIcon;
    return IconComponent;
  };

  // Animation variants
  const pageVariants = {
    initial: { opacity: 0, x: 50 },
    in: { opacity: 1, x: 0 },
    out: { opacity: 0, x: -50 }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  // Update the renderFieldContent function
  const renderFieldContent = (field) => {
    if (field === 'audienceImage' || field === 'associatedPersona') {
      if (field === 'audienceImage') {
        return (
          <AudienceImageAndPersonaSelector
            audienceId={audienceId}
            avatarUrl={avatarUrl}
            isUploading={isUploading}
            handleFileChange={handleFileChange}
            selectedPersona={selectedPersona}
            handlePersonaSelect={handlePersonaSelect}
            isDarkMode={isDarkMode}
          />
        );
      }
      return null;
    }

    const fieldTitle = getFieldTitle(field);
    const isRequired = requiredFields.includes(field);
    const description = getFieldDescription(field);
    const hasError = errors[field];

    return (
      <div key={field} className="mb-3 sm:mb-4 space-y-2">
        <div className="flex-1">
          <div className="flex flex-wrap items-center gap-1.5 mb-1.5">
            <label className={`block text-sm font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-700'}`}>
              {fieldTitle}
              {isRequired && <span className="text-red-500 ml-1">*</span>}
            </label>
            {description && (
              <div className="group relative inline-block">
                <InformationCircleIcon 
                  className={`w-4 h-4 ${isDarkMode ? 'text-slate-400 hover:text-slate-300' : 'text-gray-400 hover:text-gray-500'} 
                    cursor-help transition-colors duration-200`}
                />
                <div className={`invisible group-hover:visible opacity-0 group-hover:opacity-100
                  absolute left-0 sm:left-1/2 bottom-full mb-2 -translate-x-0 sm:-translate-x-1/2
                  px-3 py-2 text-xs rounded-md shadow-lg
                  max-w-[calc(100vw-2rem)] sm:max-w-[200px] w-max z-50
                  transition-all duration-200
                  ${isDarkMode 
                    ? 'bg-[#1e1e1e] text-slate-200 border border-[#333333]' 
                    : 'bg-white text-gray-600 border border-gray-200'}`}>
                  {description}
                  <div className={`absolute bottom-[-5px] left-4 sm:left-1/2 sm:-translate-x-1/2 w-2 h-2 rotate-45
                    ${isDarkMode ? 'bg-[#1e1e1e] border-r border-b border-[#333333]' : 'bg-white border-r border-b border-gray-200'}`} 
                  />
                </div>
              </div>
            )}
          </div>
          {renderFieldInput(field, fieldTitle, isRequired, hasError)}
        </div>
      </div>
    );
  };

  // Add this new function to get better field titles
  const getFieldTitle = (field) => {
    const titleMap = {
      targetAudienceName: 'Audience Name',
      occupation: 'Job Role',
      ageRange: 'Age Range',
      primaryLocation: 'Location',
      careerStage: 'Career Level',
      industry: 'Industry',
      primaryGoal: 'Business Goals',
      preferredLearningStyle: 'Learning Style',
      contentConsumptionFrequency: 'Content Frequency',
      keyMotivations: 'Motivations',
      coreValues: 'Core Values',
      painPoints: 'Pain Points',
      contentTone: 'Content Tone',
      influentialFigures: 'Key Influencers',
      currentGoToSources: 'Information Sources',
      unmetNeeds: 'Unmet Needs',
      mostEngagingPastTopics: 'Engaging Topics',
      requestedTopics: 'Requested Topics',
      keyCharacteristics: 'Key Traits',
      buyingNuances: 'Buying Behavior',
      expectedBuyingCycle: 'Purchase Timeline',
      decisionFactors: 'Decision Factors',
      kpis: 'Success Metrics',
      associatedPersona: 'Associated Persona',
      describeYourAudience: 'Audience Description',
      topPlatforms: 'Preferred Platforms',
      preferredFormats: 'Content Formats',
      engagementTriggers: 'Engagement Drivers',
      primaryDiscoveryMethods: 'Discovery Channels'
    };

    return titleMap[field] || field
      .split(/(?=[A-Z])/)
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  // Update renderFieldInput to include better placeholders
  const renderFieldInput = (field, fieldTitle, isRequired, placeholder) => {
    const placeholders = {
      targetAudienceName: "Give your audience segment a memorable name",
      occupation: "What roles do they typically hold?",
      ageRange: "Select the typical age range",
      primaryLocation: "Where are they primarily based?",
      careerStage: "Select their typical career stage",
      industry: "What industry do they work in?",
      primaryGoal: "What are they trying to achieve?",
      preferredLearningStyle: "How do they best absorb new information?",
      contentConsumptionFrequency: "How often do they engage with content?",
      keyMotivations: "What drives their professional decisions?",
      coreValues: "What principles guide their choices?",
      painPoints: "What frustrates them in their current situation?",
      contentTone: "What communication style resonates with them?",
      influentialFigures: "Who shapes their industry opinions?",
      currentGoToSources: "Where do they currently find information?",
      unmetNeeds: "What solutions are they missing?",
      mostEngagingPastTopics: "What topics have worked well before?",
      requestedTopics: "What content have they asked for?",
      keyCharacteristics: "What defines their behavior and preferences?",
      buyingNuances: "How do they approach purchases?",
      expectedBuyingCycle: "How long is their typical buying process?",
      decisionFactors: "What influences their final decisions?",
      kpis: "How do they measure success?",
      associatedPersona: "Linked persona profile",
    };

    switch (field) {
      case 'ageRange':
      case 'careerStage':
      case 'industry':
      case 'contentConsumptionFrequency':
      case 'kpis':
      case 'occupation':
      case 'preferredLearningStyle':
      case 'keyMotivations':
      case 'coreValues':
      case 'contentTone':
      case 'decisionFactors':
      case 'primaryGoal':
        return renderDropdownField(field, fieldTitle, isRequired, placeholders[field]);
      case 'buyingNuances':
      case 'painPoints':
        return renderTextAreaField(field, fieldTitle, isRequired, placeholders[field]);
      default:
        return renderTextInputField(field, fieldTitle, isRequired, placeholders[field]);
    }
  };

  // Update the renderTextInputField function
  const renderTextInputField = (field, fieldTitle, isRequired, placeholder) => {
    const hasError = errors[field];
    
    return (
      <div className="relative">
        <input
          type="text"
          name={field}
          value={audienceData[field]}
          onChange={handleInputChange}
          className={`block w-full px-3 py-2 text-sm rounded-md border transition-colors duration-200
                    ${isDarkMode 
                      ? 'bg-[#1a1a1a] border-[#333333] text-slate-200 hover:border-[#202020]' 
                      : 'bg-white border-gray-200 text-gray-900 hover:border-gray-300'}
                  ${hasError 
                    ? 'border-red-500' 
                    : isDarkMode 
                      ? 'focus:border-blue-500' 
                      : 'focus:border-indigo-500'}
                  focus:outline-none
                  placeholder:text-[12px] placeholder:text-slate-400/50 dark:placeholder:text-slate-500/50
                  dark:[&:-webkit-autofill]:shadow-[0_0_0_30px_#1a1a1a_inset]
                  dark:[&:-webkit-autofill]:[-webkit-text-fill-color:rgb(226,232,240)]
                  [&:-webkit-autofill]:shadow-[0_0_0_30px_rgb(255,255,255)_inset]
                  [&:-webkit-autofill]:[-webkit-text-fill-color:rgb(17,24,39)]
                  [&:-webkit-autofill]:[transition:background-color_9999s_ease-in-out_0s]
                  dark:[&:-webkit-autofill]:border-[#333333]
                  [&:-webkit-autofill]:border-gray-200`}
          placeholder={placeholder}
        />
      </div>
    );
  };

  // Update the renderTextAreaField function
  const renderTextAreaField = (field, fieldTitle, isRequired, placeholder) => {
    const hasError = errors[field];
    
    return (
      <div className="relative">
        <textarea
          name={field}
          value={audienceData[field] || ''}
          onChange={handleInputChange}
          rows={4}
          className={`block w-full px-3 py-2 text-sm rounded-md border transition-colors duration-200
                    resize-y min-h-[100px] max-h-[400px]
                    ${isDarkMode 
                      ? 'bg-[#1a1a1a] border-[#333333] text-slate-200 hover:border-[#202020]' 
                      : 'bg-white border-gray-200 text-gray-900 hover:border-gray-300'}
                  ${hasError 
                    ? 'border-red-500' 
                    : isDarkMode 
                      ? 'focus:border-blue-500' 
                      : 'focus:border-indigo-500'}
                  focus:outline-none
                  placeholder:text-xs placeholder:text-slate-400/50 dark:placeholder:text-slate-500/50`}
          placeholder={placeholder}
        />
      </div>
    );
  };

  // Update the renderDropdownField function to handle contentConsumptionFrequency differently
  const renderDropdownField = (field, fieldTitle) => {
    const { options, placeholder } = getOptionsForField(field, customOptions);
    
    // Special handling for contentConsumptionFrequency
    if (field === 'contentConsumptionFrequency') {
      return (
        <div key={field}>
          <CustomDropdown
            options={options}
            value={audienceData[field] || ''}  // Ensure we pass a string value
            onChange={(value) => handleCustomDropdownChange(field, value)}
            placeholder={placeholder}
            isDarkMode={isDarkMode}
            hasError={!!errors[field]}
          />
          {errors[field] && (
            <p className="text-red-500 text-xs mt-1">This field is required</p>
          )}
        </div>
      );
    }

    // Original handling for other fields
    return (
      <div key={field}>
        <CustomDropdown
          options={options}
          value={Array.isArray(audienceData[field]) ? audienceData[field][0] : audienceData[field]}
          onChange={(value) => handleCustomDropdownChange(field, value)}
          placeholder={placeholder}
          isDarkMode={isDarkMode}
          hasError={!!errors[field]}
        />
        {errors[field] && (
          <p className="text-red-500 text-xs mt-1">{errors[field]}</p>
        )}
        {audienceData[field] === 'custom' && (
          <input
            type="text"
            name={`${field}Custom`}
            value={audienceData[`${field}Custom`] || ''}
            onChange={handleInputChange}
            className={`mt-2 block w-full border rounded-md shadow-sm py-2 px-3 
                      focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
                      ${isDarkMode
                        ? 'bg-slate-700 border-slate-600 text-slate-200'
                        : 'bg-white border-gray-300 text-gray-900'}`}
            placeholder={`Enter custom ${fieldTitle.toLowerCase()}`}
          />
        )}
      </div>
    );
  };

  const saveProgress = () => {
    const progressData = {
      audienceData,
      step,
      sectionProgress,
      avatarUrl,  // Save the current avatar URL
      selectedPersona,  // Save the selected persona
    };

    if (audienceId) {
      // Save progress for existing audience
      localStorage.setItem(`audienceProgress_${audienceId}`, JSON.stringify(progressData));
    } else {
      // Save progress for new audience
      localStorage.setItem('newAudienceProgress', JSON.stringify(progressData));
    }
    addNotification('Progress Saved', 'Your progress has been saved. You can continue later.', 'success');
  };

  const loadProgress = () => {
    let savedProgress;
    if (audienceId) {
      // Load progress for existing audience
      savedProgress = localStorage.getItem(`audienceProgress_${audienceId}`);
    } else {
      // Load progress for new audience
      savedProgress = localStorage.getItem('newAudienceProgress');
    }

    if (savedProgress) {
      const { 
        audienceData: savedAudienceData, 
        step: savedStep, 
        sectionProgress: savedSectionProgress,
        avatarUrl: savedAvatarUrl,
        selectedPersona: savedSelectedPersona
      } = JSON.parse(savedProgress);
      
      setAudienceData(savedAudienceData);
      setStep(savedStep);
      setSectionProgress(savedSectionProgress);
      
      // Restore the avatar URL
      if (savedAvatarUrl) {
        setAvatarUrl(savedAvatarUrl);
      }
      
      // Restore the selected persona
      if (savedSelectedPersona) {
        setSelectedPersona(savedSelectedPersona);
      }
      
      addNotification('Progress Loaded', 'Your previous progress has been loaded.', 'info');
    }
  };

  const clearSavedProgress = () => {
    if (audienceId) {
      localStorage.removeItem(`audienceProgress_${audienceId}`);
    } else {
      localStorage.removeItem('newAudienceProgress');
    }
    setAudienceData({
      // Reset to initial state
      targetAudienceName: '',
      ageRange: '',
      primaryLocation: '',
      careerStage: '',
      industry: '',
      primaryGoal: [],
      preferredLearningStyle: [],
      contentConsumptionFrequency: '',
      keyMotivations: [],
      coreValues: [],
      painPoints: '',
      contentTone: [],
      associatedPersona: null,
    });
    setStep(0);
    setSectionProgress(steps.map(step => ({ title: step.title, status: 'pending' })));
    setAvatarUrl(null);  // Clear the avatar URL
    setSelectedPersona(null);  // Clear the selected persona
    addNotification('Progress Cleared', 'Your saved progress has been cleared.', 'info');
  };

  const handleSectionClick = (index) => {
    updateSectionProgress(step);
    setStep(index);
  };

  useEffect(() => {
    updateSectionProgress(step);
    console.log('Updated sectionProgress:', sectionProgress);
  }, [audienceData, step, avatarUrl, selectedPersona]);

  const handlePersonaSelect = (persona) => {
    setSelectedPersona(persona);
    setAudienceData(prevData => ({
      ...prevData,
      associatedPersona: persona._id
    }));
  };

  useEffect(() => {
    return () => {
      if (avatarUrl && avatarUrl.startsWith('blob:')) {
        URL.revokeObjectURL(avatarUrl);
      }
    };
  }, [avatarUrl]);

  // Update this function
  const updateAvatarUrlInParent = useCallback((newAvatarUrl) => {
    if (onAvatarUpdate && audienceId) {
      onAvatarUpdate(audienceId, newAvatarUrl);
    }
  }, [onAvatarUpdate, audienceId]);

  // Update the useEffect for avatar URL
  useEffect(() => {
    if (audienceId) {
      const fetchAvatar = async () => {
        try {
          const avatarUrl = await fetchAvatarUrl(audienceId);
          if (avatarUrl) {
            setAvatarUrl(avatarUrl);
            saveAvatarToCache(audienceId, avatarUrl);
            updateAvatarUrlInParent(avatarUrl);
          }
        } catch (error) {
          console.error('Error fetching audience avatar:', error);
          addNotification('Error', 'Failed to load audience avatar', 'error');
        }
      };
      fetchAvatar();
    }
  }, [audienceId, updateAvatarUrlInParent]);

  // Add this useEffect to log sectionProgress changes
  useEffect(() => {
    console.log('Updated sectionProgress:', sectionProgress);
  }, [sectionProgress]);

  // Add this function to check if all mandatory fields are filled
  const areMandatoryFieldsFilled = () => {
    return requiredFields.every(field => {
      if (Array.isArray(audienceData[field])) {
        return audienceData[field].length > 0;
      }
      return audienceData[field] && audienceData[field].trim() !== '';
    });
  };

  // Update the tooltip position function
  const updateTooltipPosition = () => {
    if (saveButtonRef.current) {
      const rect = saveButtonRef.current.getBoundingClientRect();
      setTooltipPosition({
        top: rect.top + window.scrollY - 40, // Position above the button
        left: rect.left + window.scrollX + rect.width / 2, // Center horizontally
      });
    }
  };

  // Add this effect to handle tooltip positioning
  useEffect(() => {
    if (showTooltip) {
      updateTooltipPosition();
      window.addEventListener('scroll', updateTooltipPosition);
      window.addEventListener('resize', updateTooltipPosition);
    }
    return () => {
      window.removeEventListener('scroll', updateTooltipPosition);
      window.removeEventListener('resize', updateTooltipPosition);
    };
  }, [showTooltip]);

  // Update the sections to be more focused and progressive
  const sections = [
    {
      title: 'Profile & Demographics',
      description: 'Who your audience are',
      fields: [
        'audienceImage',
        'targetAudienceName',
        'occupation',
        'ageRange',
        'primaryLocation',
        'careerStage',
        'industry',
        'associatedPersona'
      ],
      twoColumnFields: {
        targetAudienceName: true,
        occupation: true,
        ageRange: true,
        primaryLocation: true,
        careerStage: true,
        industry: true
      }
    },
    {
      title: 'Goals & Motivations',
      description: 'What drives them',
      fields: [
        'primaryGoal',
        'keyMotivations',
        'painPoints',
        'coreValues'
      ],
      twoColumnFields: {
        primaryGoal: true,
        keyMotivations: true
      }
    },
    {
      title: 'Business Insights',
      description: 'How they make decisions',
      fields: [
        'buyingNuances',
        'kpis',
        'decisionFactors'
      ],
      twoColumnFields: {
        kpis: true,
        decisionFactors: true
      }
    },
    {
      title: 'Content Preferences',
      description: 'How they consume content',
      fields: [
        'contentConsumptionFrequency',
        'contentTone',
        'preferredLearningStyle'
      ],
      twoColumnFields: {
        contentConsumptionFrequency: true,
        contentTone: true,
        preferredLearningStyle: true
      }
    }
  ];

  // Update the renderSection function
  const renderSection = (section, index) => (
    <div key={index} className={`mb-8 rounded-lg overflow-hidden
                              ${isDarkMode 
                                ? 'bg-[#151515] border-0' // Remove border in dark mode for cleaner look
                                : 'bg-white border border-gray-200'}`}>
      <div className={`px-6 py-5 border-b 
                    ${isDarkMode 
                      ? 'bg-[#151515] border-[#1e1e1e]' // Lighter border, same bg as container
                      : 'bg-gradient-to-r from-gray-50 to-gray-50/80 border-gray-200'}`}>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <div className={`flex items-center justify-center w-10 h-10 rounded-lg text-base font-semibold
              ${isDarkMode 
                ? 'bg-[#1a1a1a] text-slate-400 border border-[#202020]' // Use border instead of ring
                : 'bg-white text-gray-900 ring-1 ring-gray-200 shadow-sm'}`}>
              {index + 1}
            </div>
            <div className="space-y-1">
              <div className="flex items-center gap-2">
                <h2 className={`text-lg font-semibold leading-none tracking-tight
                              ${isDarkMode ? 'text-slate-100' : 'text-gray-900'}`}>
                  {section.title}
                </h2>
                {section.optional && (
                  <span className={`px-2 py-0.5 text-xs rounded-full
                                ${isDarkMode 
                                  ? 'bg-[#1a1a1a] text-slate-400' // Changed from bg-[#1e1e1e]
                                  : 'bg-gray-100 text-gray-600'}`}>
                    Optional
                  </span>
                )}
              </div>
              <p className={`text-sm ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
                {section.description}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={`p-6 ${isDarkMode ? 'bg-[#151515]' : 'bg-white'}`}>
        <div className="space-y-6">
          {renderSectionFields(section.fields, section.twoColumnFields)}
        </div>
      </div>
    </div>
  );

  // Update the renderSectionFields function
  const renderSectionFields = (fields, twoColumnFields) => {
    let currentRow = [];
    const rows = [];

    fields.forEach((field, index) => {
      // Special handling for full-width fields
      if (field === 'audienceImage' || 
          field === 'associatedPersona' || 
          field === 'buyingNuances' || 
          field === 'expectedBuyingCycle') {
        if (currentRow.length > 0) {
          rows.push(
            <div key={`row-${index}`} className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
              {currentRow}
            </div>
          );
          currentRow = [];
        }
        rows.push(
          <div key={`field-${field}`} className="col-span-1">
            {renderFieldContent(field)}
          </div>
        );
      } else if (twoColumnFields[field]) {
        currentRow.push(
          <div key={`field-${field}`} className="col-span-1">
            {renderFieldContent(field)}
          </div>
        );
        
        if (currentRow.length === 2) {
          rows.push(
            <div key={`row-${index}`} className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
              {currentRow}
            </div>
          );
          currentRow = [];
        }
      } else {
        rows.push(
          <div key={`field-${field}`} className="col-span-1">
            {renderFieldContent(field)}
          </div>
        );
      }
    });

    // Handle any remaining fields in the current row
    if (currentRow.length > 0) {
      rows.push(
        <div key="final-row" className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
          {currentRow}
        </div>
      );
    }

    return (
      <div className="space-y-4">
        {rows}
      </div>
    );
  };

  // Update the validateRequiredFields function
  const validateRequiredFields = () => {
    const newErrors = {};

    requiredFields.forEach(field => {
      const value = audienceData[field];
      
      // Handle different types of values
      const isEmpty = Array.isArray(value) 
        ? value.length === 0  // Check if array is empty
        : !value || (typeof value === 'string' && value.trim() === ''); // Check if string is empty
      
      if (isEmpty) {
        newErrors[field] = true;
      }
    });

    return newErrors;
  };

  // Update the getFieldValue function to handle arrays
  const getFieldValue = (field) => {
    const value = audienceData[field];
    
    // Handle arrays
    if (Array.isArray(value)) {
      return value.length > 0 ? value : '';
    }
    
    // Handle custom values
    if (value === 'custom') {
      return audienceData[`${field}Custom`] || '';
    }
    
    return value;
  };

  return (
    <div className={`flex flex-col h-full relative
                  ${isDarkMode ? 'bg-[#121212]' : 'bg-gray-50'}
                  transition-colors duration-300`}>
      {/* Top Bar - Add responsive padding */}
      <div className={`${isDarkMode 
        ? 'bg-[#151515]/95 border-[#333333]'
        : 'bg-white bg-opacity-90 border-gray-200'} 
        !h-10 min-h-10 flex-shrink-0 flex items-center 
        px-2 sm:px-4 py-2 w-full border-b`}>
        <div className="flex items-center">
          <UserGroupIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'} mr-2`} />
          <h2 className={`text-sm font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>
            {audienceId ? 'Edit Target Audience' : 'New Target Audience'}
          </h2>
        </div>
      </div>

      {/* Notifications */}
      <div className="fixed top-4 right-4 z-50">
        {notifications.map((notification) => (
          <Notification
            key={notification.id}
            {...notification}
            onClose={() => removeNotification(notification.id)}
          />
        ))}
      </div>

      {/* Main Content - Update max-width and padding for mobile */}
      <div className={`flex-1 overflow-auto custom-scrollbar
                      ${isDarkMode ? 'bg-[#121212]' : 'bg-gray-50'}`}>
        <div className="max-w-3xl mx-auto px-3 sm:px-4 py-4 sm:py-8">
          {/* Action Buttons - Make responsive */}
          <div className="mb-4 sm:mb-8 flex flex-col sm:flex-row gap-3 sm:gap-0 sm:justify-between sm:items-center">
            <button
              onClick={() => navigate('/target-audiences')}
              className={`flex items-center px-3 py-2 rounded-md transition-colors duration-200 text-sm
                        ${isDarkMode 
                          ? 'bg-[#1a1a1a] hover:bg-[#202020] text-slate-200 border border-[#333333]'
                          : 'bg-gray-100 hover:bg-gray-200 text-gray-700'}`}>
              <ArrowLeftIcon className="w-4 h-4 mr-2" />
              Back to Target Audiences
            </button>

            <button
              onClick={handleSave}
              disabled={!areMandatoryFieldsFilled() || isSaving}
              className={`
                flex items-center px-4 py-2 rounded-md text-sm font-medium
                transition-all duration-200 shadow-sm
                ${!areMandatoryFieldsFilled() || isSaving
                  ? isDarkMode
                    ? 'bg-[#1a1a1a] text-slate-500 border border-[#333333] cursor-not-allowed'
                    : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  : isDarkMode
                    ? 'bg-blue-500 hover:bg-blue-600 text-white'
                    : 'bg-blue-600 hover:bg-blue-700 text-white'
                }
              `}
            >
              {isSaving ? (
                <span className="flex items-center">
                  <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Saving...
                </span>
              ) : (
                <>
                  <CheckIcon className="w-4 h-4 mr-2" />
                  {audienceId ? 'Update' : 'Save'} Target Audience
                </>
              )}
            </button>
          </div>

          {/* Sections */}
          {sections.map((section, index) => (
            <div key={index} className={`mb-4 sm:mb-8 rounded-lg overflow-hidden
                                      ${isDarkMode 
                                        ? 'bg-[#151515] border-0'
                                        : 'bg-white border border-gray-200'}`}>
              {/* Section header */}
              <div className={`px-4 sm:px-6 py-4 sm:py-5 border-b 
                            ${isDarkMode 
                              ? 'bg-[#151515] border-[#1e1e1e]'
                              : 'bg-gradient-to-r from-gray-50 to-gray-50/80 border-gray-200'}`}>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-4">
                    <div className={`flex items-center justify-center w-10 h-10 rounded-lg text-base font-semibold
                      ${isDarkMode 
                        ? 'bg-[#1a1a1a] text-slate-400 border border-[#202020]' // Use border instead of ring
                        : 'bg-white text-gray-900 ring-1 ring-gray-200 shadow-sm'}`}>
                      {index + 1}
                    </div>
                    <div className="space-y-1">
                      <div className="flex items-center gap-2">
                        <h2 className={`text-lg font-semibold leading-none tracking-tight
                              ${isDarkMode ? 'text-slate-100' : 'text-gray-900'}`}>
                          {section.title}
                        </h2>
                        {section.optional && (
                          <span className={`px-2 py-0.5 text-xs rounded-full
                                ${isDarkMode 
                                  ? 'bg-[#1a1a1a] text-slate-400' // Changed from bg-[#1e1e1e]
                                  : 'bg-gray-100 text-gray-600'}`}>
                            Optional
                          </span>
                        )}
                      </div>
                      <p className={`text-sm ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
                        {section.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Section content - Update grid layout for mobile */}
              <div className={`p-4 sm:p-6 ${isDarkMode ? 'bg-[#151515]' : 'bg-white'}`}>
                <div className="space-y-4 sm:space-y-6">
                  {renderSectionFields(section.fields, section.twoColumnFields)}
                </div>
              </div>
            </div>
          ))}

          {/* Bottom action buttons - Make responsive */}
          <div className="mt-4 sm:mt-8 flex flex-col sm:flex-row gap-3 sm:gap-0 sm:justify-between sm:items-center">
            <button
              onClick={() => navigate('/target-audiences')}
              className={`flex items-center px-3 py-2 rounded-md transition-colors duration-200 text-sm
                        ${isDarkMode 
                          ? 'bg-[#1a1a1a] hover:bg-[#202020] text-slate-200 border border-[#333333]'
                          : 'bg-gray-100 hover:bg-gray-200 text-gray-700'}`}>
              <ArrowLeftIcon className="w-4 h-4 mr-2" />
              Back to Target Audiences
            </button>

            <button
              onClick={handleSave}
              disabled={!areMandatoryFieldsFilled() || isSaving}
              className={`
                flex items-center px-4 py-2 rounded-md text-sm font-medium
                transition-all duration-200 shadow-sm
                ${!areMandatoryFieldsFilled() || isSaving
                  ? isDarkMode
                    ? 'bg-[#1a1a1a] text-slate-500 border border-[#333333] cursor-not-allowed'
                    : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  : isDarkMode
                    ? 'bg-blue-500 hover:bg-blue-600 text-white'
                    : 'bg-blue-600 hover:bg-blue-700 text-white'
                }
              `}
            >
              {isSaving ? (
                <span className="flex items-center">
                  <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Saving...
                </span>
              ) : (
                <>
                  <CheckIcon className="w-4 h-4 mr-2" />
                  {audienceId ? 'Update' : 'Save'} Target Audience
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Add PropTypes validation
TargetAudienceCreationWizard.propTypes = {
  onAvatarUpdate: PropTypes.func,
};

export default TargetAudienceCreationWizard;