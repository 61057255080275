import React, { useEffect, useRef } from 'react';
import { useSearch } from '../../context/SearchContext';
import { useTheme } from '../../context/ThemeContext';
import { XMarkIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import SearchBar from './SearchBar';
import SearchFilters from './SearchFilters';
import SearchResults from './SearchResults';
import '../customScrollbar.css';
import { motion, AnimatePresence } from 'framer-motion';

const EmptyState = ({ isInitial, isDarkMode }) => (
  <div className="flex flex-col items-center justify-center h-full px-4 -mt-8">
    <div className={`rounded-full p-4 mb-4 ${
      isDarkMode 
        ? 'bg-gray-800 ring-1 ring-gray-700' 
        : 'bg-gray-50 ring-1 ring-gray-100/50'
    }`}>
      <MagnifyingGlassIcon className={`h-7 w-7 ${
        isDarkMode ? 'text-gray-500' : 'text-gray-300'
      }`} />
    </div>
    {isInitial ? (
      <>
        <h3 className={`text-base font-semibold mb-2 ${
          isDarkMode ? 'text-gray-200' : 'text-gray-900'
        }`}>
          Search your workspace
        </h3>
        <p className={`text-sm max-w-[280px] text-center leading-relaxed ${
          isDarkMode ? 'text-gray-400' : 'text-gray-500'
        }`}>
          Find files, chats, tasks and more by typing in the search bar above
        </p>
      </>
    ) : (
      <>
        <h3 className={`text-base font-semibold mb-2 ${
          isDarkMode ? 'text-gray-200' : 'text-gray-900'
        }`}>
          No results found
        </h3>
        <p className={`text-sm max-w-[280px] text-center leading-relaxed ${
          isDarkMode ? 'text-gray-400' : 'text-gray-500'
        }`}>
          Try searching with different keywords or filters
        </p>
      </>
    )}
  </div>
);

const LoadingState = ({ isDarkMode }) => (
  <div className="flex items-center justify-center h-full -mt-8">
    <motion.div 
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className="flex flex-col items-center space-y-4"
    >
      <div className="relative">
        <div className={`w-8 h-8 border-2 rounded-full ${
          isDarkMode ? 'border-gray-700' : 'border-gray-200'
        }`}></div>
        <div className={`absolute top-0 left-0 w-8 h-8 border-2 rounded-full border-t-transparent animate-spin ${
          isDarkMode ? 'border-blue-400' : 'border-blue-500'
        }`}></div>
      </div>
      <span className={`text-sm ${
        isDarkMode ? 'text-gray-400' : 'text-gray-500'
      }`}>Searching...</span>
    </motion.div>
  </div>
);

const SearchModal = () => {
  const modalRef = useRef(null);
  const { isDarkMode } = useTheme();
  const { 
    isOpen, 
    closeSearch,
    loading,
    results,
    searchQuery,
    handleKeyDown,
    activeIndex,
    getAllResults
  } = useSearch();

  // Modify the click outside handler to be more specific
  useEffect(() => {
    if (!isOpen) return;

    const handleClickOutside = (event) => {
      // Check if click is on the dark overlay (background) only
      if (
        modalRef.current && 
        !modalRef.current.contains(event.target) &&
        event.target.classList.contains('modal-overlay')  // We'll add this class
      ) {
        closeSearch();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, closeSearch]);

  // Focus management
  useEffect(() => {
    if (isOpen) {
      modalRef.current?.focus();
    }
  }, [isOpen]);

  // Scroll active item into view
  useEffect(() => {
    const activeItem = document.querySelector('[data-active="true"]');
    if (activeItem) {
      activeItem.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    }
  }, [activeIndex]);

  if (!isOpen) return null;

  const hasResults = Object.keys(results).length > 0;
  const isInitialState = !searchQuery && !loading && !hasResults;

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.15 }}
          className={`fixed inset-0 z-[99999] flex items-start justify-center modal-overlay backdrop-blur-sm ${
            isDarkMode ? 'bg-black/60' : 'bg-black/40'
          }`}
        >
          <motion.div 
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            transition={{ duration: 0.1 }}
            ref={modalRef}
            tabIndex={-1}
            onKeyDown={handleKeyDown}
            className={`relative z-50 w-full max-w-2xl rounded-xl shadow-2xl flex flex-col mt-[12vh] mx-4 overflow-hidden ${
              isDarkMode 
                ? 'bg-[#1a1a1a] ring-1 ring-gray-800' 
                : 'bg-white ring-1 ring-black/10'
            }`}
            style={{ height: '650px' }}
          >
            {/* Search header with gradient background */}
            <div className={`flex-shrink-0 border-b ${
              isDarkMode 
                ? 'bg-gradient-to-b from-[#1a1a1a] to-[#1a1a1a] border-gray-800' 
                : 'bg-gradient-to-b from-gray-50 to-white border-gray-100'
            }`}>
              <SearchBar />
              <button
                onClick={closeSearch}
                className={`absolute right-4 top-4 p-1 rounded-full transition-colors ${
                  isDarkMode
                    ? 'text-gray-500 hover:text-gray-300 hover:bg-gray-800'
                    : 'text-gray-400 hover:text-gray-600 hover:bg-gray-100'
                }`}
              >
                <XMarkIcon className="h-5 w-5" />
              </button>
            </div>

            {/* Active filters with animation */}
            <motion.div 
              initial={{ y: -10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className={`px-4 py-2 border-b flex-shrink-0 ${
                isDarkMode 
                  ? 'border-gray-800 bg-[#1a1a1a]' 
                  : 'border-gray-100 bg-white'
              }`}
            >
              <SearchFilters />
            </motion.div>

            {/* Results section */}
            <div className={`flex-1 overflow-y-auto custom-scrollbar-overlay ${
              isDarkMode ? 'bg-[#1a1a1a]' : 'bg-white'
            }`}>
              {loading ? (
                <LoadingState isDarkMode={isDarkMode} />
              ) : hasResults ? (
                <SearchResults />
              ) : (
                <EmptyState isInitial={isInitialState} isDarkMode={isDarkMode} />
              )}
            </div>

            {/* Footer with keyboard shortcuts */}
            <div className={`px-4 py-2.5 border-t backdrop-blur-sm text-xs flex justify-between rounded-b-xl flex-shrink-0 ${
              isDarkMode 
                ? 'border-gray-800 bg-gray-900/50 text-gray-400' 
                : 'border-gray-100 bg-gray-50/80 text-gray-500'
            }`}>
              <div className="flex space-x-4">
                <span className="flex items-center">
                  <kbd className={`px-1.5 py-0.5 border rounded shadow-sm ${
                    isDarkMode 
                      ? 'bg-gray-800 border-gray-700 text-gray-400' 
                      : 'bg-white border-gray-200 text-gray-500'
                  }`}>↑</kbd>
                  <kbd className={`px-1.5 py-0.5 border rounded shadow-sm ml-1 ${
                    isDarkMode 
                      ? 'bg-gray-800 border-gray-700 text-gray-400' 
                      : 'bg-white border-gray-200 text-gray-500'
                  }`}>↓</kbd>
                  <span className={`ml-1.5 ${
                    isDarkMode ? 'text-gray-400' : 'text-gray-600'
                  }`}>navigate</span>
                </span>
                <span className="flex items-center">
                  <kbd className={`px-1.5 py-0.5 border rounded shadow-sm ${
                    isDarkMode 
                      ? 'bg-gray-800 border-gray-700 text-gray-400' 
                      : 'bg-white border-gray-200 text-gray-500'
                  }`}>↵</kbd>
                  <span className={`ml-1.5 ${
                    isDarkMode ? 'text-gray-400' : 'text-gray-600'
                  }`}>select</span>
                </span>
              </div>
              <span className="flex items-center">
                <kbd className={`px-1.5 py-0.5 border rounded shadow-sm ${
                  isDarkMode 
                    ? 'bg-gray-800 border-gray-700 text-gray-400' 
                    : 'bg-white border-gray-200 text-gray-500'
                }`}>esc</kbd>
                <span className={`ml-1.5 ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-600'
                }`}>close</span>
              </span>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SearchModal; 