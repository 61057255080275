import React, { useState } from 'react';
import { XMarkIcon, ChevronDownIcon, ChatBubbleLeftIcon, DocumentDuplicateIcon, CheckIcon } from '@heroicons/react/24/outline';

const RedditDataModal = ({ isOpen, onClose, redditData, isDarkMode }) => {
  const [showCopySuccess, setShowCopySuccess] = useState(false);
  
  if (!isOpen) return null;

  const formatDate = (timestamp) => {
    return new Date(timestamp * 1000).toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
  };

  const handleCopyAllData = async () => {
    try {
      const formattedData = redditData.map(post => {
        const postData = [
          `Title: ${post.title}`,
          `Score: ${post.score} | Comments: ${post.num_comments}`,
          `Posted: ${formatDate(post.created_utc)}`,
          `URL: ${post.url}`,
          post.selftext ? `\nContent:\n${post.selftext}` : '',
          post.comments?.length > 0 ? '\nTop Comments:' : '',
          ...(post.comments || []).map(comment => 
            `- u/${comment.author} (${comment.score} points):\n  ${comment.body.replace(/\n/g, '\n  ')}`
          )
        ].filter(Boolean);
        
        return postData.join('\n');
      }).join('\n\n---\n\n');

      await navigator.clipboard.writeText(formattedData);
      setShowCopySuccess(true);
      setTimeout(() => setShowCopySuccess(false), 2000);
    } catch (err) {
      console.error('Failed to copy data:', err);
    }
  };

  const RedditComment = ({ comment }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
      <div className={`pl-3 border-l ${
        isDarkMode ? 'border-gray-600/30' : 'border-gray-300/40'
      }`}>
        <div className="flex items-center gap-2 mb-1">
          <span className={`text-xs font-medium ${
            isDarkMode ? 'text-gray-300' : 'text-gray-700'
          }`}>
            {comment.is_submitter && (
              <span className={`inline-block px-1 py-0.5 rounded text-xs mr-1 ${
                isDarkMode ? 'bg-blue-500/20 text-blue-300' : 'bg-blue-100 text-blue-700'
              }`}>
                OP
              </span>
            )}
            u/{comment.author}
          </span>
          <span className={`text-xs ${
            isDarkMode ? 'text-gray-400' : 'text-gray-500'
          }`}>
            • {formatDate(comment.created_utc)}
          </span>
          <span className={`text-xs ${
            isDarkMode ? 'text-gray-400' : 'text-gray-500'
          }`}>
            • ⬆️ {comment.score}
          </span>
        </div>
        <div className={`text-xs ${
          isDarkMode ? 'text-gray-300' : 'text-gray-600'
        }`}>
          {isExpanded ? comment.body : (
            <>
              {comment.body.slice(0, 150)}
              {comment.body.length > 150 && (
                <>
                  ...{' '}
                  <button
                    onClick={() => setIsExpanded(true)}
                    className={`text-xs ${
                      isDarkMode 
                        ? 'text-indigo-400 hover:text-indigo-300' 
                        : 'text-indigo-500 hover:text-indigo-600'
                    }`}
                  >
                    more
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  const PostCard = ({ post }) => {
    const [showComments, setShowComments] = useState(false);
    const hasComments = post.comments && post.comments.length > 0;

    return (
      <div className={`p-3 rounded-lg border transition-colors
        ${isDarkMode 
          ? 'bg-slate-700/50 border-gray-600/30 shadow-sm shadow-black/10' 
          : 'bg-gray-50/80 border-gray-200/40 shadow-sm shadow-gray-100/80'
        }`}
      >
        {/* Post Header */}
        <div className="space-y-1.5">
          <h4 className={`text-sm font-medium ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            {post.title}
          </h4>
          
          {/* Post Metadata */}
          <div className="flex flex-wrap items-center gap-x-3 gap-y-1">
            <span className={`text-xs ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              ⬆️ {post.score}
            </span>
            <span className={`text-xs ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              💬 {post.num_comments}
            </span>
            <span className={`text-xs ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              🕒 {formatDate(post.created_utc)}
            </span>
          </div>
        </div>

        {/* Post Content */}
        {post.selftext && (
          <div className={`mt-2 text-xs whitespace-pre-line ${
            isDarkMode ? 'text-gray-300' : 'text-gray-600'
          }`}>
            {post.selftext.length > 200 
              ? `${post.selftext.slice(0, 200)}...` 
              : post.selftext}
          </div>
        )}

        {/* Post Footer */}
        <div className="mt-3 flex items-center justify-between">
          <a 
            href={post.url}
            target="_blank"
            rel="noopener noreferrer"
            className={`text-xs hover:underline ${
              isDarkMode 
                ? 'text-indigo-400 hover:text-indigo-300' 
                : 'text-indigo-500 hover:text-indigo-600'
            }`}
          >
            View on Reddit →
          </a>
          
          {hasComments && (
            <button
              onClick={() => setShowComments(!showComments)}
              className={`flex items-center gap-1.5 px-2 py-1 rounded-md text-xs transition-colors ${
                isDarkMode 
                  ? 'text-gray-300 hover:text-gray-200 hover:bg-gray-600/30' 
                  : 'text-gray-600 hover:text-gray-700 hover:bg-gray-100/80'
              }`}
            >
              <ChatBubbleLeftIcon className="w-3.5 h-3.5" />
              <span>{showComments ? 'Hide' : 'Show'} ({post.comments.length})</span>
              <ChevronDownIcon 
                className={`w-3.5 h-3.5 transition-transform ${showComments ? 'rotate-180' : ''}`} 
              />
            </button>
          )}
        </div>

        {/* Comments Section */}
        {showComments && hasComments && (
          <div className={`mt-3 pt-3 space-y-3 border-t ${
            isDarkMode ? 'border-gray-600/30' : 'border-gray-200/40'
          }`}>
            {post.comments.map((comment, idx) => (
              <RedditComment key={comment.comment_id || idx} comment={comment} />
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Modal */}
      <div className={`relative w-full max-w-2xl h-[calc(100vh-8rem)] mx-4 rounded-lg 
        ${isDarkMode 
          ? 'bg-slate-800 border border-gray-700/50 shadow-lg shadow-black/20' 
          : 'bg-white border border-gray-200/60 shadow-lg shadow-gray-200/80'
        }`}
      >
        {/* Header */}
        <div className={`flex items-center justify-between p-3 border-b ${
          isDarkMode ? 'border-gray-700/50' : 'border-gray-200/60'
        }`}>
          <h3 className={`text-sm font-semibold ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Reddit Data
          </h3>
          <div className="flex items-center gap-2">
            <button
              onClick={handleCopyAllData}
              className={`flex items-center gap-1.5 px-2 py-1 rounded-md text-xs transition-colors ${
                isDarkMode 
                  ? 'text-gray-300 hover:text-gray-200 hover:bg-gray-700/80' 
                  : 'text-gray-600 hover:text-gray-700 hover:bg-gray-100/80'
              }`}
              title={showCopySuccess ? "Copied!" : "Copy all data"}
            >
              {showCopySuccess ? (
                <CheckIcon className="w-3.5 h-3.5 text-green-500" />
              ) : (
                <DocumentDuplicateIcon className="w-3.5 h-3.5" />
              )}
              <span>{showCopySuccess ? "Copied!" : "Copy All"}</span>
            </button>
            <button
              onClick={onClose}
              className={`p-1 rounded-full transition-colors ${
                isDarkMode 
                  ? 'text-gray-400 hover:bg-slate-700/80' 
                  : 'text-gray-500 hover:bg-gray-100/80'
              }`}
            >
              <XMarkIcon className="w-4 h-4" />
            </button>
          </div>
        </div>

        {/* Content */}
        <div className="p-4 h-[calc(100%-3.5rem)] overflow-y-auto">
          <div className="grid gap-4">
            {redditData.map((post, index) => (
              <PostCard key={post.post_id || index} post={post} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedditDataModal;
