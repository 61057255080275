import React, { useState, useEffect, useRef, useCallback } from 'react';
import PersonasPage from './PersonasPage';
import PersonaCreationWizardForChatInterface from './PersonaCreationWizardForChatInterface';
import { fetchAvatarUrl, getAvatarFromCache, saveAvatarToCache, refreshPersonaAvatarUrl } from '../../utils/avatarUtils';
import { UserIcon, PlusIcon, XMarkIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import { createPortal } from 'react-dom';

const PersonaSelector = ({ selectedPersona, onSelectPersona, isDarkMode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentPersona, setCurrentPersona] = useState(selectedPersona);
  const [personaAvatarUrl, setPersonaAvatarUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const modalRef = useRef(null);
  const [editingPersona, setEditingPersona] = useState(null);

  const fetchPersonaAvatar = useCallback(async (personaId) => {
    setIsLoading(true);
    setError(null);
    try {
      let avatarUrl = getAvatarFromCache(personaId);
      if (!avatarUrl) {
        avatarUrl = await fetchAvatarUrl(personaId);
      }
      setPersonaAvatarUrl(avatarUrl || '/default-avatar.png');
    } catch (error) {
      console.error('Error fetching persona avatar:', error);
      setError('Failed to load avatar');
      setPersonaAvatarUrl('/default-avatar.png');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    setCurrentPersona(selectedPersona);
    if (selectedPersona && selectedPersona._id) {
      fetchPersonaAvatar(selectedPersona._id);
    }
  }, [selectedPersona, fetchPersonaAvatar]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const refreshAvatarPeriodically = async () => {
      if (currentPersona && currentPersona._id) {
        const newAvatarUrl = await refreshPersonaAvatarUrl(currentPersona._id);
        if (newAvatarUrl) {
          setPersonaAvatarUrl(newAvatarUrl);
        }
      }
    };

    const intervalId = setInterval(refreshAvatarPeriodically, 30 * 60 * 1000); // Refresh every 30 minutes

    return () => clearInterval(intervalId);
  }, [currentPersona]);

  const handleSelectPersona = (persona) => {
    onSelectPersona(persona);
    setCurrentPersona(persona);
    if (persona._id) {
      fetchPersonaAvatar(persona._id);
    }
    setIsOpen(false);
  };

  const handleEditPersona = (persona) => {
    console.log('PersonaSelector: handleEditPersona called with persona:', persona);
    setEditingPersona(persona);
  };

  const handleSaveEdit = async (updatedPersona) => {
    console.log('PersonaSelector: handleSaveEdit called with updatedPersona:', updatedPersona);
    try {
      if (currentPersona && currentPersona._id === updatedPersona._id) {
        setCurrentPersona(updatedPersona);
        if (updatedPersona._id) {
          await fetchPersonaAvatar(updatedPersona._id);
        }
      }
      setEditingPersona(null);
    } catch (error) {
      console.error('Error saving persona:', error);
    }
  };

  const Modal = ({ children }) => {
    return createPortal(
      <div 
        className="fixed inset-0 z-[9999] overflow-auto bg-black/50 flex items-center justify-center"
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(false);
        }}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="relative"
        >
          {children}
        </div>
      </div>,
      document.body
    );
  };

  return (
    <div className="relative">
      <div className="flex items-center flex-1">
        <div className={`w-16 h-16 rounded-lg mr-4 flex items-center justify-center overflow-hidden
          ${isDarkMode 
            ? 'bg-[#1a1a1a] border-[#333333]' 
            : 'bg-white border-gray-300'} border`}>
          {isLoading ? (
            <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-900"></div>
          ) : currentPersona && personaAvatarUrl ? (
            <img 
              src={personaAvatarUrl} 
              alt={currentPersona.name}
              className="w-full h-full object-cover" 
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/default-avatar.png';
              }}
            />
          ) : (
            <UserIcon className="w-8 h-8 text-gray-400" />
          )}
        </div>
        <div className="flex-1">
          <h3 className={`text-sm font-medium mb-1 ${isDarkMode ? 'text-slate-200' : 'text-gray-800'}`}>
            Associated Profile
          </h3>
          <button
            onClick={() => setIsOpen(true)}
            className={`cursor-pointer inline-flex items-center px-3 py-1.5 rounded-md text-sm w-[120px] justify-center
              ${isDarkMode 
                ? 'bg-[#1a1a1a] hover:bg-[#202020] text-slate-200 border border-[#333333]' 
                : 'bg-white hover:bg-gray-50 text-gray-700 border border-gray-300'}`}
          >
            <UserIcon className="w-4 h-4 mr-2" />
            {currentPersona ? 'Change' : 'Select'}
          </button>
        </div>
      </div>
      {isOpen && (
        <Modal>
          <div
            ref={modalRef}
            className={`rounded-lg p-6 w-11/12 max-w-3xl max-h-[90vh] overflow-auto relative ${
              isDarkMode ? 'bg-[#151515]' : 'bg-white'
            }`}
          >
            <div 
              className={`absolute top-4 right-4 p-1 rounded-full transition-colors duration-200 cursor-pointer z-[9999]
                ${isDarkMode 
                  ? 'text-slate-400 hover:bg-[#1a1a1a]' 
                  : 'text-gray-500 hover:bg-gray-100'}`}
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(false);
              }}
            >
              <XMarkIcon className="w-6 h-6" />
            </div>
            <h2 
              id="modal-headline" 
              className={`text-2xl font-bold mb-4 ${
                isDarkMode ? 'text-slate-200' : 'text-gray-900'
              }`}
            >
              Select a Profile
            </h2>
            <PersonasPage 
              isPopup={true} 
              onSelectPersona={handleSelectPersona} 
              onEditPersona={handleEditPersona}
              isDarkMode={isDarkMode}
              isInChatInterface={true}
            />
          </div>
        </Modal>
      )}

      {editingPersona && (
        <PersonaCreationWizardForChatInterface
          personaId={editingPersona._id}
          onClose={() => setEditingPersona(null)}
          onSave={handleSaveEdit}
        />
      )}
    </div>
  );
};

export default PersonaSelector;
