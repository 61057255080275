import React, { useState, useEffect, useCallback } from 'react';
import { fetchAvatarUrl, refreshPersonaAvatarUrl } from '../../utils/avatarUtils';
import { UserIcon } from '@heroicons/react/24/outline';

const SimplePersonaAvatar = ({ persona, size = 'small', withGradientBorder = false }) => {
  const [avatarUrl, setAvatarUrl] = useState(persona?.avatarUrl || '');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchPersonaAvatar = useCallback(async (personaId) => {
    setIsLoading(true);
    setError(null);
    try {
      const url = await fetchAvatarUrl(personaId, 'persona');
      setAvatarUrl(url || '/default-avatar.png');
    } catch (error) {
      console.error('Error fetching persona avatar:', error);
      setError('Failed to load avatar');
      setAvatarUrl('/default-avatar.png');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (persona && persona._id) {
      fetchPersonaAvatar(persona._id);
    }
  }, [persona, fetchPersonaAvatar]);

  useEffect(() => {
    const refreshAvatarPeriodically = async () => {
      if (persona && persona._id) {
        const newAvatarUrl = await refreshPersonaAvatarUrl(persona._id);
        if (newAvatarUrl) {
          setAvatarUrl(newAvatarUrl);
        }
      }
    };

    const intervalId = setInterval(refreshAvatarPeriodically, 30 * 60 * 1000); // Refresh every 30 minutes

    return () => clearInterval(intervalId);
  }, [persona]);

  const sizeClasses = {
    small: 'w-6 h-6',
    medium: 'w-12 h-12',
    large: 'w-16 h-16'
  };

  const gradientBorderClass = withGradientBorder
    ? 'p-0.5 rounded-full bg-gradient-to-r from-black-500 via-yellow-500 via-green-500 via-blue-500 via-purple-500'
    : '';

  return (
    <div className={`${gradientBorderClass}`}>
      <div className={`${sizeClasses[size]} shadow-md rounded-full overflow-hidden bg-gradient-to-r from-indigo-200 via-purple-300 to-pink-200 flex items-center justify-center`}>
        {isLoading ? (
          <div className="animate-spin rounded-lg h-4 w-4 border-b-2 border-gray-900"></div>
        ) : avatarUrl ? (
          <img 
            src={avatarUrl} 
            alt={persona?.name || 'Persona'}
            className="w-full h-full object-cover" 
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = '/default-avatar.png';
            }}
          />
        ) : (
          <UserIcon className="w-4 h-4 text-gray-400" />
        )}
      </div>
    </div>
  );
};

export default SimplePersonaAvatar;
