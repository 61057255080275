import React from 'react';
import { motion } from 'framer-motion';

const Spinner = () => (
  <motion.div
    animate={{ rotate: 360 }}
    transition={{ 
      duration: 1,
      repeat: Infinity,
      ease: "linear"
    }}
  >
    <svg className="h-4 w-4" viewBox="0 0 24 24">
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
        fill="none"
      />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  </motion.div>
);

const StyledActionButton = ({ 
  onClick, 
  icon: Icon, 
  text, 
  loadingText = "Loading...",
  isDarkMode, 
  disabled, 
  isLoading,
  className = ''
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`
        flex items-center justify-center px-3 py-1.5 rounded-lg
        transition-all duration-200 relative
        ${isDarkMode 
          ? 'bg-gray-900 hover:bg-gray-800 disabled:bg-gray-700' 
          : 'bg-gray-900 hover:bg-gray-800 disabled:bg-gray-200'
        }
        ${disabled 
          ? isDarkMode 
            ? 'text-gray-500 cursor-not-allowed' 
            : 'text-gray-400 cursor-not-allowed'
          : 'text-white'
        }
        ${className}
      `}
    >
      {isLoading ? (
        <div className="flex items-center space-x-2">
          <Spinner />
          <span className="text-sm font-medium">{loadingText}</span>
        </div>
      ) : (
        <div className="flex items-center space-x-2">
          <Icon className="w-4 h-4" />
          <span className="text-sm font-medium">{text}</span>
        </div>
      )}
    </button>
  );
};

export default StyledActionButton; 