import React, { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { useTheme } from '../../../context/ThemeContext';
import useScreenSize from '../../../hooks/useScreenSize';
import { 
  MicrophoneIcon,
  DocumentMagnifyingGlassIcon,
  SparklesIcon,
  ChartBarIcon,
  PlayIcon,
  CheckIcon
} from '@heroicons/react/24/outline';

const HowItWorksSection = () => {
  const { isDarkMode } = useTheme();
  const { isMobile } = useScreenSize();
  const [activeStep, setActiveStep] = useState('record');
  const [hoveredStep, setHoveredStep] = useState(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const videoRef = useRef(null);
  
  const steps = [
    {
      id: 'record',
      title: "Record Your Ideas",
      fullTitle: "Record Your Ideas Naturally",
      description: "Capture your thoughts using voice notes or text. No need to worry about formatting or structure.",
      shortDescription: "Voice or text capture",
      icon: MicrophoneIcon,
      videoUrl: "/videos/record-ideas-demo.mp4",
      features: [
        "Voice-to-text conversion",
        "Quick note capture",
        "Mobile-friendly recording"
      ]
    },
    {
      id: 'context',
      title: "Build Context",
      fullTitle: "Build Your Knowledge Bank",
      description: "Import your existing content, bookmarks, and resources to create your personal knowledge bank.",
      shortDescription: "Import existing content",
      icon: DocumentMagnifyingGlassIcon,
      videoUrl: "/videos/build-context-demo.mp4",
      features: [
        "Content import tools",
        "Resource organization",
        "Smart categorization"
      ]
    },
    {
      id: 'generate',
      title: "Generate Content",
      fullTitle: "AI-Powered Content Creation",
      description: "Let AI transform your ideas into engaging LinkedIn posts while maintaining your authentic voice.",
      shortDescription: "AI creates your posts",
      icon: SparklesIcon,
      videoUrl: "/videos/generate-content-demo.mp4",
      features: [
        "Voice matching",
        "Content optimization",
        "Multiple post variations"
      ]
    },
    {
      id: 'optimize',
      title: "Review & Optimize",
      fullTitle: "Optimize for Impact",
      description: "Get AI-powered suggestions to improve engagement and track your content's performance.",
      shortDescription: "Boost engagement",
      icon: ChartBarIcon,
      videoUrl: "/videos/optimize-content-demo.mp4",
      features: [
        "Engagement analytics",
        "Performance tracking",
        "A/B testing"
      ]
    }
  ];

  const handlePlayVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsVideoPlaying(true);
    }
  };

  const handleVideoEnd = () => {
    setIsVideoPlaying(false);
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
    }
  };

  const currentStep = steps.find(s => s.id === activeStep);

  const MobileHowItWorks = () => (
    <div id="how-it-works" className={`py-16 relative overflow-hidden ${
      isDarkMode ? 'bg-[#121212]/0' : 'bg-white/0'
    }`}>
      <div className="px-4">
        {/* How It Works Tag */}
        <div className="mb-6">
          <span className={`inline-block px-3 py-1.5 text-xs font-medium uppercase tracking-wider rounded-full ${
            isDarkMode 
              ? 'bg-slate-800/80 text-slate-300 border border-slate-700/50' 
              : 'bg-white text-slate-600 border border-slate-200'
          }`}>
            How It Works
          </span>
        </div>

        <h2 className={`text-2xl font-semibold mb-8 ${
          isDarkMode ? 'text-white' : 'text-slate-900'
        }`}>
          Simple Process,{' '}
          <span className={`
            ${isDarkMode 
              ? 'text-white/60' 
              : 'text-slate-900/60'
            }
          `}>
            Powerful Results
          </span>
        </h2>

        {/* Steps */}
        <div className="relative mt-12">
          {/* Connection Line */}
          <div className={`absolute left-6 top-0 bottom-0 w-0.5 
            ${isDarkMode ? 'bg-gradient-to-b from-blue-500/50 via-purple-500/50 to-transparent' : 'bg-gradient-to-b from-blue-200 via-purple-200 to-transparent'}
          `} />

          <div className="space-y-12">
            {steps.map((step, index) => (
              <div key={step.id} className="relative pl-16">
                {/* Step Number */}
                <div className={`
                  absolute left-0 flex items-center justify-center
                  w-12 h-12 rounded-full mb-6
                  text-lg font-semibold
                  ${isDarkMode 
                    ? 'bg-blue-900/20 text-blue-400 border border-blue-500/20' 
                    : 'bg-blue-50 text-blue-600 border border-blue-100'
                  }
                `}>
                  {(index + 1).toString().padStart(2, '0')}
                </div>

                <div className={`p-6 rounded-xl ${
                  isDarkMode 
                    ? 'bg-[#1a1a1a]/90 border border-white/10' 
                    : 'bg-white/90 shadow-lg backdrop-blur-sm'
                }`}>
                  <h3 className={`text-xl font-semibold mb-4 ${
                    isDarkMode ? 'text-white' : 'text-slate-900'
                  }`}>
                    {step.fullTitle}
                  </h3>

                  <p className={`text-sm mb-6 ${
                    isDarkMode ? 'text-slate-400' : 'text-slate-600'
                  }`}>
                    {step.description}
                  </p>

                  <div className="space-y-2 mb-6">
                    {step.features.map((feature, featureIndex) => (
                      <div key={featureIndex} className="flex items-center gap-2">
                        <CheckIcon className={`w-4 h-4 ${
                          isDarkMode ? 'text-blue-400' : 'text-blue-600'
                        }`} />
                        <span className={`text-sm ${
                          isDarkMode ? 'text-slate-300' : 'text-slate-700'
                        }`}>
                          {feature}
                        </span>
                      </div>
                    ))}
                  </div>

                  {/* Video Preview */}
                  <div className="relative rounded-lg overflow-hidden">
                    <video
                      ref={step.id === activeStep ? videoRef : null}
                      className="w-full h-auto"
                      src={step.videoUrl}
                      onEnded={handleVideoEnd}
                      playsInline
                    />
                    {!isVideoPlaying && (
                      <div className="absolute inset-0 flex items-center justify-center">
                        <button
                          onClick={handlePlayVideo}
                          className={`
                            group relative flex items-center gap-2
                            px-4 py-2 rounded-full
                            text-sm font-medium
                            ${isDarkMode
                              ? 'bg-white/10 hover:bg-white/20 text-white'
                              : 'bg-black/10 hover:bg-black/20 text-black'
                            }
                          `}
                        >
                          <PlayIcon className="w-4 h-4" />
                          <span>Watch Demo</span>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  const DesktopHowItWorks = () => (
    <div id="how-it-works" className={`min-h-screen py-24 relative overflow-hidden flex items-center ${
      isDarkMode ? 'bg-[#121212]/0' : 'bg-white/0'
    }`}>
      {/* Background Gradient Elements */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <motion.div 
          animate={{ 
            scale: [1, 1.2, 1],
            rotate: [0, 90, 0] 
          }}
          transition={{ 
            duration: 20,
            repeat: Infinity,
            ease: "linear"
          }}
          className={`absolute -top-1/4 -right-1/4 w-1/2 h-1/2 bg-gradient-to-br ${
            isDarkMode 
              ? 'from-purple-500/5 via-blue-500/5 to-transparent' 
              : 'from-purple-100/30 via-blue-100/30 to-transparent'
          } blur-3xl rounded-full`}
        />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative w-full">
        {/* Section Header - Updated with flex layout */}
        <div className="flex items-center justify-between mb-12">
          <h2 className={`
            text-3xl sm:text-4xl font-semibold tracking-tight leading-tight sm:leading-normal
            ${isDarkMode ? 'text-white' : 'text-slate-900'}
          `}>
            Simple Process,{' '}
            <span className={`
              ${isDarkMode 
                ? 'text-white/60' 
                : 'text-slate-900/60'
              }
            `}>
              Powerful Results
            </span>
          </h2>
          
          <span className={`inline-block px-3 py-1.5 text-xs font-medium uppercase tracking-wider rounded-full ${
            isDarkMode 
              ? 'bg-slate-800/80 text-slate-300 border border-slate-700/50' 
              : 'bg-white text-slate-600 border border-slate-200'
          }`}>
            How It Works
          </span>
        </div>

        {/* Steps Cards Grid */}
        <div className="grid grid-cols-4 gap-3 mb-8">
          {steps.map((step, index) => (
            <motion.button
              key={step.id}
              onClick={() => setActiveStep(step.id)}
              onHoverStart={() => setHoveredStep(step.id)}
              onHoverEnd={() => setHoveredStep(null)}
              className={`
                relative group px-4 py-3 rounded-xl
                transition-all duration-200
                ${activeStep === step.id
                  ? isDarkMode
                    ? 'bg-[#1a1a1a] shadow-lg shadow-blue-500/10'
                    : 'bg-white shadow-lg shadow-blue-500/10'
                  : isDarkMode
                    ? 'bg-[#1a1a1a] hover:bg-[#1f1f1f]'
                    : 'bg-white hover:bg-slate-50'
                }
              `}
            >
              {/* Animated Border */}
              <div className={`
                absolute inset-0 rounded-xl
                ${activeStep === step.id ? 'border-2' : 'border'}
                ${isDarkMode
                  ? activeStep === step.id
                    ? 'border-blue-500/50'
                    : 'border-white/10'
                  : activeStep === step.id
                    ? 'border-blue-500/50'
                    : 'border-slate-200'
                }
              `} />

              {/* Animated Border Gradient */}
              {activeStep === step.id && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className={`
                    absolute inset-0 rounded-xl
                    before:absolute before:inset-0
                    before:rounded-xl before:p-[2px]
                    before:bg-gradient-to-r
                    ${isDarkMode
                      ? 'before:from-blue-500/50 before:via-purple-500/50 before:to-blue-500/50'
                      : 'before:from-blue-500/30 before:via-purple-500/30 before:to-blue-500/30'
                    }
                    before:animate-border-flow
                    after:absolute after:inset-[2px]
                    after:rounded-[10px]
                    ${isDarkMode
                      ? 'after:bg-[#1a1a1a]'
                      : 'after:bg-white'
                    }
                  `}
                />
              )}

              {/* Progress Line - Updated */}
              {index < steps.length - 1 && (
                <div className={`
                  absolute top-1/2 -right-3 w-3 h-0.5
                  transition-colors duration-300
                  ${activeStep === step.id || activeStep === steps[index + 1]?.id
                    ? isDarkMode ? 'bg-blue-500/50' : 'bg-blue-400/50'
                    : isDarkMode ? 'bg-white/10' : 'bg-slate-200'
                  }
                `} />
              )}

              <div className="relative flex items-center gap-3">
                {/* Step Number - Updated */}
                <div className={`
                  w-6 h-6 rounded-full shrink-0
                  flex items-center justify-center
                  text-xs font-semibold
                  transition-all duration-300
                  ${activeStep === step.id
                    ? isDarkMode
                      ? 'bg-blue-500 text-white'
                      : 'bg-blue-500 text-white'
                    : isDarkMode
                      ? 'bg-white/10 text-white/70'
                      : 'bg-slate-100 text-slate-600'
                  }
                `}>
                  {index + 1}
                </div>

                <div className="flex flex-col items-start min-w-0">
                  {/* Step Title Row */}
                  <div className="flex items-center gap-2 mb-1">
                    <step.icon className={`w-4 h-4 shrink-0 transition-colors duration-300 ${
                      activeStep === step.id
                        ? isDarkMode ? 'text-blue-400' : 'text-blue-600'
                        : isDarkMode ? 'text-white/70' : 'text-slate-600'
                    }`} />
                    <h4 className={`font-medium text-sm transition-colors duration-300 ${
                      activeStep === step.id
                        ? isDarkMode ? 'text-white' : 'text-slate-900'
                        : isDarkMode ? 'text-white/70' : 'text-slate-600'
                    }`}>
                      {step.title}
                    </h4>
                  </div>

                  {/* Description */}
                  <p className={`
                    text-[11px] font-medium tracking-wide uppercase
                    transition-colors duration-300
                    ${activeStep === step.id
                      ? isDarkMode ? 'text-blue-400' : 'text-blue-600'
                      : isDarkMode ? 'text-white/40' : 'text-slate-400'
                    }
                  `}>
                    {step.shortDescription}
                  </p>
                </div>
              </div>
            </motion.button>
          ))}
        </div>

        {/* Main Content Area */}
        <div className="relative">
          {/* Video Container */}
          <motion.div 
            className={`
              relative rounded-xl overflow-hidden
              ${isDarkMode 
                ? 'bg-[#1a1a1a] border border-white/10' 
                : 'bg-white border border-black/5'
              }
            `}
          >
            <div className="aspect-[16/9] relative">
              {/* Background Pattern */}
              <div className={`
                absolute inset-0 
                ${isDarkMode
                  ? 'bg-gradient-to-br from-[#121212] to-[#1a1a1a]' 
                  : 'bg-gradient-to-br from-slate-50 to-white'
                }
              `}>
                <div className={`
                  absolute inset-0 
                  ${isDarkMode
                    ? 'bg-[url("data:image/svg+xml,%3Csvg width=\'40\' height=\'40\' viewBox=\'0 0 40 40\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M0 0h40v40H0V0zm1 1h38v38H1V1z\' fill=\'%23333\' fill-opacity=\'.1\' fill-rule=\'evenodd\'/%3E%3C/svg%3E")]'
                    : 'bg-[url("data:image/svg+xml,%3Csvg width=\'40\' height=\'40\' viewBox=\'0 0 40 40\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M0 0h40v40H0V0zm1 1h38v38H1V1z\' fill=\'%23000\' fill-opacity=\'.05\' fill-rule=\'evenodd\'/%3E%3C/svg%3E")]'
                  }
                  opacity-30 bg-[length:20px_20px] sm:bg-[length:40px_40px]
                  ${isVideoPlaying ? 'opacity-0' : 'opacity-30'}
                `} />
              </div>

              {/* Video */}
              <video
                ref={videoRef}
                className="w-full h-full object-cover relative z-10"
                src={currentStep?.videoUrl}
                onEnded={handleVideoEnd}
                playsInline
              />

              {/* Simple Overlay with Play Button */}
              {!isVideoPlaying && (
                <div className="absolute inset-0 z-20 flex items-center justify-center">
                  <button
                    onClick={handlePlayVideo}
                    className={`
                      group relative w-16 h-16 rounded-full
                      flex items-center justify-center
                      backdrop-blur-xl backdrop-saturate-150
                      ${isDarkMode
                        ? 'bg-white/10 text-white/90 hover:bg-white/20'
                        : 'bg-black/15 text-black/90 hover:bg-black/25'
                      }
                    `}
                  >
                    <PlayIcon className="h-8 w-8 relative z-10" />
                  </button>
                </div>
              )}
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );

  return isMobile ? <MobileHowItWorks /> : <DesktopHowItWorks />;
};

export default HowItWorksSection; 