import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { XMarkIcon } from '@heroicons/react/24/outline';
import DotCircleIcon from './assets/dot-circle.svg';

const FocusIcon = ({ className }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 24 24" 
    className={className}
  >
    <path 
      fill="currentColor" 
      d="M12 15q-1.237 0-2.119-.881T9 12t.881-2.119T12 9t2.119.881T15 12t-.881 2.119T12 15m0-1q.825 0 1.413-.587T14 12t-.587-1.412T12 10t-1.412.588T10 12t.588 1.413T12 14m-6.378 6q-.697 0-1.16-.462T4 18.378V15h1v3.385q0 .23.192.423t.423.192H9v1zM15 20v-1h3.385q.23 0 .423-.192t.192-.424V15h1v3.378q0 .697-.462 1.16t-1.16.462zM4 9V5.622q0-.697.463-1.16T5.622 4H9v1H5.616q-.231 0-.424.192T5 5.616V9zm15 0V5.616q0-.231-.192-.424T18.384 5H15V4h3.378q.697 0 1.16.463T20 5.622V9z"
    />
  </svg>
);

const FocusModeSidebar = ({ 
  showOverlaySidebar, 
  setShowOverlaySidebar, 
  setIsOverlaySidebarClosing,
  isDarkMode,
  isFocusMode,
  handleFocusModeToggle,
  SidebarContent 
}) => {
  const handleMouseLeave = () => {
    setShowOverlaySidebar(false);
    setIsOverlaySidebarClosing(true);
    setTimeout(() => setIsOverlaySidebarClosing(false), 300);
  };

  const menuVariants = {
    hidden: { 
      opacity: 0, 
      x: "-100%",
      scale: 0.95,
    },
    visible: { 
      opacity: 1, 
      x: 0,
      scale: 1,
      transition: {
        duration: 0.2,
        ease: 'easeOut',
        when: "beforeChildren",
      }
    },
    exit: { 
      opacity: 0, 
      x: "-100%",
      scale: 0.95,
      transition: {
        duration: 0.15,
        ease: 'easeIn',
      }
    }
  };

  return (
    <AnimatePresence>
      {showOverlaySidebar && (
        <motion.div
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={menuVariants}
          className={`fixed top-0 left-0 h-full w-48 z-50 overflow-hidden ${
            isDarkMode 
              ? 'bg-[#121212] border-r border-[#333333] shadow-lg shadow-black/20' 
              : 'bg-white border-r border-slate-200 shadow-lg shadow-slate-200/20'
          }`}
          onMouseLeave={handleMouseLeave}
        >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ 
              opacity: 1, 
              y: 0,
              transition: {
                delay: 0.1,
                duration: 0.2,
              }
            }}
            exit={{ 
              opacity: 0, 
              y: 20,
              transition: {
                duration: 0.15,
              }
            }}
            className="h-full"
          >
            <SidebarContent isOverlay={true} />
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

// Focus Mode Button Component
export const FocusModeButton = ({ isDarkMode, isSidebarCollapsed, isFocusMode, onToggle, isOverlay = false }) => {
  return (
    <motion.button
      onClick={onToggle}
      className={`
        group relative w-full px-3 py-2.5 
        ${isSidebarCollapsed && !isOverlay ? 'flex justify-center' : 'flex items-center justify-between'} 
        transition-all duration-200 ease-out
        ${isDarkMode 
          ? 'hover:bg-gradient-to-r hover:from-blue-500/5 hover:to-purple-500/5 hover:ring-1 hover:ring-blue-500/20' 
          : 'hover:bg-blue-50/80'
        }
        ${isFocusMode 
          ? `${isDarkMode 
              ? 'bg-gradient-to-r from-blue-500/10 to-purple-500/10 ring-1 ring-blue-500/20' 
              : 'bg-blue-50 ring-1 ring-blue-100'
            }`
          : ''
        }
      `}
      whileHover={{ scale: 0.995 }}
      whileTap={{ scale: 0.97 }}
      initial={false}
    >
      <div className="flex items-center">
        <div className={`
          relative flex items-center justify-center
          w-6 h-6 
          transition-all duration-200 ease-out
          ${isFocusMode
            ? `${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`
            : `${isDarkMode 
                ? 'text-gray-300 group-hover:text-blue-400' 
                : 'text-slate-600 group-hover:text-blue-600'
              }`
          }
        `}>
          <FocusIcon 
            className={`
              w-[18px] h-[18px] 
              transition-all duration-200
              ${isFocusMode ? 'scale-110' : 'scale-100 group-hover:scale-105'}
            `}
          />
          {isFocusMode && (
            <motion.div
              className={`
                absolute inset-0
                ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}
                opacity-25
              `}
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ 
                scale: [1, 1.4, 1],
                opacity: [0.5, 0, 0]
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                repeatType: "loop"
              }}
            >
              <FocusIcon className="w-6 h-6" />
            </motion.div>
          )}
        </div>
        {(!isSidebarCollapsed || isOverlay) && (
          <span className={`
            ml-3 text-xs font-medium tracking-wide
            transition-all duration-200
            ${isDarkMode 
              ? isFocusMode 
                ? 'text-blue-400' 
                : 'text-gray-300 group-hover:text-blue-400'
              : isFocusMode 
                ? 'text-blue-600' 
                : 'text-slate-600 group-hover:text-blue-600'
            }
          `}>
            Focus Mode
          </span>
        )}
      </div>
      {(!isSidebarCollapsed || isOverlay) && (
        <div className={`
          flex items-center justify-center
          w-8 h-4 rounded-full
          transition-all duration-200 ease-out
          ${isFocusMode
            ? isDarkMode 
              ? 'bg-gradient-to-r from-blue-500/20 to-purple-500/20' 
              : 'bg-blue-100'
            : isDarkMode 
              ? 'bg-[#1E1E1E] group-hover:bg-blue-500/10' 
              : 'bg-slate-200 group-hover:bg-blue-100'
          }
        `}>
          <motion.div
            className={`
              w-3 h-3 rounded-full
              transition-colors duration-200
              ${isFocusMode
                ? `${isDarkMode ? 'bg-blue-400' : 'bg-blue-600'}`
                : `${isDarkMode 
                    ? 'bg-gray-300 group-hover:bg-blue-400' 
                    : 'bg-slate-400 group-hover:bg-blue-600'
                  }`
              }
            `}
            initial={false}
            animate={{
              x: isFocusMode ? 6 : -6,
            }}
            transition={{
              type: "spring",
              stiffness: 500,
              damping: 30
            }}
          />
        </div>
      )}
    </motion.button>
  );
};

export default FocusModeSidebar; 