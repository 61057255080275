import { useState, useEffect } from 'react';

const useSidebarState = () => {
  const [expandedItems, setExpandedItems] = useState(() => {
    const saved = localStorage.getItem('sidebarExpandedItems');
    return saved ? JSON.parse(saved) : {};
  });

  useEffect(() => {
    localStorage.setItem('sidebarExpandedItems', JSON.stringify(expandedItems));
  }, [expandedItems]);

  const toggleExpand = (itemName) => {
    setExpandedItems(prev => ({
      ...prev,
      [itemName]: !prev[itemName]
    }));
  };

  const setExpanded = (itemName, isExpanded) => {
    setExpandedItems(prev => ({
      ...prev,
      [itemName]: isExpanded
    }));
  };

  const isExpanded = (itemName) => !!expandedItems[itemName];

  return { expandedItems, toggleExpand, isExpanded, setExpanded };
};

export default useSidebarState;