import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { useTheme } from '../context/ThemeContext';
import { PaperAirplaneIcon, MicrophoneIcon, DocumentTextIcon, LightBulbIcon, ClipboardDocumentListIcon, SparklesIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import Header from './Header';
import { createNewChat } from '../api';
import PersonaDropdown from './personas/PersonaDropdown';
import TargetAudienceDropdown from './targetAudiences/TargetAudienceDropdown';
import { useChat } from '../context/ChatContext';
import ChatInput from './ChatInput';

function Home() {
  const { user } = useContext(AuthContext);
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const [input, setInput] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const { selectedPersona, setSelectedPersona } = useChat();
  const [selectedTargetAudience, setSelectedTargetAudience] = useState(null);
  const [isLoadingAvatar, setIsLoadingAvatar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [promptSuggestions] = useState([
    {
      icon: '✨',
      text: 'Create a thought leadership post about [topic]',
      description: 'Position yourself as an industry expert'
    },
    {
      icon: '🎯',
      text: 'Generate content that resonates with my target audience',
      description: 'Craft messages that connect and engage'
    },
    {
      icon: '💡',
      text: 'Transform this idea into engaging content: [idea]',
      description: 'Turn your concepts into compelling stories'
    },
    {
      icon: '📈',
      text: 'Develop a content strategy for my personal brand',
      description: 'Build a consistent online presence'
    }
  ]);
  
  const placeholders = [
    "What would you like to create today?",
    "Share your expertise with the world...",
    "Ready to engage your audience?",
    "Transform your ideas into content...",
    "Build your thought leadership...",
    "Create content that resonates...",
    "Craft your unique story...",
    "Elevate your personal brand...",
    "Connect with your audience...",
    "Share your professional journey..."
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      if (!input) {
        setCurrentIndex(current => (current + 1) % placeholders.length);
      }
    }, 4000);

    return () => clearInterval(interval);
  }, [input, placeholders.length]);

  const handleSelectPersona = async (persona) => {
    setIsLoadingAvatar(true);
    try {
      setSelectedPersona(persona);
      if (persona) {
        localStorage.setItem('selectedPersona', JSON.stringify(persona));
      } else {
        localStorage.removeItem('selectedPersona');
      }
    } catch (error) {
      console.error('Error selecting persona:', error);
    } finally {
      setIsLoadingAvatar(false);
    }
  };

  const handleSelectTargetAudience = (audience) => {
    setSelectedTargetAudience(audience);
    if (audience) {
      localStorage.setItem('selectedTargetAudience', JSON.stringify(audience));
    } else {
      localStorage.removeItem('selectedTargetAudience');
    }
  };

  useEffect(() => {
    const savedAudience = localStorage.getItem('selectedTargetAudience');
    if (savedAudience) {
      setSelectedTargetAudience(JSON.parse(savedAudience));
    }
  }, []);

  const handleSend = async () => {
    if (input.trim()) {
      try {
        setIsLoading(true);
        const newChat = await createNewChat('Untitled Chat');
        
        sessionStorage.setItem('pendingMessage', input.trim());
        sessionStorage.setItem('isNewChat', 'true');

        sessionStorage.setItem('newChatData', JSON.stringify({
          chatId: newChat.chatId,
          title: 'Untitled Chat',
          messages: [],
          isNew: true,
          pendingMessage: input.trim(),
          selectedPersona,
          selectedTargetAudience,
          forceNew: true
        }));

        localStorage.removeItem('currentChat');
        localStorage.removeItem('chatState');

        navigate('/chat');
      } catch (error) {
        console.error('Error creating new chat:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setInput(suggestion);
    const textarea = document.querySelector('textarea');
    if (textarea) {
      textarea.focus();
    }
  };

  const adjustTextareaHeight = (element) => {
    if (!element) return;
    
    // Reset height to allow shrinking
    element.style.height = 'auto';
    
    // Calculate required height (with a max of 200px)
    const newHeight = Math.min(element.scrollHeight, 200);
    
    // Set the new height
    element.style.height = `${newHeight}px`;
    
    // Adjust send button position based on textarea height
    const sendButton = document.querySelector('.send-button');
    if (sendButton) {
      if (newHeight > 44) {
        setSendButtonExpandedPosition(sendButton);
      } else {
        setSendButtonDefaultPosition(sendButton);
      }
    }
  };

  const setSendButtonDefaultPosition = (button) => {
    // Remove expanded position classes
    button.classList.remove('right-3', 'bottom-3');
    // Add default position classes
    button.classList.add('right-3', 'top-[48%]', '-translate-y-1/2');
  };

  const setSendButtonExpandedPosition = (button) => {
    // Remove default position classes
    button.classList.remove('top-[48%]', '-translate-y-1/2');
    // Add expanded position classes
    button.classList.add('right-3', 'bottom-3');
  };

  // Add useEffect to handle initial textarea setup
  useEffect(() => {
    const textarea = document.querySelector('.chat-input');
    if (textarea) {
      adjustTextareaHeight(textarea);
    }
  }, [input]);

  const styles = `
    @keyframes slideIn {
      0% {
        opacity: 0;
        transform: translateY(10px);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .hide-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;     /* Firefox */
    }
    
    .hide-scrollbar::-webkit-scrollbar {
      display: none;             /* Chrome, Safari and Opera */
    }
  `;

  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.textContent = styles;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  return (
    <div className={`h-full flex flex-col ${isDarkMode ? 'bg-[#121212] text-gray-100' : 'bg-gray-50 text-gray-800'}`}>
      <main className="flex-1 flex flex-col items-center justify-center px-4 sm:px-6">
        {/* Header Section */}
        <div className="mb-8 sm:mb-12 w-full">
          <Header 
            firstName={user?.firstName} 
            lastName={user?.lastName} 
            isNewUser={user?.isNewUser}
          />
        </div>

        {/* Examples Grid Section */}
        <div className="w-full max-w-2xl mb-4">
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-2 px-0">
            {promptSuggestions.map((suggestion, index) => (
              <button
                key={index}
                onClick={() => handleSuggestionClick(suggestion.text)}
                className={`
                  p-2.5 rounded-xl text-left
                  transition-all duration-200
                  ${isDarkMode 
                    ? 'bg-[#1E1E1E] hover:bg-[#2A2A2A] ring-1 ring-[#333333]' 
                    : 'bg-white hover:bg-slate-50 ring-1 ring-slate-200'
                  }
                  hover:shadow-sm
                `}
              >
                <span className={`
                  text-[13px] leading-[18px] font-normal line-clamp-3
                  ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}
                `}>
                  {suggestion.text}
                </span>
              </button>
            ))}
          </div>
        </div>

        {/* Chat Interface Section */}
        <div className="w-full max-w-2xl relative mb-4 sm:mb-6">
          <ChatInput 
            input={input} 
            setInput={setInput} 
            handleSend={handleSend} 
            isLoading={isLoading} 
            isDarkMode={isDarkMode} 
          />
        </div>

        {/* Dropdowns Section - Centered */}
        <div className="w-full max-w-2xl flex items-center justify-center mb-12 sm:mb-16 relative z-[9999]">
          <div className="flex items-center space-x-1">
            <div className="flex-shrink-0">
              <PersonaDropdown
                onSelectPersona={handleSelectPersona}
                selectedPersona={selectedPersona}
                isLoadingAvatar={isLoadingAvatar}
              />
            </div>
            <div className="flex-shrink-0">
              <TargetAudienceDropdown
                onSelectAudience={handleSelectTargetAudience}
                selectedAudience={selectedTargetAudience}
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Home;
