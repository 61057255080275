import React from 'react';
import { useTheme } from '../../context/ThemeContext';

const ResetIcon = ({ className }) => {
  const { isDarkMode } = useTheme();
  
  return (
    <svg 
      className={`${className} ${isDarkMode ? 'fill-gray-400' : 'fill-slate-500'}`} 
      viewBox="0 0 100 100" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        fillRule="evenodd"
        d="M89.648 76.17c10.486-10.509 10.467-27.528-.042-38.014-5.029-5.017-11.84-7.84-18.943-7.851H15.027a1.429 1.429 0 01-1.016-2.426l7.276-7.372a2.682 2.682 0 00-.023-3.792l-.008-.007a2.682 2.682 0 00-3.794.023L3.273 31.074a2.682 2.682 0 000 3.77l14.19 14.372a2.682 2.682 0 003.793.024l.004-.004a2.682 2.682 0 00.024-3.794l-7.201-7.288a1.429 1.429 0 01.94-2.508h55.64c11.882.12 21.417 9.85 21.297 21.732-.118 11.713-9.584 21.179-21.297 21.297H31.323a2.682 2.682 0 100 5.365h39.321c7.127-.003 13.959-2.845 18.986-7.897z"
      />
    </svg>
  );
};

export default ResetIcon;
