import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const ProtectedRoute = ({ children }) => {
  const { user, refreshUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    console.log('🛡️ Protected route check - Current user:', user);
    if (!user) {
      const accessToken = localStorage.getItem('accessToken');
      const storedUser = localStorage.getItem('user');
      console.log('🛡️ No user in context, checking localStorage:', { accessToken: !!accessToken, storedUser: !!storedUser });

      if (!accessToken || !storedUser) {
        console.log('❌ No valid auth data found, redirecting to login');
        navigate('/login');
      } else {
        console.log('🔄 Found stored user data, refreshing user state');
        refreshUser();
      }
    }
  }, [user, navigate, refreshUser]);

  if (!user) {
    console.log('⏳ Loading protected route...');
    return <div>Loading...</div>;
  }

  return children;
};

export default ProtectedRoute; 