import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const ProcessingStatus = ({ status, isDarkMode }) => {
  const loadingCircles = Array(3).fill(null);

  return (
    <div className={`w-full py-2 ${isDarkMode ? 'bg-[#121212]' : 'bg-white'}`}>
      <div className="max-w-3xl mx-auto">
        <AnimatePresence mode="wait">
          <motion.div
            key={status}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className={`
              flex items-center justify-center space-x-3
              ${isDarkMode ? 'text-slate-200' : 'text-gray-800'}
            `}
          >
            <div className="flex space-x-1">
              {loadingCircles.map((_, index) => (
                <motion.div
                  key={index}
                  className={`w-1.5 h-1.5 rounded-full ${
                    isDarkMode ? 'bg-emerald-500' : 'bg-green-500'
                  }`}
                  animate={{
                    scale: [1, 1.3, 1],
                    opacity: [1, 0.7, 1],
                  }}
                  transition={{
                    duration: 0.6,
                    repeat: Infinity,
                    delay: index * 0.15,
                  }}
                />
              ))}
            </div>
            <motion.span 
              className={`text-sm font-medium ${
                isDarkMode ? 'text-emerald-500' : 'text-green-500'
              }`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.2 }}
            >
              {status}
            </motion.span>
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default ProcessingStatus;
