import React, { useState, useEffect, useRef, useCallback } from 'react';
import { motion } from 'framer-motion';
import { useTheme } from '../../../context/ThemeContext';
import useScreenSize from '../../../hooks/useScreenSize';
import { 
  DocumentTextIcon,
  LightBulbIcon,
  SparklesIcon,
  PlayIcon
} from '@heroicons/react/24/outline';

const FeaturesSection = () => {
  const { isDarkMode } = useTheme();
  const { isMobile } = useScreenSize();
  const [activeTab, setActiveTab] = useState('capture');
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const videoRef = useRef(null);
  
  const features = [
    {
      id: 'capture',
      title: "Build context",
      fullTitle: "Build your context bank",
      videoUrl: "/videos/capture-notes-demo.mp4",
      icon: DocumentTextIcon,
      description: "Import your existing content and resources to create a rich knowledge base that powers your AI content generation."
    },
    {
      id: 'brainstorm',
      title: "Ideate with AI",
      fullTitle: "Brainstorm ideas",
      videoUrl: "/videos/brainstorm-ideas-demo.mp4",
      icon: LightBulbIcon,
      description: "Let AI help you generate creative content ideas based on your expertise and audience interests."
    },
    {
      id: 'brands',
      title: "Brand voice",
      fullTitle: "Build your brand voice",
      videoUrl: "/videos/brands-demo.mp4",
      icon: SparklesIcon,
      description: "Maintain consistency in your communication with AI that learns and matches your unique writing style."
    }
  ];

  const handlePlayVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsVideoPlaying(true);
    }
  };

  const handleVideoEnd = () => {
    setIsVideoPlaying(false);
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
    }
  };

  const currentFeature = features.find(f => f.id === activeTab);

  const MobileFeatures = () => (
    <div 
      id="features-section"
      className={`py-16 relative overflow-hidden ${
        isDarkMode ? 'bg-[#121212]/0' : 'bg-white/0'
      }`}
    >
      <div className="px-4">
        {/* Features Tag */}
        <div className="mb-6">
          <span className={`inline-block px-3 py-1.5 text-xs font-medium uppercase tracking-wider rounded-full ${
            isDarkMode 
              ? 'bg-slate-800/80 text-slate-300 border border-slate-700/50' 
              : 'bg-white text-slate-600 border border-slate-200'
          }`}>
            Features
          </span>
        </div>

        <h2 className={`text-2xl font-semibold mb-8 ${
          isDarkMode ? 'text-white' : 'text-slate-900'
        }`}>
          Create content that{' '}
          <span className={
            isDarkMode 
              ? 'text-white/60' 
              : 'text-slate-900/60'
          }>
            moves people
          </span>
        </h2>

        <div className="space-y-6">
          {features.map((feature, index) => (
            <div
              key={feature.id}
              className={`p-6 rounded-xl ${
                isDarkMode 
                  ? 'bg-[#1a1a1a] border border-white/10' 
                  : 'bg-white border border-slate-200'
              }`}
            >
              <div className="flex items-start">
                <div className={`p-2 rounded-lg mr-4 ${
                  isDarkMode 
                    ? 'bg-blue-900/20 text-blue-400' 
                    : 'bg-blue-50 text-blue-600'
                }`}>
                  <feature.icon className="h-6 w-6" />
                </div>
                <div>
                  <h3 className={`text-lg font-semibold mb-2 ${
                    isDarkMode ? 'text-slate-200' : 'text-slate-900'
                  }`}>
                    {feature.fullTitle}
                  </h3>
                  <p className={`text-sm ${
                    isDarkMode ? 'text-slate-400' : 'text-slate-600'
                  }`}>
                    {feature.description}
                  </p>
                </div>
              </div>

              {/* Feature Video Preview */}
              <div className="mt-4 relative rounded-lg overflow-hidden">
                <div className={`
                  relative rounded-xl overflow-hidden
                  ${isDarkMode 
                    ? 'bg-[#1a1a1a]' 
                    : 'bg-slate-50'
                  }
                `}>
                  <div className="aspect-video relative">
                    {/* Background Pattern */}
                    <div className={`
                      absolute inset-0 
                      ${isDarkMode
                        ? 'bg-[#1a1a1a]' 
                        : 'bg-slate-50'
                      }
                    `}>
                      <div className={`
                        absolute inset-0 
                        ${isDarkMode
                          ? 'bg-[url("data:image/svg+xml,%3Csvg width=\'60\' height=\'60\' viewBox=\'0 0 60 60\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M54.627 0l.83.828-1.415 1.415L51.8 0h2.827zM5.373 0l-.83.828L5.96 2.243 8.2 0H5.374zM48.97 0l3.657 3.657-1.414 1.414L46.143 0h2.828zM11.03 0L7.372 3.657 8.787 5.07 13.857 0H11.03zm32.284 0L49.8 6.485 48.384 7.9l-7.9-7.9h2.83zM16.686 0L10.2 6.485 11.616 7.9l7.9-7.9h-2.83zM22.344 0L13.858 8.485 15.272 9.9l7.9-7.9h-.828zm5.656 0L19.515 8.485 17.343 10.657 28 0h-2.83zM32.656 0L26.172 6.485 24 8.657 34.657 0h-2zM44.97 0L40.5 4.472 36.03 0h8.94zM12.807 0L9.5 3.308 6.193 0h6.614zM48.743 0L42.5 6.243 36.257 0h12.486zM15.857 0L9.5 6.357 3.143 0h12.714zM49.05 0L42.5 6.55 35.95 0h13.1zM30.807 0L24.5 6.307 18.193 0h12.614zM38.657 0L24.5 14.157 10.343 0h28.314zM32.657 0L24.5 8.157 16.343 0h16.314zM26.657 0L24.5 2.157 22.343 0h4.314zM56.657 0L24.5 32.157 0 7.657V0h56.657zM0 9.071L24.5 33.571 56.657 1.414V0h.343L24.5 32.5 0 8V9.071zM0 0h56.657L24.5 32.157 0 7.657V0z\' fill=\'%23262626\' fill-opacity=\'0.2\' fill-rule=\'evenodd\'/%3E%3C/svg%3E")]'
                          : 'bg-[url("data:image/svg+xml,%3Csvg width=\'60\' height=\'60\' viewBox=\'0 0 60 60\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M54.627 0l.83.828-1.415 1.415L51.8 0h2.827zM5.373 0l-.83.828L5.96 2.243 8.2 0H5.374zM48.97 0l3.657 3.657-1.414 1.414L46.143 0h2.828zM11.03 0L7.372 3.657 8.787 5.07 13.857 0H11.03zm32.284 0L49.8 6.485 48.384 7.9l-7.9-7.9h2.83zM16.686 0L10.2 6.485 11.616 7.9l7.9-7.9h-2.83zM22.344 0L13.858 8.485 15.272 9.9l7.9-7.9h-.828zm5.656 0L19.515 8.485 17.343 10.657 28 0h-2.83zM32.656 0L26.172 6.485 24 8.657 34.657 0h-2zM44.97 0L40.5 4.472 36.03 0h8.94zM12.807 0L9.5 3.308 6.193 0h6.614zM48.743 0L42.5 6.243 36.257 0h12.486zM15.857 0L9.5 6.357 3.143 0h12.714zM49.05 0L42.5 6.55 35.95 0h13.1zM30.807 0L24.5 6.307 18.193 0h12.614zM38.657 0L24.5 14.157 10.343 0h28.314zM32.657 0L24.5 8.157 16.343 0h16.314zM26.657 0L24.5 2.157 22.343 0h4.314zM56.657 0L24.5 32.157 0 7.657V0h56.657zM0 9.071L24.5 33.571 56.657 1.414V0h.343L24.5 32.5 0 8V9.071zM0 0h56.657L24.5 32.157 0 7.657V0z\' fill=\'%23e2e8f0\' fill-opacity=\'0.4\' fill-rule=\'evenodd\'/%3E%3C/svg%3E")]'
                        }
                        bg-[length:30px_30px]
                        opacity-50
                      `} />
                    </div>

                    {/* Video */}
                    <video
                      ref={videoRef}
                      className="w-full h-full object-cover relative z-10"
                      src={feature.videoUrl}
                      onEnded={handleVideoEnd}
                      playsInline
                    />

                    {/* Overlay Content */}
                    {!isVideoPlaying && (
                      <div className="absolute inset-0 z-20">
                        {/* ... rest of the overlay content ... */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const DesktopFeatures = () => (
    <div 
      id="features-section"
      className={`py-24 sm:py-32 relative overflow-hidden ${
        isDarkMode ? 'bg-[#121212]/0' : 'bg-white/0'
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Features Tag and Header combined in flex container */}
        <div className="flex items-center justify-between mb-6 sm:mb-8">
          <h2 className={`
            text-3xl sm:text-4xl font-semibold tracking-tight leading-tight sm:leading-normal
            ${isDarkMode ? 'text-white' : 'text-slate-900'}
          `}>
            Create content that{' '}
            <span className={`
              block sm:inline mt-1 sm:mt-0
              ${isDarkMode 
                ? 'text-white/60' 
                : 'text-slate-900/60'
              }
            `}>
              moves people
            </span>
          </h2>
          
          <span className={`inline-block px-3 py-1.5 text-xs font-medium uppercase tracking-wider rounded-full ${
            isDarkMode 
              ? 'bg-slate-800/80 text-slate-300 border border-slate-700/50' 
              : 'bg-white text-slate-600 border border-slate-200'
          }`}>
            Features
          </span>
        </div>

        <div 
          className={`
            rounded-2xl overflow-hidden
            backdrop-blur-xl backdrop-saturate-150
            ${isDarkMode 
              ? 'bg-[#1a1a1a]/90 border border-white/10' 
              : 'bg-white/90 border border-black/5'
            }
          `}
        >
          {/* Tabs Bar */}
          <div className="relative flex flex-col bg-transparent">
            {/* Pill-style Tabs */}
            <div className="flex items-center px-4 py-4">
              <div className="flex-1 flex items-center justify-center gap-3">
                {features.map((feature, index) => (
                  <button
                    key={feature.id}
                    onClick={() => setActiveTab(feature.id)}
                    className={`
                      relative group flex items-center gap-3
                      px-6 py-2.5
                      text-sm font-medium
                      whitespace-nowrap
                    `}
                  >
                    <div 
                      className={`
                        absolute inset-0 rounded-full
                        ${activeTab === feature.id
                          ? isDarkMode
                            ? 'bg-[#1a1a1a] border border-white/20 shadow-lg shadow-black/10'
                            : 'bg-white border border-black/10 shadow-lg shadow-black/5'
                          : isDarkMode
                            ? 'group-hover:bg-slate-800/80 group-hover:border-slate-700/50'
                            : 'group-hover:bg-slate-100 group-hover:border-slate-200/80'
                        }
                      `}
                    />
                    <div className="relative flex items-center gap-3">
                      <feature.icon className={`
                        w-4 h-4 flex-shrink-0
                        ${activeTab === feature.id
                          ? isDarkMode ? 'text-blue-400' : 'text-blue-600'
                          : isDarkMode 
                            ? 'text-slate-400 group-hover:text-slate-200' 
                            : 'text-slate-500 group-hover:text-slate-800'
                        }
                      `} />
                      <span className={`
                        ${activeTab === feature.id
                          ? isDarkMode ? 'text-white' : 'text-slate-900'
                          : isDarkMode 
                            ? 'text-slate-400 group-hover:text-slate-200' 
                            : 'text-slate-600 group-hover:text-slate-800'
                        }
                      `}>
                        {feature.fullTitle}
                      </span>
                    </div>
                  </button>
                ))}
              </div>
            </div>
          </div>

          {/* Content Area */}
          <div className="aspect-video relative">
            {/* Background Pattern */}
            <div className={`
              absolute inset-0
              ${isDarkMode 
                ? 'bg-gradient-to-br from-[#121212] to-[#1a1a1a]' 
                : 'bg-gradient-to-br from-slate-50 to-white'
              }
            `}>
              <div className={`
                absolute inset-0 
                ${isDarkMode
                  ? 'bg-[url("data:image/svg+xml,%3Csvg width=\'40\' height=\'40\' viewBox=\'0 0 40 40\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M0 0h40v40H0V0zm1 1h38v38H1V1z\' fill=\'%23333\' fill-opacity=\'.1\' fill-rule=\'evenodd\'/%3E%3C/svg%3E")]'
                  : 'bg-[url("data:image/svg+xml,%3Csvg width=\'40\' height=\'40\' viewBox=\'0 0 40 40\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M0 0h40v40H0V0zm1 1h38v38H1V1z\' fill=\'%23000\' fill-opacity=\'.05\' fill-rule=\'evenodd\'/%3E%3C/svg%3E")]'
                }
                opacity-30 bg-[length:20px_20px] sm:bg-[length:40px_40px]
                ${isVideoPlaying ? 'opacity-0' : 'opacity-30'}
              `} />
            </div>

            {/* Video Player */}
            <div 
              className={`
                absolute inset-0
                ${isVideoPlaying ? 'block' : 'hidden'}
              `}
            >
              <video
                ref={videoRef}
                className="w-full h-full object-cover"
                src={currentFeature.videoUrl}
                onEnded={handleVideoEnd}
                playsInline
              />
            </div>

            {/* Play Button */}
            {!isVideoPlaying && (
              <div className="absolute inset-0 flex items-center justify-center">
                <button
                  onClick={handlePlayVideo}
                  className={`
                    group relative w-16 h-16 rounded-full
                    flex items-center justify-center
                    backdrop-blur-xl backdrop-saturate-150
                    ${isDarkMode
                      ? 'bg-white/10 text-white/90 hover:bg-white/20'
                      : 'bg-black/15 text-black/90 hover:bg-black/25'
                    }
                  `}
                >
                  <PlayIcon className="h-8 w-8 relative z-10" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  return isMobile ? <MobileFeatures /> : <DesktopFeatures />;
};

export default FeaturesSection; 