import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useTheme } from '../../../context/ThemeContext';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import EarlyAccessButton from '../../EarlyAccessButton';

const CTASection = () => {
  const { isDarkMode } = useTheme();

  return (
    <motion.section 
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      className={`
        w-full px-4 sm:px-6 lg:px-8 py-24
        ${isDarkMode ? 'bg-[#09090B]' : 'bg-slate-50'}
      `}
    >
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className={`
            relative rounded-[20px] overflow-hidden
            ${isDarkMode 
              ? 'bg-[#18181B] border border-[#27272A]' 
              : 'bg-white border border-slate-200'
            }
          `}
        >
          {/* Subtle gradient overlay */}
          <div className={`
            absolute inset-0
            ${isDarkMode 
              ? 'bg-gradient-to-br from-violet-500/5 via-transparent to-transparent' 
              : 'bg-gradient-to-br from-violet-500/[0.025] via-transparent to-transparent'
            }
          `} />

          <div className="relative px-8 py-12 sm:p-16 lg:p-20">
            <div className="max-w-3xl mx-auto text-center">
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.2 }}
                className="mb-2 text-sm font-medium tracking-wide uppercase"
              >
                <span className={`
                  ${isDarkMode ? 'text-violet-400' : 'text-violet-600'}
                `}>
                  Turn Ideas Into Impact
                </span>
              </motion.div>

              <motion.h2
                initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.3 }}
                className={`
                  text-3xl sm:text-4xl lg:text-5xl font-bold tracking-tight mb-4
                  ${isDarkMode ? 'text-white' : 'text-slate-900'}
                `}
              >
                Your Thoughts,{' '}
                <span className={`
                  ${isDarkMode ? 'text-violet-400' : 'text-violet-600'}
                `}>
                  Amplified by AI
                </span>
              </motion.h2>

              <motion.p
                initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.4 }}
                className={`
                  text-lg mb-10
                  ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
                `}
              >
                Transform your notes and ideas into authentic LinkedIn content that resonates with your audience, powered by AI that understands your unique voice.
              </motion.p>

              <motion.div
                initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.5 }}
                className="flex flex-wrap items-center justify-center gap-4"
              >
                <EarlyAccessButton isDarkMode={isDarkMode} />
              </motion.div>
            </div>
          </div>

          {/* Subtle shine effect */}
          <div className={`
            absolute inset-0 pointer-events-none
            mix-blend-soft-light
            bg-[url("data:image/svg+xml,%3Csvg width='200' height='200' viewBox='0 0 200 200' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='200' height='200' rx='100' fill='url(%23paint0_radial_0_1)'/%3E%3Cdefs%3E%3CradialGradient id='paint0_radial_0_1' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(100 100) rotate(90) scale(100)'%3E%3Cstop stop-color='white' stop-opacity='0.3'/%3E%3Cstop offset='1' stop-color='white' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3C/svg%3E")]
            bg-center bg-no-repeat opacity-70
          `} />
        </motion.div>
      </div>
    </motion.section>
  );
};

export default CTASection; 