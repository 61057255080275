import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserIcon, UserPlusIcon, MagnifyingGlassIcon, XMarkIcon, ArrowPathIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../../context/ThemeContext';
import api, { defaultDataApi } from '../../api';
import { fetchAvatarUrl, getAvatarFromCache, saveAvatarToCache, refreshExpiredAvatarUrl, forceRefreshAvatarUrl } from '../../utils/avatarUtils';
import PersonaCard from './PersonaCard';
import CompactPersonaCard from './CompactPersonaCard';
import PersonaDetailsPopup from './PersonaDetailsPopup';
import { GridSkeleton } from '../common/CardSkeleton';
import { motion } from 'framer-motion';
import Notification from '../Notification';

const FALLBACK_AVATAR = '/default-avatar.png'; // Replace with an actual fallback image in your public folder
const REFRESH_INTERVAL = 30 * 60 * 1000; // 30 minutes in milliseconds
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

// Add this new component at the top of the file, outside of the main PersonasPage component
const NoResultsMessage = ({ searchTerm, onCreateClick, onAddExamples, isDarkMode }) => (
  <motion.div 
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.4 }}
    className={`
      flex flex-col items-center justify-center p-8 rounded-2xl
      backdrop-blur-xl backdrop-saturate-150
      ${isDarkMode 
        ? 'bg-[#1a1a1a]/90 border border-white/10 shadow-black/50' 
        : 'bg-white/90 border border-black/5 shadow-black/10'}
    `}
  >
    <div className={`
      p-4 rounded-full mb-4
      ${isDarkMode 
        ? 'bg-[#252525] text-blue-400/80' 
        : 'bg-slate-100 text-blue-600/80'}
    `}>
      <UserIcon className="w-8 h-8" />
    </div>
    
    <div className="text-center max-w-sm">
      <motion.h3 
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1 }}
        className={`text-lg font-semibold mb-2 tracking-tight
          ${isDarkMode ? 'text-white' : 'text-slate-900'}
        `}
      >
        {searchTerm ? `No results for "${searchTerm}"` : 'Create your first profile'}
      </motion.h3>
      
      <motion.p 
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className={`text-sm mb-6
          ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
        `}
      >
        {searchTerm 
          ? 'Try different keywords or create a new profile' 
          : 'Get started by creating a new brand profile or add example profiles'}
      </motion.p>
      
      <div className="flex items-center gap-3 justify-center">
        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={onCreateClick}
          className={`
            relative group flex items-center gap-2 
            px-4 py-2 rounded-lg text-sm font-medium
            transition-all duration-200
            ${isDarkMode 
              ? 'bg-[#252525] hover:bg-[#2a2a2a] text-slate-200 border border-white/10' 
              : 'bg-slate-900 hover:bg-slate-800 text-white'}
          `}
        >
          <UserPlusIcon className="w-4 h-4" />
          <span>New Profile</span>
        </motion.button>
        
        {!searchTerm && (
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={onAddExamples}
            className={`
              relative group flex items-center gap-2
              px-4 py-2 rounded-lg text-sm font-medium
              transition-all duration-200
              ${isDarkMode 
                ? 'bg-[#1e1e1e] hover:bg-[#252525] text-slate-300 border border-white/10' 
                : 'bg-slate-100 hover:bg-slate-200 text-slate-700'}
            `}
          >
            <PlusCircleIcon className="w-4 h-4" />
            <span>Add Examples</span>
          </motion.button>
        )}
      </div>
    </div>
  </motion.div>
);

const PersonasPage = ({ 
  isPopup = false, 
  onSelectPersona, 
  onEditPersona, 
  externalSearchTerm = '',
  isInChatInterface = false
}) => {
  const [personas, setPersonas] = useState([]);
  const [filteredPersonas, setFilteredPersonas] = useState([]);
  const [searchTerm, setSearchTerm] = useState(externalSearchTerm);
  const [isInitialFetchDone, setIsInitialFetchDone] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [personaToDelete, setPersonaToDelete] = useState(null);
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [selectedPersona, setSelectedPersona] = useState(null);
  const [isRestoring, setIsRestoring] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const addNotification = useCallback((title, message, type = 'info') => {
    const id = Date.now();
    setNotifications(prev => [...prev, { id, title, message, type }]);
    
    // Automatically remove the notification after 5 seconds
    setTimeout(() => {
      setNotifications(prev => prev.filter(notification => notification.id !== id));
    }, 5000);
  }, []);

  const retryFetchAvatarUrl = useCallback(async (personaId, retries = 0) => {
    try {
      const avatarUrl = await fetchAvatarUrl(personaId);
      if (avatarUrl) {
        saveAvatarToCache(personaId, avatarUrl);
        return avatarUrl;
      }
    } catch (error) {
      console.error(`Error fetching avatar URL for persona ${personaId}:`, error);
      if (retries < MAX_RETRIES) {
        await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
        return retryFetchAvatarUrl(personaId, retries + 1);
      }
    }
    // If we reach here, use and cache the fallback avatar
    saveAvatarToCache(personaId, FALLBACK_AVATAR);
    return FALLBACK_AVATAR;
  }, []);

  const fetchPersonas = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get('/api/personas');
      const personasWithAvatars = await Promise.all(response.data.map(async (persona) => {
        let avatarUrl = FALLBACK_AVATAR;
        
        if (persona.avatar) {
          const cachedUrl = getAvatarFromCache(persona._id);
          if (cachedUrl) {
            avatarUrl = cachedUrl;
          } else {
            try {
              avatarUrl = await retryFetchAvatarUrl(persona._id);
            } catch (error) {
              console.error(`Error fetching avatar for persona ${persona._id}:`, error);
              avatarUrl = FALLBACK_AVATAR;
            }
          }
        }
        
        return { 
          ...persona, 
          signedAvatarUrl: avatarUrl 
        };
      }));
      setPersonas(personasWithAvatars);
    } catch (error) {
      console.error('Error fetching personas:', error);
      addNotification(
        'Error Loading Profiles',
        'Failed to load profiles. Please try refreshing the page.',
        'error'
      );
    } finally {
      setIsLoading(false);
    }
  }, [retryFetchAvatarUrl]);

  const refreshExpiredAvatars = useCallback(async () => {
    const updatedPersonas = await Promise.all(
      personas.map(async (persona) => {
        if (persona.avatar) {
          const cachedUrl = getAvatarFromCache(persona._id);
          if (!cachedUrl) {
            const newAvatarUrl = await refreshExpiredAvatarUrl(persona._id);
            return { ...persona, signedAvatarUrl: newAvatarUrl || FALLBACK_AVATAR };
          }
        }
        return persona;
      })
    );
    setPersonas(updatedPersonas);
  }, [personas]);

  // Initial fetch
  useEffect(() => {
    if (!isInitialFetchDone) {
      fetchPersonas().then(() => setIsInitialFetchDone(true));
    }
  }, [fetchPersonas, isInitialFetchDone]);

  // Add a new useEffect for periodic refreshing
  useEffect(() => {
    if (isInitialFetchDone) {
      const refreshInterval = setInterval(() => {
        refreshExpiredAvatars();
      }, REFRESH_INTERVAL);

      return () => clearInterval(refreshInterval);
    }
  }, [refreshExpiredAvatars, isInitialFetchDone]);

  // Update filteredPersonas when searchTerm or personas change
  useEffect(() => {
    if (personas.length > 0) {
      const lowercasedFilter = searchTerm.toLowerCase();
      const filtered = personas.filter(persona =>
        persona.name.toLowerCase().includes(lowercasedFilter) ||
        persona.occupation.toLowerCase().includes(lowercasedFilter)
      );
      setFilteredPersonas(filtered);
    }
  }, [searchTerm, personas]);

  // Update internal searchTerm when externalSearchTerm changes (for popup mode)
  useEffect(() => {
    if (isPopup) {
      setSearchTerm(externalSearchTerm);
    }
  }, [externalSearchTerm, isPopup]);

  const handleEdit = (persona) => {
    console.log('PersonasPage: handleEdit', { isInChatInterface, persona });
    if (isInChatInterface && onEditPersona) {
      onEditPersona(persona);
    } else {
      navigate(`/personas/edit/${persona._id}`);
    }
  };

  const handleAvatarError = useCallback(async (personaId) => {
    console.error(`Error loading avatar for persona ${personaId}`);
    const persona = personas.find(p => p._id === personaId);
    if (persona) {
      console.error(`Persona details:`, persona);
      console.error(`Attempted avatar URL:`, persona.signedAvatarUrl);
      
      // Force refresh the avatar URL
      const newAvatarUrl = await forceRefreshAvatarUrl(personaId);
      
      if (newAvatarUrl) {
        setPersonas(prevPersonas => 
          prevPersonas.map(p => {
            if (p._id === personaId) {
              console.log(`Setting new avatar URL for persona ${personaId}:`, newAvatarUrl);
              return { ...p, signedAvatarUrl: newAvatarUrl };
            }
            return p;
          })
        );
      } else {
        console.error(`Failed to refresh avatar URL for persona ${personaId}`);
      }
    }
  }, [personas]);

  const handleDeleteClick = (personaId) => {
    setPersonaToDelete(personaId);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    if (personaToDelete) {
      try {
        await api.delete(`/api/personas/${personaToDelete}`);
        const updatedPersonas = personas.filter(persona => persona._id !== personaToDelete);
        setPersonas(updatedPersonas);
        // Update filtered personas immediately
        const lowercasedFilter = searchTerm.toLowerCase();
        const updatedFiltered = updatedPersonas.filter(persona =>
          persona.name.toLowerCase().includes(lowercasedFilter) ||
          persona.occupation.toLowerCase().includes(lowercasedFilter)
        );
        setFilteredPersonas(updatedFiltered);
        setShowDeleteModal(false);
        setPersonaToDelete(null);
      } catch (error) {
        console.error('Error deleting persona:', error);
        addNotification('Delete Failed', 'Failed to delete persona. Please try again.', 'error');
      }
    }
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
    setPersonaToDelete(null);
  };

  const handlePersonaClick = (persona) => {
    if (isPopup && onSelectPersona) {
      onSelectPersona(persona);
    } else {
      setSelectedPersona(persona); // Open details popup for non-popup mode
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    if (isSearchOpen) {
      setSearchTerm('');
    }
  };

  // Add this new function to handle the create button click
  const handleCreateClick = () => {
    if (isPopup) {
      // Handle creation in popup mode (if needed)
    } else {
      navigate('/personas/create');
    }
  };

  const handleRestoreDefaults = async () => {
    try {
      setIsRestoring(true);
      const response = await api.post('/api/personas/defaults/restore');
      if (response.data) {
        await fetchPersonas(); // Refresh the list after restore
        addNotification(
          'Defaults Restored',
          'Default personas have been restored successfully',
          'success'
        );
      }
    } catch (error) {
      console.error('Error restoring default personas:', error);
      addNotification(
        'Restore Failed',
        'Failed to restore default personas',
        'error'
      );
    } finally {
      setIsRestoring(false);
    }
  };

  return (
    <div className={`flex flex-col h-full relative overflow-hidden bg-transparent
                    ${isPopup ? 'max-h-96' : ''} 
                    min-w-[300px] w-full max-w-full
                    mx-auto transition-all duration-300 ease-in-out`}>
      {/* Notifications */}
      <div className="fixed top-4 right-4 z-50">
        {notifications.map(notification => (
          <Notification
            key={notification.id}
            title={notification.title}
            message={notification.message}
            type={notification.type}
            onClose={() => setNotifications(prev => prev.filter(n => n.id !== notification.id))}
          />
        ))}
      </div>

      {!isPopup && (
        // Top Bar
        <div className={`${isDarkMode 
          ? 'bg-[#121212]/95 border-[#333333]' 
          : 'bg-white bg-opacity-90 border-gray-200'} 
          !h-10 min-h-10 flex-shrink-0 flex items-center justify-between px-4 py-2 w-full border-b`}>
          {/* Left section */}
          <div className="flex items-center">
            <UserIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'} mr-2`} />
            <h2 className={`text-sm font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>Brand Profiles</h2>
          </div>

          {/* Right section */}
          <div className="flex items-center space-x-2">
            {isSearchOpen ? (
              <div className="relative">
                <input
                  type="text"
                  className={`w-48 px-3 py-1 text-sm border rounded-md pl-8 focus:outline-none focus:ring-2 focus:ring-blue-500
                    ${isDarkMode
                      ? 'bg-[#151515] border-[#333333] text-slate-200 placeholder-slate-400'
                      : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                    }`}
                  placeholder="Search personas..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <MagnifyingGlassIcon className={`absolute left-2 top-1.5 h-4 w-4 ${
                  isDarkMode ? 'text-slate-400' : 'text-gray-400'
                }`} />
                <button
                  onClick={toggleSearch}
                  className={`absolute right-2 top-1.5 ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`}
                >
                  <XMarkIcon className="h-4 w-4" />
                </button>
              </div>
            ) : (
              <button
                onClick={toggleSearch}
                className={`p-1 rounded-md ${
                  isDarkMode ? 'hover:bg-slate-700' : 'hover:bg-gray-100'
                }`}
              >
                <MagnifyingGlassIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
              </button>
            )}
            
            {personas.length > 0 && (
              <button
                onClick={handleRestoreDefaults}
                disabled={isRestoring}
                className={`flex items-center px-2 py-1 rounded-md transition-colors duration-200 text-sm
                          ${isDarkMode 
                            ? 'bg-[#1a1a1a] hover:bg-[#202020] text-slate-200' 
                            : 'bg-gray-100 hover:bg-gray-200 text-gray-700'}`}
                title="Restore Sample Profiles"
              >
                <ArrowPathIcon className={`w-4 h-4 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'} mr-1
                                  ${isRestoring ? 'animate-spin' : ''}`} />
                Restore Samples
              </button>
            )}

            <button 
              onClick={() => navigate('/personas/create')}
              className={`flex items-center px-2 py-1 rounded-md transition-colors duration-200 text-sm
                          ${isDarkMode 
                            ? 'bg-[#1a1a1a] hover:bg-[#202020] text-slate-200' 
                            : 'bg-gray-100 hover:bg-gray-200 text-gray-700'}`}
              title="Create New Persona"
            >
              <UserPlusIcon className={`w-4 h-4 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'} mr-1`} />
              Add New Profile
            </button>
          </div>
        </div>
      )}

      {/* Content Area */}
      <div className={`
        flex-grow overflow-y-auto custom-scrollbar 
        ${isDarkMode ? 'bg-[#121212]' : 'bg-white bg-opacity-80'}
        ${!isPopup && 'px-4 py-4'} // Add padding when not in popup mode
      `}>
        {isLoading ? (
          <GridSkeleton count={6} />
        ) : filteredPersonas.length > 0 ? (
          <div className={`
            grid grid-cols-1 md:grid-cols-2 gap-4
            ${isPopup ? 'px-2 pb-4' : 'max-w-3xl mx-auto pb-20'}
          `}>
            {filteredPersonas.map((persona) => (
              isInChatInterface ? (
                <CompactPersonaCard
                  key={persona._id}
                  persona={persona}
                  onPersonaClick={handlePersonaClick}
                  isSelected={selectedPersona?._id === persona._id}
                  isInChatInterface={isInChatInterface}
                  onEditInChat={() => handleEdit(persona)}
                />
              ) : (
                <PersonaCard
                  key={persona._id}
                  persona={persona}
                  isPopup={isPopup}
                  onPersonaClick={handlePersonaClick}
                  onEdit={() => handleEdit(persona)}
                  onDelete={handleDeleteClick}
                  onAvatarError={handleAvatarError}
                />
              )
            ))}
          </div>
        ) : (
          <div className="h-full flex items-center justify-center py-4">
            <NoResultsMessage 
              searchTerm={searchTerm}
              onCreateClick={handleCreateClick}
              onAddExamples={handleRestoreDefaults}
              isDarkMode={isDarkMode}
            />
          </div>
        )}
      </div>

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <div className={`relative w-full max-w-sm transform overflow-hidden rounded-lg shadow-xl transition-all
                          ${isDarkMode ? 'bg-[#151515]' : 'bg-white'}`}>
            <div className="p-4">
              {/* Header and Content Combined */}
              <div className="mb-4">
                <h3 className={`text-base font-semibold mb-2 
                              ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>
                  Delete Profile
                </h3>
                <p className={`text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}`}>
                  Are you sure? This action cannot be undone.
                </p>
              </div>

              {/* Actions */}
              <div className="flex justify-end gap-2">
                <button
                  onClick={cancelDelete}
                  className={`px-3 py-1.5 rounded-md text-sm font-medium
                            transition-all duration-200
                            ${isDarkMode 
                              ? 'bg-[#1a1a1a] text-slate-200 hover:bg-[#202020] border border-[#333333]' 
                              : 'bg-gray-100 text-gray-700 hover:bg-gray-200'}`}
                >
                  Cancel
                </button>
                <button
                  onClick={confirmDelete}
                  className={`px-3 py-1.5 rounded-md text-sm font-medium text-white
                            transition-all duration-200
                            bg-red-500 hover:bg-red-600
                            ${isDarkMode ? 'bg-opacity-90' : ''}`}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Move PersonaDetailsPopup here */}
      {selectedPersona && (
        <PersonaDetailsPopup
          persona={selectedPersona}
          onClose={() => setSelectedPersona(null)}
        />
      )}
    </div>
  );
};

export default PersonasPage;
