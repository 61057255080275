import React from 'react';
import { 
  MicrophoneIcon, 
  DocumentTextIcon, 
  PaperClipIcon, 
  BookOpenIcon,
  ArrowUpTrayIcon,
  SpeakerWaveIcon,
  DocumentIcon,
  BookmarkIcon
} from '@heroicons/react/24/outline';

const MenuItem = ({ icon: Icon, label, onClick, description, isDarkMode }) => (
  <button
    onClick={onClick}
    className={`
      flex items-center gap-2 px-2 py-1.5 w-full rounded-md
      transition-all duration-200 relative group
      ${isDarkMode 
        ? 'hover:bg-[#1e1e1e] text-slate-200' 
        : 'hover:bg-gray-50 text-gray-700'}
    `}
  >
    <div className={`
      flex items-center justify-center w-6 h-6 rounded-md
      transition-all duration-200
      ${isDarkMode 
        ? 'bg-[#1e1e1e]' 
        : 'bg-gray-100/50'}
    `}>
      <Icon className="h-4 w-4" />
    </div>
    
    <div className="flex flex-col items-start min-w-0 gap-0.5">
      <span className="text-xs font-medium truncate">
        {label}
      </span>
      <span className={`
        text-[10px] leading-none
        ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}
      `}>
        {description}
      </span>
    </div>
  </button>
);

const AddContextMenu = ({ 
  onSelectVoiceNote, 
  onSelectTextNote, 
  onSelectKnowledgeBase, 
  onSelectFileUpload, 
  isDarkMode 
}) => (
  <div className={`
    absolute bottom-full left-0 mb-2 w-56 rounded-lg shadow-lg z-50
    backdrop-blur-md backdrop-saturate-150 overflow-hidden
    border transition-all duration-200
    ${isDarkMode 
      ? 'bg-[#121212]/95 border-[#333333]' 
      : 'bg-white/95 border-gray-200/80'}
  `}>
    <div className={`
      px-2 py-1.5 border-b text-xs font-medium
      ${isDarkMode ? 'border-[#333333] text-slate-400' : 'border-gray-200/80 text-gray-500'}
    `}>
      Add Context
    </div>
    
    <div className="p-0.5">
      <MenuItem
        icon={PaperClipIcon}
        label="Upload Files"
        description="Add documents & files"
        onClick={onSelectFileUpload}
        isDarkMode={isDarkMode}
      />
      <MenuItem
        icon={MicrophoneIcon}
        label="Voice Notes"
        description="Add transcribed audio"
        onClick={onSelectVoiceNote}
        isDarkMode={isDarkMode}
      />
      <MenuItem
        icon={DocumentTextIcon}
        label="Text Notes"
        description="Add saved notes"
        onClick={onSelectTextNote}
        isDarkMode={isDarkMode}
      />
      <MenuItem
        icon={BookOpenIcon}
        label="Knowledge Base"
        description="Add from library"
        onClick={onSelectKnowledgeBase}
        isDarkMode={isDarkMode}
      />
    </div>
  </div>
);

export default AddContextMenu;