const SavingDots = ({ isDarkMode }) => {
  return (
    <div className="flex items-center space-x-0.5 ml-1">
      <div className={`w-1 h-1 rounded-full ${isDarkMode ? 'bg-slate-400' : 'bg-gray-400'} animate-saving-dot-1`}></div>
      <div className={`w-1 h-1 rounded-full ${isDarkMode ? 'bg-slate-400' : 'bg-gray-400'} animate-saving-dot-2`}></div>
      <div className={`w-1 h-1 rounded-full ${isDarkMode ? 'bg-slate-400' : 'bg-gray-400'} animate-saving-dot-3`}></div>
    </div>
  );
};

export default SavingDots; 