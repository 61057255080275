// ------------------------------------------------------------------------
// LinkedInPreviewMockup Component
// ------------------------------------------------------------------------
// This component creates a mockup of a LinkedIn post preview with responsive
// design and dark/light mode support. It handles avatar display, content
// expansion/collapse, and placeholder states.
// ------------------------------------------------------------------------

import React, { useState, useCallback, useEffect } from 'react';
import { EllipsisHorizontalIcon, UserIcon } from '@heroicons/react/24/outline';
import '../styles/markdown.css';

// ------------------------------------------------------------------------
// Props:
// - selectedPersona: Object containing user profile data (name, occupation, avatarUrl)
// - content: String containing the post content
// - isDarkMode: Boolean to toggle dark/light mode styling
// ------------------------------------------------------------------------

const LinkedInPreviewMockup = ({ 
  selectedPersona, 
  content,
  isDarkMode,
}) => {
  // State for content expansion toggle and avatar URL management
  const [isExpanded, setIsExpanded] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(null);

  // ------------------------------------------------------------------------
  // Avatar URL Management
  // Updates avatar URL when selectedPersona changes, falls back to null if
  // no avatar URL is provided
  // ------------------------------------------------------------------------
  useEffect(() => {
    if (selectedPersona?.avatarUrl) {
      setAvatarUrl(selectedPersona.avatarUrl);
    } else {
      setAvatarUrl(null);
    }
  }, [selectedPersona]);

  // ------------------------------------------------------------------------
  // Content Toggle Handler
  // Manages expansion/collapse of post content with event propagation control
  // ------------------------------------------------------------------------
  const toggleView = useCallback((e) => {
    if (e) {
      e.stopPropagation();
    }
    setIsExpanded(prev => !prev);
  }, []);

  // ------------------------------------------------------------------------
  // Content Rendering Logic
  // Handles:
  // 1. Placeholder state when no content is provided
  // 2. Content truncation for non-expanded state
  // 3. Full content display for expanded state
  // 4. "...more" and "...show less" toggle buttons
  // ------------------------------------------------------------------------
  const renderContent = () => {
    if (!content || typeof content !== 'string') {
      return (
        <>
          <div className={`h-4 ${isDarkMode ? 'bg-[#1e1e1e]' : 'bg-gray-200'} rounded w-full mb-2`}></div>
          <div className={`h-4 ${isDarkMode ? 'bg-[#1e1e1e]' : 'bg-gray-200'} rounded w-5/6 mb-2`}></div>
          <div className={`h-4 ${isDarkMode ? 'bg-[#1e1e1e]' : 'bg-gray-200'} rounded w-4/6`}></div>
        </>
      );
    }

    const contentLines = content.split('\n');
    
    if (!isExpanded) {
      return (
        <>
          {contentLines.slice(0, 3).map((line, index) => (
            <React.Fragment key={index}>
              {line}
              {index < 2 && <br />}
            </React.Fragment>
          ))}
          {contentLines.length > 3 && (
            <span 
              className={`hover:underline ml-1 cursor-pointer font-medium ${
                isDarkMode 
                  ? 'bg-gradient-to-r from-blue-400 to-blue-500 text-transparent bg-clip-text hover:from-blue-300 hover:to-blue-400' 
                  : 'bg-gradient-to-r from-blue-600 to-blue-500 text-transparent bg-clip-text hover:from-blue-700 hover:to-blue-600'
              }`}
              onClick={toggleView}
            >
              ...more
            </span>
          )}
        </>
      );
    } else {
      return (
        <>
          {content}
          <span 
            className={`hover:underline ml-1 cursor-pointer font-medium block mt-2 ${
              isDarkMode 
                ? 'bg-gradient-to-r from-blue-400 to-blue-500 text-transparent bg-clip-text hover:from-blue-300 hover:to-blue-400' 
                : 'bg-gradient-to-r from-blue-600 to-blue-500 text-transparent bg-clip-text hover:from-blue-700 hover:to-blue-600'
            }`}
            onClick={toggleView}
          >
            ...show less
          </span>
        </>
      );
    }
  };

  return (
    <div 
      className={`linkedin-post-mockup shadow-sm rounded-lg ${
        isDarkMode ? 'bg-[#151515] border-[#333333]' : 'bg-white border-gray-200'
      } border relative p-4`}
    >
      {/* Three-dot icon */}
      <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity">
        <EllipsisHorizontalIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-300' : 'text-gray-500'} cursor-pointer`} />
      </div>

      {/* Only show user section if a persona is selected */}
      {selectedPersona && (
        <div className="flex items-center">
          {avatarUrl ? (
            <img 
              src={avatarUrl} 
              alt={selectedPersona?.name || 'User'} 
              className="h-12 w-12 rounded-full object-cover aspect-square"
              onError={() => setAvatarUrl(null)}
            />
          ) : (
            <div className={`h-12 w-12 rounded-full aspect-square ${isDarkMode ? 'bg-[#1e1e1e]' : 'bg-gray-200'} flex items-center justify-center`}>
              <UserIcon className={`h-8 w-8 ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`} />
            </div>
          )}
          <div className="ml-3 flex-1 min-w-0">
            {selectedPersona?.name ? (
              <div className={`text-sm font-semibold truncate ${isDarkMode ? 'text-slate-200' : 'text-slate-800'}`}>
                {selectedPersona.name}
              </div>
            ) : (
              <div className={`h-3 ${isDarkMode ? 'bg-[#1e1e1e]' : 'bg-gray-200'} rounded w-24 mb-2`}></div>
            )}
            {selectedPersona?.occupation ? (
              <div className={`text-xs ${isDarkMode ? 'text-slate-300' : 'text-gray-500'} truncate`}>
                {selectedPersona.occupation}
              </div>
            ) : (
              <div className={`h-3 ${isDarkMode ? 'bg-[#1e1e1e]' : 'bg-gray-200'} rounded w-32`}></div>
            )}
          </div>
        </div>
      )}

      {/* Content section remains unchanged */}
      <div className={`${selectedPersona ? 'mt-4' : 'mt-0'}`}>
        <div
          className={`text-sm ${isDarkMode ? 'text-slate-200' : 'text-gray-700'} whitespace-pre-wrap break-words cursor-pointer`}
          onClick={toggleView}
        >
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default React.memo(LinkedInPreviewMockup);
