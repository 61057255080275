import React, { useRef, useState, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ItemTypes } from '../utils/constants';
import { FolderIcon, DocumentIcon, EllipsisHorizontalIcon, CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { formatDistanceToNow } from 'date-fns';
import { formatFileSize } from '../utils/formatters';
import PopupMenu from './PopupMenu';
import { createPortal } from 'react-dom';

const ItemRow = ({ 
  item, 
  onRename, 
  onMove, 
  onDelete, 
  onFolderClick, 
  onDownload, 
  onGetInfo, 
  folders, 
  isPopupVisible, 
  onPopupToggle,
  isDarkMode,
  popupRef,
  onMoveInitiate
}) => {
  const ref = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [isRenaming, setIsRenaming] = useState(false);
  const [newName, setNewName] = useState(item.name);
  const menuButtonRef = useRef(null);
  const renameInputRef = useRef(null);
  const [inputWidth, setInputWidth] = useState('auto');

  const emptyImage = new Image();
  emptyImage.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.ITEM,
    item: { id: item._id, type: item.type, name: item.name },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => !isRenaming,
  });

  useEffect(() => {
    preview(emptyImage, { captureDraggingState: true });
  }, [preview, emptyImage]);

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemTypes.ITEM,
    canDrop: (draggedItem) => draggedItem.id !== item._id && item.type === 'folder',
    drop: (draggedItem) => {
      if (draggedItem.id !== item._id && item.type === 'folder') {
        onMove(draggedItem.id, item._id);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const dragDropRef = (node) => {
    drag(node);
    drop(node);
  };

  const styles = {
    row: `group ${isDarkMode ? 'hover:bg-[#1e1e1e]' : 'hover:bg-gray-50'} transition-colors duration-200`,
    cell: 'py-2 px-3 text-sm',
    icon: `w-4 h-4 ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`,
    text: `${isDarkMode ? 'text-slate-200' : 'text-gray-900'} truncate text-sm`,
    secondaryText: `${isDarkMode ? 'text-slate-400' : 'text-gray-600'} text-sm`,
    input: `px-1 py-0.5 bg-transparent border-b text-sm ${
      isDarkMode 
        ? 'border-[#333333] focus:border-blue-400 text-slate-200' 
        : 'border-gray-300 focus:border-blue-500 text-gray-900'
    } focus:outline-none rounded`,
    button: `p-1 rounded-full transition-colors duration-150 ease-in-out text-sm ${
      isDarkMode
        ? 'hover:bg-[#1e1e1e] text-slate-400 hover:text-slate-200'
        : 'hover:bg-gray-200 text-gray-500 hover:text-gray-700'
    }`,
    confirmButton: `ml-2 p-1 rounded-full text-sm ${
      isDarkMode
        ? 'text-green-400 hover:bg-[#1e1e1e]'
        : 'text-green-600 hover:bg-green-100'
    }`,
    cancelButton: `ml-1 p-1 rounded-full text-sm ${
      isDarkMode
        ? 'text-red-400 hover:bg-[#1e1e1e]'
        : 'text-red-600 hover:bg-red-100'
    }`,
    folderRow: 'cursor-pointer',
    fileName: 'cursor-pointer',
  };

  const getIcon = () => {
    return item.type === 'folder' ? (
      <FolderIcon className={`${styles.icon} ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
    ) : (
      <DocumentIcon className={`${styles.icon} ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
    );
  };

  const handleItemClick = (e) => {
    if (item.type === 'folder' && !isRenaming) {
      onFolderClick(item);
    }
  };

  const handleMenuToggle = (e) => {
    e.stopPropagation();
    if (!isPopupVisible) {
      const iconRect = menuButtonRef.current.getBoundingClientRect();
      setMenuPosition({ x: iconRect.left, y: iconRect.top });
    }
    onPopupToggle(item._id);
  };

  const handleRename = () => {
    setIsRenaming(true);
    setNewName(item.name);
  };

  const handleRenameSubmit = () => {
    if (newName.trim() !== '' && newName !== item.name) {
      onRename(item, newName);
    }
    setIsRenaming(false);
  };

  const handleRenameCancel = () => {
    setIsRenaming(false);
    setNewName(item.name);
  };

  const formatLastModified = (lastModified) => {
    const dateToFormat = lastModified || item.updatedAt;
    if (!dateToFormat) return 'Unknown';
    try {
      const date = new Date(dateToFormat);
      if (isNaN(date.getTime())) return 'Invalid date';
      return formatDistanceToNow(date, { addSuffix: true });
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid date';
    }
  };

  const formatSize = (size) => {
    if (size === undefined || size === null) return '0 KB';
    return formatFileSize(size);
  };

  useEffect(() => {
    if (isRenaming && renameInputRef.current) {
      renameInputRef.current.focus();
      renameInputRef.current.select();
      setInputWidth(`${Math.max(item.name.length * 8, 50)}px`);
    }
  }, [isRenaming, item.name]);

  const handleInputChange = (e) => {
    setNewName(e.target.value);
    setInputWidth(`${Math.max(e.target.value.length * 8, 50)}px`);
  };

  const handleContextMenu = (e) => {
    e.preventDefault();
    const x = e.clientX;
    const y = e.clientY;
    setMenuPosition({ x, y });
    onPopupToggle(item._id);
  };

  return (
    <>
      <tr
        ref={isRenaming ? drop : dragDropRef}
        className={`
          ${isDragging ? 'opacity-50' : ''}
          ${styles.row}
          ${canDrop && item.type === 'folder' ? 'border-2 border-dashed' : ''}
          ${isOver && canDrop ? (isDarkMode ? 'border-blue-400' : 'border-blue-300') : ''}
          ${item.type === 'folder' ? styles.folderRow : ''}
          ${isDarkMode ? 'bg-[#121212]' : 'bg-white'}
        `}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleItemClick}
        onContextMenu={handleContextMenu}
      >
        <td className={`${styles.cell} w-1/2`}>
          <div className="flex items-center">
            <div className="flex-shrink-0 w-4 mr-2">
              {getIcon()}
            </div>
            {isRenaming ? (
              <div className="flex items-center flex-grow">
                <input
                  ref={renameInputRef}
                  type="text"
                  value={newName}
                  onChange={handleInputChange}
                  onKeyPress={(e) => e.key === 'Enter' && handleRenameSubmit()}
                  className={styles.input}
                  style={{ width: inputWidth }}
                />
                <button
                  onClick={handleRenameSubmit}
                  className={styles.confirmButton}
                  title="Confirm rename"
                >
                  <CheckIcon className="w-3.5 h-3.5" />
                </button>
                <button
                  onClick={handleRenameCancel}
                  className={styles.cancelButton}
                  title="Cancel rename"
                >
                  <XMarkIcon className="w-3.5 h-3.5" />
                </button>
              </div>
            ) : (
              <span className={`${styles.text} flex-grow ${item.type === 'file' ? styles.fileName : ''}`}>
                {item.name}
              </span>
            )}
          </div>
        </td>
        <td className={`${styles.cell} ${styles.secondaryText} w-1/4 whitespace-nowrap hidden sm:table-cell`}>
          {formatLastModified(item.updatedAt)}
        </td>
        <td className={`${styles.cell} ${styles.secondaryText} w-1/6 whitespace-nowrap hidden md:table-cell`}>
          {formatSize(item.size)}
        </td>
        <td className={`${styles.cell} w-1/12 text-right`}>
          <button
            ref={menuButtonRef}
            onClick={handleMenuToggle}
            className={styles.button}
            title="More options"
          >
            <EllipsisHorizontalIcon className="w-4 h-4" />
          </button>
        </td>
      </tr>
      {isPopupVisible && createPortal(
        <PopupMenu
          onMove={onMove}
          onClose={() => onPopupToggle(null)}
          onEdit={handleRename}
          onDelete={onDelete}
          onDownload={onDownload}
          onGetInfo={onGetInfo}
          folders={folders}
          currentItemId={item._id}
          position={menuPosition}
          item={item}
          isDarkMode={isDarkMode}
          ref={popupRef}
          onMoveInitiate={onMoveInitiate}
        />,
        document.body
      )}
    </>
  );
};

export default ItemRow;
