import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  ArrowLeftIcon, 
  SparklesIcon,
  ChevronRightIcon,
  ClipboardIcon,
  TrashIcon,
  XMarkIcon,
  EllipsisVerticalIcon,
  CheckIcon
} from '@heroicons/react/24/outline';
import { ClipboardIcon as ClipboardIconSolid } from '@heroicons/react/24/solid';
import { useTheme } from '../context/ThemeContext';
import { 
  fetchVoiceNote,
  generateContent,
  saveGeneratedContent,
  deleteGeneratedContent,
  updateTranscriptionTitle,
  regenerateTranscriptionTitle,
  getSignedUrl,
  deleteVoiceNote,
  updateVoiceNote
} from '../api';
import { format } from 'date-fns';
import ReactMarkdown from 'react-markdown';
import { markdownStyles } from './TranscriptionGridItem';
import { createPortal } from 'react-dom';
import GenerateContentPopup from './GenerateContentPopup';
import TranscriptionMenu from './TranscriptionMenu';

const splitIntoParagraphs = (text) => {
  if (!text) return [];
  
  // Split on multiple line breaks or when there's a significant pause (...)
  const paragraphs = text
    .split(/\n{2,}|\.\.\./g)
    .map(p => p.trim())
    .filter(p => p.length > 0);
    
  // If no natural breaks found, try to split on sentences
  if (paragraphs.length === 1) {
    return text
      .split(/(?<=[.!?])\s+/g)
      .reduce((acc, sentence, i) => {
        if (i % 3 === 0) acc.push(sentence);
        else acc[acc.length - 1] += ' ' + sentence;
        return acc;
      }, []);
  }
  
  return paragraphs;
};

const VoiceNotePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const [voiceNote, setVoiceNote] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [showPromptForm, setShowPromptForm] = useState(false);
  const [generatedContents, setGeneratedContents] = useState([]);
  const [expandedItems, setExpandedItems] = useState([]);
  const [copiedContentIndex, setCopiedContentIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [prompt, setPrompt] = useState('');
  const [promptButtonRef, setPromptButtonRef] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingTranscription, setIsEditingTranscription] = useState(false);
  const [editedTitle, setEditedTitle] = useState('');
  const [editedTranscription, setEditedTranscription] = useState('');
  const [buttonRect, setButtonRect] = useState(null);
  const createButtonRef = useRef(null);

  useEffect(() => {
    const loadVoiceNote = async () => {
      if (!id) return;
      
      try {
        setIsLoading(true);
        setError(null);
        console.log('Loading voice note:', id); // Debug log
        const data = await fetchVoiceNote(id);
        console.log('Loaded voice note:', data); // Debug log
        
        if (!data) {
          throw new Error('Voice note not found');
        }
        
        setVoiceNote(data);
        setEditedTitle(data.title);
        setEditedTranscription(data.transcription);
        setGeneratedContents(data.generatedContents || []);
      } catch (err) {
        console.error('Error loading voice note:', err);
        setError(err.message || 'Failed to load voice note');
      } finally {
        setIsLoading(false);
      }
    };

    loadVoiceNote();
  }, [id]);

  useEffect(() => {
    if (voiceNote) {
      setEditedTitle(voiceNote.title);
      setEditedTranscription(voiceNote.transcription);
    }
  }, [voiceNote]);

  const handleGenerateContent = async (promptText) => {
    setIsGenerating(true);
    try {
      const content = await generateContent(id, promptText);
      const savedContent = await saveGeneratedContent(id, promptText, content);
      setGeneratedContents(prev => [savedContent.generatedContent, ...prev]);
      setShowPromptForm(false);
    } catch (err) {
      setError('Failed to generate content');
    } finally {
      setIsGenerating(false);
    }
  };

  const handleDeleteContent = async (contentId) => {
    try {
      await deleteGeneratedContent(id, contentId);
      setGeneratedContents(prev => prev.filter(item => item._id !== contentId));
    } catch (err) {
      setError('Failed to delete generated content');
    }
  };

  const handleCopyGeneratedContent = async (content, index) => {
    try {
      await navigator.clipboard.writeText(content);
      setCopiedContentIndex(index);
      setTimeout(() => setCopiedContentIndex(null), 2000);
    } catch (err) {
      setError('Failed to copy content');
    }
  };

  const toggleItemExpansion = (index) => {
    setExpandedItems(prev => 
      prev.includes(index) 
        ? prev.filter(i => i !== index) 
        : [index]
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showPromptForm && !event.target.closest('.prompt-popup') && !event.target.closest('.create-button')) {
        setShowPromptForm(false);
        setPrompt('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPromptForm]);

  useEffect(() => {
    const updateButtonRect = () => {
      if (createButtonRef.current) {
        setButtonRect(createButtonRef.current.getBoundingClientRect());
      }
    };

    // Update initially and on scroll/resize
    updateButtonRect();
    window.addEventListener('scroll', updateButtonRect, { passive: true });
    window.addEventListener('resize', updateButtonRect, { passive: true });

    return () => {
      window.removeEventListener('scroll', updateButtonRect);
      window.removeEventListener('resize', updateButtonRect);
    };
  }, [showPromptForm]); // Re-run when popup visibility changes

  const renderPopup = () => {
    if (!showPromptForm || !buttonRect) return null;

    return createPortal(
      <GenerateContentPopup
        buttonRect={buttonRect}
        onClose={() => setShowPromptForm(false)}
        onGenerate={handleGenerateContent}
        isGenerating={isGenerating}
      />,
      document.body
    );
  };

  const handleDeleteVoiceNote = async () => {
    try {
      await deleteVoiceNote(id);
      navigate('/voice-notes'); // Navigate back to list after deletion
    } catch (error) {
      setError('Failed to delete voice note');
    }
  };

  const handleEditTitle = () => {
    setIsEditing(true);
  };

  const handleEditTranscription = () => {
    setIsEditingTranscription(true);
  };

  const handleRegenerateTitle = async () => {
    try {
      let newTitle = await regenerateTranscriptionTitle(id);
      // Remove any remaining quotation marks
      newTitle = newTitle.replace(/["']/g, '');
      setVoiceNote(prev => ({ ...prev, title: newTitle }));
    } catch (error) {
      setError('Failed to regenerate title');
    }
  };

  const handleDownloadAudio = async () => {
    try {
      const downloadUrl = await getSignedUrl(id, true);
      window.open(downloadUrl, '_blank');
    } catch (error) {
      setError('Failed to get download URL');
    }
  };

  const handleTitleSave = async () => {
    try {
      const updatedTranscription = await updateTranscriptionTitle(id, editedTitle);
      setVoiceNote(prev => ({ ...prev, title: updatedTranscription.title }));
      setIsEditing(false);
    } catch (error) {
      setError('Failed to update title');
    }
  };

  const onTranscriptionUpdate = async (id, newTranscription) => {
    try {
      const updatedVoiceNote = await updateVoiceNote(id, {
        transcription: newTranscription,
        title: voiceNote.title
      });
      
      setVoiceNote(updatedVoiceNote);
      return updatedVoiceNote;
    } catch (error) {
      console.error('Error updating transcription:', error);
      throw new Error('Failed to update transcription');
    }
  };

  const handleSaveTranscription = async () => {
    try {
      const updatedVoiceNote = await onTranscriptionUpdate(id, editedTranscription);
      setVoiceNote(updatedVoiceNote);
      setIsEditingTranscription(false);
    } catch (error) {
      setError('Failed to update transcription');
    }
  };

  if (isLoading) {
    return (
      <div className={`min-h-screen ${isDarkMode ? 'bg-[#121212]' : 'bg-slate-50'}`}>
        <div className="max-w-2xl mx-auto p-4">
          {/* Back button skeleton */}
          <div className={`w-24 h-6 rounded-md mb-6 ${
            isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-100'
          } animate-pulse`} />

          {/* Main content skeleton */}
          <div className="space-y-4">
            {/* Date */}
            <div className={`w-32 h-3 rounded ${
              isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-100'
            } animate-pulse`} />

            {/* Title */}
            <div className={`w-3/4 h-6 rounded ${
              isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-100'
            } animate-pulse`} />

            {/* Content lines */}
            <div className="space-y-2 mt-6">
              {Array(6).fill(0).map((_, index) => (
                <div 
                  key={index}
                  className={`h-3 rounded animate-pulse ${
                    isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-100'
                  } ${index === 5 ? 'w-2/3' : 'w-full'}`}
                />
              ))}
            </div>

            {/* Actions bar */}
            <div className="mt-8 pt-4 flex justify-between items-center border-t border-opacity-10 border-[#333333]">
              <div className={`w-20 h-7 rounded-md ${
                isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-100'
              } animate-pulse`} />
              <div className={`w-8 h-8 rounded-full ${
                isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-100'
              } animate-pulse`} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'} p-4`}>
        <div className="max-w-2xl mx-auto">
          <div className={`p-4 rounded-md ${isDarkMode ? 'bg-red-900/20 text-red-400' : 'bg-red-50 text-red-600'}`}>
            {error}
          </div>
        </div>
      </div>
    );
  }

  if (!voiceNote) return null;

  return (
    <div className={`h-full ${isDarkMode ? 'bg-[#121212]' : 'bg-slate-50'}`}>
      <div className="h-full max-w-2xl mx-auto p-4">
        {/* Back button with text */}
        <button
          onClick={() => navigate('/voice-notes')}
          className={`flex items-center mb-8 text-xs px-2 py-1 rounded-md transition-colors duration-200 ${
            isDarkMode 
              ? 'text-slate-400 hover:text-slate-200 hover:bg-[#1e1e1e] border border-[#333333]' 
              : 'bg-slate-100 hover:bg-slate-200 text-slate-600'
          }`}
        >
          <ArrowLeftIcon className="w-3 h-3 mr-1" />
          Back to Voice Notes
        </button>

        {/* Transcription box with title and content */}
        <div className={`rounded-lg mt-10 ${isDarkMode ? 'bg-[#121212]' : 'bg-slate-50'} relative`}>
          {/* Date and Title moved inside */}
          <div className="mb-4">
            <p className={`text-xs ${isDarkMode ? 'text-slate-500' : 'text-slate-500'}`}>
              {format(new Date(voiceNote?.createdAt), 'MMMM d, yyyy • h:mm a')}
            </p>
            {isEditing ? (
              <div className="flex items-center mt-2">
                <input
                  type="text"
                  value={editedTitle}
                  onChange={(e) => setEditedTitle(e.target.value)}
                  className={`text-lg font-bold w-full mr-2 px-2 py-1 rounded transition-colors duration-200 ${
                    isDarkMode 
                      ? 'bg-[#1a1a1a] text-slate-200 border-[#333333] placeholder-slate-500' 
                      : 'bg-slate-100 text-slate-900 border-slate-200'
                  } border`}
                />
                <button
                  onClick={handleTitleSave}
                  className={`p-1 rounded-full transition-colors duration-200 ${
                    isDarkMode ? 'hover:bg-[#1e1e1e] text-slate-400 hover:text-slate-200' : 'hover:bg-slate-200'
                  }`}
                >
                  <CheckIcon className="w-4 h-4 text-green-500" />
                </button>
                <button
                  onClick={() => {
                    setIsEditing(false);
                    setEditedTitle(voiceNote.title);
                  }}
                  className={`p-1 rounded-full transition-colors duration-200 ${
                    isDarkMode ? 'hover:bg-[#1e1e1e] text-slate-400 hover:text-slate-200' : 'hover:bg-slate-200'
                  }`}
                >
                  <XMarkIcon className="w-4 h-4 text-red-500" />
                </button>
              </div>
            ) : (
              <h1 className={`text-lg font-bold mt-2 ${isDarkMode ? 'text-slate-200' : 'text-slate-900'}`}>
                {voiceNote?.title}
              </h1>
            )}
          </div>

          {/* Transcription content */}
          {isEditingTranscription ? (
            <div className="relative">
              <textarea
                value={editedTranscription}
                onChange={(e) => setEditedTranscription(e.target.value)}
                className={`w-full p-2 text-xs border rounded-md transition-colors duration-200 ${
                  isDarkMode 
                    ? 'bg-[#1a1a1a] text-slate-200 border-[#333333] placeholder-slate-500' 
                    : 'bg-slate-50 text-slate-800 border-slate-200'
                } focus:outline-none focus:ring-1 focus:ring-blue-500/30`}
                rows="10"
              />
              <div className="flex justify-end mt-2 space-x-2">
                <button
                  onClick={() => {
                    setIsEditingTranscription(false);
                    setEditedTranscription(voiceNote.transcription);
                  }}
                  className={`px-3 py-1 rounded-md text-xs transition-colors duration-200 ${
                    isDarkMode 
                      ? 'text-slate-400 hover:text-slate-200 hover:bg-[#1e1e1e]' 
                      : 'text-slate-600 hover:bg-slate-200'
                  }`}
                >
                  Cancel
                </button>
                <button
                  onClick={handleSaveTranscription}
                  className={`px-3 py-1 rounded-md text-xs transition-colors duration-200 ${
                    isDarkMode 
                      ? 'bg-[#1a1a1a] hover:bg-[#1e1e1e] text-slate-200' 
                      : 'bg-slate-800 hover:bg-slate-700 text-slate-100'
                  }`}
                >
                  Save
                </button>
              </div>
            </div>
          ) : (
            <div className={`prose ${isDarkMode ? 'prose-invert' : ''} max-w-none text-xs`}>
              {splitIntoParagraphs(voiceNote?.transcription).map((paragraph, index) => (
                <p 
                  key={index} 
                  className={`text-xs mb-4 ${
                    isDarkMode ? 'text-slate-300' : 'text-slate-700'
                  }`}
                >
                  {paragraph}
                </p>
              ))}
            </div>
          )}

          {/* Add error message display */}
          {error && (
            <div className={`mt-4 p-2 rounded-md ${
              isDarkMode 
                ? 'bg-red-900/20 text-red-400 border border-red-900/30' 
                : 'bg-red-50 text-red-600'
            }`}>
              {error}
              <button
                onClick={() => setError(null)}
                className={`ml-2 text-xs hover:underline ${
                  isDarkMode ? 'text-red-400 hover:text-red-300' : 'text-red-600 hover:text-red-700'
                }`}
              >
                Dismiss
              </button>
            </div>
          )}

          {/* Render popup through portal */}
          {renderPopup()}

          {/* Generated Content List */}
          {generatedContents.length > 0 && (
            <div className={`mt-4 space-y-2`}>
              {generatedContents.map((item, index) => (
                <div 
                  key={item._id} 
                  className={`rounded-lg transition-colors duration-200 ${
                    isDarkMode
                      ? `${expandedItems.includes(index) ? 'bg-[#1a1a1a]' : 'hover:bg-[#1e1e1e]'}`
                      : `${expandedItems.includes(index) ? 'bg-gray-50' : 'hover:bg-gray-50'}`
                  }`}
                >
                  <div className="flex items-center py-2 px-3">
                    <button
                      onClick={() => toggleItemExpansion(index)}
                      className="flex items-center flex-grow min-w-0 text-left"
                    >
                      <ChevronRightIcon 
                        className={`w-3 h-3 mr-2 transition-transform ${
                          expandedItems.includes(index) ? 'transform rotate-90' : ''
                        } ${isDarkMode ? 'text-slate-500' : 'text-gray-500'}`}
                      />
                      <span className={`truncate text-xs ${
                        isDarkMode ? 'text-slate-400' : 'text-gray-600'
                      }`}>
                        {item.prompt}
                      </span>
                    </button>
                    <div className="flex items-center gap-1">
                      <button
                        onClick={() => handleCopyGeneratedContent(item.content, index)}
                        className={`p-1 rounded-full transition-colors duration-200 ${
                          isDarkMode ? 'text-slate-400 hover:text-slate-200 hover:bg-[#202020]' : 'hover:bg-gray-200'
                        }`}
                      >
                        {copiedContentIndex === index ? (
                          <ClipboardIconSolid className="w-3 h-3 text-green-500" />
                        ) : (
                          <ClipboardIcon className="w-3 h-3" />
                        )}
                      </button>
                      <button
                        onClick={() => handleDeleteContent(item._id)}
                        className={`p-1 rounded-full transition-colors duration-200 ${
                          isDarkMode ? 'text-slate-400 hover:text-slate-200 hover:bg-[#202020]' : 'hover:bg-gray-200'
                        }`}
                      >
                        <TrashIcon className="w-3 h-3 text-red-500" />
                      </button>
                    </div>
                  </div>
                  {expandedItems.includes(index) && (
                    <div className="px-6 py-3">
                      <pre 
                        className={`whitespace-pre-wrap text-xs font-sans ${
                          isDarkMode ? 'text-slate-300' : 'text-gray-600'
                        }`}
                      >
                        {item.content}
                      </pre>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}

          {/* Create button and menu section */}
          <div className="mt-6 flex items-center justify-between border-t pt-4 border-opacity-10 border-[#333333]">
            <button
              ref={(el) => {
                setPromptButtonRef(el);
                createButtonRef.current = el;
              }}
              onClick={() => setShowPromptForm(!showPromptForm)}
              className={`create-button inline-flex items-center px-3 py-1.5 rounded-md text-xs transition-colors duration-200 ${
                isDarkMode 
                  ? 'bg-[#1a1a1a] hover:bg-[#1e1e1e] text-slate-200 border border-[#333333]' 
                  : 'bg-slate-800 hover:bg-slate-700 text-slate-100'
              }`}
            >
              Create with your voice
            </button>
            
            <div className="relative flex items-center">
              <div className="relative">
                <TranscriptionMenu
                  isOpen={Boolean(menuAnchorEl)}
                  onToggle={setMenuAnchorEl}
                  onDelete={handleDeleteVoiceNote}
                  onEditTitle={handleEditTitle}
                  onEditTranscription={handleEditTranscription}
                  onRegenerateTitle={handleRegenerateTitle}
                  onDownloadAudio={handleDownloadAudio}
                  isDarkMode={isDarkMode}
                  anchorEl={menuAnchorEl}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoiceNotePage;