import { processPersonaText, processPersonaPDF, processPersonaURL } from '../api';

export const personaAIService = {
  async processText(text) {
    try {
      return await processPersonaText(text);
    } catch (error) {
      console.error('Error processing text:', error);
      throw new Error('Failed to process text: ' + (error.response?.data?.message || error.message));
    }
  },

  async processPDF(file) {
    try {
      return await processPersonaPDF(file);
    } catch (error) {
      console.error('Error processing PDF:', error);
      throw new Error('Failed to process PDF: ' + (error.response?.data?.message || error.message));
    }
  },

  async processURL(url) {
    try {
      return await processPersonaURL(url);
    } catch (error) {
      console.error('Error processing URL:', error);
      throw new Error('Failed to process URL: ' + (error.response?.data?.message || error.message));
    }
  }
}; 