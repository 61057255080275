// Create a new file for device configurations
export const DEVICE_CONFIGS = {
  mobile: {
    width: 375,
    maxColumns: 3,
    minWidth: 500,
    horizontalGap: 100,
    verticalGap: 16,
    containerPadding: 16
  },
  tablet: {
    width: 400,
    maxColumns: 4,
    minWidth: 1024,
    horizontalGap: 80,
    verticalGap: 24,
    containerPadding: 24
  },
  desktop: {
    width: 471,
    maxColumns: 5,
    minWidth: 1440,
    horizontalGap: 100,
    verticalGap: 32,
    containerPadding: 32
  }
}; 