import React from 'react';
import { motion } from 'framer-motion';
import { useTheme } from '../../../context/ThemeContext';
import useScreenSize from '../../../hooks/useScreenSize';
import { 
  DocumentTextIcon,
  CloudArrowUpIcon,
  CommandLineIcon,
  RectangleGroupIcon,
  SparklesIcon,
  UserGroupIcon
} from '@heroicons/react/24/outline';

const ContextBankSection = () => {
  const { isDarkMode } = useTheme();
  const { isMobile } = useScreenSize();
  
  const sources = [
    {
      icon: DocumentTextIcon,
      title: "Capture Ideas",
      description: "Capture your thoughts through voice notes, text notes, or file uploads. Your ideas are instantly transcribed and organized.",
      features: ["Voice recording", "Text notes", "File uploads"]
    },
    {
      icon: CloudArrowUpIcon,
      title: "Smart Knowledge Base",
      description: "All your notes and files become part of your personal knowledge base, making them instantly accessible for content creation.",
      features: ["Instant search", "Auto-tagging", "Content linking"]
    },
    {
      icon: CommandLineIcon,
      title: "AI-Powered Creation",
      description: "Let AI analyze your knowledge base to generate fresh content ideas, expand on your notes, and craft engaging posts.",
      features: ["Note expansion", "Content suggestions", "Style matching"]
    },
    {
      icon: RectangleGroupIcon,
      title: "Content Workspace",
      description: "Access your entire knowledge base while writing. Reference past ideas, combine concepts, and maintain brand consistency.",
      features: ["Reference panel", "Brand guidelines", "Content history"]
    },
    {
      icon: SparklesIcon,
      title: "Content Styles",
      description: "Choose from multiple AI-powered content styles including opinion posts, how-to guides, and storytelling formats for maximum engagement.",
      features: ["Opinion posts", "How-to guides", "Story formats"]
    },
    {
      icon: UserGroupIcon,
      title: "Audience Targeting",
      description: "Tailor your content to specific audience segments with AI that understands your target demographics, interests, and engagement patterns.",
      features: ["Persona matching", "Tone adaptation", "Engagement optimization"]
    }
  ];

  const MobileContextBank = () => (
    <div 
      id="context-bank-section"
      className={`py-12 relative overflow-hidden ${
        isDarkMode ? 'bg-[#121212]/0' : 'bg-white/0'
      }`}
    >
      <div className="px-5">
        {/* Section Tag and Header */}
        <div className="mb-8">
          <span className={`
            inline-block px-3 py-1.5 text-xs font-medium 
            uppercase tracking-wider rounded-full
            ${isDarkMode 
              ? 'bg-slate-800/80 text-slate-300 border border-slate-700/50' 
              : 'bg-white text-slate-600 border border-slate-200'
            }
          `}>
            Your Content Hub
          </span>
        </div>

        <h2 className={`
          text-2xl font-semibold leading-tight mb-3
          ${isDarkMode ? 'text-white' : 'text-slate-900'}
        `}>
          Capture Ideas,{' '}
          <span className={`block mt-1 ${
            isDarkMode ? 'text-white/60' : 'text-slate-900/60'
          }`}>
            Create Content
          </span>
        </h2>

        <p className={`
          text-sm mb-10 
          ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
        `}>
          Turn your thoughts into engaging content with our AI-powered workspace
        </p>

        {/* Cards Grid */}
        <div className="space-y-5">
          {sources.map((source, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, delay: index * 0.1 }}
              className={`
                relative p-6 rounded-xl
                backdrop-blur-xl backdrop-saturate-150
                ${isDarkMode 
                  ? 'bg-[#1a1a1a]/90 border border-white/10' 
                  : 'bg-white/90 border border-black/5'
                }
              `}
            >
              <div className="flex flex-col">
                {/* Header with Icon and Title */}
                <div className="flex items-center gap-3 mb-3">
                  <div className={`
                    w-10 h-10 rounded-xl
                    flex items-center justify-center flex-shrink-0
                    ${isDarkMode 
                      ? 'bg-blue-900/20 text-blue-400 border border-blue-500/20' 
                      : 'bg-blue-50 text-blue-600 border border-blue-100'
                    }
                  `}>
                    <source.icon className="h-5 w-5" />
                  </div>
                  <h3 className={`
                    text-lg font-semibold
                    ${isDarkMode ? 'text-white' : 'text-slate-900'}
                  `}>
                    {source.title}
                  </h3>
                </div>

                {/* Content Container */}
                <div className="flex-1 min-w-0">
                  <p className={`
                    text-sm mb-4 leading-relaxed
                    ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
                  `}>
                    {source.description}
                  </p>
                  <div className="flex flex-wrap gap-2">
                    {source.features.map((feature, featureIndex) => (
                      <span
                        key={featureIndex}
                        className={`
                          inline-flex items-center px-3 py-1.5 
                          rounded-full text-xs font-medium
                          ${isDarkMode
                            ? 'bg-slate-800/80 text-slate-300 border border-slate-700/50'
                            : 'bg-slate-50 text-slate-600 border border-slate-200/50'
                          }
                        `}
                      >
                        <span className={`
                          mr-1.5 w-1 h-1 rounded-full
                          ${isDarkMode ? 'bg-slate-400' : 'bg-slate-500'}
                        `} />
                        {feature}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );

  const DesktopContextBank = () => (
    <div 
      id="context-bank-section"
      className={`py-24 sm:py-32 relative overflow-hidden ${
        isDarkMode ? 'bg-[#121212]/0' : 'bg-white/0'
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Section Header - Updated with flex layout */}
        <div className="flex items-center justify-between mb-12">
          <div className="max-w-xl">
            <h2 className={`
              text-3xl sm:text-4xl font-semibold tracking-tight leading-tight sm:leading-normal
              ${isDarkMode ? 'text-white' : 'text-slate-900'}
            `}>
              Import Your Content,{' '}
              <span className={`
                ${isDarkMode 
                  ? 'text-white/60' 
                  : 'text-slate-900/60'
                }
              `}>
                Train Your AI
              </span>
            </h2>
          </div>
          
          <span className={`inline-block px-3 py-1.5 text-xs font-medium uppercase tracking-wider rounded-full ${
            isDarkMode 
              ? 'bg-slate-800/80 text-slate-300 border border-slate-700/50' 
              : 'bg-white text-slate-600 border border-slate-200'
          }`}>
            Context Bank
          </span>
        </div>

        {/* Updated grid layout for 6 cards */}
        <div className="grid grid-cols-3 gap-4">
          {sources.map((source, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, delay: index * 0.1 }}
              className={`
                relative p-8 rounded-xl
                backdrop-blur-xl backdrop-saturate-150
                ${isDarkMode 
                  ? 'bg-[#1a1a1a]/90 border border-white/10' 
                  : 'bg-white/90 border border-black/5'
                }
              `}
            >
              <div className="space-y-6">
                <div className={`
                  inline-flex p-2 rounded-lg
                  ${isDarkMode 
                    ? 'bg-blue-900/20 text-blue-400 border border-blue-500/20' 
                    : 'bg-blue-50 text-blue-600 border border-blue-100'
                  }
                `}>
                  <source.icon className="h-6 w-6" />
                </div>
                
                <div>
                  <h3 className={`text-xl font-semibold mb-3 ${
                    isDarkMode ? 'text-white' : 'text-slate-900'
                  }`}>
                    {source.title}
                  </h3>
                  <p className={`text-sm mb-6 ${
                    isDarkMode ? 'text-slate-400' : 'text-slate-600'
                  }`}>
                    {source.description}
                  </p>
                </div>

                <div className="flex flex-wrap gap-2">
                  {source.features.map((feature, featureIndex) => (
                    <span
                      key={featureIndex}
                      className={`
                        inline-block px-3 py-1 rounded-full text-xs font-medium
                        ${isDarkMode
                          ? 'bg-slate-800/80 text-slate-300 border border-slate-700/50'
                          : 'bg-slate-50 text-slate-600 border border-slate-200/50'
                        }
                      `}
                    >
                      {feature}
                    </span>
                  ))}
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );

  return isMobile ? <MobileContextBank /> : <DesktopContextBank />;
};

export default ContextBankSection; 