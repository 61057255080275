import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '../context/ThemeContext';

const SUGGESTED_PROMPTS = [
  {
    label: "✨ Summarize",
    prompt: "Create a concise summary of the main points from this transcription"
  },
  {
    label: "📝 Proofread & Edit",
    prompt: "Proofread, edit, and organize these notes into a clear and structured format"
  },
  {
    label: "🐦 Write Tweet",
    prompt: "Create an engaging tweet from the key insights in this transcription"
  },
  {
    label: "💼 Write LinkedIn Post",
    prompt: "Transform this transcription into an insightful LinkedIn post"
  },
  {
    label: "📋 Extract Action Items",
    prompt: "Extract and list all action items and tasks mentioned in this transcription"
  }
];

const GenerateContentPopup = ({ 
  buttonRect, 
  onClose, 
  onGenerate, 
  isGenerating 
}) => {
  const { isDarkMode } = useTheme();
  const [prompt, setPrompt] = useState('');
  const popupRef = useRef(null);
  const maxPromptLength = 280;
  
  const getPopupStyles = () => {
    if (!buttonRect) return {};

    const popupHeight = 320;
    const spacing = 4;
    const windowHeight = window.innerHeight;
    const spaceBelow = windowHeight - buttonRect.bottom;
    const showAbove = spaceBelow < popupHeight && buttonRect.top > spaceBelow;

    const horizontalPosition = Math.max(8, Math.min(
      buttonRect.left, 
      window.innerWidth - (window.innerWidth < 640 ? 320 : 428)
    ));

    return {
      position: 'absolute',
      left: `${horizontalPosition}px`,
      ...(showAbove 
        ? { bottom: `${window.innerHeight - buttonRect.top + spacing}px` }
        : { top: `${buttonRect.bottom + spacing}px` }
      ),
      width: 'min(calc(100vw - 16px), 320px)',
      maxWidth: window.innerWidth < 640 ? '320px' : '420px',
    };
  };

  const handleGenerate = () => {
    if (!prompt.trim() || prompt.length > maxPromptLength) return;
    onGenerate(prompt);
  };

  return (
    <div 
      ref={popupRef}
      className="prompt-popup fixed z-[9999]"
      style={getPopupStyles()}
    >
      <div 
        className={`
          ${isDarkMode ? 'bg-[#151515] border-[#333333]' : 'bg-white border-gray-200'} 
          border rounded-lg shadow-lg overflow-hidden
          ${getPopupStyles().bottom ? 'animate-slide-up' : 'animate-slide-down'}
        `}
      >
        {/* Input Area */}
        <div className="p-3 space-y-2">
          <div className="relative">
            <textarea
              value={prompt}
              onChange={(e) => setPrompt(e.target.value.slice(0, maxPromptLength))}
              placeholder="What would you like me to do with this transcription?"
              className={`
                w-full px-3 py-2 text-xs
                rounded-md border
                resize-none overflow-hidden
                placeholder:text-muted-foreground
                focus-visible:outline-none
                transition-all duration-200
                ${isDarkMode 
                  ? 'bg-[#1a1a1a] border-[#333333] text-slate-200 placeholder-slate-500 focus-visible:border-[#333333]' 
                  : 'bg-gray-50/50 border-gray-200 text-gray-700 placeholder-gray-400 focus-visible:border-gray-300'
                }
                focus-visible:ring-[0.5px]
                focus-visible:ring-opacity-40
                ${isDarkMode
                  ? 'focus-visible:ring-blue-500/30'
                  : 'focus-visible:ring-gray-300'
                }
              `}
              rows="2"
              style={{
                minHeight: '60px',
                maxHeight: '120px'
              }}
            />
            
            {/* Character count */}
            <div 
              className={`
                absolute right-2 bottom-2
                text-[10px] tabular-nums
                ${isDarkMode ? 'text-slate-500' : 'text-gray-400'}
              `}
            >
              {prompt.length}/{maxPromptLength}
            </div>
          </div>

          {/* Suggested Prompts */}
          <div className="flex flex-wrap gap-1.5">
            {SUGGESTED_PROMPTS.map((item, index) => (
              <button
                key={index}
                onClick={() => setPrompt(item.prompt)}
                className={`
                  text-[10px] px-2 py-1
                  rounded-md border
                  transition-all duration-200
                  ${isDarkMode
                    ? prompt === item.prompt
                      ? 'bg-[#1a1a1a] border-[#333333] text-slate-200'
                      : 'bg-[#151515] border-[#333333] text-slate-400 hover:bg-[#1e1e1e] hover:text-slate-300'
                    : prompt === item.prompt
                      ? 'bg-gray-100 border-gray-200 text-gray-700'
                      : 'bg-gray-50/50 border-gray-200/50 text-gray-500 hover:bg-gray-100/80 hover:text-gray-600'
                  }
                `}
              >
                <span>{item.label.split(' ')[0]}</span>
                <span className="ml-1">{item.label.split(' ').slice(1).join(' ')}</span>
              </button>
            ))}
          </div>
        </div>

        {/* Action Buttons */}
        <div className={`
          p-3 border-t
          ${isDarkMode ? 'border-[#333333]' : 'border-gray-200'}
        `}>
          <div className="flex justify-end gap-2">
            <button
              onClick={onClose}
              className={`
                px-3 py-1.5 text-xs
                rounded-md border
                transition-all duration-200
                ${isDarkMode
                  ? 'border-[#333333] text-slate-400 hover:bg-[#1e1e1e]'
                  : 'border-gray-200 text-gray-500 hover:bg-gray-50'
                }
              `}
            >
              Cancel
            </button>
            <button
              onClick={handleGenerate}
              disabled={isGenerating || !prompt.trim() || prompt.length > maxPromptLength}
              className={`
                px-3 py-1.5 text-xs
                rounded-md
                transition-all duration-200
                ${isDarkMode
                  ? isGenerating || !prompt.trim() || prompt.length > maxPromptLength
                    ? 'bg-[#1a1a1a] text-slate-500 cursor-not-allowed'
                    : 'bg-[#1a1a1a] text-slate-200 hover:bg-[#1e1e1e]'
                  : isGenerating || !prompt.trim() || prompt.length > maxPromptLength
                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                    : 'bg-gray-900 text-gray-50 hover:bg-gray-800'
                }
              `}
            >
              {isGenerating ? 'Generating...' : 'Generate'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenerateContentPopup;