import React, { useState, useRef, useEffect, useCallback } from 'react';
import { EllipsisVerticalIcon, PencilIcon, CheckIcon, XMarkIcon, PlayIcon, PauseIcon, TrashIcon, ChevronDownIcon, ChevronUpIcon, ClipboardIcon, TagIcon, SparklesIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { ClipboardIcon as ClipboardIconSolid } from '@heroicons/react/24/solid';
import { updateTranscriptionTitle, regenerateTranscriptionTitle, getSignedUrl, deleteVoiceNote, generateContent, saveGeneratedContent, deleteGeneratedContent } from '../api';
import { format } from 'date-fns';
import TagMenu from './TagMenu';
import { useMediaQuery } from 'react-responsive'; // Add this import
import TranscriptionMenu from './TranscriptionMenu';
import ReactMarkdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import { ExpandIcon } from './icons/ExpandCollapseIcon';

// Update the Spinner component to be more visually appealing
const Spinner = ({ className }) => (
  <svg className={`animate-spin ${className}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="3"></circle>
    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
  </svg>
);

// Add error message component for consistent error display
const ErrorMessage = ({ message, onRetry, isDarkMode }) => (
  <div 
    role="alert"
    aria-live="polite"
    className={`
      flex items-center justify-between p-2 rounded-md mt-2
      ${isDarkMode ? 'bg-red-900/20' : 'bg-red-50'}
    `}
  >
    <span className={`text-xs ${isDarkMode ? 'text-red-400' : 'text-red-600'}`}>
      {message}
    </span>
    {onRetry && (
      <button
        onClick={onRetry}
        className={`text-xs ml-2 font-medium ${
          isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-500'
        }`}
      >
        Retry
      </button>
    )}
  </div>
);

// Add success message component
const SuccessMessage = ({ message, isDarkMode }) => (
  <div 
    role="status"
    aria-live="polite"
    className={`flex items-center p-2 rounded-md mt-2 ${
      isDarkMode ? 'bg-green-900/20' : 'bg-green-50'
    }`}
  >
    <CheckIcon className={`w-4 h-4 mr-2 ${
      isDarkMode ? 'text-green-400' : 'text-green-500'
    }`} />
    <span className={`text-xs ${
      isDarkMode ? 'text-green-400' : 'text-green-600'
    }`}>
      {message}
    </span>
  </div>
);

// Add these suggested prompts near the top of the file with other constants
const SUGGESTED_PROMPTS = [
  {
    label: "✨ Summarize",
    prompt: "Create a concise summary of the main points from this transcription"
  },
  {
    label: "📝 Proofread & Edit",
    prompt: "Proofread, edit, and organize these notes into a clear and structured format"
  },
  {
    label: "🐦 Write Tweet",
    prompt: "Create an engaging tweet from the key insights in this transcription"
  },
  {
    label: "💼 Write LinkedIn Post",
    prompt: "Transform this transcription into an insightful LinkedIn post"
  },
  {
    label: "📋 Extract Action Items",
    prompt: "Extract and list all action items and tasks mentioned in this transcription"
  }
];

// Add this near the top of the file with other style constants
export const markdownStyles = {
  light: {
    p: 'text-gray-700 mb-2',
    h1: 'text-xl font-bold text-gray-900 mb-2',
    h2: 'text-lg font-bold text-gray-900 mb-2',
    h3: 'text-base font-bold text-gray-900 mb-2',
    ul: 'list-disc pl-4 mb-2',
    ol: 'list-decimal pl-4 mb-2',
    li: 'text-gray-700 mb-1',
    blockquote: 'border-l-4 border-gray-200 pl-4 italic text-gray-600',
    code: 'bg-gray-100 rounded px-1 font-mono text-sm text-gray-800',
    pre: 'bg-gray-100 rounded p-2 mb-2 overflow-x-auto',
  },
  dark: {
    p: 'text-gray-300 mb-2',
    h1: 'text-lg font-bold text-gray-100 mb-2',
    h2: 'text-base font-bold text-gray-100 mb-2',
    h3: 'text-sm font-bold text-gray-100 mb-2',
    ul: 'list-disc pl-4 mb-2',
    ol: 'list-decimal pl-4 mb-2',
    li: 'text-gray-300 mb-1',
    blockquote: 'border-l-4 border-gray-600 pl-4 italic text-gray-400',
    code: 'bg-gray-700 rounded px-1 font-mono text-sm text-gray-200',
    pre: 'bg-gray-700 rounded p-2 mb-2 overflow-x-auto',
  }
};

// Update the formatAIResponse function to better handle original formatting
const formatAIResponse = (content) => {
  // If content is not a string or is empty, return it as is
  if (typeof content !== 'string' || !content) {
    return content;
  }

  // Handle arrays of content (React children)
  if (Array.isArray(content)) {
    return content.map(item => 
      typeof item === 'string' ? formatAIResponse(item) : item
    );
  }

  // Split content into lines
  const lines = content.split('\n');
  
  // Process each line
  const formattedLines = lines.map(line => {
    const trimmedLine = line.trim();
    
    // Check for existing bullet points or numbered lists
    if (trimmedLine.match(/^[-•]\s/)) {
      // Keep existing bullet points but standardize the spacing
      return `• ${trimmedLine.substring(2).trim()}`;
    } else if (trimmedLine.match(/^\d+\.\s/)) {
      // Keep the original number but standardize format
      const number = trimmedLine.match(/^\d+/)[0];
      return `${number}. ${trimmedLine.substring(trimmedLine.indexOf('.') + 1).trim()}`;
    } else if (trimmedLine.match(/^[#→◆]\s/)) {
      // Keep existing special markers
      return trimmedLine;
    } else if (trimmedLine.match(/^[-*]\s/)) {
      // Convert markdown style bullets to bullet points
      return `• ${trimmedLine.substring(2).trim()}`;
    }
    
    return trimmedLine;
  });

  // Rejoin the lines
  return formattedLines.join('\n');
};

// Update the GeneratedContentSection component to use the new formatting
const GeneratedContentSection = ({ item, index, isDarkMode, expandedItems, onToggleExpand, onCopy, onDelete, copiedContentIndex }) => (
  <div 
    className={`rounded-md transition-all duration-200 ${
      isDarkMode
        ? `${expandedItems.includes(index) ? 'bg-[#1a1a1a]' : 'hover:bg-[#1a1a1a]/50'}`
        : `${expandedItems.includes(index) ? 'bg-gray-50' : 'hover:bg-gray-50'}`
    }`}
  >
    <div className="flex items-center py-1.5 px-2">
      <button
        onClick={() => onToggleExpand(index)}
        className="flex items-center flex-grow min-w-0 text-left"
      >
        <ChevronRightIcon 
          className={`transition-transform ${
            expandedItems.includes(index) ? 'transform rotate-90' : ''
          } ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}
          w-3 h-3 mr-1.5`}
        />
        <span className={`truncate text-xs ${
          isDarkMode ? 'text-gray-300' : 'text-gray-600'
        }`}>
          {item.prompt}
        </span>
      </button>
      <div className="flex items-center gap-0.5">
        <button
          onClick={() => onCopy(item.content, index)}
          className={`p-1 rounded-full ${
            isDarkMode ? 'hover:bg-gray-600' : 'hover:bg-gray-200'
          }`}
        >
          {copiedContentIndex === index ? (
            <ClipboardIconSolid className="w-3 h-3 text-green-500" />
          ) : (
            <ClipboardIcon className="w-3 h-3" />
          )}
        </button>
        <button
          onClick={() => onDelete(item._id)}
          className={`p-1 rounded-full ${
            isDarkMode ? 'hover:bg-gray-600' : 'hover:bg-gray-200'
          }`}
        >
          <TrashIcon className="w-3 h-3 text-red-500" />
        </button>
      </div>
    </div>
    {expandedItems.includes(index) && (
      <div className="px-4 py-2">
        <ReactMarkdown
          components={{
            p: ({node, children}) => (
              <p 
                className={`text-xs mb-1.5 whitespace-pre-wrap ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}
              >
                {typeof children === 'string' ? formatAIResponse(children) : children}
              </p>
            ),
            ul: ({node, children}) => (
              <ul className="space-y-1 mb-2">
                {React.Children.map(children, child => 
                  React.isValidElement(child) ? React.cloneElement(child, {
                    className: `text-xs ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`
                  }) : child
                )}
              </ul>
            ),
            li: ({node, children}) => {
              const formattedContent = typeof children === 'string' 
                ? formatAIResponse(children)
                : Array.isArray(children)
                ? children.map(child => 
                    typeof child === 'string' ? formatAIResponse(child) : child
                  )
                : children;

              return (
                <li 
                  className={`text-xs leading-relaxed ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}
                >
                  {formattedContent}
                </li>
              );
            },
            // Keep other component renderers the same
            h1: ({node, children}) => (
              <h1 className={`text-sm font-bold mb-1.5 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                {children}
              </h1>
            ),
            h2: ({node, children}) => (
              <h2 className={`text-xs font-bold mb-1.5 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                {children}
              </h2>
            ),
            h3: ({node, children}) => (
              <h3 className={`text-xs font-bold mb-1.5 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                {children}
              </h3>
            ),
            blockquote: ({node, children}) => (
              <blockquote 
                className={`border-l-2 pl-2 my-1 ${
                  isDarkMode ? 'border-gray-600 text-gray-400' : 'border-gray-300 text-gray-600'
                }`}
              >
                {children}
              </blockquote>
            ),
            code: ({node, children}) => (
              <code 
                className={`px-1 rounded text-xs font-mono ${
                  isDarkMode ? 'bg-gray-800 text-gray-300' : 'bg-gray-100 text-gray-800'
                }`}
              >
                {children}
              </code>
            ),
          }}
        >
          {item.content}
        </ReactMarkdown>
      </div>
    )}
  </div>
);

// Update the prompt form section
const PromptForm = ({ isDarkMode, prompt, setPrompt, onGenerate, onClose, isGenerating }) => (
  <div className="space-y-3" data-prompt-form="true">
    {/* Input Area */}
    <div className="relative">
      <textarea
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        placeholder="What would you like me to do with this transcription?"
        className={`
          w-full px-3 py-2 text-xs
          rounded-md border
          resize-none overflow-hidden
          placeholder:text-muted-foreground
          focus-visible:outline-none
          transition-all duration-200
          ${isDarkMode 
            ? 'bg-[#1a1a1a] border-[#333333] text-slate-200 placeholder-slate-500' 
            : 'bg-gray-50/50 border-gray-200 text-gray-700 placeholder-gray-400'}
          focus-visible:ring-[0.5px]
          focus-visible:ring-opacity-40
          ${isDarkMode
            ? 'focus-visible:ring-slate-500'
            : 'focus-visible:ring-gray-300'
          }
        `}
        rows="2"
        style={{
          minHeight: '60px',
          maxHeight: '120px'
        }}
      />
      
      {/* Character count */}
      <div 
        className={`
          absolute right-2 bottom-2
          text-[10px] tabular-nums
          ${isDarkMode ? 'text-gray-500' : 'text-gray-400'}
        `}
      >
        {prompt.length}/280
      </div>
    </div>

    {/* Suggested Prompts */}
    <div className="flex flex-wrap gap-1.5">
      {SUGGESTED_PROMPTS.map((item, index) => (
        <button
          key={index}
          onClick={() => setPrompt(item.prompt)}
          className={`
            text-[10px] px-2 py-1
            rounded-md border
            transition-all duration-200
            ${isDarkMode
              ? prompt === item.prompt
                ? 'bg-gray-700/80 border-gray-600 text-gray-200'
                : 'bg-gray-800/30 border-gray-700/50 text-gray-400 hover:bg-gray-700/50 hover:text-gray-300'
              : prompt === item.prompt
                ? 'bg-gray-100 border-gray-200 text-gray-700'
                : 'bg-gray-50/50 border-gray-200/50 text-gray-500 hover:bg-gray-100/80 hover:text-gray-600'
            }
          `}
        >
          {item.label}
        </button>
      ))}
    </div>

    {/* Action Buttons */}
    <div className="flex justify-end gap-2">
      <button
        onClick={onClose}
        className={`
          px-3 py-1.5 text-xs
          rounded-md border
          transition-all duration-200
          ${isDarkMode
            ? 'border-gray-700 text-gray-400 hover:bg-gray-800/80'
            : 'border-gray-200 text-gray-500 hover:bg-gray-50'
          }
        `}
      >
        Cancel
      </button>
      <button
        onClick={() => onGenerate(prompt)}
        disabled={isGenerating || !prompt.trim() || prompt.length > 280}
        className={`
          px-3 py-1.5 text-xs
          rounded-md
          transition-all duration-200
          ${isDarkMode
            ? isGenerating || !prompt.trim() || prompt.length > 280
              ? 'bg-gray-800 text-gray-500 cursor-not-allowed'
              : 'bg-gray-800 text-gray-200 hover:bg-gray-700'
            : isGenerating || !prompt.trim() || prompt.length > 280
              ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
              : 'bg-gray-900 text-gray-50 hover:bg-gray-800'
          }
        `}
      >
        {isGenerating ? (
          <span className="flex items-center gap-2">
            <Spinner className="w-3 h-3" />
            Generating...
          </span>
        ) : (
          'Generate'
        )}
      </button>
    </div>
  </div>
);

const TranscriptionGridItem = ({ 
  transcription: initialTranscription, 
  title, 
  isDarkMode, 
  id, 
  onTitleUpdate, 
  audioUrl, 
  createdAt, 
  onDelete, 
  onTranscriptionUpdate, 
  tags = [], 
  onAddTag, 
  onRemoveTag, 
  allTags = [], 
  generatedContents = [] 
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);
  const [isRegenerating, setIsRegenerating] = useState(false);
  const [regenerateError, setRegenerateError] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [signedAudioUrl, setSignedAudioUrl] = useState(audioUrl);  // Initialize with the provided audioUrl
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const inputRef = useRef(null);
  const menuRef = useRef(null);
  const audioRef = useRef(null);
  const [isEditingTranscription, setIsEditingTranscription] = useState(false);
  const [currentTranscription, setCurrentTranscription] = useState(initialTranscription);
  const [editedTranscription, setEditedTranscription] = useState(initialTranscription.trim());
  const transcriptionInputRef = useRef(null);
  const [textareaHeight, setTextareaHeight] = useState('auto');
  const [wordCount, setWordCount] = useState(0);
  const [isTranscriptionEdited, setIsTranscriptionEdited] = useState(false);
  const [showCopyAnimation, setShowCopyAnimation] = useState(false);
  const [isTagMenuOpen, setIsTagMenuOpen] = useState(false);
  const tagMenuRef = useRef(null);
  const [showRightFade, setShowRightFade] = useState(false);
  const tagsContainerRef = useRef(null);
  const [isAudioLoading, setIsAudioLoading] = useState(false);
  const [isUrlReady, setIsUrlReady] = useState(false);
  const [playAttempts, setPlayAttempts] = useState(0);
  const [audioLoadAttempts, setAudioLoadAttempts] = useState(0);
  const maxLoadAttempts = 3;
  const [audioError, setAudioError] = useState(null);
  // Add this near the top of the component with other state declarations
  const [networkError, setNetworkError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 3;
  const [isGenerating, setIsGenerating] = useState(false);
  const [showPromptForm, setShowPromptForm] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [generatedContentList, setGeneratedContentList] = useState(generatedContents);
  const [copiedContentIndex, setCopiedContentIndex] = useState(null);
  const [expandedContentIndex, setExpandedContentIndex] = useState(null);
  const [expandedItems, setExpandedItems] = useState([]);
  const maxPromptLength = 280; // You can adjust this value as needed
  const isMobile = useMediaQuery({ maxWidth: 640 }); // Add this hook
  // Add this with other state declarations at the top of the component
  const [tagButtonElement, setTagButtonElement] = useState(null);
  const [menuButtonElement, setMenuButtonElement] = useState(null);
  const navigate = useNavigate();

  // Add this near the top of the component with other state declarations
  const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    typeof navigator !== 'undefined' ? navigator.userAgent : ''
  );

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleTitleChange = (e) => {
    setEditedTitle(e.target.value);
  };

  const handleTitleSave = async () => {
    try {
      if (!id) {
        console.error('Transcription ID is undefined', { id, title, transcription: currentTranscription });
        return;
      }
      console.log('Updating transcription with ID:', id);
      const updatedTranscription = await updateTranscriptionTitle(id, editedTitle);
      setIsEditing(false);
      onTitleUpdate(id, updatedTranscription.title);
    } catch (error) {
      console.error('Error saving title:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const handleRegenerateTitle = async () => {
    setIsRegenerating(true);
    setRegenerateError(null);
    try {
      let newTitle = await regenerateTranscriptionTitle(id);
      // Remove any remaining quotation marks
      newTitle = newTitle.replace(/["']/g, '');
      setEditedTitle(newTitle);
      onTitleUpdate(id, newTitle);
    } catch (error) {
      console.error('Error regenerating title:', error);
      setRegenerateError('Trouble generating! Please try again.');
    } finally {
      setIsRegenerating(false);
    }
  };

  const handleTitleCancel = () => {
    setEditedTitle(title);
    setIsEditing(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleTitleSave();
    } else if (e.key === 'Escape') {
      handleTitleCancel();
    }
  };

  const loadAudio = useCallback(async () => {
    if (id && (!signedAudioUrl || retryCount > 0)) {
      try {
        setIsAudioLoading(true);
        setNetworkError(null);
        setAudioError(null);
        console.log('Fetching signed URL for:', id);
        const url = await getSignedUrl(id);
        console.log('Received signed URL:', url);
        
        setSignedAudioUrl(url);
        setIsUrlReady(true);
        
        // Pre-load the audio
        if (audioRef.current) {
          audioRef.current.load();
        }
        
        setRetryCount(0);
      } catch (error) {
        console.error('Error loading audio:', error);
        setNetworkError("Failed to load audio. Tap to retry.");
        if (retryCount < maxRetries) {
          setRetryCount(prev => prev + 1);
        }
      } finally {
        setIsAudioLoading(false);
      }
    }
  }, [id, signedAudioUrl, retryCount, maxRetries]);

  useEffect(() => {
    loadAudio();
  }, [loadAudio]);

  const handlePlayPause = async () => {
    if (!audioRef.current) {
      console.error('Audio ref not available');
      return;
    }

    try {
      if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        setIsAudioLoading(true);
        
        // If URL is not ready, load it first
        if (!isUrlReady) {
          await loadAudio();
        }
        
        // Reset audio source if there was an error
        if (audioError) {
          audioRef.current.src = signedAudioUrl;
          audioRef.current.load();
          setAudioError(null);
        }

        try {
          const playPromise = audioRef.current.play();
          if (playPromise !== undefined) {
            await playPromise;
            setIsPlaying(true);
            setIsAudioLoading(false);
          }
        } catch (error) {
          console.error('Play failed:', error);
          if (playAttempts < 3) {
            setPlayAttempts(prev => prev + 1);
            audioRef.current.load();
            await audioRef.current.play();
            setIsPlaying(true);
          } else {
            setAudioError('Unable to play audio. Please try again.');
          }
        }
      }
    } catch (error) {
      console.error('Playback error:', error);
      setAudioError('Playback failed. Please try again.');
    } finally {
      setIsAudioLoading(false);
    }
  };

  const handleAudioEnded = () => {
    setIsPlaying(false);
  };

  const handleDownloadAudio = () => {
    if (downloadUrl) {
      window.open(downloadUrl, '_blank');
    }
  };

  useEffect(() => {
    const fetchSignedUrls = async () => {
      if (id) {
        try {
          const playUrl = await getSignedUrl(id);
          setSignedAudioUrl(playUrl);
          const downloadUrl = await getSignedUrl(id, true);
          setDownloadUrl(downloadUrl);
        } catch (error) {
          console.error('Error fetching signed URLs:', error);
        }
      }
    };

    fetchSignedUrls();
  }, [id]);

  const handleDelete = async () => {
    try {
      await deleteVoiceNote(id);
      onDelete(id);
    } catch (error) {
      console.error('Error deleting voice note:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const handleCopyTranscription = async () => {
    try {
      await navigator.clipboard.writeText(currentTranscription);
      setIsCopied(true);
      setShowCopyAnimation(true);
      setTimeout(() => {
        setShowCopyAnimation(false);
        setIsCopied(false);
      }, 2000); // Increased duration for better visibility
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  // Format the date
  const formattedDate = format(new Date(createdAt), 'MMM dd');
  const formattedTime = format(new Date(createdAt), 'h:mm a');

  const formatTranscription = (text) => {
    const paragraphs = text.split(/\n+/).filter(p => p.trim() !== '');

    return paragraphs.map((paragraph, index) => {
      const speakerMatch = paragraph.match(/^([\w\s]+):\s*(.*)/);
      if (speakerMatch) {
        const [, speaker, content] = speakerMatch;
        return (
          <p key={index} className="mb-1">
            <span className={`font-medium ${isDarkMode ? 'text-blue-300' : 'text-blue-600'}`}>
              {speaker}:
            </span>{' '}
            <span>{content}</span>
          </p>
        );
      }
      return <p key={index} className="mb-1">{paragraph}</p>;
    });
  };

  const truncateTranscription = (text) => {
    // Split into lines and take only first 2 lines
    const lines = text.split('\n').slice(0, 2);
    let truncated = lines.join('\n');
    
    // If there were more lines, add ellipsis
    if (text.split('\n').length > 2) {
      truncated += '...';
    }
    
    return truncated;
  };

  useEffect(() => {
    if (isEditingTranscription && transcriptionInputRef.current) {
      transcriptionInputRef.current.focus();
      // Place the cursor at the end of the text
      transcriptionInputRef.current.setSelectionRange(
        editedTranscription.length,
        editedTranscription.length
      );
      adjustTextareaHeight(transcriptionInputRef.current);
    }
  }, [isEditingTranscription, editedTranscription]);

  const countWords = (text) => {
    return text.trim().split(/\s+/).filter(word => word.length > 0).length;
  };

  useEffect(() => {
    setWordCount(countWords(currentTranscription));
  }, [currentTranscription]);

  const handleTranscriptionChange = (e) => {
    const newText = e.target.value;
    setEditedTranscription(newText);
    adjustTextareaHeight(e.target);
    setWordCount(countWords(newText));
    setIsTranscriptionEdited(newText !== currentTranscription);
  };

  const adjustTextareaHeight = (element) => {
    element.style.height = 'auto';
    element.style.height = `${element.scrollHeight}px`;
    setTextareaHeight(`${element.scrollHeight}px`);
  };

  useEffect(() => {
    if (isEditingTranscription && transcriptionInputRef.current) {
      adjustTextareaHeight(transcriptionInputRef.current);
    }
  }, [isEditingTranscription, editedTranscription]);

  const handleSaveTranscription = async () => {
    if (!isTranscriptionEdited) return;
    try {
      console.log('Attempting to save transcription. ID:', id);
      if (!id) {
        console.error('Voice note ID is missing');
        throw new Error('Voice note ID is missing');
      }
      console.log('Saving transcription for id:', id);
      console.log('New transcription:', editedTranscription);
      const result = await onTranscriptionUpdate(id, editedTranscription);
      console.log('Transcription update result:', result);
      setIsEditingTranscription(false);
      console.log('Transcription saved successfully');
      setCurrentTranscription(editedTranscription);
      setIsTranscriptionEdited(false);
    } catch (error) {
      console.error('Error saving transcription:', error);
    }
  };

  const handleCancelTranscriptionEdit = () => {
    setEditedTranscription(currentTranscription);
    setIsEditingTranscription(false);
    setIsTranscriptionEdited(false);
  };

  useEffect(() => {
    console.log('TranscriptionGridItem mounted or id changed. Current ID:', id);
  }, [id]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is inside the tag menu or the tag button
      const isClickInsideMenu = TagMenu.isClickInsideMenu(event.target, tagMenuRef);
      const isClickOnTagButton = tagButtonElement?.contains(event.target);

      if (!isClickInsideMenu && !isClickOnTagButton) {
        setIsTagMenuOpen(false);
        setTagButtonElement(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [tagButtonElement]);

  const handleTagClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    // If the tag menu is already open with the same button, close it
    if (isTagMenuOpen && tagButtonElement === e.currentTarget) {
      setIsTagMenuOpen(false);
      setTagButtonElement(null);
    } else {
      // Otherwise, open the tag menu with the new button reference
      setIsTagMenuOpen(true);
      // Use the current target as the anchor element
      setTagButtonElement(e.currentTarget);
    }
  };

  const handleAddTagInternal = (tag) => {
    onAddTag(tag); // This will actually call handleAddTag(note._id, tag) in TranscriptionScreen
  };

  const handleRemoveTag = (tagToRemove) => {
    console.log('handleRemoveTag called with tag:', tagToRemove);
    console.log('Current voice note ID:', id);
    
    // If onRemoveTag expects both parameters
    if (onRemoveTag.length === 2) {
      onRemoveTag(id, tagToRemove);
    } else {
      // If onRemoveTag expects just the tag
      onRemoveTag(tagToRemove);
    }
  };

  useEffect(() => {
    const checkScroll = () => {
      if (tagsContainerRef.current) {
        const { scrollWidth, clientWidth, scrollLeft } = tagsContainerRef.current;
        setShowRightFade(scrollWidth > clientWidth && scrollLeft < scrollWidth - clientWidth);
      }
    };

    checkScroll();
    window.addEventListener('resize', checkScroll);
    return () => window.removeEventListener('resize', checkScroll);
  }, [tags]);

  const handleTagsScroll = () => {
    if (tagsContainerRef.current) {
      const { scrollWidth, clientWidth, scrollLeft } = tagsContainerRef.current;
      setShowRightFade(scrollLeft < scrollWidth - clientWidth);
    }
  };

  const setTextareaRef = (element) => {
    transcriptionInputRef.current = element;
    if (element && isEditingTranscription) {
      element.focus();
      element.setSelectionRange(editedTranscription.length, editedTranscription.length);
      adjustTextareaHeight(element);
    }
  };

  const handleGenerateContent = async () => {
    if (!prompt.trim() || prompt.length > maxPromptLength) return;
    setIsGenerating(true);
    try {
      console.log('Attempting to generate content for voice note:', id);
      const content = await generateContent(id, prompt);
      console.log('Generated content:', content);
      
      // Save the generated content
      const savedContent = await saveGeneratedContent(id, prompt, content);
      console.log('Saved generated content:', savedContent);
      
      // Update the list with the new content at the beginning
      setGeneratedContentList(prevList => [savedContent.generatedContent, ...prevList]);
      setShowPromptForm(false);
      setPrompt(''); // Clear the prompt input
    } catch (error) {
      console.error('Error generating or saving content:', error);
      let errorMessage = 'Failed to generate or save content. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      // Show an error message to the user (you might want to add a state for this)
      alert(errorMessage);
    } finally {
      setIsGenerating(false);
      setShowPromptForm(false);
      setPrompt(''); // Clear the prompt input
    }
  };

  const handleCopyGeneratedContent = async (content, index) => {
    try {
      await navigator.clipboard.writeText(content);
      setCopiedContentIndex(index);
      setTimeout(() => setCopiedContentIndex(null), 2000); // Reset after 2 seconds
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const toggleItemExpansion = (index) => {
    setExpandedItems(prev => 
      prev.includes(index) 
        ? prev.filter(i => i !== index) 
        : [index] // This ensures only one item is expanded at a time
    );
  };

  const handleMenuToggle = (buttonElement) => {
    if (buttonElement) {
      setMenuButtonElement(buttonElement);
      setIsMenuOpen(true);
    } else {
      setMenuButtonElement(null);
      setIsMenuOpen(false);
    }
  };

  // Add this function inside the component before the return statement
  const handlePromptSelect = (selectedPrompt) => {
    setPrompt(selectedPrompt);
  };

  const handleDeleteContent = async (contentId) => {
    try {
      await deleteGeneratedContent(id, contentId);
      setGeneratedContentList(prevList => 
        prevList.filter(item => item._id !== contentId)
      );
    } catch (error) {
      console.error('Error deleting generated content:', error);
    }
  };

  // Add transition classes for smooth animations
  const buttonBaseClasses = `
    transition-all duration-200 ease-in-out
    focus:outline-none focus:ring-2 focus:ring-offset-2
    ${isDarkMode ? 'focus:ring-blue-500' : 'focus:ring-blue-600'}
    disabled:opacity-50 disabled:cursor-not-allowed
  `;

  const iconButtonClasses = `
    ${buttonBaseClasses}
    p-1.5 rounded-full
    ${isDarkMode 
      ? 'hover:bg-gray-700 active:bg-gray-600' 
      : 'hover:bg-gray-100 active:bg-gray-200'}
  `;

  // Update the handleItemClick function
  const handleItemClick = (e) => {
    // Prevent navigation if clicking on buttons, selecting text, editing, or interacting with AI generation
    if (
      e.target.closest('button') || 
      window.getSelection().toString() || 
      isEditing || 
      isEditingTranscription ||
      showPromptForm ||
      e.target.closest('[data-generated-content="true"]') ||
      e.target.closest('[data-prompt-form="true"]')
    ) {
      return;
    }

    // Navigate to the voice note detail page
    e.preventDefault();
    e.stopPropagation();
    navigate(`/voice-notes/${id}`);
  };

  // Update the main container styles and restructure the date/time position
  return (
    <div 
      onClick={handleItemClick}
      className={`group/item relative transition-all duration-200 ease-in-out mb-4 cursor-pointer ${
        isDarkMode ? 'text-gray-200' : 'text-gray-800'
      }`}
    >
      {/* Main content container */}
      <div className={`
        relative overflow-hidden
        rounded-xl border transition-all duration-200
        ${isDarkMode 
          ? 'bg-[#151515]/95 border-[#333333] hover:border-[#444444]' 
          : 'bg-white border-gray-200/50 hover:border-gray-300/50'}
        ${isEditingTranscription ? 'p-5' : 'p-4'}
      `}>
        {/* Header Row */}
        <div className="flex items-center justify-between mb-3">
          {/* Left side - Date/Time */}
          <div className="flex items-center space-x-2">
            <span className={`text-xs font-medium ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
              {formattedDate}
            </span>
            <span className={`text-[10px] ${isDarkMode ? 'text-gray-500' : 'text-gray-400'}`}>•</span>
            <span className={`text-[11px] ${isDarkMode ? 'text-gray-500' : 'text-gray-400'}`}>
              {formattedTime}
            </span>
          </div>
          
          {/* Right side - Controls */}
          <div className={`
            flex items-center gap-1.5
            ${isMobile ? '' : 'opacity-0 group-hover/item:opacity-100 transition-opacity duration-200'}
          `}>
            {/* Play Button */}
            {!isMobileDevice && (
              <button
                onClick={handlePlayPause}
                disabled={isAudioLoading || !isUrlReady}
                className={`
                  p-1.5 rounded-lg transition-colors duration-200
                  ${isDarkMode
                    ? 'text-slate-400 hover:bg-[#1e1e1e] hover:text-slate-300'
                    : 'text-gray-500 hover:bg-gray-100 hover:text-gray-700'}
                  ${(isAudioLoading || !isUrlReady) ? 'opacity-50 cursor-not-allowed' : ''}
                `}
              >
                {isAudioLoading ? (
                  <Spinner className="h-3.5 w-3.5" />
                ) : isPlaying ? (
                  <PauseIcon className="h-3.5 w-3.5" />
                ) : (
                  <PlayIcon className="h-3.5 w-3.5" />
                )}
              </button>
            )}

            {/* Generate Content Button */}
            <ActionButton
              onClick={() => setShowPromptForm(prev => !prev)}
              icon={SparklesIcon}
              tooltip={showPromptForm ? "Close prompt" : "Generate content"}
              isDarkMode={isDarkMode}
              isActive={showPromptForm}
            />

            {/* Expand Button */}
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate(`/voice-notes/${id}`);
              }}
              className={`
                p-1.5 rounded-lg transition-colors duration-200
                ${isDarkMode
                  ? 'text-gray-400 hover:bg-gray-700/50 hover:text-gray-300'
                  : 'text-gray-500 hover:bg-gray-100 hover:text-gray-700'}
              `}
              aria-label="Expand voice note"
            >
              <ExpandIcon className="w-3.5 h-3.5" />
            </button>

            {/* Menu Button */}
            <div>
              <TranscriptionMenu
                isOpen={isMenuOpen}
                onToggle={handleMenuToggle}
                onDelete={handleDelete}
                onEditTitle={() => setIsEditing(true)}
                onEditTranscription={() => setIsEditingTranscription(true)}
                onRegenerateTitle={handleRegenerateTitle}
                onDownloadAudio={handleDownloadAudio}
                onCopyTranscription={handleCopyTranscription}
                onManageTags={handleTagClick}
                isDarkMode={isDarkMode}
                anchorEl={menuButtonElement}
                isCopied={isCopied}
                tagButtonElement={tagButtonElement}
                setTagButtonElement={setTagButtonElement}
              />
            </div>
          </div>
        </div>

        {/* Title Section */}
        <div className="mb-3">
          {isEditing ? (
            <div className="flex items-center gap-2">
              <input
                ref={inputRef}
                type="text"
                value={editedTitle}
                onChange={handleTitleChange}
                onKeyDown={handleKeyDown}
                className={`
                  flex-grow px-3 py-1.5 text-sm font-medium rounded-lg
                  transition-colors duration-200
                  ${isDarkMode 
                    ? 'bg-[#1a1a1a] text-slate-200 focus:bg-[#202020]' 
                    : 'bg-gray-50 text-gray-900 focus:bg-gray-100'}
                  border ${isDarkMode ? 'border-gray-600' : 'border-gray-200'}
                  focus:outline-none focus:ring-1 focus:ring-blue-500
                `}
              />
              <div className="flex items-center gap-1">
                <button onClick={handleTitleSave} className={iconButtonClasses}>
                  <CheckIcon className={`h-4 w-4 ${isDarkMode ? 'text-green-400' : 'text-green-600'}`} />
                </button>
                <button onClick={handleTitleCancel} className={iconButtonClasses}>
                  <XMarkIcon className={`h-4 w-4 ${isDarkMode ? 'text-red-400' : 'text-red-600'}`} />
                </button>
              </div>
            </div>
          ) : (
            <h3 className={`text-sm font-semibold ${isDarkMode ? 'text-gray-100' : 'text-gray-900'}`}>
              {isRegenerating ? (
                <span className="text-green-500">Regenerating title...</span>
              ) : regenerateError ? (
                <span className="text-red-500">{regenerateError}</span>
              ) : (
                editedTitle
              )}
            </h3>
          )}
        </div>

        {/* Transcription Content */}
        <div className={`relative ${isEditingTranscription ? 'mb-4' : 'mb-3'}`}>
          {isEditingTranscription ? (
            <div className="space-y-2">
              <textarea
                ref={setTextareaRef}
                value={editedTranscription}
                onChange={handleTranscriptionChange}
                className={`
                  w-full p-3 rounded-lg text-sm
                  transition-colors duration-200
                  ${isDarkMode 
                    ? 'bg-gray-700/50 text-gray-200 border-gray-600' 
                    : 'bg-gray-50 text-gray-800 border-gray-200'}
                  border focus:outline-none focus:ring-1 focus:ring-blue-500
                `}
                style={{ 
                  minHeight: '120px',
                  height: textareaHeight,
                  maxHeight: '400px',
                  lineHeight: '1.5',
                }}
              />
              <div className="flex items-center justify-between px-1">
                <span className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                  {wordCount} words
                </span>
                <div className="flex items-center gap-2">
                  <button
                    onClick={handleSaveTranscription}
                    disabled={!isTranscriptionEdited}
                    className={`
                      p-1.5 rounded-lg transition-colors duration-200
                      ${isTranscriptionEdited
                        ? isDarkMode
                          ? 'bg-green-500/10 text-green-400 hover:bg-green-500/20'
                          : 'bg-green-50 text-green-600 hover:bg-green-100'
                        : 'opacity-50 cursor-not-allowed'}
                    `}
                  >
                    <CheckIcon className="h-4 w-4" />
                  </button>
                  <button
                    onClick={handleCancelTranscriptionEdit}
                    className={`
                      p-1.5 rounded-lg transition-colors duration-200
                      ${isDarkMode
                        ? 'bg-red-500/10 text-red-400 hover:bg-red-500/20'
                        : 'bg-red-50 text-red-600 hover:bg-red-100'}
                    `}
                  >
                    <XMarkIcon className="h-4 w-4" />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="line-clamp-2">
              <div className={`text-xs leading-5 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                {formatTranscription(truncateTranscription(currentTranscription))}
              </div>
            </div>
          )}
        </div>

        {/* Generated Content Section */}
        {showPromptForm && (
          <div className="mt-4" data-prompt-form="true">
            <PromptForm
              isDarkMode={isDarkMode}
              prompt={prompt}
              setPrompt={setPrompt}
              onGenerate={handleGenerateContent}
              onClose={() => {
                setShowPromptForm(false);
                setPrompt('');
              }}
              isGenerating={isGenerating}
            />
          </div>
        )}

        {generatedContentList.length > 0 && (
          <div className="mt-4 space-y-2" data-generated-content="true">
            {generatedContentList.map((item, index) => (
              <GeneratedContentSection
                key={item._id}
                item={item}
                index={index}
                isDarkMode={isDarkMode}
                expandedItems={expandedItems}
                onToggleExpand={toggleItemExpansion}
                onCopy={handleCopyGeneratedContent}
                onDelete={handleDeleteContent}
                copiedContentIndex={copiedContentIndex}
              />
            ))}
          </div>
        )}

        {/* Tags Section with Expand Icon */}
        {!isEditingTranscription && (
          <div className={`flex items-center gap-3 ${
            generatedContentList.length > 0 ? 'mt-4' : 'mt-0'
          }`}>
            <div className="flex-1 min-w-0">
              <div className="flex items-center gap-2 overflow-x-auto scrollbar-hide">
                {tags.map((tag) => (
                  <span
                    key={tag}
                    className={`
                      inline-flex items-center px-2 py-1 rounded-lg text-xs
                      ${isDarkMode
                        ? 'bg-gray-700/50 text-gray-300'
                        : 'bg-gray-100 text-gray-600'}
                    `}
                  >
                    {tag}
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleRemoveTag(tag);
                      }}
                      className={`
                        ml-1.5 p-0.5 rounded-md transition-colors duration-200
                        ${isDarkMode
                          ? 'hover:bg-gray-600 text-gray-400'
                          : 'hover:bg-gray-200 text-gray-500'}
                      `}
                    >
                      <XMarkIcon className="w-3 h-3" />
                    </button>
                  </span>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Audio Element */}
        {!isMobileDevice && (
          <audio
            ref={audioRef}
            src={signedAudioUrl}
            onEnded={handleAudioEnded}
            preload="auto"
            playsInline
            className="hidden"
            onLoadedData={() => {
              console.log('Audio loaded successfully');
              setIsAudioLoading(false);
              setIsUrlReady(true);
              setNetworkError(null);
              setAudioError(null);
            }}
            onLoadStart={() => {
              console.log('Audio starting to load');
              setIsAudioLoading(true);
            }}
            onCanPlayThrough={() => {
              console.log('Audio can play through');
              setIsAudioLoading(false);
              setIsUrlReady(true);
            }}
            onError={(e) => {
              const error = e.target.error;
              console.error('Audio error:', error);
              
              let errorMessage = "Failed to load audio. ";
              if (error) {
                switch (error.code) {
                  case error.MEDIA_ERR_NETWORK:
                    errorMessage += "Network error occurred.";
                    break;
                  case error.MEDIA_ERR_DECODE:
                    errorMessage += "Audio decoding failed.";
                    break;
                  case error.MEDIA_ERR_SRC_NOT_SUPPORTED:
                    errorMessage += "Audio format not supported.";
                    break;
                  default:
                    errorMessage += "Please check your connection.";
                }
              }
              
              setAudioError(errorMessage);
              setNetworkError(errorMessage);
              
              if (audioLoadAttempts < maxLoadAttempts) {
                setAudioLoadAttempts(prev => prev + 1);
                setTimeout(() => loadAudio(), 1000 * Math.pow(2, audioLoadAttempts));
              }
            }}
          />
        )}

        {/* Error Messages */}
        {networkError && !isMobileDevice && (
          <div className="mt-3 flex items-center gap-2">
            <span className={`text-xs ${isDarkMode ? 'text-red-400' : 'text-red-600'}`}>
              {networkError}
            </span>
            <button
              onClick={() => {
                setRetryCount(0);
                loadAudio();
              }}
              className={`
                text-xs font-medium transition-colors duration-200
                ${isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-500'}
              `}
            >
              Retry
            </button>
          </div>
        )}
      </div>

      {/* Tag Menu */}
      {isTagMenuOpen && tagButtonElement && (
        <TagMenu
          ref={tagMenuRef}
          isOpen={isTagMenuOpen}
          onClose={() => {
            setIsTagMenuOpen(false);
            setTagButtonElement(null);
          }}
          anchorEl={tagButtonElement}
          tags={tags}
          allTags={allTags}
          onAddTag={handleAddTagInternal}
          onRemoveTag={handleRemoveTag}
          isDarkMode={isDarkMode}
        />
      )}
    </div>
  );
};

// Add a new ActionButton component for better reusability
const ActionButton = React.forwardRef(({ onClick, icon: Icon, tooltip, isDarkMode, isActive, className = '' }, ref) => (
  <button
    ref={ref}
    onClick={onClick}
    className={`
      p-1.5 rounded-lg transition-all duration-200
      ${isDarkMode
        ? isActive 
          ? 'bg-purple-500/20 text-purple-400' 
          : 'hover:bg-gray-600/80 text-gray-400'
        : isActive 
          ? 'bg-purple-100 text-purple-600' 
          : 'hover:bg-gray-200 text-gray-500'
      } ${className}
    `}
  >
    <Icon className="w-3.5 h-3.5" />
    <span 
      className={`
        absolute -top-8 left-1/2 -translate-x-1/2 px-2 py-1
        text-xs rounded bg-black text-white opacity-0
        group-hover/tooltip:opacity-100 transition-opacity
        whitespace-nowrap pointer-events-none
      `}
    >
      {tooltip}
    </span>
  </button>
));

export default TranscriptionGridItem;
