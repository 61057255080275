import React, { createContext, useContext, useState, useEffect } from 'react';
import { AuthContext } from './AuthContext'; // Correct import statement

const TimerContext = createContext();

export const TimerProvider = ({ children }) => {
  const { user } = useContext(AuthContext); // Use AuthContext directly
  const [activeTimer, setActiveTimer] = useState(null);
  const [timerVisibility, setTimerVisibility] = useState(false);
  const [iconVisibility, setIconVisibility] = useState(false);
  const [isTimerBubbleVisible, setIsTimerBubbleVisible] = useState(false);

  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [selectedDuration, setSelectedDuration] = useState(null);

  useEffect(() => {
    let interval;
    if (isRunning && time > 0) {
      interval = setInterval(() => {
        setTime((prevTime) => {
          const newTime = prevTime - 1;
          if (newTime <= 0) {
            clearInterval(interval);
            setIsRunning(false);
            setIsTimerBubbleVisible(false); // Hide bubble when timer ends
            setActiveTimer(null);
            return 0;
          }
          setActiveTimer((prev) => prev ? { ...prev, time: newTime } : null);
          return newTime;
        });
      }, 1000);
    } else if (time === 0) {
      setIsRunning(false);
      setActiveTimer(null);
      setIsTimerBubbleVisible(false); // Hide bubble when timer ends
    }
    return () => clearInterval(interval);
  }, [isRunning, time]);

  const startTimer = () => {
    if (selectedDuration && time === 0) {
      setTime(selectedDuration);
    }
    setIsRunning(true);
    setIsTimerBubbleVisible(true);
    setTimerVisibility(true);  // Add this line
    console.log('Timer started, bubble should be visible:', true); // Add this line for debugging
  };

  const pauseTimer = () => {
    setIsRunning(false);
  };

  const resumeTimer = () => {
    if (time > 0) {
      setIsRunning(true);
    }
  };

  const stopTimer = () => {
    setIsRunning(false);
    setIsTimerBubbleVisible(false); // Hide bubble when timer stops
  };

  const resetTimer = () => {
    setTime(selectedDuration || 0);
    setIsRunning(false);
    setIsTimerBubbleVisible(false); // Hide bubble when timer resets
  };

  const setDuration = (duration) => {
    setSelectedDuration(duration);
    setTime(duration);
    setIsRunning(false);
  };

  const toggleTimerVisibility = () => {
    setTimerVisibility(prev => !prev);
    setIconVisibility(prev => !prev);
    setIsTimerBubbleVisible(prev => !prev);
  };

  const showTimerBubble = () => {
    setTimerVisibility(true);
    setIconVisibility(false);
  };

  const value = {
    activeTimer,
    setActiveTimer,
    pauseTimer,
    resumeTimer, // Add this line
    timerVisibility,
    toggleTimerVisibility,
    iconVisibility,
    showTimerBubble,
    time,
    isRunning,
    selectedDuration,
    startTimer,
    stopTimer,
    resetTimer,
    setDuration,
    setShowTimerBubble: setIsTimerBubbleVisible,
    isTimerBubbleVisible,
    isLoggedIn: !!user // Convert user to boolean
  };

  return (
    <TimerContext.Provider value={value}>
      {children}
    </TimerContext.Provider>
  );
};

export function useTimer() {
  return useContext(TimerContext);
}