import React, { useRef, useState } from 'react';

const formatCurrency = (value) => {
  if (typeof value !== 'number') return value;
  const absValue = Math.abs(value);
  const formattedValue = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  }).format(absValue);
  return value < 0 ? `($${formattedValue})` : `$${formattedValue}`;
};

const TableSection = ({ title, data, color, icon, subtitle }) => (
  <div className="bg-gray-900/30 rounded-xl border border-gray-800/50 backdrop-blur-sm hover:shadow-lg hover:shadow-gray-900/20 transition-all duration-300 h-fit">
    <div className={`px-6 py-4 border-b border-gray-800/50 ${color} rounded-t-xl`}>
      <div className="flex justify-between items-center">
        <div className="flex flex-col gap-1">
          <div className="flex items-center gap-3">
            {icon}
            <h3 className="text-sm font-semibold text-white">{title}</h3>
          </div>
          {subtitle && (
            <span className="text-xs text-gray-400">{subtitle}</span>
          )}
        </div>
        <span className="text-lg bg-black/40 px-4 py-1.5 rounded-full text-white font-medium">
          {data.length}
        </span>
      </div>
    </div>
    <div className="p-5 relative">
      <div className={`absolute inset-0 ${color.replace('from-', 'from-[').replace('/20', '/10]')} rounded-b-xl`} />
      
      <table className="w-full relative z-10">
        <thead>
          <tr className="text-xs text-gray-400 border-b border-gray-800/50">
            <th className="text-left pb-3 font-medium">Account</th>
            <th className="text-right pb-3 font-medium">Target</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-800/30">
          {data.map((item, index) => (
            <tr key={index} className="group hover:bg-gray-800/20 transition-colors">
              <td className="py-2.5 text-sm text-gray-300 group-hover:text-white transition-colors">
                {item.name}
              </td>
              <td className={`py-2.5 text-right font-mono text-sm
                ${title === "INACTIVE & DROP" 
                  ? 'text-gray-300'
                  : typeof item.value === 'number'
                    ? item.value >= 0 
                      ? 'text-emerald-400' 
                      : 'text-red-400'
                    : 'text-gray-500'
                }`}>
                {item.value 
                  ? title === "INACTIVE & DROP"
                    ? `$${Math.abs(item.value).toFixed(3)}`
                    : formatCurrency(item.value)
                  : ''
                }
              </td>
            </tr>
          ))}
          {data.length > 0 && (
            <tr className="font-medium">
              <td className="py-4 px-3 text-sm text-white bg-gray-800/50 rounded-md">
                TOTAL
              </td>
              <td className={`py-4 px-3 text-right font-mono text-sm text-white bg-gray-800/50 rounded-md`}>
                {title === "INACTIVE & DROP"
                  ? `$${Math.abs(data.reduce((acc, curr) => acc + (curr.value || 0), 0)).toFixed(3)}`
                  : formatCurrency(data.reduce((acc, curr) => acc + (curr.value || 0), 0))
                }
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </div>
);

const AccountsOverview = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const containerRef = useRef(null);

  const toggleFullscreen = async () => {
    if (!document.fullscreenElement) {
      await containerRef.current.requestFullscreen();
      setIsFullscreen(true);
    } else {
      await document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  const focusGrowthData = [
    { name: 'INTENTSIFY', value: 2.60 },
    { name: 'ACQUISITION INC', value: 1.50 },
    { name: '195/AGENT3', value: 1.50 },
    { name: 'ANTERIAD', value: 1.00 },
    { name: 'NETHAWK', value: 1.00 },
    { name: 'TECHTARGET', value: 0.50 }
  ];

  const sustainAccountsData = [
    { name: 'MLG', value: 0.50 },
    { name: 'DEMANDSCIENCE', value: 0.50 },
    { name: 'B2B2 INT', value: 0.50 }
  ];

  const inactiveDropData = [
    { name: 'BWR', value: -0.008 },
    { name: 'KHAT', value: -0.00050 },
    { name: 'TEAMVIEWER', value: 0.00130 },
    { name: 'MOBICA', value: -0.00200 },
    { name: 'BYTHON', value: -0.00150 },
    { name: 'TA', value: -0.00010 },
    { name: 'MAJOREL', value: -0.00010 }
  ];

  const newGrowthData = [
    { name: 'KINGPIN', value: null },
    { name: 'MY OUTREACH', value: null },
    { name: 'WEARETOGETHER', value: null },
    { name: 'TRANSMISSION', value: null },
    { name: 'ACCOUNT MEDIA', value: null },
    { name: 'DWA MERKLE APAC', value: null },
    { name: 'DWA MERKLE USA & ROW', value: null },
    { name: 'MEDIACOM', value: null },
    { name: 'IBM MEDIA PARTNER', value: null },
    { name: 'REALM B2B', value: null },
    { name: 'GILROY', value: null },
    { name: 'DIGITAL RADDISH', value: null }
  ];

  return (
    <div ref={containerRef} className="bg-black rounded-xl border border-gray-800 max-w-7xl mx-auto my-6">
      <div className="flex justify-between items-center px-4 py-3 border-b border-gray-800/50">
        <div className="flex items-center gap-3">
          <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
          </svg>
          <h2 className="text-base text-white font-medium">Accounts Overview</h2>
        </div>
        <button
          onClick={toggleFullscreen}
          className="p-2 rounded-md bg-gray-800 hover:bg-gray-700 transition-colors"
          title={isFullscreen ? "Exit fullscreen" : "Enter fullscreen"}
        >
          {isFullscreen ? (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 9L4 4m0 0l5-5M4 4l5 5M15 9l5-5m0 0l-5-5m5 5l-5 5M9 15l-5 5m0 0l5 5M4 20l5-5M15 15l5 5m0 0l-5 5m5-5l-5-5" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5v-4m0 4h-4m4 0l-5-5" />
            </svg>
          )}
        </button>
      </div>

      <div className={`p-4 ${isFullscreen ? 'h-screen overflow-auto' : ''}`}>
        <div className="grid grid-cols-4 gap-4">
          <TableSection 
            title="FOCUS + GROWTH ACCOUNTS" 
            subtitle="GROWTH TARGET IN 2025 TOM 2026 ANNUAL REVS IN USD MILL"
            data={focusGrowthData} 
            color="bg-gradient-to-r from-pink-500/20 to-transparent"
            icon={<svg className="w-4 h-4 text-pink-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
            </svg>}
          />
          <TableSection 
            title="SUSTAIN ACCOUNTS" 
            data={sustainAccountsData} 
            color="bg-gradient-to-r from-blue-500/20 to-transparent"
            icon={<svg className="w-4 h-4 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>}
          />
          <TableSection 
            title="INACTIVE & DROP" 
            data={inactiveDropData} 
            color="bg-gradient-to-r from-red-500/20 to-transparent"
            icon={<svg className="w-4 h-4 text-red-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
            </svg>}
          />
          <TableSection 
            title="NEW GROWTH" 
            data={newGrowthData} 
            color="bg-gradient-to-r from-emerald-500/20 to-transparent"
            icon={<svg className="w-4 h-4 text-emerald-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
            </svg>}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountsOverview; 