import api from '../api';
import imageCompression from 'browser-image-compression';

const AVATAR_CACHE_KEY = 'agent_avatars';
const AVATAR_CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours

const isUrlExpired = (timestamp) => {
  return Date.now() - timestamp > AVATAR_CACHE_DURATION;
};

const isValidUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

async function compressImage(imageUrl) {
  if (!imageUrl) {
    console.error('Invalid image URL provided for compression');
    return null;
  }

  try {
    const response = await fetch(imageUrl);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const blob = await response.blob();
    
    const options = {
      maxSizeMB: 0.1, // Compress to 100KB
      maxWidthOrHeight: 256, // Max width or height of 256px
      useWebWorker: true
    };
    
    const compressedFile = await imageCompression(blob, options);
    return URL.createObjectURL(compressedFile);
  } catch (error) {
    console.error('Error compressing image:', error);
    return null;
  }
}

export const saveAvatarToCache = async (agentId, avatarUrl) => {
  const cache = JSON.parse(localStorage.getItem(AVATAR_CACHE_KEY) || '{}');
  
  let compressedUrl = avatarUrl;
  if (avatarUrl && !avatarUrl.startsWith('data:')) {
    compressedUrl = await compressImage(avatarUrl);
  }
  
  if (!compressedUrl) {
    console.error(`Failed to compress or process avatar for agent ${agentId}`);
    return;
  }
  
  if (compressedUrl.startsWith('blob:')) {
    try {
      const response = await fetch(compressedUrl);
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onloadend = function() {
        cache[agentId] = {
          url: reader.result,
          timestamp: Date.now()
        };
        localStorage.setItem(AVATAR_CACHE_KEY, JSON.stringify(cache));
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error(`Error processing blob URL for agent ${agentId}:`, error);
    }
  } else {
    cache[agentId] = {
      url: compressedUrl,
      timestamp: Date.now()
    };
    localStorage.setItem(AVATAR_CACHE_KEY, JSON.stringify(cache));
  }
};

export const getAvatarFromCache = (agentId) => {
  const cache = JSON.parse(localStorage.getItem(AVATAR_CACHE_KEY) || '{}');
  const cachedAvatar = cache[agentId];
  if (cachedAvatar && !isUrlExpired(cachedAvatar.timestamp)) {
    if (cachedAvatar.url.startsWith('data:')) {
      const byteString = atob(cachedAvatar.url.split(',')[1]);
      const mimeString = cachedAvatar.url.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], {type: mimeString});
      return URL.createObjectURL(blob);
    }
    return cachedAvatar.url;
  }
  return null;
};

export const fetchAvatarUrl = async (agentId) => {
  try {
    const cachedUrl = getAvatarFromCache(agentId);
    if (cachedUrl) {
      if (!isValidUrl(cachedUrl)) {
        console.error(`Invalid cached URL for agent ${agentId}: ${cachedUrl}`);
        removeAvatarFromCache(agentId);
      } else {
        return cachedUrl;
      }
    }

    const response = await api.get(`/api/ai-agents/${agentId}/avatar`);
    if (!response.data || !response.data.avatarUrl) {
      throw new Error('Invalid response from server');
    }
    const signedUrl = response.data.avatarUrl;
    if (!isValidUrl(signedUrl)) {
      throw new Error(`Invalid signed URL received from server: ${signedUrl}`);
    }
    
    const compressedUrl = await compressImage(signedUrl);
    if (compressedUrl) {
      saveAvatarToCache(agentId, compressedUrl);
      return compressedUrl;
    } else {
      saveAvatarToCache(agentId, signedUrl);
      return signedUrl;
    }
  } catch (error) {
    console.error(`Error fetching avatar URL for agent ${agentId}:`, error);
    return null;
  }
};

export const removeAvatarFromCache = (agentId) => {
  const cache = JSON.parse(localStorage.getItem(AVATAR_CACHE_KEY) || '{}');
  delete cache[agentId];
  localStorage.setItem(AVATAR_CACHE_KEY, JSON.stringify(cache));
};

export const refreshExpiredAvatarUrl = async (agentId) => {
  try {
    console.log(`Refreshing avatar URL for agent ${agentId} at ${new Date().toISOString()}`);
    const response = await api.get(`/api/ai-agents/${agentId}/avatar`);
    if (!response.data || !response.data.avatarUrl) {
      throw new Error('Invalid response from server');
    }
    const signedUrl = response.data.avatarUrl;
    if (!isValidUrl(signedUrl)) {
      throw new Error(`Invalid signed URL received from server: ${signedUrl}`);
    }
    saveAvatarToCache(agentId, signedUrl);
    console.log(`Successfully refreshed avatar URL for agent ${agentId} at ${new Date().toISOString()}`);
    return signedUrl;
  } catch (error) {
    console.error(`Error refreshing avatar URL for agent ${agentId}:`, error);
    return null;
  }
};

export const forceRefreshAvatarUrl = async (agentId) => {
  console.log(`Force refreshing avatar URL for agent ${agentId} at ${new Date().toISOString()}`);
  removeAvatarFromCache(agentId);
  return await fetchAvatarUrl(agentId);
};
