import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../context/ThemeContext';
import { motion, AnimatePresence } from 'framer-motion';
import { Bars3Icon, XMarkIcon, SunIcon, MoonIcon } from '@heroicons/react/24/outline';
import { useMediaQuery } from 'react-responsive';
import Logo from '../logo.svg';
import EarlyAccessButton from './EarlyAccessButton';

// Define shimmerVariants for the shimmer effect
const shimmerVariants = {
  initial: { x: "-100%" },
  hover: {
    x: "200%",
    transition: {
      repeat: Infinity,
      duration: 2,
      ease: "linear"
    }
  }
};

const LPHeader = () => {
  const { isDarkMode, toggleDarkMode } = useTheme();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  // Optimized scroll handler with debounce
  useEffect(() => {
    let timeoutId;
    const handleScroll = () => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setIsScrolled(window.scrollY > 10);
      }, 10);
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, []);

  const navItems = [
    { label: 'Features', path: '#features-section' },
    { label: 'How It Works', path: '#how-it-works' },
    { label: 'Context Bank', path: '#context-bank-section' },
    { label: 'Pricing', path: '#pricing-section' },
  ];

  // Enhanced glass effect styles
  const glassStyles = `
    backdrop-blur-xl backdrop-saturate-150
    ${isDarkMode 
      ? 'bg-[#121212]/40 border-white/10' 
      : 'bg-white/40 border-black/5'}
    border
    transition-all duration-500 ease-in-out
  `;

  // Modern floating pill styles for desktop nav
  const navPillStyles = `
    px-2 py-1.5 rounded-full
    ${glassStyles}
    ${isScrolled ? 'shadow-lg' : ''}
    transform transition-all duration-500
  `;

  // Enhanced mobile menu styles
  const mobileMenuStyles = `
    ${isDarkMode 
      ? 'bg-[#121212] border-[#333]'
      : 'bg-white border-gray-200'}
    border rounded-2xl
    shadow-xl
    ${isScrolled ? 'shadow-black/10' : ''}
  `;

  const handleNavClick = (e, path) => {
    e.preventDefault();
    setIsMenuOpen(false);
    
    const elementId = path.replace('#', '');
    const element = document.getElementById(elementId);
    const mainContainer = document.querySelector('main');
    
    if (element && mainContainer) {
      // Get the current scroll position of the main container
      const currentScrollTop = mainContainer.scrollTop;
      
      // Calculate element's position relative to the main container
      const containerRect = mainContainer.getBoundingClientRect();
      const elementRect = element.getBoundingClientRect();
      const headerOffset = 100;
      
      // Calculate the absolute position within the scrollable container
      const absoluteElementTop = elementRect.top - containerRect.top + currentScrollTop;
      
      // Scroll to the calculated position
      mainContainer.scrollTo({
        top: absoluteElementTop - headerOffset,
        behavior: 'smooth'
      });
      
      // Update URL without affecting scroll
      window.history.pushState({}, '', path);
    }
  };

  const handleScrollToTop = (e) => {
    e.preventDefault();
    const mainContainer = document.querySelector('main');
    if (mainContainer) {
      mainContainer.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  return (
    <motion.header
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      className={`
        fixed top-0 left-0 right-0 z-40
        px-4 sm:px-6 lg:px-8 py-4
        w-full
      `}
    >
      <div className="max-w-7xl mx-auto">
        <nav className={`
          relative flex items-center justify-between
          ${!isMobile ? navPillStyles : ''}
        `}>
          {/* Logo */}
          <motion.div
            whileHover={{ scale: 1.02 }}
            className="flex items-center space-x-2"
          >
            <a 
              href="/"
              onClick={handleScrollToTop}
              className="flex items-center space-x-2"
            >
              {/* <img src={Logo} alt="Logo" className="h-6 w-auto" /> */}
              <span className={`
                font-semibold ml-2
                ${isDarkMode ? 'text-white' : 'text-gray-900'}
              `}>
                Ammmplify
              </span>
            </a>
          </motion.div>

          {/* Desktop Navigation */}
          {!isMobile && (
            <div className="flex items-center space-x-8">
              {navItems.map((item) => (
                <motion.div
                  key={item.label}
                  whileHover={{ scale: 1.05 }}
                  className="relative"
                >
                  <button
                    onClick={(e) => handleNavClick(e, item.path)}
                    className={`
                      text-sm font-medium
                      ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}
                      hover:text-primary-500 transition-colors
                    `}
                  >
                    {item.label}
                  </button>
                </motion.div>
              ))}
            </div>
          )}

          {/* Auth/Menu Controls */}
          <div className="flex items-center space-x-3">
            {isMobile ? (
              <>
                {/* Theme Toggle for Mobile */}
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={toggleDarkMode}
                  className={glassStyles + ' p-2 rounded-full'}
                  aria-label="Toggle theme"
                >
                  {isDarkMode ? (
                    <SunIcon className="h-4 w-4 text-gray-300" />
                  ) : (
                    <MoonIcon className="h-4 w-4 text-gray-600" />
                  )}
                </motion.button>

                {/* Menu Button */}
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  className={glassStyles + ' p-2 rounded-full'}
                  aria-label="Toggle menu"
                >
                  {isMenuOpen ? (
                    <XMarkIcon className="h-4 w-4" />
                  ) : (
                    <Bars3Icon className="h-4 w-4" />
                  )}
                </motion.button>
              </>
            ) : (
              <>
                {/* Theme Toggle for Desktop */}
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={toggleDarkMode}
                  className={`
                    p-2 rounded-full
                    ${glassStyles}
                  `}
                  aria-label="Toggle theme"
                >
                  {isDarkMode ? (
                    <SunIcon className="h-4 w-4 text-gray-300" />
                  ) : (
                    <MoonIcon className="h-4 w-4 text-gray-600" />
                  )}
                </motion.button>

                {/* Login Button for Desktop */}
                <Link
                  to="/login"
                  className={`
                    px-4 py-2 rounded-lg text-sm font-medium
                    ${isDarkMode 
                      ? 'text-gray-200 hover:bg-white/5' 
                      : 'text-gray-700 hover:bg-black/5'}
                    transition-colors duration-200
                  `}
                >
                  Login
                </Link>

                {/* Sign Up for Beta Button for Desktop */}
                <EarlyAccessButton 
                  isDarkMode={isDarkMode} 
                  className="hidden sm:block"
                />
              </>
            )}
          </div>
        </nav>

        {/* Mobile Menu */}
        <AnimatePresence>
          {isMobile && isMenuOpen && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ type: "spring", stiffness: 300, damping: 30 }}
              className="absolute top-20 left-4 right-4 mt-2"
            >
              <div className={mobileMenuStyles}>
                <div className="p-4 space-y-3">
                  {/* Navigation Items */}
                  {navItems.map((item) => (
                    <motion.div
                      key={item.label}
                      whileHover={{ x: 10 }}
                      className="relative"
                    >
                      <button
                        onClick={(e) => handleNavClick(e, item.path)}
                        className={`
                          block w-full text-left py-2.5 px-2 text-sm font-medium rounded-lg
                          ${isDarkMode 
                            ? 'text-gray-200 hover:bg-white/5' 
                            : 'text-gray-700 hover:bg-black/5'}
                          transition-colors duration-200
                        `}
                      >
                        {item.label}
                      </button>
                    </motion.div>
                  ))}

                  {/* Login Button in Mobile Menu */}
                  <motion.div
                    whileHover={{ x: 10 }}
                    className="relative"
                  >
                    <Link
                      to="/login"
                      onClick={() => setIsMenuOpen(false)}
                      className={`
                        group
                        block py-3 px-4 text-sm font-semibold rounded-lg
                        relative inline-flex items-center justify-center
                        overflow-hidden
                        transition-all duration-300
                        ${isDarkMode 
                          ? 'bg-[#1E1E1E] text-white hover:bg-[#252525]' 
                          : 'bg-white text-gray-800 hover:bg-gray-100'}
                        shadow-lg hover:shadow-xl
                      `}
                    >
                      {/* Shimmer effect */}
                      <motion.div
                        variants={shimmerVariants}
                        className={`
                          absolute inset-0 w-1/2 h-full transform -skew-x-12 
                          ${isDarkMode 
                            ? 'bg-gradient-to-r from-transparent via-white/[0.1] to-transparent'
                            : 'bg-gradient-to-r from-transparent via-black/10 to-transparent'
                          }
                        `}
                      />

                      {/* Enhanced hover glow effect */}
                      <div className={`
                        absolute inset-0 opacity-0 group-hover:opacity-100
                        transition-opacity duration-300
                        ${isDarkMode
                          ? 'bg-[radial-gradient(circle_at_50%_50%,rgba(255,255,255,0.1),transparent_70%)]'
                          : 'bg-[radial-gradient(circle_at_50%_50%,rgba(0,0,0,0.1),transparent_70%)]'
                        }
                      `} />

                      {/* Button content */}
                      <div className="flex items-center gap-2 relative z-10">
                        <span className="flex items-center gap-2">
                          <span>Login</span>
                        </span>
                      </div>

                      {/* Focus ring */}
                      <motion.div 
                        className={`
                          absolute inset-0 rounded-lg ring-0 
                          group-focus-visible:ring-2 
                          ${isDarkMode 
                            ? 'ring-white/30 ring-offset-[#121212]' 
                            : 'ring-gray-400/50 ring-offset-white'
                          } 
                          ring-offset-2
                        `}
                        initial={{ opacity: 0 }}
                        whileFocus={{ opacity: 1 }}
                        transition={{ duration: 0.2 }}
                      />
                    </Link>
                  </motion.div>

                  {/* Sign Up for Beta Button in Mobile Menu */}
                  <EarlyAccessButton 
                    isDarkMode={isDarkMode}
                    isMobile={true}
                    onClick={() => setIsMenuOpen(false)}
                    className="pt-2"
                  />
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.header>
  );
};

export default LPHeader;
