import React, { useState, useEffect, useCallback, useRef } from 'react';
import { XMarkIcon, MicrophoneIcon, CheckIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from '../context/ThemeContext';
import { fetchVoiceNotes } from '../api';
import { motion } from 'framer-motion';
import AddToChat from './common/AddToChat';

const VoiceNoteSelector = ({ onSelect, onClose, initialSelectedNotes = [] }) => {
  const { isDarkMode } = useTheme();
  const isMobile = useMediaQuery({ maxWidth: 639 });
  const isTablet = useMediaQuery({ minWidth: 640, maxWidth: 1023 });
  const [voiceNotes, setVoiceNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedNotes, setSelectedNotes] = useState(initialSelectedNotes);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();

  // Update the loadVoiceNotes function
  const loadVoiceNotes = useCallback(async (pageToLoad = 1) => {
    try {
      setLoading(true);
      const { voiceNotes: newNotes, totalPages, currentPage } = await fetchVoiceNotes(
        [], 
        pageToLoad, 
        15
      );
      
      if (pageToLoad === 1) {
        setVoiceNotes(newNotes);
      } else {
        setVoiceNotes(prev => [...prev, ...newNotes]);
      }
      
      setHasMore(currentPage < totalPages);
    } catch (error) {
      console.error('Error fetching voice notes:', error);
      setError('Failed to load voice notes. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  // Improved intersection observer
  const lastNoteElementRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => {
          const nextPage = prevPage + 1;
          loadVoiceNotes(nextPage);
          return nextPage;
        });
      }
    });

    if (node) observer.current.observe(node);
  }, [loading, hasMore, loadVoiceNotes]);

  // Update truncation to be more aggressive for narrow viewport
  const truncateTranscription = (text, maxLength = 95) => {
    if (!text) return 'No transcription available';
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + '...';
  };

  const truncateTitle = (title, maxLength = 35) => {
    if (!title) return 'Untitled';
    if (title.length <= maxLength) return title;
    return title.substr(0, maxLength) + '...';
  };

  const handleNoteSelection = (note) => {
    setSelectedNotes(prevSelected => {
      const isAlreadySelected = prevSelected.some(selected => selected._id === note._id);
      if (isAlreadySelected) {
        return prevSelected.filter(selected => selected._id !== note._id);
      } else {
        const completeNote = {
          _id: note._id,
          title: note.title,
          transcription: note.transcription,
          audioUrl: note.audioUrl,
          createdAt: note.createdAt
        };
        return [...prevSelected, completeNote];
      }
    });
  };

  const handleConfirmSelection = () => {
    const completeNotes = selectedNotes.map(note => ({
      _id: note._id,
      title: note.title,
      transcription: note.transcription,
      audioUrl: note.audioUrl,
      createdAt: note.createdAt
    }));
    onSelect(completeNotes);
    onClose();
  };

  useEffect(() => {
    loadVoiceNotes(1);
  }, []); // Add initial load effect

  if (error) {
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className={`
          ${isDarkMode ? 'bg-slate-800 text-slate-200' : 'bg-white text-slate-800'}
          w-full h-full md:w-11/12 md:h-[90vh] md:max-w-2xl md:rounded-lg
          flex flex-col overflow-hidden shadow-lg
        `}>
          <div className="flex-1 flex items-center justify-center p-4">
            <div className="text-center">
              <p className={isDarkMode ? 'text-slate-300' : 'text-gray-600'}>{error}</p>
              <button
                onClick={() => loadVoiceNotes(1)}
                className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Retry
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="absolute inset-0 z-50 flex">
      <div className="flex-1 h-full">
        <motion.div 
          className={`flex flex-col h-full relative overflow-hidden
            ${isDarkMode ? 'bg-[#121212]' : 'bg-white'}
            border-none rounded-none`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          {/* Header */}
          <div className={`
            ${isDarkMode ? 'bg-[#151515] border-[#333333]' : 'bg-white border-gray-200'} 
            !h-10 min-h-10 flex-shrink-0 flex items-center justify-between px-4 py-2 w-full 
            border-b`}
          >
            <div className="flex items-center">
              <MicrophoneIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'} mr-2`} />
              <h2 className={`text-sm font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>
                Voice Notes
              </h2>
            </div>
            <button 
              onClick={onClose}
              className={`p-1 rounded-full transition-colors duration-200
                ${isDarkMode ? 'hover:bg-[#1e1e1e]' : 'hover:bg-gray-100'}`}
              title="Back to Chat"
            >
              <ArrowLeftIcon className={`w-5 h-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
            </button>
          </div>

          {/* Content Area */}
          <div className={`
            flex-grow overflow-y-auto custom-scrollbar
            ${isDarkMode ? 'bg-[#121212]' : 'bg-white'}
          `}>
            {voiceNotes.length === 0 && !loading ? (
              <div className="flex flex-col items-center justify-center h-full p-4">
                <MicrophoneIcon className={`h-10 w-10 mb-3 ${isDarkMode ? 'text-slate-500' : 'text-gray-400'}`} />
                <p className={`text-center text-sm ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
                  No voice notes available
                </p>
              </div>
            ) : (
              <div className="px-4 py-3">
                {voiceNotes.map((note, index) => (
                  <button
                    key={note._id}
                    ref={index === voiceNotes.length - 1 ? lastNoteElementRef : null}
                    onClick={() => handleNoteSelection(note)}
                    className={`
                      w-full text-left p-3 mb-2.5 flex items-center
                      transition-colors duration-200 rounded-md
                      ${isDarkMode 
                        ? 'hover:bg-[#1e1e1e] active:bg-[#202020]' 
                        : 'hover:bg-gray-50 active:bg-gray-100'}
                      ${selectedNotes.some(selected => selected._id === note._id)
                        ? isDarkMode
                          ? 'bg-[#1e1e1e] border border-[#333333]'
                          : 'bg-blue-50 border border-blue-200'
                        : 'border border-transparent'}
                    `}
                  >
                    <MicrophoneIcon className={`w-5 h-5 mr-3 flex-shrink-0 ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`} />
                    <div className="flex-grow min-w-0">
                      <h3 className={`font-medium text-sm mb-1 truncate ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>
                        {truncateTitle(note.title)}
                      </h3>
                      <p className={`text-xs line-clamp-2 ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
                        {truncateTranscription(note.transcription, 115)}
                      </p>
                    </div>
                    {selectedNotes.some(selected => selected._id === note._id) && (
                      <CheckIcon className={`h-5 w-5 ml-3 flex-shrink-0 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`} />
                    )}
                  </button>
                ))}
                {loading && (
                  <div className="py-4 text-center">
                    <div className="inline-block animate-spin rounded-full h-5 w-5 border-2 border-t-transparent border-blue-500"></div>
                  </div>
                )}
              </div>
            )}
          </div>

          {/* Footer */}
          <div className={`
            px-3 py-2 sticky bottom-0
            ${isDarkMode ? 'bg-[#151515] border-[#333333]' : 'bg-white border-gray-200'}
            border-t
          `}>
            <AddToChat
              onClick={handleConfirmSelection}
              disabled={selectedNotes.length === 0}
              selectedCount={selectedNotes.length}
            />
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default VoiceNoteSelector;
