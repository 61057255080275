import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables. Please check your .env file');
}

const supabase = createClient(supabaseUrl, supabaseAnonKey);

export const signUpWithEmail = async (email, password, firstName, lastName) => {
  try {
    console.log('Starting Supabase signup process...');
    
    // Sign up with Supabase
    const { data: authData, error: authError } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: {
          firstName,
          lastName,
        },
        emailRedirectTo: `${window.location.origin}/auth/callback`,
      },
    });

    console.log('Supabase signup response:', authData);

    if (authError) {
      console.error('Supabase signup error:', authError);
      throw authError;
    }

    // Check if the user was created
    if (!authData?.user) {
      throw new Error('No user data returned from signup');
    }

    // Return the response
    return {
      supabaseUser: authData.user,
      session: authData.session,
      message: 'Please check your email for the verification link'
    };
  } catch (error) {
    console.error('Supabase signup error:', error);
    throw error;
  }
};

export const signInWithEmail = async (email, password) => {
  try {
    const { data: { user, session }, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) throw error;

    // Check if email is verified
    if (!user.email_confirmed_at) {
      throw new Error('Please verify your email before signing in');
    }

    return { user, session };
  } catch (error) {
    console.error('Supabase signin error:', error);
    throw error;
  }
};

export const resendVerificationEmail = async (email) => {
  try {
    const { error } = await supabase.auth.resend({
      type: 'signup',
      email,
      options: {
        emailRedirectTo: `${window.location.origin}/auth/callback`
      }
    });

    if (error) throw error;
    return { message: 'Verification email resent successfully' };
  } catch (error) {
    console.error('Error resending verification email:', error);
    throw error;
  }
};

export const verifyEmail = async (token) => {
  try {
    const { error } = await supabase.auth.verifyOtp({
      token_hash: token,
      type: 'email'
    });

    if (error) throw error;
    return { message: 'Email verified successfully' };
  } catch (error) {
    console.error('Error verifying email:', error);
    throw error;
  }
};

export const signOut = async () => {
  try {
    const { error } = await supabase.auth.signOut();
    if (error) throw error;
  } catch (error) {
    console.error('Supabase signout error:', error);
    throw error;
  }
};

export const getCurrentSession = async () => {
  try {
    const { data: { session }, error } = await supabase.auth.getSession();
    if (error) throw error;
    return session;
  } catch (error) {
    console.error('Error getting session:', error);
    return null;
  }
};

export const onAuthStateChange = (callback) => {
  return supabase.auth.onAuthStateChange(callback);
};

export const handleEmailConfirmation = async (token) => {
  try {
    console.log('Handling email confirmation with token:', token);
    const { data, error } = await supabase.auth.verifyOtp({
      token_hash: token,
      type: 'email'
    });

    if (error) throw error;

    return {
      success: true,
      message: 'Email verified successfully'
    };
  } catch (error) {
    console.error('Email confirmation error:', error);
    throw error;
  }
};

export default supabase;
