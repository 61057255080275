import React from 'react';
import { motion } from 'framer-motion';
import { useTheme } from '../../../context/ThemeContext';
import useScreenSize from '../../../hooks/useScreenSize';
import FAQItem from '../shared/FAQItem';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

const FAQSection = () => {
  const { isDarkMode } = useTheme();
  const { isMobile } = useScreenSize();

  const faqs = [
    {
      question: "How does the AI learn my writing style?",
      answer: "Our AI analyzes your voice notes, existing content, and writing patterns to understand your unique communication style. It captures your tone, vocabulary preferences, and expertise to generate content that authentically represents you."
    },
    {
      question: "Will the content maintain my authentic voice?",
      answer: "Absolutely! We use advanced voice matching technology to ensure all generated content maintains your natural speaking style and expertise level. You have full editorial control to review and refine any content before posting."
    },
    {
      question: "How is this different from other AI writing tools?",
      answer: "Unlike generic AI writers, we focus on maintaining your authentic voice through voice pattern analysis and personal context integration. Our AI learns from your expertise and adapts to your specific industry and audience."
    }
  ];

  const MobileFAQ = () => (
    <div id="faq-section" className={`py-16 relative overflow-hidden ${
      isDarkMode ? 'bg-[#121212]/0' : 'bg-white/0'
    }`}>
      <div className="px-4">
        {/* FAQ Tag */}
        <div className="mb-6">
          <span className={`inline-block px-3 py-1.5 text-xs font-medium uppercase tracking-wider rounded-full ${
            isDarkMode 
              ? 'bg-slate-800/80 text-slate-300 border border-slate-700/50' 
              : 'bg-white text-slate-600 border border-slate-200'
          }`}>
            FAQ
          </span>
        </div>

        <h2 className={`text-2xl font-semibold mb-8 ${
          isDarkMode ? 'text-white' : 'text-slate-900'
        }`}>
          Common Questions{' '}
          <span className={
            isDarkMode 
              ? 'text-white/60' 
              : 'text-slate-900/60'
          }>
            About Ammmplify
          </span>
        </h2>

        {/* FAQ Items */}
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <FAQItem 
              key={index}
              question={faq.question}
              answer={faq.answer}
            />
          ))}
        </div>

        {/* Support CTA */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className={`
            mt-8 p-6 rounded-xl text-center
            ${isDarkMode 
              ? 'bg-[#1a1a1a]/90 border border-white/10' 
              : 'bg-white/90 border border-black/5'
            }
          `}
        >
          <h3 className={`text-lg font-semibold mb-3 ${
            isDarkMode ? 'text-slate-200' : 'text-slate-900'
          }`}>
            Still have questions?
          </h3>
          <p className={`text-sm mb-4 ${
            isDarkMode ? 'text-slate-400' : 'text-slate-600'
          }`}>
            Our team is here to help you get started
          </p>
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className={`
              px-6 py-2 rounded-full text-sm font-medium
              transition-all duration-300
              ${isDarkMode
                ? 'bg-blue-500 text-white hover:bg-blue-600'
                : 'bg-blue-600 text-white hover:bg-blue-700'
              }
              shadow-lg hover:shadow-xl
            `}
          >
            Contact Support
          </motion.button>
        </motion.div>
      </div>
    </div>
  );

  const DesktopFAQ = () => (
    <div id="faq-section" className={`py-24 sm:py-32 relative overflow-hidden ${
      isDarkMode ? 'bg-[#121212]/0' : 'bg-white/0'
    }`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Section Header */}
        <div className="flex items-center justify-between mb-16">
          <h2 className={`
            text-3xl sm:text-4xl font-semibold tracking-tight leading-tight sm:leading-normal
            ${isDarkMode ? 'text-white' : 'text-slate-900'}
          `}>
            Common Questions{' '}
            <span className={`
              ${isDarkMode 
                ? 'text-white/60' 
                : 'text-slate-900/60'
              }
            `}>
              About Ammmplify
            </span>
          </h2>
          
          <span className={`inline-block px-3 py-1.5 text-xs font-medium uppercase tracking-wider rounded-full ${
            isDarkMode 
              ? 'bg-slate-800/80 text-slate-300 border border-slate-700/50' 
              : 'bg-white text-slate-600 border border-slate-200'
          }`}>
            FAQ
          </span>
        </div>

        <div className="grid grid-cols-12 gap-12">
          {/* FAQ Items Column */}
          <div className="col-span-7 space-y-6">
            {faqs.map((faq, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <FAQItem 
                  question={faq.question}
                  answer={faq.answer}
                />
              </motion.div>
            ))}
          </div>

          {/* Support CTA Column */}
          <div className="col-span-5">
            <div className="sticky top-24">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
                className={`
                  p-6 rounded-lg
                  ${isDarkMode 
                    ? 'bg-[#1a1a1a]/70 border border-white/10' 
                    : 'bg-white/70 border border-black/5'
                  }
                `}
              >
                <div className="flex items-center gap-4">
                  <div className={`
                    flex-shrink-0 p-2 rounded-full
                    ${isDarkMode ? 'bg-blue-500/10' : 'bg-blue-50'}
                  `}>
                    <QuestionMarkCircleIcon className={`
                      w-5 h-5
                      ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}
                    `} />
                  </div>
                  
                  <div className="flex-1 min-w-0">
                    <h3 className={`text-sm font-medium mb-1 ${
                      isDarkMode ? 'text-slate-200' : 'text-slate-900'
                    }`}>
                      Still have questions?
                    </h3>
                    <p className={`text-xs mb-0 truncate ${
                      isDarkMode ? 'text-slate-400' : 'text-slate-600'
                    }`}>
                      Our team is here to help
                    </p>
                  </div>

                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    className={`
                      flex-shrink-0
                      px-4 py-2 rounded-full text-xs font-medium
                      transition-all duration-300
                      ${isDarkMode
                        ? 'bg-blue-500 text-white hover:bg-blue-600'
                        : 'bg-blue-600 text-white hover:bg-blue-700'
                      }
                      shadow-sm hover:shadow-md
                    `}
                  >
                    Contact
                  </motion.button>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>

      {/* Background Gradient */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <motion.div 
          animate={{ 
            scale: [1, 1.2, 1],
            rotate: [0, 90, 0] 
          }}
          transition={{ 
            duration: 20,
            repeat: Infinity,
            ease: "linear"
          }}
          className={`absolute -top-1/4 -right-1/4 w-1/2 h-1/2 bg-gradient-to-br ${
            isDarkMode 
              ? 'from-purple-500/5 via-blue-500/5 to-transparent' 
              : 'from-purple-100/30 via-blue-100/30 to-transparent'
          } blur-3xl rounded-full`}
        />
      </div>
    </div>
  );

  return isMobile ? <MobileFAQ /> : <DesktopFAQ />;
};

export default FAQSection; 