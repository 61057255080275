import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  PlusIcon, 
  CheckCircleIcon,
  XMarkIcon,
  SparklesIcon,
  ExclamationTriangleIcon,
  ArrowPathIcon,
  RocketLaunchIcon,
  ClipboardDocumentIcon,
  ClipboardDocumentCheckIcon,
  CheckIcon,
  XCircleIcon,
  ChevronDownIcon,
  VideoCameraIcon
} from '@heroicons/react/24/outline';
import { useTheme } from '../../context/ThemeContext';
import api, { generateContentStrategy, getYoutubeTranscript } from '../../api';
import { toast } from 'react-toastify';

const STORAGE_KEY = 'contentStrategy_suggestedTopics';
const STORAGE_KEY_CUSTOM = 'contentStrategy_customTopics';

const truncateWords = (text, limit = 15) => {
  const words = text.split(' ');
  if (words.length > limit) {
    return words.slice(0, limit).join(' ') + '...';
  }
  return text;
};

const TopicSuggestions = ({ 
  persona, 
  audience,
  onSubmit,
  loading,
  selectedTopics,
  setSelectedTopics
}) => {
  const { isDarkMode } = useTheme();
  const [suggestedTopics, setSuggestedTopics] = useState(() => {
    const stored = localStorage.getItem(STORAGE_KEY);
    return stored ? JSON.parse(stored) : [];
  });
  const [customTopic, setCustomTopic] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const [customTopics, setCustomTopics] = useState(() => {
    const stored = localStorage.getItem(STORAGE_KEY_CUSTOM);
    return stored ? JSON.parse(stored) : [];
  });
  const [expandedCategories, setExpandedCategories] = useState({});
  const inputRef = useRef(null);
  const [isLoadingTranscript, setIsLoadingTranscript] = useState(false);
  const [showYoutubeInput, setShowYoutubeInput] = useState(false);
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const youtubeInputRef = useRef(null);

  useEffect(() => {
    if (suggestedTopics.length > 0) {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(suggestedTopics));
    }
  }, [suggestedTopics]);

  useEffect(() => {
    if (customTopics.length > 0) {
      localStorage.setItem(STORAGE_KEY_CUSTOM, JSON.stringify(customTopics));
    }
  }, [customTopics]);

  useEffect(() => {
    if (suggestedTopics.length === 0) {
      generateTopicSuggestions();
    }
  }, []);

  useEffect(() => {
    if (suggestedTopics.length > 0) {
      const categories = suggestedTopics.reduce((acc, topic) => {
        const category = topic.includes(':') ? topic.split(':')[0].trim() : 'General';
        acc[category] = true; // Set to true to expand all categories by default
        return acc;
      }, {});
      setExpandedCategories(categories);
    }
  }, [suggestedTopics]);

  const generateTopicSuggestions = async () => {
    setIsLoading(true);
    setError(null);
    
    if (!persona || !audience) {
      setError('Missing persona or audience information');
      setIsLoading(false);
      return;
    }

    const maxAttempts = 3;
    let attempt = 0;

    while (attempt < maxAttempts) {
      try {
        const response = await api.post('/api/content-strategy/suggest-topics', {
          persona: {
            name: persona?.name || '',
            occupation: persona?.occupation || '',
            expertiseLevel: persona?.expertiseLevel || '',
            contentGoals: persona?.contentGoals || '',
            writingStyle: persona?.writingStyle || 'Professional',
          },
          audience: {
            targetAudienceName: audience?.targetAudienceName || '',
            industry: audience?.industry || '',
            primaryGoal: audience?.primaryGoal || [],
            painPoints: audience?.painPoints || [],
          }
        });
        
        if (Array.isArray(response.data?.topics)) {
          setSuggestedTopics(response.data.topics);
          setExpandedCategories({});
          localStorage.removeItem(STORAGE_KEY);
          break;
        } else {
          throw new Error('Invalid topics data received');
        }
      } catch (error) {
        attempt++;
        const shouldRetry = error.response?.data?.shouldRetry || error.response?.status === 529;
        
        if (shouldRetry && attempt < maxAttempts) {
          const delay = Math.pow(2, attempt) * 1000;
          await new Promise(resolve => setTimeout(resolve, delay));
          continue;
        }

        console.error('Error generating topic suggestions:', error);
        setError(
          error.response?.data?.message || 
          error.message || 
          'Failed to generate topics. Please try again.'
        );
        setSuggestedTopics([]);
        break;
      }
    }
    
    setIsLoading(false);
  };

  const handleTopicSelect = (topic) => {
    setSelectedTopics(prev => {
      const isSelected = prev.includes(topic);
      if (isSelected) {
        return prev.filter(t => t !== topic);
      }
      return [...prev, topic];
    });
  };

  const handleAddCustomTopic = () => {
    if (customTopic.trim()) {
      const newTopic = customTopic.trim();
      const topicExists = [
        ...suggestedTopics,
        ...customTopics
      ].some(topic => topic.toLowerCase() === newTopic.toLowerCase());

      if (!topicExists) {
        const updatedCustomTopics = [...customTopics, newTopic];
        setCustomTopics(updatedCustomTopics);
        setSelectedTopics(prev => [...prev, newTopic]);
        setCustomTopic('');
        localStorage.setItem(STORAGE_KEY_CUSTOM, JSON.stringify(updatedCustomTopics));
        inputRef.current?.focus();
      } else {
        toast.warning('This topic already exists', {
          icon: '⚠️',
          position: 'bottom-center',
          className: isDarkMode ? 'dark-toast' : ''
        });
      }
    }
  };

  const handleSubmit = async () => {
    try {
      if (!Array.isArray(selectedTopics) || selectedTopics.length === 0) {
        throw new Error('Please select at least one topic');
      }

      if (!persona || !audience) {
        throw new Error('Missing persona or audience information');
      }

      // Call onSubmit with the selected topics
      onSubmit(selectedTopics);
    } catch (error) {
      console.error('Error submitting topics:', error);
      toast.error(error.message || 'Failed to submit topics');
    }
  };

  const handleCopyAll = async () => {
    try {
      const topicsText = suggestedTopics.join('\n');
      await navigator.clipboard.writeText(topicsText);
      setIsCopied(true);
      
      // Reset copy status after 2 seconds
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
      
      toast.success('Topics copied to clipboard!');
    } catch (error) {
      console.error('Failed to copy topics:', error);
      toast.error('Failed to copy topics');
    }
  };

  const handleSelectAll = () => {
    setSelectedTopics([...new Set([...suggestedTopics, ...customTopics])]);
  };

  const handleUnselectAll = () => {
    setSelectedTopics([]);
  };

  const isCustomTopic = (topic) => {
    return customTopics.includes(topic);
  };

  // Handle key press for textarea
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (!e.shiftKey && customTopic.trim()) {
        e.preventDefault();
        handleAddCustomTopic();
      }
    }
  };

  // Add function to auto-resize textarea
  const adjustTextareaHeight = (element) => {
    element.style.height = 'auto';
    element.style.height = (element.scrollHeight) + 'px';
  };

  // Handle textarea input
  const handleTextareaChange = (e) => {
    setCustomTopic(e.target.value);
    adjustTextareaHeight(e.target);
  };

  // Group topics by category
  const groupedTopics = suggestedTopics.reduce((acc, topic) => {
    const category = topic.includes(':') ? topic.split(':')[0].trim() : 'General';
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(topic);
    return acc;
  }, {});

  // Function to extract YouTube video ID
  const extractVideoId = (url) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[7].length === 11) ? match[7] : false;
  };

  // Function to fetch transcript
  const fetchTranscript = async () => {
    try {
      const videoId = extractVideoId(youtubeUrl);
      
      if (!videoId) {
        toast.error('Invalid YouTube URL');
        return;
      }

      setIsLoadingTranscript(true);
      
      const response = await getYoutubeTranscript(videoId);

      if (response?.transcript) {
        setCustomTopic(response.transcript);
        setShowYoutubeInput(false);
        setYoutubeUrl('');
        adjustTextareaHeight(inputRef.current);
      }
    } catch (error) {
      toast.error(error.message || 'Failed to fetch transcript');
    } finally {
      setIsLoadingTranscript(false);
    }
  };

  // Handle YouTube input key press
  const handleYoutubeKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchTranscript();
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="w-full max-w-4xl mx-auto space-y-4 sm:space-y-6 pb-28"
    >
      {/* Main Header - Mobile optimized */}
      <div className="text-center space-y-1.5 sm:space-y-2 px-2">
        <motion.h2 
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          className={`text-xl sm:text-2xl font-bold ${
            isDarkMode ? 'text-slate-200' : 'text-slate-800'
          }`}
        >
          Select Topics to Generate Ideas For
        </motion.h2>
        <motion.p 
          initial={{ opacity: 0, y: -5 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className={`text-xs sm:text-sm ${
            isDarkMode ? 'text-slate-400' : 'text-slate-600'
          }`}
        >
          Choose topics that resonate with your audience and align with your content goals
        </motion.p>
      </div>

      {/* Main content area - Mobile optimized */}
      <div className={`rounded-lg sm:rounded-xl border shadow-sm ${
        isDarkMode 
          ? 'bg-[#151515] border-[#333333]' 
          : 'bg-white border-slate-200'
      }`}>
        <div className="p-3 sm:p-6">
          {/* Header with action buttons - Mobile optimized */}
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-3 sm:gap-0 mb-4 sm:mb-6">
            <div className="flex items-center gap-2">
              <h3 className={`text-sm font-medium ${
                isDarkMode ? 'text-slate-300' : 'text-slate-700'
              }`}>
                Suggested Topics
              </h3>
              {suggestedTopics.length > 0 && (
                <span className={`text-xs px-2 py-0.5 rounded-full ${
                  isDarkMode 
                    ? 'bg-[#1a1a1a] text-slate-400 border border-[#333333]' 
                    : 'bg-slate-100 text-slate-600'
                }`}>
                  {suggestedTopics.length}
                </span>
              )}
            </div>
            
            <div className="flex items-center gap-2 overflow-x-auto hide-scrollbar">
              {suggestedTopics.length > 0 && (
                <>
                  <div className="flex items-center gap-2">
                    <motion.button
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      onClick={handleCopyAll}
                      disabled={isLoading}
                      className={`text-xs flex items-center gap-1.5 px-2 sm:px-2.5 py-1.5 rounded-md transition-all whitespace-nowrap ${
                        isDarkMode
                          ? 'bg-[#1a1a1a] text-slate-300 hover:bg-[#202020] border border-[#333333]'
                          : 'bg-slate-100 text-slate-700 hover:bg-slate-200'
                      } ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                      {isCopied ? (
                        <ClipboardDocumentCheckIcon className="w-3.5 h-3.5 text-green-500" />
                      ) : (
                        <ClipboardDocumentIcon className="w-3.5 h-3.5" />
                      )}
                      <span className="hidden sm:inline">Copy All</span>
                      <span className="sm:hidden">Copy</span>
                    </motion.button>
                    
                    <motion.button
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      onClick={handleSelectAll}
                      disabled={isLoading}
                      className={`text-xs flex items-center gap-1.5 px-2 sm:px-2.5 py-1.5 rounded-md transition-all whitespace-nowrap ${
                        isDarkMode
                          ? 'bg-[#1a1a1a] text-slate-300 hover:bg-[#202020] border border-[#333333]'
                          : 'bg-slate-100 text-slate-700 hover:bg-slate-200'
                      } ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                      <CheckIcon className="w-3.5 h-3.5" />
                      <span className="hidden sm:inline">Select All</span>
                      <span className="sm:hidden">All</span>
                    </motion.button>

                    <motion.button
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      onClick={handleUnselectAll}
                      disabled={!selectedTopics.length}
                      className={`text-xs flex items-center gap-1.5 px-2 sm:px-2.5 py-1.5 rounded-md transition-all whitespace-nowrap ${
                        isDarkMode
                          ? 'bg-[#1a1a1a] text-slate-300 hover:bg-[#202020] border border-[#333333]'
                          : 'bg-slate-100 text-slate-700 hover:bg-slate-200'
                      } ${!selectedTopics.length ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                      <XCircleIcon className="w-3.5 h-3.5" />
                      <span className="hidden sm:inline">Clear</span>
                      <span className="sm:hidden">Clear</span>
                    </motion.button>
                  </div>

                  {/* Separator */}
                  <div className={`h-6 w-px ${isDarkMode ? 'bg-[#333333]' : 'bg-slate-200'}`} />

                  {/* Regenerate button */}
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={generateTopicSuggestions}
                    disabled={isLoading}
                    className={`text-xs flex items-center gap-1.5 px-2 sm:px-2.5 py-1.5 rounded-md transition-all whitespace-nowrap ${
                      isDarkMode
                        ? 'bg-blue-500/20 text-blue-300 hover:bg-blue-500/30 border border-blue-500/30'
                        : 'bg-blue-50 text-blue-600 hover:bg-blue-100 border border-blue-200'
                    } ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                  >
                    <SparklesIcon className={`w-3.5 h-3.5 ${isLoading ? 'animate-spin' : ''}`} />
                    {isLoading ? '...' : 'New'}
                  </motion.button>
                </>
              )}
            </div>
          </div>

          {/* Add Topic Input - Mobile optimized */}
          <div className="mb-4 sm:mb-6">
            <div className="flex flex-col gap-3">
              {/* Main Input Row */}
              <div className="flex flex-col sm:flex-row gap-2">
                <div className="flex-1 relative group">
                  <textarea
                    ref={inputRef}
                    value={customTopic}
                    onChange={handleTextareaChange}
                    onKeyPress={handleKeyPress}
                    placeholder="Add your own topic or paste content..."
                    rows={1}
                    className={`w-full px-3 sm:px-4 py-2 sm:py-2.5 rounded-md border text-sm transition-colors 
                      focus:outline-none focus:ring-2 resize-y
                      ${isDarkMode
                        ? 'bg-[#1a1a1a] border-[#333333] text-slate-200 placeholder-slate-400 focus:ring-blue-500/30'
                        : 'bg-white border-slate-200 text-slate-900 placeholder-slate-400 focus:ring-blue-500/20'
                      }
                    `}
                    style={{ 
                      height: '38px',
                      minHeight: '38px', 
                      maxHeight: '200px',
                      overflow: customTopic.length > 0 ? 'auto' : 'hidden'
                    }}
                  />
                  <div className={`
                    absolute right-3 bottom-2.5
                    text-[10px] font-medium tracking-wide
                    transition-opacity duration-200
                    ${isDarkMode ? 'text-slate-600' : 'text-slate-400'}
                    opacity-0 group-hover:opacity-100
                    pointer-events-none
                  `}>
                    SHIFT + ↵
                  </div>
                </div>

                {/* Action Buttons Container */}
                <div className="flex items-start gap-2">
                  {/* Add Topic Button */}
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={handleAddCustomTopic}
                    disabled={!customTopic.trim()}
                    className={`h-[38px] px-3 sm:px-4 rounded-md transition-colors flex items-center gap-1.5 flex-1 sm:flex-none justify-center
                      ${customTopic.trim()
                        ? isDarkMode
                          ? 'bg-blue-500/20 text-blue-300 hover:bg-blue-500/30 border border-blue-500/30'
                          : 'bg-blue-500 text-white hover:bg-blue-600'
                        : isDarkMode
                          ? 'bg-[#151515] text-slate-400 border border-[#333333]'
                          : 'bg-slate-100 text-slate-400'
                      }
                    `}
                  >
                    <PlusIcon className="w-4 h-4" />
                    <span className="text-xs font-medium">Add Topic</span>
                  </motion.button>

                  {/* YouTube Toggle Button */}
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={() => {
                      if (showYoutubeInput) {
                        setYoutubeUrl('');
                      }
                      setShowYoutubeInput(!showYoutubeInput);
                      if (!showYoutubeInput) {
                        setTimeout(() => youtubeInputRef.current?.focus(), 100);
                      }
                    }}
                    className={`h-[38px] px-3 sm:px-4 rounded-md transition-colors flex items-center gap-1.5
                      ${showYoutubeInput
                        ? isDarkMode
                          ? 'bg-red-500/20 text-red-300 border border-red-500/30'
                          : 'bg-red-500 text-white'
                        : isDarkMode
                          ? 'bg-[#1a1a1a] text-slate-400 hover:text-slate-300 border border-[#333333]'
                          : 'bg-slate-100 text-slate-600 hover:bg-slate-200'
                      }
                    `}
                  >
                    <VideoCameraIcon className="w-4 h-4" />
                    <span className="text-xs font-medium hidden sm:inline">
                      {showYoutubeInput ? 'Cancel' : 'YouTube'}
                    </span>
                  </motion.button>
                </div>
              </div>

              {/* YouTube Input Row - Mobile optimized */}
              <AnimatePresence>
                {showYoutubeInput && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    className="overflow-hidden"
                  >
                    <div className={`p-3 sm:p-4 rounded-md border ${
                      isDarkMode
                        ? 'bg-[#151515] border-[#333333]'
                        : 'bg-slate-50 border-slate-200'
                    }`}>
                      <div className="flex flex-col sm:flex-row gap-2">
                        <div className="flex-1 relative">
                          <input
                            ref={youtubeInputRef}
                            type="text"
                            value={youtubeUrl}
                            onChange={(e) => setYoutubeUrl(e.target.value)}
                            onKeyPress={handleYoutubeKeyPress}
                            placeholder="Paste YouTube URL here..."
                            className={`w-full px-3 sm:px-4 h-[38px] rounded-md border text-sm transition-colors 
                              focus:outline-none focus:ring-2
                              ${isDarkMode
                                ? 'bg-[#1a1a1a] border-[#333333] text-slate-200 placeholder-slate-400 focus:ring-red-500/30'
                                : 'bg-white border-slate-200 text-slate-900 placeholder-slate-400 focus:ring-red-500/20'
                              }
                            `}
                          />
                          {isLoadingTranscript && (
                            <div className="absolute right-3 top-1/2 -translate-y-1/2">
                              <ArrowPathIcon className="w-4 h-4 animate-spin text-red-500" />
                            </div>
                          )}
                        </div>

                        <motion.button
                          whileHover={{ scale: 1.02 }}
                          whileTap={{ scale: 0.98 }}
                          onClick={fetchTranscript}
                          disabled={!youtubeUrl.trim() || isLoadingTranscript}
                          className={`h-[38px] px-4 sm:px-6 rounded-md transition-colors flex items-center gap-2 justify-center
                            ${youtubeUrl.trim() && !isLoadingTranscript
                              ? isDarkMode
                                ? 'bg-red-500/20 text-red-300 hover:bg-red-500/30 border border-red-500/30'
                                : 'bg-red-500 text-white hover:bg-red-600'
                              : isDarkMode
                                ? 'bg-[#151515] text-slate-400 border border-[#333333]'
                                : 'bg-slate-100 text-slate-400'
                            }
                          `}
                        >
                          <RocketLaunchIcon className="w-4 h-4" />
                          <span className="text-xs font-medium">
                            {isLoadingTranscript ? 'Fetching...' : 'Get Transcript'}
                          </span>
                        </motion.button>
                      </div>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>

          {/* Error message - Mobile optimized */}
          {error && (
            <motion.div 
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              className={`p-3 sm:p-4 rounded-md mb-4 sm:mb-6 ${
                isDarkMode 
                  ? 'bg-red-500/10 text-red-300 border border-red-500/30' 
                  : 'bg-red-50 text-red-600 border border-red-200'
              }`}
            >
              <div className="flex items-center justify-between flex-wrap gap-2">
                <div className="flex items-start gap-2 sm:gap-3">
                  <ExclamationTriangleIcon className="w-4 sm:w-5 h-4 sm:h-5 flex-shrink-0 mt-0.5" />
                  <div>
                    <p className="text-xs sm:text-sm font-medium">{error}</p>
                  </div>
                </div>
                <motion.button 
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={generateTopicSuggestions}
                  className={`text-xs flex items-center gap-1.5 px-2 sm:px-2.5 py-1.5 rounded-md ${
                    isDarkMode 
                      ? 'bg-red-500/20 text-red-300 hover:bg-red-500/30 border border-red-500/30' 
                      : 'bg-red-50 text-red-600 hover:bg-red-100 border border-red-200'
                  }`}
                >
                  <ArrowPathIcon className="w-3.5 h-3.5" />
                  Regenerate
                </motion.button>
              </div>
            </motion.div>
          )}

          {/* Loading state - Mobile optimized */}
          {isLoading ? (
            <div className="space-y-3 sm:space-y-4">
              {[1, 2, 3].map(i => (
                <motion.div 
                  key={i}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: i * 0.1 }}
                  className={`animate-pulse h-[60px] sm:h-[72px] rounded-md ${
                    isDarkMode ? 'bg-[#1a1a1a]' : 'bg-slate-100'
                  }`}
                />
              ))}
            </div>
          ) : (
            <div className="h-[350px] sm:h-[400px] overflow-y-auto custom-scrollbar pr-2">
              <div className="space-y-3 sm:space-y-4">
                {/* Custom Topics Section - Mobile optimized */}
                {customTopics.length > 0 && (
                  <motion.div 
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                  >
                    <div className={`p-4 sm:p-6 rounded-lg sm:rounded-xl border ${
                      isDarkMode 
                        ? 'bg-[#151515] border-[#333333]' 
                        : 'bg-white border-slate-200'
                    }`}>
                      <div className="flex items-center justify-between mb-3 sm:mb-4">
                        <div className="flex items-center gap-2 sm:gap-3">
                          <span className={`text-xs sm:text-sm font-semibold uppercase tracking-wider ${
                            isDarkMode ? 'text-slate-200' : 'text-slate-800'
                          }`}>
                            Custom Topics
                          </span>
                          <span className={`px-1.5 sm:px-2 py-0.5 rounded-full text-[10px] sm:text-xs ${
                            isDarkMode 
                              ? 'bg-[#202020] text-slate-300 border border-[#333333]' 
                              : 'bg-slate-100 text-slate-600'
                          }`}>
                            {customTopics.length}
                          </span>
                        </div>
                      </div>
                      
                      <div className="space-y-2">
                        {customTopics.map((topic, index) => (
                          <motion.button
                            key={`custom-${index}`}
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: index * 0.05 }}
                            onClick={() => handleTopicSelect(topic)}
                            className={`group w-full flex items-center justify-between p-2.5 sm:p-3.5 rounded-lg border transition-all text-left ${
                              selectedTopics.includes(topic)
                                ? isDarkMode
                                  ? 'bg-purple-500/20 border-purple-500/30 text-purple-300'
                                  : 'bg-purple-50 border-purple-200 text-purple-700'
                                : isDarkMode
                                  ? 'bg-[#1a1a1a] border-[#333333] text-slate-300 hover:bg-[#202020]'
                                  : 'bg-slate-50 border-slate-200 text-slate-700 hover:bg-slate-100'
                            }`}
                          >
                            <div className="flex items-center gap-2 sm:gap-3 flex-1 min-w-0">
                              <span className="text-xs sm:text-sm truncate flex-1" title={topic}>
                                {truncateWords(topic)}
                              </span>
                              <span className={`text-[10px] sm:text-xs px-1.5 sm:px-2 py-0.5 rounded-full flex-shrink-0 ${
                                isDarkMode
                                  ? 'bg-purple-500/30 text-purple-300'
                                  : 'bg-purple-100 text-purple-600'
                              }`}>
                                Custom
                              </span>
                            </div>
                            <div className="flex items-center gap-2">
                              <XMarkIcon 
                                className={`w-4 sm:w-5 h-4 sm:h-5 flex-shrink-0 transition-colors ${
                                  isDarkMode ? 'hover:text-red-400' : 'hover:text-red-500'
                                }`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setCustomTopics(prev => prev.filter(t => t !== topic));
                                  setSelectedTopics(prev => prev.filter(t => t !== topic));
                                }}
                              />
                            </div>
                          </motion.button>
                        ))}
                      </div>
                    </div>
                  </motion.div>
                )}

                {/* Suggested Topics - Mobile optimized */}
                {Object.entries(groupedTopics).map(([category, topics], categoryIndex) => (
                  <motion.div
                    key={category}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: categoryIndex * 0.1 }}
                    className={`p-4 sm:p-6 rounded-lg sm:rounded-xl border ${
                      isDarkMode 
                        ? 'bg-[#151515] border-[#333333]' 
                        : 'bg-white border-slate-200'
                    }`}
                  >
                    <div className="flex items-center justify-between mb-3 sm:mb-4">
                      <div className="flex items-center gap-2 sm:gap-3">
                        <span className={`text-xs sm:text-sm font-semibold uppercase tracking-wider ${
                          isDarkMode ? 'text-slate-200' : 'text-slate-800'
                        }`}>
                          {category}
                        </span>
                        <span className={`px-1.5 sm:px-2 py-0.5 rounded-full text-[10px] sm:text-xs ${
                          isDarkMode 
                            ? 'bg-[#202020] text-slate-300 border border-[#333333]' 
                            : 'bg-slate-100 text-slate-600'
                        }`}>
                          {topics.length}
                        </span>
                      </div>
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={() => setExpandedCategories(prev => ({
                          ...prev,
                          [category]: !prev[category]
                        }))}
                        className={`p-1.5 rounded-md transition-colors ${
                          isDarkMode 
                            ? 'hover:bg-[#202020] text-slate-400' 
                            : 'hover:bg-slate-100 text-slate-600'
                        }`}
                      >
                        <ChevronDownIcon 
                          className={`w-4 sm:w-5 h-4 sm:h-5 transition-transform duration-200 ${
                            expandedCategories[category] ? 'transform rotate-180' : ''
                          }`} 
                        />
                      </motion.button>
                    </div>

                    <AnimatePresence>
                      {expandedCategories[category] && (
                        <motion.div
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: 'auto' }}
                          exit={{ opacity: 0, height: 0 }}
                          className="overflow-hidden"
                        >
                          <div className="space-y-2">
                            {topics.map((topic, index) => {
                              const topicContent = topic.includes(':') ? topic.split(':')[1].trim() : topic;
                              
                              return (
                                <motion.button
                                  key={`suggested-${index}`}
                                  initial={{ opacity: 0, y: 10 }}
                                  animate={{ opacity: 1, y: 0 }}
                                  transition={{ delay: index * 0.05 }}
                                  onClick={() => handleTopicSelect(topic)}
                                  className={`group w-full flex items-center justify-between p-2.5 sm:p-3.5 rounded-lg border transition-all text-left ${
                                    selectedTopics.includes(topic)
                                      ? isDarkMode
                                        ? 'bg-blue-500/20 border-blue-500/30 text-blue-300'
                                        : 'bg-blue-50 border-blue-200 text-blue-700'
                                      : isDarkMode
                                        ? 'bg-[#1a1a1a] border-[#333333] text-slate-300 hover:bg-[#202020]'
                                        : 'bg-slate-50 border-slate-200 text-slate-700 hover:bg-slate-100'
                                  }`}
                                >
                                  <span className="text-xs sm:text-sm break-words pr-3 flex-1">
                                    {topicContent}
                                  </span>
                                  <div className="flex-shrink-0">
                                    {selectedTopics.includes(topic) ? (
                                      <CheckCircleIcon className={`w-4 sm:w-5 h-4 sm:h-5 ${
                                        isDarkMode ? 'text-blue-400' : 'text-blue-500'
                                      }`} />
                                    ) : (
                                      <CheckCircleIcon className={`w-4 sm:w-5 h-4 sm:h-5 opacity-0 group-hover:opacity-50 transition-opacity ${
                                        isDarkMode ? 'text-slate-400' : 'text-slate-500'
                                      }`} />
                                    )}
                                  </div>
                                </motion.button>
                              );
                            })}
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </motion.div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default TopicSuggestions; 