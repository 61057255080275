import React from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';

const CreateButton = ({ 
  isDarkMode, 
  isSidebarCollapsed, 
  onClick, 
  icon: Icon = PlusIcon, 
  text = "Create", 
  className = "",
  isActive = false
}) => {
  // Simple tap effect
  const buttonVariants = {
    tap: { 
      scale: 0.98,
      transition: {
        type: "spring",
        stiffness: 500,
        damping: 10
      }
    }
  };

  // Hover-triggered floating animation for icon
  const iconVariants = {
    initial: { y: 0, rotate: 0 },
    hover: {
      y: [-2, 2, -2],
      rotate: [-5, 5, -5],
      transition: {
        y: {
          repeat: Infinity,
          duration: 2.5,
          ease: "easeInOut"
        },
        rotate: {
          repeat: Infinity,
          duration: 3,
          ease: "easeInOut"
        }
      }
    }
  };

  // Hover-triggered shimmer effect
  const shimmerVariants = {
    initial: { x: "-100%" },
    hover: {
      x: "200%",
      transition: {
        repeat: Infinity,
        duration: 2,
        ease: "linear"
      }
    }
  };

  return (
    <motion.button
      onClick={onClick}
      variants={buttonVariants}
      whileTap="tap"
      whileHover="hover"
      className={`
        group
        ${isSidebarCollapsed ? 'w-8 h-8' : 'w-full h-9'} 
        flex items-center justify-center
        rounded-md relative
        overflow-hidden
        ${isDarkMode 
          ? 'bg-gradient-to-r from-[#1E1E1E] to-[#2A2A2A] hover:from-[#2A2A2A] hover:to-[#1E1E1E] text-gray-200 ring-1 ring-[#333333]'
          : 'bg-gradient-to-r from-slate-800 to-slate-900 hover:from-slate-900 hover:to-slate-800 text-white'
        }
        transition-all duration-300
        hover:shadow-[0_4px_25px_rgba(15,23,42,0.45)]
        ${className}
      `}
      aria-label={text}
      title={isSidebarCollapsed ? text : undefined}
    >
      {/* Hover-triggered shimmer effect - only when sidebar is expanded */}
      {!isSidebarCollapsed && (
        <motion.div
          variants={shimmerVariants}
          className={`
            absolute inset-0 w-1/2 h-full transform -skew-x-12 
            ${isDarkMode 
              ? 'bg-gradient-to-r from-transparent via-white/5 to-transparent'
              : 'bg-gradient-to-r from-transparent via-white/10 to-transparent'
            }
          `}
        />
      )}

      {/* Enhanced hover glow effect - only visible on hover */}
      <div className={`
        absolute inset-0 opacity-0 group-hover:opacity-100
        transition-opacity duration-300
        ${isDarkMode
          ? 'bg-[radial-gradient(circle_at_50%_50%,rgba(255,255,255,0.05),transparent_70%)]'
          : 'bg-[radial-gradient(circle_at_50%_50%,rgba(255,255,255,0.1),transparent_70%)]'
        }
      `} />

      <div className="flex items-center justify-center relative z-10">
        <motion.div
          variants={iconVariants}
          className="will-change-transform"
        >
          <Icon className={`
            w-4 h-4 flex-shrink-0
            transition-colors duration-200
            ${isDarkMode ? 'text-gray-200' : 'text-white'}
          `} />
        </motion.div>
        
        {!isSidebarCollapsed && (
          <span className={`
            ml-2 text-xs font-semibold tracking-wide
            ${isDarkMode ? 'text-gray-200' : 'text-white'}
          `}>
            {text}
          </span>
        )}
      </div>

      {/* Focus ring */}
      <motion.div 
        className={`
          absolute inset-0 rounded-md ring-0 
          group-focus-visible:ring-2 
          ${isDarkMode 
            ? 'ring-blue-500/30 ring-offset-[#121212]' 
            : 'ring-slate-400/50 ring-offset-white'
          } 
          ring-offset-2
        `}
        initial={{ opacity: 0 }}
        whileFocus={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
      />
    </motion.button>
  );
};

export default CreateButton;
