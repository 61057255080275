import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import axios from 'axios';
import AudioRecorder from './AudioRecorder';
import TranscriptionGridItem from './TranscriptionGridItem';
import { MicrophoneIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../context/ThemeContext';
import { AuthContext } from '../context/AuthContext';
import api, { fetchVoiceNotes, deleteVoiceNote, updateVoiceNote, fetchTags, addTag, removeTag, editTag, deleteTag, getTagCounts, getSignedUrl } from '../api';
import './scrollbar.css';
import VoiceNotesSkeleton from './VoiceNotesSkeleton';
import TagList from './TagList';
import TagManager from './TagManager';
import ProcessingStatus from './ProcessingStatus';
import { useInView } from 'react-intersection-observer';
import { useLocation, useNavigate } from 'react-router-dom';
import VoiceNotesEmptyState from './VoiceNotesEmptyState';
import { useRecentActivities } from '../context/RecentActivitiesContext';
import VoiceNotePage from './VoiceNotePage';

const TopBar = ({ searchTerm, setSearchTerm, isDarkMode, isSearchExpanded, setIsSearchExpanded }) => (
  <div className={`${
    isDarkMode ? 'bg-[#121212]/95 border-[#333333]' : 'bg-white border-gray-200'
  } bg-opacity-90 h-10 flex-shrink-0 flex items-center justify-between px-4 py-2 w-full border-b border-opacity-50`}>
    {/* Left section */}
    <div className="flex items-center">
      <MicrophoneIcon className={`h-5 w-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'} mr-2`} />
      <h2 className={`text-sm font-medium ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>Voice Notes</h2>
    </div>

    {/* Right section - Search */}
    <div className="flex items-center">
      <ExpandableSearchBar
        isDarkMode={isDarkMode}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        isExpanded={isSearchExpanded}
        setIsExpanded={setIsSearchExpanded}
      />
    </div>
  </div>
);

// Add this new component for the expandable search bar
const ExpandableSearchBar = ({ isDarkMode, searchTerm, setSearchTerm, isExpanded, setIsExpanded }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (isExpanded) {
      inputRef.current?.focus();
    }
  }, [isExpanded]);

  return (
    <div className="relative">
      {isExpanded ? (
        <div className="flex items-center">
          <input
            ref={inputRef}
            type="text"
            placeholder="Search transcriptions..."
            className={`w-40 pl-8 pr-8 py-1 text-xs border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500
                        ${isDarkMode 
                          ? 'bg-[#151515] text-slate-200 border-[#333333]' 
                          : 'bg-white text-gray-900 border-gray-300'}`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <MagnifyingGlassIcon className={`absolute left-2 top-1/2 transform -translate-y-1/2 w-4 h-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-400'}`} />
          <button
            onClick={() => setIsExpanded(false)}
            className="absolute right-2 top-1/2 transform -translate-y-1/2"
          >
            <XMarkIcon className={`w-4 h-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-400'}`} />
          </button>
        </div>
      ) : (
        <button
          onClick={() => setIsExpanded(true)}
          className={`p-1 rounded-full transition-colors duration-200 ${
            isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-200'
          }`}
        >
          <MagnifyingGlassIcon className={`h-5 w-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`} />
        </button>
      )}
    </div>
  );
};

const TranscriptionScreen = ({ setRecordingState, children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [startRecordingOnMount, setStartRecordingOnMount] = useState(false);
  const { isDarkMode } = useTheme();
  const { refreshTokenAndUpdateUser } = useContext(AuthContext);
  const [voiceNotes, setVoiceNotes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const transcriptionsRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState(['All']);
  const [tagCounts, setTagCounts] = useState({});
  const [filteredNotes, setFilteredNotes] = useState([]);

  // Add the missing state variables
  const [isTagManagerOpen, setIsTagManagerOpen] = useState(false);
  const [isAudioRecorderLoading, setIsAudioRecorderLoading] = useState(false);
  const [processingStage, setProcessingStage] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);

  const { refreshActivities } = useRecentActivities();

  useEffect(() => {
    fetchVoiceNotesData();
    fetchTagsData();
    fetchTagCounts();
  }, [selectedTags]);

  useEffect(() => {
    if (searchTerm) {
      const filtered = voiceNotes.filter(note => 
        note.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        note.transcription.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredNotes(filtered);
    } else {
      setFilteredNotes(voiceNotes);
    }
  }, [searchTerm, voiceNotes]);

  const fetchVoiceNotesData = useCallback(async (pageToFetch = 1, retryCount = 0) => {
    try {
      setIsLoading(true);
      let response;
      if (selectedTags.includes('All')) {
        response = await fetchVoiceNotes([], pageToFetch);
      } else {
        response = await fetchVoiceNotes(selectedTags, pageToFetch);
      }

      const { voiceNotes, totalPages, currentPage } = response;

      setVoiceNotes(prevNotes => pageToFetch === 1 ? voiceNotes : [...prevNotes, ...voiceNotes]);
      setHasMore(currentPage < totalPages);
      setIsLoading(false);
      setIsLoadingMore(false);
      setError(null);
    } catch (error) {
      console.error('Error fetching voice notes:', error);
      if (error.response && error.response.status === 401 && retryCount < 1) {
        console.log('Attempting to refresh token...');
        try {
          const newToken = await refreshTokenAndUpdateUser();
          if (newToken) {
            console.log('Token refreshed successfully. Retrying fetch...');
            // Retry the fetch with the new token
            return fetchVoiceNotesData(pageToFetch, retryCount + 1);
          } else {
            console.log('Failed to refresh token.');
            setError('Authentication failed. Please log in again.');
          }
        } catch (refreshError) {
          console.error('Error refreshing token:', refreshError);
          setError('Authentication failed. Please log in again.');
        }
      } else {
        setError(`Error fetching voice notes: ${error.message}`);
      }
      setIsLoading(false);
      setIsLoadingMore(false);
    }
  }, [selectedTags, refreshTokenAndUpdateUser]);

  useEffect(() => {
    setPage(1);
    fetchVoiceNotesData(1);
  }, [selectedTags, fetchVoiceNotesData]);

  const loadMore = useCallback(() => {
    if (!isLoadingMore && hasMore) {
      setIsLoadingMore(true);
      setPage(prevPage => prevPage + 1);
      fetchVoiceNotesData(page + 1);
    }
  }, [isLoadingMore, hasMore, page, fetchVoiceNotesData]);

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      loadMore();
    }
  }, [inView, loadMore]);

  const fetchTagsData = async () => {
    try {
      const fetchedTags = await fetchTags();
      setTags(fetchedTags);
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  };

  const fetchTagCounts = async () => {
    try {
      const counts = await getTagCounts();
      setTagCounts(counts);
    } catch (error) {
      console.error('Error fetching tag counts:', error);
      setError(`Error fetching tag counts: ${error.message}`);
    }
  };

  const handleTagSelect = (newSelectedTags) => {
    setSelectedTags(newSelectedTags);
  };

  const handleAddTag = async (voiceNoteId, newTag) => {
    try {
      const updatedNote = await addTag(voiceNoteId, newTag);
      setVoiceNotes(prevNotes =>
        prevNotes.map(note =>
          note._id === voiceNoteId ? { ...note, tags: updatedNote.tags } : note
        )
      );
      fetchTagsData();
      fetchTagCounts();
    } catch (error) {
      console.error('Error adding tag:', error);
    }
  };

  const handleRemoveTag = async (noteId, tagToRemove) => {
    try {
      await removeTag(noteId, tagToRemove);
      setVoiceNotes(prevNotes =>
        prevNotes.map(note =>
          note._id === noteId
            ? { ...note, tags: note.tags.filter(tag => tag !== tagToRemove) }
            : note
        )
      );
    } catch (error) {
      console.error('Error removing tag:', error);
    }
  };

  const handleRecordingComplete = async (audioBlob, audioUrl) => {
    setError(null);
    try {
      // Clear the recording state when processing starts
      setRecordingState({
        isRecording: false,
        isPaused: false,
        recordingTime: 0,
        onStop: null,
        onPause: null,
        onResume: null,
        onDiscard: null
      });

      if (!audioBlob || audioBlob.size === 0) {
        console.log('No audio data to process');
        return;
      }

      const formData = new FormData();
      formData.append('audio', audioBlob);

      // Show only one initial status with shorter delay
      setProcessingStage('Processing your voice...');
      await new Promise(resolve => setTimeout(resolve, 500)); // Reduced from 2000ms

      const response = await api.post('/api/voice-notes/transcribe', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const { transcription, title, _id, audioUrl: serverAudioUrl } = response.data;

      const finalTranscription = transcription || "Transcription failed...";

      let signedUrl = serverAudioUrl;
      if (_id) {
        try {
          signedUrl = await getSignedUrl(_id);
        } catch (signedUrlError) {
          console.error('Error fetching signed URL:', signedUrlError);
        }
      }

      // Clear processing status
      setProcessingStage(null);

      // Reduced exit animation delay
      await new Promise(resolve => setTimeout(resolve, 150)); // Reduced from 300ms

      const newVoiceNote = {
        _id,
        audioUrl: signedUrl,
        transcription: finalTranscription,
        title,
        createdAt: new Date().toISOString(),
        tags: [],
        isNew: true
      };

      setVoiceNotes(prevNotes => [newVoiceNote, ...prevNotes]);
      await refreshActivities();
      transcriptionsRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (error) {
      console.error('Error processing audio:', error);
      if (error.response && error.response.status === 401) {
        try {
          await refreshTokenAndUpdateUser();
          return handleRecordingComplete(audioBlob, audioUrl);
        } catch (refreshError) {
          console.error('Error refreshing token:', refreshError);
          setError('Authentication failed. Please log in again.');
        }
      } else {
        setError(`Error processing audio: ${error.message}`);
      }
      setProcessingStage(null);
    }
  };

  const handleTitleUpdate = async (id, newTitle) => {
    try {
      const updatedNote = await updateVoiceNote(id, { title: newTitle });
      setVoiceNotes(prevNotes =>
        prevNotes.map(note =>
          note._id === id ? { ...note, title: newTitle } : note
        )
      );
      await refreshActivities();
    } catch (error) {
      console.error('Error updating title:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteVoiceNote(id);
      setVoiceNotes(prevNotes => prevNotes.filter(note => note._id !== id));
      await refreshActivities();
      fetchTagCounts();
      fetchTagsData();
    } catch (error) {
      console.error('Error deleting voice note:', error);
      setError(`Error deleting voice note: ${error.message}`);
    }
  };

  const handleTranscriptionUpdate = async (id, newTranscription) => {
    try {
      if (!id) {
        throw new Error('Voice note ID is missing');
      }
      const updatedNote = await updateVoiceNote(id, { transcription: newTranscription });
      setVoiceNotes(prevNotes =>
        prevNotes.map(note =>
          note._id === id ? { ...note, transcription: updatedNote.transcription } : note
        )
      );
      await refreshActivities();
      return updatedNote;
    } catch (error) {
      console.error('Error updating transcription:', error);
      throw error;
    }
  };

  const handleEditTag = async (oldTag, newTag) => {
    try {
      if (!oldTag || !newTag || oldTag === newTag) {
        alert('Invalid tag edit. Please ensure both old and new tags are provided and different.');
        return;
      }
      const result = await editTag(oldTag, newTag);

      setTags(prevTags => prevTags.map(tag => tag === oldTag ? newTag : tag));

      setVoiceNotes(prevNotes => prevNotes.map(note => ({
        ...note,
        tags: note.tags.map(tag => tag === oldTag ? newTag : tag)
      })));

      fetchTagsData();
      alert('Tag edited successfully');
    } catch (error) {
      console.error('Error editing tag:', error);
      let errorMessage = 'An error occurred while editing the tag';
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }
      alert(errorMessage);
    }
  };

  const handleDeleteTag = async (tagToDelete) => {
    try {
      const result = await deleteTag(tagToDelete);

      setTags(prevTags => prevTags.filter(tag => tag !== tagToDelete));

      setVoiceNotes(prevNotes => prevNotes.map(note => ({
        ...note,
        tags: note.tags.filter(tag => tag !== tagToDelete)
      })));

      setSelectedTags(prevSelected => prevSelected.filter(tag => tag !== tagToDelete));
      fetchTagsData();
    } catch (error) {
      console.error('Error deleting tag:', error);
    }
  };

  const renderEmptyState = () => {
    // Don't show empty state if we're processing a new note
    if (voiceNotes.length === 0 && !processingStage) {
      return <VoiceNotesEmptyState type="no-notes" isDarkMode={isDarkMode} />;
    } else if (filteredNotes.length === 0 && !processingStage) {
      return <VoiceNotesEmptyState type="no-results" isDarkMode={isDarkMode} />;
    }
    return null;
  };

  useEffect(() => {
    if (location.state && location.state.startRecording) {
      setStartRecordingOnMount(true);
    }
  }, [location]);

  const handleVoiceNoteSelect = (voiceNoteId) => {
    navigate(`/voice-notes/${voiceNoteId}`);
  };

  const renderMainContent = () => {
    if (children) {
      return (
        <div className={`h-full ${isDarkMode ? 'bg-[#121212]' : 'bg-white'}`}>
          {children}
        </div>
      );
    }

    return (
      <>
        {/* Tags section - now sticky within the scrollable area */}
        <div className={`sticky top-0 z-20 ${isDarkMode ? 'bg-[#121212]' : 'bg-white'}`}>
          <div className="pt-4">
            <div className="max-w-3xl mx-auto px-4">
              <TagList
                tags={tags}
                selectedTags={selectedTags}
                onTagSelect={handleTagSelect}
                isDarkMode={isDarkMode}
                onManageTags={() => setIsTagManagerOpen(true)}
                tagCounts={tagCounts}
              />
            </div>
          </div>
          
          {processingStage && (
            <ProcessingStatus status={processingStage} isDarkMode={isDarkMode} />
          )}
        </div>

        {/* Scrollable content */}
        <div
          ref={transcriptionsRef}
          className={`flex-grow overflow-y-auto custom-scrollbar pb-32 ${
            isDarkMode ? 'bg-[#121212]' : 'bg-white'
          }`}
        >
          <div className="max-w-3xl mx-auto px-4">
            <div className="h-full">
              {isLoading && page === 1 ? (
                <VoiceNotesSkeleton isDarkMode={isDarkMode} count={5} />
              ) : error ? (
                <p className="mt-4 text-red-500">{error}</p>
              ) : (
                <>
                  {renderEmptyState() || (
                    <div className="space-y-4">
                      {/* Show processing item at the top if exists */}
                      {/* Remove this line */}
                      {/* {processingNote && <ProcessingTranscriptionGridItem isDarkMode={isDarkMode} />} */}
                      {filteredNotes.map((note) => (
                        <TranscriptionGridItem
                          key={note._id}
                          id={note._id}
                          title={note.title}
                          transcription={note.transcription}
                          audioUrl={note.audioUrl}
                          createdAt={note.createdAt}
                          isDarkMode={isDarkMode}
                          tags={note.tags || []}
                          allTags={tags}
                          onAddTag={(tag) => handleAddTag(note._id, tag)}
                          onRemoveTag={(tag) => handleRemoveTag(note._id, tag)}
                          onDelete={(id) => handleDelete(id)}
                          onTitleUpdate={(id, newTitle) => handleTitleUpdate(id, newTitle)}
                          onTranscriptionUpdate={(id, newTranscription) => handleTranscriptionUpdate(id, newTranscription)}
                          generatedContents={note.generatedContents || []}
                          onSelect={() => handleVoiceNoteSelect(note._id)}
                        />
                      ))}
                      {isLoadingMore && <VoiceNotesSkeleton isDarkMode={isDarkMode} count={2} />}
                      <div ref={ref} style={{ height: '20px' }}></div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div 
      data-transcription-screen
      className={`flex flex-col h-full relative overflow-hidden rounded-tr-lg rounded-br-lg ${
        isDarkMode ? 'bg-[#121212] text-slate-200' : 'bg-white text-gray-800'
      }`}
    >
      <TopBar 
        searchTerm={searchTerm} 
        setSearchTerm={setSearchTerm} 
        isDarkMode={isDarkMode}
        isSearchExpanded={isSearchExpanded}
        setIsSearchExpanded={setIsSearchExpanded}
      />
      
      {/* Main scrollable content area */}
      <div className="flex flex-col flex-grow overflow-hidden relative">
        {renderMainContent()}
      </div>

      {/* Fixed AudioRecorder at bottom - only show if no children */}
      {!children && (
        <div className={`fixed bottom-0 left-0 right-0 z-30`}>
          <div className="max-w-3xl mx-auto">
            <AudioRecorder
              onRecordingComplete={handleRecordingComplete}
              isLoading={isAudioRecorderLoading}
              setProcessingStatus={setProcessingStage}
              startRecordingOnMount={startRecordingOnMount}
            />
          </div>
        </div>
      )}

      {/* Tag Manager Modal */}
      {isTagManagerOpen && (
        <TagManager
          tags={tags}
          onClose={() => setIsTagManagerOpen(false)}
          onEditTag={handleEditTag}
          onDeleteTag={handleDeleteTag}
          isDarkMode={isDarkMode}
        />
      )}
    </div>
  );
};

export default TranscriptionScreen;
