import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';

const toastRoot = document.getElementById('toast-root') || document.createElement('div');
toastRoot.id = 'toast-root';
document.body.appendChild(toastRoot);

let toastId = 0;

const ToastManager = () => {
  const [toasts, setToasts] = useState([]);

  useEffect(() => {
    window.showCustomToast = (message, type = 'info') => {
      const id = toastId++;
      setToasts(prevToasts => [...prevToasts, { id, message, type }]);
      setTimeout(() => {
        setToasts(prevToasts => prevToasts.filter(toast => toast.id !== id));
      }, 5000);
    };

    return () => {
      delete window.showCustomToast;
    };
  }, []);

  return (
    <div className="toast-container">
      {toasts.map(toast => (
        <CustomToast key={toast.id} {...toast} />
      ))}
    </div>
  );
};

const CustomToast = ({ message, type }) => {
  return (
    <div className={`custom-toast ${type}`}>
      {message}
    </div>
  );
};

const root = createRoot(toastRoot);
root.render(<ToastManager />);

export const showCustomToast = (message, type = 'info') => {
  if (window.showCustomToast) {
    window.showCustomToast(message, type);
  }
};
