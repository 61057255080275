import React, { useState, useEffect, useCallback, useRef } from 'react';
import { XMarkIcon, LightBulbIcon, CheckIcon, ArrowLeftIcon, DocumentTextIcon } from '@heroicons/react/24/outline';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from '../../context/ThemeContext';
import { fetchIdeas } from '../../api';
import DOMPurify from 'dompurify';
import { motion, AnimatePresence } from 'framer-motion';
import AddToChat from '../common/AddToChat';

const TextNoteSelector = ({ onSelect, onClose, initialSelectedNotes = [] }) => {
  const { isDarkMode } = useTheme();
  const isMobile = useMediaQuery({ maxWidth: 639 });
  const isTablet = useMediaQuery({ minWidth: 640, maxWidth: 1023 });
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedNotes, setSelectedNotes] = useState(initialSelectedNotes);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const observerTarget = useRef(null);
  const pageSize = 15;

  const loadIdeas = useCallback(async (pageToLoad = 1) => {
    try {
      if (pageToLoad === 1) {
        setLoading(true);
        setNotes([]);
      } else {
        setIsLoadingMore(true);
      }

      const ideas = await fetchIdeas(pageToLoad, pageSize);
      setNotes(prev => pageToLoad === 1 ? ideas : [...prev, ...ideas]);
      setHasMore(ideas.length === pageSize);
      
    } catch (err) {
      console.error('Error loading ideas:', err);
      setError('Failed to load ideas. Please try again.');
    } finally {
      setLoading(false);
      setIsLoadingMore(false);
    }
  }, []);

  useEffect(() => {
    loadIdeas(1);
  }, [loadIdeas]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && hasMore && !loading && !isLoadingMore) {
          setPage(prev => prev + 1);
          loadIdeas(page + 1);
        }
      },
      { threshold: 0.1 }
    );

    const currentTarget = observerTarget.current;
    if (currentTarget) {
      observer.observe(currentTarget);
    }

    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget);
      }
    };
  }, [hasMore, loading, isLoadingMore, page, loadIdeas]);

  const truncateContent = (content, maxLength = 100) => {
    if (!content) return 'No content available';
    
    try {
      const parsedContent = JSON.parse(content);
      if (parsedContent && parsedContent.blocks) {
        content = parsedContent.blocks
          .map(block => block.data && block.data.text ? block.data.text : '')
          .join('\n');
      }
    } catch (e) {
      // If parsing fails, use content as is
    }

    content = DOMPurify.sanitize(content, { ALLOWED_TAGS: [] });
    return content.length <= maxLength ? content : `${content.substr(0, maxLength)}...`;
  };

  const handleNoteSelection = (note) => {
    setSelectedNotes(prevSelected => {
      const isAlreadySelected = prevSelected.some(selected => selected._id === note._id);
      return isAlreadySelected
        ? prevSelected.filter(selected => selected._id !== note._id)
        : [...prevSelected, note];
    });
  };

  const handleConfirmSelection = () => {
    onSelect(selectedNotes);
    onClose();
  };

  const listItemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 }
  };

  return (
    <div className="absolute inset-0 z-50 flex">
      <div className="flex-1 h-full">
        <motion.div 
          className={`flex flex-col h-full w-full overflow-hidden
            ${isDarkMode ? 'bg-[#121212]' : 'bg-white'}
            border-none rounded-none`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          {/* Header */}
          <div className={`
            ${isDarkMode ? 'bg-[#151515] border-[#333333]' : 'bg-white border-gray-200'} 
            !h-10 min-h-10 flex-shrink-0 flex items-center justify-between px-4 w-full 
            border-b z-50 relative
          `}>
            {/* Left side: Icon + Title */}
            <div className="flex items-center gap-1.5">
              <div className={`flex items-center justify-center w-6 h-6 rounded-md ${isDarkMode ? 'bg-[#1e1e1e]' : 'bg-gray-100/80'}`}>
                <DocumentTextIcon className={`w-4 h-4 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
              </div>
              <h2 className={`text-sm font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>
                Text Notes
              </h2>
            </div>

            {/* Right side: Back button */}
            <button 
              onClick={onClose}
              className={`
                p-1.5 rounded-md transition-colors duration-200
                ${isDarkMode 
                  ? 'hover:bg-[#1e1e1e] text-slate-400 hover:text-slate-200' 
                  : 'hover:bg-gray-100 text-gray-600 hover:text-gray-800'}
              `}
              title="Back to Chat"
            >
              <ArrowLeftIcon className="w-4 h-4" />
            </button>
          </div>

          {/* Content Area */}
          <div className={`
            flex-grow overflow-y-auto custom-scrollbar
            ${isDarkMode ? 'bg-[#121212]' : 'bg-white'}
          `}>
            {error ? (
              <div className="flex flex-col items-center justify-center h-full p-4">
                <p className={`text-center ${isDarkMode ? 'text-red-400' : 'text-red-600'}`}>{error}</p>
                <button
                  onClick={() => loadIdeas(1)}
                  className={`
                    mt-4 px-4 py-2 rounded-md text-white
                    ${isDarkMode ? 'bg-[#1e1e1e] hover:bg-[#2a2a2a] border border-[#333333]' : 'bg-blue-500 hover:bg-blue-600'}
                  `}
                >
                  Retry
                </button>
              </div>
            ) : notes.length === 0 && !loading ? (
              <div className="flex flex-col items-center justify-center h-full p-4">
                <DocumentTextIcon className={`h-10 w-10 mb-3 ${isDarkMode ? 'text-[#333333]' : 'text-gray-400'}`} />
                <p className={`text-center text-sm ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
                  No notes available
                </p>
              </div>
            ) : (
              <div className="px-4 py-3">
                {notes.map((note) => (
                  <motion.button
                    key={note._id}
                    variants={listItemVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    transition={{ duration: 0.2 }}
                    onClick={() => handleNoteSelection(note)}
                    className={`
                      w-full text-left p-3 mb-2.5 flex items-center
                      transition-colors duration-200 rounded-md
                      ${isDarkMode 
                        ? 'hover:bg-[#1e1e1e] active:bg-[#1e1e1e]' 
                        : 'hover:bg-gray-50 active:bg-gray-100'}
                      ${selectedNotes.some(selected => selected._id === note._id)
                        ? isDarkMode
                          ? 'bg-[#1e1e1e] border border-[#333333]'
                          : 'bg-blue-50 border border-blue-200'
                        : 'border border-transparent'}
                    `}
                  >
                    <DocumentTextIcon className={`w-5 h-5 mr-3 flex-shrink-0 ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`} />
                    <div className="flex-grow min-w-0">
                      <h3 className={`font-medium text-sm mb-1 truncate ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>
                        {note.title || 'Untitled Note'}
                      </h3>
                      <p className={`text-xs line-clamp-2 ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
                        {truncateContent(note.content)}
                      </p>
                      {note.tags && note.tags.length > 0 && (
                        <div className="flex flex-wrap gap-1 mt-1.5">
                          {note.tags.slice(0, 3).map((tag) => (
                            <span
                              key={tag}
                              className={`px-1.5 py-0.5 rounded-full text-[10px] ${
                                isDarkMode ? 'bg-[#1e1e1e] text-slate-300' : 'bg-gray-200 text-gray-700'
                              }`}
                            >
                              {tag}
                            </span>
                          ))}
                          {note.tags.length > 3 && (
                            <span className={`text-[10px] ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
                              +{note.tags.length - 3}
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                    {selectedNotes.some(selected => selected._id === note._id) && (
                      <CheckIcon className={`h-5 w-5 ml-3 flex-shrink-0 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`} />
                    )}
                  </motion.button>
                ))}
                {loading && (
                  <div className="py-4 text-center">
                    <div className={`inline-block animate-spin rounded-full h-5 w-5 border-2 border-t-transparent ${
                      isDarkMode ? 'border-[#333333]' : 'border-blue-500'
                    }`}></div>
                  </div>
                )}
                {/* Observer target for infinite scroll */}
                <div ref={observerTarget} className="h-4 w-full" />
              </div>
            )}
          </div>

          {/* Footer */}
          <div className={`
            px-3 py-2 sticky bottom-0
            ${isDarkMode ? 'bg-[#151515] border-[#333333]' : 'bg-white border-gray-200'}
            border-t
          `}>
            <AddToChat
              onClick={handleConfirmSelection}
              disabled={selectedNotes.length === 0}
              selectedCount={selectedNotes.length}
            />
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default TextNoteSelector;
