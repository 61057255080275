import React, { useState, useRef, useEffect } from 'react';
import { XMarkIcon, EyeIcon, CpuChipIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../context/ThemeContext';

const AIAgentTag = ({ agent, onRemove, width = 'w-full' }) => {
  const [showContent, setShowContent] = useState(false);
  const { isDarkMode } = useTheme();
  const contentRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (contentRef.current && !contentRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
        setShowContent(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleContent = (e) => {
    e.stopPropagation();
    setShowContent(!showContent);
  };

  return (
    <div className={`${width} relative`}>
      <div 
        className={`px-3 py-2 rounded-md flex justify-between items-center text-xs ${
          isDarkMode ? 'bg-purple-600 text-white hover:bg-purple-500' : 'bg-purple-100 text-purple-800 hover:bg-purple-200'
        } transition-colors duration-200`}
      >
        <div className="flex items-center flex-grow">
          {agent.avatarUrl ? (
            <img 
              src={agent.avatarUrl} 
              alt={agent.name} 
              className="h-4 w-4 rounded-full mr-2 flex-shrink-0"
            />
          ) : (
            <CpuChipIcon className="h-4 w-4 mr-2 flex-shrink-0" />
          )}
          <h3 className="font-medium truncate">{agent.name}</h3>
        </div>
        <div className="flex items-center">
          <button
            ref={buttonRef}
            onClick={toggleContent}
            className={`mr-1 p-1 rounded-md transition-colors duration-200 ${
              isDarkMode ? 'hover:bg-purple-500' : 'hover:bg-purple-200'
            }`}
            title="View AI agent details"
          >
            <EyeIcon className="h-4 w-4" />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onRemove();
            }}
            className={`p-1 rounded-md transition-colors duration-200 ${
              isDarkMode ? 'hover:bg-purple-500' : 'hover:bg-purple-200'
            }`}
            title="Remove AI agent"
          >
            <XMarkIcon className="h-4 w-4" />
          </button>
        </div>
      </div>
      {showContent && (
        <div 
          ref={contentRef}
          className={`absolute z-10 right-0 bottom-full mb-1 p-4 rounded-md shadow-lg w-96 ${
            isDarkMode ? 'bg-slate-800 text-slate-200' : 'bg-white text-gray-800 border border-gray-200'
          }`}
        >
          {/* Basic Information Section */}
          <div className="mb-4">
            <h4 className="font-medium text-sm mb-2">Basic Information</h4>
            <div className="space-y-2">
              <div className="flex items-center">
                {agent.avatarUrl && (
                  <img 
                    src={agent.avatarUrl} 
                    alt={agent.name} 
                    className="h-10 w-10 rounded-full mr-3"
                  />
                )}
                <div>
                  <p className="font-medium">{agent.name}</p>
                  <p className="text-xs opacity-75">{agent.description}</p>
                </div>
              </div>
              {agent.categories?.length > 0 && (
                <div className="flex flex-wrap gap-1">
                  {agent.categories.map((category, index) => (
                    <span 
                      key={index}
                      className={`text-xs px-2 py-1 rounded-full ${
                        isDarkMode ? 'bg-slate-700' : 'bg-gray-100'
                      }`}
                    >
                      {category}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* System Instructions Section */}
          <div className="mb-4">
            <h4 className="font-medium text-sm mb-2">System Instructions</h4>
            <pre className="text-xs whitespace-pre-wrap p-2 rounded-md bg-opacity-50 max-h-32 overflow-y-auto custom-scrollbar
                          ${isDarkMode ? 'bg-slate-700' : 'bg-gray-100'}">
              {agent.systemInstructions}
            </pre>
          </div>

          {/* Training Examples Section */}
          {agent.trainingExamples?.length > 0 && (
            <div>
              <h4 className="font-medium text-sm mb-2">Training Examples</h4>
              <div className="space-y-2 max-h-48 overflow-y-auto custom-scrollbar">
                {agent.trainingExamples.map((example, index) => (
                  <div 
                    key={index}
                    className={`p-2 rounded-md text-xs ${
                      isDarkMode ? 'bg-slate-700' : 'bg-gray-100'
                    }`}
                  >
                    <div className="mb-1">
                      <span className="font-medium">Input: </span>
                      <span>{example.input}</span>
                    </div>
                    <div>
                      <span className="font-medium">Output: </span>
                      <span>{example.output}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AIAgentTag;