const STORAGE_KEY = 'contentStrategyProgress';

// Helper to safely parse JSON with a default value
const safeJSONParse = (str, defaultValue = null) => {
  try {
    return str ? JSON.parse(str) : defaultValue;
  } catch (e) {
    console.error('Error parsing stored progress:', e);
    return defaultValue;
  }
};

export const contentStrategyProgressService = {
  // Save full progress
  saveProgress: (data) => {
    try {
      const timestamp = new Date().toISOString();
      const progressData = {
        ...data,
        lastUpdated: timestamp,
        version: '1.0' // For future migrations if needed
      };
      
      localStorage.setItem(STORAGE_KEY, JSON.stringify(progressData));
      console.log('✅ Progress saved:', progressData);
    } catch (error) {
      console.error('Error saving progress:', error);
    }
  },

  // Load full progress
  loadProgress: () => {
    const stored = localStorage.getItem(STORAGE_KEY);
    return safeJSONParse(stored, {
      selectedPersona: null,
      selectedAudience: null,
      contentTypes: null,
      generatedIdeas: null,
      currentStep: 1
    });
  },

  // Clear progress
  clearProgress: () => {
    localStorage.removeItem(STORAGE_KEY);
    console.log('🧹 Progress cleared');
  },

  // Update specific parts of progress
  updateProgress: (key, value) => {
    try {
      const currentProgress = contentStrategyProgressService.loadProgress();
      const updatedProgress = {
        ...currentProgress,
        [key]: value,
        lastUpdated: new Date().toISOString()
      };
      
      localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedProgress));
      console.log(`✅ Progress updated for ${key}:`, value);
    } catch (error) {
      console.error(`Error updating progress for ${key}:`, error);
    }
  },

  // Check if there's saved progress
  hasProgress: () => {
    const progress = contentStrategyProgressService.loadProgress();
    return !!(progress.selectedPersona || progress.selectedAudience || progress.contentTypes);
  }
}; 