import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Route, Routes, useParams, useLocation } from 'react-router-dom';
import { UserGroupIcon, UserPlusIcon, MagnifyingGlassIcon, XMarkIcon, ArrowPathIcon, PlusCircleIcon, CpuChipIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../../context/ThemeContext';
import api, { defaultDataApi } from '../../api';
import TargetAudienceCreationWizard from './TargetAudienceCreationWizard';
import TargetAudienceOverviewPopup from './TargetAudienceOverviewPopup';
import { fetchAvatarUrl, getAvatarFromCache, saveAvatarToCache, refreshExpiredAvatarUrl, forceRefreshAvatarUrl } from '../../utils/targetAudienceAvatarUtils';
import { fetchAvatarUrl as fetchPersonaAvatarUrl } from '../../utils/avatarUtils';
import Notification from '../Notification';
import TargetAudienceCard from './TargetAudienceCard';
import { motion, AnimatePresence } from 'framer-motion';
import { GridSkeleton } from '../common/CardSkeleton';

const FALLBACK_AVATAR = '/default-avatar.png';
const REFRESH_INTERVAL = 30 * 60 * 1000; // 30 minutes in milliseconds

const NoResultsMessage = ({ searchTerm, onCreateClick, onAddExamples, isDarkMode }) => (
  <motion.div 
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.4 }}
    className={`
      flex flex-col items-center justify-center p-8 rounded-2xl
      backdrop-blur-xl backdrop-saturate-150
      ${isDarkMode 
        ? 'bg-[#1a1a1a]/90 border border-white/10 shadow-black/50' 
        : 'bg-white/90 border border-black/5 shadow-black/10'}
    `}
  >
    <div className={`
      p-4 rounded-full mb-4
      ${isDarkMode 
        ? 'bg-[#252525] text-blue-400/80' 
        : 'bg-slate-100 text-blue-600/80'}
    `}>
      <UserGroupIcon className="w-8 h-8" />
    </div>
    
    <div className="text-center max-w-sm">
      <motion.h3 
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1 }}
        className={`text-lg font-semibold mb-2 tracking-tight
          ${isDarkMode ? 'text-white' : 'text-slate-900'}
        `}
      >
        {searchTerm ? `No results for "${searchTerm}"` : 'Create your first audience'}
      </motion.h3>
      
      <motion.p 
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className={`text-sm mb-6
          ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
        `}
      >
        {searchTerm 
          ? 'Try different keywords or create a new audience' 
          : 'Get started by creating a new target audience or add example audiences'}
      </motion.p>
      
      <div className="flex items-center gap-3 justify-center">
        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={onCreateClick}
          className={`
            relative group flex items-center gap-2 
            px-4 py-2 rounded-lg text-sm font-medium
            transition-all duration-200
            ${isDarkMode 
              ? 'bg-[#252525] hover:bg-[#2a2a2a] text-slate-200 border border-white/10' 
              : 'bg-slate-900 hover:bg-slate-800 text-white'}
          `}
        >
          <UserPlusIcon className="w-4 h-4" />
          <span>New Audience</span>
        </motion.button>
        
        {!searchTerm && (
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={onAddExamples}
            className={`
              relative group flex items-center gap-2
              px-4 py-2 rounded-lg text-sm font-medium
              transition-all duration-200
              ${isDarkMode 
                ? 'bg-[#1e1e1e] hover:bg-[#252525] text-slate-300 border border-white/10' 
                : 'bg-slate-100 hover:bg-slate-200 text-slate-700'}
            `}
          >
            <PlusCircleIcon className="w-4 h-4" />
            <span>Add Examples</span>
          </motion.button>
        )}
      </div>
    </div>
  </motion.div>
);

const EmptyState = ({ isDarkMode, onCreateClick, searchTerm = '' }) => (
  <motion.div 
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.4 }}
    className={`
      flex flex-col items-center justify-center p-8 rounded-2xl
      backdrop-blur-xl backdrop-saturate-150
      ${isDarkMode 
        ? 'bg-[#1a1a1a]/90 border border-white/10 shadow-black/50' 
        : 'bg-white/90 border border-black/5 shadow-black/10'}
    `}
  >
    <div className={`
      p-4 rounded-full mb-4
      ${isDarkMode 
        ? 'bg-[#252525] text-blue-400/80' 
        : 'bg-slate-100 text-blue-600/80'}
    `}>
      <UserGroupIcon className="w-8 h-8" />
    </div>
    
    <div className="text-center max-w-sm">
      <motion.h3 
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1 }}
        className={`text-lg font-semibold mb-2 tracking-tight
          ${isDarkMode ? 'text-white' : 'text-slate-900'}
        `}
      >
        {searchTerm ? `No results for "${searchTerm}"` : 'Create your first audience'}
      </motion.h3>
      
      <motion.p 
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className={`text-sm mb-6
          ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
        `}
      >
        {searchTerm 
          ? 'Try different keywords or create a new audience' 
          : 'Get started by creating a new target audience'}
      </motion.p>
      
      <motion.button
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        onClick={onCreateClick}
        className={`
          relative group flex items-center gap-2 
          px-4 py-2 rounded-lg text-sm font-medium
          transition-all duration-200
          ${isDarkMode 
            ? 'bg-[#252525] hover:bg-[#2a2a2a] text-slate-200 border border-white/10' 
            : 'bg-slate-900 hover:bg-slate-800 text-white'}
        `}
      >
        <UserPlusIcon className="w-4 h-4" />
        <span>New Audience</span>
      </motion.button>
    </div>
  </motion.div>
);

const AudienceList = ({ 
  audiences, 
  filteredAudiences, 
  isLoading, 
  searchTerm, 
  handleEdit, 
  handleDeleteClick, 
  handleDownload, 
  isDarkMode, 
  handleAudienceClick, 
  handleAvatarError, 
  handlePersonaAvatarError, 
  isPopup, 
  handleCreateClick,
  handleRestoreDefaults,
  progress,
  updateProgress
}) => {
  if (isLoading) {
    return <GridSkeleton count={6} />;
  }

  if (filteredAudiences.length === 0) {
    return (
      <NoResultsMessage 
        searchTerm={searchTerm}
        onCreateClick={handleCreateClick}
        onAddExamples={handleRestoreDefaults}
        isDarkMode={isDarkMode}
      />
    );
  }

  return (
    <motion.div 
      className={`grid grid-cols-1 sm:grid-cols-2 gap-4 ${isPopup ? '' : 'pt-4'}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <AnimatePresence>
        {filteredAudiences.map((audience) => (
          <motion.div
            key={audience._id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            <TargetAudienceCard
              audience={audience}
              onEdit={handleEdit}
              onDelete={handleDeleteClick}
              onDownload={handleDownload}
              isDarkMode={isDarkMode}
              handleAudienceClick={handleAudienceClick}
              handleAvatarError={handleAvatarError}
              handlePersonaAvatarError={handlePersonaAvatarError}
              isPopup={isPopup}
              progress={progress[audience._id] || 0}
              updateProgress={(newProgress) => updateProgress(audience._id, newProgress)}
            />
          </motion.div>
        ))}
      </AnimatePresence>
    </motion.div>
  );
};

const TargetAudiencesPage = ({ isPopup = false, onSelectAudience, showTopBar = true }) => {
  const [audiences, setAudiences] = useState([]);
  const [filteredAudiences, setFilteredAudiences] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isInitialFetchDone, setIsInitialFetchDone] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [audienceToDelete, setAudienceToDelete] = useState(null);
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [selectedAudience, setSelectedAudience] = useState(null);
  const [avatarRefreshTimers, setAvatarRefreshTimers] = useState({});
  const [notifications, setNotifications] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const [progress, setProgress] = useState(() => {
    const savedProgress = localStorage.getItem('targetAudienceProgress');
    return savedProgress ? JSON.parse(savedProgress) : {};
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [savedProgress, setSavedProgress] = useState(null);
  const location = useLocation();
  const [isRestoring, setIsRestoring] = useState(false);

  const addNotification = useCallback((title, message, type = 'info') => {
    const id = Date.now();
    setNotifications(prev => [...prev, { id, title, message, type }]);
    
    // Automatically remove the notification after 5 seconds
    setTimeout(() => {
      setNotifications(prev => prev.filter(notification => notification.id !== id));
    }, 5000);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleAvatarUpdate = useCallback((audienceId, newAvatarUrl) => {
    setAudiences(prevAudiences => 
      prevAudiences.map(audience => 
        audience._id === audienceId ? { ...audience, avatarUrl: newAvatarUrl } : audience
      )
    );
  }, []);

  const fetchAudiences = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get('/api/target-audiences');
      const audiencesWithAvatars = await Promise.all(response.data.map(async (audience) => {
        let avatarUrl = FALLBACK_AVATAR;
        
        if (audience.avatar) {
          const cachedUrl = getAvatarFromCache(audience._id);
          if (cachedUrl) {
            avatarUrl = cachedUrl;
          } else {
            try {
              avatarUrl = await fetchAvatarUrl(audience._id);
            } catch (error) {
              console.error(`Error fetching avatar for audience ${audience._id}:`, error);
              avatarUrl = FALLBACK_AVATAR;
            }
          }
        }

        // If there's an associated persona, get its avatar too
        let associatedPersona = audience.associatedPersona;
        if (associatedPersona && associatedPersona._id) {
          try {
            const personaAvatarUrl = await fetchPersonaAvatarUrl(associatedPersona._id);
            associatedPersona = {
              ...associatedPersona,
              signedAvatarUrl: personaAvatarUrl || FALLBACK_AVATAR
            };
          } catch (error) {
            console.error(`Error fetching persona avatar for audience ${audience._id}:`, error);
            associatedPersona = {
              ...associatedPersona,
              signedAvatarUrl: FALLBACK_AVATAR
            };
          }
        }
        
        return { 
          ...audience,
          signedAvatarUrl: avatarUrl,
          associatedPersona
        };
      }));
      setAudiences(audiencesWithAvatars);
    } catch (error) {
      console.error('Error fetching target audiences:', error);
      addNotification(
        'Error Loading Audiences',
        'Failed to load target audiences. Please try refreshing the page.',
        'error'
      );
    } finally {
      setIsLoading(false);
    }
  }, [addNotification]);

  useEffect(() => {
    if (!isInitialFetchDone) {
      fetchAudiences().then(() => setIsInitialFetchDone(true));
    }
  }, [fetchAudiences, isInitialFetchDone]);

  useEffect(() => {
    if (audiences.length > 0) {
      const lowercasedFilter = searchTerm.toLowerCase();
      const filtered = audiences.filter(audience =>
        (audience.personaName?.toLowerCase() || '').includes(lowercasedFilter) ||
        (audience.oneSentenceDescription?.toLowerCase() || '').includes(lowercasedFilter)
      );
      setFilteredAudiences(filtered);
    } else {
      setFilteredAudiences([]);
    }
  }, [searchTerm, audiences]);

  useEffect(() => {
    const refreshAvatars = async () => {
      const updatedAudiences = await Promise.all(
        audiences.map(async (audience) => {
          const isDefaultAudience = !audience.isCustom;
          if (!isDefaultAudience && audience.avatar) {
            const newAvatarUrl = await refreshExpiredAvatarUrl(audience._id);
            return { ...audience, signedAvatarUrl: newAvatarUrl || FALLBACK_AVATAR };
          }
          return audience;
        })
      );
      setAudiences(updatedAudiences);
    };

    const timerId = setInterval(refreshAvatars, REFRESH_INTERVAL);
    setAvatarRefreshTimers(prev => ({ ...prev, [audiences.length]: timerId }));

    return () => clearInterval(timerId);
  }, [audiences]);

  useEffect(() => {
    localStorage.setItem('targetAudienceProgress', JSON.stringify(progress));
  }, [progress]);

  const updateProgress = (audienceId, newProgress) => {
    setProgress(prevProgress => {
      const updatedProgress = {
        ...prevProgress,
        [audienceId]: newProgress
      };
      localStorage.setItem('targetAudienceProgress', JSON.stringify(updatedProgress));
      return updatedProgress;
    });
  };

  const handleEdit = (audienceId) => {
    navigate(`edit/${audienceId}`);
  };

  const handleDeleteClick = (audienceId) => {
    setAudienceToDelete(audienceId);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    if (audienceToDelete) {
      try {
        await api.delete(`/api/target-audiences/${audienceToDelete}`);
        setAudiences(audiences.filter(audience => audience._id !== audienceToDelete));
        setShowDeleteModal(false);
        setAudienceToDelete(null);
      } catch (error) {
        console.error('Error deleting target audience:', error);
        alert('Failed to delete target audience. Please try again.');
      }
    }
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
    setAudienceToDelete(null);
  };

  const handleAudienceClick = (audience) => {
    if (isPopup && onSelectAudience) {
      onSelectAudience(audience);
    } else {
      setSelectedAudience(audience);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    if (isSearchOpen) {
      setSearchTerm('');
    }
  };

  const handleCreateClick = () => {
    navigate('create');
  };

  const closeOverviewPopup = () => {
    setSelectedAudience(null);
  };

  const handleAvatarError = async (audienceId) => {
    try {
      const newAvatarUrl = await forceRefreshAvatarUrl(audienceId);
      if (newAvatarUrl) {
        saveAvatarToCache(audienceId, newAvatarUrl);
        setAudiences(prevAudiences => 
          prevAudiences.map(audience => 
            audience._id === audienceId ? { ...audience, avatarUrl: newAvatarUrl } : audience
          )
        );
      }
    } catch (error) {
      console.error('Error refreshing avatar URL:', error);
    }
  };

  const handlePersonaAvatarError = async (personaId) => {
    try {
      const newAvatarUrl = await forceRefreshAvatarUrl(personaId, 'persona');
      if (newAvatarUrl) {
        setAudiences(prevAudiences => 
          prevAudiences.map(audience => 
            audience.associatedPersona && audience.associatedPersona._id === personaId
              ? { ...audience, associatedPersona: { ...audience.associatedPersona, avatarUrl: newAvatarUrl } }
              : audience
          )
        );
      }
    } catch (error) {
      console.error('Error refreshing persona avatar URL:', error);
    }
  };

  const handleDownload = async (audienceId) => {
    try {
      const response = await api.get(`/api/target-audiences/${audienceId}/download`, {
        responseType: 'blob'
      });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `target_audience_${audienceId}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      addNotification('Success', 'Target audience downloaded successfully', 'success');
    } catch (error) {
      console.error('Error downloading target audience:', error);
      addNotification('Error', 'Failed to download target audience', 'error');
    }
  };

  const handleNewAudienceClick = () => {
    // Directly navigate to the create page without any checks
    navigate('create');
  };

  const handleRestoreDefaults = async () => {
    try {
      setIsRestoring(true);
      const response = await api.post('/api/target-audiences/defaults/restore');
      if (response.data) {
        await fetchAudiences(); // Refresh the list after restore
        addNotification(
          'Defaults Restored',
          'Default target audiences have been restored successfully',
          'success'
        );
      }
    } catch (error) {
      console.error('Error restoring default target audiences:', error);
      addNotification(
        'Restore Failed',
        'Failed to restore default target audiences',
        'error'
      );
    } finally {
      setIsRestoring(false);
    }
  };

  useEffect(() => {
    return () => {
      setIsModalOpen(false);
      setSavedProgress(null);
    };
  }, [location.pathname]);

  return (
    <div className="h-full overflow-hidden select-none">
      <Routes>
        <Route path="/" element={
          <div className={`flex flex-col h-full relative overflow-hidden bg-transparent
                          min-w-[300px] w-full max-w-full
                          mx-auto transition-all duration-300 ease-in-out select-none`}>
            {showTopBar && (
              // Top Bar
              <div className={`${isDarkMode ? 'bg-[#121212]/95 border-[#333333]' : 'bg-white bg-opacity-90 border-gray-200'} 
                              !h-10 min-h-10 flex-shrink-0 flex items-center justify-between px-4 py-2 w-full 
                              border-b`}>
                {/* Left section */}
                <div className="flex items-center">
                  <UserGroupIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'} mr-2`} />
                  <h2 className={`text-sm font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-900'} ${isSearchOpen && isMobile ? 'hidden' : ''}`}>
                    {isMobile ? 'Audiences' : 'Target Audiences'}
                  </h2>
                </div>

                {/* Right section */}
                <div className="flex items-center space-x-2">
                  {isSearchOpen ? (
                    <div className="relative flex-grow">
                      <input
                        type="text"
                        className={`w-full sm:w-48 px-3 py-1 text-sm border rounded-md pl-8 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                          isDarkMode
                            ? 'bg-[#1e1e1e] border-[#333333] text-slate-200 placeholder-slate-400'
                            : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                        }`}
                        placeholder="Search audiences..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                      <MagnifyingGlassIcon className={`absolute left-2 top-1.5 h-4 w-4 ${
                        isDarkMode ? 'text-slate-400' : 'text-gray-400'
                      }`} />
                      <button
                        onClick={toggleSearch}
                        className={`absolute right-2 top-1.5 ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`}
                      >
                        <XMarkIcon className="h-4 w-4" />
                      </button>
                    </div>
                  ) : (
                    <button 
                      onClick={toggleSearch}
                      className={`p-1 rounded-md ${
                        isDarkMode ? 'hover:bg-slate-700' : 'hover:bg-gray-100'
                      }`}
                    >
                      <MagnifyingGlassIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
                    </button>
                  )}
                  {audiences.length > 0 && (
                    <button
                      onClick={handleRestoreDefaults}
                      disabled={isRestoring}
                      className={`flex items-center px-2 py-1 rounded-md transition-colors duration-200 text-sm
                                ${isDarkMode 
                                  ? 'bg-[#1a1a1a] hover:bg-[#202020] text-slate-200' 
                                  : 'bg-gray-100 hover:bg-gray-200 text-gray-700'}
                                ${isRestoring ? 'opacity-50 cursor-not-allowed' : ''}`}
                      title="Restore Sample Audiences"
                    >
                      <ArrowPathIcon className={`w-4 h-4 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'} mr-1
                                            ${isRestoring ? 'animate-spin' : ''}`} />
                      Restore Samples
                    </button>
                  )}
                  <button 
                    onClick={handleNewAudienceClick}
                    className={`flex items-center px-2 py-1 rounded-md transition-colors duration-200 text-sm
                                ${isDarkMode 
                                  ? 'bg-[#121212] hover:bg-[#1e1e1e] text-slate-200 border border-[#333333]' 
                                  : 'bg-gray-100 hover:bg-gray-200 text-gray-700'}
                                ${isSearchOpen && isMobile ? 'hidden' : ''}`}
                    title="Create New Target Audience"
                  >
                    <UserPlusIcon className={`w-4 h-4 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'} mr-1`} />
                    <span className="hidden sm:inline">Add New Audience</span>
                  </button>
                </div>
              </div>
            )}

            {/* Content Area */}
            <div className={`
              flex-grow overflow-y-auto custom-scrollbar 
              ${isDarkMode ? 'bg-[#121212]' : 'bg-white bg-opacity-80'}
              ${!isPopup && 'px-4 py-4'}
            `}>
              {isLoading ? (
                <GridSkeleton count={6} />
              ) : filteredAudiences.length > 0 ? (
                <div className={`
                  grid grid-cols-1 md:grid-cols-2 gap-4
                  ${isPopup ? 'px-2 pb-4' : 'max-w-3xl mx-auto pb-20'}
                `}>
                  <AnimatePresence>
                    {filteredAudiences.map((audience) => (
                      <motion.div
                        key={audience._id}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.3 }}
                      >
                        <TargetAudienceCard
                          audience={audience}
                          onEdit={handleEdit}
                          onDelete={handleDeleteClick}
                          onDownload={handleDownload}
                          isDarkMode={isDarkMode}
                          handleAudienceClick={handleAudienceClick}
                          handleAvatarError={handleAvatarError}
                          handlePersonaAvatarError={handlePersonaAvatarError}
                          isPopup={isPopup}
                          progress={progress[audience._id] || 0}
                          updateProgress={(newProgress) => updateProgress(audience._id, newProgress)}
                        />
                      </motion.div>
                    ))}
                  </AnimatePresence>
                </div>
              ) : (
                <div className="h-[calc(100vh-10rem)] flex items-center justify-center">
                  <NoResultsMessage 
                    searchTerm={searchTerm}
                    onCreateClick={handleCreateClick}
                    onAddExamples={handleRestoreDefaults}
                    isDarkMode={isDarkMode}
                  />
                </div>
              )}
            </div>

            {/* Delete Confirmation Modal */}
            {showDeleteModal && (
              <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
                <div className={`relative w-full max-w-sm transform overflow-hidden rounded-lg shadow-xl transition-all
                                ${isDarkMode ? 'bg-[#151515]' : 'bg-white'}`}>
                  <div className="p-4">
                    {/* Header and Content Combined */}
                    <div className="mb-4">
                      <h3 className={`text-base font-semibold mb-2 
                                    ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>
                        Delete Target Audience
                      </h3>
                      <p className={`text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}`}>
                        Are you sure? This action cannot be undone.
                      </p>
                    </div>

                    {/* Actions */}
                    <div className="flex justify-end gap-2">
                      <button
                        onClick={cancelDelete}
                        className={`px-3 py-1.5 rounded-md text-sm font-medium
                                  transition-all duration-200
                                  ${isDarkMode 
                                    ? 'bg-[#1a1a1a] text-slate-200 hover:bg-[#202020] border border-[#333333]' 
                                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'}`}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={confirmDelete}
                        className={`px-3 py-1.5 rounded-md text-sm font-medium text-white
                                  transition-all duration-200
                                  bg-red-500 hover:bg-red-600
                                  ${isDarkMode ? 'bg-opacity-90' : ''}`}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Add the TargetAudienceOverviewPopup */}
            {selectedAudience && (
              <TargetAudienceOverviewPopup
                audience={selectedAudience}
                onClose={closeOverviewPopup}
              />
            )}
          </div>
        } />
        <Route path="create" element={
          <div className="h-full w-full">
            <TargetAudienceCreationWizard />
          </div>
        } />
        <Route 
          path="edit/:audienceId" 
          element={
            <div className="h-full w-full">
              <EditAudienceWrapper onAvatarUpdate={handleAvatarUpdate} />
            </div>
          } 
        />
      </Routes>

      {/* Notifications */}
      <div className="fixed top-4 right-4 z-50">
        {notifications.map(notification => (
          <Notification
            key={notification.id}
            title={notification.title}
            message={notification.message}
            type={notification.type}
            onClose={() => setNotifications(prev => prev.filter(n => n.id !== notification.id))}
          />
        ))}
      </div>
    </div>
  );
};

// Update the EditAudienceWrapper component
const EditAudienceWrapper = ({ onAvatarUpdate }) => {
  const { audienceId } = useParams();
  return (
    <TargetAudienceCreationWizard 
      audienceId={audienceId}
      onAvatarUpdate={onAvatarUpdate}
    />
  );
};

// Add this new function at the end of the file, after the existing export statement
export const SimpleAudienceList = ({ audiences = [], onSelectAudience, isDarkMode }) => {
  if (!audiences || audiences.length === 0) {
    return <p className={isDarkMode ? 'text-slate-400' : 'text-gray-600'}>No audiences available.</p>;
  }

  return (
    <ul className="space-y-2">
      {audiences.map((audience) => (
        <li key={audience._id}>
          <button
            onClick={() => onSelectAudience(audience)}
            className={`w-full text-left px-4 py-2 rounded-md transition-colors duration-200
                        ${isDarkMode 
                          ? 'bg-[#121212] hover:bg-[#1e1e1e] text-slate-200 border border-[#333333]' 
                          : 'bg-gray-100 hover:bg-gray-200 text-gray-800'}`}
          >
            {audience.targetAudienceName}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default TargetAudiencesPage;
export { AudienceList };
