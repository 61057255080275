import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { 
  UserIcon, 
  EllipsisHorizontalIcon, 
  PencilIcon, 
  TrashIcon,
  CommandLineIcon,
  PencilSquareIcon,
  BriefcaseIcon,
  HeartIcon
} from '@heroicons/react/24/outline';
import { useTheme } from '../../context/ThemeContext';

const FALLBACK_AVATAR = '/default-avatar.png';

// Map of icon names to actual icon components
const ICON_MAP = {
  CommandLineIcon,
  PencilSquareIcon,
  BriefcaseIcon,
  HeartIcon,
  UserIcon // fallback
};

const DropdownMenu = ({ isDarkMode, onEdit, onDelete, position, menuRef }) => {
  if (!position) return null;

  return ReactDOM.createPortal(
    <div 
      ref={menuRef}
      style={{
        position: 'fixed',
        top: `${position.top}px`,
        left: `${position.left}px`,
        zIndex: 9999,
      }}
      className={`min-w-[8rem] overflow-hidden rounded-md border p-1 shadow-md
                 ${isDarkMode 
                   ? 'bg-[#121212] border-[#333333]' 
                   : 'bg-white border-slate-200'}`}
    >
      <button
        onClick={onEdit}
        className={`flex w-full items-center gap-2 rounded-sm px-3 py-2 text-sm transition-colors
                   ${isDarkMode 
                     ? 'hover:bg-[#1e1e1e] text-slate-400' 
                     : 'hover:bg-slate-100 text-slate-600'}`}
      >
        <PencilIcon className="h-4 w-4" />
        Edit
      </button>
      <button
        onClick={onDelete}
        className={`flex w-full items-center gap-2 rounded-sm px-3 py-2 text-sm transition-colors
                   text-red-500 hover:text-red-600
                   ${isDarkMode ? 'hover:bg-[#1e1e1e]' : 'hover:bg-slate-100'}`}
      >
        <TrashIcon className="h-4 w-4" />
        Delete
      </button>
    </div>,
    document.body
  );
};

const PersonaCard = ({ persona, isPopup, onPersonaClick, onEdit, onDelete, onAvatarError }) => {
  const { isDarkMode } = useTheme();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState(null);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  // Check if this is a default persona
  const isDefaultPersona = !persona.isCustom; // Add this flag in your data structure

  // Get the correct icon component
  const IconComponent = isDefaultPersona && persona.icon ? ICON_MAP[persona.icon] || UserIcon : UserIcon;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target) && 
          buttonRef.current && !buttonRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleMoreOptions = (e) => {
    e.stopPropagation();
    const buttonRect = buttonRef.current.getBoundingClientRect();
    setMenuPosition({
      top: buttonRect.bottom + window.scrollY,
      left: buttonRect.right - 128, // 8rem (min-w-[8rem]) = 128px
    });
    setIsMenuOpen(!isMenuOpen);
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    onEdit(persona._id);
    setIsMenuOpen(false);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(persona._id);
    setIsMenuOpen(false);
  };

  const handleCardClick = () => {
    if (isPopup) {
      onPersonaClick(persona);
    } else {
      onPersonaClick(persona);
    }
  };

  return (
    <div 
      onClick={handleCardClick}
      className={`${isDarkMode 
               ? 'bg-[#151515] hover:bg-[#1e1e1e]' 
                : 'bg-white hover:bg-gray-50'} 
              rounded-lg border 
              ${isDarkMode ? 'border-[#333333]' : 'border-gray-200'} 
              p-3 relative cursor-pointer transition-colors`}
    >
      <div className="flex items-center gap-3">
        {/* Avatar */}
        <div className="relative flex-shrink-0">
          <div className={`w-10 h-10 rounded-full overflow-hidden ${isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-100'}`}>
            {persona.signedAvatarUrl && persona.signedAvatarUrl !== FALLBACK_AVATAR ? (
              <img 
                src={persona.signedAvatarUrl} 
                alt={persona.name}
                className="w-full h-full object-cover"
                onError={() => onAvatarError(persona._id)}
              />
            ) : (
              <div className="w-full h-full flex items-center justify-center">
                <IconComponent className={`w-6 h-6 ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`} />
              </div>
            )}
          </div>
        </div>

        {/* Content */}
        <div className="flex-1 min-w-0">
          <h2 className={`text-sm font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-800'} truncate`}>
            {persona.name}
          </h2>
          <div className={`text-xs ${isDarkMode ? 'text-slate-400' : 'text-gray-500'} truncate mt-0.5`}>
            {[persona.occupation, persona.location, persona.industry]
              .filter(Boolean)
              .join(' • ')}
          </div>
        </div>

        {/* Actions Menu */}
        {!isPopup && (
          <div className="relative">
            <button
              ref={buttonRef}
              onClick={handleMoreOptions}
              className={`rounded-full p-1.5 transition-colors duration-200
                         ${isDarkMode 
                           ? 'hover:bg-[#1e1e1e] text-slate-400' 
                           : 'hover:bg-gray-100 text-gray-500'}`}
            >
              <EllipsisHorizontalIcon className="h-4 w-4" />
            </button>

            {isMenuOpen && (
              <DropdownMenu
                isDarkMode={isDarkMode}
                onEdit={handleEdit}
                onDelete={handleDelete}
                position={menuPosition}
                menuRef={menuRef}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PersonaCard;
