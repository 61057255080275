import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { ChevronDownIcon, UserGroupIcon, ChatBubbleLeftRightIcon, FlagIcon, PencilIcon, RocketLaunchIcon, PlusIcon, MinusIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../context/ThemeContext';
import { cn } from '../utils/cn';

const toneOptions = [
  "Professional", "Informative", "Analytical", "Authoritative", "Collaborative",
  "Diplomatic", "Empowering", "Engaging", "Formal", "Innovative",
  "Inspirational", "Instructive", "Mentoring", "Motivational", "Objective",
  "Optimistic", "Pragmatic", "Strategic", "Thought-provoking", "Visionary"
];

const goalOptions = [
  "To educate or share knowledge",
  "To entertain or provide inspiration",
  "To challenge ideas or perspectives",
  "To share a personal experience or story",
  "To tell a compelling narrative",
  "To spark discussion or debate",
  "To provide insights or analysis",
  "To offer practical tips or advice",
  "To celebrate achievements or milestones",
  "To express gratitude or appreciation",
  "To reflect on lessons learned",
  "To share industry news or updates",
  "To promote an event or opportunity",
  "To seek input, feedback or collaboration",
  "To showcase creativity or innovative thinking",
  "To build brand awareness",
  "To drive engagement and interactions",
  "To establish thought leadership",
  "To attract potential clients or customers",
  "To connect with like-minded professionals",
  "To share company updates or announcements",
  "To promote products or services",
  "To share motivational or inspiring content",
  "To express personal or professional values",
  "To foster community and networking"
];

const MAX_POSTS = 10;
const MIN_POSTS = 1;

const IconWrapper = ({ icon: Icon, className }) => (
  <Icon className={cn(
    "h-4 w-4 absolute left-3 top-1/2 -translate-y-1/2 pointer-events-none",
    className
  )} />
);

const Input = ({ type, options, value, onChange, placeholder, icon: Icon, minHeight, fullWidth, required }) => {
  const { isDarkMode } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (type === 'textarea' && inputRef.current) {
      inputRef.current.style.height = 'auto';
      const newHeight = Math.max(inputRef.current.scrollHeight, minHeight || 0);
      inputRef.current.style.height = `${newHeight}px`;
    }
  }, [value, type, minHeight]);

  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const getInputClasses = () => {
    return `w-full px-3 py-2 rounded-md text-xs transition-all duration-200 outline-none border ${
      isDarkMode
        ? 'bg-slate-800 border-slate-600 text-slate-200 focus:border-slate-500 focus:bg-slate-700'
        : 'bg-white border-gray-200 text-gray-900 focus:border-blue-500/50 focus:bg-gray-50'
    }`;
  };

  const handleInputChange = (inputValue) => {
    onChange(inputValue);
  };

  const filteredOptions = useMemo(() => 
    options?.filter(option => 
      option.toLowerCase().includes((value || '').toLowerCase())
    ),
    [options, value]
  );

  const inputClasses = useMemo(() => 
    cn(
      "w-full px-3 py-2 rounded-md text-xs transition-all duration-200 outline-none border",
      isDarkMode
        ? "bg-slate-800 border-slate-600 text-slate-200 focus:border-slate-500 focus:bg-slate-700"
        : "bg-white border-gray-200 text-gray-900 focus:border-blue-500/50 focus:bg-gray-50",
      required && "required"
    ),
    [isDarkMode, required]
  );

  const [touched, setTouched] = useState(false);
  const hasError = required && touched && !value;

  if (type === 'dropdown') {
    return (
      <div className={`relative ${fullWidth ? 'w-full' : ''}`} ref={inputRef}>
        <div className="relative">
          <input
            type="text"
            value={value}
            onChange={(e) => handleInputChange(e.target.value)}
            onFocus={() => setIsOpen(true)}
            placeholder={placeholder}
            className={`${getInputClasses()} pl-9 pr-8`}
          />
          {Icon && (
            <Icon
              className={`h-4 w-4 absolute left-3 top-1/2 -translate-y-1/2 ${
                isDarkMode ? 'text-slate-400' : 'text-gray-500'
              }`}
            />
          )}
          <ChevronDownIcon
            className={`h-4 w-4 absolute right-3 top-1/2 -translate-y-1/2 ${
              isDarkMode ? 'text-slate-400' : 'text-gray-500'
            }`}
          />
        </div>
        {isOpen && (
          <div 
            ref={dropdownRef}
            className={`absolute z-10 w-full border-2 rounded-md shadow-lg max-h-60 overflow-auto bottom-full mb-1 ${
              isDarkMode 
                ? 'bg-slate-800 border-slate-500 shadow-slate-900/90' 
                : 'bg-white border-gray-300 shadow-lg shadow-gray-200/90'
            } ${fullWidth ? 'min-w-[200px]' : ''}`}
          >
            {filteredOptions.map((option) => (
              <div
                key={option}
                className={`px-3 py-2 cursor-pointer text-xs transition-colors ${
                  isDarkMode 
                    ? 'text-slate-200 hover:bg-slate-700 active:bg-slate-600' 
                    : 'text-gray-900 hover:bg-gray-50 active:bg-gray-100'
                }`}
                onClick={() => {
                  onChange(option);
                  setIsOpen(false);
                }}
              >
                {option}
              </div>
            ))}
            {filteredOptions.length === 0 && (
              <div className={`px-3 py-2 text-xs ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
                No matches found. You can use this custom value.
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="relative w-full">
      {Icon && (
        <IconWrapper 
          icon={Icon}
          className={cn(
            type === 'textarea' ? "top-3 translate-y-0" : "top-1/2 -translate-y-1/2",
            isDarkMode ? "text-slate-400" : "text-gray-500",
            hasError && "text-red-500",
            "z-10"
          )}
        />
      )}
      {type === 'textarea' ? (
        <textarea
          ref={inputRef}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onBlur={() => setTouched(true)}
          placeholder={placeholder}
          className={cn(
            inputClasses,
            "pl-9 resize-none overflow-hidden relative",
            hasError && "border-red-500"
          )}
          rows={1}
          style={{ minHeight: minHeight ? `${minHeight}px` : 'auto' }}
          aria-required={required}
          aria-invalid={hasError}
        />
      ) : (
        <input
          type="text"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          className={cn(
            inputClasses,
            "pl-9 relative"
          )}
        />
      )}
      {hasError && (
        <span className="absolute -bottom-5 left-0 text-xs text-red-500">
          This field is required
        </span>
      )}
    </div>
  );
};

const NumberInput = ({ value, onChange, min = MIN_POSTS, max = MAX_POSTS }) => {
  const { isDarkMode } = useTheme();

  const handleIncrease = useCallback(() => 
    onChange(Math.min(parseInt(value) + 1, max).toString()),
    [value, max, onChange]
  );

  const handleDecrease = useCallback(() => 
    onChange(Math.max(parseInt(value) - 1, min).toString()),
    [value, min, onChange]
  );

  return (
    <div className={cn(
      "flex items-center border rounded-md transition-colors",
      isDarkMode ? "bg-slate-800 border-slate-600" : "bg-white border-gray-200"
    )}>
      <button
        type="button"
        onClick={handleDecrease}
        disabled={parseInt(value) <= min}
        aria-label="Decrease number"
        className={cn(
          "px-2 py-2 transition-colors disabled:opacity-50",
          isDarkMode 
            ? "text-slate-400 hover:bg-slate-700 active:bg-slate-600" 
            : "text-gray-500 hover:bg-gray-50 active:bg-gray-100"
        )}
      >
        <MinusIcon className="h-4 w-4" />
      </button>
      <input
        type="text"
        value={value}
        onChange={(e) => {
          const newValue = e.target.value;
          if (/^\d*$/.test(newValue) && parseInt(newValue || '1') >= 1 && parseInt(newValue || '1') <= 10) {
            onChange(newValue || '1');
          }
        }}
        className={`w-8 text-center outline-none transition-colors ${
          isDarkMode ? 'bg-slate-800 text-slate-200' : 'bg-white text-gray-900'
        }`}
      />
      <button
        type="button"
        onClick={handleIncrease}
        className={`px-2 py-2 transition-colors ${
          isDarkMode 
            ? 'text-slate-400 hover:bg-slate-700 active:bg-slate-600' 
            : 'text-gray-500 hover:bg-gray-50 active:bg-gray-100'
        }`}
      >
        <PlusIcon className="h-4 w-4" />
      </button>
    </div>
  );
};

const TemplateForm = ({ onSubmit, template, isMinimized, onToggleMinimize }) => {
  const { isDarkMode } = useTheme();
  const [formData, setFormData] = useState({
    contentIdea: '',
    targetAudience: '',
    toneOfVoice: '',
    goalOfPost: '',
    numberOfPosts: '1'
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = useCallback((field, value) => {
    setFormData(prev => ({ ...prev, [field]: value }));
    if (errors[field]) {
      setErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[field];
        return newErrors;
      });
    }
  }, [errors]);

  const validateForm = useCallback(() => {
    const newErrors = {};
    if (!formData.contentIdea.trim()) {
      newErrors.contentIdea = 'Content idea is required';
    }
    return newErrors;
  }, [formData.contentIdea]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsSubmitting(false);
      return;
    }

    try {
      const filteredFormData = Object.entries(formData).reduce((acc, [key, value]) => {
        if (value.toString().trim() !== '') {
          acc[key] = value;
        }
        return acc;
      }, {});
      
      await onSubmit(filteredFormData, template);
    } catch (error) {
      console.error('Error submitting form:', error);
      setErrors({ submit: 'Failed to submit form. Please try again.' });
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isSubmitting) {
    return (
      <div className="flex items-center justify-center p-4">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
      </div>
    );
  }

  if (isMinimized) {
    return null; // Don't render anything if minimized
  }

  return (
    <form 
      onSubmit={handleSubmit} 
      className={cn(
        "w-full mx-auto rounded-lg",
        isDarkMode ? "bg-slate-800" : "bg-white"
      )}
      noValidate
    >
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
        <div className="col-span-1 sm:col-span-2">
          <Input
            type="textarea"
            value={formData.contentIdea}
            onChange={(value) => handleChange('contentIdea', value)}
            placeholder="Content Idea *"
            icon={PencilIcon}
            minHeight={100} // Set a minimum height of 100px for the Content Idea field
          />
        </div>
        <Input
          type="text"
          value={formData.targetAudience}
          onChange={(value) => handleChange('targetAudience', value)}
          placeholder="Target Audience"
          icon={UserGroupIcon}
        />
        <Input
          type="dropdown"
          options={toneOptions}
          value={formData.toneOfVoice}
          onChange={(value) => handleChange('toneOfVoice', value)}
          placeholder="Tone"
          icon={ChatBubbleLeftRightIcon}
          fullWidth
        />
        <div className="col-span-1 sm:col-span-2 flex items-start space-x-2">
          <div className="flex-grow">
            <Input
              type="dropdown"
              options={goalOptions}
              value={formData.goalOfPost}
              onChange={(value) => handleChange('goalOfPost', value)}
              placeholder="Goal of Post"
              icon={FlagIcon}
              fullWidth
            />
          </div>
          <NumberInput
            value={formData.numberOfPosts}
            onChange={(value) => handleChange('numberOfPosts', value)}
          />
          <button 
            type="submit"
            className={`
              flex items-center justify-center px-3 py-2 rounded-md text-xs font-medium transition-all duration-200 whitespace-nowrap
              ${!formData.contentIdea.trim() 
                ? `${isDarkMode 
                    ? 'bg-slate-700/50 text-slate-400 cursor-not-allowed' 
                    : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  }` 
                : `${isDarkMode 
                    ? 'bg-blue-600 hover:bg-blue-500 active:bg-blue-700 text-white' 
                    : 'bg-blue-500 hover:bg-blue-400 active:bg-blue-600 text-white'
                  } hover:shadow-lg hover:shadow-blue-500/20`
              }
            `}
            disabled={!formData.contentIdea.trim()}
          >
            <RocketLaunchIcon className="h-4 w-4 mr-2" />
            Generate Post
          </button>
        </div>
      </div>
      <div className={`mt-4 border-t ${isDarkMode ? 'border-slate-700' : 'border-gray-200'}`}></div>
      {errors.submit && (
        <div className="mt-2 text-xs text-red-500">
          {errors.submit}
        </div>
      )}
    </form>
  );
};

export default TemplateForm;
