import React, { useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useTheme } from '../context/ThemeContext';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import BusinessMetricsTable from './BusinessMetricsTable';
import ImpactGoingForward from './ImpactGoingForward';
import OrganizationChart from './OrganizationChart';
import AccountsOverview from './AccountsOverview';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const TestCharts = () => {
  const { isDarkMode } = useTheme();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const containerRef = useRef(null);

  const toggleFullscreen = async () => {
    if (!document.fullscreenElement) {
      await containerRef.current.requestFullscreen();
      setIsFullscreen(true);
    } else {
      await document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  const data = {
    labels: ['2021', '2022', '2023', '2024', '2025P', '2026P', '2027P'],
    datasets: [
      {
        label: 'Revenue',
        data: [0.41, 1.86, 3.19, 3.53, 6.24, 7.80, 9.75],
        backgroundColor: 'rgb(236, 72, 153)',
        borderRadius: 6,
        barPercentage: 0.8,
        categoryPercentage: 0.7
      },
      {
        label: 'Gross',
        data: [0.13, 1.30, 2.51, 2.50, 4.70, 5.88, 7.34],
        backgroundColor: 'rgb(59, 130, 246)',
        borderRadius: 6,
        barPercentage: 0.8,
        categoryPercentage: 0.7
      },
      {
        label: 'EBITDA',
        data: [0.02, 0.52, 0.23, 0.06, 1.52, 2.20, 3.24],
        backgroundColor: 'rgb(16, 185, 129)',
        borderRadius: 6,
        barPercentage: 0.8,
        categoryPercentage: 0.7
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 60,
        right: 35,
        bottom: 25,
        left: 35
      }
    },
    scales: {
      x: {
        grid: {
          display: true,
          color: 'rgba(75, 85, 99, 0.2)',
          lineWidth: 0.5,
          drawBorder: false,
          borderDash: [4, 4],
        },
        ticks: {
          color: 'rgb(156, 163, 175)',
          padding: 10,
          font: {
            size: 12,
            family: 'ui-monospace, monospace'
          }
        },
        border: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          display: true,
          color: 'rgba(75, 85, 99, 0.2)',
          lineWidth: 0.5,
          drawBorder: false,
          borderDash: [4, 4],
        },
        ticks: {
          color: 'rgb(156, 163, 175)',
          padding: 10,
          font: {
            size: 12,
            family: 'ui-monospace, monospace'
          },
          callback: function(value) {
            return value.toFixed(2);
          }
        },
        border: {
          display: false
        }
      }
    },
    plugins: {
      legend: {
        position: 'top',
        align: 'center',
        labels: {
          padding: 30,
          color: 'rgb(229, 231, 235)',
          font: {
            size: 20,
            weight: '500',
            family: 'ui-sans-serif, system-ui'
          },
          boxWidth: 24,
          boxHeight: 24,
          usePointStyle: true,
          pointStyle: 'rect',
          borderRadius: 6
        }
      },
      tooltip: {
        enabled: true,
        backgroundColor: 'rgba(17, 24, 39, 0.9)',
        titleColor: 'rgb(229, 231, 235)',
        bodyColor: 'rgb(229, 231, 235)',
        padding: 12,
        cornerRadius: 6,
        displayColors: true,
        titleFont: {
          size: 12,
          weight: '500',
          family: 'ui-sans-serif, system-ui'
        },
        bodyFont: {
          size: 12,
          family: 'ui-monospace, monospace'
        },
        callbacks: {
          label: function(context) {
            const value = context.parsed.y;
            return `${context.dataset.label}: ${value.toFixed(2)}`;
          }
        }
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        rotation: 0,
        formatter: (value) => value.toFixed(2),
        color: 'rgb(229, 231, 235)',
        font: {
          weight: '500',
          size: 12,
          family: 'ui-monospace, monospace'
        },
        padding: { top: 6 },
        offset: 0
      }
    }
  };

  return (
    <div className="min-h-screen bg-black">
      <div className="p-8 max-w-[1600px] mx-auto">
        <div ref={containerRef} className="bg-black rounded-xl border border-gray-800 mb-8">
          <div className="flex justify-between items-center px-4 py-3 border-b border-gray-800/50">
            <div className="flex items-center gap-3">
              <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
              </svg>
              <h2 className="text-base text-white font-medium">Financial Metrics</h2>
            </div>
            <button
              onClick={toggleFullscreen}
              className="p-2 rounded-md bg-gray-800/50 hover:bg-gray-700/50 transition-colors"
              title={isFullscreen ? "Exit fullscreen" : "Enter fullscreen"}
            >
              {isFullscreen ? (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 9L4 4m0 0l5-5M4 4l5 5M15 9l5-5m0 0l-5-5m5 5l-5 5M9 15l-5 5m0 0l5 5M4 20l5-5M15 15l5 5m0 0l-5 5m5-5l-5-5" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5v-4m0 4h-4m4 0l-5-5" />
                </svg>
              )}
            </button>
          </div>
          
          <div className={`p-4 ${isFullscreen ? 'h-screen' : ''}`}>
            <div className={`${isFullscreen ? 'h-[calc(100vh-8rem)]' : 'h-[500px]'} bg-black rounded-lg`}>
              <Bar 
                data={data} 
                options={options}
                plugins={[ChartDataLabels]}
              />
            </div>
          </div>
        </div>

        <OrganizationChart />
        
        <BusinessMetricsTable />
        
        <AccountsOverview />
        
        <ImpactGoingForward />
      </div>
    </div>
  );
};

export default TestCharts;