import React, { useState, forwardRef } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { ClipboardDocumentIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { ClipboardDocumentIcon as ClipboardDocumentIconSolid } from '@heroicons/react/24/solid';

// Simplified cn function
const cn = (...classes) => classes.filter(Boolean).join(' ');

// Card component definitions
const Card = forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "rounded-lg border bg-card text-card-foreground shadow-sm",
      className
    )}
    {...props}
  />
));
Card.displayName = "Card";

const CardContent = forwardRef(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("p-4", className)} {...props} />
));
CardContent.displayName = "CardContent";

const cleanMarkdownSymbols = (text) => {
  console.log("Original text:", text);
  let cleaned = text.replace(/^```[\s\S]*?\n|```\s*$/g, '');
  cleaned = cleaned.replace(/^[\s#*_-]+|[\s#*_-]+$/g, '').trim();
  console.log("Cleaned text:", cleaned);
  return cleaned;
};

const PostInsideChatOutput = ({ 
  post, 
  index, 
  onCopy, 
  onEditInPostLibrary, 
  isDarkMode,
  copiedMessages,
  onSave,
  onManagePosts,
  logo,
  addNotification,
  outputNumber,
  personaAvatarUrl,
  personaName,
  personaDetails,
  selectedTargetAudience
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    onCopy(post);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const handleEditInPostLibrary = () => {
    console.log("Original post:", post);
    const cleanedPost = cleanMarkdownSymbols(post);
    console.log("Cleaned post:", cleanedPost);
    onEditInPostLibrary(cleanedPost);
  };

  const renderMarkdown = (content) => (
    <ReactMarkdown 
      remarkPlugins={[remarkGfm]} 
      components={{
        p: ({ node, ...props }) => <p className="mb-2 last:mb-0 text-sm" {...props} />,
        a: ({ node, ...props }) => <a className="text-blue-500 hover:underline text-sm" {...props} />,
        h1: ({ node, ...props }) => <h1 className="text-2xl font-bold mb-4" {...props} />,
        h2: ({ node, ...props }) => <h2 className="text-xl font-bold mb-3" {...props} />,
        h3: ({ node, ...props }) => <h3 className="text-lg font-bold mb-2" {...props} />,
        ul: ({ node, ...props }) => <ul className="list-disc list-inside mb-4" {...props} />,
        ol: ({ node, ...props }) => <ol className="list-decimal list-inside mb-4" {...props} />,
        li: ({ node, ...props }) => <li className="mb-1" {...props} />,
        blockquote: ({ node, ...props }) => (
          <blockquote className="border-l-4 border-gray-300 pl-4 py-2 mb-4 italic" {...props} />
        ),
        code: ({ node, inline, className, children, ...props }) => {
          const match = /language-(\w+)/.exec(className || '');
          return !inline ? (
            <SyntaxHighlighter
              style={tomorrow}
              language={match ? match[1] : 'text'}
              PreTag="div"
              className="rounded-md text-sm mb-2"
              {...props}
            >
              {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
          ) : (
            <code className={`px-1 py-0.5 rounded-sm text-sm ${isDarkMode ? 'bg-slate-700' : 'bg-gray-100'}`} {...props}>
              {children}
            </code>
          );
        },
      }}
      className={`markdown-content ${isDarkMode ? 'dark' : 'light'} text-sm`}
    >
      {content}
    </ReactMarkdown>
  );

  const AvatarSkeleton = () => (
    <div className={`w-8 h-8 rounded-full ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'}`}></div>
  );

  const PersonaInfoSkeleton = () => (
    <div className="flex flex-col">
      <div className={`h-4 w-20 mb-1 ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} rounded`}></div>
      <div className={`h-3 w-16 ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} rounded`}></div>
    </div>
  );

  const DottedIconSkeleton = () => (
    <div className={`w-4 h-4 rounded-full ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'}`}></div>
  );

  return (
    <Card className={`w-full max-w-[500px] mb-4 ${isDarkMode ? 'bg-slate-800 border-slate-700' : 'bg-white border-gray-200'}`}>
      <CardContent className="p-4">
        {/* Persona and Target Audience Information */}
        <div className={`flex items-center justify-between mb-4 ${isDarkMode ? 'text-slate-300' : 'text-gray-700'}`}>
          <div className="flex items-center flex-grow">
            <div className={`w-8 h-8 flex-shrink-0 mr-2 rounded-full overflow-hidden ${
              isDarkMode ? 'border border-slate-700' : 'border border-gray-200'
            }`}>
              {personaAvatarUrl ? (
                <img
                  src={personaAvatarUrl}
                  alt={personaName}
                  className="w-full h-full object-cover"
                />
              ) : (
                <AvatarSkeleton />
              )}
            </div>
            {personaName ? (
              <div>
                <span className="font-medium text-sm">{personaName}</span>
                {personaDetails && (
                  <span className="text-xs block opacity-70">{`${personaDetails.occupation || ''}, ${personaDetails.age || ''}`}</span>
                )}
              </div>
            ) : (
              <PersonaInfoSkeleton />
            )}
          </div>
          {selectedTargetAudience ? (
            <div className="text-xs">
              <span className="font-medium">Target: </span>
              <span>{selectedTargetAudience.name}</span>
            </div>
          ) : (
            <DottedIconSkeleton />
          )}
        </div>

        <div className="mb-2">
          <h3 className="font-semibold">{outputNumber}</h3>
        </div>
        <div className="mt-2 text-sm">
          {renderMarkdown(post)}
        </div>
        <div className={`flex justify-end items-center mt-4 pt-3 border-t ${isDarkMode ? 'border-slate-700' : 'border-gray-200'}`}>
          <button
            onClick={handleCopy}
            className={`flex items-center px-3 py-1 rounded mr-2 transition-colors duration-200 ${
              isDarkMode 
                ? 'text-slate-300 hover:text-slate-100 hover:bg-slate-700' 
                : 'text-gray-600 hover:text-gray-800 hover:bg-gray-200'
            }`}
            title={isCopied ? "Copied!" : "Copy to clipboard"}
          >
            {isCopied ? (
              <ClipboardDocumentIconSolid className="h-4 w-4 mr-1" />
            ) : (
              <ClipboardDocumentIcon className="h-4 w-4 mr-1" />
            )}
            <span className="text-xs">{isCopied ? "Copied!" : "Copy"}</span>
          </button>
          <button
            onClick={handleEditInPostLibrary}
            className={`flex items-center px-3 py-1 rounded transition-colors duration-200 ${
              isDarkMode 
                ? 'text-slate-300 hover:text-slate-100 hover:bg-slate-700' 
                : 'text-gray-600 hover:text-gray-800 hover:bg-gray-200'
            }`}
            title="Edit in Post Library"
          >
            <PencilSquareIcon className="h-4 w-4 mr-1" />
            <span className="text-xs">Edit in Post Library</span>
          </button>
        </div>
      </CardContent>
    </Card>
  );
};

export default PostInsideChatOutput;
