import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../../context/ThemeContext';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  ArrowLeftIcon,
  DocumentDuplicateIcon,
  ChevronDownIcon,
  CheckIcon,
  UserIcon,
  UserGroupIcon,
  CalendarIcon,
  ClockIcon,
  ExclamationCircleIcon,
  DocumentMagnifyingGlassIcon,
  BriefcaseIcon,
  BuildingOfficeIcon,
  InformationCircleIcon,
  XMarkIcon,
  AdjustmentsHorizontalIcon,
  ArrowDownTrayIcon,
  BookmarkIcon,
  FolderIcon,
  DocumentTextIcon
} from '@heroicons/react/24/outline';
import { contentIdeaApi, saveIdea, getClientFolders, moveIdeaToFolder } from '../../api';
import { createPortal } from 'react-dom';
import IdeaCard from './IdeaCard';
import { toast } from 'react-toastify';
import ContentStrategyPDFDownloader from './ContentStrategyPDFDownloader';
import ReactMarkdown from 'react-markdown';

// Update the TopBar component to include Info text
const TopBar = ({ isDarkMode, onBack, onInfo }) => (
  <div className={`${isDarkMode ? 'bg-[#121212]/95' : 'bg-white/50'} 
    backdrop-blur-sm sticky top-0 z-10 h-10 flex items-center justify-between px-3 w-full border-b ${
    isDarkMode ? 'border-[#333333]' : 'border-gray-200/60'}`}>
    <div className="flex items-center flex-1 gap-2">
      <button
        onClick={onBack}
        className={`p-1 rounded-md transition-colors ${
          isDarkMode 
            ? 'hover:bg-slate-700 text-slate-400' 
            : 'hover:bg-slate-200 text-slate-600'
        }`}
      >
        <ArrowLeftIcon className="w-4 h-4" />
      </button>
      <DocumentMagnifyingGlassIcon className={`h-4 w-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`} />
      <h2 className={`text-xs font-medium ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>
        Content Strategy Details
      </h2>
    </div>
    <button
      onClick={onInfo}
      className={`flex items-center gap-1.5 px-2 py-1 rounded-md text-xs transition-colors ${
        isDarkMode 
          ? 'hover:bg-slate-700 text-slate-400' 
          : 'hover:bg-slate-200 text-slate-600'
      }`}
    >
      <InformationCircleIcon className="w-3.5 h-3.5" />
      <span>Info</span>
    </button>
  </div>
);

// Add ActionBar component from GeneratedIdeas.js
const ActionBar = ({ strategy, isDarkMode, selectedPersona }) => {
  const [copyState, setCopyState] = useState('idle');
  const [downloadingPDF, setDownloadingPDF] = useState(false);

  // Calculate statistics
  const stats = {
    frameworks: strategy.frameworks?.length || 0,
    totalIdeas: strategy.frameworks?.reduce((total, framework) => 
      total + framework.ideaGroups.reduce((groupTotal, group) => 
        groupTotal + (group.ideas?.length || 0), 0), 0) || 0
  };

  const copyAllIdeas = async () => {
    if (copyState !== 'idle') return;
    
    setCopyState('copying');
    try {
      const formattedIdeas = strategy.frameworks
        .map(framework => {
          return `${framework.frameworkName}\n\n${framework.ideaGroups
            .map(group => {
              return `${group.groupName}:\n${group.ideas
                .map(idea => {
                  return `• ${idea.content}${idea.details ? `\n  ${idea.details}` : ''}${
                    idea.targetOutcome ? `\n  Target Outcome: ${idea.targetOutcome}` : ''
                  }`
                })
                .join('\n\n')}`
            })
            .join('\n\n')}`
        })
        .join('\n\n---\n\n');

      await navigator.clipboard.writeText(formattedIdeas);
      setCopyState('copied');
      toast.success('All ideas copied to clipboard');
      
      setTimeout(() => {
        setCopyState('idle');
      }, 3500);
    } catch (error) {
      console.error('Copy error:', error);
      toast.error('Failed to copy ideas');
      setCopyState('idle');
    }
  };

  const downloadPDF = () => {
    setDownloadingPDF(true);
    try {
      // Format data for PDF generation
      const pdfData = {
        data: {
          frameworks: strategy.frameworks,
          persona: strategy.persona,
          audience: strategy.audience
        }
      };
      
      const pdfDownloader = new ContentStrategyPDFDownloader(pdfData, strategy.persona);
      pdfDownloader.generatePDF();
      toast.success('PDF downloaded successfully');
    } catch (error) {
      console.error('PDF generation error:', error);
      toast.error('Failed to generate PDF');
    } finally {
      setDownloadingPDF(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className={`sticky top-0 z-10 mb-4 rounded-lg ring-1 backdrop-blur-lg ${
        isDarkMode 
          ? 'bg-[#121212] ring-[#333333]' 
          : 'bg-white/90 ring-blue-100'
      }`}
    >
      <div className="px-4 py-3">
        <div className="flex items-center justify-between">
          <span className={`text-sm ${
            isDarkMode ? 'text-blue-400' : 'text-blue-500'
          }`}>
            <span className={`font-medium ${
              isDarkMode ? 'text-blue-100' : 'text-blue-900'
            }`}>{stats.totalIdeas} ideas</span>
            {strategy.persona?.name && (
              <>
                <span> generated for </span>
                <span className={`font-medium ${
                  isDarkMode ? 'text-blue-100' : 'text-blue-900'
                }`}>{strategy.persona.name}</span>
              </>
            )}
          </span>

          <div className="flex items-center gap-2">
            <button
              onClick={copyAllIdeas}
              disabled={copyState !== 'idle'}
              className={`flex items-center gap-1.5 px-2.5 py-1 rounded-md text-xs transition-all duration-200 ${
                copyState === 'copied'
                  ? isDarkMode
                    ? 'bg-green-500/20 text-green-400 hover:bg-green-500/30'
                    : 'bg-green-50 text-green-600 hover:bg-green-100'
                  : isDarkMode
                  ? 'hover:bg-blue-900/50 text-blue-200'
                  : 'hover:bg-blue-100/80 text-blue-700'
              } ${
                copyState === 'copying' ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {copyState === 'copied' ? (
                <>
                  <CheckIcon className="w-3.5 h-3.5 animate-scale-check" />
                  <span>Copied!</span>
                </>
              ) : copyState === 'copying' ? (
                <>
                  <DocumentDuplicateIcon className="w-3.5 h-3.5 animate-pulse" />
                  <span>Copying...</span>
                </>
              ) : (
                <>
                  <DocumentDuplicateIcon className="w-3.5 h-3.5" />
                  <span>Copy All</span>
                </>
              )}
            </button>
            
            <button
              onClick={downloadPDF}
              disabled={downloadingPDF}
              className={`flex items-center gap-1.5 px-2.5 py-1 rounded-md text-xs transition-colors ${
                downloadingPDF
                  ? 'opacity-50 cursor-not-allowed'
                  : isDarkMode
                  ? 'hover:bg-blue-900/50 text-blue-200'
                  : 'hover:bg-blue-100/80 text-blue-700'
              }`}
            >
              <ArrowDownTrayIcon className={`w-3.5 h-3.5 ${downloadingPDF ? 'animate-pulse' : ''}`} />
              <span>{downloadingPDF ? 'Downloading...' : 'PDF'}</span>
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

// Update the InfoPopup component's background overlay
const InfoPopup = ({ strategy, isDarkMode, onClose }) => {
  if (!strategy) return null;

  return (
    <>
      {/* Background Overlay - updated to white blur */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={onClose}
        className={`fixed inset-0 z-40 ${
          isDarkMode 
            ? 'bg-black/50' 
            : 'bg-white/30'
        } backdrop-blur-sm`}
      />

      {/* Popup Content */}
      <div className="fixed inset-x-6 top-16 z-50">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className={`relative w-full max-w-2xl mx-auto rounded-xl border ${
            isDarkMode 
              ? 'bg-[#121212] border-[#333333]' 
              : 'bg-white/95 border-slate-200/50'
          } backdrop-blur-sm`}
        >
          {/* Header - updated border style */}
          <div className={`sticky top-0 z-10 px-4 py-3 border-b rounded-t-xl ${
            isDarkMode 
              ? 'bg-[#121212] border-[#333333]' 
              : 'bg-white/95 border-slate-200/30'
          } backdrop-blur-sm`}>
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <InformationCircleIcon className={`w-4 h-4 ${
                  isDarkMode ? 'text-slate-400' : 'text-slate-600'
                }`} />
                <h3 className={`text-sm font-medium ${
                  isDarkMode ? 'text-slate-200' : 'text-slate-900'
                }`}>
                  Strategy Details
                </h3>
              </div>
              <button
                onClick={onClose}
                className={`p-1 rounded-md transition-colors ${
                  isDarkMode 
                    ? 'hover:bg-slate-800 text-slate-400' 
                    : 'hover:bg-slate-100 text-slate-600'
                }`}
              >
                <XMarkIcon className="w-4 h-4" />
              </button>
            </div>
          </div>

          {/* Scrollable Content - updated inner container styles */}
          <div className="max-h-[calc(100vh-12rem)] overflow-y-auto">
            <div className="p-4 space-y-6">
              {/* Persona Info */}
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <UserIcon className={`w-4 h-4 ${
                    isDarkMode ? 'text-slate-400' : 'text-slate-600'
                  }`} />
                  <h4 className={`text-xs font-medium ${
                    isDarkMode ? 'text-slate-300' : 'text-slate-700'
                  }`}>
                    Content Creator
                  </h4>
                </div>
                <div className={`p-3 rounded-lg border ${
                  isDarkMode 
                    ? 'bg-[#1e1e1e] border-[#333333]' 
                    : 'bg-slate-50/30 border-slate-200/30'
                }`}>
                  <div className="space-y-2 text-xs">
                    <div className="grid grid-cols-[80px_1fr] gap-2">
                      <span className={isDarkMode ? 'text-slate-400' : 'text-slate-500'}>Name</span>
                      <span className={isDarkMode ? 'text-slate-200' : 'text-slate-900'}>
                        {strategy.persona?.name}
                      </span>
                    </div>
                    <div className="grid grid-cols-[80px_1fr] gap-2">
                      <span className={isDarkMode ? 'text-slate-400' : 'text-slate-500'}>Role</span>
                      <span className={isDarkMode ? 'text-slate-200' : 'text-slate-900'}>
                        {strategy.persona?.occupation}
                      </span>
                    </div>
                    <div className="grid grid-cols-[80px_1fr] gap-2">
                      <span className={isDarkMode ? 'text-slate-400' : 'text-slate-500'}>Goals</span>
                      <span className={isDarkMode ? 'text-slate-200' : 'text-slate-900'}>
                        {strategy.persona?.contentGoals}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Audience Info */}
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <UserGroupIcon className={`w-4 h-4 ${
                    isDarkMode ? 'text-slate-400' : 'text-slate-600'
                  }`} />
                  <h4 className={`text-xs font-medium ${
                    isDarkMode ? 'text-slate-300' : 'text-slate-700'
                  }`}>
                    Target Audience
                  </h4>
                </div>
                <div className={`p-3 rounded-lg border ${
                  isDarkMode 
                    ? 'bg-[#1e1e1e] border-[#333333]' 
                    : 'bg-slate-50/30 border-slate-200/30'
                }`}>
                  <div className="space-y-2 text-xs">
                    <div className="grid grid-cols-[80px_1fr] gap-2">
                      <span className={isDarkMode ? 'text-slate-400' : 'text-slate-500'}>Name</span>
                      <span className={isDarkMode ? 'text-slate-200' : 'text-slate-900'}>
                        {strategy.audience?.targetAudienceName}
                      </span>
                    </div>
                    <div className="grid grid-cols-[80px_1fr] gap-2">
                      <span className={isDarkMode ? 'text-slate-400' : 'text-slate-500'}>Industry</span>
                      <span className={isDarkMode ? 'text-slate-200' : 'text-slate-900'}>
                        {strategy.audience?.industry}
                      </span>
                    </div>
                    <div className="grid grid-cols-[80px_1fr] gap-2 items-start">
                      <span className={isDarkMode ? 'text-slate-400' : 'text-slate-500'}>Pain Points</span>
                      <div className="space-y-1">
                        {strategy.audience?.painPoints?.map((point, index) => (
                          <div key={index} className="flex items-start gap-2">
                            <span className="text-blue-500 mt-1">•</span>
                            <span className={isDarkMode ? 'text-slate-200' : 'text-slate-900'}>
                              {point}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
};

// Find the FloatingFolderBar component and update its styles:

const FloatingFolderBar = ({ isDarkMode, onClose, onSelectFolder, folders }) => {
  const [isSelectingFolder, setIsSelectingFolder] = useState(false);

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      className="fixed inset-x-0 bottom-8 z-50 flex justify-center px-4"
    >
      <div className={`max-w-xl w-full flex items-center gap-3 px-6 py-4 rounded-xl shadow-lg backdrop-blur-lg ${
        isDarkMode 
          ? 'bg-[#121212]/90 ring-1 ring-[#333333]' 
          : 'bg-white ring-1 ring-slate-200'
      }`}>
        {!isSelectingFolder ? (
          <>
            <div className={`flex items-center gap-2 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-700'
            }`}>
              <CheckIcon className="w-4 h-4" />
              <span className="text-sm">Saved successfully</span>
            </div>
            <div className="flex-1" />
            <button
              onClick={() => setIsSelectingFolder(true)}
              className={`px-3 py-1.5 text-sm rounded-md transition-colors ${
                isDarkMode
                  ? 'bg-[#1e1e1e] text-slate-300 hover:bg-[#1a1a1a]'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              Add to folder
            </button>
            <button
              onClick={onClose}
              className={`p-1.5 rounded-md transition-colors ${
                isDarkMode
                  ? 'text-gray-400 hover:bg-gray-700'
                  : 'text-gray-500 hover:bg-gray-100'
              }`}
            >
              <XMarkIcon className="w-4 h-4" />
            </button>
          </>
        ) : (
          <>
            <div className="flex items-center gap-3 flex-1">
              <FolderIcon className={`w-4 h-4 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
              <div className="flex flex-wrap gap-2">
                {folders.map((folder) => (
                  <button
                    key={folder._id}
                    onClick={() => {
                      onSelectFolder(folder);
                      setIsSelectingFolder(false);
                    }}
                    className={`px-3 py-1.5 text-sm rounded-md transition-colors ${
                      isDarkMode
                        ? 'bg-[#1e1e1e] text-slate-300 hover:bg-[#1a1a1a] hover:ring-1 hover:ring-[#333333]'
                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200 hover:ring-1 hover:ring-gray-300'
                    }`}
                  >
                    {folder.name}
                  </button>
                ))}
              </div>
            </div>
            <button
              onClick={() => setIsSelectingFolder(false)}
              className={`p-1.5 rounded-md transition-colors ${
                isDarkMode
                  ? 'text-gray-400 hover:bg-gray-700'
                  : 'text-gray-500 hover:bg-gray-100'
              }`}
            >
              <XMarkIcon className="w-4 h-4" />
            </button>
          </>
        )}
      </div>
    </motion.div>
  );
};

const ViewStrategy = () => {
  const { id } = useParams();
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const [strategy, setStrategy] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const [copiedIdeaId, setCopiedIdeaId] = useState(null);
  const [savingStates, setSavingStates] = useState({});
  const [savedIdea, setSavedIdea] = useState(null);
  const [folders, setFolders] = useState([]);
  const [savedGroupIdeas, setSavedGroupIdeas] = useState({});

  // Load folders when component mounts
  useEffect(() => {
    const loadFolders = async () => {
      try {
        const response = await getClientFolders();
        setFolders(response.data || response || []); // Handle both response formats
        console.log('Loaded folders:', response); // Debug log
      } catch (error) {
        console.error('Error loading folders:', error);
        toast.error('Failed to load folders');
      }
    };
    loadFolders();
  }, []);

  useEffect(() => {
    loadStrategy();
  }, [id]);

  const loadStrategy = async () => {
    try {
      console.log('🔄 Loading strategy:', id);
      setLoading(true);
      setError(null);
      const data = await contentIdeaApi.getStrategy(id);
      console.log('📥 Received strategy:', data);
      setStrategy(data.data || data);
    } catch (error) {
      console.error('❌ Error loading strategy:', error);
      setError(error.message || 'Failed to load strategy');
    } finally {
      setLoading(false);
    }
  };

  const handleCopyIdea = (idea) => {
    const textToCopy = [
      idea.content,
      idea.details,
      idea.targetOutcome ? `Target Outcome: ${idea.targetOutcome}` : null
    ]
      .filter(Boolean)
      .join('\n\n');
      
    navigator.clipboard.writeText(textToCopy);
    setCopiedIdeaId(idea.id || idea._id);
    toast.success('Idea copied to clipboard');
    setTimeout(() => setCopiedIdeaId(null), 2000);
  };

  const handleSaveIdea = async (idea, framework, group) => {
    const ideaId = idea.id || idea._id;
    
    try {
      setSavingStates(prev => ({ ...prev, [ideaId]: 'saving' }));
      
      const savedIdeaResponse = await saveIdea({
        content: idea.content,
        details: idea.details || '',
        targetOutcome: idea.targetOutcome || '',
        source: 'content-strategy',
        sourceId: strategy._id,
        category: framework.frameworkName || 'Uncategorized',
        tags: [
          strategy.persona?.name && `Creator: ${strategy.persona.name}`,
          strategy.audience?.targetAudienceName && `Audience: ${strategy.audience.targetAudienceName}`,
          group.groupName && `Type: ${group.groupName}`
        ].filter(Boolean),
        status: 'pending'
      });

      setSavingStates(prev => ({ ...prev, [ideaId]: 'saved' }));
      setSavedIdea(savedIdeaResponse.data || savedIdeaResponse); // Handle both response formats
      toast.success('Idea saved successfully');
    } catch (error) {
      setSavingStates(prev => ({ ...prev, [ideaId]: null }));
      console.error('Error saving idea:', error);
      toast.error('Failed to save idea');
    }
  };

  const handleSaveGroup = async (framework, group) => {
    const groupKey = `${framework.frameworkName}-${group.groupName}`;
    
    try {
      setSavedGroupIdeas(prev => ({ ...prev, [groupKey]: 'saving' }));
      
      // Save all ideas in the group
      const savePromises = group.ideas.map(idea => 
        saveIdea({
          content: idea.content,
          details: idea.details || '',
          targetOutcome: idea.targetOutcome || '',
          source: 'content-strategy',
          sourceId: strategy._id,
          category: framework.frameworkName || 'Uncategorized',
          tags: [
            strategy.persona?.name && `Creator: ${strategy.persona.name}`,
            strategy.audience?.targetAudienceName && `Audience: ${strategy.audience.targetAudienceName}`,
            group.groupName && `Type: ${group.groupName}`
          ].filter(Boolean),
          status: 'pending'
        })
      );

      const savedIdeas = await Promise.all(savePromises);
      setSavedGroupIdeas(prev => ({ ...prev, [groupKey]: 'saved' }));
      setSavedIdea(savedIdeas);
      toast.success(`${group.ideas.length} ideas saved successfully`);
    } catch (error) {
      setSavedGroupIdeas(prev => ({ ...prev, [groupKey]: null }));
      console.error('Error saving group:', error);
      toast.error('Failed to save ideas');
    }
  };

  const handleAddToFolder = async (folderId) => {
    try {
      if (Array.isArray(savedIdea)) {
        // Handle group of ideas
        await Promise.all(savedIdea.map(idea => 
          moveIdeaToFolder(idea._id || idea.id, folderId)
        ));
        toast.success('Ideas added to folder successfully');
      } else {
        // Handle single idea
        await moveIdeaToFolder(savedIdea._id || savedIdea.id, folderId);
        toast.success('Idea added to folder successfully');
      }
    } catch (error) {
      console.error('Error adding to folder:', error);
      toast.error('Failed to add to folder');
    } finally {
      setSavedIdea(null);
    }
  };

  if (loading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full h-full flex flex-col items-center justify-center">
        <ExclamationCircleIcon className="w-12 h-12 text-red-500 mb-4" />
        <h3 className={`text-lg font-medium mb-2 ${
          isDarkMode ? 'text-slate-200' : 'text-slate-900'
        }`}>
          Failed to load strategy
        </h3>
        <p className={`text-sm ${
          isDarkMode ? 'text-slate-400' : 'text-slate-500'
        }`}>
          {error}
        </p>
        <button
          onClick={() => navigate('/content-strategy/manager')}
          className={`mt-6 px-4 py-2 rounded-md text-sm font-medium ${
            isDarkMode
              ? 'bg-[#1e1e1e] text-slate-200 hover:bg-[#1a1a1a]'
              : 'bg-slate-100 text-slate-700 hover:bg-slate-200'
          }`}
        >
          Back to Strategies
        </button>
      </div>
    );
  }

  if (!strategy) return null;

  return (
    <div className={`h-full w-full flex flex-col ${isDarkMode ? 'bg-[#121212]' : 'bg-[#fafafa]'}`}>
      <TopBar 
        isDarkMode={isDarkMode} 
        onBack={() => navigate('/content-strategy/manager')}
        onInfo={() => setShowInfo(true)}
      />
      
      <div className="flex-1 overflow-auto">
        <div className="w-full max-w-4xl mx-auto p-6 space-y-6">
          <ActionBar 
            strategy={strategy} 
            isDarkMode={isDarkMode}
            selectedPersona={strategy.persona}
          />

          <div className="space-y-6">
            {strategy.frameworks ? (
              strategy.frameworks.map((framework, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4, delay: index * 0.1 }}
                  className={`rounded-xl ${
                    isDarkMode 
                      ? 'bg-[#151515]' 
                      : 'bg-white'
                  }`}
                >
                  {/* Framework Header */}
                  <div className={`px-6 py-4 border-b ${
                    isDarkMode ? 'border-[#333333]' : 'border-slate-200'
                  }`}>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-4">
                        <div className={`flex items-center justify-center w-10 h-10 rounded-lg font-medium ${
                          isDarkMode 
                            ? 'bg-slate-800/50 text-slate-300' 
                            : 'bg-slate-100 text-slate-600'
                        }`}>
                          {index + 1}
                        </div>
                        <div>
                          <h3 className={`text-base font-medium ${
                            isDarkMode ? 'text-slate-200' : 'text-slate-700'
                          }`}>
                            {getFormattedFrameworkName(framework.frameworkName)}
                          </h3>
                          <p className={`text-sm mt-0.5 ${
                            isDarkMode ? 'text-slate-400' : 'text-slate-500'
                          }`}>
                            {framework.ideaGroups.length} series · {
                              framework.ideaGroups.reduce((total, group) => 
                                total + (group.ideas?.length || 0), 0
                              )} ideas
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Framework Content */}
                  <div className="p-6 grid grid-cols-1 md:grid-cols-2 gap-6">
                    {framework.ideaGroups.map((group, groupIndex) => (
                      <motion.div
                        key={groupIndex}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3, delay: groupIndex * 0.05 }}
                        className={`rounded-xl ring-1 overflow-hidden ${
                          isDarkMode 
                            ? 'bg-[#1a1a1a] ring-[#333333]' 
                            : 'bg-slate-50 ring-slate-200'
                        }`}
                      >
                        {/* Group Header */}
                        <div className={`px-4 py-3 border-b ${
                          isDarkMode ? 'border-[#333333]' : 'border-slate-200'
                        }`}>
                          <div className="flex items-center justify-between">
                            <div className="flex items-center gap-2">
                              <DocumentTextIcon className={`w-4 h-4 ${
                                isDarkMode ? 'text-slate-400' : 'text-slate-500'
                              }`} />
                              <span className={`text-sm font-medium ${
                                isDarkMode ? 'text-slate-300' : 'text-slate-700'
                              }`}>
                                {getFormattedSeriesName(group.groupName)}
                              </span>
                              <span className={`text-xs ${
                                isDarkMode ? 'text-slate-500' : 'text-slate-400'
                              }`}>
                                {group.ideas?.length || 0} ideas
                              </span>
                            </div>

                            {/* Restore group save button */}
                            <button
                              onClick={() => handleSaveGroup(framework, group)}
                              className={`p-1.5 rounded-md transition-colors ${
                                isDarkMode 
                                  ? 'hover:bg-[#252525] text-slate-400 hover:text-slate-300' 
                                  : 'hover:bg-slate-200/80 text-slate-500 hover:text-slate-600'
                              }`}
                              title="Save all ideas in this group"
                            >
                              <BookmarkIcon 
                                className={`w-4 h-4 transition-all duration-200 ${
                                  savedGroupIdeas[`${framework.frameworkName}-${group.groupName}`] === 'saving' 
                                    ? 'animate-pulse opacity-50' 
                                    : savedGroupIdeas[`${framework.frameworkName}-${group.groupName}`] === 'saved'
                                      ? 'fill-current'
                                      : ''
                                }`} 
                              />
                            </button>
                          </div>
                        </div>

                        {/* Group Content */}
                        <div className={`divide-y ${
                          isDarkMode ? 'divide-[#333333]' : 'divide-slate-200'
                        }`}>
                          {group.ideas.map((idea, ideaIndex) => {
                            const isIdeaCopied = copiedIdeaId === (idea.id || idea._id);
                            const ideaId = idea.id || idea._id;

                            return (
                              <div
                                key={ideaIndex}
                                className={`p-4 group transition-colors ${
                                  isDarkMode 
                                    ? 'hover:bg-[#202020]' 
                                    : 'hover:bg-slate-100/50'
                                }`}
                              >
                                <div className="flex items-center justify-between gap-4">
                                  <div className="flex-1 space-y-2">
                                    <div className={`text-sm leading-relaxed whitespace-pre-wrap ${
                                      isDarkMode ? 'text-slate-300' : 'text-slate-700'
                                    }`}>
                                      {idea.content}
                                    </div>
                                    
                                    {idea.details && (
                                      <div className={`text-xs whitespace-pre-wrap ${
                                        isDarkMode ? 'text-slate-400' : 'text-slate-500'
                                      }`}>
                                        {idea.details}
                                      </div>
                                    )}
                                    
                                    {idea.targetOutcome && (
                                      <span className={`inline-flex text-xs px-2 py-1 rounded-full ${
                                        isDarkMode 
                                          ? 'bg-[#1a1a1a] text-slate-300 ring-1 ring-[#333333]' 
                                          : 'bg-slate-100/60 text-slate-600 ring-1 ring-slate-200/50'
                                      }`}>
                                        {idea.targetOutcome}
                                      </span>
                                    )}
                                  </div>

                                  {/* Restore idea action buttons */}
                                  <div className="flex items-center gap-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                                    <button
                                      onClick={() => handleSaveIdea(idea, framework, group)}
                                      className={`p-1.5 rounded-md transition-colors ${
                                        isDarkMode 
                                          ? 'hover:bg-[#252525] text-slate-400 hover:text-slate-300' 
                                          : 'hover:bg-slate-200/80 text-slate-500 hover:text-slate-600'
                                      }`}
                                      title="Save to Ideas"
                                    >
                                      <BookmarkIcon 
                                        className={`w-4 h-4 transition-all duration-200 ${
                                          savingStates[ideaId] === 'saving' 
                                            ? 'animate-pulse opacity-50' 
                                            : savingStates[ideaId] === 'saved'
                                              ? 'fill-current'
                                              : ''
                                        }`} 
                                      />
                                    </button>

                                    <button
                                      onClick={() => handleCopyIdea(idea)}
                                      className={`p-1.5 rounded-md transition-colors ${
                                        isDarkMode 
                                          ? 'hover:bg-[#252525] text-slate-400 hover:text-slate-300' 
                                          : 'hover:bg-slate-200/80 text-slate-500 hover:text-slate-600'
                                      }`}
                                      title="Copy to clipboard"
                                    >
                                      <DocumentDuplicateIcon 
                                        className={`w-4 h-4 transition-all duration-200 ${
                                          isIdeaCopied ? 'fill-current' : ''
                                        }`} 
                                      />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </motion.div>
                    ))}
                  </div>
                </motion.div>
              ))
            ) : (
              <div className="text-center py-12">
                <p className={isDarkMode ? 'text-slate-400' : 'text-slate-600'}>
                  No content available.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Restore FloatingFolderBar */}
      <AnimatePresence>
        {savedIdea && (
          <FloatingFolderBar
            isDarkMode={isDarkMode}
            onClose={() => setSavedIdea(null)}
            onSelectFolder={(folder) => handleAddToFolder(folder._id)}
            folders={folders}
            savedIdea={savedIdea}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showInfo && (
          <InfoPopup
            strategy={strategy}
            isDarkMode={isDarkMode}
            onClose={() => setShowInfo(false)}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

// Add the formatting functions at the top of the file
const getFormattedFrameworkName = (name) => {
  const cleanName = name.replace('FRAMEWORK', '').trim();
  switch (cleanName.toLowerCase()) {
    case 'actionable':
      return 'Practical Implementation Guide';
    case 'aspirational':
      return 'Strategic Vision & Growth';
    case 'anthropological':
      return 'Human-Centered Insights';
    case 'analytical':
      return 'Data-Driven Analysis';
    default:
      return cleanName;
  }
};

const getFormattedSeriesName = (name) => {
  const seriesMap = {
    'tips': 'Expert Tips',
    'hacks': 'Practical Hacks',
    'ultimate guides': 'Comprehensive Guides',
    'lessons': 'Key Lessons',
    'mistakes': 'Common Mistakes',
    'reflections': 'Strategic Insights',
    'getting started': 'Quick Start Guide',
    'fears': 'Emotional Challenges',
    'challenges': 'Implementation Challenges',
    'misconceptions': 'Common Misconceptions',
    'trends': 'Industry Trends',
    'impact': 'Impact Analysis',
    'methodology': 'Methodology Breakdown'
  };
  
  const cleanName = name.toLowerCase().replace(' series', '').trim();
  return seriesMap[cleanName] || name;
};

export default ViewStrategy; 