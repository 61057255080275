import React from 'react';
import { FolderPlusIcon, ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../context/ThemeContext';

const ActionButtonGroup = ({ onCreateFolder, onFileUpload }) => {
  const { isDarkMode } = useTheme();

  return (
    <div
      className={`rounded-full px-2 py-1 flex items-center shadow-lg transition-all duration-300 ease-in-out ${
        isDarkMode
          ? 'bg-gray-800 text-white border border-gray-700'
          : 'bg-white text-gray-800 border border-gray-200'
      }`}
    >
      <button
        onClick={onCreateFolder}
        className="flex items-center justify-center px-4 py-2 rounded-full bg-black text-white"
      >
        <FolderPlusIcon className="w-4 h-4 mr-2 text-white" />
        <span className="font-medium whitespace-nowrap text-sm">
          Create Folder
        </span>
      </button>
      <label
        htmlFor="fileUpload"
        className="flex items-center justify-center px-4 py-2 rounded-full bg-black text-white ml-2 cursor-pointer"
      >
        <ArrowUpTrayIcon className="w-4 h-4 mr-2 text-white" />
        <span className="font-medium whitespace-nowrap text-sm">
          Upload
        </span>
        <input
          type="file"
          id="fileUpload"
          className="hidden"
          onChange={onFileUpload}
          accept=".txt,.pdf,.docx,.png,.rtf"
        />
      </label>
    </div>
  );
};

export default ActionButtonGroup;