import api from '../api';

const API_BASE_URL = 'http://localhost:3000/api'; // Adjust if your local API uses a different port

export const fetchUserProfile = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/user/profile`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch user profile');
    }

    const data = await response.json();
    console.log('Fetched user data:', data); // Add this line for debugging
    return data;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
};

export const updateUserProfile = async (profileData) => {
  try {
    const response = await api.put('/api/user/profile', profileData, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.data) {
      throw new Error('Failed to update user profile: No data received');
    }

    return response.data;
  } catch (error) {
    console.error('Error updating user profile:', error.response?.data || error.message);
    throw error;
  }
};

export const fetchUserData = async (token) => {
  try {
    const response = await fetch(`${API_BASE_URL}/user/profile`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch user data');
    }

    const data = await response.json();
    console.log('Fetched user data:', data);
    return data;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

export const uploadProfilePicture = async (formData) => {
  try {
    const response = await api.post('/api/user/profile-picture', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading profile picture:', error);
    throw error;
  }
};

// Add this new function at the end of the file
export const fetchAvatarUrl = async () => {
  try {
    const response = await api.get('/api/user/avatar-url');
    return response.data.signedUrl;
  } catch (error) {
    console.error('Error fetching avatar URL:', error);
    throw error;
  }
};

export const refreshAvatarUrl = async () => {
  try {
    const newSignedUrl = await fetchAvatarUrl();
    return newSignedUrl;
  } catch (error) {
    console.error('Error refreshing avatar URL:', error);
    throw error;
  }
};

// Add these new functions for managing default personas and audiences
export const createDefaultPersonas = async () => {
  try {
    const response = await api.post('/api/personas/defaults/create');
    return response.data;
  } catch (error) {
    console.error('Error creating default personas:', error);
    throw error;
  }
};

export const restoreDefaultPersonas = async () => {
  try {
    const response = await api.post('/api/personas/defaults/restore');
    return response.data;
  } catch (error) {
    console.error('Error restoring default personas:', error);
    throw error;
  }
};

export const getDefaultPersonaNames = async () => {
  try {
    const response = await api.get('/api/personas/defaults/names');
    return response.data;
  } catch (error) {
    console.error('Error getting default persona names:', error);
    throw error;
  }
};

export const createDefaultTargetAudiences = async () => {
  try {
    const response = await api.post('/api/target-audiences/defaults/create');
    return response.data;
  } catch (error) {
    console.error('Error creating default target audiences:', error);
    throw error;
  }
};

export const restoreDefaultTargetAudiences = async () => {
  try {
    const response = await api.post('/api/target-audiences/defaults/restore');
    return response.data;
  } catch (error) {
    console.error('Error restoring default target audiences:', error);
    throw error;
  }
};

export const getDefaultTargetAudienceNames = async () => {
  try {
    const response = await api.get('/api/target-audiences/defaults/names');
    return response.data;
  } catch (error) {
    console.error('Error getting default target audience names:', error);
    throw error;
  }
};