import React from 'react';
import {
  DocumentTextIcon,
  DocumentIcon,
  CodeBracketIcon,
  TableCellsIcon,
  DocumentChartBarIcon,
  PresentationChartBarIcon,
} from '@heroicons/react/24/outline';

const FileTypeIcon = ({ fileType, className }) => {
  const getIconByFileType = (type) => {
    // Text files
    if (type.includes('text/plain')) return DocumentTextIcon;
    if (type.includes('text/markdown')) return DocumentTextIcon;
    
    // Document files
    if (type.includes('application/pdf')) return DocumentIcon;
    if (type.includes('application/msword') || 
        type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
      return DocumentTextIcon;
    }
    
    // Spreadsheet files
    if (type.includes('application/vnd.ms-excel') || 
        type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
        type.includes('text/csv')) {
      return TableCellsIcon;
    }
    
    // Code files
    if (type.includes('text/javascript') || 
        type.includes('text/css') ||
        type.includes('text/html') ||
        type.includes('application/json') ||
        type.includes('text/xml')) {
      return CodeBracketIcon;
    }
    
    // Presentation files
    if (type.includes('application/vnd.ms-powerpoint') ||
        type.includes('application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
      return PresentationChartBarIcon;
    }
    
    // Default icon for unknown types
    return DocumentIcon;
  };

  const Icon = getIconByFileType(fileType);
  return <Icon className={className} />;
};

export default FileTypeIcon; 