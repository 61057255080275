import { motion } from 'framer-motion';

const LoadingSpinner = ({ size = 16, color = 'currentColor', className = '' }) => {
  return (
    <motion.div
      className={`relative ${className}`}
      style={{ width: size, height: size }}
    >
      {/* Outer spinning ring */}
      <motion.div
        className="absolute inset-0"
        style={{
          border: `2px solid ${color}`,
          borderRadius: '50%',
          opacity: 0.3
        }}
      />
      
      {/* Inner spinning element */}
      <motion.div
        className="absolute inset-0"
        style={{
          border: `2px solid transparent`,
          borderTopColor: color,
          borderRightColor: color,
          borderRadius: '50%'
        }}
        animate={{ 
          rotate: 360 
        }}
        transition={{
          duration: 0.8,
          ease: "linear",
          repeat: Infinity
        }}
      />
    </motion.div>
  );
};

export default LoadingSpinner;