import React, { useState, useRef, useEffect } from 'react';
import { UserGroupIcon, ChevronDownIcon, PlusIcon, PencilIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import { createPortal } from 'react-dom';
import { handleAvatarUrl } from '../../utils/avatarUtils';
import api from '../../api';
import { useTheme } from '../../context/ThemeContext';
import { useNavigate } from 'react-router-dom';

const AudienceForContentStrategy = ({ onSelectAudience, selectedAudience }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [audiences, setAudiences] = useState([]);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const buttonRef = useRef(null);
  const { isDarkMode } = useTheme();

  useEffect(() => {
    const fetchAudiences = async () => {
      try {
        const response = await api.get('/api/target-audiences');
        setAudiences(response.data);
      } catch (error) {
        console.error('Error fetching audiences:', error);
      }
    };

    fetchAudiences();
  }, []);

  useEffect(() => {
    const loadAvatar = async () => {
      if (selectedAudience?._id) {
        try {
          const url = await handleAvatarUrl(selectedAudience._id, 'audience');
          if (url) {
            setAvatarUrl(url);
          }
        } catch (error) {
          console.error('Error loading audience avatar:', error);
          // Attempt to reload after a short delay if failed
          setTimeout(async () => {
            const retryUrl = await handleAvatarUrl(selectedAudience._id, 'audience', true);
            if (retryUrl) setAvatarUrl(retryUrl);
          }, 2000);
        }
      }
    };
    
    loadAvatar();
  }, [selectedAudience]);

  useEffect(() => {
    const updatePosition = () => {
      if (buttonRef.current && isOpen) {
        const rect = buttonRef.current.getBoundingClientRect();
        setDropdownPosition({
          top: rect.bottom + window.scrollY + 5,
          left: rect.left + window.scrollX,
          width: rect.width,
        });
      }
    };

    updatePosition();
    window.addEventListener('scroll', updatePosition);
    window.addEventListener('resize', updatePosition);

    return () => {
      window.removeEventListener('scroll', updatePosition);
      window.removeEventListener('resize', updatePosition);
    };
  }, [isOpen]);

  const handleAudienceSelect = (audience) => {
    onSelectAudience(audience);
    setIsOpen(false);
  };

  const handleAddNew = () => {
    setIsOpen(false);
    navigate('/target-audiences/create');
  };

  const renderDropdown = () => (
    <div 
      className="fixed inset-0 z-[1000]" 
      onClick={() => setIsOpen(false)}
    >
      <div 
        className="fixed"
        style={{
          top: dropdownPosition.top,
          left: dropdownPosition.left,
          width: '300px',
        }}
      >
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          className={`rounded-lg shadow-lg backdrop-blur-sm ${
            isDarkMode 
              ? 'bg-[#151515]/95 border border-[#333333]' 
              : 'bg-white/95 border border-purple-200/50'
          }`}
          onClick={e => e.stopPropagation()}
        >
          <div className="py-3">
            <div className="px-4 mb-2 flex items-center justify-between">
              <div className={`text-xs font-medium ${
                isDarkMode ? 'text-purple-300/70' : 'text-purple-500/70'
              }`}>
                Your audiences
              </div>
              <div className="flex items-center gap-2">
                <div className={`text-[10px] px-1.5 rounded-full ${
                  isDarkMode 
                    ? 'bg-purple-500/10 text-purple-300' 
                    : 'bg-purple-50 text-purple-500'
                }`}>
                  {audiences.length}
                </div>
                {selectedAudience && (
                  <button
                    onClick={() => {
                      onSelectAudience(null);
                      setIsOpen(false);
                    }}
                    className={`text-[10px] px-1.5 rounded-full transition-colors duration-200 ${
                      isDarkMode
                        ? 'text-red-400/70 hover:text-red-300'
                        : 'text-red-500/70 hover:text-red-500'
                    }`}
                  >
                    Clear
                  </button>
                )}
              </div>
            </div>
            <div className="space-y-1 px-2">
              {audiences.map((audience) => (
                <button
                  key={audience._id}
                  onClick={() => handleAudienceSelect(audience)}
                  className={`block w-full text-left px-3 py-2 text-sm rounded-md
                    bg-gradient-to-r transition-all duration-200
                    ${isDarkMode
                      ? 'bg-[#1a1a1a] hover:bg-[#202020] text-slate-200 border-[#333333]'
                      : 'from-slate-50/80 to-purple-50/20 hover:from-slate-100/80 hover:to-purple-100/20 text-slate-700 border-purple-100/50'
                    }
                    border
                  `}
                >
                  <div className="flex items-center">
                    {audience.avatarUrl ? (
                      <img src={audience.avatarUrl} alt="" className="w-5 h-5 rounded-full mr-2" />
                    ) : (
                      <UserGroupIcon className="w-5 h-5 mr-2 opacity-50" />
                    )}
                    <span className="truncate">{audience.targetAudienceName}</span>
                  </div>
                </button>
              ))}
            </div>
          </div>

          {/* Action buttons */}
          <div className={`px-4 py-3 border-t ${
            isDarkMode ? 'border-purple-500/20' : 'border-purple-200/20'
          }`}>
            <button
              onClick={handleAddNew}
              className={`w-full flex items-center justify-center px-3 py-2 rounded-md text-xs
                ${isDarkMode
                  ? 'bg-[#1a1a1a] text-slate-200 hover:bg-[#202020] border border-[#333333]'
                  : 'bg-blue-500 text-white hover:bg-blue-600'
                }
              `}
            >
              <PlusIcon className="w-4 h-4 mr-1.5" />
              Create New Audience
            </button>
          </div>
        </motion.div>
      </div>
    </div>
  );

  // Add error handling for avatar display
  const handleAvatarError = async (audienceId) => {
    try {
      const newUrl = await handleAvatarUrl(audienceId, 'audience', true);
      if (newUrl) setAvatarUrl(newUrl);
    } catch (error) {
      console.error('Error refreshing avatar:', error);
    }
  };

  return (
    <>
      {/* Main button */}
      <button
        ref={buttonRef}
        onClick={() => setIsOpen(!isOpen)}
        className={`inline-flex items-center px-3 py-1.5 text-sm font-medium rounded-md min-w-[140px] 
          border transition-all duration-200
          ${isDarkMode
            ? 'bg-[#1a1a1a] text-slate-200 hover:bg-[#202020] border-[#333333]'
            : 'bg-gradient-to-r from-slate-50 to-purple-50/30 text-slate-700 border-purple-100 hover:border-purple-200'
          }
        `}
      >
        {selectedAudience ? (
          <>
            {avatarUrl ? (
              <img
                src={avatarUrl}
                alt={selectedAudience.targetAudienceName}
                className="w-5 h-5 rounded-full mr-2"
              />
            ) : (
              <UserGroupIcon className="w-5 h-5 mr-2" />
            )}
            {selectedAudience.targetAudienceName}
          </>
        ) : (
          'Select Audience'
        )}
        <ChevronDownIcon className="w-4 h-4 ml-2" />
      </button>

      {/* Render dropdown */}
      {isOpen && createPortal(renderDropdown(), document.body)}
    </>
  );
};

export default AudienceForContentStrategy; 