import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './CustomQuill.css';
import { ArrowLeftIcon, ClipboardIcon, CheckIcon } from '@heroicons/react/24/outline';
import '../scrollbar.css';
import IdeasTagMenu from './IdeasTagMenu';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchIdea, updateIdea } from '../../api';
import { motion } from 'framer-motion';
import { socket } from '../../socket';
import { useRecentActivities } from '../../context/RecentActivitiesContext';

const FullScreenNote = ({ isDarkMode, onAddTag, onRemoveTag, allTags }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [note, setNote] = useState(null);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [lastEdited, setLastEdited] = useState(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [copyButtonText, setCopyButtonText] = useState('Copy');
  const quillRef = useRef(null);
  const toolbarRef = useRef(null);
  const titleRef = useRef(null);
  const [showToolbar, setShowToolbar] = useState(false);
  const [toolbarPosition, setToolbarPosition] = useState({ top: 0, left: 0 });
  const [isCopied, setIsCopied] = useState(false);
  const [isTagMenuOpen, setIsTagMenuOpen] = useState(false);
  const tagMenuRef = useRef(null);
  const [tags, setTags] = useState([]);
  const [tagMenuPosition, setTagMenuPosition] = useState({ top: 0, left: 0 });
  const tagButtonRef = useRef(null);
  const [saveStatus, setSaveStatus] = useState('saved'); // 'saved' | 'saving' | 'error'
  const [statusMessage, setStatusMessage] = useState('');
  const [showStatus, setShowStatus] = useState(false);
  const { refreshActivities } = useRecentActivities();

  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const saveChangesDebounced = useCallback(
    debounce(async (newTitle, newContent) => {
      setSaveStatus('saving');
      
      try {
        const updatedNote = await updateIdea(note?._id, { 
          title: newTitle, 
          content: newContent, 
          lastEdited: new Date()
        });
        
        setNote(updatedNote);
        setLastEdited(new Date());
        setSaveStatus('saved');
        
        // Refresh activities after successful update
        await refreshActivities();
      } catch (error) {
        console.error('Error saving note:', error);
        setSaveStatus('error');
        setTimeout(() => {
          setSaveStatus('saved');
        }, 5000);
      }
    }, 500),
    [note?._id, refreshActivities]
  );

  useEffect(() => {
    let mounted = true;
    let retryInterval;

    const loadNote = async () => {
      try {
        setIsLoading(true);
        setError(null);
        
        const fetchNote = async () => {
          try {
            const fetchedNote = await fetchIdea(id);
            if (mounted && fetchedNote) {
              setNote(fetchedNote);
              setTitle(fetchedNote.title || '');
              setContent(fetchedNote.content === 'Start typing...' ? '' : fetchedNote.content);
              setTags(fetchedNote.tags || []);
              setLastEdited(fetchedNote.lastEdited || new Date());
              setIsLoading(false);
              return true;
            }
            return false;
          } catch (err) {
            return false;
          }
        };

        // Try immediately
        if (await fetchNote()) return;

        // If failed, retry every 500ms for up to 5 seconds
        let attempts = 0;
        retryInterval = setInterval(async () => {
          attempts++;
          if (await fetchNote() || attempts >= 10) {
            clearInterval(retryInterval);
            if (attempts >= 10 && mounted) {
              setError('Failed to load note after multiple attempts');
              setIsLoading(false);
            }
          }
        }, 500);

      } catch (error) {
        if (mounted) {
          console.error('Error loading note:', error);
          setError(error.message || 'Failed to load note');
          setIsLoading(false);
        }
      }
    };
    
    if (id) {
      loadNote();
    }

    return () => {
      mounted = false;
      if (retryInterval) clearInterval(retryInterval);
    };
  }, [id]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tagMenuRef.current && !tagMenuRef.current.contains(event.target)) {
        setIsTagMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (isLoading) {
    return (
      <motion.div 
        initial={{ opacity: 0, scale: 0.98 }}
        animate={{ opacity: 1, scale: 1 }}
        className={`flex items-center justify-center h-full ${
          isDarkMode ? 'text-slate-200' : 'text-gray-800'
        }`}
      >
        <div className="flex flex-col items-center gap-4">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-current"></div>
          <span className="text-sm">Loading note...</span>
        </div>
      </motion.div>
    );
  }

  if (error) {
    return (
      <div className={`flex flex-col items-center justify-center h-full ${isDarkMode ? 'text-slate-200' : 'text-gray-800'}`}>
        <div className="text-red-500 mb-4">Error: {error}</div>
        <button
          onClick={() => navigate('/ideas')}
          className={`px-4 py-2 rounded-md ${
            isDarkMode 
              ? 'bg-slate-700 hover:bg-slate-600 text-slate-200' 
              : 'bg-gray-100 hover:bg-gray-200 text-gray-700'
          }`}
        >
          Back to Ideas
        </button>
      </div>
    );
  }

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    saveChanges(e.target.value, content);
  };

  const handleContentChange = (value) => {
    setContent(value);
    saveChanges(title, value);
  };

  const saveChanges = (newTitle, newContent) => {
    saveChangesDebounced(newTitle, newContent);
  };

  const getWordCount = (text) => {
    return text.replace(/<[^>]*>/g, '').trim().split(/\s+/).length;
  };

  const getCharacterCount = (text) => {
    return text.replace(/<[^>]*>/g, '').length;
  };

  const copyToClipboard = () => {
    if (quillRef.current) {
      const text = quillRef.current.getEditor().getText();
      navigator.clipboard.writeText(text).then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      });
    }
  };

  const handleClose = () => {
    const updatedNote = {
      _id: note._id,
      title: title,
      content: content,
      lastEdited: lastEdited
    };
    navigate('/ideas');
  };

  const modules = {
    toolbar: false
  };

  const formats = [
    'bold', 'italic', 'underline', 'strike',
    'blockquote', 'code-block',
    'list', 'bullet', 'indent',
    'script',
    'link'
  ];

  const textColor = isDarkMode ? 'text-white' : 'text-gray-900';
  const placeholderColor = isDarkMode ? 'text-slate-500' : 'text-gray-400';
  const borderColor = isDarkMode ? 'border-slate-700' : 'border-gray-200';

  const handleAddTag = (newTag) => {
    if (newTag && !tags.includes(newTag)) {
      const updatedTags = [...tags, newTag];
      setTags(updatedTags);
      onAddTag(newTag);
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    const updatedTags = tags.filter(tag => tag !== tagToRemove);
    setTags(updatedTags);
    onRemoveTag(tagToRemove);
  };

  const handleTagButtonClick = (event) => {
    event.preventDefault();
    const buttonRect = tagButtonRef.current.getBoundingClientRect();
    setTagMenuPosition({
      top: buttonRect.bottom + window.scrollY,
      left: buttonRect.left + window.scrollX,
    });
    setIsTagMenuOpen(!isTagMenuOpen);
  };

  return (
    <div 
      className={`${
        isDarkMode ? 'bg-[#121212] dark-mode' : 'bg-white'
      } flex flex-col h-full w-full overflow-hidden relative`}
    >
      {/* Floating back button */}
      <button
        onClick={handleClose}
        className={`fixed top-4 left-4 z-50 flex items-center px-3 py-2 rounded-md transition-colors duration-200
          ${isDarkMode 
            ? 'bg-[#1a1a1a] text-slate-400 hover:text-slate-200 hover:bg-[#252525]' 
            : 'bg-white text-gray-500 hover:text-gray-700 hover:bg-gray-50 shadow-sm'
          }`}
      >
        <ArrowLeftIcon className="w-4 h-4" />
      </button>

      {/* Status indicator */}
      <div className={`fixed top-4 right-4 z-50 flex items-center gap-2 px-3 py-2 rounded-md transition-colors duration-200
        ${isDarkMode 
          ? 'bg-[#1a1a1a] text-slate-400' 
          : 'bg-white text-gray-500 shadow-sm'
        }`}
      >
        <div className={`w-2 h-2 rounded-full transition-colors duration-300 ${
          saveStatus === 'saving' ? 'bg-yellow-500 animate-pulse' :
          saveStatus === 'error' ? 'bg-red-500' :
          'bg-green-500'
        }`} />
        <span className="text-xs">
          {saveStatus === 'saving' ? 'Saving...' :
           saveStatus === 'error' ? 'Failed to save' :
           'Saved'}
        </span>
      </div>

      {/* Main content area */}
      <div className="flex-grow overflow-y-auto">
        <div className="max-w-3xl mx-auto px-4">
          {/* Title input */}
          <div className="pt-6">
            <input
              ref={titleRef}
              className={`text-xl font-bold w-full focus:outline-none ${
                isDarkMode 
                  ? 'text-slate-200 bg-[#121212]' 
                  : 'text-gray-800 bg-transparent'
              } placeholder:${isDarkMode ? 'text-slate-500' : 'text-gray-400'}`}
              value={title}
              onChange={handleTitleChange}
              placeholder="Untitled"
            />
          </div>
          
          {/* Content editor */}
          <div className="mt-2">
            <ReactQuill
              ref={quillRef}
              theme="snow"
              value={content}
              onChange={handleContentChange}
              modules={modules}
              formats={formats}
              placeholder="Start typing your idea here..."
              className={`custom-quill ${textColor} notion-like-editor min-h-[calc(100vh-300px)]`}
            />
          </div>
        </div>
      </div>

      {/* Bottom bar with word count and copy button */}
      <div className={`${
        isDarkMode ? 'bg-[#151515] border-[#333333]' : 'bg-white border-gray-200'
      } bg-opacity-90 border-t`}>
        <div className="max-w-3xl mx-auto px-4 sm:px-2 py-2 flex justify-between items-center">
          <div className={`text-xs ${isDarkMode ? 'text-slate-400' : 'text-gray-500'} flex items-center gap-4`}>
            <span>{getWordCount(content)} words</span>
            <span>•</span>
            <span>Last edited {new Date(lastEdited).toLocaleString()}</span>
          </div>
          <button
            onClick={copyToClipboard}
            className={`flex items-center text-xs transition-colors duration-200
              ${isDarkMode 
                ? 'text-slate-400 hover:text-slate-200 hover:bg-[#1a1a1a]' 
                : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100'
              } rounded-md px-2 py-1`}
          >
            {isCopied ? (
              <><CheckIcon className="w-3.5 h-3.5 mr-1" /> Copied</>
            ) : (
              <><ClipboardIcon className="w-3.5 h-3.5 mr-1" /> Copy</>
            )}
          </button>
        </div>
      </div>

      {/* Tag menu remains the same */}
      {isTagMenuOpen && (
        <IdeasTagMenu
          ref={tagMenuRef}
          isDarkMode={isDarkMode}
          tags={tags}
          allTags={allTags}
          onAddTag={handleAddTag}
          onRemoveTag={handleRemoveTag}
          position={tagMenuPosition}
        />
      )}
    </div>
  );
};

export default FullScreenNote;
