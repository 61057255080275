import CryptoJS from 'crypto-js';

const STORAGE_KEY = 'li_tokens';
const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY || 'your-fallback-key';

export const TokenStorage = {
  // Save tokens securely
  saveTokens: (tokens) => {
    try {
      const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify(tokens),
        ENCRYPTION_KEY
      ).toString();
      localStorage.setItem(STORAGE_KEY, encryptedData);
      return true;
    } catch (error) {
      console.error('Error saving tokens:', error);
      return false;
    }
  },

  // Get tokens
  getTokens: () => {
    try {
      const encryptedData = localStorage.getItem(STORAGE_KEY);
      if (!encryptedData) return null;

      const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY);
      const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptedData);
    } catch (error) {
      console.error('Error retrieving tokens:', error);
      return null;
    }
  },

  // Clear tokens
  clearTokens: () => {
    try {
      localStorage.removeItem(STORAGE_KEY);
      return true;
    } catch (error) {
      console.error('Error clearing tokens:', error);
      return false;
    }
  }
}; 