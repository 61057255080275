import React, { useState, useRef, useEffect, forwardRef, useCallback } from 'react';
import { useTheme } from '../context/ThemeContext';
import { 
  PlusIcon, 
  XMarkIcon, 
  ChevronRightIcon, 
  ChevronLeftIcon,
  HomeIcon,
  CheckIcon
} from '@heroicons/react/24/outline';
import '../components/scrollbar.css';
import { createCanvas, updateCanvasTitle, getCanvas, invalidateCanvasCache } from '../api';
import { CANVAS_MARGIN } from '../constants'; // Create this constants file if it doesn't exist
import PropTypes from 'prop-types';
import { PencilIcon } from './icons/PencilIcon';
import SavingDots from './ui/SavingDots';
import { useRecentActivities } from '../context/RecentActivitiesContext';

// Add these base style constants at the top of the file
const baseButtonStyles = `
  flex items-center justify-center 
  w-7 h-7 mx-1 my-0.5
  rounded-md transition-all duration-200
  hover:shadow-sm
  transform hover:scale-[1.02]
  border border-transparent
`;

const baseTabStyles = `
  group flex items-center h-7 px-2 my-0.5 ml-1
  rounded-md transition-all duration-200
  border border-transparent
  flex-shrink-0
  whitespace-nowrap
  min-w-0
`;

// Add these new icon components at the top of the file, after the existing imports
const ExpandChatIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="16" 
    height="16" 
    viewBox="0 0 24 24"
    className="current-color"
  >
    <path 
      fill="none" 
      stroke="currentColor" 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      strokeWidth="2" 
      d="M11 12a1 1 0 1 0 2 0a1 1 0 1 0-2 0M4 8V6a2 2 0 0 1 2-2h2M4 16v2a2 2 0 0 0 2 2h2m8-16h2a2 2 0 0 1 2 2v2m-4 12h2a2 2 0 0 0 2-2v-2"
    />
  </svg>
);

const CollapseChatIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="16" 
    height="16" 
    viewBox="0 0 24 24"
    className="current-color"
  >
    <path 
      fill="currentColor" 
      fillRule="evenodd" 
      d="M18.028 9.964a.75.75 0 0 0-.75-.75h-2.492V6.722a.75.75 0 0 0-1.5 0v3.242c0 .415.335.75.75.75h3.242a.75.75 0 0 0 .75-.75m-3.992 8.064a.75.75 0 0 0 .75-.75v-2.493h2.492a.75.75 0 0 0 0-1.5h-3.242a.75.75 0 0 0-.75.75v3.243c0 .414.335.75.75.75m-4.072 0a.75.75 0 0 1-.75-.75v-2.493H6.722a.75.75 0 0 1 0-1.5h3.242a.75.75 0 0 1 .75.75v3.243a.75.75 0 0 1-.75.75M5.972 9.964a.75.75 0 0 1 .75-.75h2.492V6.722a.75.75 0 0 1 1.5 0v3.242a.75.75 0 0 1-.75.75H6.722a.75.75 0 0 1-.75-.75" 
      clipRule="evenodd"
    />
  </svg>
);

// Update the Tab component styles
const Tab = ({ tab, isActive, onSelect, onRemove, onTitleChange, isDarkMode }) => {
  const [isEditing, setIsEditing] = useState(tab.isNew === true);
  const [localTitle, setLocalTitle] = useState(tab.title);
  const [isHovered, setIsHovered] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const inputRef = useRef(null);
  const tabRef = useRef(null); // Add this ref for the tab container

  // Focus the input when the tab is new
  useEffect(() => {
    if (tab.isNew && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [tab.isNew]);

  // Add click outside listener
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isEditing && 
          tabRef.current && 
          !tabRef.current.contains(event.target)) {
        handleTitleSubmit();
      }
    };

    if (isEditing) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditing]); // Only re-run if isEditing changes

  // Update local title when tab title changes
  useEffect(() => {
    setLocalTitle(tab.title);
  }, [tab.title]);

  const handleTitleSubmit = async () => {
    try {
      const newTitle = localTitle.trim() === '' ? 'Untitled Campaign' : localTitle.trim();
      
      setIsSaving(true); // Show saving status
      
      // Call the parent's onTitleChange handler
      await onTitleChange(tab.id, newTitle);
      
      setIsEditing(false);
      setLocalTitle(newTitle); // Update local state
    } catch (error) {
      console.error('Error updating canvas title:', error);
      // Revert to the original title on error
      setLocalTitle(tab.title);
      setIsEditing(false);
    } finally {
      // Hide saving status after a brief delay
      setTimeout(() => {
        setIsSaving(false);
      }, 800);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleTitleSubmit();
    } else if (e.key === 'Escape') {
      setLocalTitle(tab.title);
      setIsEditing(false);
    }
  };

  return (
    <div
      ref={tabRef}
      key={tab.id}
      className={`
        ${baseTabStyles}
        ${isActive
          ? isDarkMode
            ? 'bg-[#1e1e1e] text-slate-200'
            : 'bg-slate-100/70 text-slate-900'
          : isDarkMode
          ? 'text-slate-400 hover:bg-[#1a1a1a]'
          : 'text-slate-600 hover:bg-slate-100/50'
        }
        cursor-pointer transform hover:scale-[1.01] transition-transform
        max-w-[200px]
        flex-shrink-0
      `}
      style={{
        minWidth: 0,
      }}
      onClick={(e) => {
        if (!isEditing) {
          onSelect(tab.id);
        }
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isEditing ? (
        <div className="flex items-center w-full">
          <input
            ref={inputRef}
            type="text"
            value={localTitle}
            onChange={(e) => setLocalTitle(e.target.value)}
            onBlur={handleTitleSubmit}
            onKeyDown={handleKeyPress}
            className={`
              flex-1 bg-transparent border-none outline-none text-xs mr-1
              focus:ring-0 focus:outline-none
              ${isDarkMode ? 'placeholder-slate-500' : 'placeholder-slate-400'}
            `}
            onClick={(e) => e.stopPropagation()}
          />
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleTitleSubmit();
            }}
            className="w-3.5 h-3.5 flex-shrink-0 text-green-500 hover:text-green-400"
          >
            <CheckIcon className="w-3.5 h-3.5" />
          </button>
        </div>
      ) : (
        <div className="flex items-center w-full gap-1.5">
          <PencilIcon 
            className={`
              w-3 h-3
              ${isActive 
                ? isDarkMode 
                  ? 'text-slate-400' 
                  : 'text-slate-500'
                : 'text-slate-400'
              }
              opacity-50 group-hover:opacity-100
              transition-opacity duration-200
            `} 
          />
          <span
            className="flex-grow text-xs font-medium truncate max-w-[150px]"
            onDoubleClick={() => setIsEditing(true)}
          >
            {tab.title}
          </span>
          {isSaving && <SavingDots isDarkMode={isDarkMode} />}
          <button
            className={`
              w-3.5 h-3.5 flex-shrink-0 
              transition-all duration-200
              ${isActive || isHovered ? 'opacity-100' : 'opacity-0'}
              text-slate-400 hover:text-red-500
              dark:text-slate-500 dark:hover:text-red-400
              rounded-sm
            `}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onRemove(tab.id);
            }}
          >
            <XMarkIcon className="w-3.5 h-3.5" />
          </button>
        </div>
      )}
    </div>
  );
};

// Add display name for the forwarded ref component
Tab.displayName = 'Tab';

const CanvasTopBar = forwardRef(({ 
  tabs, 
  activeTabId, 
  onTabChange, 
  onAddTab, 
  onRemoveTab, 
  onTabTitleChange,
  onHomeClick,
  isHomeView,
  setShowWelcome,
  setActiveTabId,
  setTabsState,
  addNotification,
  isChatCollapsed,    // Add these
  onToggleChat,       // two props
  setTabs,
  setPosts,           // Add these
  setCanvasTitle,     // two props
  tabsState,
  calculateLayout,
  isMobile,
}, ref) => {
  const { isDarkMode } = useTheme();
  const tabsContainerRef = useRef(null);
  const [isHovering, setIsHovering] = useState(false);
  const [showLeftScroll, setShowLeftScroll] = useState(false);
  const [showRightScroll, setShowRightScroll] = useState(false);
  const { refreshActivities } = useRecentActivities();

  const checkForScrollButtons = useCallback(() => {
    if (tabsContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = tabsContainerRef.current;
      setShowLeftScroll(scrollLeft > 0);
      setShowRightScroll(scrollLeft + clientWidth < scrollWidth - 1); // -1 for rounding errors
    }
  }, []);

  useEffect(() => {
    checkForScrollButtons();
    
    // Add resize observer
    const resizeObserver = new ResizeObserver(() => {
      checkForScrollButtons();
    });

    if (tabsContainerRef.current) {
      resizeObserver.observe(tabsContainerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, [tabs, checkForScrollButtons]);

  const handleScroll = () => {
    checkForScrollButtons();
  };

  const handleWheel = useCallback((e) => {
    if (tabsContainerRef.current) {
      tabsContainerRef.current.scrollLeft += e.deltaY;
      checkForScrollButtons();
    }
  }, [checkForScrollButtons]);

  const scrollTabs = (direction) => {
    if (tabsContainerRef.current) {
      const container = tabsContainerRef.current;
      const scrollAmount = 200; // Adjust this value as needed
      
      const targetScroll = direction === 'left'
        ? container.scrollLeft - scrollAmount
        : container.scrollLeft + scrollAmount;

      container.scrollTo({
        left: targetScroll,
        behavior: 'smooth'
      });
    }
  };

  const handleMouseEvent = (e) => {
    e.stopPropagation();
  };

  const handleAddTab = async () => {
    console.log('Initiating canvas creation from TopBar');
    try {
      const newCanvas = await createCanvas('Untitled Campaign');
      console.log('Canvas created successfully:', newCanvas);
      
      const newTab = {
        id: newCanvas._id,
        title: newCanvas.title
      };
      
      // Update tabs state and localStorage
      setTabs(prevTabs => {
        const updatedTabs = [...prevTabs, newTab];
        localStorage.setItem('canvasTabs', JSON.stringify(updatedTabs));
        return updatedTabs;
      });
      
      setActiveTabId(newCanvas._id);
      setShowWelcome(false);
      setPosts([]);
      
      setTabsState(prev => ({
        ...prev,
        [newCanvas._id]: {
          posts: [],
          title: newCanvas.title,
          canvasPosition: { x: CANVAS_MARGIN, y: CANVAS_MARGIN },
          zoom: 1
        }
      }));
      
      localStorage.setItem('lastActiveTabId', newCanvas._id);
      localStorage.setItem('canvasLastView', 'canvas');
      
      // Refresh activities after canvas creation
      await refreshActivities();
      
      console.log('New canvas tab added and activated:', newTab);
    } catch (error) {
      console.error('Error in handleAddTab:', error);
      addNotification('Failed to create new canvas', 'error');
    }
  };

  const handleTabChange = useCallback((tabId) => {
    if (tabId === activeTabId) return;
    
    console.log('Tab change requested:', tabId);
    setShowWelcome(false);
    setActiveTabId(tabId);
    
    // Persist the state
    localStorage.setItem('lastActiveTabId', tabId);
    localStorage.setItem('canvasLastView', 'canvas');
    
    const loadCanvasData = async () => {
      try {
        console.log('Loading canvas data for tab:', tabId);
        const canvas = await getCanvas(tabId);
        
        setTabsState(prev => ({
          ...prev,
          [tabId]: {
            posts: canvas.posts || [],
            title: canvas.title,
            canvasPosition: canvas.canvasPosition || { x: CANVAS_MARGIN, y: CANVAS_MARGIN },
            zoom: canvas.zoom || 1
          }
        }));

        if (canvas.posts?.length > 0) {
          setPosts(calculateLayout(canvas.posts));
        } else {
          setPosts([]);
        }
      } catch (error) {
        console.error('Error loading canvas data:', error);
        addNotification('Failed to load canvas data', 'error');
      }
    };

    loadCanvasData();
  }, [activeTabId, calculateLayout, addNotification, setShowWelcome, setActiveTabId, setPosts]);

  // Update the home button click handler
  const handleHomeClick = (e) => {
    e.stopPropagation();
    setActiveTabId(null); // Clear active tab
    setShowWelcome(true); // Show welcome screen
    localStorage.setItem('canvasLastView', 'welcome');
    localStorage.removeItem('lastActiveTabId');
    onHomeClick(); // Call the parent handler
  };

  // Update the handleRemoveTab function to handle tab switching
  const handleRemoveTab = (tabId) => {
    // Find the index of the tab being removed
    const tabIndex = tabs.findIndex(tab => tab.id === tabId);
    
    // Create updated tabs array
    const updatedTabs = tabs.filter(tab => tab.id !== tabId);
    
    // Update tabs state and localStorage
    setTabs(updatedTabs);
    localStorage.setItem('canvasTabs', JSON.stringify(updatedTabs));
    
    setTabsState(prev => {
      const { [tabId]: _, ...rest } = prev;
      return rest;
    });

    // If there are no remaining tabs, show welcome screen
    if (updatedTabs.length === 0) {
      setActiveTabId(null);
      setPosts([]);
      setCanvasTitle('');
      setShowWelcome(true);
      localStorage.setItem('canvasLastView', 'welcome');
      localStorage.removeItem('lastActiveTabId');
      return;
    }

    // If we're removing the active tab, switch to another tab
    if (activeTabId === tabId) {
      let newActiveTabId;
      
      // If there's a tab after the current one, switch to it
      if (tabIndex < updatedTabs.length) {
        newActiveTabId = updatedTabs[tabIndex].id;
      } 
      // Otherwise, switch to the last tab
      else {
        newActiveTabId = updatedTabs[updatedTabs.length - 1].id;
      }
      
      // Update active tab
      setActiveTabId(newActiveTabId);
      handleTabChange(newActiveTabId);
      localStorage.setItem('lastActiveTabId', newActiveTabId);
    }
  };

  // Update the useEffect that listens for canvas deletion events
  useEffect(() => {
    const handleCanvasDeleted = async (event) => {
      const deletedCanvasId = event.detail.canvasId;
      console.log('Canvas deleted event received:', deletedCanvasId);
      
      // Find the index of the deleted tab
      const tabIndex = tabs.findIndex(tab => tab.id === deletedCanvasId);
      
      // Remove the tab
      const updatedTabs = tabs.filter(tab => tab.id !== deletedCanvasId);
      
      // Update tabs state and localStorage
      setTabs(updatedTabs);
      localStorage.setItem('canvasTabs', JSON.stringify(updatedTabs));
      
      // Clear tab state
      setTabsState(prev => {
        const { [deletedCanvasId]: _, ...rest } = prev;
        return rest;
      });
      
      // If there are no remaining tabs, show welcome screen
      if (updatedTabs.length === 0) {
        setShowWelcome(true);
        setActiveTabId(null);
        setPosts([]);
        setCanvasTitle('');
        localStorage.setItem('canvasLastView', 'welcome');
        localStorage.removeItem('lastActiveTabId');
      } 
      // If the deleted canvas was active, switch to another tab
      else if (activeTabId === deletedCanvasId) {
        // Try to select the next tab, or the last tab if we're at the end
        const newActiveTabId = tabIndex < updatedTabs.length 
          ? updatedTabs[tabIndex].id 
          : updatedTabs[updatedTabs.length - 1].id;
        
        setActiveTabId(newActiveTabId);
        handleTabChange(newActiveTabId);
        localStorage.setItem('lastActiveTabId', newActiveTabId);
        localStorage.setItem('canvasLastView', 'canvas');
      }
      
      // Refresh activities after canvas deletion
      await refreshActivities();
    };

    window.addEventListener('canvasDeleted', handleCanvasDeleted);
    
    return () => {
      window.removeEventListener('canvasDeleted', handleCanvasDeleted);
    };
  }, [activeTabId, tabs, setShowWelcome, setActiveTabId, handleTabChange, refreshActivities, setTabsState, setPosts, setCanvasTitle]);

  const handleTabTitleChange = async (tabId, newTitle) => {
    try {
      await updateCanvasTitle(tabId, newTitle);
      
      setTabs(prevTabs => {
        const updatedTabs = prevTabs.map(tab => 
          tab.id === tabId ? { 
            id: tab.id, 
            title: newTitle 
            // Explicitly omit the isNew property
          } : tab
        );
        localStorage.setItem('canvasTabs', JSON.stringify(updatedTabs));
        return updatedTabs;
      });
      
      setTabsState(prev => ({
        ...prev,
        [tabId]: {
          ...prev[tabId],
          title: newTitle
        }
      }));
      
      if (tabId === activeTabId) {
        setCanvasTitle(newTitle);
      }
      
      // Refresh activities after title update
      await refreshActivities();
      
      addNotification('Canvas title updated successfully', 'success');
    } catch (error) {
      console.error('Error updating canvas title:', error);
      addNotification('Failed to update canvas title', 'error');
      throw error;
    }
  };

  return (
    <div
      ref={ref}
      className={`
        h-10 flex-shrink-0 flex items-center rounded-t-lg
        ${isDarkMode 
          ? 'bg-[#121212]/95 border-[#333333]' 
          : 'bg-white/90 border-slate-200/50'}
        border-b border-opacity-50 relative z-[100]
        backdrop-blur-sm
        w-full
      `}
      style={{
        minWidth: 0,
      }}
    >
      {/* Add Mobile Back Button */}
      {isMobile && (
        <button
          onClick={onToggleChat}
          className={`
            flex items-center px-1.5 py-0.5 ml-0.5
            rounded-md transition-all duration-200
            ${isDarkMode 
              ? 'text-slate-300 hover:bg-[#2A2A2A]' 
              : 'text-slate-600 hover:bg-gray-100'}
          `}
          aria-label="Back to Chat"
        >
          <ChevronLeftIcon className="w-4 h-4" />
          <span className="text-xs">Chat</span>
        </button>
      )}

      {/* Home Button - Update its container to handle mobile spacing */}
      <div className={`flex-shrink-0 ${isMobile ? 'pl-0.5' : 'pl-2'}`}>
        <button
          onClick={handleHomeClick}
          className={`
            ${baseButtonStyles}
            ${isHomeView
              ? isDarkMode
                ? 'text-slate-200'
                : 'text-slate-900'
              : isDarkMode
              ? 'text-slate-400 hover:text-slate-200'
              : 'text-slate-600 hover:text-slate-900'
            }
            ${isMobile ? 'mx-0.5' : 'mx-1'}
          `}
        >
          <HomeIcon className="w-4 h-4" />
        </button>
      </div>

      {/* Tabs Container with Scroll Buttons */}
      <div className={`flex-1 flex items-stretch overflow-hidden relative min-w-0 ${isMobile ? 'mx-1' : 'mx-2'}`}>
        {/* Left Scroll Button */}
        {showLeftScroll && (
          <div 
            className={`
              absolute left-0 top-1/2 -translate-y-1/2 z-[102]
              pointer-events-none
              ${isDarkMode ? 'bg-gradient-to-r from-[#121212]/95' : 'bg-gradient-to-r from-white/90'}
              pl-0.5 pr-2 py-1
            `}
          >
            <button
              onClick={() => scrollTabs('left')}
              className={`
                flex items-center justify-center 
                w-5 h-5
                rounded-md transition-all duration-200
                pointer-events-auto
                ${isDarkMode 
                  ? 'text-slate-400 hover:text-slate-200 hover:bg-[#1e1e1e]' 
                  : 'text-slate-500 hover:text-slate-700 hover:bg-slate-100/50'}
              `}
            >
              <ChevronLeftIcon className="w-4 h-4" />
            </button>
          </div>
        )}

        {/* Right Scroll Button */}
        {showRightScroll && (
          <div 
            className={`
              absolute right-0 top-1/2 -translate-y-1/2 z-[102]
              pointer-events-none
              ${isDarkMode ? 'bg-gradient-to-l from-[#121212]/95' : 'bg-gradient-to-l from-white/90'}
              pr-0.5 pl-2 py-1
            `}
          >
            <button
              onClick={() => scrollTabs('right')}
              className={`
                flex items-center justify-center 
                w-5 h-5
                rounded-md transition-all duration-200
                pointer-events-auto
                ${isDarkMode 
                  ? 'text-slate-400 hover:text-slate-200 hover:bg-[#1e1e1e]' 
                  : 'text-slate-500 hover:text-slate-700 hover:bg-slate-100/50'}
              `}
            >
              <ChevronRightIcon className="w-4 h-4" />
            </button>
          </div>
        )}

        {/* Tabs Scrollable Container */}
        <div
          ref={tabsContainerRef}
          className={`
            flex-1 overflow-x-auto scrollbar-hide
            whitespace-nowrap
            min-w-0 relative z-[101]
          `}
          onScroll={handleScroll}
          onWheel={handleWheel}
          style={{
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
          }}
        >
          <div className="flex h-full items-center min-w-0 flex-shrink-0">
            {tabs.map(tab => (
              <Tab
                key={tab.id}
                tab={tab}
                isActive={activeTabId === tab.id}
                onSelect={onTabChange}
                onRemove={handleRemoveTab}
                onTitleChange={handleTabTitleChange}
                isDarkMode={isDarkMode}
              />
            ))}
            {/* Add New Tab Button */}
            <button
              onClick={handleAddTab}
              className={`
                ${baseButtonStyles}
                ${isDarkMode
                  ? 'text-slate-400 hover:bg-[#1e1e1e] hover:border-[#333333]'
                  : 'text-slate-500 hover:bg-slate-100/50 hover:border-slate-200'}
                hover:shadow-md
                flex-shrink-0
              `}
              title="Add new canvas"
            >
              <PlusIcon className="w-4 h-4" />
            </button>
          </div>
        </div>
      </div>

      {/* Chat Toggle Button - with separator and distinct styling */}
      {!isMobile && (
        <div className={`
          flex items-center
          relative z-[102]
          pl-2 pr-2
          before:content-['']
          before:absolute
          before:left-0
          before:top-2
          before:bottom-2
          before:w-[1px]
          ${isDarkMode 
            ? 'before:bg-[#333333]' 
            : 'before:bg-slate-200'
          }
        `}>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onToggleChat();
            }}
            className={`
              flex items-center justify-center 
              w-7 h-7
              rounded-md 
              transition-all duration-200
              border border-transparent
              ${isDarkMode 
                ? 'text-slate-400 hover:bg-[#202020] hover:text-slate-200' 
                : 'text-slate-600 hover:bg-slate-100 hover:text-slate-900'
              }
              ${isChatCollapsed 
                ? isDarkMode
                  ? 'bg-[#1e1e1e]' 
                  : 'bg-slate-50'
                : ''
              }
              hover:shadow-sm
              transform hover:scale-[1.02]
              relative
              isolate
            `}
            title={isChatCollapsed ? "Show Chat Interface" : "Hide Chat Interface"}
          >
            <div className="w-4 h-4 flex items-center justify-center">
              {isChatCollapsed ? <CollapseChatIcon /> : <ExpandChatIcon />}
            </div>
          </button>
        </div>
      )}
    </div>
  );
});

// Add PropTypes validation
CanvasTopBar.propTypes = {
  tabs: PropTypes.array.isRequired,
  activeTabId: PropTypes.string,
  onTabChange: PropTypes.func.isRequired,
  onAddTab: PropTypes.func.isRequired,
  onRemoveTab: PropTypes.func.isRequired,
  onTabTitleChange: PropTypes.func.isRequired,
  onHomeClick: PropTypes.func.isRequired,
  isHomeView: PropTypes.bool.isRequired,
  setShowWelcome: PropTypes.func.isRequired,
  setActiveTabId: PropTypes.func.isRequired,
  setTabsState: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
  isChatCollapsed: PropTypes.bool.isRequired,
  onToggleChat: PropTypes.func.isRequired,
  setTabs: PropTypes.func.isRequired,
  setPosts: PropTypes.func.isRequired,
  setCanvasTitle: PropTypes.func.isRequired,
  tabsState: PropTypes.object,
  calculateLayout: PropTypes.func,
  isMobile: PropTypes.bool.isRequired,
};

// Add default props
CanvasTopBar.defaultProps = {
  tabsState: {},
  calculateLayout: (posts) => posts, // Default implementation that just returns the posts unchanged
};

export default CanvasTopBar;
