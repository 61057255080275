import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '../context/ThemeContext';
import { FolderIcon } from '@heroicons/react/24/outline';

const NewFolderModal = ({ onClose, onCreate }) => {
  const { isDarkMode } = useTheme();
  const [folderName, setFolderName] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (folderName.trim()) {
      onCreate(folderName.trim());
      onClose();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  return (
    <div 
      className={`fixed inset-0 ${
        isDarkMode 
          ? 'bg-black/60' 
          : 'bg-white/60'
      } backdrop-blur-sm flex items-center justify-center z-50`}
      onClick={(e) => {
        if (e.target === e.currentTarget) onClose();
      }}
    >
      <div className={`${
        isDarkMode 
          ? 'bg-[#1c1c1e] border-[#333333]' 
          : 'bg-white border-gray-200 backdrop-blur-xl'
      } rounded-xl border shadow-lg w-80 transform transition-all duration-200 scale-100`}>
        <div className="px-6 pt-4 pb-3">
          <div className="flex items-center space-x-3 mb-4">
            <div className={`p-2 rounded-full ${
              isDarkMode ? 'bg-[#2c2c2e]' : 'bg-gray-100'
            }`}>
              <FolderIcon className={`w-5 h-5 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-500'
              }`} />
            </div>
            <h2 className={`text-base font-medium ${
              isDarkMode ? 'text-slate-200' : 'text-gray-900'
            }`}>
              New Folder
            </h2>
          </div>
          <form onSubmit={handleSubmit}>
            <input
              ref={inputRef}
              type="text"
              value={folderName}
              onChange={(e) => setFolderName(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Untitled Folder"
              className={`w-full px-3 py-2 rounded-lg mb-3 ${
                isDarkMode 
                  ? 'bg-[#2c2c2e] border-[#3c3c3e] text-slate-200 placeholder-slate-400 focus:border-blue-500' 
                  : 'bg-gray-50 border-gray-200 text-gray-900 focus:border-blue-500'
              } border focus:outline-none focus:ring-0 text-sm transition-colors duration-200`}
            />
            <div className="flex justify-end space-x-2">
              <button
                type="button"
                onClick={onClose}
                className={`px-4 py-1.5 rounded-lg text-sm font-medium transition-colors duration-200 ${
                  isDarkMode
                    ? 'bg-[#2c2c2e] text-slate-200 hover:bg-[#3c3c3e]'
                    : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                }`}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={`px-4 py-1.5 rounded-lg text-sm font-medium transition-colors duration-200 ${
                  isDarkMode
                    ? 'bg-blue-500 text-white hover:bg-blue-600'
                    : 'bg-blue-500 text-white hover:bg-blue-600'
                } ${!folderName.trim() ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={!folderName.trim()}
              >
                Create
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewFolderModal;