import React from 'react';
import { useTheme } from '../context/ThemeContext';
import LPHeader from './LPHeader';
import Footer from './Footer';
import '../components/scrollbar.css';

import {
  HeroSection,
  FeaturesSection,
  HowItWorksSection,
  ContextBankSection,
  PricingSection,
  FAQSection,
  CTASection
} from './landing';

const LandingPage = () => {
  const { isDarkMode } = useTheme();

  return (
    <div className={`min-h-screen flex flex-col overflow-x-hidden w-full ${
      isDarkMode 
        ? 'bg-[#121212] text-slate-200' 
        : 'bg-white text-slate-800'
    } dotted-background relative`}>
      <LPHeader />
      <main className={`flex-grow overflow-y-auto overflow-x-hidden w-full chat-scrollbar-overlay relative ${
        isDarkMode ? 'bg-[#121212]/50' : 'bg-white/50'
      }`}>
        <HeroSection />
        <FeaturesSection />
        <HowItWorksSection />
        <ContextBankSection />
        <PricingSection />
        <FAQSection />
        <CTASection />
        <Footer />
      </main>
    </div>
  );
};

export default LandingPage;
