import React, { useState, forwardRef, useRef, useEffect } from 'react';
import { PlusIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { createPortal } from 'react-dom';
import { motion, AnimatePresence } from 'framer-motion';

const TagMenu = forwardRef(({ isDarkMode, tags, allTags, onAddTag, onRemoveTag, anchorEl, onClose }, ref) => {
  const [inputValue, setInputValue] = useState('');
  const menuRef = useRef(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const updatePosition = () => {
      const container = document.querySelector('[data-transcription-screen]');
      if (container) {
        const containerRect = container.getBoundingClientRect();
        const menuWidth = 256;
        const menuHeight = 300;

        const centerX = containerRect.left + (containerRect.width - menuWidth) / 2;
        const centerY = containerRect.top + (containerRect.height - menuHeight) / 2;

        setPosition({
          top: window.scrollY + centerY,
          left: window.scrollX + centerX,
        });
      }
    };

    updatePosition();

    window.addEventListener('resize', updatePosition);
    return () => {
      window.removeEventListener('resize', updatePosition);
    };
  }, [anchorEl]);

  const filteredAllTags = allTags.filter(tag => 
    tag.toLowerCase().includes(inputValue.toLowerCase()) && !tags.includes(tag)
  );

  const handleAddTag = (tagToAdd) => {
    const trimmedTag = tagToAdd.trim();
    if (trimmedTag && !tags.includes(trimmedTag)) {
      onAddTag(trimmedTag);
      setInputValue('');
      onClose();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddTag(inputValue);
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    console.log('Removing tag:', tagToRemove);
    if (onRemoveTag) {
      onRemoveTag(tagToRemove);
    }
  };

  if (!anchorEl) return null;

  return createPortal(
    <AnimatePresence>
      {/* Background Overlay */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black/50 backdrop-blur-sm z-40"
        onClick={onClose}
      />

      {/* Menu */}
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        transition={{ type: "spring", stiffness: 500, damping: 30 }}
        ref={menuRef}
        className={`
          fixed z-50 w-64 rounded-lg overflow-hidden
          shadow-xl border backdrop-blur-sm
          ${isDarkMode 
            ? 'bg-[#1a1a1a]/95 text-slate-200 border-[#333333]' 
            : 'bg-white/95 text-gray-800 border-gray-200'
          }
        `}
        style={{
          top: `${position.top}px`,
          left: `${position.left}px`,
        }}
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={`
          p-3 flex flex-col max-h-[80vh] overflow-hidden
          ${isDarkMode ? 'divide-[#333333]' : 'divide-gray-200'}
        `}>
          {/* Header with close button */}
          <div className="flex items-center justify-between mb-3">
            <h3 className={`
              text-sm font-semibold
              ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}
            `}>
              Manage Tags
            </h3>
            <button
              onClick={onClose}
              className={`
                p-1 rounded-lg transition-colors duration-200
                ${isDarkMode
                  ? 'text-slate-400 hover:bg-[#202020] hover:text-slate-300'
                  : 'text-gray-500 hover:bg-gray-100 hover:text-gray-700'}
              `}
              aria-label="Close menu"
            >
              <XMarkIcon className="w-4 h-4" />
            </button>
          </div>
          
          {/* Search and Add Input */}
          <div className="flex mb-3 relative">
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Search or add new tag"
              className={`
                w-full pl-7 pr-7 py-1.5 text-xs rounded-md
                transition-colors duration-200
                ${isDarkMode 
                  ? 'bg-[#151515] text-slate-200 placeholder-slate-500 border-[#333333]' 
                  : 'bg-gray-100 text-gray-800 placeholder-gray-400'
                }
                focus:outline-none focus:ring-1
                ${isDarkMode
                  ? 'focus:ring-blue-500/50 focus:border-blue-500/50'
                  : 'focus:ring-blue-500 focus:border-blue-500'
                }
                border
              `}
            />
            <MagnifyingGlassIcon 
              className={`
                absolute left-2 top-1/2 transform -translate-y-1/2 w-3 h-3
                ${isDarkMode ? 'text-slate-500' : 'text-gray-400'}
              `}
            />
            <button
              onClick={() => handleAddTag(inputValue)}
              className={`
                absolute right-1.5 top-1/2 transform -translate-y-1/2
                p-0.5 rounded transition-colors duration-200
                ${isDarkMode 
                  ? 'text-blue-400 hover:text-blue-300' 
                  : 'text-blue-600 hover:text-blue-700'
                }
              `}
            >
              <PlusIcon className="w-3 h-3" />
            </button>
          </div>

          {/* Current Tags Section */}
          <div className="mb-3">
            <h4 className={`
              text-xs font-medium mb-2
              ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}
            `}>
              Current Tags
            </h4>
            <div className="flex flex-wrap gap-1.5">
              {tags.map((tag) => (
                <span
                  key={tag}
                  className={`
                    px-2 py-1 rounded-md text-xs flex items-center max-w-full
                    border transition-colors duration-200
                    ${isDarkMode 
                      ? 'bg-[#1a1a1a] text-slate-300 border-[#333333]' 
                      : 'bg-gray-50 text-gray-700 border-gray-200'}
                  `}
                >
                  <span className="truncate">{tag}</span>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveTag(tag);
                    }}
                    className={`
                      ml-1.5 p-0.5 rounded-full flex-shrink-0
                      transition-colors duration-200
                      ${isDarkMode 
                        ? 'hover:bg-[#202020] text-slate-400 hover:text-slate-300' 
                        : 'hover:bg-gray-200 text-gray-400 hover:text-gray-500'}
                    `}
                  >
                    <XMarkIcon className="w-3 h-3" />
                  </button>
                </span>
              ))}
            </div>
          </div>

          {/* Available Tags Section */}
          <div className="flex-1 overflow-hidden">
            <h4 className={`
              text-xs font-medium mb-2
              ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}
            `}>
              Available Tags
            </h4>
            <div className="max-h-32 overflow-y-auto overflow-x-hidden custom-scrollbar">
              <AnimatePresence>
                {filteredAllTags.map((tag, index) => (
                  <motion.button
                    key={tag}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 20 }}
                    transition={{ delay: index * 0.05 }}
                    onClick={() => handleAddTag(tag)}
                    className={`
                      block w-full text-left px-2.5 py-1.5 text-xs rounded-md
                      transition-colors duration-200 mb-1
                      ${isDarkMode
                        ? 'hover:bg-[#202020] text-slate-300'
                        : 'hover:bg-gray-100 text-gray-700'}
                    `}
                  >
                    {tag}
                  </motion.button>
                ))}
              </AnimatePresence>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>,
    document.body
  );
});

TagMenu.isClickInsideMenu = (element, menuRef) => {
  if (!menuRef.current) return false;
  return menuRef.current === element || menuRef.current.contains(element);
};

export default TagMenu;
