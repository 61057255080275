import React, { createContext, useState, useEffect } from 'react';
import { fetchContentStrategies } from '../api';

export const ContentStrategyContext = createContext();

export const ContentStrategyProvider = ({ children }) => {
  const [selectedPersona, setSelectedPersona] = useState(null);
  const [selectedAudience, setSelectedAudience] = useState(null);
  const [savedStrategies, setSavedStrategies] = useState([]);
  const [selectedStrategy, setSelectedStrategy] = useState(null);

  const loadSavedStrategies = async () => {
    try {
      const strategies = await fetchContentStrategies();
      setSavedStrategies(strategies);
    } catch (error) {
      console.error('Error loading saved strategies:', error);
    }
  };

  const selectStrategy = (strategy) => {
    setSelectedStrategy(strategy);
  };

  useEffect(() => {
    loadSavedStrategies();
  }, []);

  return (
    <ContentStrategyContext.Provider 
      value={{
        selectedPersona,
        setSelectedPersona,
        selectedAudience,
        setSelectedAudience,
        savedStrategies,
        selectedStrategy,
        loadSavedStrategies,
        selectStrategy
      }}
    >
      {children}
    </ContentStrategyContext.Provider>
  );
}; 