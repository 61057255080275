import { useState, useCallback, useEffect } from 'react';

const HORIZONTAL_PADDING = 30;
const VERTICAL_PADDING = 20;
const POST_GAP = 40;
const MIN_POST_HEIGHT = 300;

export const useCanvasPostsPosition = (activeDevice, posts, setPosts, postsRef, canvasContainerRef) => {
  const [contentSize, setContentSize] = useState({ width: 0, height: 0 });
  const [canvasViewportSize, setCanvasViewportSize] = useState({ width: 0, height: 0 });

  const getPostWidth = (device) => {
    switch (device) {
      case 'mobile':
        return 409; // Fixed width for mobile
      case 'tablet':
        return 471; // Fixed width for tablet
      case 'computer':
        return 555; // Fixed width for desktop
      default:
        return 555; // Default to desktop width
    }
  };

  const calculateLayout = (posts) => {
    if (!posts.length) return posts;
    
    // Sort posts by order first (smaller order numbers come first)
    const sortedPosts = [...posts].sort((a, b) => (a.order || 0) - (b.order || 0));
    
    let yOffset = 0;
    const spacing = 24; // Spacing between posts
    
    return sortedPosts.map((post) => {
      const width = getPostWidth(activeDevice);
      const height = post.height || MIN_POST_HEIGHT;
      
      const position = {
        x: (window.innerWidth - width) / 2, // Center horizontally
        y: yOffset
      };
      
      yOffset += height + spacing;
      
      return {
        ...post,
        width,
        height,
        position
      };
    });
  };

  const updateContentSize = useCallback(() => {
    if (!posts.length) return;

    // Sort posts by order before calculating size
    const sortedPosts = [...posts].sort((a, b) => (a.order || 0) - (b.order || 0));
    const postWidth = getPostWidth(activeDevice);
    
    // Calculate total height maintaining order
    const totalHeight = sortedPosts.reduce((acc, post) => {
      const actualHeight = postsRef.current[post.id]?.offsetHeight || MIN_POST_HEIGHT;
      return acc + actualHeight + POST_GAP;
    }, VERTICAL_PADDING);

    setContentSize({
      width: Math.max(postWidth + (HORIZONTAL_PADDING * 2), canvasViewportSize.width),
      height: Math.max(totalHeight, canvasViewportSize.height)
    });
  }, [posts, canvasViewportSize, activeDevice, postsRef]);

  useEffect(() => {
    const updateCanvasViewportSize = () => {
      if (canvasContainerRef.current) {
        setCanvasViewportSize({
          width: canvasContainerRef.current.clientWidth,
          height: canvasContainerRef.current.clientHeight
        });
      }
    };

    updateCanvasViewportSize();
    window.addEventListener('resize', updateCanvasViewportSize);
    return () => window.removeEventListener('resize', updateCanvasViewportSize);
  }, [canvasContainerRef]);

  const resetLayout = useCallback(() => {
    setPosts(prevPosts => {
      // Ensure posts maintain their order during reset
      const sortedPosts = [...prevPosts].sort((a, b) => (a.order || 0) - (b.order || 0));
      return calculateLayout(sortedPosts);
    });
  }, [calculateLayout, setPosts]);

  return {
    contentSize,
    calculateLayout,
    getPostWidth,
    updateContentSize,
    setCanvasViewportSize,
    resetLayout
  };
};
