import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { EllipsisVerticalIcon, PencilIcon, ArrowDownTrayIcon, TrashIcon } from '@heroicons/react/24/outline';

const MenuPortal = ({ children, buttonRect, onClose, isDarkMode }) => {
  const menuRef = useRef(null);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0, position: 'bottom' });

  useEffect(() => {
    const updatePosition = () => {
      if (!buttonRect) return;
      
      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;
      const menuHeight = menuRef.current?.offsetHeight || 200;
      const menuWidth = menuRef.current?.offsetWidth || 192; // 12rem = 192px
      
      // Calculate available space in different directions
      const spaceBelow = viewportHeight - buttonRect.bottom;
      const spaceAbove = buttonRect.top;
      const spaceRight = viewportWidth - buttonRect.right;
      
      let top, left, position;
      
      // Determine vertical position
      if (spaceBelow >= menuHeight || spaceBelow >= spaceAbove) {
        top = buttonRect.bottom + 8; // Add 8px gap
        position = 'bottom';
      } else {
        top = buttonRect.top - menuHeight - 8; // Add 8px gap
        position = 'top';
      }
      
      // Determine horizontal position
      if (spaceRight >= menuWidth) {
        left = buttonRect.right - menuWidth;
      } else {
        left = buttonRect.left;
      }
      
      // Ensure menu stays within viewport bounds
      left = Math.max(16, Math.min(left, viewportWidth - menuWidth - 16));
      top = Math.max(16, Math.min(top, viewportHeight - menuHeight - 16));
      
      setMenuPosition({ top, left, position });
    };

    updatePosition();
    window.addEventListener('scroll', updatePosition, true);
    window.addEventListener('resize', updatePosition);

    return () => {
      window.removeEventListener('scroll', updatePosition, true);
      window.removeEventListener('resize', updatePosition);
    };
  }, [buttonRect]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  return createPortal(
    <div
      ref={menuRef}
      className={`
        fixed
        min-w-[12rem] overflow-hidden 
        rounded-md border p-1.5
        shadow-lg
        select-none
        ${isDarkMode 
          ? 'bg-[#121212] border-[#333333]' 
          : 'bg-white border-slate-200'
        }
      `}
      style={{
        top: `${menuPosition.top}px`,
        left: `${menuPosition.left}px`,
        zIndex: 9999,
        filter: 'drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1))',
        transition: 'opacity 0.2s ease-in-out',
      }}
    >
      {children}
    </div>,
    document.body
  );
};

const AudienceOptionsMenu = ({ audience, onEdit, onDelete, onDownload, isDarkMode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);
  const [buttonRect, setButtonRect] = useState(null);

  const toggleMenu = (e) => {
    e.stopPropagation();
    if (!isOpen) {
      const rect = buttonRef.current.getBoundingClientRect();
      setButtonRect(rect);
    }
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (action) => (e) => {
    e.stopPropagation();
    action();
    setIsOpen(false);
  };

  return (
    <>
      <button
        ref={buttonRef}
        onClick={toggleMenu}
        className={`
          rounded-full p-1.5 
          transition-colors duration-200
          select-none
          ${isDarkMode 
            ? 'hover:bg-[#1e1e1e] text-slate-400' 
            : 'hover:bg-gray-100 text-gray-500'
          }
        `}
        title="Audience Options"
      >
        <EllipsisVerticalIcon className="h-4 w-4" />
      </button>

      {isOpen && (
        <MenuPortal
          buttonRect={buttonRect}
          onClose={() => setIsOpen(false)}
          isDarkMode={isDarkMode}
        >
          <button
            onClick={handleOptionClick(() => onEdit(audience._id))}
            className={`
              flex w-full items-center gap-2.5 
              rounded-sm px-4 py-2 text-sm 
              transition-colors
              ${isDarkMode 
                ? 'hover:bg-[#1e1e1e] text-slate-400' 
                : 'hover:bg-slate-100 text-slate-600'
              }
            `}
          >
            <PencilIcon className="h-4 w-4" />
            Edit Audience
          </button>

          <button
            onClick={handleOptionClick(() => onDownload(audience._id))}
            className={`
              flex w-full items-center gap-2.5 
              rounded-sm px-4 py-2 text-sm 
              transition-colors
              ${isDarkMode 
                ? 'hover:bg-[#1e1e1e] text-slate-400' 
                : 'hover:bg-slate-100 text-slate-600'
              }
            `}
          >
            <ArrowDownTrayIcon className="h-4 w-4" />
            Download as PDF
          </button>

          <button
            onClick={handleOptionClick(() => onDelete(audience._id))}
            className={`
              flex w-full items-center gap-2.5 
              rounded-sm px-4 py-2 text-sm 
              transition-colors
              text-red-500 hover:text-red-600
              ${isDarkMode 
                ? 'hover:bg-[#1e1e1e]' 
                : 'hover:bg-slate-100'
              }
            `}
          >
            <TrashIcon className="h-4 w-4" />
            Delete Audience
          </button>
        </MenuPortal>
      )}
    </>
  );
};

export default AudienceOptionsMenu;
