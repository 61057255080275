import React, { useState } from 'react';
import { XMarkIcon, DocumentTextIcon, DocumentIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../../context/ThemeContext';

const PersonaAIFillModal = ({ isOpen, onClose, onSubmit }) => {
  const { isDarkMode } = useTheme();
  const [inputType, setInputType] = useState('text');
  const [input, setInput] = useState('');
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async () => {
    setError('');
    setIsLoading(true);

    try {
      let processedInput = '';

      if (inputType === 'text') {
        processedInput = input;
      } else if (inputType === 'pdf' && file) {
        // File will be handled by the parent component
        processedInput = file;
      }

      await onSubmit(inputType, processedInput);
      onClose();
    } catch (err) {
      setError(err.message || 'An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === 'application/pdf') {
      setFile(selectedFile);
      setError('');
    } else {
      setError('Please select a PDF file');
      setFile(null);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className={`relative w-full max-w-md transform overflow-hidden rounded-xl shadow-xl transition-all
                    ${isDarkMode ? 'bg-[#151515]' : 'bg-white'}`}>
        {/* Header */}
        <div className={`px-6 py-4 border-b ${isDarkMode ? 'border-slate-800' : 'border-gray-200'}`}>
          <div className="flex items-center justify-between">
            <h3 className={`text-lg font-semibold ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>
              Fill Profile with AI
            </h3>
            <button
              onClick={onClose}
              className={`rounded-full p-1 transition-colors duration-200
                      ${isDarkMode 
                        ? 'hover:bg-slate-800 text-slate-400 hover:text-slate-300' 
                        : 'hover:bg-gray-100 text-gray-500 hover:text-gray-700'}`}
            >
              <XMarkIcon className="h-5 w-5" />
            </button>
          </div>
        </div>

        {/* Content */}
        <div className="px-6 py-4">
          <div className="space-y-4">
            {/* Input Type Selection */}
            <div className="flex space-x-4">
              <button
                onClick={() => setInputType('text')}
                className={`flex-1 p-3 rounded-lg border transition-colors duration-200 flex items-center justify-center gap-2
                        ${inputType === 'text'
                          ? isDarkMode
                            ? 'bg-blue-500/20 border-blue-500 text-blue-400'
                            : 'bg-blue-50 border-blue-500 text-blue-700'
                          : isDarkMode
                            ? 'bg-[#1a1a1a] border-[#333333] text-slate-400 hover:bg-[#252525]'
                            : 'bg-white border-gray-200 text-gray-700 hover:bg-gray-50'
                        }`}
              >
                <DocumentTextIcon className="h-5 w-5" />
                <span>Text</span>
              </button>
              <button
                onClick={() => setInputType('pdf')}
                className={`flex-1 p-3 rounded-lg border transition-colors duration-200 flex items-center justify-center gap-2
                        ${inputType === 'pdf'
                          ? isDarkMode
                            ? 'bg-blue-500/20 border-blue-500 text-blue-400'
                            : 'bg-blue-50 border-blue-500 text-blue-700'
                          : isDarkMode
                            ? 'bg-[#1a1a1a] border-[#333333] text-slate-400 hover:bg-[#252525]'
                            : 'bg-white border-gray-200 text-gray-700 hover:bg-gray-50'
                        }`}
              >
                <DocumentIcon className="h-5 w-5" />
                <span>PDF</span>
              </button>
            </div>

            {/* Input Field */}
            {inputType === 'text' && (
              <textarea
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Enter your text here..."
                rows={6}
                className={`w-full px-4 py-3 text-sm rounded-md border transition-all duration-200
                        resize-y min-h-[150px]
                        ${isDarkMode 
                          ? 'bg-[#121212] border-[#333333] text-slate-200 hover:border-2' 
                          : 'bg-white border-gray-200 text-gray-900 hover:border-2'}
                        focus:outline-none focus:border-2 focus:border-indigo-500`}
              />
            )}

            {inputType === 'pdf' && (
              <div className={`border-2 border-dashed rounded-lg p-6 text-center
                           ${isDarkMode 
                             ? 'border-[#333333] bg-[#1a1a1a]' 
                             : 'border-gray-300 bg-gray-50'}`}>
                <input
                  type="file"
                  accept=".pdf"
                  onChange={handleFileChange}
                  className="hidden"
                  id="pdf-upload"
                />
                <label
                  htmlFor="pdf-upload"
                  className="cursor-pointer"
                >
                  <DocumentIcon className={`mx-auto h-12 w-12 mb-3
                                       ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`} />
                  <p className={`text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}`}>
                    {file ? file.name : 'Click to upload PDF'}
                  </p>
                </label>
              </div>
            )}

            {error && (
              <p className="text-red-500 text-sm mt-2">{error}</p>
            )}
          </div>
        </div>

        {/* Footer */}
        <div className={`px-6 py-4 border-t ${isDarkMode ? 'border-slate-800' : 'border-gray-200'}
                      flex justify-end space-x-3`}>
          <button 
            onClick={onClose}
            className={`px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200
                    ${isDarkMode 
                      ? 'bg-slate-800 text-slate-200 hover:bg-slate-700' 
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'}`}
          >
            Cancel
          </button>
          <button 
            onClick={handleSubmit}
            disabled={isLoading || (!input && !file)}
            className={`px-4 py-2 rounded-md text-sm font-medium text-white
                    transition-colors duration-200
                    ${isLoading || (!input && !file)
                      ? 'bg-indigo-400 cursor-not-allowed'
                      : 'bg-indigo-600 hover:bg-indigo-700'}`}
          >
            {isLoading ? 'Processing...' : 'Fill Profile'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PersonaAIFillModal; 