import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import TitleForLinkedInPostMockup from './titleForLinkedInPostMockup';
import CanvasPostContentEditor from './CanvasPostContentEditor';
import PropTypes from 'prop-types';
import { DEVICE_CONFIGS } from '../constants/deviceConfig';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import '../styles/markdown.css';
import Portal from './Portal';

/**
 * Utility function for random number generation
 * @param {number} min - Minimum value
 * @param {number} max - Maximum value
 * @returns {number} Random number between min and max
 */
const getRandomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

/**
 * Helper functions for generating random engagement numbers
 */
const getRandomReactions = () => getRandomNumber(0, 9999);
const getRandomComments = () => getRandomNumber(0, 999);
const getRandomShares = () => getRandomNumber(0, 99);

/**
 * LinkedIn post mockup component that simulates a LinkedIn post interface
 * @component
 * @param {Object} props
 * @param {Object} props.selectedPersona - Selected user persona data
 * @param {Object} props.canvasContent - Content data for the post
 * @param {Function} props.onRemove - Callback when post is removed
 * @param {string} props.title - Post title
 * @param {Function} props.onTitleChange - Callback for title changes
 * @param {Function} props.onContentChange - Callback for content changes
 * @param {boolean} props.isDarkMode - Whether dark mode is enabled
 * @param {boolean} props.isNewest - Whether this is the newest post
 * @param {Function} props.onStartEditing - Callback when editing starts
 * @param {Function} props.onStopEditing - Callback when editing stops
 * @param {boolean} [props.isFocused=false] - Whether the post is focused
 * @param {Function} props.onExpand - Callback when post is expanded
 * @param {boolean} [props.disableDragging=false] - Whether dragging is disabled
 * @param {Function} props.onNavigateNext - Callback to navigate to next post
 * @param {Function} props.onNavigatePrevious - Callback to navigate to previous post
 * @param {number} props.currentIndex - Current index in posts array
 * @param {number} props.totalPosts - Total number of posts
 * @param {boolean} [props.isInCanvasExperience=false] - Whether in canvas experience mode
 * @param {string} [props.focusBorderColor=''] - Color for focus border
 * @param {boolean} props.removeBorderAndPadding - Whether to remove border and padding
 * @param {boolean} props.isEditing - Whether post is being edited
 * @param {Function} props.onSave - Callback when content is saved
 * @param {Function} props.onCancel - Callback when editing is cancelled
 * @param {boolean} [props.hideTitleBar=false] - Whether to hide the title bar
 * @param {boolean} [props.isSimplifiedView=true] - Whether to show simplified view
 * @param {boolean} [props.forceExpanded=false] - Whether to force expanded view
 * @param {boolean} [props.preventCollapse=false] - Whether to prevent collapsing
 * @param {Function} props.onRequestDelete - Callback when delete is requested
 * @param {Function} props.onFocusMode - Callback when focus mode is toggled
 * @param {string} props.activeDevice - Active device type
 * @param {string} props.canvasId - Canvas ID
 * @param {Function} props.onFullScreen - Callback when full screen mode is toggled
 */
const LinkedInPostMockup = React.memo(function LinkedInPostMockup({ 
  selectedPersona, 
  canvasContent, 
  onRemove,
  title,
  onTitleChange,
  onContentChange,
  isDarkMode,
  isNewest,
  onStartEditing,
  onStopEditing,
  isFocused = false,
  onExpand,
  onNavigateNext,
  onNavigatePrevious,
  currentIndex,
  totalPosts,
  isInCanvasExperience = false,
  focusBorderColor = '',
  removeBorderAndPadding,
  isEditing,
  onSave,
  onCancel,
  hideTitleBar = false,
  isSimplifiedView: initialSimplifiedView = true,
  forceExpanded = false,
  preventCollapse = false,
  onRequestDelete,
  onFocusMode,
  activeDevice,
  canvasId,
  onFullScreen,
}) {
  // Move all state declarations to the top
  // eslint-disable-next-line no-unused-vars
  const [isSimplifiedView, setIsSimplifiedView] = useState(!forceExpanded && initialSimplifiedView);
  const [isExpanded, setIsExpanded] = useState(forceExpanded || !initialSimplifiedView);
  const [isInteracting, setIsInteracting] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [isEditingContent, setIsEditingContent] = useState(isEditing);
  const [editedContent, setEditedContent] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  const [prevWidth, setPrevWidth] = useState(null);
  const [prevPosition, setPrevPosition] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  // Add these constants for the engagement numbers
  const reactions = useMemo(() => getRandomReactions(), []);
  const comments = useMemo(() => getRandomComments(), []);
  const shares = useMemo(() => getRandomShares(), []);

  // Add delete handlers inside the component
  const handleDeleteClick = useCallback((e) => {
    if (e) {
      e.stopPropagation();
    }
    setShowDeleteConfirm(true);
  }, []);

  const handleCancelDelete = useCallback((e) => {
    if (e) {
      e.stopPropagation();
    }
    setShowDeleteConfirm(false);
  }, []);

  const handleConfirmDelete = useCallback((e) => {
    if (e) {
      e.stopPropagation();
    }
    if (onRemove) {
      onRemove(canvasContent.id);
    }
    setShowDeleteConfirm(false);
  }, [onRemove, canvasContent?.id]);

  // Refs
  const postRef = useRef(null);
  const contentEditableRef = useRef(null);
  const contentRef = useRef(null);

  // Add this new state for avatar URL
  useEffect(() => {
    if (selectedPersona && selectedPersona.avatarUrl) {
      setAvatarUrl(selectedPersona.avatarUrl);
    } else {
      setAvatarUrl(null);
    }
  }, [selectedPersona]);

  // Title-specific handler
  const handleTitleChange = useCallback((newTitle) => {
    if (onTitleChange && canvasContent?.id) {
      onTitleChange(newTitle);
    }
  }, [onTitleChange, canvasContent?.id]);

  /**
   * Handles content editing request
   * @param {Event} e - The event object
   */
  const handleContentEdit = useCallback((e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setIsEditingContent(true);
    setIsSimplifiedView(false);
    setEditedContent(canvasContent.content);
    if (onStartEditing) {
      onStartEditing(canvasContent.id);
    }
  }, [canvasContent.id, canvasContent.content, onStartEditing]);

  /**
   * Handles saving edited content
   * @param {string} newContent - The new content to save
   */
  const handleContentSave = useCallback((newContent) => {
    // Ensure we have valid content
    const contentToSave = String(newContent || editedContent || '').trim();
    
    if (!contentToSave) {
      console.warn('Attempted to save empty content');
      return;
    }

    if (onContentChange) {
      console.log('Saving content from LinkedInPostMockup:', {
        postId: canvasContent.id,
        content: contentToSave,
        length: contentToSave.length
      });
      
      onContentChange(canvasContent.id, contentToSave);
      setIsEditingContent(false);
      setEditedContent('');
      
      if (onStopEditing) {
        onStopEditing();
      }
    }
  }, [canvasContent.id, editedContent, onContentChange, onStopEditing]);

  const handleContentCancel = useCallback(() => {
    setIsEditingContent(false);
    setEditedContent(canvasContent.content);
    if (onStopEditing) {
      onStopEditing();
    } else {
      console.warn('onStopEditing is not provided');
    }
  }, [canvasContent.content, onStopEditing]);

  // Add a double-click handler to the post content
  const handleDoubleClick = useCallback((e) => {
    e.stopPropagation();
    handleContentEdit(e);
  }, [handleContentEdit]);

  // Add this function to handle mouse movement
  const handleMouseMove = useCallback((e) => {
    if (isEditingContent) return;
    
    // Get cursor position relative to viewport
    const x = e.clientX;
    const y = e.clientY;
    
    // Update tooltip position with offset
    setTooltipPosition({
      x: x + 10, // 10px right of cursor
      y: y - 25  // 25px above cursor
    });
  }, [isEditingContent]);

  // Add this utility function at the top
  const isTouchDevice = () => {
    return (('ontouchstart' in window) ||
       (navigator.maxTouchPoints > 0) ||
       (navigator.msMaxTouchPoints > 0));
  };

  // Update the renderContent function
  const renderContent = () => {
    if (!canvasContent || !canvasContent.content) {
      return <div className="skeleton-content">...</div>;
    }

    if (isEditingContent) {
      return (
        <CanvasPostContentEditor
          ref={contentEditableRef}
          initialContent={canvasContent.content}
          content={editedContent || canvasContent.content}
          onSave={handleContentSave}
          onCancel={handleContentCancel}
          isDarkMode={isDarkMode}
          isEditing={isEditingContent}
          removeBorderAndPadding={isInCanvasExperience && removeBorderAndPadding}
          hideSaveCancelButtons={true}
          onChange={(newContent) => {
            console.log('Content changed:', newContent);
            setEditedContent(newContent);
          }}
        />
      );
    }

    return (
      <div 
        ref={contentRef}
        className={`
          relative
          whitespace-pre-wrap
          ${isEditingContent ? 'cursor-text' : 'cursor-pointer'}
          ${isTouchDevice() ? 'active:bg-gray-50 dark:active:bg-gray-800/50' : ''}
          transition-colors duration-150
          group
        `}
        onDoubleClick={handleDoubleClick}
        onMouseEnter={() => !isEditingContent && !isTouchDevice() && setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        onMouseMove={handleMouseMove}
        onTouchStart={() => {
          if (!isEditingContent && isTouchDevice()) {
            setShowTooltip(true);
            setTimeout(() => setShowTooltip(false), 1500);
          }
        }}
      >
        {showTooltip && !isEditingContent && (
          <Portal>
            <div
              className={`
                fixed
                select-none
                animate-in
                fade-in-0
                zoom-in-95
                px-2.5 py-1
                text-xs
                font-medium
                rounded-md
                shadow-md
                pointer-events-none
                whitespace-nowrap
                z-[9999]
                ${isDarkMode 
                  ? 'bg-slate-800 text-slate-100 border border-slate-700' 
                  : 'bg-white text-slate-950 border border-slate-200'
                }
              `}
              style={isTouchDevice() 
                ? {
                    bottom: '16px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                  } 
                : {
                    left: `${tooltipPosition.x}px`,
                    top: `${tooltipPosition.y}px`,
                    transform: 'translate(0, -100%)',
                  }
              }
            >
              <span>
                {isTouchDevice() ? 'Tap twice to edit' : 'Double click to edit'}
              </span>
              <div className={`
                absolute
                -bottom-1
                left-1/2
                h-2
                w-2
                -translate-x-1/2
                rotate-45
                ${isDarkMode
                  ? 'border-b border-r border-slate-700 bg-slate-800'
                  : 'border-b border-r border-slate-200 bg-white'
                }
              `} />
            </div>
          </Portal>
        )}
        {canvasContent.content}
      </div>
    );
  };

  // Add console log to verify prop
  useEffect(() => {
    if (isNewest) {
      console.log('LinkedInPostMockup received isNewest prop:', { id: canvasContent.id });
    }
  }, [isNewest, canvasContent.id]);

  // Add this effect to handle smooth transitions when post position changes
  useEffect(() => {
    if (isNewest) {
      const element = postRef.current;
      if (element) {
        element.style.transition = 'all 0.5s ease-out';
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [isNewest]);

  // Update isEditingContent when isEditing prop changes
  useEffect(() => {
    setIsEditingContent(isEditing);
  }, [isEditing]);

  // Inside the LinkedInPostMockup component
  const customTitle = canvasContent.title.split(' - ')[1] || canvasContent.title;

  // Add mouse event handlers
  const handleMouseEnter = useCallback(() => {
    setIsInteracting(true);
    setIsHovered(true);
  }, []);

  // Keep the second handleMouseLeave as is (for interaction state)
  const handleMouseLeave = useCallback(() => {
    if (!isEditingContent) {
      setIsInteracting(false);
      setIsHovered(false);
    }
  }, [isEditingContent]);

  // Add the copy handler function near other handlers
  const handleCopyContent = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(canvasContent.content);
      // Optional: Add some visual feedback that copying succeeded
      // You could implement this through state if needed
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  }, [canvasContent.content]);

  // Add an effect to handle forceExpanded changes
  useEffect(() => {
    if (forceExpanded) {
      setIsExpanded(true);
      setIsSimplifiedView(false);
    }
  }, [forceExpanded]);

  // Add a handler for toggling expanded state
  const handleToggleExpand = useCallback(() => {
    if (!preventCollapse) {
      setIsExpanded(prev => !prev);
      setIsSimplifiedView(prev => !prev);
      if (onExpand) {
        onExpand(!isExpanded);
      }
    }
  }, [preventCollapse, onExpand, isExpanded]);

  // Update the handleFocusMode function to use the prop
  const handleFocusMode = useCallback((e) => {
    e.stopPropagation();
    onFocusMode(canvasContent); // Pass the post content to the parent
  }, [canvasContent, onFocusMode]);

  // Update the save handler in LinkedInPostMockup
  const handleSaveClick = useCallback((e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    
    const contentToSave = editedContent || canvasContent.content;
    console.log('Saving content:', contentToSave);
    handleContentSave(contentToSave);
  }, [editedContent, canvasContent.content, handleContentSave]);

  // Add this new function to calculate responsive width
  const calculateResponsiveWidth = useCallback(() => {
    const isMobileView = window.innerWidth <= 768;
    
    if (isMobileView) {
      // On mobile, use available viewport width minus padding
      return `${Math.min(window.innerWidth - 32, DEVICE_CONFIGS[activeDevice].width)}px`;
    }
    
    // For other devices, use the device config width
    return `${DEVICE_CONFIGS[activeDevice].width}px`;
  }, [activeDevice]);

  // Update the getDeviceWidth function
  const getDeviceWidth = useCallback(() => {
    return calculateResponsiveWidth();
  }, [calculateResponsiveWidth]);

  // Add resize listener
  useEffect(() => {
    const handleResize = () => {
      // Force re-render when window is resized
      setPrevWidth(calculateResponsiveWidth());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [calculateResponsiveWidth]);

  // Fix position effect dependencies
  useEffect(() => {
    const currentPosition = canvasContent?.position;
    if (prevPosition !== null && 
        JSON.stringify(prevPosition) !== JSON.stringify(currentPosition)) {
      setIsMoving(true);
      const timer = setTimeout(() => {
        setIsMoving(false);
      }, 200);
      return () => clearTimeout(timer);
    }
    setPrevPosition(currentPosition);
  }, [canvasContent?.position, prevPosition]);

  // Update the main container style
  return (
    <div 
      className={`
        flex flex-col
        ${isInCanvasExperience ? 'mb-4' : 'mb-4'}
        mx-auto // Center the post
        w-full // Take full width on mobile
        px-4 // Add horizontal padding
      `}
      style={{
        maxWidth: getDeviceWidth(), // Limit maximum width
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* Title bar container */}
      {!hideTitleBar && (
        <div className="w-full" style={{ 
          width: '100%', // Take full width
          maxWidth: getDeviceWidth() // Limit maximum width
        }}>
          <TitleForLinkedInPostMockup
            initialTitle={customTitle}
            onTitleChange={handleTitleChange}
            isDarkMode={isDarkMode}
            onEdit={handleContentEdit}
            onCopy={handleCopyContent}
            currentIndex={currentIndex}
            totalPosts={totalPosts}
            onNavigateNext={onNavigateNext}
            onNavigatePrevious={onNavigatePrevious}
            isFocusMode={false}
            isEditing={isEditingContent}
            onSave={handleSaveClick}
            onCancel={handleContentCancel}
            isInteracting={isInteracting || isEditingContent}
            isInCanvasExperience={isInCanvasExperience}
            onFocusMode={handleFocusMode}
            onRemove={handleDeleteClick}
            isHovered={isHovered}
            canvasId={canvasId}
            postId={canvasContent.id}
            onFullScreen={() => onFullScreen(canvasContent)}
            content={canvasContent?.content}
          />
        </div>
      )}

      {/* Main post content container */}
      <div 
        ref={postRef}
        className={`
          linkedin-post-mockup rounded-lg
          ${isDarkMode 
            ? isEditingContent 
              ? 'bg-[#151515]' 
              : 'bg-[#1e1e1e]' 
            : 'bg-white'}
          relative group p-6
          overflow-visible
          ${isFocused && isInCanvasExperience ? `ring-1.5 ring-[${focusBorderColor}]` : ''}
          ${
            isEditingContent
              ? `border-2 border-dashed ${
                  isDarkMode 
                    ? 'bg-[#151515] border-[#333333]' 
                    : 'bg-white border-blue-300'
                }`
              : isDarkMode
                ? 'border border-[#333333]'
                : 'border border-gray-200'
          }
          ${showDeleteConfirm ? 'blur-[1px] pointer-events-none' : ''}
          ${isResizing ? 'post-resizing' : ''}
          ${isMoving ? 'post-moving' : ''}
          transition-colors duration-200
          w-full // Take full width
        `}
        style={{
          cursor: 'default',
          zIndex: isFocused ? 1000 : isEditingContent ? 999 : 1,
          userSelect: 'none',
          touchAction: 'pan-y',
          pointerEvents: showDeleteConfirm ? 'none' : 'auto',
          isolation: isEditingContent ? 'isolate' : 'auto',
          width: '100%', // Take full width
          maxWidth: getDeviceWidth(), // Limit maximum width
          height: 'auto',
          minHeight: 'min-content',
          margin: '1px auto', // Center horizontally
          position: 'relative',
          overflow: 'visible',
          willChange: isResizing || isMoving ? 'transform, width' : 'auto',
        }}
        onClick={!isEditingContent ? handleToggleExpand : undefined}
      >
        <div className="flex items-center">
          {selectedPersona ? (
            <>
              {avatarUrl ? (
                <img src={avatarUrl} alt={selectedPersona?.name || 'User'} className="h-12 w-12 rounded-full object-cover" />
              ) : (
                <div className={`h-12 w-12 rounded-full ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} flex items-center justify-center`}>
                  <svg 
                    className={`w-7 h-7 ${isDarkMode ? 'text-slate-500' : 'text-gray-400'}`}
                    xmlns="http://www.w3.org/2000/svg" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    stroke="currentColor" 
                    strokeWidth="1.5" 
                    strokeLinecap="round" 
                    strokeLinejoin="round"
                  >
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                    <circle cx="12" cy="7" r="4" />
                  </svg>
                </div>
              )}
              <div className="ml-3 flex-1 min-w-0">
                <div className={`text-sm font-semibold truncate ${isDarkMode ? 'text-slate-200' : 'text-slate-800'}`}>
                  {selectedPersona.name}
                </div>
                <div className={`text-xs ${isDarkMode ? 'text-slate-400' : 'text-gray-500'} truncate`}>
                  {selectedPersona.occupation || 'Occupation'}
                </div>
                <div className={`flex items-center text-xs ${isDarkMode ? 'text-slate-500' : 'text-gray-400'}`}>
                </div>
              </div>
            </>
          ) : null}
        </div>
        <div className={selectedPersona ? 'mt-6' : ''}>
          <div
            className={`text-sm ${
              isDarkMode 
                ? isEditingContent 
                  ? 'text-slate-200 bg-[#151515]' 
                  : 'text-slate-300' 
                : 'text-gray-700'
            } whitespace-pre-wrap break-words relative`}
            style={{
              position: 'relative',
              zIndex: 0
            }}
          >
            {renderContent()}
          </div>
        </div>
        {/* Only show engagement row if persona is selected */}
        {selectedPersona && (
          <div className={`text-xs flex items-center justify-between mt-6 ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
            <div className="flex items-center space-x-0">
              <div className="flex -space-x-1 mr-1">
                <img 
                  className={`${isDarkMode ? 'bg-slate-700' : 'bg-white'} rounded-full w-4 h-4`} 
                  src="https://static.kleo.so/icons/like.svg" 
                  alt="Likes" 
                />
                <img 
                  className={`${isDarkMode ? 'bg-slate-700' : 'bg-white'} rounded-full w-4 h-4`} 
                  src="https://static.kleo.so/icons/celebrate.svg" 
                  alt="Celebrations" 
                />
                <img 
                  className={`${isDarkMode ? 'bg-slate-700' : 'bg-white'} rounded-full w-4 h-4`} 
                  src="https://static.kleo.so/icons/support.svg" 
                  alt="Empathy" 
                />
              </div>
              <span>{reactions.toLocaleString()}</span>
            </div>
            <span>{comments} comments • {shares} reposts</span>
          </div>
        )}
      </div>

      {/* Only show delete confirmation if NOT in CanvasExperience */}
      {!isInCanvasExperience && showDeleteConfirm && (
        <div 
          className="fixed inset-0 z-50 flex items-center justify-center p-4"
          onClick={(e) => {
            if (e.target === e.currentTarget) handleCancelDelete();
          }}
        >
          {/* Improved backdrop */}
          <div 
            className={`absolute inset-0 ${
              isDarkMode 
                ? 'bg-slate-900/70 backdrop-blur-sm' 
                : 'bg-slate-50/80 backdrop-blur-sm'
            } transition-opacity duration-300`}
            aria-hidden="true"
          />
          
          {/* Modal content - Vertical layout with compact sizes */}
          <div 
            className={`
              ${isDarkMode 
                ? 'bg-slate-800 border-slate-700' 
                : 'bg-white border-gray-200'
              } 
              border rounded-lg p-4 relative z-50 w-[300px] 
              transform transition-all duration-200 ease-out
              ${isDarkMode 
                ? 'ring-1 ring-slate-600/50 shadow-xl shadow-black/20' 
                : 'ring-1 ring-gray-200/50 shadow-xl shadow-black/10'
              }
            `}
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-title"
          >
            {/* Warning icon - Centered */}
            <div className="flex h-8 w-8 mx-auto items-center justify-center rounded-full bg-red-100 mb-3">
              <ExclamationTriangleIcon 
                className="h-4 w-4 text-red-500" 
                aria-hidden="true" 
              />
            </div>

            <div 
              id="modal-title"
              className={`text-sm font-semibold text-center mb-1 ${
                isDarkMode ? 'text-slate-200' : 'text-gray-900'
              }`}
            >
              Delete This Post?
            </div>

            <div className={`text-xs text-center mb-3 ${
              isDarkMode 
                ? 'text-slate-400'
                : 'text-gray-500'
            }`}>
              Sure you want to delete this post? This action cannot be undone.
            </div>

            <div className="flex space-x-2">
              <button
                onClick={handleCancelDelete}
                className={`
                  flex-1 px-3 py-1.5 rounded text-xs font-medium 
                  transition-all duration-200
                  ${isDarkMode 
                    ? 'bg-slate-700 hover:bg-slate-600 text-slate-200 focus:ring-slate-500' 
                    : 'bg-gray-100 hover:bg-gray-200 text-gray-700 focus:ring-gray-400'
                  } 
                  focus:outline-none focus:ring-2 focus:ring-offset-2
                `}
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmDelete}
                className={`
                  flex-1 px-3 py-1.5 rounded text-xs font-medium
                  bg-red-500 hover:bg-red-600 text-white
                  transition-all duration-200
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500
                  ${isDarkMode 
                    ? 'shadow-sm shadow-red-900/30' 
                    : 'shadow-sm shadow-red-200/50'
                  }
                `}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

// Update PropTypes
LinkedInPostMockup.propTypes = {
  selectedPersona: PropTypes.object,
  canvasContent: PropTypes.object,
  onRemove: PropTypes.func,
  title: PropTypes.string,
  onTitleChange: PropTypes.func,
  onContentChange: PropTypes.func,
  isDarkMode: PropTypes.bool,
  isNewest: PropTypes.bool,
  onStartEditing: PropTypes.func,
  onStopEditing: PropTypes.func,
  isFocused: PropTypes.bool,
  onExpand: PropTypes.func,
  onNavigateNext: PropTypes.func,
  onNavigatePrevious: PropTypes.func,
  currentIndex: PropTypes.number,
  totalPosts: PropTypes.number,
  isInCanvasExperience: PropTypes.bool,
  focusBorderColor: PropTypes.string,
  removeBorderAndPadding: PropTypes.bool,
  isEditing: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  hideTitleBar: PropTypes.bool,
  isSimplifiedView: PropTypes.bool,
  forceExpanded: PropTypes.bool,
  preventCollapse: PropTypes.bool,
  onRequestDelete: PropTypes.func,
  onFocusMode: PropTypes.func,
  activeDevice: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
  canvasId: PropTypes.string,
  onFullScreen: PropTypes.func,
};

/**
 * Skeleton component for LinkedIn post mockup
 * @component
 * @param {Object} props
 * @param {boolean} props.isDarkMode - Whether dark mode is enabled
 */
const SkeletonLinkedInPost = ({ isDarkMode }) => {
  return (
    <div className={`linkedin-post-mockup shadow-sm rounded-lg ${
      isDarkMode ? 'bg-slate-800 border-slate-700' : 'bg-white border-gray-200'
    } border relative p-6 animate-pulse mb-6`}  // Changed p-4 to p-6
    style={{ 
      width: '400px',
      marginBottom: '24px',
    }}
    >
      <div className="flex items-center mb-6"> {/* Changed from mb-4 */}
        <div className={`h-12 w-12 rounded-full ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'}`}></div>
        <div className="ml-3 flex-1 min-w-0">
          <div className={`h-4 ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} rounded w-3/4 mb-2`}></div>
          <div className={`h-3 ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} rounded w-1/2`}></div>
          <div className={`h-2 ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} rounded w-1/4 mt-2`}></div>
        </div>
      </div>
      <div className="mb-6"> {/* Changed from mb-4 */}
        <div className={`h-4 ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} rounded w-full mb-2`}></div>
        <div className={`h-4 ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} rounded w-5/6 mb-2`}></div>
        <div className={`h-4 ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} rounded w-4/6`}></div>
      </div>
      <div className="flex justify-between items-center">
        <div className={`h-3 ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} rounded w-1/4`}></div>
        <div className={`h-3 ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} rounded w-1/3`}></div>
      </div>
    </div>
  );
};

// Export both components
export { SkeletonLinkedInPost };
export default LinkedInPostMockup;
