import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from '../context/ThemeContext';
import { 
  ChatBubbleLeftRightIcon, 
  SparklesIcon,
  MicrophoneIcon,
  PencilSquareIcon,
  LightBulbIcon,
  UserCircleIcon,
  UsersIcon,
  CpuChipIcon
} from '@heroicons/react/24/outline';
import styles from './WelcomeScreen.module.css';

/**
 * QuickAction Button Component
 */
const QuickActionButton = ({ action, onClick, onActionSelect }) => {
  const { isDarkMode } = useTheme();
  
  return (
    <button
      key={action.id}
      onClick={() => {
        onClick();
        if (onActionSelect) onActionSelect(action.id);
      }}
      className={`
        ${styles.quickAction} 
        ${action.type === 'input' ? styles.inputAction : styles.toolAction} 
        ${isDarkMode ? styles.darkMode : ''}
      `}
      title={action.label}
      aria-label={`Navigate to ${action.label}`}
    >
      <div className={styles.actionContent}>
        <action.icon className={`${styles.actionIcon} ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`} />
        <div className={styles.actionText}>
          <span className={`${styles.actionLabel} ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
            {action.label}
          </span>
          <span className={`${styles.actionDescription} ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
            {action.description}
          </span>
        </div>
      </div>
    </button>
  );
};

QuickActionButton.propTypes = {
  action: PropTypes.shape({
    id: PropTypes.string.isRequired,
    icon: PropTypes.elementType.isRequired,
    label: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  onActionSelect: PropTypes.func,
};

/**
 * WelcomeScreen Component
 */
const WelcomeScreen = ({ onActionSelect }) => {
  const navigate = useNavigate();
  const [isNavigating, setIsNavigating] = useState(false);
  const { isDarkMode } = useTheme();

  const quickActions = useMemo(() => ({
    row1: [
      { 
        id: 'voice', 
        icon: MicrophoneIcon, 
        label: 'Take Voice Notes', 
        description: 'Use your voice notes to generate posts',
        path: '/voice-notes',
        type: 'input'
      },
      { 
        id: 'text', 
        icon: PencilSquareIcon, 
        label: 'Make Text Notes', 
        description: 'Use your text notes to generate posts',
        path: '/text-notes',
        type: 'input'
      },
      { 
        id: 'ideas', 
        icon: LightBulbIcon, 
        label: 'Generate Ideas', 
        description: 'Ideate based on your profile & audience',
        path: '/content-strategy/manager',
        type: 'input'
      },
    ],
    row2: [
      { 
        id: 'writer', 
        icon: UserCircleIcon, 
        label: 'Custom personas', 
        description: 'Hyper personalize your brand voice',
        path: '/personas',
        type: 'tool'
      },
      { 
        id: 'audience', 
        icon: UsersIcon, 
        label: 'Your Audience', 
        description: 'Tailor your content to your audience',
        path: '/target-audiences',
        type: 'tool'
      },
      { 
        id: 'ai', 
        icon: CpuChipIcon, 
        label: 'AI Templates', 
        description: 'Purpose-built AI agents for your brand',
        path: '/agents',
        type: 'tool'
      }
    ]
  }), []);

  const handleQuickAction = async (action, path) => {
    if (path && !isNavigating) {
      setIsNavigating(true);
      try {
        if (action === 'voice') {
          onActionSelect && onActionSelect('voice');
        } else if (action === 'text') {
          onActionSelect && onActionSelect('text');
        } else {
          await navigate(path);
        }
      } finally {
        setIsNavigating(false);
      }
    }
  };

  return (
    <div className={`flex flex-col items-center justify-center h-full text-center px-2 mx-auto space-y-4 ${
      isDarkMode ? 'text-gray-200' : 'text-gray-600'
    }`} role="main">
      <div className={`${styles.iconWrapper} transition-all duration-300 hover:scale-105`}>
        <ChatBubbleLeftRightIcon className={`w-5 h-5 ${
          isDarkMode ? 'text-gray-300' : 'text-gray-500'
        } opacity-80`} aria-hidden="true" />
      </div>

      <div className={`${styles.titleWrapper} ${isDarkMode ? styles.darkMode : ''}`}>
        <h1 className={styles.titleContainer}>
          <div className={styles.titleLines}>
            <div className={`${styles.firstLine} ${isDarkMode ? styles.darkMode : ''}`}>
              Transform your thoughts & notes
            </div>
            <div className={`${styles.secondLine} ${isDarkMode ? styles.darkMode : ''}`}>
              into LinkedIn influence
            </div>
          </div>
        </h1>
      </div>

      <p className={`${styles.simpleFormula} ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
        (Your thoughts + notes + ideas) × (your brand voice) × (Your audience) = Content worth sharing
      </p>

      <div className={`${styles.quickActionsContainer} ${isNavigating ? 'opacity-70 pointer-events-none' : ''}`}>
        <div className={styles.quickActionsGrid}>
          <div className={styles.quickActionsWrapper}>
            {[...quickActions.row1, ...quickActions.row2].map((action) => (
              <QuickActionButton
                key={action.id}
                action={action}
                onClick={() => handleQuickAction(action.id, action.path)}
                onActionSelect={onActionSelect}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeScreen;