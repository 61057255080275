import React, { useState } from 'react';
import { XMarkIcon, EyeIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../context/ThemeContext';

const TemplateAttachment = ({ template, onRemove, width = 'w-full' }) => {
  const [showContent, setShowContent] = useState(false);
  const { isDarkMode } = useTheme();

  const baseClasses = isDarkMode 
    ? 'bg-slate-800 text-slate-100' 
    : 'bg-white text-slate-800';

  const buttonClasses = isDarkMode 
    ? 'text-slate-400 hover:text-slate-200' 
    : 'text-slate-600 hover:text-slate-800';

  const tags = [template.inspiredBy, template.category, template.tone]
    .filter(Boolean)
    .join(' • ');

  return (
    <div className={`${width} relative`}>
      <div className={`rounded-lg p-2 ${baseClasses} border ${isDarkMode ? 'border-slate-700' : 'border-slate-200'}`}>
        <div className="flex items-center gap-2">
          {/* Column with template name and tags */}
          <div className="flex flex-col flex-1 min-w-0 justify-center">
            <div className="text-sm font-medium truncate">
              {template.title}
            </div>
            {tags && (
              <div className={`text-xs truncate ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`}>
                {tags}
              </div>
            )}
          </div>

          {/* Action buttons */}
          <div className="flex gap-1 shrink-0">
            <button onClick={() => setShowContent(!showContent)} className={`p-1.5 rounded transition-colors ${buttonClasses}`}>
              <EyeIcon className="h-4 w-4" />
            </button>
            <button onClick={onRemove} className={`p-1.5 rounded transition-colors ${buttonClasses}`}>
              <XMarkIcon className="h-4 w-4" />
            </button>
          </div>
        </div>
      </div>

      {/* Preview Popup */}
      {showContent && (
        <div className={`
          absolute z-10 left-0 right-0 bottom-full mb-2 p-3 rounded-lg border
          shadow-lg max-h-60 overflow-y-auto ${baseClasses}
        `}>
          <p className="text-sm whitespace-pre-wrap">{template.content}</p>
        </div>
      )}
    </div>
  );
};

export default TemplateAttachment;