import React from 'react';
import PropTypes from 'prop-types';

const OpportunityCard = ({ opportunity, isDarkMode, wrapWithCopy }) => {
  const [title, ...details] = opportunity.split('\n').filter(Boolean);
  const engagementScore = title.match(/\(([^)]+)\)/)?.[1];
  
  // Parse details into structured format
  const parsedDetails = details.reduce((acc, detail) => {
    // Skip empty lines and dashes
    if (!detail.trim() || detail.trim() === '-') return acc;

    // Try to split by colon first
    let [type, ...content] = detail.split(':').map(s => s.trim());
    content = content.join(':').trim(); // Rejoin in case there were colons in the content

    // If no colon found, treat the whole line as content
    if (!content) {
      content = type;
      type = 'content';
    }

    // Remove leading dash if present
    if (type.startsWith('-')) {
      type = type.substring(1).trim();
    }
    if (content.startsWith('-')) {
      content = content.substring(1).trim();
    }

    // Handle special cases
    switch (type.toLowerCase()) {
      case 'hook':
        acc.hook = content;
        break;
      case 'key points':
        // Split by actual bullet points or dashes
        acc.keyPoints = content
          .split(/(?:^|\n)[-•]/)
          .map(point => point.trim())
          .filter(Boolean);
        break;
      case 'supporting data':
        acc.supportingData = content;
        break;
      case 'content':
        if (!acc.content) acc.content = [];
        acc.content.push(content);
        break;
      default:
        if (!acc.other) acc.other = [];
        acc.other.push({ type, content });
    }
    return acc;
  }, {});

  return (
    <div 
      className={`p-4 rounded-lg border ${
        isDarkMode 
          ? 'bg-[#1a1a1a] border-[#333333] hover:border-[#444444]' 
          : 'bg-gray-50 border-gray-100 hover:border-gray-200'
      } transition-colors duration-200`}
    >
      {/* Header with Title and Score */}
      {wrapWithCopy(
        title.split('(')[0].replace(/^\d+\.\s*/, ''),
        <div className="flex items-center justify-between mb-4">
          <h4 className={`text-base font-medium flex-1 pr-8 ${
            isDarkMode ? 'text-slate-100' : 'text-slate-900'
          }`}>
            {title.split('(')[0].replace(/^\d+\.\s*/, '')}
          </h4>
          {engagementScore && (
            <span className={`px-2 py-1 text-sm rounded font-medium ml-3 ${
              isDarkMode 
                ? 'bg-[#252525] text-green-300 border border-green-500/20' 
                : 'bg-green-50 text-green-700 border border-green-100'
            }`}>
              {engagementScore.replace(/^Engagement Score:\s*/i, '')}
            </span>
          )}
        </div>
      )}

      {/* Content Sections */}
      <div className="space-y-4">
        {/* Hook Section */}
        {parsedDetails.hook && wrapWithCopy(
          parsedDetails.hook,
          <div className={`text-sm ${
            isDarkMode ? 'text-slate-300' : 'text-slate-700'
          }`}>
            <span className={`font-medium ${
              isDarkMode ? 'text-slate-400' : 'text-slate-600'
            }`}>
              Hook:
            </span>
            <p className="mt-1 pr-8 italic">"{parsedDetails.hook}"</p>
          </div>
        )}

        {/* Key Points Section */}
        {parsedDetails.keyPoints?.length > 0 && (
          <div className="space-y-2">
            <span className={`text-sm font-medium ${
              isDarkMode ? 'text-slate-400' : 'text-slate-600'
            }`}>
              Key Points:
            </span>
            <ul className="space-y-1 mt-1">
              {parsedDetails.keyPoints.map((point, index) => 
                wrapWithCopy(
                  point,
                  <li 
                    key={index}
                    className={`flex gap-2 text-sm pr-8 ${
                      isDarkMode ? 'text-slate-300' : 'text-slate-700'
                    }`}
                  >
                    <span>•</span>
                    <span>{point}</span>
                  </li>
                )
              )}
            </ul>
          </div>
        )}

        {/* Supporting Data Section */}
        {parsedDetails.supportingData && wrapWithCopy(
          parsedDetails.supportingData,
          <div className={`text-sm ${
            isDarkMode ? 'text-slate-300' : 'text-slate-700'
          }`}>
            <span className={`font-medium ${
              isDarkMode ? 'text-slate-400' : 'text-slate-600'
            }`}>
              Supporting Data:
            </span>
            <p className="mt-1 pr-8">{parsedDetails.supportingData}</p>
          </div>
        )}

        {/* Plain Content Section */}
        {parsedDetails.content?.length > 0 && (
          <div className="space-y-2">
            {parsedDetails.content.map((text, index) => 
              wrapWithCopy(
                text,
                <p 
                  key={index}
                  className={`text-sm pr-8 ${
                    isDarkMode ? 'text-slate-300' : 'text-slate-700'
                  }`}
                >
                  {text}
                </p>
              )
            )}
          </div>
        )}

        {/* Other Details */}
        {parsedDetails.other?.map((item, index) => 
          wrapWithCopy(
            `${item.type}: ${item.content}`,
            <div 
              key={index}
              className={`text-sm pr-8 ${
                isDarkMode ? 'text-slate-300' : 'text-slate-700'
              }`}
            >
              <span className={`font-medium ${
                isDarkMode ? 'text-slate-400' : 'text-slate-600'
              }`}>
                {item.type}:
              </span>
              <span className="ml-2">{item.content}</span>
            </div>
          )
        )}
      </div>
    </div>
  );
};

const ViralOpportunitiesFormat = ({ content, isDarkMode, wrapWithCopy }) => {
  if (!content) {
    return (
      <div className={`text-sm ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}`}>
        No viral opportunities available.
      </div>
    );
  }

  const opportunities = content
    .split(/(?=\d+\. )/)
    .filter(Boolean)
    .slice(1); // Skip the section title

  if (opportunities.length === 0) {
    return (
      <div className={`text-sm ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}`}>
        No viral opportunities found in the content.
      </div>
    );
  }

  return (
    <div className="grid gap-6">
      {opportunities.map((opportunity, index) => (
        <OpportunityCard
          key={index}
          opportunity={opportunity}
          isDarkMode={isDarkMode}
          wrapWithCopy={wrapWithCopy}
        />
      ))}
    </div>
  );
};

ViralOpportunitiesFormat.propTypes = {
  content: PropTypes.string,
  isDarkMode: PropTypes.bool.isRequired,
  wrapWithCopy: PropTypes.func.isRequired
};

OpportunityCard.propTypes = {
  opportunity: PropTypes.string.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
  wrapWithCopy: PropTypes.func.isRequired
};

export default ViralOpportunitiesFormat; 