import React, { useState, useContext, useRef, useEffect, useCallback, useMemo } from 'react';
import { ChatBubbleLeftEllipsisIcon, ChatBubbleLeftRightIcon, BookOpenIcon, ChevronLeftIcon, ChevronRightIcon, EllipsisHorizontalIcon, Cog6ToothIcon, HomeIcon, MicrophoneIcon, ArchiveBoxIcon, BellIcon, UserIcon, MoonIcon, SunIcon, CircleStackIcon, FolderIcon, CheckIcon, DocumentTextIcon, AcademicCapIcon, CpuChipIcon, LightBulbIcon, PencilSquareIcon, ClipboardDocumentListIcon, ChevronDownIcon, Squares2X2Icon, EyeIcon, MagnifyingGlassIcon, GlobeAltIcon, CloudArrowDownIcon, BriefcaseIcon, UsersIcon, LinkIcon, HashtagIcon, ClockIcon, BookmarkIcon, UserCircleIcon, QuestionMarkCircleIcon, ArrowRightOnRectangleIcon, PlusIcon, SparklesIcon, CalendarIcon, XMarkIcon, RocketLaunchIcon, ChartBarIcon, DocumentMagnifyingGlassIcon, PencilIcon, BoltIcon } from '@heroicons/react/24/outline';
// Note: ClockIcon has been removed from this import
import { PanelLeftClose } from 'lucide-react'; // Add this import
import { AuthContext } from '../context/AuthContext';
import { useTheme } from '../context/ThemeContext';
import { Link, useNavigate, useLocation } from 'react-router-dom';
// import Logo from '../logo.svg';
import useSidebarState from '../hooks/useSidebarState';
import api from '../api';
import { fetchAvatarUrl, refreshAvatarUrl } from '../services/userService';
import { isUrlExpired } from '../utils/imageUtils';
import { Popover } from '@headlessui/react';
import { createPortal } from 'react-dom';
import { motion, AnimatePresence } from 'framer-motion'; // Add this import
import { Transition } from '@headlessui/react';
import '../components/scrollbar.css'; // Add this import
import CreateButton from './CreateButton';
import SidebarFlipIcon from './assets/sidebar-flip.svg'; // Add this import
import DotCircleIcon from './assets/dot-circle.svg'; // Add this import
import FocusModeSidebar, { FocusModeButton } from './FocusModeSidebar';
import SideBarFooterMenuPopup from './SideBarFooterMenuPopup';
import FocusModeFooterMenuPopup from './FocusModeFooterMenuPopup';
import PricingForGhost from './PricingForGhost';
import SearchTrigger from './GlobalSearch/SearchTrigger';
import { useSearch } from '../context/SearchContext';

// Duration for caching the avatar URL
const AVATAR_CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
const DEFAULT_AVATAR = 'https://ghostscompany.s3.amazonaws.com/userprofile-placeholder.png';

// Define menuItems here
export const menuItems = [
  {
    title: 'My Brands',
    shortTitle: 'Brands',
    icon: BriefcaseIcon,
    children: [
      { name: 'Brand Personas', icon: UserCircleIcon, label: 'Brand Profiles', path: '/personas' },
      { name: 'Target Audiences', icon: UsersIcon, label: 'Target Audiences', path: '/target-audiences' },
      { name: 'AI Templates', icon: CpuChipIcon, label: 'AI Templates', path: '/agents' },
    ],
  },
  {
    title: 'Brainstorm Ideas',
    shortTitle: 'Ideate',
    icon: LightBulbIcon,
    children: [
      { 
        name: 'Ideate with AI', 
        icon: DocumentMagnifyingGlassIcon, 
        label: 'Ideate with AI', 
        path: '/content-strategy/manager'
      },
      { 
        name: 'Content Ideas', 
        icon: GlobeAltIcon, 
        label: 'Ideate with Reddit', 
        path: '/youidea'
      },
      { 
        name: 'Finalized Ideas', 
        icon: BookmarkIcon, 
        label: 'Finalized Ideas', 
        path: '/saved-ideas'
      },
    ],
  },
  {
    title: 'Capture Notes',
    shortTitle: 'Capture',
    icon: PencilSquareIcon,
    children: [
      { name: 'Voice Notes', icon: MicrophoneIcon, label: 'Voice Notes', path: '/voice-notes' },
      { name: 'Text Notes', icon: DocumentTextIcon, label: 'Text Notes', path: '/text-notes' },
      { name: 'Media Library', icon: FolderIcon, label: 'Files', path: '/files' },
    ],
  },
  {
    title: 'Analytics',
    shortTitle: 'Analytics',
    icon: ChartBarIcon,
    path: '/analytics',
  },
];

// Add this function just before the Sidebar component definition

const CustomTooltip = ({ children, content, isDarkMode }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const buttonRef = useRef(null);

  const updateTooltipPosition = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setTooltipPosition({
        top: rect.top + window.scrollY + rect.height / 2,
        left: rect.right + window.scrollX + 10, // 10px offset from the right edge
      });
    }
  };

  useEffect(() => {
    if (isHovered) {
      updateTooltipPosition();
      window.addEventListener('scroll', updateTooltipPosition);
      window.addEventListener('resize', updateTooltipPosition);
    }
    return () => {
      window.removeEventListener('scroll', updateTooltipPosition);
      window.removeEventListener('resize', updateTooltipPosition);
    };
  }, [isHovered]);

  // Don't show tooltip for Dashboard in collapsed mode
  if (content === 'Dashboard') {
    return children;
  }

  return (
    <div
      className="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      ref={buttonRef}
    >
      {children}
      {isHovered &&
        createPortal(
          <div
            className={`fixed z-50 px-2 py-1 text-sm font-medium rounded-md shadow-lg whitespace-nowrap ${
              isDarkMode ? 'bg-slate-700 text-slate-100' : 'bg-slate-200 text-slate-900'
            }`}
            style={{
              top: `${tooltipPosition.top}px`,
              left: `${tooltipPosition.left}px`,
              transform: 'translateY(-50%)',
            }}
          >
            {content}
          </div>,
          document.body
        )}
    </div>
  );
};

// Add this new component for the "Soon" tag
const SoonTag = ({ isDarkMode }) => (
  <span className={`ml-2 px-1.5 py-0.5 text-xs font-medium rounded-full ${
    isDarkMode ? 'bg-slate-700 text-slate-300' : 'bg-slate-200 text-slate-700'
  }`}>
    Soon
  </span>
);

// Add this new component for the sparkle effect
const SparkleEffect = () => (
  <div className="absolute inset-0 overflow-hidden rounded-md">
    <div className="absolute inset-0 animate-shine bg-gradient-to-r from-transparent via-white/10 to-transparent -skew-x-45" />
  </div>
);

// Add this new component for the badge
const MenuItemBadge = ({ text, isDarkMode }) => (
  <span className={`
    ml-2 px-1.5 py-0.5 text-[10px] font-medium rounded-full
    ${isDarkMode 
      ? 'bg-gradient-to-r from-blue-500/10 to-purple-500/10 text-blue-400 ring-1 ring-blue-500/20' 
      : 'bg-gradient-to-r from-blue-100 to-purple-100 text-blue-600'
    }
    animate-pulse
  `}>
    {text}
  </span>
);

const TreeItem = ({ item, level, isSidebarCollapsed, isDarkMode, handleClick, location, onHover, onLeave, isExpanded, toggleExpand }) => {
  const hasChildren = item.children && item.children.length > 0;
  const iconClasses = 'w-4 h-4 flex-shrink-0';

  // Add special styling for highlighted items
  const getHighlightClasses = () => {
    if (item.highlight) {
      return isDarkMode
        ? 'bg-gradient-to-r from-blue-500/10 via-purple-500/10 to-blue-500/10 hover:from-blue-500/20 hover:via-purple-500/20 hover:to-blue-500/20 ring-1 ring-blue-500/20'
        : 'bg-gradient-to-r from-blue-50 via-purple-50 to-blue-50 hover:from-blue-100 hover:via-purple-100 hover:to-blue-100';
    }
    return '';
  };

  const itemClasses = `
    flex items-center justify-between w-full transition-colors duration-200 rounded-md 
    ${location.pathname === item.path
      ? isDarkMode
        ? 'bg-gradient-to-r from-[#2A2A2A] to-[#1E1E1E] text-white ring-1 ring-[#333333]'
        : 'bg-slate-200 text-slate-900'
      : isDarkMode
        ? 'text-gray-300 hover:bg-[#1E1E1E]'
        : 'text-slate-700 hover:bg-slate-100'
    } 
    ${isSidebarCollapsed ? 'py-1 px-2' : 'p-2'} 
    ${hasChildren || item.path ? 'cursor-pointer' : ''}
    ${getHighlightClasses()}
    ${item.sparkle ? 'relative overflow-hidden' : ''}
  `;

  // Update the button content to include the badge and sparkle effect
  const ButtonContent = (
    <div className={`flex items-center justify-center relative z-10 ${
      isSidebarCollapsed ? 'w-full h-6' : 'gap-2'
    }`}>
      <div className="flex-shrink-0">
        <item.icon className={`${
          isSidebarCollapsed ? 'w-4 h-4' : iconClasses
        } ${item.highlight ? 'text-blue-500' : ''}`} />
      </div>
      {!isSidebarCollapsed && (
        <span className={`text-xs ${
          item.highlight ? 'font-medium' : ''
        } flex-grow min-h-[1.5rem] flex items-center`}>
          {item.title || item.label}
        </span>
      )}
      {item.badge && <MenuItemBadge text={item.badge} isDarkMode={isDarkMode} />}
      {item.sparkle && <SparkleEffect />}
    </div>
  );

  const toggleOpen = (e) => {
    e.stopPropagation();
    toggleExpand(item.title);
  };

  if (isSidebarCollapsed) {
    return (
      <div 
        className="py-0" // Removed vertical padding completely
        onMouseEnter={(e) => {
          const rect = e.currentTarget.getBoundingClientRect();
          onHover(item, rect.top + window.scrollY);
        }}
        onMouseLeave={onLeave}
      >
        <button
          onClick={() => item.path && handleClick(item.title || item.name, item.path)}
          className={`${itemClasses} w-full`}
          aria-label={item.title || item.label}
        >
          {hasChildren ? ButtonContent : (
            <CustomTooltip content={item.title || item.label} isDarkMode={isDarkMode}>
              {ButtonContent}
            </CustomTooltip>
          )}
        </button>
      </div>
    );
  }

  // Special handling for Dashboard item
  if (item.title === 'Dashboard') {
    return (
      <CustomTooltip content={item.title} isDarkMode={isDarkMode}>
        <button
          onClick={() => handleClick(item.title, item.path)}
          className={`${
            isSidebarCollapsed 
              ? 'w-10 h-10 rounded-md flex items-center justify-center transition-all duration-300 ease-in-out'
              : `${itemClasses} justify-start`
          } w-full`}
          aria-label={item.title}
        >
          <div className="flex items-center">
            <item.icon className={`${iconClasses} ${isDarkMode ? 'text-slate-400' : 'text-slate-700'}`} />
            {!isSidebarCollapsed && <span className="ml-3 text-xs">{item.title}</span>}
          </div>
        </button>
      </CustomTooltip>
    );
  }

  return (
    <div className={`ml-${level * 2}`}>
      <div 
        className={`${itemClasses} ${hasChildren ? 'cursor-pointer' : ''}`} 
        onClick={hasChildren ? toggleOpen : () => item.path && handleClick(item.name, item.path)}
      >
        <div className="flex items-center">
          <item.icon className={`${iconClasses} ${isSidebarCollapsed ? '' : 'mr-3'}`} />
          {!isSidebarCollapsed && (
            <>
              <span className="text-xs">{item.title || item.label}</span>
              {item.soon && <SoonTag isDarkMode={isDarkMode} />}
            </>
          )}
        </div>
        {hasChildren && !isSidebarCollapsed && (
          <ChevronRightIcon 
            className={`w-3 h-3 transition-transform duration-200 ${
              isExpanded(item.title) ? 'transform rotate-90' : ''
            } ${isDarkMode ? 'text-slate-500' : 'text-slate-400'}`} 
          />
        )}
      </div>
      {hasChildren && isExpanded(item.title) && (
        <div className="mt-1 space-y-1 ml-2">
          {item.children.map((child) => (
            <TreeItem
              key={child.name}
              item={child}
              level={level + 1}
              isSidebarCollapsed={isSidebarCollapsed}
              isDarkMode={isDarkMode}
              handleClick={handleClick}
              location={location}
              isExpanded={isExpanded}
              toggleExpand={toggleExpand}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const Popup = ({ item, isDarkMode, handleClick, isVisible, position, onMouseEnter, onMouseLeave, location }) => {
  if (!item || !item.children) return null;

  const offsetX = 56; // Slightly reduced from 64 to align better

  return createPortal(
    <Transition
      show={isVisible}
      enter="transition ease-out duration-200"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-150"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <div 
        className={`fixed py-1.5 w-48 rounded-md shadow-lg z-50 ${
          isDarkMode 
            ? 'bg-[#1E1E1E] border border-[#333333]' 
            : 'bg-white border border-slate-200'
        }`}
        style={{ 
          top: `${position}px`,
          left: `${offsetX}px`,
          position: 'fixed',
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {item.children.map((child) => (
          <button
            key={child.name}
            className={`
              block w-full text-left px-3 py-1.5 text-sm transition-colors duration-150 
              ${location.pathname === child.path
                ? isDarkMode
                  ? 'bg-gradient-to-r from-[#2A2A2A] to-[#1E1E1E] text-white ring-1 ring-[#333333]'
                  : 'bg-slate-100 text-slate-900'
                : isDarkMode
                  ? 'text-gray-300 hover:bg-[#2A2A2A] hover:text-gray-200'
                  : 'text-slate-700 hover:bg-slate-100 hover:text-slate-900'
              }
            `}
            onClick={() => handleClick(child.name, child.path)}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <child.icon className={`w-4 h-4 mr-2 ${
                  isDarkMode ? 'text-slate-400' : 'text-slate-600'
                }`} />
                <span className="text-xs">{child.label}</span>
              </div>
              {child.soon && <SoonTag isDarkMode={isDarkMode} />}
            </div>
          </button>
        ))}
      </div>
    </Transition>,
    document.body
  );
};

const TreeView = ({ items, isSidebarCollapsed, isDarkMode, handleClick, location, isExpanded, toggleExpand }) => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupPosition, setPopupPosition] = useState(0);
  const popupTimeoutRef = useRef(null);

  const handleItemHover = (item, position) => {
    clearTimeout(popupTimeoutRef.current);
    setHoveredItem(item);
    setPopupPosition(position);
    setIsPopupOpen(true);
  };

  const handleItemLeave = () => {
    popupTimeoutRef.current = setTimeout(() => {
      setIsPopupOpen(false);
    }, 300); // 300ms delay before closing
  };

  const handlePopupEnter = () => {
    clearTimeout(popupTimeoutRef.current);
  };

  const handlePopupLeave = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className="space-y-2"> {/* Increased from space-y-0.5 to space-y-2 */}
      <div className={`${
        isSidebarCollapsed 
          ? 'flex justify-center mt-3' // Added mt-3 for top margin in collapsed mode
          : ''
      } mb-2`}>
        <CreateButton
          isDarkMode={isDarkMode}
          isSidebarCollapsed={isSidebarCollapsed}
          onClick={() => handleClick('Create', '/chat')}
          icon={SparklesIcon}
          text="Content Studio"
          className={`hover:shadow-none ${
            isDarkMode 
              ? 'bg-gradient-to-r from-slate-800 to-slate-700 hover:from-slate-700 hover:to-slate-600 ring-1 ring-slate-700 hover:ring-slate-600 text-slate-200' 
              : 'hover:bg-slate-100'
          }`}
        />
      </div>
      {items.map((item, index) => (
        <div 
          key={item.title} 
          className={`${
            isSidebarCollapsed 
              ? 'collapsed-sidebar-section' // Removed mb-0 to let space-y handle the spacing
              : ''
          }`}
        >
          <TreeItem
            item={item}
            level={0}
            isSidebarCollapsed={isSidebarCollapsed}
            isDarkMode={isDarkMode}
            handleClick={handleClick}
            location={location}
            onHover={handleItemHover}
            onLeave={handleItemLeave}
            isExpanded={isExpanded}
            toggleExpand={toggleExpand}
          />
        </div>
      ))}
      {isSidebarCollapsed && (
        <Popup
          item={hoveredItem}
          isDarkMode={isDarkMode}
          handleClick={handleClick}
          isVisible={isPopupOpen}
          position={popupPosition}
          onMouseEnter={handlePopupEnter}
          onMouseLeave={handlePopupLeave}
          location={location}
        />
      )}
    </div>
  );
};

const SubIconsBubble = ({ subItems, handleClick, isDarkMode, isSidebarCollapsed }) => (
  <div className={`py-2 px-2 rounded-md z-20 ${isDarkMode ? 'bg-slate-800' : 'bg-blue-100'} w-full flex flex-row items-center justify-center transition-all duration-300 ease-in-out`}>
    {subItems.map((subItem, index) => (
      <React.Fragment key={subItem.name}>
        <CustomTooltip content={subItem.label} isDarkMode={isDarkMode}>
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleClick(subItem.name, subItem.path, true);
            }}
            className={`${isSidebarCollapsed ? 'w-6 h-6' : 'w-6 h-6'} rounded-md flex items-center justify-center transition-all duration-300 ease-in-out ${isDarkMode ? 'text-slate-400 hover:bg-slate-700' : 'text-slate-700 hover:bg-slate-100'}`}
            aria-label={subItem.label}
          >
            <subItem.icon className={isSidebarCollapsed ? 'w-4 h-4' : 'w-4 h-4'} />
          </button>
        </CustomTooltip>
        {index < subItems.length - 1 && (
          <div className={`w-px h-4 mx-3 self-center ${isDarkMode ? 'bg-slate-700' : 'bg-blue-200'}`} />
        )}
      </React.Fragment>
    ))}
  </div>
);

// Step 1: Add a Separator component
const Separator = ({ isDarkMode, isSidebarCollapsed }) => (
  <hr className={`my-2 ${isSidebarCollapsed ? 'mx-2' : 'mx-1'} border-t ${
    isDarkMode ? 'border-slate-700' : 'border-slate-200'
  }`} />
);

// Update the MinimalSearchBar component
const MinimalSearchBar = ({ isDarkMode, isSidebarCollapsed, className }) => {
  const { openSearch } = useSearch();

  return (
    <div className={`
      flex 
      ${isSidebarCollapsed ? 'justify-center' : 'px-3 w-full'}
    `}>
      <button
        onClick={openSearch}
        className={`
          group relative
          ${isSidebarCollapsed ? 'w-8 h-8' : 'w-full h-9'}
          flex items-center justify-center
          rounded-md transition-all duration-200
          ${isDarkMode 
            ? 'bg-[#121212] border-transparent text-gray-400' // Match sidebar background in dark mode
            : 'bg-slate-50 border border-transparent text-gray-500' // Light mode background
          }
          ${isSidebarCollapsed ? 'hover:scale-105 transform transition-transform' : ''}
        `}
      >
        <div className={`
          flex items-center
          ${isSidebarCollapsed 
            ? 'w-full h-full justify-center' 
            : 'w-full px-3'
          }
        `}>
          <MagnifyingGlassIcon className={`
            ${isSidebarCollapsed ? 'w-4 h-4' : 'w-3.5 h-3.5'} 
            flex-shrink-0 
            ${isDarkMode ? 'text-gray-500' : 'text-gray-400'}
            group-hover:text-gray-500
            transition-colors duration-200
          `} />
          
          {!isSidebarCollapsed && (
            <>
              <span className={`ml-2 text-xs font-medium flex-grow text-left truncate ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              } group-hover:text-gray-600`}>
                Quick search...
              </span>
              <kbd className={`
                hidden md:flex items-center gap-0.5 px-1.5 rounded text-[10px] font-medium
                ${isDarkMode 
                  ? 'bg-[#242424] text-gray-500 ring-1 ring-[#333333]' 
                  : 'bg-slate-100 text-gray-400 ring-1 ring-slate-200'
                }
              `}>
                <span className="text-[10px]">⌘</span>
                <span className="text-[10px]">K</span>
              </kbd>
            </>
          )}
        </div>
      </button>
    </div>
  );
};

const UpgradeButton = ({ isDarkMode, onClick, isSidebarCollapsed }) => (
  <button
    onClick={onClick}
    className={`
      group relative w-full
      ${isSidebarCollapsed ? 'px-2 py-2' : 'px-3 py-2.5'}
      mb-2 rounded-lg
      transition-all duration-200
      ${isDarkMode
        ? 'bg-gradient-to-r from-purple-500/10 to-blue-500/10 hover:from-purple-500/20 hover:to-blue-500/20 text-white'
        : 'bg-gradient-to-r from-purple-50 to-blue-50 hover:from-purple-100 hover:to-blue-100 text-slate-900'
      }
      border
      ${isDarkMode ? 'border-purple-500/20' : 'border-purple-100'}
    `}
  >
    <div className="flex items-center justify-center gap-2">
      <BoltIcon className={`w-4 h-4 ${isDarkMode ? 'text-purple-400' : 'text-purple-500'}`} />
      {!isSidebarCollapsed && (
        <span className="text-xs font-medium">Upgrade to Pro</span>
      )}
    </div>
    <div className="absolute inset-0 overflow-hidden rounded-lg">
      <div className="absolute inset-0 animate-shine bg-gradient-to-r from-transparent via-white/10 to-transparent -skew-x-45" />
    </div>
  </button>
);

const Sidebar = ({
  currentScreen,
  setCurrentScreen,
  isSidebarCollapsed,
  setIsSidebarCollapsed,
  activeScreen,
  onTimerClick,
  onFocusModeToggle,
  isFocusMode,
  activeSubmenu,
  onMenuItemClick
}) => {
  // Context and hooks
  const { user } = useContext(AuthContext);
  const { isDarkMode, toggleDarkMode } = useTheme();
  const { expandedItems, toggleExpand, isExpanded } = useSidebarState();
  const location = useLocation();
  const navigate = useNavigate();

  // State variables
  const [visibleSubItems, setVisibleSubItems] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [openSubmenu, setOpenSubmenu] = useState(() => {
    const savedSubmenu = localStorage.getItem('openSubmenu');
    return savedSubmenu || null;
  });
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const { logout } = useContext(AuthContext);
  const [avatarUrl, setAvatarUrl] = useState('');
  const [isAvatarLoading, setIsAvatarLoading] = useState(true);
  const [isFocusModeActive, setIsFocusModeActive] = useState(false);
  
  // Refs for DOM elements
  const dropdownRef = useRef(null);
  const userMenuButtonRef = useRef(null);
  const sidebarRef = useRef(null);
  const subMenuRefs = useRef({});

  // Add this new state
  const [showOverlaySidebar, setShowOverlaySidebar] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupPosition, setPopupPosition] = useState(0);
  const popupTimeoutRef = useRef(null);
  
  // Add this new state to prevent immediate re-opening of the sidebar
  const [isOverlaySidebarClosing, setIsOverlaySidebarClosing] = useState(false);
  
  // Move UserAvatar component definition here, before it's used
  const UserAvatar = ({ user, avatarUrl, isLoading, isDarkMode }) => {
    const avatarClasses = "w-5 h-5 rounded-full flex items-center justify-center overflow-hidden";
    
    if (isLoading) {
      return (
        <div className={`${avatarClasses} ${isDarkMode ? 'bg-slate-700' : 'bg-slate-300'}`}>
          <span className="text-[8px]">...</span>
        </div>
      );
    }

    if (avatarUrl) {
      return (
        <div className={avatarClasses}>
          <img src={avatarUrl} alt={`${user.firstName} ${user.lastName}`} className="w-full h-full object-cover" />
        </div>
      );
    }

    const initials = user?.firstName && user?.lastName 
      ? `${user.firstName[0]}${user.lastName[0]}` 
      : user?.firstName?.[0] || 'U';

    return (
      <div className={`${avatarClasses} ${isDarkMode ? 'bg-slate-700' : 'bg-slate-500'} text-white text-[7px] font-medium`}>
        <span>{initials.toUpperCase()}</span>
      </div>
    );
  };

  // Function to determine button classes based on active state
  const getButtonClasses = (screen, isSubItem = false) => {
    const baseClasses = "w-full text-left py-2 px-2.5 flex items-center rounded-md text-sm transition-all duration-300 ease-in-out";
    const activeClasses = isDarkMode 
      ? 'bg-slate-700 text-slate-100 font-medium' 
      : 'bg-slate-200 text-slate-900 font-medium';
    const inactiveClasses = isDarkMode
      ? 'text-slate-400 hover:bg-slate-800'
      : 'text-slate-600 hover:bg-slate-100';
    
    const isActive = isSubItem
      ? location.pathname === `/${screen.toLowerCase().replace(/\s+/g, '-')}`
      : location.pathname.startsWith(`/${screen.toLowerCase().replace(/\s+/g, '-')}`);
    
    return `${baseClasses} ${isActive ? activeClasses : inactiveClasses}`;
  };

  // Update the handleClick function
  const handleClick = (name, path) => {
    if (path === '/schedule') {
      navigate('/schedule');
    } else {
      setCurrentScreen(name);
      if (path) {
        if (path === '/timer') {
          onTimerClick();
        } else {
          navigate(path);
        }
      }
      
      // Close sidebar on mobile devices after selection
      if (window.innerWidth <= 640) setIsSidebarCollapsed(true);

      // Smoothly close the overlay sidebar when an item is clicked
      if (showOverlaySidebar) {
        setShowOverlaySidebar(false);
        setIsOverlaySidebarClosing(true);
        setTimeout(() => setIsOverlaySidebarClosing(false), 300); // Match this with the exit animation duration
      }
    }
  };

  // Toggle user menu
  const toggleUserMenu = () => setIsUserMenuOpen(!isUserMenuOpen);

  // Effect for handling clicks outside the user menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) &&
          !userMenuButtonRef.current.contains(event.target)) {
        setIsUserMenuOpen(false);
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') setIsUserMenuOpen(false);
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  const refreshAvatarUrlIfNeeded = useCallback(async () => {
    if (user && user.avatar && avatarUrl) {
      if (isUrlExpired(avatarUrl)) {
        try {
          const newSignedUrl = await refreshAvatarUrl();
          setAvatarUrl(newSignedUrl);
          localStorage.setItem(`user_avatar_${user.id}`, JSON.stringify({
            url: newSignedUrl,
            timestamp: Date.now()
          }));
        } catch (error) {
          console.error('Error refreshing avatar URL:', error);
        }
      }
    }
  }, [user, avatarUrl]);

  // Optimize avatar loading logic
  useEffect(() => {
    const fetchUserAvatar = async () => {
      setIsAvatarLoading(true);
      if (user && user.avatar) {
        // Check if the avatar is a full URL (LinkedIn profile picture)
        if (user.avatar.startsWith('http')) {
          setAvatarUrl(user.avatar);
          setIsAvatarLoading(false);
          return;
        }

        const cachedAvatar = localStorage.getItem(`user_avatar_${user.id}`);
        if (cachedAvatar) {
          const { url, timestamp } = JSON.parse(cachedAvatar);
          if (Date.now() - timestamp < AVATAR_CACHE_DURATION && !isUrlExpired(url)) {
            setAvatarUrl(url);
            setIsAvatarLoading(false);
            return;
          }
        }

        try {
          const signedUrl = await fetchAvatarUrl();
          setAvatarUrl(signedUrl);
          localStorage.setItem(`user_avatar_${user.id}`, JSON.stringify({
            url: signedUrl,
            timestamp: Date.now()
          }));
        } catch (error) {
          console.error('Error fetching user avatar URL:', error);
          setAvatarUrl(DEFAULT_AVATAR);
        }
      } else {
        setAvatarUrl(DEFAULT_AVATAR);
      }
      setIsAvatarLoading(false);
    };

    fetchUserAvatar();

    // Set up an interval to check and refresh the avatar URL every 5 minutes
    const refreshInterval = setInterval(refreshAvatarUrlIfNeeded, 5 * 60 * 1000);

    return () => clearInterval(refreshInterval);
  }, [user, refreshAvatarUrlIfNeeded]);

  // Memoize the UserAvatar component
  const MemoizedUserAvatar = useMemo(() => {
    return (
      <UserAvatar
        user={user}
        avatarUrl={avatarUrl}
        isLoading={isAvatarLoading}
        isDarkMode={isDarkMode}
      />
    );
  }, [user, avatarUrl, isAvatarLoading, isDarkMode]);

  useEffect(() => {
    Object.keys(subMenuRefs.current).forEach(key => {
      const element = subMenuRefs.current[key];
      if (element) {
        if (isExpanded(key)) {
          element.style.maxHeight = `${element.scrollHeight}px`;
        } else {
          element.style.maxHeight = '0px';
        }
      }
    });
  }, [expandedItems, isSidebarCollapsed]);

  // Update the toggleSidebar function
  const toggleSidebar = async () => {
    if (!isFocusMode) {
      const newState = !isSidebarCollapsed;
      setIsSidebarCollapsed(newState);
      localStorage.setItem('sidebarCollapsed', JSON.stringify(newState));

      if (user) {
        try {
          await fetch('/api/user-preferences', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ sidebarCollapsed: newState }),
          });
        } catch (error) {
          console.error('Failed to save sidebar state:', error);
        }
      }
    }
  };

  // Helper function to get user's first name
  const getFirstName = (user) => {
    if (!user) return 'Guest';
    return user.firstName || user.name?.split(' ')[0] || 'Guest';
  };

  // Add this to your existing styles
  const sidebarStyles = `
    .sidebar-section:not(:last-child) {
      border-bottom: 1px solid ${isDarkMode ? '#333333' : 'rgba(0, 0, 0, 0.1)'};
      margin-bottom: 0.5rem;
      padding-bottom: 0.5rem;
    }
    
    .sidebar-section > div {
      position: relative;
    }
    
    .sidebar-section > div::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 1px;
      background-color: ${isDarkMode ? '#333333' : 'rgba(0, 0, 0, 0.1)'};
    }
    
    .sidebar-section > div > div:last-child::before {
      content: '';
      position: absolute;
      bottom: 50%;
      left: -8px;
      width: 8px;
      height: 1px;
      background-color: ${isDarkMode ? '#333333' : 'rgba(0, 0, 0, 0.1)'};
    }
    
    .sidebar-tree-item {
      transition: all 0.3s ease-in-out;
    }

    .sidebar-tree-item:hover {
      background-color: ${isDarkMode ? '#1E1E1E' : 'rgba(0, 0, 0, 0.05)'};
    }

    .sidebar-tree-item.active {
      background: ${isDarkMode 
        ? 'linear-gradient(to right, #2A2A2A, #1E1E1E)' 
        : 'rgba(0, 0, 0, 0.1)'
      };
      font-weight: 600;
      ${isDarkMode ? 'box-shadow: inset 0 0 0 1px #333333;' : ''}
    }

    .sidebar-tree-item-icon {
      transition: transform 0.2s ease-in-out;
    }

    .sidebar-tree-item.open > .sidebar-tree-item-icon {
      transform: rotate(90deg);
    }

    .collapsed-sidebar-section {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @keyframes sparkle {
      0% { background-position: 0% 50%; }
      50% { background-position: 100% 50%; }
      100% { background-position: 0% 50%; }
    }

    .sparkle-border {
      position: relative;
      overflow: hidden;
    }

    .sparkle-border::before {
      content: '';
      position: absolute;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      background: linear-gradient(45deg, #ff00ee, #00ffff, #00ff00, #ffff00, #ff00ee);
      background-size: 400% 400%;
      z-index: -1;
      filter: blur(3px);
      animation: sparkle 3s ease infinite;
    }
  `;

  // Add this inside your component, before the return statement
  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.textContent = sidebarStyles;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, [isDarkMode]);

  const handleFocusModeToggle = () => {
    onFocusModeToggle(!isFocusMode);
  };

  // Update handleMouseMove function
  const handleMouseMove = useCallback((e) => {
    if (isFocusMode && e.clientX <= 10 && !isOverlaySidebarClosing) {
      setShowOverlaySidebar(true);
    }
  }, [isFocusMode, isOverlaySidebarClosing]);

  // Update useEffect for mousemove event
  useEffect(() => {
    if (isFocusMode) {
      document.addEventListener('mousemove', handleMouseMove);
    } else {
      setShowOverlaySidebar(false);
    }
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isFocusMode, handleMouseMove]);

  // Add this state in the Sidebar component
  const [showPricingModal, setShowPricingModal] = useState(false);

  // Update the SidebarContent component to include the upgrade button
  const SidebarContent = ({ isOverlay = false }) => {
    const [isOverlayUserMenuOpen, setIsOverlayUserMenuOpen] = useState(false);
    const overlayUserMenuRef = useRef(null);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (overlayUserMenuRef.current && !overlayUserMenuRef.current.contains(event.target)) {
          setIsOverlayUserMenuOpen(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    // Use regular menu for focus mode overlay
    const effectiveSidebarCollapsed = isOverlay ? false : isSidebarCollapsed;

    return (
      <div className="flex flex-col h-full">
        {/* Top bar */}
        <div 
          className={`w-full h-10 flex items-center justify-between px-2 border-b cursor-pointer ${
            isDarkMode 
              ? 'border-[#333333] bg-[#121212] text-gray-200 hover:bg-[#1E1E1E]' 
              : 'border-slate-200 bg-slate-50 text-slate-800 hover:bg-slate-100'
          }`}
          onClick={isOverlay ? null : toggleSidebar}
        >
          {!effectiveSidebarCollapsed && (
            <div className="flex items-center overflow-hidden">
              <h1 className="ml-2 text-[11px] font-bold whitespace-nowrap overflow-hidden text-ellipsis ${isDarkMode ? 'text-slate-200' : 'text-slate-800'}">
                Ammmplify
              </h1>
            </div>
          )}
          {!isOverlay && (
            <img
              src={SidebarFlipIcon}
              alt="Toggle Sidebar"
              className={`w-4 h-4 transition-transform duration-300 ${
                effectiveSidebarCollapsed ? '' : 'transform rotate-180'
              } ${isDarkMode ? 'invert' : ''} ${
                effectiveSidebarCollapsed ? 'mx-auto' : ''
              }`}
            />
          )}
        </div>
        
        {/* Update search bar container padding */}
        <div className="pt-2">
          <MinimalSearchBar 
            isDarkMode={isDarkMode} 
            isSidebarCollapsed={effectiveSidebarCollapsed} 
            className={`${
              isDarkMode 
                ? 'bg-[#1E1E1E] border-transparent text-gray-400' // Dark mode background
                : 'bg-slate-50 border border-transparent text-gray-500' // Light mode background
            }`}
          />
        </div>
        
        {/* Scrollable content area */}
        <nav className={`flex-grow overflow-y-auto custom-scrollbar-overlay ${
          isOverlay ? 'py-4 px-3' : (effectiveSidebarCollapsed ? 'py-1 px-1' : 'py-4 px-3')
        }`}>
          <TreeView
            items={menuItems}
            isSidebarCollapsed={effectiveSidebarCollapsed}
            isDarkMode={isDarkMode}
            handleClick={handleClick}
            location={location}
            isExpanded={isExpanded}
            toggleExpand={toggleExpand}
          />
        </nav>
        
        {/* Rest of the content */}
        <div className={`px-${isOverlay || !effectiveSidebarCollapsed ? '3' : '1'} mb-2`}>
          <UpgradeButton 
            isDarkMode={isDarkMode} 
            onClick={() => setShowPricingModal(true)}
            isSidebarCollapsed={effectiveSidebarCollapsed}
          />
        </div>
        
        {/* Focus Mode Button */}
        <FocusModeButton
          isDarkMode={isDarkMode}
          isSidebarCollapsed={effectiveSidebarCollapsed}
          isFocusMode={isFocusMode}
          onToggle={() => {
            handleFocusModeToggle();
            if (isOverlay) setShowOverlaySidebar(false);
          }}
          isOverlay={isOverlay}
        />
        
        {/* Bottom bar with user info */}
        <div className={`relative ${isDarkMode ? 'border-t border-[#333333]' : 'border-t border-slate-200'}`}>
          <button 
            ref={userMenuButtonRef}
            className={`w-full p-3 flex items-center ${
              isOverlay || !effectiveSidebarCollapsed ? 'justify-between' : 'justify-center'
            } ${
              isDarkMode 
                ? 'bg-[#121212] hover:bg-[#1E1E1E] text-gray-200' 
                : 'bg-slate-50 hover:bg-slate-100 text-slate-800'
            }`}
            onClick={() => isOverlay ? setIsOverlayUserMenuOpen(!isOverlayUserMenuOpen) : toggleUserMenu()}
            aria-haspopup="true"
            aria-expanded={isOverlay ? isOverlayUserMenuOpen : isUserMenuOpen}
          >
            {effectiveSidebarCollapsed && !isOverlay ? (
              <div className="flex items-center justify-center">
                {MemoizedUserAvatar}
              </div>
            ) : (
              <>
                <div className="flex items-center flex-grow">
                  {MemoizedUserAvatar}
                  <span className={`ml-2.5 text-xs font-medium truncate ${isDarkMode ? 'text-slate-200' : 'text-slate-700'}`}>
                    {user?.firstName} {user?.lastName}
                  </span>
                </div>
                <EllipsisHorizontalIcon className="w-4 h-4 flex-shrink-0" />
              </>
            )}
          </button>
          
          {/* Replace the existing overlay menu with the new component */}
          {isOverlay && (
            <FocusModeFooterMenuPopup
              menuRef={overlayUserMenuRef}
              isDarkMode={isDarkMode}
              toggleDarkMode={toggleDarkMode}
              logout={logout}
              onClose={() => setIsOverlayUserMenuOpen(false)}
              isOpen={isOverlayUserMenuOpen}
            />
          )}
        </div>
      </div>
    );
  };

  if (showPricingModal) {
    return createPortal(
      <PricingForGhost 
        onClose={() => setShowPricingModal(false)} 
        isDarkMode={isDarkMode}
      />,
      document.body
    );
  }

  return (
    <>
      <div 
        ref={sidebarRef}
        className={`h-full border flex flex-col overflow-hidden flex-shrink-0 transition-all duration-300 ease-in-out rounded-lg ${
          isFocusMode ? 'w-0 opacity-0' : (isSidebarCollapsed ? 'w-[3.5rem]' : 'w-48')
        } ${
          isDarkMode 
            ? 'bg-[#121212] text-gray-200 border-[#333333]' 
            : 'bg-slate-50 text-slate-800 border-slate-200'
        }`}
      >
        <SidebarContent />
      </div>
      <FocusModeSidebar
        showOverlaySidebar={showOverlaySidebar}
        setShowOverlaySidebar={setShowOverlaySidebar}
        setIsOverlaySidebarClosing={setIsOverlaySidebarClosing}
        isDarkMode={isDarkMode}
        isFocusMode={isFocusMode}
        handleFocusModeToggle={handleFocusModeToggle}
        SidebarContent={SidebarContent}
      />
      
      {/* User menu dropdown */}
      {isUserMenuOpen && (
        <SideBarFooterMenuPopup
          dropdownRef={dropdownRef}
          isDarkMode={isDarkMode}
          isSidebarCollapsed={isSidebarCollapsed}
          toggleDarkMode={toggleDarkMode}
          logout={logout}
        />
      )}
    </>
  );
};

export default Sidebar;
