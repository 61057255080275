import React, { useState, useRef, useEffect } from 'react';
import { EllipsisVerticalIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';

const style = document.createElement('style');
style.textContent = `
  @keyframes tag-glow {
    0%, 100% { box-shadow: 0 0 10px rgba(99, 102, 241, 0.2); }
    50% { box-shadow: 0 0 20px rgba(99, 102, 241, 0.4); }
  }
  .animate-tag-selected {
    animation: tag-glow 2s ease-in-out infinite;
  }
`;
document.head.appendChild(style);

const TagManagerMenu = ({ isOpen, onClose, onManageTags, isDarkMode }) => {
  if (!isOpen) return null;

  return (
    <div 
      className={`
        absolute right-0 mt-2 w-64 rounded-lg shadow-lg
        border backdrop-blur-sm
        ${isDarkMode 
          ? 'bg-[#1a1a1a]/95 text-slate-200 border-[#333333]' 
          : 'bg-white/95 text-gray-800 border-gray-200'
        } z-50 p-3
      `}
    >
      <button
        onClick={onManageTags}
        className={`
          block w-full text-left px-2 py-1.5 text-sm rounded-md
          transition-colors duration-200
          ${isDarkMode
            ? 'hover:bg-[#202020] text-slate-300'
            : 'hover:bg-gray-100 text-gray-700'}
        `}
      >
        Manage Tags
      </button>
    </div>
  );
};

const TagList = ({ tags, selectedTags, onTagSelect, isDarkMode, onManageTags, tagCounts }) => {
  const [isTagMenuOpen, setIsTagMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsTagMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const isSelected = (tag) => selectedTags[0] === tag;

  const getButtonClass = (tag) => `
    whitespace-nowrap px-3 py-1.5 rounded-full text-xs font-medium
    transition-all duration-200
    ${isSelected(tag)
      ? isDarkMode
        ? 'bg-indigo-600/20 text-indigo-300 border border-indigo-500/30 shadow-[0_2px_8px_0_rgba(99,102,241,0.1)]'
        : 'bg-indigo-100 text-indigo-600 border border-indigo-200 shadow-sm'
      : isDarkMode 
        ? 'bg-[#1a1a1a] text-slate-200 hover:bg-[#202020] border border-[#333333] hover:border-[#444444]' 
        : 'bg-gray-100 text-gray-700 hover:bg-gray-200 border border-gray-200/50 hover:border-gray-300/50'
    }
  `;

  const handleTagSelect = (tag) => {
    onTagSelect([tag]);
  };

  return (
    <div className="mb-4">
      <div className="flex items-center justify-between w-full pb-2">
        <div className="flex-1 overflow-x-auto custom-scrollbar">
          <div className="flex flex-nowrap space-x-2 py-1">
            <button
              onClick={() => handleTagSelect('All')}
              className={`${getButtonClass('All')} transform`}
            >
              All {tagCounts['All'] !== undefined && `(${tagCounts['All']})`}
            </button>
            {tags.map((tag) => (
              <button
                key={tag}
                onClick={() => handleTagSelect(tag)}
                className={`${getButtonClass(tag)} transform`}
              >
                {tag} {tagCounts[tag] !== undefined && `(${tagCounts[tag]})`}
              </button>
            ))}
          </div>
        </div>
        
        <div className="relative inline-block flex-shrink-0 ml-4" ref={menuRef}>
          <button
            onClick={() => setIsTagMenuOpen(!isTagMenuOpen)}
            className={`
              p-1.5 rounded-lg transition-colors duration-200
              ${isDarkMode 
                ? 'text-slate-400 hover:bg-[#1e1e1e] hover:text-slate-300' 
                : 'text-gray-600 hover:bg-gray-100 hover:text-gray-700'
              }
            `}
            aria-label="Tag options"
          >
            <EllipsisVerticalIcon className="h-5 w-5" />
          </button>
          <TagManagerMenu
            isOpen={isTagMenuOpen}
            onClose={() => setIsTagMenuOpen(false)}
            onManageTags={() => {
              onManageTags();
              setIsTagMenuOpen(false);
            }}
            isDarkMode={isDarkMode}
          />
        </div>
      </div>
    </div>
  );
};

export default TagList;
