import React from 'react';
import ReactDOM from 'react-dom';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';

const DownloadOptionsPortal = ({ 
  isOpen, 
  onClose, 
  onDownload, 
  isDarkMode, 
  buttonPosition 
}) => {
  if (!isOpen || !buttonPosition) return null;

  const calculatePosition = () => {
    if (!buttonPosition) return {};

    const DROPDOWN_WIDTH = 160;
    const DROPDOWN_HEIGHT = 144; // Approximate height of dropdown
    const WINDOW_PADDING = 16;

    let left = buttonPosition.right - DROPDOWN_WIDTH;
    let top = buttonPosition.bottom + 8; // 8px gap from button

    // Check right edge of screen
    if (left + DROPDOWN_WIDTH + WINDOW_PADDING > window.innerWidth) {
      left = window.innerWidth - DROPDOWN_WIDTH - WINDOW_PADDING;
    }

    // Check left edge of screen
    if (left < WINDOW_PADDING) {
      left = WINDOW_PADDING;
    }

    // Check bottom edge of screen
    if (top + DROPDOWN_HEIGHT + WINDOW_PADDING > window.innerHeight) {
      top = buttonPosition.top - DROPDOWN_HEIGHT - 8; // Position above button
    }

    return { left: `${left}px`, top: `${top}px` };
  };

  const positionStyle = calculatePosition();

  const handleOptionClick = (format) => (e) => {
    e.stopPropagation(); // Prevent event from bubbling
    onDownload(format);
    // Delay closing to ensure the download starts
    setTimeout(() => {
      onClose();
    }, 100);
  };

  return ReactDOM.createPortal(
    <div 
      className="fixed inset-0 z-50"
      onClick={(e) => {
        e.stopPropagation();
        onClose();
      }}
    >
      <div 
        style={{ position: 'fixed', ...positionStyle }}
        className={`py-2 w-48 rounded-md shadow-xl
                   ${isDarkMode ? 'bg-slate-700 text-slate-200' : 'bg-white text-gray-700'}
                   border ${isDarkMode ? 'border-slate-600' : 'border-gray-200'}`}
        onClick={e => e.stopPropagation()}
      >
        {['PDF', 'JSON', 'TXT'].map((format) => (
          <button
            key={format}
            className={`block px-4 py-2 text-sm w-full text-left transition-colors duration-150
                      ${isDarkMode 
                        ? 'hover:bg-slate-600' 
                        : 'hover:bg-gray-100'}
                      ${isDarkMode 
                        ? 'text-slate-200' 
                        : 'text-gray-700'}`}
            onClick={handleOptionClick(format.toLowerCase())}
          >
            <div className="flex items-center">
              <ArrowDownTrayIcon className="w-4 h-4 mr-2" />
              <span>Download as {format}</span>
            </div>
          </button>
        ))}
      </div>
    </div>,
    document.body
  );
};

export default DownloadOptionsPortal; 