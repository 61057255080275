import React from 'react';
import { Link } from 'react-router-dom';
import { motion, useScroll, useTransform } from 'framer-motion';
import { useTheme } from '../../../context/ThemeContext';
import useScreenSize from '../../../hooks/useScreenSize';
import { 
  PlayIcon,
  ArrowDownIcon 
} from '@heroicons/react/24/outline';
import EarlyAccessButton from '../../EarlyAccessButton';
import heroVideoThumbnailLight from '../../assets/hero-video-thumbnail-light.webp';
import heroVideoThumbnailDark from '../../assets/hero-video-thumbnail-dark.webp';

const HeroSection = () => {
  const { isDarkMode } = useTheme();
  const { isMobile } = useScreenSize();
  const { scrollY } = useScroll();
  const opacity = useTransform(scrollY, [0, 200], [1, 0]);
  const y = useTransform(scrollY, [0, 200], [0, 50]);

  const MobileHero = () => (
    <div className={`
      relative min-h-[calc(100vh-4rem)] 
      flex flex-col items-center justify-center 
      px-4 py-8
      ${isDarkMode ? 'bg-[#121212]/0' : 'bg-white/0'}
    `}>
      {/* Mobile Content */}
      <div className="relative w-full flex flex-col items-center justify-center">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-8"
        >
          <h1 className={`
            text-4xl
            font-bold 
            tracking-tight 
            leading-tight 
            mb-6
            ${isDarkMode ? 'text-white' : 'text-gray-900'}
          `}>
            Your Ideas,
            <br />
            <span className={`
              ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}
            `}>
              Amplified by AI
            </span>
          </h1>
          <p className={`
            text-sm
            max-w-md
            mx-auto
            ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
          `}>
          Turn your thoughts, notes, and ideas into authentic content that resonates with your target audience, powered by AI that's optimized to understands your unique brand voice and context.
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.1 }}
          className="relative w-full rounded-lg overflow-hidden mb-8"
        >
          <img
            src={isDarkMode ? heroVideoThumbnailDark : heroVideoThumbnailLight}
            alt="Product demo preview"
            className="w-full h-auto"
          />
          <div className="absolute inset-0 flex items-center justify-center">
            <div className={`
              w-14 h-14 rounded-full
              flex items-center justify-center
              backdrop-blur-sm
              ${isDarkMode
                ? 'bg-white/10 text-white/90 hover:bg-white/20'
                : 'bg-black/10 text-black/90 hover:bg-black/20'
              }
              transition-colors
              duration-200
            `}>
              <PlayIcon className="h-8 w-8" />
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <EarlyAccessButton isDarkMode={isDarkMode} className="w-full flex justify-center" />
        </motion.div>
      </div>

      {/* Mobile Scroll Indicator */}
      <motion.div 
        style={{ opacity }} 
        className="absolute bottom-4 left-1/2 transform -translate-x-1/2"
      >
        <Link
          to="#features-section"
          onClick={(e) => {
            e.preventDefault();
            document.getElementById('features-section')?.scrollIntoView({ 
              behavior: 'smooth',
              block: 'start'
            });
          }}
          className={`
            flex flex-col items-center gap-2
            ${isDarkMode ? 'text-slate-500' : 'text-slate-400'}
          `}
        >
          <ArrowDownIcon className="h-4 w-4" />
          <span className="text-xs uppercase tracking-widest font-light">Scroll</span>
        </Link>
      </motion.div>
    </div>
  );

  const DesktopHero = () => (
    <div 
      id="hero-section"
      className={`
        relative min-h-screen 
        flex flex-col items-center justify-start 
        px-4 sm:px-6 lg:px-8 
        pt-24 sm:pt-32 pb-12 sm:pb-16 
        scroll-mt-24
        ${isDarkMode ? 'bg-[#121212]/0' : 'bg-white/0'}
      `}
    >
      <motion.div 
        style={{ y }}
        className="relative w-full max-w-6xl mx-auto flex flex-col items-center text-center"
      >
        {/* Main Heading */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="mb-8 max-w-4xl"
        >
          <h1 className={`
            text-5xl
            font-bold 
            tracking-tight 
            leading-[1.1] 
            mb-6
            ${isDarkMode ? 'text-white' : 'text-gray-900'}
          `}>
            Your Ideas, Amplified by AI
          </h1>
          <p className={`
            text-md
            max-w-3xl
            mx-auto
            ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
          `}>
          Turn your thoughts, notes, and ideas into authentic content that resonates with your target audience, powered by AI that's optimized to understands your unique brand voice and context.
          </p>
        </motion.div>

        {/* Preview Video */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.1 }}
          className="relative w-full max-w-5xl mx-auto mb-12"
        >
          <Link
            to="/early-access"
            className="block group"
          >
            <div className={`
              relative aspect-video rounded-xl overflow-hidden
              ${isDarkMode 
                ? 'bg-[#1a1a1a]' 
                : 'bg-slate-50'
              }
            `}>
              <img
                src={isDarkMode ? heroVideoThumbnailDark : heroVideoThumbnailLight}
                alt="Product demo preview"
                className="w-full h-full object-cover"
              />
              {/* Play Button */}
              <div className="absolute inset-0 flex items-center justify-center">
                <div className={`
                  w-20 h-20 rounded-full
                  flex items-center justify-center
                  backdrop-blur-sm
                  transition-colors
                  duration-200
                  ${isDarkMode
                    ? 'bg-white/10 text-white/90 hover:bg-white/20'
                    : 'bg-black/10 text-black/90 hover:bg-black/20'
                  }
                `}>
                  <PlayIcon className="h-10 w-10" />
                </div>
              </div>
            </div>
          </Link>
        </motion.div>
      </motion.div>

      {/* Scroll indicator */}
      <motion.div 
        style={{ opacity }} 
        className="absolute bottom-8 left-1/2 transform -translate-x-1/2"
      >
        <Link
          to="#features-section"
          onClick={(e) => {
            e.preventDefault();
            document.getElementById('features-section')?.scrollIntoView({ 
              behavior: 'smooth',
              block: 'start'
            });
          }}
          className={`
            flex flex-col items-center gap-2
            transition-colors
            duration-200
            ${isDarkMode 
              ? 'text-slate-500 hover:text-slate-400' 
              : 'text-slate-400 hover:text-slate-600'
            }
          `}
        >
          <ArrowDownIcon className="h-4 w-4" />
          <span className="text-xs uppercase tracking-widest font-light">Scroll</span>
        </Link>
      </motion.div>
    </div>
  );

  return isMobile ? <MobileHero /> : <DesktopHero />;
};

export default HeroSection; 