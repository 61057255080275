import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';

const NotificationsPopup = ({ notifications, onClose, isDarkMode }) => {
  return (
    <div 
      data-testid="notifications-popup"
      className={`fixed top-12 right-4 w-80 max-h-96 overflow-y-auto rounded-lg shadow-lg z-50 ${
        isDarkMode ? 'bg-slate-800 text-slate-200' : 'bg-white text-slate-800'
      }`}
    >
      <div className="flex justify-between items-center p-4 border-b border-gray-200">
        <h2 className="text-lg font-semibold">Notifications</h2>
        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
          <XMarkIcon className="h-5 w-5" />
        </button>
      </div>
      <div className="p-4">
        {notifications.length === 0 ? (
          <p>No new notifications</p>
        ) : (
          notifications.map((notification) => (
            <div key={notification.id} className="mb-4 pb-4 border-b border-gray-200 last:border-b-0">
              <p className="font-semibold">{notification.title}</p>
              <p className="text-sm">{notification.message}</p>
              <p className="text-xs text-gray-500 mt-1">{new Date(notification.timestamp).toLocaleString()}</p>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default NotificationsPopup;