'use client'

import React, { useState, useEffect, useCallback } from 'react';
import { UserPlusIcon, PencilIcon, TrashIcon, CpuChipIcon, MagnifyingGlassIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../context/ThemeContext';
import api from '../api';
import Modal from './Modal';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { motion, AnimatePresence } from 'framer-motion';

const NoResultsMessage = ({ searchTerm, onCreateClick, isDarkMode }) => (
  <div className={`text-center py-10 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`}>
    <p className="text-xl mb-4">No AI agents found for "{searchTerm}"</p>
    <button
      onClick={onCreateClick}
      className={`flex items-center px-4 py-2 rounded-md transition-colors duration-200 text-sm mx-auto
                  ${isDarkMode 
                    ? 'bg-slate-700 hover:bg-slate-600 text-slate-200' 
                    : 'bg-blue-500 hover:bg-blue-600 text-white'}`}
    >
      <UserPlusIcon className={`w-5 h-5 ${isDarkMode ? 'text-slate-400' : 'text-white'} mr-2`} />
      Create New AI Agent
    </button>
  </div>
);

const EmptyState = ({ isDarkMode, onCreateClick }) => (
  <motion.div 
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    className="flex flex-col items-center justify-center w-full max-w-md mx-auto px-4 text-center h-full"
  >
    {/* Animated Illustration */}
    <div className="relative w-24 h-24 mb-6">
      {/* Background circle with gradient */}
      <motion.div
        className={`absolute inset-0 rounded-full ${
          isDarkMode 
            ? 'bg-gradient-to-br from-slate-800/50 to-slate-900/50' 
            : 'bg-gradient-to-br from-slate-100 to-slate-200/50'
        }`}
        animate={{
          scale: [1, 1.05, 1],
        }}
        transition={{
          duration: 4,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />
      
      {/* CPU Chip icon with floating animation */}
      <motion.div
        className="absolute inset-0 flex items-center justify-center"
        animate={{
          y: [-2, 2, -2],
          rotate: [-5, 5, -5],
        }}
        transition={{
          y: { repeat: Infinity, duration: 2.5, ease: "easeInOut" },
          rotate: { repeat: Infinity, duration: 3, ease: "easeInOut" }
        }}
      >
        <CpuChipIcon className={`w-10 h-10 ${
          isDarkMode ? 'text-slate-400' : 'text-slate-600'
        }`} />
      </motion.div>
      
      {/* Small floating dots */}
      {[...Array(3)].map((_, i) => (
        <motion.div
          key={i}
          className={`absolute w-1.5 h-1.5 rounded-full ${
            isDarkMode ? 'bg-slate-600' : 'bg-slate-400'
          }`}
          style={{
            left: `${20 + i * 25}%`,
            top: '50%'
          }}
          animate={{
            y: [-8, 0, -8],
            opacity: [0.5, 1, 0.5],
          }}
          transition={{
            y: {
              repeat: Infinity,
              duration: 2,
              delay: i * 0.4,
              ease: "easeInOut"
            },
            opacity: {
              repeat: Infinity,
              duration: 2,
              delay: i * 0.4,
              ease: "easeInOut"
            }
          }}
        />
      ))}
    </div>

    {/* Text Content */}
    <h3 className={`text-lg font-medium mb-2 ${
      isDarkMode ? 'text-slate-200' : 'text-slate-800'
    }`}>
      No AI Agents Created Yet
    </h3>
    <p className={`text-sm mb-8 ${
      isDarkMode ? 'text-slate-400' : 'text-slate-600'
    }`}>
      Create your first AI agent to start generating personalized content.
    </p>

    {/* CTA Button */}
    <motion.button
      onClick={onCreateClick}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      className={`
        group relative overflow-hidden
        px-6 py-3 rounded-xl
        font-medium inline-flex items-center justify-center gap-2
        transition-all duration-300
        ${isDarkMode
          ? 'bg-gradient-to-r from-slate-800 to-slate-900 text-white hover:from-slate-700 hover:to-slate-800'
          : 'bg-gradient-to-r from-slate-800 to-slate-900 text-white hover:from-slate-700 hover:to-slate-800'
        }
      `}
    >
      <UserPlusIcon className="w-5 h-5" />
      <span className="text-sm">Create AI Agent</span>
      <div className="absolute inset-0 overflow-hidden">
        <motion.div
          className="w-full h-full absolute inset-0 bg-gradient-to-r from-transparent via-white/10 to-transparent -skew-x-45"
          initial={{ x: '-100%' }}
          whileHover={{ x: '200%' }}
          transition={{ duration: 1.5, repeat: Infinity, ease: 'linear' }}
        />
      </div>
    </motion.button>
  </motion.div>
);

const AIAgentsListPopup = ({ isOpen, onClose, onSelectAgent }) => {
  const navigate = useNavigate();
  const [agents, setAgents] = useState([]);
  const [isInitialFetchDone, setIsInitialFetchDone] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [agentToDelete, setAgentToDelete] = useState(null);
  const { isDarkMode } = useTheme();
  const [filteredAgents, setFilteredAgents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const isMobile = useMediaQuery({ maxWidth: 639 });
  const isTablet = useMediaQuery({ minWidth: 640, maxWidth: 1023 });

  const fetchAgents = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get('/api/ai-agents');
      setAgents(response.data);
    } catch (error) {
      console.error('Error fetching AI agents:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!isInitialFetchDone) {
      fetchAgents().then(() => setIsInitialFetchDone(true));
    }
  }, [fetchAgents, isInitialFetchDone]);

  const handleEdit = (agentId) => {
    navigate(`/agents/edit/${agentId}`);
    onClose(); // Close the popup after navigating
  };

  const handleDeleteClick = (agent) => {
    setAgentToDelete(agent);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    if (!agentToDelete || !agentToDelete._id) {
      console.error('No agent to delete or invalid agent ID');
      return;
    }

    try {
      console.log('Deleting agent with ID:', agentToDelete._id);
      
      const response = await api.delete(`/api/ai-agents/${agentToDelete._id}`);
      console.log('Delete response:', response.data);

      // Update the local state by filtering out the deleted agent
      setAgents(prevAgents => prevAgents.filter(agent => agent._id !== agentToDelete._id));
      setFilteredAgents(prevFiltered => prevFiltered.filter(agent => agent._id !== agentToDelete._id));

      // Close the modal and reset the agentToDelete
      setShowDeleteModal(false);
      setAgentToDelete(null);

      // Show success notification
      if (window.addNotification) {
        window.addNotification('AI Agent deleted successfully', 'success');
      }
    } catch (error) {
      console.error('Error deleting AI agent:', error);
      console.error('Error details:', error.response?.data);
      
      // Show appropriate error message
      const errorMessage = error.response?.data?.error || 
        (error.response?.status === 404 
          ? 'AI Agent not found or already deleted' 
          : 'Failed to delete AI agent. Please try again.');
      
      if (window.addNotification) {
        window.addNotification(errorMessage, 'error');
      }

      // Close the modal if the agent doesn't exist anymore
      if (error.response?.status === 404) {
        setShowDeleteModal(false);
        setAgentToDelete(null);
        // Refresh the agents list to ensure UI is in sync
        fetchAgents();
      }
    }
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
    setAgentToDelete(null);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (agents.length > 0) {
      const lowercasedFilter = searchTerm.toLowerCase();
      const filtered = agents.filter(agent =>
        agent.name.toLowerCase().includes(lowercasedFilter) ||
        agent.description.toLowerCase().includes(lowercasedFilter)
      );
      setFilteredAgents(filtered);
    }
  }, [searchTerm, agents]);

  const handleCreateAgent = () => {
    navigate('/agents/create');
    onClose();
  };

  const handleAgentSelect = (agent) => {
    onSelectAgent(agent);
    onClose();
  };

  return (
    <div className={`fixed inset-0 z-50 flex items-center justify-center ${isDarkMode ? 'bg-black/50' : 'bg-black/30'}`}>
      <div className={`
        ${isDarkMode ? 'bg-[#121212]' : 'bg-white'} 
        w-full h-full 
        flex flex-col 
        overflow-hidden
      `}>
        {/* Header */}
        <div className={`
          ${isDarkMode ? 'bg-[#151515] border-[#333333]' : 'bg-white border-gray-200'} 
          !h-10 min-h-10 flex-shrink-0 flex items-center justify-between px-4 py-2 w-full 
          border-b
        `}>
          <div className="flex items-center">
            <CpuChipIcon className={`h-5 w-5 mr-2 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
            <h2 className={`text-sm font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>
              AI Agents
            </h2>
          </div>
          <button 
            onClick={onClose}
            className={`p-1 rounded-full transition-colors duration-200 ${isDarkMode ? 'hover:bg-[#1e1e1e]' : 'hover:bg-gray-100'}`}
            title="Back"
          >
            <ArrowLeftIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
          </button>
        </div>

        {/* Content */}
        <div className="flex-1 overflow-y-auto p-4">
          {isLoading ? (
            <div className="animate-pulse space-y-4">
              {[...Array(5)].map((_, index) => (
                <div key={index} className={`flex items-start p-3 rounded-md ${isDarkMode ? 'bg-[#1e1e1e]' : 'bg-gray-100'}`}>
                  <div className={`w-5 h-5 mr-3 rounded ${isDarkMode ? 'bg-[#2a2a2a]' : 'bg-gray-300'}`}></div>
                  <div className="flex-1">
                    <div className={`h-4 ${isDarkMode ? 'bg-[#2a2a2a]' : 'bg-gray-300'} rounded w-3/4 mb-2`}></div>
                    <div className={`h-3 ${isDarkMode ? 'bg-[#2a2a2a]' : 'bg-gray-300'} rounded w-1/2`}></div>
                  </div>
                </div>
              ))}
            </div>
          ) : filteredAgents.length > 0 ? (
            filteredAgents.map((agent) => (
              <button
                key={agent._id}
                onClick={() => handleAgentSelect(agent)}
                className={`w-full text-left p-3 rounded-md mb-2 flex items-center transition-colors duration-200 ${
                  isDarkMode ? 'text-slate-200 hover:bg-[#1e1e1e]' : 'text-slate-800 hover:bg-gray-100'
                }`}
              >
                <CpuChipIcon className={`w-5 h-5 mr-3 flex-shrink-0 ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`} />
                <div className="flex-grow min-w-0 mr-2">
                  <h3 className={`font-medium truncate ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>{agent.name}</h3>
                  <p className={`text-sm truncate ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>{agent.description}</p>
                </div>
                <div className="flex space-x-1">
                  <button
                    onClick={(e) => { e.stopPropagation(); handleEdit(agent._id); }}
                    className={`p-1 rounded-md ${isDarkMode ? 'bg-[#1e1e1e] hover:bg-[#2a2a2a]' : 'bg-gray-100 hover:bg-gray-200'}`}
                    title="Edit AI Agent"
                  >
                    <PencilIcon className={`w-4 h-4 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
                  </button>
                  <button
                    onClick={(e) => { e.stopPropagation(); handleDeleteClick(agent); }}
                    className={`p-1 rounded-md ${isDarkMode ? 'bg-[#1e1e1e] hover:bg-[#2a2a2a]' : 'bg-gray-100 hover:bg-gray-200'}`}
                    title="Delete AI Agent"
                  >
                    <TrashIcon className={`w-4 h-4 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
                  </button>
                </div>
              </button>
            ))
          ) : searchTerm ? (
            <NoResultsMessage 
              searchTerm={searchTerm}
              onCreateClick={handleCreateAgent}
              isDarkMode={isDarkMode}
            />
          ) : (
            <EmptyState 
              isDarkMode={isDarkMode}
              onCreateClick={handleCreateAgent}
            />
          )}
        </div>

        {/* Footer */}
        <div className={`p-4 ${isDarkMode ? 'bg-[#151515] border-[#333333]' : 'bg-white border-gray-200'} border-t`}>
          <div className="flex items-center justify-between">
            <span className={`text-sm ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
              {filteredAgents.length} agent{filteredAgents.length !== 1 ? 's' : ''}
            </span>
            <button
              onClick={handleCreateAgent}
              className={`
                px-3 py-1.5 rounded-md text-xs font-medium
                transition-colors duration-200 flex items-center gap-1.5
                ${isDarkMode
                  ? 'bg-[#1e1e1e] hover:bg-[#2a2a2a] text-blue-400 border border-[#333333]'
                  : 'bg-black hover:bg-gray-900 text-white border border-black'
                }
              `}
            >
              <UserPlusIcon className="h-4 w-4" />
              Create New Agent
            </button>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className={`
            fixed inset-0 flex items-center justify-center
            bg-black/50 backdrop-blur-sm z-50
          `}
          onClick={cancelDelete}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.95, y: 10 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.95, y: 10 }}
            transition={{ 
              duration: 0.2,
              ease: [0.23, 1, 0.32, 1]
            }}
            className={`
              w-[380px] rounded-xl shadow-lg
              ${isDarkMode
                ? 'bg-[#151515] border border-[#333333]'
                : 'bg-white border border-gray-200'
              }
              backdrop-blur-sm
              p-6
            `}
            onClick={e => e.stopPropagation()}
          >
            <div className="flex items-start space-x-4">
              {/* Icon */}
              <div className={`
                w-10 h-10 rounded-full flex-shrink-0
                flex items-center justify-center
                ${isDarkMode ? 'bg-red-500/10' : 'bg-red-50'}
              `}>
                <motion.div
                  initial={{ rotate: -90, scale: 0.6 }}
                  animate={{ rotate: 0, scale: 1 }}
                  transition={{ 
                    delay: 0.1,
                    duration: 0.4,
                    ease: [0.23, 1, 0.32, 1]
                  }}
                >
                  <TrashIcon className="w-5 h-5 text-red-500" />
                </motion.div>
              </div>

              {/* Content */}
              <div className="flex-1 space-y-4">
                <div>
                  <h3 className={`
                    text-base font-semibold mb-1
                    ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}
                  `}>
                    Delete AI Agent
                  </h3>
                  
                  <p className={`
                    text-sm
                    ${isDarkMode ? 'text-slate-300' : 'text-slate-600'}
                  `}>
                    Are you sure you want to delete this AI agent?
                    <span className={`
                      block mt-1 text-xs
                      ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}
                    `}>
                      This action cannot be undone.
                    </span>
                  </p>
                </div>

                {/* Buttons */}
                <div className="flex items-center justify-end gap-2">
                  <motion.button
                    whileHover={{ scale: 1.01 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={cancelDelete}
                    className={`
                      px-4 py-2 rounded-lg text-sm font-medium
                      transition-colors duration-200
                      ${isDarkMode
                        ? 'text-slate-300 hover:bg-[#1e1e1e]'
                        : 'text-slate-600 hover:bg-gray-100'
                      }
                      focus:outline-none
                    `}
                  >
                    Cancel
                  </motion.button>

                  <motion.button
                    whileHover={{ scale: 1.01 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={confirmDelete}
                    className={`
                      px-4 py-2 rounded-lg
                      font-medium text-sm text-white
                      bg-red-500 hover:bg-red-600
                      transition-colors duration-200
                      focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2
                      ${isDarkMode ? 'focus:ring-offset-[#151515]' : 'focus:ring-offset-white'}
                    `}
                  >
                    Delete
                  </motion.button>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </div>
  );
};

export default AIAgentsListPopup;