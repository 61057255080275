import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { 
  TrashIcon, 
  CheckCircleIcon,
  UserGroupIcon,
  CalendarIcon,
  DocumentTextIcon,
  DocumentDuplicateIcon,
  ChevronRightIcon,
  EyeIcon
} from '@heroicons/react/24/outline';

const ContentStrategyCard = ({ 
  strategy, 
  isDarkMode, 
  onView, 
  onDelete,
  isSelected,
  onSelect,
  selectionMode 
}) => {
  const [isHovered, setIsHovered] = useState(false);

  // Calculate statistics
  const totalIdeas = strategy.frameworks?.reduce((total, framework) => {
    return total + framework.ideaGroups.reduce((groupTotal, group) => {
      return groupTotal + (group.ideas?.length || 0);
    }, 0);
  }, 0) || 0;

  const totalContentTypes = strategy.frameworks?.reduce((total, framework) => {
    return total + framework.ideaGroups.length;
  }, 0) || 0;

  const handleClick = (e) => {
    if (selectionMode) {
      e.stopPropagation();
      onSelect(strategy._id);
    } else {
      onView(strategy._id);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      onClick={handleClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={`
        group relative flex flex-col
        rounded-xl border transition-all duration-200
        hover:-translate-y-1 hover:shadow-lg
        cursor-pointer
        ${isDarkMode 
          ? 'bg-[#121212] border-[#333333] hover:border-[#1e1e1e]' 
          : 'bg-white/90 border-slate-200 hover:border-slate-300'
        } 
        ${isSelected ? isDarkMode 
          ? 'ring-2 ring-blue-500/30' 
          : 'ring-2 ring-blue-500/50'
          : ''
        }
      `}
    >
      {/* Selection Indicator */}
      {selectionMode && (
        <div className="absolute top-3 right-3 z-20">
          <div className={`w-4 h-4 rounded-full border-2 transition-colors ${
            isSelected
              ? 'bg-blue-500 border-blue-500'
              : isDarkMode
                ? 'border-[#333333]'
                : 'border-slate-300'
          }`}>
            {isSelected && (
              <CheckCircleIcon className="w-3 h-3 text-white" />
            )}
          </div>
        </div>
      )}

      {/* Main Content */}
      <div className="p-4">
        {/* Header Section */}
        <div className="space-y-3">
          <div className="space-y-1.5">
            <h3 className={`font-medium line-clamp-1 pr-8 ${
              isDarkMode ? 'text-slate-200' : 'text-slate-900'
            }`}>
              {strategy.persona?.name || 'Unnamed Creator'}
            </h3>
            <div className="flex items-center gap-2 text-xs">
              <span className={`flex items-center gap-1 ${
                isDarkMode ? 'text-slate-400' : 'text-slate-500'
              }`}>
                <UserGroupIcon className="w-3.5 h-3.5" />
                <span className="line-clamp-1">
                  {strategy.audience?.targetAudienceName || 'General Audience'}
                </span>
              </span>
              <span className={`flex items-center gap-1 ${
                isDarkMode ? 'text-slate-400' : 'text-slate-400'
              }`}>
                <CalendarIcon className="w-3.5 h-3.5" />
                {new Date(strategy.createdAt).toLocaleDateString(undefined, { 
                  month: 'short', 
                  day: 'numeric'
                })}
              </span>
            </div>
          </div>

          {/* Stats Section */}
          <div className="flex items-center gap-4">
            <div className={`flex items-center gap-1.5 ${
              isDarkMode ? 'text-slate-400' : 'text-slate-500'
            }`}>
              <DocumentTextIcon className="w-3.5 h-3.5" />
              <span className="text-xs">{totalContentTypes} content types</span>
            </div>
            <div className={`flex items-center gap-1.5 ${
              isDarkMode ? 'text-slate-400' : 'text-slate-500'
            }`}>
              <DocumentDuplicateIcon className="w-3.5 h-3.5" />
              <span className="text-xs">{totalIdeas} ideas</span>
            </div>
          </div>
        </div>

        {/* Action Buttons */}
        <div className={`
          absolute right-2 top-1/2 -translate-y-1/2
          flex items-center gap-1
          transition-all duration-200
          ${isHovered ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-2'}
        `}>
          {!selectionMode && (
            <>
              {/* Delete Button */}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(strategy);
                }}
                className={`
                  p-2 rounded-full
                  transition-all duration-200
                  ${isDarkMode 
                    ? 'bg-[#1e1e1e] text-red-400 hover:text-red-300 hover:bg-[#1a1a1a]' 
                    : 'bg-white/90 text-red-500 hover:text-red-600 hover:bg-red-50'
                  }
                  shadow-sm backdrop-blur-sm
                `}
              >
                <TrashIcon className="w-4 h-4" />
              </button>

              {/* View Button */}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onView(strategy._id);
                }}
                className={`
                  p-2 rounded-full
                  transition-all duration-200
                  ${isDarkMode 
                    ? 'bg-[#1e1e1e] text-blue-400 hover:text-blue-300 hover:bg-[#1a1a1a]' 
                    : 'bg-white/90 text-blue-500 hover:text-blue-600 hover:bg-blue-50'
                  }
                  shadow-sm backdrop-blur-sm
                `}
              >
                <EyeIcon className="w-4 h-4" />
              </button>
            </>
          )}
        </div>

        {/* Hover Indicator */}
        {!selectionMode && (
          <div className={`
            absolute right-4 top-1/2 -translate-y-1/2
            transition-all duration-200
            ${isHovered ? 'opacity-0 translate-x-2' : 'opacity-100 translate-x-0'}
            ${isDarkMode ? 'text-[#333333]' : 'text-slate-300'}
          `}>
            <ChevronRightIcon className="w-5 h-5" />
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default ContentStrategyCard; 