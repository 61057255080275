import React from 'react';
import { XMarkIcon, CheckIcon, SparklesIcon } from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';

const PricingTier = ({ tier, isDarkMode, onSelect }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    className={`
      relative p-6 rounded-xl
      ${isDarkMode 
        ? 'bg-[#1a1a1a] border border-[#333333]' 
        : 'bg-white border border-slate-200'
      }
      ${tier.highlighted 
        ? 'ring-2 ring-purple-500 ring-offset-2 ring-offset-black' 
        : ''
      }
    `}
  >
    {tier.highlighted && (
      <div className={`
        absolute -top-4 left-1/2 transform -translate-x-1/2
        px-3 py-1 rounded-full text-xs font-medium
        ${isDarkMode 
          ? 'bg-purple-500/20 text-purple-300 border border-purple-500/30' 
          : 'bg-purple-100 text-purple-600'}
      `}>
        Most Popular
      </div>
    )}

    <div className="flex items-center gap-2 mb-4">
      <SparklesIcon className={`w-5 h-5 ${isDarkMode ? 'text-purple-400' : 'text-purple-500'}`} />
      <h3 className={`text-lg font-semibold ${isDarkMode ? 'text-white' : 'text-slate-900'}`}>
        {tier.name}
      </h3>
    </div>

    <div className="mb-4">
      <span className={`text-2xl font-bold ${isDarkMode ? 'text-white' : 'text-slate-900'}`}>
        ${tier.price}
      </span>
      <span className={`text-sm ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}`}>
        /month
      </span>
    </div>

    <ul className="space-y-3 mb-6">
      {tier.features.map((feature, index) => (
        <li key={index} className="flex items-start gap-2">
          <CheckIcon className={`w-5 h-5 flex-shrink-0 ${
            isDarkMode ? 'text-purple-400' : 'text-purple-500'
          }`} />
          <span className={`text-sm ${isDarkMode ? 'text-slate-300' : 'text-slate-600'}`}>
            {feature}
          </span>
        </li>
      ))}
    </ul>

    <button
      onClick={() => onSelect(tier)}
      className={`
        w-full py-2.5 px-4 rounded-lg
        text-sm font-medium
        transition-all duration-200
        ${tier.highlighted
          ? isDarkMode
            ? 'bg-purple-500 hover:bg-purple-600 text-white'
            : 'bg-purple-500 hover:bg-purple-600 text-white'
          : isDarkMode
            ? 'bg-[#222222] hover:bg-[#2a2a2a] text-white border border-[#333333]'
            : 'bg-slate-100 hover:bg-slate-200 text-slate-900'
        }
      `}
    >
      Get Started
    </button>
  </motion.div>
);

const PricingForGhost = ({ onClose, isDarkMode }) => {
  const pricingTiers = [
    {
      name: 'Creator',
      price: '29',
      features: [
        '1 User Profile',
        '2 Audience Profiles',
        'Basic Chat & Canvas',
        'Basic Content Generation',
        'Voice Notes (1 hr/mo)',
        'Reddit Research (20 searches/mo)',
        'GPT-4o Access',
        '200MB Context Storage',
        'Email Support',
        'Coming Soon: LinkedIn Publishing',
      ],
    },
    {
      name: 'Professional',
      price: '49',
      highlighted: true,
      features: [
        '3 User Profiles',
        '5 Audience Profiles',
        'Advanced Chat & Canvas',
        'Advanced Content Generation',
        'Voice Notes (3 hrs/mo)',
        'Reddit Research (Unlimited)',
        'GPT-4o & Claude 3.5 Access',
        '500MB Context Storage',
        'Priority Support',
        'Coming Soon: LinkedIn Publishing',
      ],
    },
    {
      name: 'Agency',
      price: '99',
      features: [
        'Unlimited User Profiles',
        'Unlimited Audience Profiles',
        'Premium Chat & Canvas',
        'Priority Content Generation',
        'Voice Notes (10 hrs/mo)',
        'Reddit Research (Unlimited)',
        'GPT-4o & Claude 3.5 Priority Access',
        '1GB Context Storage',
        'Priority Support + Strategy Call',
        'Coming Soon: LinkedIn Publishing',
      ],
    },
  ];

  const handleSelect = (tier) => {
    // This will be implemented when we add Stripe
    console.log('Selected tier:', tier);
  };

  return (
    <div 
      className="fixed inset-0 z-[100] overflow-y-auto"
      aria-labelledby="pricing-modal"
      role="dialog"
      aria-modal="true"
    >
      <div 
        className="fixed inset-0 bg-black/50 backdrop-blur-sm transition-opacity"
        aria-hidden="true"
        onClick={onClose}
      />

      <div className="flex min-h-screen items-center justify-center p-4">
        <div 
          className="relative w-full max-w-5xl transform transition-all"
          onClick={(e) => e.stopPropagation()}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ duration: 0.2 }}
            className={`
              relative rounded-xl shadow-2xl
              ${isDarkMode ? 'bg-[#121212]' : 'bg-slate-50'}
              p-6 sm:p-8
            `}
          >
            <button
              onClick={onClose}
              className={`
                absolute right-4 top-4
                p-2 rounded-lg
                transition-colors duration-200
                ${isDarkMode
                  ? 'hover:bg-white/10 text-slate-400'
                  : 'hover:bg-slate-200/50 text-slate-500'}
              `}
            >
              <XMarkIcon className="w-5 h-5" />
            </button>

            <div className="text-center mb-8">
              <motion.h2 
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.1 }}
                className={`text-2xl font-bold mb-2 ${
                  isDarkMode ? 'text-white' : 'text-slate-900'
                }`}
              >
                Upgrade Your Ghosts Experience
              </motion.h2>
              <motion.p 
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
                className={`text-sm ${
                  isDarkMode ? 'text-slate-400' : 'text-slate-600'
                }`}
              >
                Choose the perfect plan for your content creation needs
              </motion.p>
            </div>

            <div className="grid md:grid-cols-3 gap-6">
              {pricingTiers.map((tier, index) => (
                <motion.div
                  key={tier.name}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 + index * 0.1 }}
                >
                  <PricingTier
                    tier={tier}
                    isDarkMode={isDarkMode}
                    onSelect={handleSelect}
                  />
                </motion.div>
              ))}
            </div>

            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
              className={`text-xs text-center mt-8 ${
                isDarkMode ? 'text-slate-500' : 'text-slate-400'
              }`}
            >
              All plans include a 14-day free trial. No credit card required.
            </motion.p>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default PricingForGhost; 