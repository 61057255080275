import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import supabase from '../services/supabaseAuth';
import { toast } from 'react-hot-toast';
import api from '../api';

const AuthCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleAuthCallback = async () => {
      try {
        console.log('Handling auth callback...');
        
        // Get the current session
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        console.log('Current session:', session);
        
        if (sessionError) {
          console.error('Session error:', sessionError);
          throw sessionError;
        }

        // Get the current user
        const { data: { user }, error: userError } = await supabase.auth.getUser();
        console.log('Current user:', user);
        
        if (userError) {
          console.error('User error:', userError);
          throw userError;
        }

        if (user?.email_confirmed_at || user?.confirmed_at) {
          console.log('Email confirmed for user:', user.email);

          try {
            // Update MongoDB with verification status
            const response = await api.post('/api/auth/verify-email', {
              email: user.email,
              supabaseUid: user.id
            });

            console.log('MongoDB update response:', response.data);

            if (response.data.user?.emailVerified) {
              toast.success('Email verified successfully!');
              
              // Sign out after confirmation
              await supabase.auth.signOut();
              
              navigate('/login', {
                state: {
                  verificationSuccess: true,
                  message: 'Email verified successfully! Please log in.'
                }
              });
            } else {
              throw new Error('Verification status not updated in MongoDB');
            }
          } catch (mongoError) {
            console.error('Error updating MongoDB:', mongoError);
            console.error('Full error:', mongoError.response?.data || mongoError);
            
            toast.error('Failed to update verification status. Please try logging in again.');
            navigate('/login', {
              state: {
                verificationError: true,
                message: 'Verification status update failed. Please try logging in again.'
              }
            });
          }
        } else {
          console.log('Email not confirmed yet');
          toast.error('Email not verified yet. Please check your email and click the verification link.');
          navigate('/verification', {
            state: {
              email: user?.email,
              message: 'Please check your email for the verification link.'
            }
          });
        }
      } catch (error) {
        console.error('Verification error:', error);
        toast.error(error.message || 'Verification failed');
        navigate('/login', {
          state: {
            verificationError: true,
            message: error.message || 'Verification failed. Please try again.'
          }
        });
      }
    };

    handleAuthCallback();
  }, [navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="text-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
        <p className="mt-4 text-gray-600">Verifying your email...</p>
      </div>
    </div>
  );
};

export default AuthCallback; 