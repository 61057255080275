import React from 'react';
import { SparklesIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ArrowRightIcon } from '@heroicons/react/24/outline';

const EarlyAccessButton = ({ 
  isDarkMode,
  className = "",
  isMobile = false,
  onClick
}) => {
  // Button variants
  const buttonVariants = {
    tap: { 
      scale: 0.98,
      transition: {
        type: "spring",
        stiffness: 500,
        damping: 10
      }
    }
  };

  // Icon animation
  const iconVariants = {
    initial: { y: 0, rotate: 0 },
    hover: {
      y: [-1, 1, -1],
      rotate: [-5, 5, -5],
      transition: {
        y: {
          repeat: Infinity,
          duration: 2.5,
          ease: "easeInOut"
        },
        rotate: {
          repeat: Infinity,
          duration: 3,
          ease: "easeInOut"
        }
      }
    }
  };

  // Shimmer effect
  const shimmerVariants = {
    initial: { x: "-100%" },
    hover: {
      x: "200%",
      transition: {
        repeat: Infinity,
        duration: 2,
        ease: "linear"
      }
    }
  };

  return (
    <motion.div
      variants={buttonVariants}
      whileTap="tap"
      whileHover="hover"
      className={`inline-block ${className}`}
    >
      <Link
        to="/register"
        onClick={onClick}
        className={`
          group
          relative
          inline-flex items-center justify-center
          px-5 py-2
          rounded-full
          overflow-hidden
          text-sm font-medium
          transition-all duration-300
          ${isDarkMode 
            ? 'bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 text-gray-100 hover:bg-gradient-to-r hover:from-gray-800 hover:via-gray-700 hover:to-gray-800' 
            : 'bg-black text-white hover:bg-gray-900'
          }
          shadow-lg hover:shadow-xl
          ${isDarkMode && 'hover:shadow-blue-900/10'}
          border
          ${isDarkMode ? 'border-gray-700/50' : 'border-transparent'}
        `}
      >
        {/* Refined Shimmer effect */}
        <motion.div
          variants={shimmerVariants}
          className={`
            absolute inset-0 w-1/2 h-full transform -skew-x-12 
            ${isDarkMode 
              ? 'bg-gradient-to-r from-transparent via-white/[0.07] to-transparent'
              : 'bg-gradient-to-r from-transparent via-white/10 to-transparent'
            }
          `}
        />

        {/* Subtle hover glow effect */}
        <div className={`
          absolute inset-0 opacity-0 group-hover:opacity-100
          transition-opacity duration-300
          ${isDarkMode
            ? 'bg-[radial-gradient(circle_at_50%_50%,rgba(30,64,175,0.05),transparent_70%)]'
            : 'bg-[radial-gradient(circle_at_50%_50%,rgba(255,255,255,0.1),transparent_70%)]'
          }
        `} />

        {/* Button content with enhanced spacing */}
        <div className="flex items-center gap-2.5 relative z-10">
          <span className={`
            ${isDarkMode ? 'text-gray-100' : ''}
          `}>
            Sign Up for Beta
          </span>
          <ArrowRightIcon className={`
            h-4 w-4 transition-transform duration-300
            group-hover:translate-x-0.5
            ${isDarkMode ? 'text-gray-300' : ''}
          `} />
        </div>

        {/* Enhanced Focus ring */}
        <motion.div 
          className={`
            absolute inset-0 rounded-full ring-0 
            group-focus-visible:ring-2 
            ${isDarkMode 
              ? 'ring-gray-600 ring-offset-[#121212]' 
              : 'ring-slate-400/50 ring-offset-white'
            } 
            ring-offset-2
          `}
          initial={{ opacity: 0 }}
          whileFocus={{ opacity: 1 }}
          transition={{ duration: 0.2 }}
        />

        {/* Subtle background glow for dark mode */}
        {isDarkMode && (
          <div className="
            absolute inset-0 
            bg-gradient-to-r from-blue-900/5 via-blue-800/5 to-blue-900/5
            opacity-0 group-hover:opacity-100 
            blur-xl 
            transition-opacity duration-300
          "/>
        )}
      </Link>
    </motion.div>
  );
};

export default EarlyAccessButton; 