import React from 'react';
import { MicrophoneIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';

const VoiceNotesEmptyState = ({ type = 'no-notes', isDarkMode }) => {
  const states = {
    'no-notes': {
      icon: MicrophoneIcon,
      title: 'No voice notes yet',
      description: 'Start by recording your first voice note',
      hint: 'Click the microphone button below to begin'
    },
    'no-results': {
      icon: MagnifyingGlassIcon,
      title: 'No matching voice notes',
      description: "We couldn't find any voice notes matching your search",
      hint: 'Try adjusting your search terms or filters'
    }
  };

  const { icon: Icon, title, description, hint } = states[type];

  return (
    <div className="flex-grow flex items-center justify-center min-h-[calc(100vh-300px)]">
      <div className="text-center px-4">
        <div className={`
          inline-flex items-center justify-center w-16 h-16 rounded-full mb-6
          transition-colors duration-200
          ${isDarkMode ? 'bg-[#1a1a1a] border border-[#333333]' : 'bg-gray-100'}
        `}>
          <Icon className={`w-8 h-8 ${
            isDarkMode ? 'text-slate-400' : 'text-gray-500'
          }`} />
        </div>
        <h3 className={`
          text-xl font-semibold mb-2
          ${isDarkMode ? 'text-slate-200' : 'text-gray-800'}
        `}>
          {title}
        </h3>
        <p className={`
          text-sm mb-1
          ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}
        `}>
          {description}
        </p>
        <p className={`
          text-sm
          ${isDarkMode ? 'text-slate-500' : 'text-gray-500'}
        `}>
          {hint}
        </p>
      </div>
    </div>
  );
};

export default VoiceNotesEmptyState; 