import React from 'react';
import { useTheme } from '../context/ThemeContext';

const TemplateHover = ({ template }) => {
  const { isDarkMode } = useTheme();

  return (
    <div className={`absolute z-[9999] bottom-full left-1/2 transform -translate-x-1/2 mb-2 p-4 rounded-lg shadow-lg w-64 ${
      isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'
    }`}>
      <h3 className="font-bold mb-2">{template.title}</h3>
      <p className="text-sm mb-2 line-clamp-3">{template.content}</p>
      <div className="text-xs">
        <span className="mr-2">Category: {template.category}</span>
        <span>Tone: {template.tone}</span>
      </div>
    </div>
  );
};

export default TemplateHover;