import React, { useState, useEffect, useRef } from 'react'
import { CheckIcon, XMarkIcon, SparklesIcon } from '@heroicons/react/24/outline'
import axios from 'axios'
import { updateChatTitle, createNewChat } from '../api';
import { useTheme } from '../context/ThemeContext';
import { generateChatTitle } from '../services/openaiService';
import api from '../api';

const MAX_TITLE_LENGTH = 50

export default function EditableChatTitle({ 
  initialTitle, 
  onSave, 
  onChange, 
  chatId, 
  resetKey, 
  isNewChat, 
  createNewChat, 
  isGeneratingTitle, 
  setIsGeneratingTitle, 
  messages,
}) {
  const [isEditing, setIsEditing] = useState(false)
  const [title, setTitle] = useState(initialTitle || '')
  const [isSaving, setIsSaving] = useState(false)
  const [hasBeenEdited, setHasBeenEdited] = useState(initialTitle !== '' && initialTitle !== 'Untitled Chat')
  const inputRef = useRef(null)
  const [editingTitle, setEditingTitle] = useState(initialTitle || '')
  const { isDarkMode } = useTheme();

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus()
      inputRef.current.select()
    }
  }, [isEditing])

  useEffect(() => {
    setTitle(initialTitle || '')
    setEditingTitle(initialTitle || '')
    setIsEditing(false)
    setHasBeenEdited(initialTitle !== '' && initialTitle !== 'Untitled Chat')
  }, [initialTitle, resetKey])

  useEffect(() => {
    if (messages.length >= 3 && !hasBeenEdited && !isGeneratingTitle) {
      handleGenerateTitle();
    }
  }, [messages, hasBeenEdited, isGeneratingTitle]);

  const handleSave = async () => {
    if (editingTitle.trim() === '') {
      setIsEditing(false);
      setEditingTitle(title);
      return;
    }

    if (editingTitle === title) {
      setIsEditing(false);
      return;
    }

    setIsSaving(true);
    try {
      console.log('Saving chat title:', { chatId, title: editingTitle });
      
      let updatedChat;
      if (!chatId) {
        console.log('Creating new chat with title:', editingTitle);
        updatedChat = await createNewChat(editingTitle);
      } else {
        console.log('Updating existing chat title:', { chatId, title: editingTitle });
        updatedChat = await updateChatTitle(chatId, editingTitle);
      }

      console.log('Chat title updated successfully:', updatedChat);
      setIsEditing(false);
      setHasBeenEdited(true);
      setTitle(editingTitle);
      
      // Call onSave callback if provided
      if (onSave) {
        onSave(editingTitle);
      }
    } catch (error) {
      console.error('Failed to save chat title:', error);
      // Show error to user (you might want to add a toast notification here)
      setIsEditing(false);
      setEditingTitle(title);
    } finally {
      setIsSaving(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSave()
    } else if (e.key === 'Escape') {
      setIsEditing(false)
      setEditingTitle(title)
    }
  }

  const handleGenerateTitle = async (force = false) => {
    if (!force && (hasBeenEdited || isGeneratingTitle)) {
      return;
    }
    if (!messages || messages.length < 2) {
      return;
    }
    setIsGeneratingTitle(true);
    try {
      const generatedTitle = await generateChatTitle(messages.slice(0, 4));
      setTitle(generatedTitle);
      setEditingTitle(generatedTitle);
      setHasBeenEdited(true);
      if (chatId) {
        await api.put(`/api/chats/${chatId}/title`, { title: generatedTitle });
      }
      onChange(generatedTitle, chatId);
    } catch (error) {
      console.error('Error generating title:', error);
    } finally {
      setIsGeneratingTitle(false);
    }
  }

  const truncatedTitle = title.length > MAX_TITLE_LENGTH
    ? `${title.slice(0, MAX_TITLE_LENGTH)}...`
    : title

  return (
    <>
      {isEditing ? (
        <div className="flex items-center space-x-2 max-w-md">
          <input
            ref={inputRef}
            type="text"
            value={editingTitle}
            onChange={(e) => {
              setEditingTitle(e.target.value);
            }}
            onBlur={handleSave}
            onKeyDown={handleKeyDown}
            className={`w-full bg-transparent border-none text-xs font-semibold focus:outline-none px-0 ${
              isDarkMode ? 'text-slate-200' : 'text-slate-800'
            }`}
            placeholder={isNewChat ? "Add a new title..." : "Edit chat title..."}
            maxLength={MAX_TITLE_LENGTH}
            aria-label="Edit chat title"
          />
          <div className="flex items-center space-x-2">
            {isSaving ? (
              <div className={`animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 ${
                isDarkMode ? 'border-slate-400' : 'border-gray-900'
              }`} />
            ) : (
              <>
                <button
                  onClick={handleSave}
                  className={`p-1 ${
                    isDarkMode ? 'text-slate-400 hover:text-slate-200' : 'text-gray-500 hover:text-gray-900'
                  }`}
                  aria-label="Save title"
                >
                  <CheckIcon className="h-4 w-4" />
                </button>
                <button
                  onClick={() => {
                    setIsEditing(false)
                    setEditingTitle(title)
                  }}
                  className={`p-1 ${
                    isDarkMode ? 'text-slate-400 hover:text-slate-200' : 'text-gray-500 hover:text-gray-900'
                  }`}
                  aria-label="Cancel editing"
                >
                  <XMarkIcon className="h-4 w-4" />
                </button>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="group relative max-w-md flex items-center">
          <div 
            className={`flex items-center cursor-text px-1 py-1 rounded transition-colors duration-200 ${
              isDarkMode 
                ? `${hasBeenEdited ? 'text-slate-200' : 'text-slate-400'} hover:bg-slate-800` 
                : `${hasBeenEdited ? 'text-gray-900' : 'text-gray-400'} hover:bg-gray-100`
            }`}
            onClick={() => {
              if (!isGeneratingTitle) {
                setIsEditing(true);
                setEditingTitle(title);
              }
            }}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              if (!isGeneratingTitle && (e.key === 'Enter' || e.key === ' ')) {
                setIsEditing(true);
                setEditingTitle(title);
              }
            }}
            aria-label={`Chat title: ${title || 'Add a new title...'}. Click to edit.`}
          >
            <h1 className={`text-xs font-bold ${isGeneratingTitle ? 'text-green-500' : ''}`}>
              {isGeneratingTitle ? 'Generating title...' : (title || 'Add a new title...')}
            </h1>
          </div>
          <button
            onClick={() => handleGenerateTitle(true)}
            disabled={isGeneratingTitle}
            className={`ml-2 p-1 rounded-full flex items-center justify-center transition-opacity duration-200 ${
              isGeneratingTitle ? 'opacity-50 cursor-not-allowed' : 'hover:opacity-80'
            }`}
            aria-label={isGeneratingTitle ? "Generating title..." : "Regenerate title"}
            title={isGeneratingTitle ? "Generating title..." : "Regenerate title"}
          >
            <SparklesIcon className={`h-4 w-4 ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`} />
          </button>
        </div>
      )}
    </>
  )
}
