import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../context/ThemeContext';
import { motion } from 'framer-motion';

const Footer = () => {
  const { isDarkMode } = useTheme();
  const currentYear = new Date().getFullYear();

  // Simplified footer sections with proper navigation links
  const footerSections = [
    {
      title: 'Platform',
      links: [
        { label: 'Content Creation', href: '#features-section' },
        { label: 'Idea to Impact', href: '#how-it-works' },
        { label: 'Knowledge Base', href: '#context-bank-section' },
        { label: 'Plans & Pricing', href: '#pricing-section' },
      ],
    },
    {
      title: 'Resources',
      links: [
        { label: 'Our Story', href: '/about' },
        { label: 'Thought Leadership', href: '/blog' },
        { label: 'Support', href: '/contact' },
      ],
    },
    {
      title: 'Trust',
      links: [
        { label: 'Privacy Promise', href: '/privacy' },
        { label: 'Terms', href: '/terms' },
      ],
    },
  ];

  const socialLinks = [
    { name: 'Twitter', href: '#' },
    { name: 'LinkedIn', href: '#' },
    { name: 'GitHub', href: '#' }
  ];

  // Handle navigation for section links
  const handleNavClick = (e, href) => {
    e.preventDefault();
    if (href.startsWith('#')) {
      const elementId = href.replace('#', '');
      const element = document.getElementById(elementId);
      const mainContainer = document.querySelector('main');
      
      if (element && mainContainer) {
        const currentScrollTop = mainContainer.scrollTop;
        const containerRect = mainContainer.getBoundingClientRect();
        const elementRect = element.getBoundingClientRect();
        const headerOffset = 100;
        const absoluteElementTop = elementRect.top - containerRect.top + currentScrollTop;
        
        mainContainer.scrollTo({
          top: absoluteElementTop - headerOffset,
          behavior: 'smooth'
        });
        
        window.history.pushState({}, '', href);
      }
    } else {
      window.location.href = href;
    }
  };

  // Glass effect styles matching header
  const glassEffectStyles = `
    ${isDarkMode
      ? 'bg-gradient-to-b from-[#151515]/60 to-[#121212]/40'
      : 'bg-gradient-to-b from-white/60 to-white/40'
    }
  `;

  const containerStyles = `
    ${isDarkMode 
      ? 'bg-[#151515]/30 shadow-lg shadow-[#121212]/20 border border-white/[0.05]' 
      : 'bg-slate-50/30 shadow-lg shadow-slate-200/20 border border-black/[0.03]'
    }
  `;

  const borderStyles = `
    ring-1 
    ${isDarkMode
      ? 'ring-[#333333]'
      : 'ring-black/5'
    }
  `;

  return (
    <div className="w-full px-4 sm:px-6 lg:px-8 py-4">
      <div className="max-w-7xl mx-auto">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className={`
            relative rounded-2xl overflow-hidden
            ${containerStyles}
          `}
        >
          {/* Glass effect overlay */}
          <div className={`
            absolute inset-0
            backdrop-blur-xl backdrop-saturate-150
            ${glassEffectStyles}
          `} />

          {/* Border overlay */}
          <div className={`
            absolute inset-0
            ${borderStyles}
            rounded-2xl
          `} />

          {/* Main content */}
          <div className="relative p-8 lg:p-12">
            <div className="grid grid-cols-2 md:grid-cols-12 gap-x-8 gap-y-12">
              {/* Brand section */}
              <div className="col-span-2 md:col-span-4">
                <motion.div
                  whileHover={{ scale: 1.02 }}
                  className="mb-6"
                >
                  <Link to="/" className="inline-flex items-center">
                    <span className={`text-xl font-bold ${
                      isDarkMode 
                        ? 'text-white hover:text-slate-200' 
                        : 'text-slate-900 hover:text-slate-700'
                    }`}>
                      Ammmplify
                    </span>
                  </Link>
                </motion.div>
                <p className={`text-sm max-w-md ${
                  isDarkMode ? 'text-slate-400' : 'text-slate-600'
                }`}>
                  Turn your thoughts into authentic LinkedIn content that resonates. Our AI understands your unique voice and helps amplify your ideas to build meaningful connections.
                </p>
              </div>

              {/* Navigation sections */}
              {footerSections.map((section) => (
                <div key={section.title} className="col-span-1 md:col-span-2">
                  <h3 className={`text-sm font-semibold mb-4 ${
                    isDarkMode ? 'text-white' : 'text-slate-900'
                  }`}>
                    {section.title}
                  </h3>
                  <ul className="space-y-3">
                    {section.links.map((link) => (
                      <motion.li 
                        key={link.label}
                        whileHover={{ x: 4 }}
                        transition={{ type: "tween" }}
                      >
                        <a
                          href={link.href}
                          onClick={(e) => handleNavClick(e, link.href)}
                          className={`
                            text-sm transition-colors duration-200
                            ${isDarkMode
                              ? 'text-slate-400 hover:text-white'
                              : 'text-slate-600 hover:text-slate-900'
                            }
                          `}
                        >
                          {link.label}
                        </a>
                      </motion.li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>

          {/* Bottom bar */}
          <div className={`
            relative
            border-t px-8 lg:px-12 py-6
            ${isDarkMode ? 'border-white/10' : 'border-black/5'}
          `}>
            <div className="flex flex-col md:flex-row justify-between items-center gap-4">
              <div className={`text-sm ${
                isDarkMode ? 'text-slate-400' : 'text-slate-600'
              }`}>
                © {currentYear} Ammmplify. Amplifying authentic voices on LinkedIn.
              </div>
              <div className="flex items-center gap-6">
                {socialLinks.map((link) => (
                  <motion.a
                    key={link.name}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                    href={link.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`
                      text-sm font-medium transition-colors duration-200
                      ${isDarkMode 
                        ? 'text-slate-400 hover:text-white' 
                        : 'text-slate-600 hover:text-slate-900'
                      }
                    `}
                  >
                    {link.name}
                  </motion.a>
                ))}
              </div>
            </div>
          </div>

          {/* Floating effect shadows */}
          <div className={`
            absolute inset-0
            rounded-2xl 
            -z-10
            blur-2xl
            opacity-50
            ${isDarkMode
              ? 'bg-gradient-to-r from-blue-500/5 via-purple-500/5 to-pink-500/5'
              : 'bg-gradient-to-r from-blue-500/10 via-purple-500/10 to-pink-500/10'
            }
          `} />
        </motion.div>
      </div>
    </div>
  );
};

export default Footer;
