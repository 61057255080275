import React from 'react';
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../context/ThemeContext';

const TableHeader = ({ onSort, sortColumn, sortDirection }) => {
  const { isDarkMode } = useTheme();

  const headerClass = `px-3 py-2 text-left text-xs font-medium ${
    isDarkMode ? 'text-slate-400' : 'text-gray-500'
  } uppercase tracking-wider cursor-pointer transition-colors duration-150 ease-in-out`;

  const hoverClass = isDarkMode
    ? 'hover:bg-[#1e1e1e]'
    : 'hover:bg-gray-100';

  const renderSortIcon = (column) => {
    if (sortColumn === column) {
      return (
        <ChevronUpDownIcon
          className={`w-4 h-4 inline-block ml-1 ${
            sortDirection === 'asc' ? 'transform rotate-180' : ''
          } ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}
        />
      );
    }
    return null;
  };

  return (
    <thead className={isDarkMode ? 'bg-[#121212] border-[#333333]' : 'bg-white border-gray-200'}>
      <tr>
        <th className={`${headerClass} ${hoverClass} w-1/2`} onClick={() => onSort('name')}>
          Name {renderSortIcon('name')}
        </th>
        <th className={`${headerClass} ${hoverClass} w-1/4 whitespace-nowrap hidden sm:table-cell`} onClick={() => onSort('modifiedAt')}>
          Last modified {renderSortIcon('modifiedAt')}
        </th>
        <th className={`${headerClass} ${hoverClass} w-1/6 whitespace-nowrap hidden md:table-cell`} onClick={() => onSort('size')}>
          Size {renderSortIcon('size')}
        </th>
        <th className={`${headerClass} ${hoverClass} w-1/12 text-right`}>Actions</th>
      </tr>
    </thead>
  );
};

export default TableHeader;
