import React, { useState, useEffect } from 'react';
import { X, Upload, Plus, Trash2, Loader2, ArrowLeft, ArrowRight, Check } from 'lucide-react';
import { createAIAgent, updateAIAgent, uploadAIAgentProfilePicture, deleteAIAgent } from '../api';
import Notification from './Notification';
import { useTheme } from '../context/ThemeContext';

export default function AIAgentCreationInterface({ onClose, onSave, onDelete, existingAgent }) {
  const { isDarkMode } = useTheme();
  const [agentData, setAgentData] = useState({
    name: '',
    description: '',
    categories: '',
    systemInstructions: '',
    welcomeMessage: '',
    trainingExamples: [{ input: '', output: '' }]
  });
  const [profilePicture, setProfilePicture] = useState(null);
  const [activeTab, setActiveTab] = useState("basic-info");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [agentId, setAgentId] = useState(null);
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [errors, setErrors] = useState({});
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    if (existingAgent) {
      setAgentData(existingAgent);
      setAgentId(existingAgent._id);
    }
  }, [existingAgent]);

  const tabs = ['basic-info', 'agent-behavior', 'training'];
  const currentTabIndex = tabs.indexOf(activeTab);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAgentData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleTrainingExampleChange = (index, field, value) => {
    const updatedExamples = [...agentData.trainingExamples];
    updatedExamples[index][field] = value;
    setAgentData(prevData => ({ ...prevData, trainingExamples: updatedExamples }));
  };

  const addTrainingExample = () => {
    setAgentData(prevData => ({
      ...prevData,
      trainingExamples: [...prevData.trainingExamples, { input: '', output: '' }]
    }));
  };

  const removeTrainingExample = (index) => {
    setAgentData(prevData => ({
      ...prevData,
      trainingExamples: prevData.trainingExamples.filter((_, i) => i !== index)
    }));
  };

  const handleProfilePictureChange = (e) => {
    if (e.target.files[0]) {
      setProfilePicture(e.target.files[0]);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!agentData.name.trim()) newErrors.name = "Agent name is required";
    if (!agentData.description.trim()) newErrors.description = "Description is required";
    if (!agentData.systemInstructions.trim()) newErrors.systemInstructions = "System instructions are required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      // Show notification for validation errors
      const missingFields = Object.keys(errors).join(', ');
      showNotification({
        title: 'Validation Error',
        message: `Please fill in all required fields: ${missingFields}`,
        type: 'error',
      });
      return;
    }
    setIsSubmitting(true);
    try {
      if (!agentId) {
        const response = await createAIAgent(agentData);
        setAgentId(response._id);
        console.log('Agent created:', response); // Add this line for debugging
      } else {
        await updateAIAgent(agentId, agentData);
      }
      if (profilePicture) {
        await uploadProfilePicture();
      }
      setIsSuccess(true);
      onSave(agentData);
    } catch (error) {
      console.error('Error submitting agent:', error);
      showNotification({
        title: 'Error',
        message: `Error creating agent: ${error.message}`,
        type: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const uploadProfilePicture = async () => {
    if (!profilePicture || !agentId) return;

    const formData = new FormData();
    formData.append('profilePicture', profilePicture);

    try {
      await uploadAIAgentProfilePicture(agentId, formData);
    } catch (error) {
      console.error('Error uploading profile picture:', error);
    }
  };

  const getTabProgress = (tab) => {
    switch (tab) {
      case 'basic-info':
        return ['name', 'description'].every(field => agentData[field].trim() !== '') ? 100 : 0;
      case 'agent-behavior':
        return ['systemInstructions', 'welcomeMessage'].every(field => agentData[field].trim() !== '') ? 100 : 0;
      case 'training':
        return agentData.trainingExamples.length > 0 ? 100 : 0;
      default:
        return 0;
    }
  };

  const getOverallProgress = () => {
    const tabProgresses = ['basic-info', 'agent-behavior', 'training'].map(getTabProgress);
    return tabProgresses.reduce((sum, progress) => sum + progress, 0) / tabProgresses.length;
  };

  const handleNext = () => {
    if (currentTabIndex < tabs.length - 1) {
      setActiveTab(tabs[currentTabIndex + 1]);
    }
  };

  const handleBack = () => {
    if (currentTabIndex > 0) {
      setActiveTab(tabs[currentTabIndex - 1]);
    }
  };

  const handleTabChange = (newTab) => {
    if (newTab === activeTab) return;
    setActiveTab(newTab);
  };

  const handleDelete = async () => {
    if (!agentId) return;
    
    if (window.confirm("Are you sure you want to delete this AI agent? This action cannot be undone.")) {
      try {
        await deleteAIAgent(agentId);
        onDelete(agentId);
        onClose();
      } catch (error) {
        console.error('Error deleting agent:', error);
      }
    }
  };

  const handleTagInput = (e) => {
    const value = e.target.value;
    setInputValue(value);

    if (value.endsWith(',')) {
      addTag(value.slice(0, -1).trim());
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && inputValue.trim()) {
      e.preventDefault();
      addTag(inputValue.trim());
    }
  };

  const addTag = (tag) => {
    if (tag && !tags.includes(tag)) {
      setTags([...tags, tag]);
      setInputValue('');
    }
  };

  const removeTag = (tagToRemove) => {
    setTags(tags.filter(tag => tag !== tagToRemove));
  };

  function debounce(func, wait) {
    let timeout;
    const debouncedFunction = function(...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
    debouncedFunction.cancel = function() {
      clearTimeout(timeout);
    };
    return debouncedFunction;
  }

  const showNotification = (notificationData) => {
    const id = Date.now();
    setNotifications((prevNotifications) => [
      ...prevNotifications,
      { id, ...notificationData },
    ]);
  };

  const removeNotification = (id) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== id)
    );
  };

  const getInputClasses = () => {
    return `w-full px-4 py-3 text-sm rounded-lg transition-all duration-200 ${
      isDarkMode
        ? 'bg-slate-700 border-slate-600 text-slate-200 focus:bg-slate-600'
        : 'bg-white border-gray-300 text-gray-900 focus:bg-gray-100'
    } input-focus-effect`;
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-y-auto py-4 px-4 sm:px-6 lg:px-8">
      <div className={`w-full max-w-2xl mx-auto rounded-lg shadow-lg relative overflow-hidden flex flex-col ${
        isDarkMode ? 'bg-slate-800' : 'bg-white'
      }`} style={{ height: '95vh', maxHeight: '850px' }}>
        {/* Top bar with tabs */}
        <div className={`bg-opacity-90 h-auto min-h-12 flex-shrink-0 flex flex-col sm:flex-row items-start sm:items-center justify-between px-4 py-3 w-full border-b border-opacity-50 rounded-t-lg ${
          isDarkMode ? 'bg-slate-800 border-slate-700' : 'bg-white border-gray-200'
        }`}>
          <h2 className="text-lg font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600 mb-2 sm:mb-0">
            {existingAgent ? 'Edit AI Agent' : 'Create AI Agent'}
          </h2>
          <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-2 sm:space-y-0 sm:space-x-4 w-full sm:w-auto">
            <div className="flex p-1 bg-gray-100 rounded-full relative overflow-hidden w-full sm:w-auto">
              {/* Progress background */}
              <div 
                className="absolute inset-0 bg-blue-100 transition-all duration-300 ease-out"
                style={{ width: `${getOverallProgress()}%` }}
              ></div>
              
              {/* Tab buttons */}
              <div className="flex flex-wrap justify-center w-full">
                {['basic-info', 'agent-behavior', 'training'].map((tab) => (
                  <button
                    key={tab}
                    type="button"
                    onClick={() => handleTabChange(tab)}
                    className={`
                      relative text-xs sm:text-sm font-medium py-1 px-2 sm:px-3 rounded-full transition-all duration-400 ease-custom m-1
                      ${activeTab === tab
                        ? 'bg-white text-gray-900 shadow-md'
                        : 'text-gray-500 hover:text-gray-900 hover:bg-gray-200'
                      }
                    `}
                  >
                    <span className="relative z-10">
                      {tab.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                    </span>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Add cancel button for both mobile and desktop */}
        <button 
          onClick={onClose} 
          className={`absolute top-3 right-3 transition duration-150 hover:rotate-90 ${
            isDarkMode ? 'text-slate-400 hover:text-slate-200' : 'text-gray-500 hover:text-gray-700'
          }`}
        >
          <X className="h-5 w-5" />
        </button>

        {/* Main content area */}
        <div className="flex-grow overflow-y-auto p-4 sm:p-6">
          <form onSubmit={handleSubmit} className="h-full flex flex-col">
            <div className="space-y-6 sm:space-y-8 flex-grow">
              {activeTab === 'basic-info' && (
                <div className="space-y-8">
                  <div>
                    <label htmlFor="name" className={`block mb-2 text-sm font-medium ${
                      isDarkMode ? 'text-slate-200' : 'text-gray-700'
                    }`}>
                      Agent Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      id="name"
                      name="name"
                      value={agentData.name}
                      onChange={handleInputChange}
                      required
                      placeholder="e.g. Client A's LinkedIn Post Assistant"
                      className={getInputClasses()}
                    />
                    {errors.name && <p className="mt-1 text-sm text-red-500">{errors.name}</p>}
                  </div>
                  <div>
                    <label htmlFor="description" className={`block mb-2 text-sm font-medium ${
                      isDarkMode ? 'text-slate-200' : 'text-gray-700'
                    }`}>
                      Description <span className="text-red-500">*</span>
                    </label>
                    <textarea
                      id="description"
                      name="description"
                      value={agentData.description}
                      onChange={handleInputChange}
                      required
                      rows={6}
                      placeholder="Describe your AI agent's purpose and capabilities..."
                      className={getInputClasses()}
                    />
                    {errors.description && <p className="mt-1 text-sm text-red-500">{errors.description}</p>}
                  </div>
                  <div>
                    <label htmlFor="categories" className={`block mb-2 text-sm font-medium ${
                      isDarkMode ? 'text-slate-200' : 'text-gray-700'
                    }`}>Categories</label>
                    <div className="flex flex-wrap gap-2 mb-2">
                      {tags.map((tag, index) => (
                        <span key={index} className="bg-blue-100 text-blue-800 text-sm font-medium px-2.5 py-0.5 rounded-full flex items-center">
                          {tag}
                          <button type="button" onClick={() => removeTag(tag)} className="ml-1 text-blue-600 hover:text-blue-800">
                            <X size={14} />
                          </button>
                        </span>
                      ))}
                      {inputValue && (
                        <span className="bg-gray-100 text-gray-800 text-sm font-medium px-2.5 py-0.5 rounded-full flex items-center">
                          {inputValue}
                          <Check size={14} className="ml-1 text-green-600" />
                        </span>
                      )}
                    </div>
                    <input
                      id="categories"
                      name="categories"
                      value={inputValue}
                      onChange={handleTagInput}
                      onKeyDown={handleKeyDown}
                      placeholder="Type a category and press Enter or comma (e.g. Finance, Technology, Health)"
                      className={getInputClasses()}
                    />
                  </div>
                  <div>
                    <label className={`block mb-2 text-sm font-medium ${
                      isDarkMode ? 'text-slate-200' : 'text-gray-700'
                    }`}>Profile Picture</label>
                    <div className="flex items-center space-x-4">
                      <div className="h-14 w-14 rounded-full overflow-hidden bg-gray-200 flex items-center justify-center">
                        {profilePicture ? (
                          <img src={URL.createObjectURL(profilePicture)} alt="Profile" className="h-full w-full object-cover" />
                        ) : (
                          <Upload className="h-8 w-8 text-gray-500" />
                        )}
                      </div>
                      <label htmlFor="profile-upload" className="cursor-pointer">
                        <div className="flex items-center space-x-2 bg-gray-200 text-gray-700 px-6 py-3 rounded-lg hover:bg-gray-300 transition-colors">
                          <Upload size={24} />
                          <span className="text-sm font-medium">Upload Picture</span>
                        </div>
                        <input
                          id="profile-upload"
                          type="file"
                          className="sr-only"
                          onChange={handleProfilePictureChange}
                          accept="image/*"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 'agent-behavior' && (
                <div className="space-y-8">
                  <div>
                    <label htmlFor="systemInstructions" className={`block mb-2 text-sm font-medium ${
                      isDarkMode ? 'text-slate-200' : 'text-gray-700'
                    }`}>
                      System Instructions <span className="text-red-500">*</span>
                    </label>
                    <textarea
                      id="systemInstructions"
                      name="systemInstructions"
                      value={agentData.systemInstructions}
                      onChange={handleInputChange}
                      required
                      rows={10}
                      placeholder="Enter detailed instructions for your AI agent's behavior and capabilities..."
                      className={getInputClasses()}
                    />
                    {errors.systemInstructions && <p className="mt-1 text-sm text-red-500">{errors.systemInstructions}</p>}
                  </div>
                  <div>
                    <label htmlFor="welcomeMessage" className={`block mb-2 text-sm font-medium ${
                      isDarkMode ? 'text-slate-200' : 'text-gray-700'
                    }`}>Welcome Message</label>
                    <textarea
                      id="welcomeMessage"
                      name="welcomeMessage"
                      value={agentData.welcomeMessage}
                      onChange={handleInputChange}
                      rows={6}
                      placeholder="Enter a friendly welcome message for users when they start interacting with your agent..."
                      className={getInputClasses()}
                    />
                  </div>
                </div>
              )}
              {activeTab === 'training' && (
                <div className="space-y-6">
                  {agentData.trainingExamples.map((example, index) => (
                    <div key={index} className="border p-6 rounded-lg">
                      <div className="flex justify-between items-start mb-4">
                        <span className="font-medium text-sm text-gray-700">Example {index + 1}</span>
                        <button
                          type="button"
                          onClick={() => removeTrainingExample(index)}
                          className="text-red-500 hover:text-red-700"
                        >
                          <Trash2 className="h-4 w-4" />
                        </button>
                      </div>
                      <div className="space-y-4">
                        <textarea
                          value={example.input}
                          onChange={(e) => handleTrainingExampleChange(index, 'input', e.target.value)}
                          placeholder="Enter a sample user input..."
                          rows={4}
                          className={getInputClasses()}
                        />
                        <textarea
                          value={example.output}
                          onChange={(e) => handleTrainingExampleChange(index, 'output', e.target.value)}
                          placeholder="Enter the expected AI response..."
                          rows={4}
                          className={getInputClasses()}
                        />
                      </div>
                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={addTrainingExample}
                    className="w-sm px-2 py-2 bg-gray-200 hover:bg-gray-300 rounded-lg flex items-center justify-center text-sm font-medium"
                  >
                    <Plus className="mr-2 h-4 w-4" /> Add Example
                  </button>
                </div>
              )}
            </div>
          </form>
        </div>

        {/* Bottom bar with navigation and action buttons */}
        <div className={`bg-opacity-90 h-auto min-h-16 flex-shrink-0 flex flex-col sm:flex-row items-center justify-between px-4 py-3 w-full border-t border-opacity-50 rounded-b-lg ${
          isDarkMode ? 'bg-slate-800 border-slate-700' : 'bg-white border-gray-200'
        }`}>
          <div className="w-full sm:w-auto mb-2 sm:mb-0">
            {currentTabIndex > 0 && (
              <button
                type="button"
                onClick={handleBack}
                className="px-3 py-1 text-sm font-medium text-gray-600 rounded-full hover:bg-gray-100 flex items-center transition duration-150 hover:translate-x-[-4px] w-full sm:w-auto justify-center sm:justify-start"
              >
                <ArrowLeft className="mr-2 h-4 w-4" /> Back
              </button>
            )}
          </div>
          <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4 w-full sm:w-auto">
            {currentTabIndex < tabs.length - 1 && (
              <button
                type="button"
                onClick={handleNext}
                className="px-3 py-1 text-sm font-medium text-blue-600 rounded-full hover:bg-blue-50 flex items-center transition duration-150 hover:translate-x-[4px] w-full sm:w-auto justify-center"
              >
                Next <ArrowRight className="ml-2 h-4 w-4" />
              </button>
            )}
            {currentTabIndex === tabs.length - 1 && (
              <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 w-full sm:w-auto">
                {existingAgent && (
                  <button
                    type="button"
                    onClick={handleDelete}
                    className="px-3 py-1 text-sm font-medium bg-red-500 text-white rounded-full transition-all duration-300 ease-in-out hover:bg-red-600 w-full sm:w-auto"
                  >
                    Delete Agent
                  </button>
                )}
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="relative px-3 py-1 text-sm font-medium bg-black text-white rounded-full transition-all duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 hover:bg-gray-800 border-2 border-transparent hover:border-white w-full sm:w-auto"
                  disabled={isSubmitting || isSuccess}
                >
                  {isSubmitting ? (
                    <>
                      <Loader2 className="inline-block mr-2 h-4 w-4 animate-spin" />
                      {existingAgent ? 'Updating...' : 'Creating...'}
                    </>
                  ) : isSuccess ? (
                    <>
                      <Check className="inline-block mr-2 h-4 w-4" />
                      {existingAgent ? 'Updated!' : 'Created!'}
                    </>
                  ) : (
                    existingAgent ? 'Update Agent' : 'Create New Agent'
                  )}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Add Notifications */}
      {notifications.map((notification, index) => (
        <Notification
          key={notification.id}
          id={notification.id}
          title={notification.title}
          message={notification.message}
          type={notification.type}
          onClose={removeNotification}
          currentIndex={index + 1}
          totalCount={notifications.length}
        />
      ))}
    </div>
  );
}