import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ArrowLeftIcon, ArrowRightIcon, UserIcon, ArrowUpTrayIcon, CheckIcon, XMarkIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../../context/ThemeContext';
import api from '../../api';
import { fetchAvatarUrl, saveAvatarToCache, getAvatarFromCache } from '../../utils/avatarUtils';
import Notification from '../Notification'; // Add this import
import imageCompression from 'browser-image-compression'; // Add this import
import { createPortal } from 'react-dom'; // Add this import

const FALLBACK_AVATAR = '/default-avatar.png'; // Ensure this path is correct and the image exists

const CustomDropdown = ({ options, value, onChange, placeholder, isDarkMode, hasError }) => {
  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef(null);
  const dropdownRef = useRef(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (triggerRef.current && 
          !triggerRef.current.contains(event.target) && 
          dropdownRef.current && 
          !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    const updatePosition = () => {
      if (triggerRef.current && isOpen) {
        const rect = triggerRef.current.getBoundingClientRect();
        setDropdownPosition({
          top: rect.bottom + window.scrollY + 4,
          left: rect.left,
          width: rect.width,
        });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('scroll', updatePosition, true);
    window.addEventListener('resize', updatePosition);

    updatePosition();

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', updatePosition, true);
      window.removeEventListener('resize', updatePosition);
    };
  }, [isOpen]);

  const renderDropdown = () => {
    if (!isOpen) return null;

    return createPortal(
      <div
        ref={dropdownRef}
        style={{
          position: 'absolute',
          top: `${dropdownPosition.top}px`,
          left: `${dropdownPosition.left}px`,
          width: `${dropdownPosition.width}px`,
        }}
        className={`z-[9999] overflow-hidden rounded-md shadow-lg max-h-[200px] border
                   ${isDarkMode 
                     ? 'bg-slate-950 border-slate-800' 
                     : 'bg-white border-gray-200'}
                   animate-in fade-in-0 zoom-in-95`}
      >
        <div className="overflow-y-auto max-h-[200px] py-1 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
          {options.map((option) => (
            <div
              key={option}
              className={`px-2.5 py-1.5 text-sm cursor-default select-none relative
                        transition-colors duration-100
                        ${isDarkMode
                          ? 'hover:bg-slate-800 text-slate-200'
                          : 'hover:bg-gray-50 text-gray-900'}
                        ${value === option 
                          ? (isDarkMode 
                              ? 'bg-slate-800 text-slate-200' 
                              : 'bg-gray-50 text-gray-900')
                          : ''}`}
              onClick={() => {
                onChange(option);
                setIsOpen(false);
              }}
            >
              <span className={`block truncate ${value === option ? 'font-medium' : 'font-normal'}`}>
                {option}
              </span>
              {value === option && (
                <span className={`absolute inset-y-0 right-2 flex items-center
                                ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>
                  <CheckIcon className="h-4 w-4" />
                </span>
              )}
            </div>
          ))}
        </div>
      </div>,
      document.body
    );
  };

  return (
    <div className="relative" ref={triggerRef}>
      <button
        type="button"
        className={`relative w-full text-left cursor-default rounded-md py-1.5 pl-3 pr-10 text-sm
                   border transition-all duration-200 outline-none
                   ${isDarkMode 
                     ? 'bg-slate-950 border-slate-800 text-slate-200 hover:border-2' 
                     : 'bg-white border-gray-200 text-gray-900 hover:border-2'}
                   ${hasError ? 'border-red-500' : ''}
                   disabled:cursor-not-allowed disabled:opacity-50`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className={`block truncate ${value ? '' : 'text-slate-400/50 dark:text-slate-500/50'}`}>
          {value || placeholder}
        </span>
        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <svg 
            className={`h-4 w-4 transition-transform duration-200
                     ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}
                     ${isOpen ? 'rotate-180' : ''}`}
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 20 20" 
            fill="currentColor"
          >
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </span>
      </button>
      {renderDropdown()}
    </div>
  );
};

const PrivacyPopup = ({ onDismiss, onNeverShowAgain }) => {
  const { isDarkMode } = useTheme();
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className={`relative w-full max-w-md transform overflow-hidden rounded-xl shadow-xl transition-all
                      ${isDarkMode ? 'bg-slate-900' : 'bg-white'}`}>
        {/* Header */}
        <div className={`px-6 py-4 border-b ${isDarkMode ? 'border-slate-800' : 'border-gray-200'}`}>
          <div className="flex items-center justify-between">
            <h3 className={`text-lg font-semibold ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>
              Privacy Notice
            </h3>
            <button
              onClick={onDismiss}
              className={`rounded-full p-1 transition-colors duration-200
                        ${isDarkMode 
                          ? 'hover:bg-slate-800 text-slate-400 hover:text-slate-300' 
                          : 'hover:bg-gray-100 text-gray-500 hover:text-gray-700'}`}
            >
              <XMarkIcon className="h-5 w-5" />
            </button>
          </div>
        </div>

        {/* Content */}
        <div className="px-6 py-4">
          <div className={`space-y-4 ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}`}>
            <p className="text-sm leading-relaxed">
              Your privacy is important to us. Here's what you should know:
            </p>
            <ul className="text-sm space-y-2 list-disc list-inside">
              <li>All information provided is strictly private</li>
              <li>Data is used only for persona development</li>
              <li>We never share your information with third parties</li>
              <li>You can delete your data at any time</li>
            </ul>
          </div>
        </div>

        {/* Footer */}
        <div className={`px-6 py-4 border-t ${isDarkMode ? 'border-slate-800' : 'border-gray-200'}
                        flex justify-end space-x-3`}>
          <button 
            onClick={onDismiss}
            className={`px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200
                      ${isDarkMode 
                        ? 'bg-slate-800 text-slate-200 hover:bg-slate-700' 
                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'}`}
          >
            Got it
          </button>
          <button 
            onClick={onNeverShowAgain}
            className={`px-4 py-2 rounded-md text-sm font-medium text-white
                      transition-colors duration-200
                      ${isDarkMode 
                        ? 'bg-indigo-600 hover:bg-indigo-700' 
                        : 'bg-indigo-600 hover:bg-indigo-700'}`}
          >
            Don't show again
          </button>
        </div>
      </div>
    </div>
  );
};

const ActionButtons = ({ onBack, onSave, isSaving, personaId, isFormValid, isDarkMode, position }) => (
  <div className={`flex items-center justify-between w-full ${position === 'top' ? 'mb-6' : 'mt-6'}`}>
    <button
      onClick={onBack}
      className={`flex items-center px-3 py-2 rounded-md transition-colors duration-200 text-sm
                ${isDarkMode 
                  ? 'bg-slate-800 hover:bg-slate-700 text-slate-200' 
                  : 'bg-gray-100 hover:bg-gray-200 text-gray-700'}`}
    >
      <ArrowLeftIcon className="w-4 h-4 mr-2" />
      Cancel
    </button>

    <button
      onClick={onSave}
      disabled={!isFormValid || isSaving}
      className={`flex items-center px-4 py-2 rounded-md text-sm font-medium
                ${!isFormValid || isSaving
                  ? 'bg-black/10 text-white cursor-not-allowed' 
                  : 'bg-black text-white hover:bg-black/90'}
                transition-colors duration-200 shadow-sm`}
      title={!isFormValid ? "Please fill in all required fields" : ""}
    >
      {isSaving ? (
        <span className="inline-flex items-center gap-2">
          <svg className="animate-spin h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
          </svg>
          <span>Saving...</span>
        </span>
      ) : (
        <span className="inline-flex items-center gap-2">
          <CheckIcon className="h-4 w-4" />
          <span>{personaId ? 'Update Profile' : 'Save Profile'}</span>
        </span>
      )}
    </button>
  </div>
);

const PersonaCreationWizardForChatInterface = ({ onClose, personaId, onSave }) => {
  const { isDarkMode } = useTheme();
  const [showPrivacyPopup, setShowPrivacyPopup] = useState(true);
  const [personaData, setPersonaData] = useState({
    name: '',
    profilePicture: null,
    occupation: '',
    contentGoals: '',
    communicationStyle: '',
    interests: '',
    dislikes: '',
    personalHistory: '',
    culturalBackground: '',
    languageProficiency: '',
    formalityLevel: '',
    trainingExamples: [{ input: '', output: '' }]
  });

  const [avatarUrl, setAvatarUrl] = useState('');
  const [imageError, setImageError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [notifications, setNotifications] = useState([]); // Add this state
  const [isSlowInternet, setIsSlowInternet] = useState(false);
  const [avatarUploadFailed, setAvatarUploadFailed] = useState(false);
  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const requiredFields = {
      name: true,
      occupation: true,
      personalHistory: true,
      culturalBackground: true,
      interests: true,
      dislikes: true,
      formalityLevel: true
    };

    const isValid = Object.keys(requiredFields).every(
      field => personaData[field] && personaData[field].trim() !== ''
    );

    setIsFormValid(isValid);
  }, [personaData]);

  const handleDismissPrivacyPopup = () => {
    setShowPrivacyPopup(false);
  };

  const handleNeverShowAgain = () => {
    setShowPrivacyPopup(false);
    localStorage.setItem('hasSeenPrivacyNotice', 'true');
  };

  const fetchPersona = async () => {
    try {
      const response = await api.get(`/api/personas/${personaId}`);
      setPersonaData(response.data);
      if (response.data.avatar) {
        const cachedUrl = getAvatarFromCache(personaId);
        if (cachedUrl) {
          setAvatarUrl(cachedUrl);
        } else {
          const avatarUrl = await fetchAvatarUrl(personaId);
          setAvatarUrl(avatarUrl || FALLBACK_AVATAR);
          if (avatarUrl) {
            saveAvatarToCache(personaId, avatarUrl);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching persona:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const sections = [
    { 
      title: 'Basic Identity',
      description: 'Define the core characteristics of your writing profile.',
      fields: ['profilePicture', 'name', 'occupation', 'contentGoals']
    },
    {
      title: 'Background & Expertise',
      description: 'Establish the knowledge base and perspective of your profile.',
      fields: ['personalHistory', 'culturalBackground', 'interests', 'dislikes']
    },
    {
      title: 'Writing Voice',
      description: 'Define the tone and style of communication.',
      fields: ['writingStyle', 'toneOfVoice']
    },
    {
      title: 'Training Examples',
      description: 'Provide examples to guide your persona\'s responses.',
      fields: ['trainingExamples']
    }
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    if (name.endsWith('Custom')) {
      // This is a custom input field
      const mainField = name.replace('Custom', '');
      setPersonaData(prevData => ({
        ...prevData,
        [name]: value,
        [mainField]: 'custom' // Ensure the main field is set to 'custom'
      }));
    } else {
      // This is a regular field or dropdown
      setPersonaData(prevData => ({
        ...prevData,
        [name]: value,
        [`${name}Custom`]: value === 'custom' ? prevData[`${name}Custom`] || '' : ''
      }));
    }
  };

  // Modify the compressImage function
  const compressImage = async (file) => {
    const maxSizeMB = 0.1; // Set to 100KB
    const maxWidthOrHeight = 512; // Reduced from 1024 to 512 for smaller file size
    let quality = 0.7; // Start with 70% quality

    const compressFile = async (file, options) => {
      try {
        return await imageCompression(file, options);
      } catch (error) {
        console.error('Error in compression iteration:', error);
        return file;
      }
    };

    let compressedFile = file;
    let iterations = 0;
    const maxIterations = 3; // Limit the number of compression attempts

    while (compressedFile.size > maxSizeMB * 1024 * 1024 && iterations < maxIterations) {
      const options = {
        maxSizeMB,
        maxWidthOrHeight,
        useWebWorker: true,
        quality
      };

      compressedFile = await compressFile(compressedFile, options);
      quality -= 0.1; // Reduce quality by 10% each iteration
      iterations++;
    }

    if (compressedFile.size > maxSizeMB * 1024 * 1024) {
      addNotification('Compression Warning', 'Image compressed, but still exceeds 100KB. It may be resized.', 'warning');
    } else {
      addNotification('Image Compressed', 'Profile picture has been successfully compressed.', 'success');
    }

    return compressedFile;
  };

  // Modify the handleFileChange function
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const compressedFile = await compressImage(file);
        const fileSizeKB = compressedFile.size / 1024;
        if (fileSizeKB > 100) {
          addNotification('File Size Notice', `Image size is ${fileSizeKB.toFixed(2)}KB. It may be further resized on upload.`, 'info');
        }

        setSelectedFile(compressedFile);
        const objectUrl = URL.createObjectURL(compressedFile);
        setAvatarUrl(objectUrl);
        setImageError(false);
      } catch (error) {
        console.error('Error handling file:', error);
        addNotification('File Error', 'An error occurred while processing the file.', 'error');
      }
    }
  };

  const uploadAvatar = async (personaId) => {
    if (selectedFile) {
      try {
        setIsUploading(true);
        addNotification('Uploading Avatar', 'Please wait while we upload your profile picture.', 'info');
        
        const avatarFormData = new FormData();
        avatarFormData.append('avatar', selectedFile, selectedFile.name);

        const slowInternetTimeout = setTimeout(() => {
          setIsSlowInternet(true);
          addNotification('Slow Internet Detected', 'The upload is taking longer than expected. Please be patient.', 'warning');
        }, 10000);

        const avatarResponse = await Promise.race([
          api.post(`/api/personas/${personaId}/avatar`, avatarFormData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            timeout: 30000
          }),
          new Promise((_, reject) => 
            setTimeout(() => reject(new Error('Upload timed out')), 30000)
          )
        ]);

        clearTimeout(slowInternetTimeout);
        setIsSlowInternet(false);

        console.log('Avatar upload response:', avatarResponse);
        if (avatarResponse && avatarResponse.data && avatarResponse.data.avatarKey) {
          const newAvatarUrl = avatarResponse.data.signedUrl;
          setAvatarUrl(newAvatarUrl);
          saveAvatarToCache(personaId, newAvatarUrl);
          console.log('Updated avatar URL:', newAvatarUrl);
          addNotification('Avatar Uploaded', 'Your profile picture has been successfully uploaded.', 'success');
          return avatarResponse;
        } else {
          throw new Error('Invalid response from server');
        }
      } catch (error) {
        console.error('Error uploading avatar:', error);
        if (error.message === 'Upload timed out' || error.code === 'ECONNABORTED') {
          addNotification('Upload Failed', 'The avatar upload failed due to slow internet. Please try again.', 'error');
        } else if (error.response && error.response.status === 500) {
          addNotification('Server Error', 'There was a problem on our end. Please try again later.', 'error');
        } else {
          addNotification('Upload Failed', 'Failed to upload avatar. Please try again.', 'error');
        }
      } finally {
        setIsUploading(false);
        setIsSlowInternet(false);
      }
    }
    return null;
  };

  // Move this function before handleSave
  const validateRequiredFields = () => {
    const requiredFields = {
      name: 'Persona Name',
      occupation: 'Professional Role',
      personalHistory: 'Professional Background',
      culturalBackground: 'Cultural Perspective',
      interests: 'Areas of Expertise',
      dislikes: 'Topics to Avoid',
      formalityLevel: 'Tone of Voice'
    };

    const newErrors = {};
    let isValid = true;

    Object.entries(requiredFields).forEach(([field, label]) => {
      if (!personaData[field] || personaData[field].trim() === '') {
        newErrors[field] = `${label} is required`;
        isValid = false;
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  const handleSave = async () => {
    if (!validateRequiredFields()) {
      addNotification('Missing Fields', 'Please fill in all required fields.', 'error');
      return;
    }

    try {
      setIsSaving(true);
      addNotification('Saving Persona', 'Please wait while we save your persona.', 'info');

      let avatarKey = null;
      
      if (selectedFile) {
        try {
          const avatarFormData = new FormData();
          avatarFormData.append('avatar', selectedFile);
          
          const avatarResponse = await api.post(`/api/personas/temp-id/avatar`, avatarFormData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          
          avatarKey = avatarResponse.data.avatarKey;
        } catch (error) {
          console.error('Error uploading avatar:', error);
          addNotification('Avatar Upload Failed', 'Failed to upload avatar, but proceeding with persona creation.', 'warning');
        }
      }

      let formData = new FormData();
      
      const personaDataForSave = {
        ...personaData,
        avatar: avatarKey || personaData.avatar,
        contentGoals: personaData.contentGoals || ''
      };

      formData.append('personaData', JSON.stringify(personaDataForSave));

      let response;
      if (personaId) {
        response = await api.put(`/api/personas/${personaId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      } else {
        response = await api.post('/api/personas/create', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      }

      console.log('Persona saved successfully:', response.data);
      onSave(response.data);
      addNotification('Persona Saved', 'Your persona has been successfully saved.', 'success');
      onClose();
    } catch (error) {
      console.error('Error saving persona:', error.response?.data || error.message);
      addNotification('Save Failed', 'Failed to save persona. Please try again.', 'error');
    } finally {
      setIsSaving(false);
    }
  };

  const formatFieldTitle = (field) => {
    const titleMap = {
      name: 'Persona Name',
      profilePicture: 'Profile Picture',
      occupation: 'Professional Role',
      interests: 'Areas of Expertise',
      dislikes: 'Topics to Avoid',
      personalHistory: 'Professional Background',
      culturalBackground: 'Cultural Perspective',
      contentGoals: 'Content Goals',
      writingStyle: 'Writing Style',
      toneOfVoice: 'Tone of Voice',
    };

    return titleMap[field] || field
      .split(/(?=[A-Z])/)
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const renderField = (field) => {
    const fieldTitle = formatFieldTitle(field);
    
    switch (field) {
      case 'profilePicture':
        return (
          <div key={field} className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">{fieldTitle}</label>
            <div className="flex items-center">
              <div className="w-20 h-20 bg-gray-200 rounded-full mr-4 flex items-center justify-center overflow-hidden">
                {isUploading ? (
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
                ) : avatarUrl && !imageError ? (
                  <img 
                    src={avatarUrl} 
                    alt="Profile" 
                    className="w-full h-full object-cover" 
                    onError={(e) => {
                      console.error('Error loading image:', e);
                      setImageError(true);
                      setAvatarUrl(FALLBACK_AVATAR);
                    }}
                  />
                ) : (
                  <UserIcon className="w-12 h-12 text-gray-400" />
                )}
              </div>
              <label className="cursor-pointer bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Upload Picture
                <input type="file" className="hidden" onChange={handleFileChange} accept="image/*" disabled={isUploading} />
              </label>
            </div>
          </div>
        );
      case 'interests':
      case 'dislikes':
      case 'personalHistory':
      case 'culturalBackground':
      case 'contentGoals':
        return renderTextAreaField(field, fieldTitle);
      case 'writingStyle':
      case 'toneOfVoice':
        return renderDropdownField(field, fieldTitle);
      case 'trainingExamples':
        return (
          <div key={field} className="mb-4 space-y-6">
            <div className="flex items-center justify-between mb-6">
              <div>
                <label className={`block text-sm font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-700'}`}>
                  Training Examples
                </label>
                <p className={`mt-1 text-xs ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
                  Help your persona learn by providing example conversations
                </p>
              </div>
              <button
                onClick={addTrainingExample}
                className={`inline-flex items-center px-2.5 py-1.5 text-xs font-medium rounded-md
                          transition-colors duration-200 shadow-sm
                          ${isDarkMode 
                            ? 'bg-purple-600/20 text-purple-400 hover:bg-purple-600/30' 
                            : 'bg-purple-50 text-purple-700 hover:bg-purple-100'}`}
              >
                <PlusIcon className="h-3.5 w-3.5 mr-1" />
                Add Example
              </button>
            </div>
            
            <div className="space-y-6">
              {personaData.trainingExamples.map((example, index) => (
                <div 
                  key={index} 
                  className={`relative p-5 rounded-lg border transition-all duration-200
                            ${isDarkMode 
                              ? 'bg-slate-900/50 border-slate-800 hover:border-slate-700' 
                              : 'bg-white/50 border-gray-200 hover:border-gray-300'}`}
                >
                  <div className={`absolute -left-2 -top-2 h-6 w-6 rounded-full flex items-center justify-center text-xs font-medium
                                ${isDarkMode 
                                  ? 'bg-slate-800 text-slate-200 border border-slate-700' 
                                  : 'bg-white text-gray-700 border border-gray-200 shadow-sm'}`}>
                    {index + 1}
                  </div>
                  
                  {personaData.trainingExamples.length > 1 && (
                    <button
                      onClick={() => removeTrainingExample(index)}
                      className={`absolute -right-2 -top-2 h-6 w-6 rounded-full flex items-center justify-center
                                transition-colors duration-200
                                ${isDarkMode 
                                  ? 'bg-slate-800 text-slate-400 hover:text-red-400 border border-slate-700' 
                                  : 'bg-white text-gray-400 hover:text-red-600 border border-gray-200 shadow-sm'}`}
                    >
                      <XMarkIcon className="h-3.5 w-3.5" />
                    </button>
                  )}
                  
                  <div className="space-y-4">
                    <div>
                      <label className={`block text-sm font-medium mb-2
                                      ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}`}>
                        User Message
                      </label>
                      <div className={`relative rounded-md`}>
                        <textarea
                          value={example.input}
                          onChange={(e) => handleTrainingExampleChange(index, 'input', e.target.value)}
                          rows={3}
                          className={`w-full px-4 py-3 text-sm rounded-md border transition-all duration-200
                                    resize-y min-h-[80px] max-h-[200px]
                                    ${isDarkMode 
                                      ? 'bg-slate-950 border-slate-800 text-slate-200 hover:border-2' 
                                      : 'bg-white border-gray-200 text-gray-900 hover:border-2'}
                                    ${errors[field] ? 'border-red-500' : ''}
                                    focus:outline-none focus:border-2 focus:border-indigo-500`}
                          placeholder="What would a user typically say to your persona..."
                        />
                      </div>
                    </div>
                    
                    <div className={`relative ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
                      <div className="absolute inset-0 flex items-center">
                        <div className="w-full border-current opacity-20"></div>
                      </div>
                    </div>

                    <div>
                      <label className={`block text-sm font-medium mb-2
                                      ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}`}>
                        Persona's Response
                      </label>
                      <div className={`relative rounded-md`}>
                        <textarea
                          value={example.output}
                          onChange={(e) => handleTrainingExampleChange(index, 'output', e.target.value)}
                          rows={3}
                          className={`w-full px-4 py-3 text-sm rounded-md border transition-all duration-200
                                    resize-y min-h-[80px] max-h-[200px]
                                    ${isDarkMode 
                                      ? 'bg-slate-950 border-slate-800 text-slate-200 hover:border-2' 
                                      : 'bg-white border-gray-200 text-gray-900 hover:border-2'}
                                    ${errors[field] ? 'border-red-500' : ''}
                                    focus:outline-none focus:border-2 focus:border-indigo-500`}
                          placeholder="How should your persona respond..."
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            
            {personaData.trainingExamples.length === 0 && (
              <div className={`text-center py-8 rounded-lg border-2 border-dashed
                            ${isDarkMode 
                              ? 'border-slate-800 text-slate-400' 
                              : 'border-gray-200 text-gray-500'}`}>
                <p className="text-sm">No training examples yet</p>
                <button
                  onClick={addTrainingExample}
                  className={`mt-2 text-xs font-medium inline-flex items-center
                            ${isDarkMode ? 'text-purple-400' : 'text-purple-600'}`}
                >
                  <PlusIcon className="h-3.5 w-3.5 mr-1" />
                  Add your first example
                </button>
              </div>
            )}
          </div>
        );
      default:
        return renderTextInputField(field, fieldTitle);
    }
  };

  const renderTextInputField = (field, fieldTitle) => (
    <div key={field} className="mb-4 space-y-2">
      <label className={`block text-sm font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-700'}`}>
        {fieldTitle}
        {['name', 'occupation'].includes(field) && 
          <span className="text-red-500 ml-1">*</span>
        }
      </label>
      <input
        type="text"
        name={field}
        value={personaData[field]}
        onChange={handleInputChange}
        className={`block w-full px-3 py-2 text-sm rounded-md border transition-all duration-200
                    ${isDarkMode 
                      ? 'bg-slate-950 border-slate-800 text-slate-200 hover:border-2' 
                      : 'bg-white border-gray-200 text-gray-900 hover:border-2'}
                    ${errors[field] ? 'border-red-500' : ''}
                    focus:outline-none focus:border-2 focus:border-indigo-500
                    placeholder:text-slate-400/50 dark:placeholder:text-slate-500/50`}
        placeholder={`Enter ${fieldTitle.toLowerCase()}`}
      />
      {errors[field] && (
        <p className="text-red-500 text-xs mt-1">{errors[field]}</p>
      )}
    </div>
  );

  const renderDropdownField = (field, fieldTitle) => {
    const options = getOptionsForField(field);
    return (
      <div key={field} className="mb-4">
        <label className={`block text-sm font-medium ${isDarkMode ? 'text-slate-300' : 'text-gray-700'} mb-2`}>
          {fieldTitle}
          {field === 'formalityLevel' && <span className="text-red-500 ml-1">*</span>}
        </label>
        <CustomDropdown
          options={[...options, 'custom']}
          value={personaData[field]}
          onChange={(value) => handleInputChange({ target: { name: field, value } })}
          placeholder={`Select ${fieldTitle}`}
          isDarkMode={isDarkMode}
          hasError={!!errors[field]}
        />
        {errors[field] && (
          <p className="text-red-500 text-xs mt-1">{errors[field]}</p>
        )}
        {personaData[field] === 'custom' && (
          <input
            type="text"
            name={`${field}Custom`}
            value={personaData[`${field}Custom`] || ''}
            onChange={handleInputChange}
            className={`mt-2 block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
                        ${isDarkMode
                ? 'bg-slate-700 border-slate-600 text-slate-200'
                : 'bg-white border-gray-300 text-gray-900'}`}
            placeholder={`Enter custom ${fieldTitle.toLowerCase()}`}
          />
        )}
      </div>
    );
  };

  const renderTextAreaField = (field, fieldTitle) => {
    const placeholderMap = {
      interests: 'What subjects, industries, or topics is this persona expert in?',
      dislikes: 'What topics or writing approaches should this persona avoid?',
      personalHistory: 'What professional experience shapes this persona\'s expertise?',
      culturalBackground: 'What cultural perspectives influence this persona\'s writing?',
      contentGoals: 'What are the main objectives and content goals for this writing profile?'
    };

    return (
      <div key={field} className="mb-4 space-y-2">
        <label className={`block text-sm font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-700'}`}>
          {fieldTitle}
          {['personalHistory', 'culturalBackground', 'interests', 'dislikes'].includes(field) && 
            <span className="text-red-500 ml-1">*</span>
          }
        </label>
        <div className="relative">
          <textarea
            name={field}
            value={personaData[field]}
            onChange={(e) => handleInputChange(e)}
            rows={4}
            className={`block w-full px-3 py-2 text-sm rounded-md border transition-all duration-200
                      resize-y min-h-[100px] max-h-[400px]
                      ${isDarkMode 
                        ? 'bg-slate-950 border-slate-800 text-slate-200 hover:border-2' 
                        : 'bg-white border-gray-200 text-gray-900 hover:border-2'}
                      ${errors[field] ? 'border-red-500' : ''}
                      focus:outline-none focus:border-2 focus:border-indigo-500
                      placeholder:text-slate-400/50 dark:placeholder:text-slate-500/50
                      placeholder:text-sm`}
            placeholder={placeholderMap[field] || `Enter ${fieldTitle.toLowerCase()}`}
          />
          {errors[field] && (
            <p className="text-red-500 text-xs mt-1">{errors[field]}</p>
          )}
        </div>
      </div>
    );
  };

  const getOptionsForField = (field) => {
    switch (field) {
      case 'writingStyle':
        return [
          'Analytical & Technical',
          'Creative & Descriptive',
          'Clear & Concise',
          'Storytelling',
          'Educational',
          'Conversational',
          'Journalistic'
        ];
      case 'toneOfVoice':
        return [
          'Professional & Formal',
          'Friendly & Approachable',
          'Authoritative & Confident',
          'Empathetic & Supportive',
          'Enthusiastic & Energetic',
          'Neutral & Objective'
        ];
      default:
        return [];
    }
  };

  const addNotification = (title, message, type = 'info') => {
    const id = Date.now();
    const updatedMessage = message.replace(/persona/g, 'profile');
    setNotifications(prev => [...prev, { id, title, message: updatedMessage, type }]);
  };

  const removeNotification = (id) => {
    setNotifications(prev => prev.filter(notification => notification.id !== id));
  };

  const renderNotifications = () => {
    return notifications.map((notification, index) => (
      <Notification
        key={notification.id}
        id={notification.id}
        title={notification.title}
        message={notification.message}
        type={notification.type}
        onClose={removeNotification}
        currentIndex={index + 1}
        totalCount={notifications.length}
      />
    ));
  };

  const renderSection = (section, index) => (
    <div key={index} className={`mb-8 rounded-lg overflow-hidden
                              border shadow-sm
                              ${isDarkMode 
                                ? 'bg-slate-900 border-slate-800' 
                                : 'bg-white border-gray-200'}`}>
      {/* Section Header */}
      <div className={`px-6 py-5 border-b 
                      ${isDarkMode 
                        ? 'bg-gradient-to-r from-slate-800 to-slate-800/80 border-slate-700' 
                        : 'bg-gradient-to-r from-gray-50 to-gray-50/80 border-gray-200'}`}>
        {/* Section Number Badge */}
        <div className="flex items-center gap-4">
          <div className={`flex items-center justify-center w-10 h-10 rounded-lg text-base font-semibold
                         ${isDarkMode 
                           ? 'bg-slate-900 text-slate-100 ring-1 ring-slate-700' 
                           : 'bg-white text-gray-900 ring-1 ring-gray-200 shadow-sm'}`}>
            {index + 1}
          </div>
          <div className="space-y-1">
            <h2 className={`text-lg font-semibold leading-none tracking-tight
                          ${isDarkMode ? 'text-slate-100' : 'text-gray-900'}`}>
              {section.title}
            </h2>
            <p className={`text-sm
                          ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
              {section.description}
            </p>
          </div>
        </div>
      </div>

      {/* Section Content */}
      <div className={`p-6 
                      ${isDarkMode 
                        ? 'bg-slate-900 border-slate-800' 
                        : 'bg-white border-gray-200'}`}>
        <div className="grid grid-cols-1 gap-6">
          {renderSectionFields(section.fields)}
        </div>
      </div>
    </div>
  );

  const renderSectionFields = (fields) => {
    const twoColumnFields = {
      name: true,
      occupation: true,
      writingStyle: true,
      toneOfVoice: true,
    };

    let currentRow = [];
    const rows = [];

    fields.forEach((field, index) => {
      if (field === 'profilePicture' || 
          field === 'personalHistory' || 
          field === 'culturalBackground' ||
          field === 'interests' ||
          field === 'dislikes' ||
          field === 'contentGoals' || 
          field === 'trainingExamples') {
        if (currentRow.length > 0) {
          rows.push(
            <div key={`row-${index}`} className="grid grid-cols-2 gap-6">
              {currentRow}
            </div>
          );
          currentRow = [];
        }
        rows.push(
          <div key={`field-${field}`} className="col-span-1">
            {renderField(field)}
          </div>
        );
      } else if (twoColumnFields[field]) {
        currentRow.push(
          <div key={`field-${field}`} className="col-span-1">
            {renderField(field)}
          </div>
        );
        
        if (currentRow.length === 2) {
          rows.push(
            <div key={`row-${index}`} className="grid grid-cols-2 gap-6">
              {currentRow}
            </div>
          );
          currentRow = [];
        }
      } else {
        rows.push(
          <div key={`field-${field}`} className="col-span-1">
            {renderField(field)}
          </div>
        );
      }
    });

    if (currentRow.length > 0) {
      rows.push(
        <div key="final-row" className="grid grid-cols-2 gap-6">
          {currentRow}
        </div>
      );
    }

    return rows;
  };

  const handleTrainingExampleChange = (index, field, value) => {
    const updatedExamples = [...personaData.trainingExamples];
    updatedExamples[index][field] = value;
    setPersonaData(prevData => ({
      ...prevData,
      trainingExamples: updatedExamples
    }));
  };

  const addTrainingExample = () => {
    setPersonaData(prevData => ({
      ...prevData,
      trainingExamples: [...prevData.trainingExamples, { input: '', output: '' }]
    }));
  };

  const removeTrainingExample = (index) => {
    setPersonaData(prevData => ({
      ...prevData,
      trainingExamples: prevData.trainingExamples.filter((_, i) => i !== index)
    }));
  };

  return (
    <div className="fixed inset-0 z-[60] bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className={`relative w-full max-w-4xl h-[90vh] transform overflow-hidden rounded-xl shadow-xl transition-all
                    ${isDarkMode ? 'bg-slate-900' : 'bg-white'}`}>
        {/* Close button */}
        <button
          onClick={onClose}
          className={`absolute top-4 right-4 p-2 rounded-full transition-colors duration-200 z-50
                     ${isDarkMode 
                       ? 'hover:bg-slate-800 text-slate-400 hover:text-slate-300' 
                       : 'hover:bg-gray-100 text-gray-500 hover:text-gray-700'}`}
        >
          <XMarkIcon className="h-5 w-5" />
        </button>

        {renderNotifications()}
        
        {showPrivacyPopup && (
          <PrivacyPopup 
            onDismiss={handleDismissPrivacyPopup} 
            onNeverShowAgain={handleNeverShowAgain}
          />
        )}
        
        {isSlowInternet && (
          <div className={`fixed top-0 left-0 right-0 bg-yellow-500 text-white text-center py-2 z-50`}>
            Slow internet detected. Upload may take longer than usual.
          </div>
        )}
        
        {/* Main Content */}
        <div className={`flex-grow overflow-y-auto custom-scrollbar
                        ${isDarkMode ? 'bg-slate-900' : 'bg-gray-50'}`}>
          <div className="max-w-3xl mx-auto px-4 py-8">
            {/* Top Action Buttons */}
            <ActionButtons
              onBack={onClose}
              onSave={handleSave}
              isSaving={isSaving}
              personaId={personaId}
              isFormValid={isFormValid}
              isDarkMode={isDarkMode}
              position="top"
            />

            {/* Sections */}
            {sections.map(renderSection)}

            {/* Bottom Action Buttons */}
            <ActionButtons
              onBack={onClose}
              onSave={handleSave}
              isSaving={isSaving}
              personaId={personaId}
              isFormValid={isFormValid}
              isDarkMode={isDarkMode}
              position="bottom"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonaCreationWizardForChatInterface;
