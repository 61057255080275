import React from 'react';

export const CanvasGridIcon = ({ className }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 56 56" 
    className={className}
  >
    <path 
      fill="currentColor" 
      d="M3.991 28.293h19.12c2.654 0 3.99-1.337 3.99-4.09v-9.141c0-2.754-1.336-4.071-3.99-4.071H3.99C1.337 10.99 0 12.308 0 15.06v9.141c0 2.754 1.337 4.091 3.991 4.091m34.147 16.485h13.85c2.675 0 4.012-1.337 4.012-4.091V18.075c0-2.754-1.337-4.09-4.012-4.09h-13.85c-2.694 0-4.031 1.336-4.031 4.09v22.612c0 2.754 1.337 4.091 4.031 4.091M8.841 47.552h16.684c2.695 0 4.032-1.317 4.032-4.071v-6.606c0-2.754-1.337-4.091-4.032-4.091H8.841c-2.674 0-4.011 1.337-4.011 4.091v6.606c0 2.754 1.337 4.071 4.011 4.071"
    />
  </svg>
); 