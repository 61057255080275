import React, { useState, useRef, useEffect } from 'react';
import { 
  ChatBubbleBottomCenterTextIcon, 
  PresentationChartBarIcon,
  ChevronUpDownIcon,
  CheckIcon
} from '@heroicons/react/24/outline';

const ChatInterfaceMode = ({ 
  chatMode, 
  onModeSelect, 
  isDarkMode,
  className = ''
}) => {
  const [showModeSelector, setShowModeSelector] = useState(false);
  const modeSelectorRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modeSelectorRef.current && !modeSelectorRef.current.contains(event.target)) {
        setShowModeSelector(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleModeClick = (mode) => {
    onModeSelect(mode);
    setShowModeSelector(false);
  };

  return (
    <div className={`relative ${className}`} ref={modeSelectorRef}>
      <button
        onClick={() => setShowModeSelector(!showModeSelector)}
        className={`
          flex items-center gap-1.5 px-2 h-7 text-xs font-medium rounded-lg
          transition-all duration-200 border
          ${isDarkMode 
            ? 'bg-[#121212] text-slate-200 hover:bg-[#1e1e1e] border-[#333333]' 
            : 'bg-gray-50 text-gray-700 hover:bg-gray-100/80 border-gray-200/80'}
          backdrop-blur-sm
        `}
      >
        {chatMode === 'post' ? (
          <ChatBubbleBottomCenterTextIcon className="h-3.5 w-3.5" />
        ) : (
          <PresentationChartBarIcon className="h-3.5 w-3.5" />
        )}
        <span className="whitespace-nowrap capitalize">{chatMode} Mode</span>
        <ChevronUpDownIcon className="h-3.5 w-3.5 ml-1" />
      </button>

      {showModeSelector && (
        <div className={`
          absolute bottom-full left-0 mb-2 w-48 rounded-lg shadow-lg z-50
          backdrop-blur-md backdrop-saturate-150 overflow-hidden
          border transition-all duration-200
          ${isDarkMode 
            ? 'bg-[#121212]/95 border-[#333333]' 
            : 'bg-white/95 border-gray-200/80'}
        `}>
          <div className={`
            px-2 py-1.5 border-b text-xs font-medium
            ${isDarkMode ? 'border-[#333333] text-slate-400' : 'border-gray-200/80 text-gray-500'}
          `}>
            Select Chat Mode
          </div>
          
          <div className="p-1">
            <button
              onClick={() => handleModeClick('post')}
              className={`
                flex items-center gap-2 px-2 py-1.5 w-full rounded-md
                transition-all duration-200 relative
                ${chatMode === 'post'
                  ? isDarkMode 
                    ? 'bg-[#1e1e1e] text-blue-400' 
                    : 'bg-blue-50 text-blue-600'
                  : isDarkMode 
                    ? 'hover:bg-[#1e1e1e] text-slate-200' 
                    : 'hover:bg-gray-50 text-gray-700'
                }
              `}
            >
              <ChatBubbleBottomCenterTextIcon className="h-4 w-4" />
              <span className="text-xs font-medium">Post Mode</span>
              {chatMode === 'post' && (
                <div className={`
                  absolute right-2 flex items-center justify-center
                  w-4 h-4 rounded-full
                  ${isDarkMode ? 'bg-blue-500' : 'bg-blue-600'}
                `}>
                  <CheckIcon className="h-2.5 w-2.5 text-white" />
                </div>
              )}
            </button>

            <button
              onClick={() => handleModeClick('deck')}
              className={`
                flex items-center gap-2 px-2 py-1.5 w-full rounded-md
                transition-all duration-200 relative
                ${chatMode === 'deck'
                  ? isDarkMode 
                    ? 'bg-[#1e1e1e] text-blue-400' 
                    : 'bg-blue-50 text-blue-600'
                  : isDarkMode 
                    ? 'hover:bg-[#1e1e1e] text-slate-200' 
                    : 'hover:bg-gray-50 text-gray-700'
                }
              `}
            >
              <PresentationChartBarIcon className="h-4 w-4" />
              <span className="text-xs font-medium">Deck Mode</span>
              {chatMode === 'deck' && (
                <div className={`
                  absolute right-2 flex items-center justify-center
                  w-4 h-4 rounded-full
                  ${isDarkMode ? 'bg-blue-500' : 'bg-blue-600'}
                `}>
                  <CheckIcon className="h-2.5 w-2.5 text-white" />
                </div>
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatInterfaceMode; 