import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useTheme } from '../../context/ThemeContext';
import { 
  MagnifyingGlassIcon,
  ChevronDownIcon,
  CheckIcon,
  XMarkIcon,
  PencilSquareIcon,
  LightBulbIcon,
  XCircleIcon,
  ArrowLeftIcon
} from '@heroicons/react/24/outline';
import api from '../../api';
import PersonaDropdown from '../personas/PersonaDropdown';
import TargetAudienceDropdown from '../targetAudiences/TargetAudienceDropdown';
import YouIdeaItem from './YouIdeaItem';
import { Combobox, Transition, Tab } from '@headlessui/react';
import { createPortal } from 'react-dom';
import '../customScrollbar.css';
import { motion } from 'framer-motion';
import AllInsightsView from './AllInsightsView';
import RedditDataModal from './RedditDataModal';
import RedditAnalysisOverlay from './RedditAnalysisOverlay';

// First, let's create a RedditIcon component at the top of the file
const markdownStyles = {
  light: `prose prose-xs max-w-none
    prose-headings:text-gray-900 prose-headings:text-sm prose-headings:font-medium
    prose-p:text-gray-600 prose-p:text-xs
    prose-strong:text-gray-900 prose-strong:text-xs
    prose-ul:text-gray-600 prose-ul:text-xs
    prose-ol:text-gray-600 prose-ol:text-xs
    prose-li:text-gray-600 prose-li:text-xs
    prose-ul:list-disc prose-ul:pl-4 
    prose-ol:list-decimal prose-ol:pl-4
    prose-li:my-0.5
    prose-p:my-1.5`,
  dark: `prose prose-xs max-w-none
    prose-invert
    prose-headings:text-gray-100 prose-headings:text-sm prose-headings:font-medium
    prose-p:text-gray-300 prose-p:text-xs
    prose-strong:text-gray-100 prose-strong:text-xs
    prose-ul:text-gray-300 prose-ul:text-xs
    prose-ol:text-gray-300 prose-ol:text-xs
    prose-li:text-gray-300 prose-li:text-xs
    prose-ul:list-disc prose-ul:pl-4
    prose-ol:list-decimal prose-ol:pl-4
    prose-li:my-0.5
    prose-p:my-1.5`
};

const RedditIcon = ({ isDarkMode, className = "w-4 h-4", showBackground = true }) => (
  <svg 
    className={className}
    viewBox="0 0 256 256"
  >
    {showBackground && <circle cx="128" cy="128" r="128" fill={isDarkMode ? '#FF4500' : '#FF4500'} />}
    <path 
      fill={showBackground ? (isDarkMode ? '#FFFFFF' : '#FFFFFF') : 'currentColor'} 
      d="M213.15 129.22c0-10.376-8.391-18.617-18.617-18.617a18.74 18.74 0 0 0-12.97 5.189c-12.818-9.157-30.368-15.107-49.9-15.87l8.544-39.981l27.773 5.95c.307 7.02 6.104 12.667 13.278 12.667c7.324 0 13.275-5.95 13.275-13.278c0-7.324-5.95-13.275-13.275-13.275c-5.188 0-9.768 3.052-11.904 7.478l-30.976-6.562c-.916-.154-1.832 0-2.443.458c-.763.458-1.22 1.22-1.371 2.136l-9.464 44.558c-19.837.612-37.692 6.562-50.662 15.872a18.74 18.74 0 0 0-12.971-5.188c-10.377 0-18.617 8.391-18.617 18.617c0 7.629 4.577 14.037 10.988 16.939a33.6 33.6 0 0 0-.458 5.646c0 28.686 33.42 52.036 74.621 52.036c41.202 0 74.622-23.196 74.622-52.036a35 35 0 0 0-.458-5.646c6.408-2.902 10.985-9.464 10.985-17.093M85.272 142.495c0-7.324 5.95-13.275 13.278-13.275c7.324 0 13.275 5.95 13.275 13.275s-5.95 13.278-13.275 13.278c-7.327.15-13.278-5.953-13.278-13.278m74.317 35.251c-9.156 9.157-26.553 9.768-31.588 9.768c-5.188 0-22.584-.765-31.59-9.768c-1.371-1.373-1.371-3.51 0-4.883c1.374-1.371 3.51-1.371 4.884 0c5.8 5.8 18.008 7.782 26.706 7.782s21.058-1.983 26.704-7.782c1.374-1.371 3.51-1.371 4.884 0c1.22 1.373 1.22 3.51 0 4.883m-2.443-21.822c-7.325 0-13.275-5.95-13.275-13.275s5.95-13.275 13.275-13.275c7.327 0 13.277 5.95 13.277 13.275c0 7.17-5.95 13.275-13.277 13.275"
    />
  </svg>
);

const TopBar = ({ searchTerm, setSearchTerm, isDarkMode, isSearchExpanded, setIsSearchExpanded, onViewAllInsights, onBackToInput, viewMode, onClose }) => (
  <div className={`${isDarkMode ? 'bg-[#151515]/50' : 'bg-white/50'} 
    backdrop-blur-sm sticky top-0 z-10 h-10 flex items-center px-3 w-full border-b ${
    isDarkMode ? 'border-[#333333]' : 'border-gray-200/60'}`}>
    <div className="flex items-center flex-1 gap-2">
      <LightBulbIcon className={`h-4 w-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`} />
      <h2 className={`text-xs font-medium ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>
        Insights
      </h2>
    </div>
    <div className="flex items-center gap-2 sm:gap-3 relative">
      {viewMode === 'all-insights' && (
        <button
          onClick={onBackToInput}
          className={`
            inline-flex items-center gap-1 sm:gap-2 px-2 sm:px-3 py-1 text-xs rounded-md
            transition-colors duration-200 whitespace-nowrap
            ${isDarkMode
              ? 'bg-[#1a1a1a] hover:bg-[#202020] text-slate-200 border border-[#333333]'
              : 'bg-gray-100 hover:bg-gray-200 text-gray-700'
            }
          `}
        >
          <ArrowLeftIcon className="w-3.5 h-3.5" />
          <span className="hidden sm:inline">Back to Input</span>
        </button>
      )}
      <button
        onClick={onViewAllInsights}
        className={`px-2 sm:px-3 py-1 text-xs rounded-md transition-colors whitespace-nowrap
          ${isDarkMode 
            ? 'bg-[#1a1a1a] hover:bg-[#202020] text-slate-200 border border-[#333333]' 
            : 'bg-gray-100 hover:bg-gray-200 text-gray-700'
          }`}
      >
        All Insights
      </button>
      <div className="flex-shrink-0">
        <ExpandableSearchBar
          isDarkMode={isDarkMode}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          isExpanded={isSearchExpanded}
          setIsExpanded={setIsSearchExpanded}
        />
      </div>
      {onClose && (
        <button
          onClick={onClose}
          className={`
            p-1.5 rounded-md
            transition-all duration-200
            ml-2 sm:ml-3
            ${isDarkMode
              ? 'bg-white hover:bg-gray-100 text-black'
              : 'bg-black hover:bg-gray-800 text-white'
            }
          `}
          title="Close"
        >
          <ArrowLeftIcon className="w-4 h-4" />
        </button>
      )}
    </div>
  </div>
);

const ExpandableSearchBar = ({ isDarkMode, searchTerm, setSearchTerm, isExpanded, setIsExpanded }) => {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className={`relative transition-all duration-200 ease-in-out ${
      isExpanded || isFocused ? 'w-48 sm:w-72' : 'w-32 sm:w-48'
    }`}>
      <input
        ref={inputRef}
        type="text"
        placeholder="Search insights..."
        className={`w-full pl-7 pr-7 py-1 text-xs rounded-md border
          transition-colors duration-200
          ${isDarkMode 
            ? 'bg-[#1a1a1a] border-[#333333] text-slate-200 placeholder-slate-500' 
            : 'bg-gray-50/50 border-gray-200/50 text-gray-900 placeholder-gray-400'
          }
          ${isFocused 
            ? isDarkMode 
              ? 'border-[#333333] shadow-[0_2px_8px_0_rgba(0,0,0,0.1)]' 
              : 'border-gray-300 shadow-[0_2px_8px_0_rgba(0,0,0,0.03)]'
            : ''
          }
          focus:outline-none
          ${isDarkMode
            ? 'focus:border-indigo-500/40 focus:shadow-[0_2px_8px_0_rgba(99,102,241,0.05)]'
            : 'focus:border-indigo-500/30 focus:shadow-[0_2px_8px_0_rgba(99,102,241,0.03)]'
          }
        `}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onFocus={() => {
          setIsExpanded(true);
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
          if (!searchTerm) setIsExpanded(false);
        }}
      />
      <MagnifyingGlassIcon 
        className={`absolute left-2 top-1/2 -translate-y-1/2 w-3.5 h-3.5 
          ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`} 
      />
      {searchTerm && (
        <button
          onClick={() => setSearchTerm('')}
          className={`absolute right-2 top-1/2 -translate-y-1/2 p-0.5 rounded-md
            ${isDarkMode 
              ? 'hover:bg-[#202020] text-slate-400' 
              : 'hover:bg-gray-200 text-gray-500'
            }`}
        >
          <XMarkIcon className="w-3 h-3" />
        </button>
      )}
    </div>
  );
};

// Update the DropdownPortal component to use a more reasonable width
const DropdownPortal = React.forwardRef(({ children, targetRef }, ref) => {
  const [position, setPosition] = useState({ top: 0, left: 0, width: 0 });

  useEffect(() => {
    if (targetRef.current) {
      const updatePosition = () => {
        const rect = targetRef.current.getBoundingClientRect();
        const spaceBelow = window.innerHeight - rect.bottom;
        const spaceAbove = rect.top;
        const showAbove = spaceBelow < 260 && spaceAbove > spaceBelow;

        // Calculate width based on input width
        const width = rect.width; // Use exact input width instead of expanding it

        setPosition({
          top: showAbove ? rect.top - 4 : rect.bottom + 4,
          left: rect.left,
          width: width,
          transformOrigin: showAbove ? 'bottom' : 'top'
        });
      };

      updatePosition();
      window.addEventListener('scroll', updatePosition, true);
      window.addEventListener('resize', updatePosition);

      return () => {
        window.removeEventListener('scroll', updatePosition, true);
        window.removeEventListener('resize', updatePosition);
      };
    }
  }, [targetRef]);

  return createPortal(
    <div 
      ref={ref}
      style={{
        position: 'fixed',
        top: position.top,
        left: position.left,
        width: position.width,
        zIndex: 9999,
      }}
    >
      {children}
    </div>,
    document.body
  );
});

// Update the RedditListingDropdown component
const RedditListingDropdown = ({ value, onChange, isDarkMode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);
  const portalRef = useRef(null);

  const options = [
    { value: 'hot', label: 'Hot', icon: '🔥' },
    { value: 'top', label: 'Top', icon: '⭐' },
    { value: 'new', label: 'New', icon: '🆕' },
    { value: 'rising', label: 'Rising', icon: '📈' },
  ];

  const selectedOption = options.find(opt => opt.value === value);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleEscape = (event) => {
      if (event.key === 'Escape' && isOpen) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen]);

  const handleKeyDown = (event) => {
    if (!isOpen) return;

    const currentIndex = options.findIndex(opt => opt.value === value);
    let newIndex;

    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        newIndex = currentIndex < options.length - 1 ? currentIndex + 1 : 0;
        onChange(options[newIndex].value);
        break;
      case 'ArrowUp':
        event.preventDefault();
        newIndex = currentIndex > 0 ? currentIndex - 1 : options.length - 1;
        onChange(options[newIndex].value);
        break;
      case 'Enter':
      case 'Space':
        event.preventDefault();
        setIsOpen(!isOpen);
        break;
      default:
        break;
    }
  };

  return (
    <div className="relative">
      <button
        ref={buttonRef}
        onClick={() => setIsOpen(!isOpen)}
        onKeyDown={handleKeyDown}
        className={`
          w-full flex items-center justify-between px-3 py-2 text-xs 
          rounded-md border transition-all duration-200
          ${isDarkMode 
            ? 'bg-[#1a1a1a] border-[#333333] text-slate-200 hover:bg-[#202020]' 
            : 'bg-gray-50/50 border-gray-200/50 text-gray-900 hover:bg-gray-100/50'
          }
          focus:outline-none
          ${isDarkMode
            ? 'focus:border-indigo-500/40 focus:shadow-[0_2px_8px_0_rgba(99,102,241,0.05)]'
            : 'focus:border-indigo-500/30 focus:shadow-[0_2px_8px_0_rgba(99,102,241,0.03)]'
          }
        `}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        aria-labelledby="reddit-listing-label"
      >
        <div className="flex items-center gap-2">
          <span>{selectedOption.icon}</span>
          <span>{selectedOption.label}</span>
        </div>
        <ChevronDownIcon 
          className={`h-4 w-4 transition-transform duration-200 ${
            isOpen ? 'rotate-180' : ''
          } ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`} 
        />
      </button>

      {isOpen && (
        <DropdownPortal targetRef={buttonRef} ref={portalRef}>
          <motion.div
            ref={dropdownRef}
            initial={{ opacity: 0, y: -5 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -5 }}
            transition={{ duration: 0.15 }}
            className={`
              rounded-md border shadow-sm mt-1 py-1
              fixed left-0 right-0 mx-4 sm:mx-0 sm:relative
              ${isDarkMode 
                ? 'bg-[#1a1a1a] border-[#333333] shadow-[0_4px_12px_rgba(0,0,0,0.2)]' 
                : 'bg-white border-gray-200/50 shadow-[0_4px_12px_rgba(0,0,0,0.05)]'
              }
            `}
            role="listbox"
            aria-labelledby="reddit-listing-label"
          >
            {options.map((option) => (
              <button
                key={option.value}
                onClick={() => {
                  onChange(option.value);
                  setIsOpen(false);
                }}
                className={`
                  w-full flex items-center gap-2 px-3 py-2 text-xs
                  transition-colors duration-200
                  ${option.value === value
                    ? isDarkMode
                      ? 'bg-[#252525] text-slate-200'
                      : 'bg-gray-100 text-gray-900'
                    : isDarkMode
                      ? 'text-slate-300 hover:bg-[#252525] hover:text-slate-200'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                  }
                `}
                role="option"
                aria-selected={option.value === value}
              >
                <span>{option.icon}</span>
                <span>{option.label}</span>
              </button>
            ))}
          </motion.div>
        </DropdownPortal>
      )}
    </div>
  );
};

// Add this component near the top of the file, after other component imports
const SearchResultsSummary = ({ filteredCount, totalCount, searchTerm, isDarkMode }) => {
  if (!searchTerm) return null;

  return (
    <div className={`text-sm px-4 py-2 mb-2 rounded-md ${
      isDarkMode 
        ? 'bg-slate-800/50 text-slate-300' 
        : 'bg-slate-100/50 text-slate-600'
    }`}>
      Found {filteredCount} {filteredCount === 1 ? 'result' : 'results'} 
      {totalCount > 0 && ` out of ${totalCount} insights`}
    </div>
  );
};

// Update the LoadingSpinner component
const LoadingSpinner = ({ message, isRedditMode }) => (
  <div className={`
    absolute inset-0 flex items-center justify-center
    ${isRedditMode ? 'bg-[#121212]/80' : 'bg-white/80'}
    backdrop-blur-sm
  `}>
    <div className="flex items-center gap-2">
      <svg className="animate-spin h-4 w-4 text-white" viewBox="0 0 24 24">
        <circle 
          className="opacity-25" 
          cx="12" 
          cy="12" 
          r="10" 
          stroke="currentColor" 
          strokeWidth="4"
          fill="none"
        />
        <path 
          className="opacity-75" 
          fill="currentColor" 
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
      <span className={`
        text-xs font-medium
        ${isRedditMode ? 'text-slate-300' : 'text-gray-600'}
      `}>
        {message}
      </span>
    </div>
  </div>
);

// Add this near the top of the file with other constants
const dropdownOptionStyles = `
  relative w-full flex items-center space-x-2 px-3 py-2 text-xs
  transition-colors overflow-hidden cursor-pointer
  hover:shadow-[0_4px_25px_rgba(15,23,42,0.45)]
`;

// Update the SimpleButton component
const SimpleButton = ({ 
  onClick, 
  isDarkMode, 
  disabled, 
  isRedditMode,
  isLoading,
  children 
}) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`
      w-full h-9 px-4 
      rounded-md text-xs font-medium
      transition-colors duration-200
      disabled:opacity-50 disabled:cursor-not-allowed
      text-white relative
      ${isRedditMode 
        ? 'bg-orange-600 hover:bg-orange-700 border border-orange-500/50' 
        : 'bg-indigo-600 hover:bg-indigo-700 border border-indigo-500/50'
      }
    `}
  >
    <span className={`transition-opacity duration-200 ${isLoading ? 'opacity-0' : 'opacity-100'}`}>
      {children}
    </span>
  </button>
);

// Add these loading messages at the top of the file
const REDDIT_LOADING_STAGES = {
  INIT: {
    title: 'Initializing Analysis',
    message: 'Setting up the Reddit data pipeline and preparing for analysis...',
    progress: 5
  },
  FETCH_INIT: {
    title: 'Starting Data Collection',
    message: 'Connecting to Reddit API and preparing to gather data...',
    progress: 10
  },
  FETCH_POSTS: {
    title: 'Gathering Posts',
    message: 'Collecting posts from selected subreddits (up to 100 per subreddit)...',
    progress: 20
  },
  FETCH_COMMENTS_START: {
    title: 'Processing Comments',
    message: 'Beginning to collect comment data from each post...',
    progress: 30
  },
  FETCH_COMMENTS_PROGRESS: {
    title: 'Processing Comments',
    message: 'Analyzing community discussions and gathering user interactions...',
    progress: 40
  },
  COMMENTS_ANALYSIS: {
    title: 'Analyzing Comments',
    message: 'Processing comment sentiment, user engagement, and discussion patterns...',
    progress: 50
  },
  ANALYZING: {
    title: 'Extracting Insights',
    message: 'Using advanced analysis to identify trends, patterns, and valuable information...',
    progress: 70
  },
  GENERATING: {
    title: 'Compiling Results',
    message: 'Organizing insights and preparing a comprehensive analysis of the community discussions...',
    progress: 85
  },
  FINALIZING: {
    title: 'Final Touches',
    message: 'Putting everything together and preparing your personalized Reddit analysis...',
    progress: 95
  }
};

// Add this helper function for controlled delays
const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

// Add this component for the loading UI
const RedditLoadingState = ({ currentStage, progress, isDarkMode, subreddit }) => (
  <div className="space-y-4 w-full max-w-md mx-auto px-4 sm:px-0">
    <div className="relative pt-1">
      <div className="flex mb-2 items-center justify-between">
        <div>
          <span className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full ${
            isDarkMode 
              ? 'bg-orange-500/20 text-orange-300' 
              : 'bg-orange-100 text-orange-600'
          }`}>
            {currentStage?.title || 'Processing'}
          </span>
        </div>
        <div className={`text-right ${
          isDarkMode ? 'text-gray-300' : 'text-gray-600'
        }`}>
          <span className="text-xs font-semibold">
            {progress}%
          </span>
        </div>
      </div>
      <div className={`overflow-hidden h-2 mb-4 text-xs flex rounded-full ${
        isDarkMode ? 'bg-gray-700' : 'bg-gray-200'
      }`}>
        <div
          style={{ width: `${progress}%`, transition: 'width 0.5s ease-in-out' }}
          className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${
            isDarkMode 
              ? 'bg-gradient-to-r from-orange-500 to-orange-600' 
              : 'bg-gradient-to-r from-orange-400 to-orange-500'
          }`}
        />
      </div>
    </div>

    <div className={`text-center ${
      isDarkMode ? 'text-gray-400' : 'text-gray-600'
    }`}>
      <p className="text-sm">
        {currentStage?.message?.replace('subreddits', 'subreddit') || 'Processing your request...'}
      </p>
      <p className="text-xs mt-1 font-medium">
        Analyzing r/{subreddit}
      </p>
    </div>

    <div className={`mt-6 p-4 rounded-lg ${
      isDarkMode 
        ? 'bg-gray-800/50 border border-gray-700/50' 
        : 'bg-gray-50 border border-gray-200/50'
    }`}>
      <h4 className={`text-xs font-medium mb-2 ${
        isDarkMode ? 'text-gray-300' : 'text-gray-700'
      }`}>
        💡 Analysis in Progress
      </h4>
      <p className={`text-xs ${
        isDarkMode ? 'text-gray-400' : 'text-gray-600'
      }`}>
        We're analyzing up to 100 posts and their top comments from r/{subreddit} to provide you with comprehensive insights about this community.
      </p>
    </div>
  </div>
);

// First, add this new component after the RedditIcon component
const RedditAnalysisHeader = ({ isDarkMode }) => (
  <div className={`
    flex flex-col sm:flex-row sm:items-center justify-between px-4 sm:px-6 py-4 border-b
    ${isDarkMode 
      ? 'border-[#333333] bg-[#151515]/40' 
      : 'border-gray-200/40 bg-gray-50/40'
    }
    rounded-t-xl
  `}>
    <div className="flex items-center gap-3 mb-3 sm:mb-0">
      <div className={`
        p-2 rounded-lg
        ${isDarkMode 
          ? 'bg-[#1a1a1a] border border-[#333333]' 
          : 'bg-white/50 border border-gray-200/50'
        }
      `}>
        <RedditIcon isDarkMode={isDarkMode} className="w-5 h-5" />
      </div>
      <div>
        <h2 className={`
          text-sm font-medium
          ${isDarkMode ? 'text-gray-200' : 'text-gray-900'}
        `}>
          Subreddit Analysis
        </h2>
        <p className={`
          text-xs mt-0.5
          ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}
        `}>
          Deep dive into a subreddit's discussions
        </p>
      </div>
    </div>
    <div className={`
      px-3 py-1 rounded-full text-xs inline-flex justify-center
      ${isDarkMode 
        ? 'bg-orange-500/10 text-orange-300 border border-orange-500/20' 
        : 'bg-orange-50 text-orange-600 border border-orange-200'
      }
    `}>
      AI-Powered Analysis
    </div>
  </div>
);

// Update the RedditAnalysisError component
const RedditAnalysisError = ({ error, onRetry, isDarkMode, onClose }) => (
  <div 
    className={`
      fixed inset-0 flex items-center justify-center p-4 sm:p-0
      ${isDarkMode ? 'bg-[#121212]/80' : 'bg-white/80'}
      backdrop-blur-sm z-50
    `}
    onClick={onClose}
  >
    <div 
      className={`
        relative w-full max-w-md mx-auto p-4 sm:p-6 rounded-lg
        ${isDarkMode 
          ? 'bg-[#1a1a1a] border border-[#333333]' 
          : 'bg-white border border-gray-200'
        }
      `}
      onClick={e => e.stopPropagation()}
    >
      <button
        onClick={onClose}
        className={`
          absolute top-3 right-3 sm:top-4 sm:right-4 p-1.5 rounded-full
          transition-colors duration-200
          ${isDarkMode
            ? 'hover:bg-gray-800 text-gray-400 hover:text-gray-300'
            : 'hover:bg-gray-100 text-gray-500 hover:text-gray-600'
          }
        `}
        aria-label="Close error message"
      >
        <XMarkIcon className="w-4 h-4" />
      </button>

      <div className="text-center space-y-4">
        <XCircleIcon className={`
          w-10 h-10 sm:w-12 sm:h-12 mx-auto
          ${isDarkMode ? 'text-red-500' : 'text-red-600'}
        `} />
        <h3 className={`
          text-sm font-medium
          ${isDarkMode ? 'text-gray-200' : 'text-gray-900'}
        `}>
          Analysis Failed
        </h3>
        <p className={`
          text-xs px-4
          ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}
        `}>
          {error || 'Failed to analyze Reddit data. Please try again.'}
        </p>
        <div className="flex justify-center gap-3 pt-2">
          <button
            onClick={onRetry}
            className={`
              px-4 py-2 text-xs font-medium rounded-md
              transition-colors duration-200
              ${isDarkMode
                ? 'bg-orange-500/20 text-orange-300 hover:bg-orange-500/30'
                : 'bg-orange-50 text-orange-600 hover:bg-orange-100'
              }
            `}
          >
            Try Again
          </button>
        </div>
      </div>
    </div>
  </div>
);

const YouIdea = ({ onClose, isInChatInterface }) => {
  const { isDarkMode } = useTheme();
  const [viewMode, setViewMode] = useState('input');
  const [ideas, setIdeas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState('');
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [selectedSubreddit, setSelectedSubreddit] = useState('');
  const [subredditError, setSubredditError] = useState('');
  const [isAnalysisCancelled, setIsAnalysisCancelled] = useState(false);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredIdeas, setFilteredIdeas] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [selectedPersona, setSelectedPersona] = useState(null);
  const [selectedAudience, setSelectedAudience] = useState(null);
  const [subreddit, setSubreddit] = useState('');
  const [redditListing, setRedditListing] = useState('hot');
  const [subredditQuery, setSubredditQuery] = useState('');
  const [subredditResults, setSubredditResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [ideaToDelete, setIdeaToDelete] = useState(null);
  const [redditModalData, setRedditModalData] = useState(null);
  const subredditInputRef = useRef(null);
  const subredditPortalRef = useRef(null);
  const [abortController, setAbortController] = useState(null);

  // Loading status messages based on source
  const loadingMessages = {
    reddit: [
      "Connecting to Reddit...",
      "Fetching discussions...",
      "Analyzing top comments...",
      "Extracting key insights...",
      "Organizing findings...",
      "Generating insights..."
    ],
    manual: [
      "Initializing analysis...",
      "Processing topic...",
      "Extracting insights...",
      "Identifying patterns...",
      "Organizing ideas...",
      "Finalizing content..."
    ]
  };

  // Update the isInputEmpty condition
  const isInputEmpty = !selectedSubreddit;

  // Fetch saved ideas on component mount
  useEffect(() => {
    fetchIdeas();
  }, []);

  const fetchIdeas = async () => {
    try {
      const response = await api.fetchYouIdeas(page);
      const newIdeas = response.ideas;
      setIdeas(prev => page === 1 ? newIdeas : [...prev, ...newIdeas]);
      setHasMore(page < response.totalPages);
    } catch (err) {
      console.error('Error fetching ideas:', err);
    }
  };

  const toggleFavorite = async (ideaId) => {
    try {
      const response = await api.toggleYouIdeaFavorite(ideaId);
      setIdeas(prev => 
        prev.map(idea => 
          idea._id === ideaId ? response : idea
        )
      );
    } catch (err) {
      console.error('Error toggling favorite:', err);
    }
  };

  const deleteIdea = async (ideaId) => {
    try {
      await api.deleteYouIdea(ideaId);
      setIdeas(prev => prev.filter(idea => idea._id !== ideaId));
      setIdeaToDelete(null); // Close the dialog after successful deletion
    } catch (err) {
      console.error('Error deleting idea:', err);
    }
  };

  const loadMore = () => {
    setPage(prev => prev + 1);
  };

  // Group ideas by timestamp (rounded to the nearest minute to group ideas generated together)
  const groupIdeas = (ideas) => {
    const groups = {};
    ideas.forEach(idea => {
      const timestamp = new Date(idea.createdAt);
      timestamp.setSeconds(0, 0); // Round to nearest minute
      const key = timestamp.getTime();
      if (!groups[key]) groups[key] = [];
      groups[key].push(idea);
    });
    return Object.values(groups);
  };

  const handleSubredditSelect = (newSubreddit) => {
    setSelectedSubreddit(newSubreddit);
    setSubredditQuery(''); // Clear the search input
    setSubreddit(''); // Clear the combobox value
    setSubredditError('');
  };

  const removeSubreddit = () => {
    setSelectedSubreddit('');
    setSubredditError('');
  };

  // Add this function back inside the YouIdea component, before fetchRedditIdeas
  const updateLoadingState = async (stage, delayTime = 1000) => {
    if (isAnalysisCancelled) {
      if (abortController) {
        abortController.abort();
      }
      throw new Error('ANALYSIS_CANCELLED');
    }
    setLoadingStatus(stage);
    setLoadingProgress(REDDIT_LOADING_STAGES[stage].progress);
    await delay(delayTime);
  };

  // The fetchRedditIdeas function can now use updateLoadingState
  const fetchRedditIdeas = async () => {
    if (!selectedSubreddit) {
      setSubredditError('Please select a subreddit');
      return;
    }
    
    const controller = new AbortController();
    setAbortController(controller);
    
    setIsGenerating(true);
    setIsLoading(true);
    setIsAnalysisCancelled(false);

    try {
      await updateLoadingState('INIT');
      await updateLoadingState('FETCH_INIT');
      await updateLoadingState('FETCH_POSTS', 2000);
      
      let redditData;
      try {
        redditData = await api.fetchRedditData(selectedSubreddit, redditListing, { signal: controller.signal });
      } catch (err) {
        console.error(`Failed to fetch data for r/${selectedSubreddit}:`, err);
        throw new Error(`Failed to fetch data from r/${selectedSubreddit}. Please try again or select a different subreddit.`);
      }

      await updateLoadingState('FETCH_COMMENTS_START', 1500);
      await updateLoadingState('FETCH_COMMENTS_PROGRESS', 2000);
      
      if (!redditData || redditData.length === 0) {
        throw new Error('No data found for selected subreddit');
      }

      await updateLoadingState('COMMENTS_ANALYSIS', 2500);
      await updateLoadingState('ANALYZING', 2000);

      const summary = `Based on analysis of r/${selectedSubreddit}:\n\n` +
        redditData.slice(0, 5).map(post => 
          `- ${post.title} (${post.score} upvotes, ${post.num_comments} comments)`
        ).join('\n');

      await updateLoadingState('GENERATING', 2000);
      
      const response = await api.generateYouIdeas({
        topic: `Reddit Analysis: r/${selectedSubreddit}`,
        content: summary,
        source: 'reddit',
        redditData: redditData.map(post => ({
          ...post,
          comments: post.comments || []
        })),
        signal: controller.signal
      });

      if (response && Array.isArray(response)) {
        const newIdeasWithTimestamp = response.map(idea => ({
          ...idea,
          isNew: true,
          addedAt: new Date().getTime(),
          redditData: redditData
        }));
        
        setIdeas(prev => [...newIdeasWithTimestamp, ...prev]);
        setSelectedSubreddit('');
        setViewMode('all-insights');
      }
      
    } catch (err) {
      console.error('Error in fetchRedditIdeas:', err);
      setError(err.message || 'Failed to analyze subreddit');
    } finally {
      setAbortController(null);
      setIsAnalysisCancelled(false);
      setIsLoading(false);
      setIsGenerating(false);
      setLoadingStatus(null);
      setLoadingProgress(0);
    }
  };

  // Update the searchSubreddits function
  const searchSubreddits = async (query) => {
    if (!query.trim() || query.length < 2) {
      setSubredditResults([]);
      return;
    }

    setIsSearching(true);
    setSubredditError('');
    
    try {
      const subreddits = await api.searchSubreddits(query);
      
      if (Array.isArray(subreddits) && subreddits.length > 0) {
        setSubredditResults(subreddits);
      } else {
        setSubredditError(`No subreddits found matching "${query}"`);
        setSubredditResults([]);
      }
    } catch (error) {
      console.error('Error searching subreddits:', error);
      setSubredditResults([]);
    } finally {
      setIsSearching(false);
    }
  };

  // Update the useEffect for debounced search
  useEffect(() => {
    const searchTimer = setTimeout(() => {
      if (subredditQuery.trim() && subredditQuery.length >= 2) {
        searchSubreddits(subredditQuery);
      } else {
        setSubredditResults([]);
        setSubredditError('');
      }
    }, 300);

    return () => clearTimeout(searchTimer);
  }, [subredditQuery]);

  // Update the useEffect for filtering ideas based on search term
  useEffect(() => {
    if (!searchTerm.trim()) {
      setFilteredIdeas(ideas);
      return;
    }

    const searchTermLower = searchTerm.toLowerCase();
    const filtered = ideas.filter(idea => {
      const searchableFields = [
        idea.topic,
        idea.generatedIdeas,
        // Include categories and their ideas if they exist
        ...(idea.categories?.flatMap(cat => [
          cat.name,
          ...cat.ideas.flatMap(idea => [idea.title, idea.explanation])
        ]) || [])
      ];

      return searchableFields.some(field => 
        field?.toLowerCase().includes(searchTermLower)
      );
    });

    setFilteredIdeas(filtered);
  }, [searchTerm, ideas]);

  // Add this function to check for existing ideas
  const hasExistingIdeas = () => {
    return ideas.length > 0;
  };

  const DeleteConfirmationDialog = ({ isOpen, onClose, onConfirm, isDarkMode }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center p-4 sm:p-0">
        <div 
          className="absolute inset-0 bg-black/20 backdrop-blur-sm"
          onClick={onClose}
        />
        <div className={`
          relative z-10 w-full max-w-sm rounded-lg p-4 sm:p-6 shadow-lg
          ${isDarkMode ? 'bg-[#1a1a1a] border border-[#333333]' : 'bg-white'}
        `}>
          <h3 className={`text-base sm:text-lg font-semibold mb-2 ${
            isDarkMode ? 'text-slate-200' : 'text-gray-900'
          }`}>
            Delete Insight
          </h3>
          <p className={`mb-4 text-sm ${
            isDarkMode ? 'text-slate-400' : 'text-gray-600'
          }`}>
            Are you sure you want to delete this insight? This action cannot be undone.
          </p>
          <div className="flex justify-end gap-3">
            <button
              onClick={onClose}
              className={`
                px-3 sm:px-4 py-2 rounded-md text-sm
                transition-colors duration-200
                ${isDarkMode 
                  ? 'text-slate-300 hover:text-slate-200 hover:bg-[#202020]' 
                  : 'text-gray-600 hover:text-gray-900 hover:bg-gray-100'
                }
              `}
            >
              Cancel
            </button>
            <button
              onClick={onConfirm}
              className={`
                px-3 sm:px-4 py-2 rounded-md text-sm text-white
                transition-colors duration-200
                bg-red-500 hover:bg-red-600
              `}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    );
  };

  // Handler for viewing all insights
  const handleViewAllInsights = () => {
    setViewMode('all-insights');
  };

  // Handler for going back to input view
  const handleBackToInput = () => {
    setViewMode('input');
  };

  // Handler for opening Reddit data modal
  const handleOpenRedditData = (redditData) => {
    console.log('Opening Reddit data modal with data:', redditData);
    setRedditModalData(redditData);
  };

  // Handler for closing Reddit data modal
  const handleCloseRedditData = () => {
    console.log('Closing Reddit data modal');
    setRedditModalData(null);
  };

  // Update the handleCancelAnalysis function
  const handleCancelAnalysis = async () => {
    try {
      setIsAnalysisCancelled(true);
      
      if (abortController) {
        abortController.abort();
      }

      await api.cancelAnalysis();

      // Clear all states
      setIsGenerating(false);
      setIsLoading(false);
      setLoadingStatus(null);
      setLoadingProgress(0);
      setError(null); // Clear any existing errors
      setSelectedSubreddit('');
      setViewMode('input');
      
    } catch (err) {
      console.error('Error cancelling analysis:', err);
      setError('Failed to cancel analysis');
    } finally {
      setAbortController(null);
      setIsAnalysisCancelled(false);
    }
  };

  // Add error state debugging
  useEffect(() => {
    console.log('Error state changed:', error);
    console.log('Loading state:', isLoading);
  }, [error, isLoading]);

  return (
    <div className={`h-screen flex flex-col overflow-hidden ${
      isDarkMode ? 'bg-[#121212]' : 'bg-white'
    }`}>
      <TopBar 
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        isDarkMode={isDarkMode}
        isSearchExpanded={isSearchExpanded}
        setIsSearchExpanded={setIsSearchExpanded}
        onViewAllInsights={handleViewAllInsights}
        onBackToInput={handleBackToInput}
        viewMode={viewMode}
        onClose={isInChatInterface ? onClose : undefined}
      />
      
      {viewMode === 'input' ? (
        <div className="flex-1 overflow-y-auto custom-scrollbar flex items-start sm:items-center justify-center">
          <div className="w-full max-w-3xl p-4 sm:p-8">
            <div className={`
              ${isDarkMode 
                ? 'bg-[#151515]/40 border border-[#333333] shadow-[0_2px_8px_0_rgba(0,0,0,0.08)]' 
                : 'bg-white/90 border border-gray-200/40 shadow-[0_2px_8px_0_rgba(0,0,0,0.04)]'
              }
              rounded-xl backdrop-blur-sm
            `}>
              <RedditAnalysisHeader isDarkMode={isDarkMode} />
              <div className="p-4 sm:p-6">
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  className="space-y-4 sm:space-y-6"
                >
                  <div className="space-y-4 sm:space-y-6 relative isolate">
                    <div className="relative z-[200]">
                      <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-2 gap-1">
                        <label className={`block text-xs font-medium ${
                          isDarkMode ? 'text-gray-300' : 'text-gray-700'
                        }`}>
                          Select a Subreddit to Analyze
                        </label>
                        <span className={`text-xs ${
                          isDarkMode ? 'text-gray-400' : 'text-gray-500'
                        }`}>
                          One subreddit at a time
                        </span>
                      </div>
                      <div className="relative">
                        <Combobox 
                          value={subreddit} 
                          onChange={(newValue) => {
                            if (newValue && typeof newValue === 'string') {
                              handleSubredditSelect(newValue);
                            }
                          }}
                        >
                          <div className="relative">
                            <div ref={subredditInputRef}>
                              <Combobox.Input
                                className={`
                                  w-full px-4 py-3 text-sm rounded-lg
                                  transition-all duration-200
                                  ${isDarkMode 
                                    ? 'bg-[#1a1a1a] border border-[#333333] text-slate-200 placeholder-slate-500 shadow-sm shadow-black/5' 
                                    : 'bg-gray-50/50 border border-gray-200/60 text-gray-900 placeholder-gray-400 shadow-sm shadow-black/[0.03]'
                                  }
                                  focus:outline-none
                                  ${isDarkMode 
                                    ? 'focus:border-indigo-500/40 focus:shadow-[0_2px_8px_0_rgba(99,102,241,0.05)]' 
                                    : 'focus:border-indigo-500/30 focus:shadow-[0_2px_8px_0_rgba(99,102,241,0.03)]'
                                  }
                                `}
                                placeholder="Enter a subreddit name (e.g., 'programming')"
                                onChange={(event) => {
                                  setSubredditQuery(event.target.value);
                                  setSubreddit(event.target.value);
                                }}
                                displayValue={(value) => value}
                              />
                            </div>

                            {/* Restore the search results dropdown */}
                            <Transition
                              show={subredditQuery.length >= 2}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-150"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 translate-y-1"
                              afterLeave={() => setSubredditQuery('')}
                            >
                              <Combobox.Options 
                                className={`
                                  absolute w-full mt-1 max-h-60 overflow-auto rounded-lg py-1
                                  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none
                                  ${isDarkMode 
                                    ? 'bg-[#1a1a1a] border border-[#333333]' 
                                    : 'bg-white border border-gray-200/60'
                                  }
                                  z-[9999]
                                  custom-scrollbar
                                `}
                              >
                                {isSearching ? (
                                  <div className={`
                                    px-4 py-3 text-sm flex items-center gap-2
                                    ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}
                                  `}>
                                    <svg className="animate-spin h-4 w-4" viewBox="0 0 24 24">
                                      <circle 
                                        className="opacity-25" 
                                        cx="12" 
                                        cy="12" 
                                        r="10" 
                                        stroke="currentColor" 
                                        strokeWidth="4"
                                        fill="none"
                                      />
                                      <path 
                                        className="opacity-75" 
                                        fill="currentColor" 
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                      />
                                    </svg>
                                    <span>Searching subreddits...</span>
                                  </div>
                                ) : subredditResults.length === 0 ? (
                                  <div className={`
                                    px-4 py-3 text-sm
                                    ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}
                                  `}>
                                    {subredditQuery.length < 2 
                                      ? "Type at least 2 characters to search" 
                                      : "No subreddits found"}
                                  </div>
                                ) : (
                                  subredditResults.map((sub) => (
                                    <Combobox.Option
                                      key={sub.name}
                                      value={sub.name}
                                      className={({ active }) => `
                                        relative cursor-pointer select-none py-3 px-4
                                        ${active
                                          ? isDarkMode
                                            ? 'bg-[#252525] text-gray-200'
                                            : 'bg-gray-50 text-gray-900'
                                          : isDarkMode
                                            ? 'text-gray-300'
                                            : 'text-gray-600'
                                        }
                                      `}
                                    >
                                      {({ selected }) => (
                                        <div className="flex items-center justify-between gap-4">
                                          <div className="flex items-center gap-2 min-w-0">
                                            <RedditIcon 
                                              isDarkMode={isDarkMode} 
                                              className="w-4 h-4" 
                                              showBackground={false}
                                            />
                                            <span className={`
                                              truncate
                                              ${selected ? 'font-medium' : 'font-normal'}
                                            `}>
                                              r/{sub.name}
                                            </span>
                                          </div>
                                          <span className={`
                                            text-xs whitespace-nowrap
                                            ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}
                                          `}>
                                            {sub.subscribers?.toLocaleString()} members
                                          </span>
                                        </div>
                                      )}
                                    </Combobox.Option>
                                  ))
                                )}
                              </Combobox.Options>
                            </Transition>

                            {/* Selected Subreddit Pill */}
                            {selectedSubreddit && (
                              <div className="flex flex-wrap gap-2 mt-3">
                                <motion.div
                                  initial={{ scale: 0.9, opacity: 0 }}
                                  animate={{ scale: 1, opacity: 1 }}
                                  exit={{ scale: 0.9, opacity: 0 }}
                                  className={`
                                    inline-flex items-center gap-1.5 px-3 py-1.5
                                    rounded-full text-xs font-medium
                                    ${isDarkMode 
                                      ? 'bg-[#1e1e1e] text-orange-300 border border-[#333333]' 
                                      : 'bg-orange-50 text-orange-600 border border-orange-200'
                                    }
                                  `}
                                >
                                  <RedditIcon 
                                    isDarkMode={isDarkMode} 
                                    className="w-3.5 h-3.5" 
                                    showBackground={false}
                                  />
                                  <span>r/{selectedSubreddit}</span>
                                  <button
                                    onClick={removeSubreddit}
                                    className="p-0.5 rounded-full hover:bg-orange-500/20"
                                  >
                                    <XMarkIcon className="w-3 h-3" />
                                  </button>
                                </motion.div>
                              </div>
                            )}

                            {/* Error Message */}
                            {subredditError && (
                              <div className={`
                                mt-2 text-xs px-3 py-2 rounded-md
                                ${isDarkMode 
                                  ? 'bg-red-500/10 text-red-400 border border-red-500/20' 
                                  : 'bg-red-50 text-red-600 border border-red-200'
                                }
                              `}>
                                {subredditError}
                              </div>
                            )}

                            {/* Helper text below input */}
                            <p className={`mt-2 text-xs ${
                              isDarkMode ? 'text-gray-400' : 'text-gray-500'
                            }`}>
                              We'll analyze up to 100 top posts and their discussions from your selected subreddit
                            </p>

                            {/* Processing time note */}
                            <p className={`mt-1 text-xs ${
                              isDarkMode ? 'text-gray-400' : 'text-gray-500'
                            }`}>
                              ⏱️ Analysis typically takes 45-60 seconds to complete
                            </p>

                            {/* Update the Generate button text */}
                            <button
                              onClick={fetchRedditIdeas}
                              disabled={isLoading || isInputEmpty}
                              className={`
                                w-full py-3 rounded-lg text-xs font-medium mt-6
                                transition-all duration-200
                                ${isDarkMode
                                  ? 'bg-gradient-to-r from-orange-600/20 to-orange-500/20 hover:from-orange-600/30 hover:to-orange-500/30 text-orange-300 border border-orange-500/30'
                                  : 'bg-gradient-to-r from-orange-500 to-orange-600 hover:from-orange-600 hover:to-orange-700 text-white'
                                }
                                disabled:opacity-50 disabled:cursor-not-allowed
                                relative overflow-hidden
                              `}
                            >
                              <span className={`
                                transition-opacity duration-200
                                ${isLoading ? 'opacity-0' : 'opacity-100'}
                              `}>
                                {selectedSubreddit 
                                  ? `Analyze r/${selectedSubreddit}`
                                  : 'Select a Subreddit to Begin Analysis'}
                              </span>
                            </button>

                            {/* Update loading messages */}
                            {isLoading && (
                              <RedditLoadingState 
                                currentStage={REDDIT_LOADING_STAGES[loadingStatus]}
                                progress={loadingProgress}
                                isDarkMode={isDarkMode}
                                subreddit={selectedSubreddit}
                              />
                            )}
                          </div>
                        </Combobox>
                      </div>
                    </div>

                    <div className="flex flex-col sm:flex-row gap-4 relative z-[150]">
                      <div className={`
                        p-4 rounded-lg space-y-4 relative w-full sm:w-[40%]
                        ${isDarkMode 
                          ? 'bg-[#1a1a1a] border-[1.5px] border-[#333333] shadow-[0_1px_3px_0_rgba(0,0,0,0.05)]' 
                          : 'bg-gray-50/50 border-[1.5px] border-gray-200/50 shadow-[0_1px_3px_0_rgba(0,0,0,0.02)]'
                        }
                      `}>
                        <div className="flex items-center justify-between">
                          <label className={`text-xs font-medium ${
                            isDarkMode ? 'text-slate-300' : 'text-gray-700'
                          }`}>
                            Sort By
                          </label>
                        </div>
                        <div className="mt-4">
                          <RedditListingDropdown
                            value={redditListing}
                            onChange={setRedditListing}
                            isDarkMode={isDarkMode}
                          />
                        </div>
                      </div>

                      <div className={`
                        p-4 rounded-lg space-y-4 relative flex-1
                        ${isDarkMode 
                          ? 'bg-[#1a1a1a] border-[1.5px] border-[#333333] shadow-[0_1px_3px_0_rgba(0,0,0,0.05)]' 
                          : 'bg-gray-50/50 border-[1.5px] border-gray-200/50 shadow-[0_1px_3px_0_rgba(0,0,0,0.02)]'
                        }
                      `}>
                        <div className="flex items-center justify-between">
                          <label className={`text-xs font-medium ${
                            isDarkMode ? 'text-slate-300' : 'text-gray-700'
                          }`}>
                            Customize Analysis
                          </label>
                        </div>
                        <div className="flex gap-3">
                          <PersonaDropdown
                            onSelectPersona={setSelectedPersona}
                            selectedPersona={selectedPersona}
                            compact={true}
                          />
                          <TargetAudienceDropdown
                            onSelectAudience={setSelectedAudience}
                            selectedAudience={selectedAudience}
                            compact={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <AllInsightsView 
          ideas={ideas}
          isDarkMode={isDarkMode}
          onBackToInput={handleBackToInput}
          searchTerm={searchTerm}
          onToggleFavorite={toggleFavorite}
          onDelete={setIdeaToDelete}
          markdownStyles={markdownStyles}
          onViewRedditData={handleOpenRedditData}
        />
      )}
      
      {/* Delete confirmation dialog */}
      <DeleteConfirmationDialog
        isOpen={ideaToDelete !== null}
        onClose={() => setIdeaToDelete(null)}
        onConfirm={() => deleteIdea(ideaToDelete)}
        isDarkMode={isDarkMode}
      />

      {/* Reddit Data Modal */}
      <RedditDataModal
        isOpen={redditModalData !== null}
        onClose={handleCloseRedditData}
        redditData={redditModalData || []}
        isDarkMode={isDarkMode}
      />

      {/* Reddit Analysis Overlay */}
      <RedditAnalysisOverlay
        isVisible={isLoading}
        currentStage={REDDIT_LOADING_STAGES[loadingStatus]}
        progress={loadingProgress}
        isDarkMode={isDarkMode}
        onCancel={handleCancelAnalysis}
      />
    </div>
  );
};

export default YouIdea;
