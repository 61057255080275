import React from 'react';

const VoiceNotesSkeleton = ({ isDarkMode, count = 3 }) => {
  return Array(count).fill(0).map((_, index) => (
    <div 
      key={index}
      className={`w-full mb-4 rounded-lg p-4 border ${
        isDarkMode 
          ? 'bg-[#151515]/95 border-[#333333]' 
          : 'bg-white border-gray-100'
      }`}
    >
      {/* Date and time */}
      <div className="flex items-center gap-2 mb-3">
        <div className={`h-3 w-16 rounded ${
          isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-100'
        } animate-pulse`} />
        <div className={`h-3 w-3 rounded-full ${
          isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-100'
        } animate-pulse`} />
        <div className={`h-3 w-12 rounded ${
          isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-100'
        } animate-pulse`} />
      </div>

      {/* Title */}
      <div className={`h-5 w-3/4 rounded mb-3 ${
        isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-100'
      } animate-pulse`} />

      {/* Content lines */}
      <div className="space-y-2">
        <div className={`h-3 w-full rounded ${
          isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-100'
        } animate-pulse`} />
        <div className={`h-3 w-5/6 rounded ${
          isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-100'
        } animate-pulse`} />
      </div>

      {/* Tags */}
      <div className="flex gap-2 mt-4">
        <div className={`h-5 w-16 rounded-full ${
          isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-100'
        } animate-pulse`} />
        <div className={`h-5 w-20 rounded-full ${
          isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-100'
        } animate-pulse`} />
      </div>
    </div>
  ));
};

export default VoiceNotesSkeleton;