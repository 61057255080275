import React, { useRef, useState } from 'react';

const ImpactGoingForward = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const tableRef = useRef(null);

  const formatCurrency = (value) => {
    if (value === null || value === undefined) return '';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const formatPercentage = (value) => {
    return `${value}%`;
  };

  const toggleFullscreen = async () => {
    if (!document.fullscreenElement) {
      await tableRef.current.requestFullscreen();
      setIsFullscreen(true);
    } else {
      await document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  const tableData = [
    {
      id: 'BU1',
      metrics: {
        H1: { target: 1000000, actuals: 496314, achievement: 50 },
        Q1: { target: 395000, actuals: 232269, achievement: 59 },
        'Apr-24': { target: 100000, actuals: 68624, achievement: 69 },
        'May-24': { target: 125000, actuals: 72060, achievement: 58 },
        'Jun-24': { target: 170000, actuals: 91585, achievement: 54 },
        Q2: { target: 605000, actuals: 264046, achievement: 44 },
        'Jul-24': { target: 210000, actuals: 89739, achievement: 43 },
        'Aug-24': { target: 195000, actuals: 89307, achievement: 46 },
        'Sep-24': { target: 200000, actuals: 85000, achievement: 43 },
        H2: { target: 1455000, actuals: null, achievement: null },
        'Carry Over': { target: 503686, actuals: null, achievement: null },
        'H2+Carry Over': { target: 1958686, actuals: null, achievement: null }
      }
    },
    {
      id: 'BU2',
      metrics: {
        H1: { target: 1500000, actuals: 648686, achievement: 43 },
        Q1: { target: 750000, actuals: 345504, achievement: 46 },
        'Apr-24': { target: 250000, actuals: 105151, achievement: 42 },
        'May-24': { target: 250000, actuals: 146029, achievement: 58 },
        'Jun-24': { target: 250000, actuals: 94324, achievement: 38 },
        Q2: { target: 750000, actuals: 303182, achievement: 40 },
        'Jul-24': { target: 250000, actuals: 121512, achievement: 49 },
        'Aug-24': { target: 250000, actuals: 146670, achievement: 59 },
        'Sep-24': { target: 250000, actuals: 35000, achievement: 14 },
        H2: { target: 1500000, actuals: null, achievement: null },
        'Carry Over': { target: 851314, actuals: null, achievement: null },
        'H2+Carry Over': { target: 2351314, actuals: null, achievement: null }
      }
    },
    {
      id: 'BU3',
      metrics: {
        H1: { target: 218125, actuals: 109765, achievement: 50 },
        Q1: { target: 90850, actuals: 45622, achievement: 50 },
        'Apr-24': { target: 23150, actuals: 4334, achievement: 19 },
        'May-24': { target: 26650, actuals: 31452, achievement: 118 },
        'Jun-24': { target: 41050, actuals: 9836, achievement: 24 },
        Q2: { target: 127275, actuals: 64143, achievement: 50 },
        'Jul-24': { target: 30825, actuals: 34682, achievement: 113 },
        'Aug-24': { target: 40825, actuals: 19461, achievement: 48 },
        'Sep-24': { target: 55625, actuals: 10000, achievement: 18 },
        H2: { target: 437775, actuals: null, achievement: null },
        'Carry Over': { target: 108360, actuals: null, achievement: null },
        'H2+Carry Over': { target: 546135, actuals: null, achievement: null }
      }
    },
    {
      id: 'BU4',
      metrics: {
        H1: { target: 329000, actuals: 103450, achievement: 31 },
        Q1: { target: 171000, actuals: 69141, achievement: 40 },
        'Apr-24': { target: 42000, actuals: 21369, achievement: 51 },
        'May-24': { target: 67000, actuals: 17539, achievement: 26 },
        'Jun-24': { target: 62000, actuals: 30233, achievement: 49 },
        Q2: { target: 158000, actuals: 34309, achievement: 22 },
        'Jul-24': { target: 46000, actuals: 15796, achievement: 34 },
        'Aug-24': { target: 51000, actuals: 11513, achievement: 23 },
        'Sep-24': { target: 61000, actuals: 7000, achievement: 11 },
        H2: { target: 336000, actuals: null, achievement: null },
        'Carry Over': { target: 225551, actuals: null, achievement: null },
        'H2+Carry Over': { target: 561551, actuals: null, achievement: null }
      }
    },
    {
      id: 'New Business',
      metrics: {
        H1: { target: 180667, actuals: 41251, achievement: 23 },
        Q1: { target: 27000, actuals: 33671, achievement: 125 },
        'Apr-24': { target: 8000, actuals: 11466, achievement: 143 },
        'May-24': { target: 8000, actuals: 7539, achievement: 94 },
        'Jun-24': { target: 11000, actuals: 14666, achievement: 133 },
        Q2: { target: 153667, actuals: 7580, achievement: 5 },
        'Jul-24': { target: 51222, actuals: 800, achievement: 2 },
        'Aug-24': { target: 51222, actuals: 3780, achievement: 7 },
        'Sep-24': { target: 51222, actuals: 3000, achievement: 6 },
        H2: { target: 294333, actuals: null, achievement: null },
        'Carry Over': { target: 139416, actuals: null, achievement: null },
        'H2+Carry Over': { target: 433749, actuals: null, achievement: null }
      }
    },
    {
      id: 'Grand Total',
      metrics: {
        H1: { target: 3227792, actuals: 1399466, achievement: 43 },
        Q1: { target: 1433850, actuals: 726207, achievement: 51 },
        'Apr-24': { target: 423150, actuals: 210944, achievement: 50 },
        'May-24': { target: 476650, actuals: 274619, achievement: 58 },
        'Jun-24': { target: 534050, actuals: 240644, achievement: 45 },
        Q2: { target: 1793942, actuals: 673259, achievement: 38 },
        'Jul-24': { target: 588047, actuals: 262528, achievement: 45 },
        'Aug-24': { target: 588047, actuals: 270731, achievement: 46 },
        'Sep-24': { target: 617847, actuals: 140000, achievement: 23 },
        H2: { target: 4023108, actuals: null, achievement: null },
        'Carry Over': { target: 1828326, actuals: null, achievement: null },
        'H2+Carry Over': { target: 5851434, actuals: null, achievement: null }
      }
    }
  ];

  const getBuStyle = (buId) => {
    const styles = {
      'BU1': 'bg-[#1c1c1c] text-[#e2e2e2] border-l-4 border-l-[#ff4d8d]',
      'BU2': 'bg-[#1c1c1c] text-[#e2e2e2] border-l-4 border-l-[#06b6d4]',
      'BU3': 'bg-[#1c1c1c] text-[#e2e2e2] border-l-4 border-l-[#22c55e]',
      'BU4': 'bg-[#1c1c1c] text-[#e2e2e2] border-l-4 border-l-[#eab308]',
      'New Business': 'bg-[#1c1c1c] text-[#e2e2e2] border-l-4 border-l-[#3b82f6]',
      'Grand Total': 'bg-[#18181b] text-[#e2e2e2] border-l-4 border-l-[#6366f1]'
    };
    return styles[buId] || '';
  };

  return (
    <div ref={tableRef} className="bg-[#09090b] rounded-xl border border-[#27272a] m-4">
      <div className="flex justify-between items-center px-6 py-3 border-b border-[#27272a]">
        <h2 className="text-[#e2e2e2] font-medium text-sm">Impact Going Forward</h2>
        <button
          onClick={toggleFullscreen}
          className="p-1.5 rounded-md bg-black hover:bg-[#27272a] transition-colors"
          title={isFullscreen ? "Exit fullscreen" : "Enter fullscreen"}
        >
          {isFullscreen ? (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-[#e2e2e2]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 9L4 4m0 0l5-5M4 4l5 5M15 9l5-5m0 0l-5-5m5 5l-5 5M9 15l-5 5m0 0l5 5M4 20l5-5M15 15l5 5m0 0l-5 5m5-5l-5-5" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-[#e2e2e2]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5v-4m0 4h-4m4 0l-5-5" />
            </svg>
          )}
        </button>
      </div>

      <div className={`overflow-x-auto ${isFullscreen ? 'h-screen' : ''}`}>
        <div className="p-4">
          <table className="min-w-full bg-black rounded-lg overflow-hidden">
            <thead>
              <tr className="border-b border-[#27272a]">
                <th className="px-3 py-2 text-left bg-black text-[#a1a1aa] font-medium text-xs rounded-tl-lg">BU</th>
                <th className="px-3 py-2 text-left bg-black text-[#a1a1aa] font-medium text-xs">Metrics</th>
                <th className="px-3 py-2 text-center bg-[#18181b] text-[#fbbf24] font-medium text-xs border-x border-[#27272a]">H1</th>
                <th className="px-3 py-2 text-center bg-[#18181b] text-[#c084fc] font-medium text-xs border-x border-[#27272a]">Q1</th>
                <th className="px-3 py-2 text-center bg-black text-[#60a5fa] font-medium text-xs">Apr-24</th>
                <th className="px-3 py-2 text-center bg-black text-[#60a5fa] font-medium text-xs">May-24</th>
                <th className="px-3 py-2 text-center bg-black text-[#60a5fa] font-medium text-xs">Jun-24</th>
                <th className="px-3 py-2 text-center bg-[#18181b] text-[#c084fc] font-medium text-xs border-x border-[#27272a]">Q2</th>
                <th className="px-3 py-2 text-center bg-black text-[#60a5fa] font-medium text-xs">Jul-24</th>
                <th className="px-3 py-2 text-center bg-black text-[#60a5fa] font-medium text-xs">Aug-24</th>
                <th className="px-3 py-2 text-center bg-black text-[#60a5fa] font-medium text-xs">Sep-24</th>
                <th className="px-3 py-2 text-center bg-[#18181b] text-[#fbbf24] font-medium text-xs border-x border-[#27272a]">H2</th>
                <th className="px-3 py-2 text-center bg-[#18181b] text-[#60a5fa] font-medium text-xs border-x border-[#27272a]">Carry Over</th>
                <th className="px-3 py-2 text-center bg-[#18181b] text-[#fbbf24] font-medium text-xs border-x border-[#27272a] rounded-tr-lg">H2+Carry Over</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-[#27272a]">
              {tableData.map((bu, buIndex) => (
                <React.Fragment key={bu.id}>
                  <tr>
                    <td rowSpan="3" className={`px-3 py-1.5 font-medium text-xs ${getBuStyle(bu.id)}`}>
                      {bu.id}
                    </td>
                    <td className="px-3 py-1.5 bg-black text-[#f87171] text-xs font-medium">Target</td>
                    {Object.values(bu.metrics).map((metric, idx) => {
                      const isHighlighted = [0, 1, 5, 9, 10, 11].includes(idx);
                      return (
                        <td key={idx} className={`px-3 py-1.5 text-right text-[#e2e2e2] font-mono text-xs
                          ${isHighlighted ? 'bg-[#18181b] border-x border-[#27272a]' : 'bg-black'}`}>
                          {formatCurrency(metric.target)}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <td className="px-3 py-1.5 bg-black text-[#4ade80] text-xs font-medium">Actuals</td>
                    {Object.values(bu.metrics).map((metric, idx) => {
                      const isHighlighted = [0, 1, 5, 9, 10, 11].includes(idx);
                      return (
                        <td key={idx} className={`px-3 py-1.5 text-right text-[#e2e2e2] font-mono text-xs
                          ${isHighlighted ? 'bg-[#18181b] border-x border-[#27272a]' : 'bg-black'}`}>
                          {formatCurrency(metric.actuals)}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <td className="px-3 py-1.5 bg-black text-[#60a5fa] text-xs font-medium">% Achievement</td>
                    {Object.values(bu.metrics).map((metric, idx) => {
                      const isHighlighted = [0, 1, 5, 9, 10, 11].includes(idx);
                      const isLastRow = buIndex === tableData.length - 1;
                      const isLastCol = idx === Object.values(bu.metrics).length - 1;
                      
                      return (
                        <td 
                          key={idx} 
                          className={`px-3 py-1.5 text-right font-medium text-xs
                            ${isHighlighted ? 'bg-[#18181b] border-x border-[#27272a]' : 'bg-black'}
                            ${metric.achievement >= 100 ? 'text-[#4ade80]' : 
                              metric.achievement >= 80 ? 'text-[#fbbf24]' : 'text-[#f87171]'}
                            ${isLastRow && idx === 0 ? 'rounded-bl-lg' : ''}
                            ${isLastRow && isLastCol ? 'rounded-br-lg' : ''}`}
                        >
                          {metric.achievement ? formatPercentage(metric.achievement) : ''}
                        </td>
                      );
                    })}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ImpactGoingForward; 