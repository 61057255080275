import React, { useEffect, useState, memo, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../../context/ThemeContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { contentStrategyProgressService } from '../../services/contentStrategyProgressService';
import { 
  SparklesIcon,
  DocumentTextIcon,
  ChevronDownIcon,
  StarIcon,
  CheckIcon,
  DocumentDuplicateIcon,
  PlusSmallIcon,
  MinusSmallIcon,
  EllipsisHorizontalIcon,
  ArrowDownTrayIcon,
  XMarkIcon,
  FolderIcon,
} from '@heroicons/react/24/outline';
import { StarIcon as StarIconSolid } from '@heroicons/react/24/solid';
import {
  LightBulbIcon,
  WrenchScrewdriverIcon,
  BookOpenIcon,
  AcademicCapIcon,
  ExclamationTriangleIcon,
  ChatBubbleBottomCenterTextIcon,
  RocketLaunchIcon,
  HeartIcon,
  PuzzlePieceIcon,
  QuestionMarkCircleIcon,
  ArrowTrendingUpIcon,
  CalculatorIcon,
  BeakerIcon,
} from '@heroicons/react/24/outline';
import ContentStrategyPDFDownloader from './ContentStrategyPDFDownloader';
import { saveIdea, getClientFolders, moveIdeaToFolder } from '../../api';
import { BookmarkIcon } from '@heroicons/react/24/outline';

// Animation variants
const pageVariants = {
  initial: { opacity: 0, y: 20 },
  animate: { 
    opacity: 1, 
    y: 0,
    transition: { duration: 0.4, ease: "easeOut" }
  },
  exit: { 
    opacity: 0,
    y: 20,
    transition: { duration: 0.3, ease: "easeIn" }
  }
};

const expandAnimation = {
  initial: { height: 0, opacity: 0 },
  animate: { 
    height: "auto", 
    opacity: 1,
    transition: { 
      height: { duration: 0.3, ease: "easeOut" },
      opacity: { duration: 0.2, delay: 0.1 }
    }
  },
  exit: { 
    height: 0,
    opacity: 0,
    transition: { 
      height: { duration: 0.3, ease: "easeIn" },
      opacity: { duration: 0.2 }
    }
  }
};

const ideaGroupVariants = {
  initial: { opacity: 0, x: -10 },
  animate: { 
    opacity: 1, 
    x: 0,
    transition: { duration: 0.3 }
  }
};

const ideaCardVariants = {
  initial: { opacity: 0, y: 10 },
  animate: { 
    opacity: 1, 
    y: 0,
    transition: { duration: 0.2 }
  }
};

// Loading Components
const LoadingSkeleton = memo(({ isDarkMode, selectedPersona }) => (
  <motion.div
    variants={pageVariants}
    initial="initial"
    animate="animate"
    exit="exit"
    className="w-full max-w-4xl mx-auto space-y-4 p-4"
  >
    {/* Loading Action Bar */}
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className={`sticky top-0 z-10 mb-4 rounded-lg ring-1 backdrop-blur-lg ${
        isDarkMode 
          ? 'bg-[#151515]/90 ring-[#333333]' 
          : 'bg-blue-50/90 ring-blue-100'
      }`}
    >
      <div className="px-4 py-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <div className={`h-4 w-32 rounded animate-pulse ${
              isDarkMode ? 'bg-blue-800/20' : 'bg-blue-100'
            }`} />
            {selectedPersona?.name && (
              <>
                <div className={`h-4 w-4 rounded animate-pulse ${
                  isDarkMode ? 'bg-blue-800/20' : 'bg-blue-100'
                }`} />
                <div className={`h-4 w-32 rounded animate-pulse ${
                  isDarkMode ? 'bg-blue-800/20' : 'bg-blue-100'
                }`} />
              </>
            )}
          </div>
          <div className="flex items-center gap-2">
            <div className={`h-7 w-20 rounded-md animate-pulse ${
              isDarkMode ? 'bg-[#1a1a1a]' : 'bg-blue-100'
            }`} />
            <div className={`h-7 w-16 rounded-md animate-pulse ${
              isDarkMode ? 'bg-[#1a1a1a]' : 'bg-blue-100'
            }`} />
          </div>
        </div>
      </div>
    </motion.div>

    {/* Loading Frameworks */}
    {[1, 2, 3].map(index => (
      <motion.div
        key={index}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4, delay: index * 0.1 }}
        className={`rounded-xl ring-1 ${
          isDarkMode 
            ? 'bg-[#151515] ring-[#333333]' 
            : 'bg-white/50 ring-slate-200/50'
        }`}
      >
        {/* Framework Header */}
        <div className="px-5 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <div className={`flex items-center justify-center w-8 h-8 rounded-lg ${
                isDarkMode 
                  ? 'bg-slate-800/50' 
                  : 'bg-slate-100/80'
              }`}>
                <div className={`h-4 w-4 rounded animate-pulse ${
                  isDarkMode ? 'bg-slate-700' : 'bg-slate-200'
                }`} />
              </div>
              <div className={`h-4 w-48 rounded animate-pulse ${
                isDarkMode ? 'bg-slate-800/50' : 'bg-slate-200'
              }`} />
            </div>

            <div className="flex items-center gap-6">
              <div className="flex items-center gap-4">
                <div className={`h-3 w-20 rounded animate-pulse ${
                  isDarkMode ? 'bg-slate-800/50' : 'bg-slate-200'
                }`} />
                <div className={`h-3 w-20 rounded animate-pulse ${
                  isDarkMode ? 'bg-slate-800/50' : 'bg-slate-200'
                }`} />
              </div>
              <div className={`h-4 w-px ${
                isDarkMode ? 'bg-[#333333]' : 'bg-slate-200'
              }`} />
              <div className={`h-5 w-5 rounded animate-pulse ${
                isDarkMode ? 'bg-slate-800/50' : 'bg-slate-200'
              }`} />
            </div>
          </div>
        </div>

        {/* Framework Content */}
        <div className="p-4 space-y-4">
          {[1, 2].map(groupIndex => (
            <div key={groupIndex} className="space-y-2">
              {/* Group Header */}
              <div className={`flex items-center justify-between py-2 px-1`}>
                <div className="flex items-center gap-2">
                  <div className={`h-4 w-4 rounded animate-pulse ${
                    isDarkMode ? 'bg-slate-800/50' : 'bg-slate-200'
                  }`} />
                  <div className={`h-3 w-24 rounded animate-pulse ${
                    isDarkMode ? 'bg-slate-800/50' : 'bg-slate-200'
                  }`} />
                  <div className={`h-3 w-16 rounded animate-pulse ${
                    isDarkMode ? 'bg-slate-800/50' : 'bg-slate-200'
                  }`} />
                </div>
                <div className={`h-6 w-6 rounded animate-pulse ${
                  isDarkMode ? 'bg-slate-800/50' : 'bg-slate-200'
                }`} />
              </div>

              {/* Group Content */}
              <div className={`rounded-lg overflow-hidden ring-1 ${
                isDarkMode 
                  ? 'bg-[#1a1a1a] ring-[#333333]' 
                  : 'bg-slate-50/50 ring-slate-200/50'
              }`}>
                <div className={`divide-y ${
                  isDarkMode ? 'divide-[#333333]' : 'divide-slate-200/50'
                }`}>
                  {[1, 2, 3].map(ideaIndex => (
                    <div
                      key={ideaIndex}
                      className="p-4"
                    >
                      <div className="space-y-3">
                        <div className={`h-4 rounded animate-pulse ${
                          isDarkMode ? 'bg-slate-800/50' : 'bg-slate-200'
                        }`} style={{ width: '85%' }} />
                        <div className={`h-4 rounded animate-pulse ${
                          isDarkMode ? 'bg-slate-800/50' : 'bg-slate-200'
                        }`} style={{ width: '65%' }} />
                        <div className={`h-6 w-32 rounded-full animate-pulse ${
                          isDarkMode ? 'bg-slate-800/50' : 'bg-slate-200'
                        }`} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </motion.div>
    ))}
  </motion.div>
));

// Utility Components
const CopyButton = memo(({ content, isDarkMode }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(content);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <button
      onClick={handleCopy}
      className={`p-2 rounded-lg transition-colors ${
        isDarkMode ? 'hover:bg-slate-700' : 'hover:bg-slate-100'
      }`}
    >
      {copied ? (
        <CheckIcon className="w-4 h-4 text-green-500" />
      ) : (
        <DocumentDuplicateIcon className="w-4 h-4 text-slate-400" />
      )}
    </button>
  );
});

// Content Components
const IdeaCard = memo(({ idea, isDarkMode, index, framework, group, onSave }) => {
  const [copied, setCopied] = useState(false);
  const [savingState, setSavingState] = useState('idle');

  const handleCopy = (e) => {
    e.stopPropagation();
    const textToCopy = [
      idea.content,
      idea.details,
      idea.targetOutcome ? `Target Outcome: ${idea.targetOutcome}` : null
    ]
      .filter(Boolean)
      .join('\n\n');
      
    navigator.clipboard.writeText(textToCopy);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleSave = async (e) => {
    e.stopPropagation();
    setSavingState('saving');
    try {
      const savedIdeaResponse = await saveIdea({
        content: idea.content,
        details: idea.details || '',
        targetOutcome: idea.targetOutcome || '',
        source: 'content-strategy',
        sourceId: framework?._id || idea._id,
        category: framework?.frameworkName || 'Uncategorized',
        tags: [
          group?.groupName && `Type: ${group.groupName}`
        ].filter(Boolean),
        status: 'pending'
      });

      setSavingState('saved');
      onSave(savedIdeaResponse);
      return savedIdeaResponse.data || savedIdeaResponse;
    } catch (error) {
      console.error('Error saving idea:', error);
      toast.error('Failed to save idea');
      setSavingState('idle');
      throw error;
    }
  };

  return (
    <motion.div
      variants={ideaCardVariants}
      initial="initial"
      animate="animate"
      custom={index}
      onClick={handleCopy}
      className={`w-full p-4 transition-colors group text-left relative ${
        isDarkMode ? 'hover:bg-[#202020]' : 'hover:bg-slate-50/80'
      }`}
    >
      <div className="flex items-center justify-between gap-4">
        <div className="flex-1 space-y-2">
          <div 
            className={`text-sm leading-relaxed whitespace-pre-wrap ${
              isDarkMode ? 'text-slate-300' : 'text-slate-700'
            }`}
          >
            {idea.content}
          </div>
          
          {idea.details && (
            <div 
              className={`text-xs whitespace-pre-wrap ${
                isDarkMode ? 'text-slate-400' : 'text-slate-500'
              }`}
            >
              {idea.details}
            </div>
          )}
          
          {idea.targetOutcome && (
            <span className={`inline-flex text-xs px-2 py-1 rounded-full ${
              isDarkMode 
                ? 'bg-[#1a1a1a] text-slate-300 ring-1 ring-[#333333]' 
                : 'bg-slate-100/60 text-slate-600 ring-1 ring-slate-200/50'
            }`}>
              {idea.targetOutcome}
            </span>
          )}
        </div>
        
        <div className="flex items-center gap-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
          <button
            onClick={handleSave}
            className={`p-1.5 rounded-md transition-colors ${
              isDarkMode 
                ? 'hover:bg-[#1e1e1e]/80 text-slate-400 hover:text-slate-300' 
                : 'hover:bg-slate-200/80 text-slate-500 hover:text-slate-600'
            }`}
            title="Save to Ideas"
          >
            <BookmarkIcon 
              className={`w-4 h-4 transition-all duration-200 ${
                savingState === 'saving' 
                  ? 'animate-pulse opacity-50' 
                  : savingState === 'saved'
                    ? 'fill-current'
                    : ''
              }`} 
            />
          </button>
          <div className={`transition-opacity duration-200 ${
            copied ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'
          }`}>
            {copied ? (
              <CheckIcon className="w-4 h-4 text-green-500" />
            ) : (
              <DocumentDuplicateIcon className="w-4 h-4 text-slate-400/80" />
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
});

// Only keep the SERIES_ICONS mapping
const SERIES_ICONS = {
  expertTips: LightBulbIcon,
  practicalHacks: WrenchScrewdriverIcon,
  resources: DocumentTextIcon,
  comprehensiveGuides: BookOpenIcon,
  keyLessons: AcademicCapIcon,
  commonMistakes: ExclamationTriangleIcon,
  strategicInsights: ChatBubbleBottomCenterTextIcon,
  quickStart: RocketLaunchIcon,
  emotionalChallenges: HeartIcon,
  implementationChallenges: PuzzlePieceIcon,
  commonMisconceptions: QuestionMarkCircleIcon,
  industryTrends: ArrowTrendingUpIcon,
  impactAnalysis: CalculatorIcon,
  methodologyBreakdown: BeakerIcon,
};

// Update the IdeaGroup component to include save button
const IdeaGroup = memo(({ group, framework, isDarkMode, onSaveGroup }) => {
  const [savingState, setSavingState] = useState('idle');
  const groupKey = group.groupName
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
  const GroupIcon = SERIES_ICONS[groupKey] || DocumentTextIcon;

  const handleSaveGroup = async () => {
    setSavingState('saving');
    try {
      const savePromises = group.ideas.map(idea => 
        saveIdea({
          content: idea.content,
          details: idea.details || '',
          targetOutcome: idea.targetOutcome || '',
          source: 'content-strategy',
          sourceId: framework._id,
          category: framework.frameworkName || 'Uncategorized',
          tags: [
            group.groupName && `Type: ${group.groupName}`
          ].filter(Boolean),
          status: 'pending'
        })
      );

      const savedIdeas = await Promise.all(savePromises);
      setSavingState('saved');
      onSaveGroup(savedIdeas);
      toast.success(`${group.ideas.length} ideas saved successfully`);
    } catch (error) {
      console.error('Error saving group:', error);
      toast.error('Failed to save ideas');
      setSavingState('idle');
    }
  };

  return (
    <motion.div 
      variants={ideaGroupVariants}
      initial="initial"
      animate="animate"
      className={`rounded-xl ring-1 overflow-hidden ${
        isDarkMode 
          ? 'bg-[#1a1a1a] ring-[#333333]' 
          : 'bg-slate-50 ring-slate-200'
      }`}
    >
      {/* Group Header */}
      <div className={`px-4 py-3 border-b ${
        isDarkMode ? 'border-[#333333]' : 'border-slate-200'
      }`}>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <GroupIcon className={`w-4 h-4 ${
              isDarkMode ? 'text-slate-400' : 'text-slate-500'
            }`} />
            <span className={`text-sm font-medium ${
              isDarkMode ? 'text-slate-300' : 'text-slate-700'
            }`}>
              {group.groupName}
            </span>
            <span className={`text-xs ${
              isDarkMode ? 'text-slate-500' : 'text-slate-400'
            }`}>
              {group.ideas?.length || 0} ideas
            </span>
          </div>

          <button
            onClick={handleSaveGroup}
            className={`p-1.5 rounded-md transition-colors ${
              isDarkMode 
                ? 'hover:bg-[#252525] text-slate-400 hover:text-slate-300' 
                : 'hover:bg-slate-200/80 text-slate-500 hover:text-slate-600'
            }`}
            title="Save all ideas in this group"
          >
            <BookmarkIcon 
              className={`w-4 h-4 transition-all duration-200 ${
                savingState === 'saving' 
                  ? 'animate-pulse opacity-50' 
                  : savingState === 'saved'
                    ? 'fill-current'
                    : ''
              }`} 
            />
          </button>
        </div>
      </div>

      {/* Group Content */}
      <div className={`divide-y ${
        isDarkMode ? 'divide-[#333333]' : 'divide-slate-200'
      }`}>
        {group.ideas.map((idea, index) => (
          <IdeaCard
            key={index}
            idea={idea}
            isDarkMode={isDarkMode}
            index={index}
            framework={framework}
            group={group}
            onSave={onSaveGroup}
          />
        ))}
      </div>
    </motion.div>
  );
});

// Update the Framework component to pass onSaveGroup
const Framework = memo(({ framework, isDarkMode, index, onSaveIdea }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  
  const stats = {
    totalIdeas: framework.ideaGroups.reduce((acc, group) => acc + group.ideas.length, 0),
    seriesCount: framework.ideaGroups.length
  };

  // Format the framework name to be more descriptive
  const getFormattedFrameworkName = (name) => {
    const cleanName = name.replace('FRAMEWORK', '').trim();
    switch (cleanName.toLowerCase()) {
      case 'actionable':
        return 'Action oriented ideas';
      case 'aspirational':
        return 'Strategic perspective ideas';
      case 'anthropological':
        return 'Human emotion-driven ideas';
      case 'analytical':
        return 'Data-driven ideas';
      default:
        return cleanName;
    }
  };

  // Format the series name to be more descriptive
  const getFormattedSeriesName = (name) => {
    const seriesMap = {
      'tips': 'Quick implementation ideas',
      'hacks': 'Smart solutions & strategies',
      'ultimate guides': 'Comprehensive guides',
      'lessons': 'Key lessons',
      'mistakes': 'Common mistakes',
      'reflections': 'Strategic insights',
      'getting started': 'Quick start guide',
      'fears': 'Emotional challenges',
      'challenges': 'Implementation challenges',
      'misconceptions': 'Common misconceptions',
      'trends': 'Industry trends',
      'impact': 'Impact analysis',
      'methodology': 'Methodology breakdown'
    };
    
    const cleanName = name.toLowerCase().replace(' series', '').trim();
    return seriesMap[cleanName] || name;
  };

  const frameworkName = getFormattedFrameworkName(framework.frameworkName);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4, delay: index * 0.1 }}
      className={`rounded-xl ${
        isDarkMode 
          ? 'bg-[#151515]' 
          : 'bg-white'
      }`}
    >
      {/* Framework Header */}
      <div className={`px-6 py-4 border-b ${
        isDarkMode ? 'border-[#333333]' : 'border-slate-200'
      }`}>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <div className={`flex items-center justify-center w-10 h-10 rounded-lg font-medium ${
              isDarkMode 
                ? 'bg-slate-800/50 text-slate-300' 
                : 'bg-slate-100 text-slate-600'
            }`}>
              {index + 1}
            </div>
            <div>
              <h3 className={`text-base font-medium ${
                isDarkMode ? 'text-slate-200' : 'text-slate-700'
              }`}>
                {frameworkName}
              </h3>
              <p className={`text-sm mt-0.5 ${
                isDarkMode ? 'text-slate-400' : 'text-slate-500'
              }`}>
                {stats.seriesCount} series · {stats.totalIdeas} ideas
              </p>
            </div>
          </div>
          
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className={`p-2 rounded-lg transition-colors ${
              isDarkMode 
                ? 'hover:bg-slate-800/50 text-slate-400' 
                : 'hover:bg-slate-100 text-slate-500'
            }`}
          >
            {isExpanded ? (
              <MinusSmallIcon className="w-5 h-5" />
            ) : (
              <PlusSmallIcon className="w-5 h-5" />
            )}
          </button>
        </div>
      </div>

      {/* Framework Content */}
      <AnimatePresence>
        {isExpanded && (
          <motion.div 
            {...expandAnimation} 
            className="p-6 grid grid-cols-1 md:grid-cols-2 gap-6"
          >
            {framework.ideaGroups.map((group, groupIndex) => (
              <IdeaGroup
                key={groupIndex}
                group={{
                  ...group,
                  groupName: getFormattedSeriesName(group.groupName)
                }}
                framework={framework}
                isDarkMode={isDarkMode}
                onSaveGroup={onSaveIdea}
              />
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
});

// Update the ActionBar component - remove SparklesIcon from the return JSX
const ActionBar = memo(({ ideas, isDarkMode, selectedPersona }) => {
  const [copyState, setCopyState] = useState('idle');
  const [downloadingPDF, setDownloadingPDF] = useState(false);

  // Add null checks for ideas data
  const stats = useMemo(() => {
    if (!ideas?.data?.frameworks) {
      return {
        frameworks: 0,
        series: 0,
        totalIdeas: 0
      };
    }

    return {
      frameworks: ideas.data.frameworks.length,
      series: ideas.data.frameworks.reduce((acc, framework) => 
        acc + framework.ideaGroups.length, 0
      ),
      totalIdeas: ideas.data.frameworks.reduce((acc, framework) => 
        acc + framework.ideaGroups.reduce((sum, group) => 
          sum + group.ideas.length, 0
        ), 0
      ),
    };
  }, [ideas]);

  const copyAllIdeas = async () => {
    if (copyState !== 'idle' || !ideas?.data?.frameworks) return;
    
    setCopyState('copying');
    try {
      const formattedIdeas = ideas.data.frameworks
        .map(framework => {
          return `${framework.frameworkName}\n\n${framework.ideaGroups
            .map(group => {
              return `${group.groupName}:\n${group.ideas
                .map(idea => {
                  return `• ${idea.content}${idea.details ? `\n  ${idea.details}` : ''}${
                    idea.targetOutcome ? `\n  Target Outcome: ${idea.targetOutcome}` : ''
                  }`
                })
                .join('\n\n')}`
            })
            .join('\n\n')}`
        })
        .join('\n\n---\n\n');

      await navigator.clipboard.writeText(formattedIdeas);
      setCopyState('copied');
      toast.success('All ideas copied to clipboard');
      
      setTimeout(() => {
        setCopyState('idle');
      }, 3500);
    } catch (error) {
      console.error('Copy error:', error);
      toast.error('Failed to copy ideas');
      setCopyState('idle');
    }
  };

  const downloadPDF = () => {
    setDownloadingPDF(true);
    try {
      const pdfDownloader = new ContentStrategyPDFDownloader(ideas, selectedPersona);
      pdfDownloader.generatePDF();
      toast.success('PDF downloaded successfully');
    } catch (error) {
      console.error('PDF generation error:', error);
      toast.error('Failed to generate PDF');
    } finally {
      setDownloadingPDF(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className={`sticky top-0 z-10 mb-4 rounded-lg ring-1 backdrop-blur-lg ${
        isDarkMode 
          ? 'bg-[#151515]/90 ring-[#333333]' 
          : 'bg-blue-50/90 ring-blue-100'
      }`}
    >
      <div className="px-4 py-3">
        <div className="flex items-center justify-between">
          <span className={`text-sm ${
            isDarkMode ? 'text-blue-400' : 'text-blue-500'
          }`}>
            <span className={`font-medium ${
              isDarkMode ? 'text-blue-100' : 'text-blue-900'
            }`}>{stats.totalIdeas} ideas</span>
            {selectedPersona?.name && (
              <>
                <span> generated for </span>
                <span className={`font-medium ${
                  isDarkMode ? 'text-blue-100' : 'text-blue-900'
                }`}>{selectedPersona.name}</span>
              </>
            )}
          </span>

          <div className="flex items-center gap-2">
            <button
              onClick={copyAllIdeas}
              disabled={copyState !== 'idle'}
              className={`flex items-center gap-1.5 px-2.5 py-1 rounded-md text-xs transition-all duration-200 ${
                copyState === 'copied'
                  ? isDarkMode
                    ? 'bg-green-500/20 text-green-400 hover:bg-green-500/30'
                    : 'bg-green-50 text-green-600 hover:bg-green-100'
                  : isDarkMode
                  ? 'bg-[#1a1a1a] text-slate-200 hover:bg-[#202020] border border-[#333333]'
                  : 'hover:bg-blue-100/80 text-blue-700'
              } ${
                copyState === 'copying' ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {copyState === 'copied' ? (
                <>
                  <CheckIcon className="w-3.5 h-3.5 animate-scale-check" />
                  <span>Copied!</span>
                </>
              ) : copyState === 'copying' ? (
                <>
                  <DocumentDuplicateIcon className="w-3.5 h-3.5 animate-pulse" />
                  <span>Copying...</span>
                </>
              ) : (
                <>
                  <DocumentDuplicateIcon className="w-3.5 h-3.5" />
                  <span>Copy All</span>
                </>
              )}
            </button>
            
            <button
              onClick={downloadPDF}
              disabled={downloadingPDF}
              className={`flex items-center gap-1.5 px-2.5 py-1 rounded-md text-xs transition-colors ${
                downloadingPDF
                  ? 'opacity-50 cursor-not-allowed'
                  : isDarkMode
                  ? 'bg-[#1a1a1a] text-slate-200 hover:bg-[#202020] border border-[#333333]'
                  : 'hover:bg-blue-100/80 text-blue-700'
              }`}
            >
              <ArrowDownTrayIcon className={`w-3.5 h-3.5 ${downloadingPDF ? 'animate-pulse' : ''}`} />
              <span>{downloadingPDF ? 'Downloading...' : 'PDF'}</span>
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
});

// Add this CSS at the top of your file or in your global styles
const styles = `
  @keyframes scale-check {
    0% { transform: scale(1); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1); }
  }

  .animate-scale-check {
    animation: scale-check 0.3s ease-in-out;
  }
`;

// Add the styles to the document
if (!document.getElementById('generated-ideas-styles')) {
  const styleSheet = document.createElement('style');
  styleSheet.id = 'generated-ideas-styles';
  styleSheet.textContent = styles;
  document.head.appendChild(styleSheet);
}

// Update the FloatingFolderBar component's positioning
const FloatingFolderBar = ({ isDarkMode, onClose, onSelectFolder, folders, savedIdea }) => {
  const [isSelectingFolder, setIsSelectingFolder] = useState(false);

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      className="absolute inset-x-0 bottom-8 z-[100] flex justify-center px-4"
    >
      <div className={`max-w-xl w-full flex items-center gap-3 px-6 py-4 rounded-xl shadow-lg backdrop-blur-lg ${
        isDarkMode 
          ? 'bg-[#121212]/95 ring-1 ring-[#333333] shadow-black/20' 
          : 'bg-white/95 ring-1 ring-slate-200 shadow-black/5'
      }`}>
        {!isSelectingFolder ? (
          <>
            <div className={`flex items-center gap-2 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-700'
            }`}>
              <CheckIcon className="w-4 h-4" />
              <span className="text-sm">
                {Array.isArray(savedIdea) 
                  ? `${savedIdea.length} ideas saved` 
                  : 'Idea saved'} successfully
              </span>
            </div>
            <div className="flex-1" />
            <button
              onClick={() => setIsSelectingFolder(true)}
              className={`px-3 py-1.5 text-sm rounded-md transition-colors ${
                isDarkMode
                  ? 'bg-blue-500/20 text-blue-300 hover:bg-blue-500/30'
                  : 'bg-blue-50 text-blue-600 hover:bg-blue-100'
              }`}
            >
              Add to folder
            </button>
            <button
              onClick={onClose}
              className={`p-1.5 rounded-md transition-colors ${
                isDarkMode
                  ? 'text-gray-400 hover:bg-gray-700'
                  : 'text-gray-500 hover:bg-gray-100'
              }`}
            >
              <XMarkIcon className="w-4 h-4" />
            </button>
          </>
        ) : (
          <>
            <div className="flex items-center gap-3 flex-1">
              <FolderIcon className={`w-4 h-4 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
              <div className="flex flex-wrap gap-2">
                {folders.map((folder) => (
                  <button
                    key={folder._id}
                    onClick={() => {
                      onSelectFolder(folder);
                      setIsSelectingFolder(false);
                    }}
                    className={`px-3 py-1.5 text-sm rounded-md transition-colors ${
                      isDarkMode
                        ? 'bg-[#1e1e1e] text-slate-300 hover:bg-[#1a1a1a] hover:ring-1 hover:ring-[#333333]'
                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200 hover:ring-1 hover:ring-gray-300'
                    }`}
                  >
                    {folder.name}
                  </button>
                ))}
              </div>
            </div>
            <button
              onClick={() => setIsSelectingFolder(false)}
              className={`p-1.5 rounded-md transition-colors ${
                isDarkMode
                  ? 'text-gray-400 hover:bg-gray-700'
                  : 'text-gray-500 hover:bg-gray-100'
              }`}
            >
              <XMarkIcon className="w-4 h-4" />
            </button>
          </>
        )}
      </div>
    </motion.div>
  );
};

// Main Component
const GeneratedIdeas = memo(({ ideas, loading, selectedPersona }) => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const [folders, setFolders] = useState([]);
  const [savedIdea, setSavedIdea] = useState(null);

  // Load folders when component mounts
  useEffect(() => {
    const loadFolders = async () => {
      try {
        const response = await getClientFolders();
        setFolders(response.data || response || []);
      } catch (error) {
        console.error('Error loading folders:', error);
        toast.error('Failed to load folders');
      }
    };
    loadFolders();
  }, []);

  const handleAddToFolder = async (folderId) => {
    try {
      if (Array.isArray(savedIdea)) {
        await Promise.all(savedIdea.map(idea => 
          moveIdeaToFolder(idea._id || idea.id, folderId)
        ));
        toast.success('Ideas added to folder successfully');
      } else {
        await moveIdeaToFolder(savedIdea._id || savedIdea.id, folderId);
        toast.success('Idea added to folder successfully');
      }
    } catch (error) {
      console.error('Error adding to folder:', error);
      toast.error('Failed to add to folder');
    } finally {
      setSavedIdea(null);
    }
  };

  useEffect(() => {
    if (!loading && ideas) {
      if (!ideas.data?.frameworks || !Array.isArray(ideas.data.frameworks)) {
        console.error('Invalid ideas data structure:', ideas);
        toast.error('Failed to load content ideas');
        return;
      }

      contentStrategyProgressService.updateProgress('generatedIdeas', { data: ideas });
      toast.success('Content strategy generated successfully!');
    }
  }, [loading, ideas, navigate]);

  const handleSaveIdea = async (savedIdeaResponse) => {
    try {
      // Handle both single idea and group saves
      if (Array.isArray(savedIdeaResponse)) {
        // Group save
        const savedIdeas = savedIdeaResponse.map(response => response.data || response);
        setSavedIdea(savedIdeas);
      } else {
        // Single idea save
        setSavedIdea(savedIdeaResponse);
      }
    } catch (error) {
      console.error('Error handling saved idea:', error);
      toast.error('Failed to process saved idea');
    }
  };

  if (loading) {
    return <LoadingSkeleton isDarkMode={isDarkMode} selectedPersona={selectedPersona} />;
  }

  if (!ideas?.data?.frameworks?.length) {
    return (
      <div className="text-center py-12">
        <p className={isDarkMode ? 'text-slate-400' : 'text-slate-600'}>
          No content generated. Please try again.
        </p>
      </div>
    );
  }

  return (
    <div className="relative min-h-screen pb-24">
      <motion.div
        variants={pageVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        className="w-full max-w-4xl mx-auto space-y-4 p-4"
      >
        <ActionBar 
          ideas={ideas}
          isDarkMode={isDarkMode}
          selectedPersona={selectedPersona}
        />
        
        {ideas.data.frameworks.map((framework, index) => (
          <Framework
            key={index}
            framework={framework}
            isDarkMode={isDarkMode}
            index={index}
            onSaveIdea={handleSaveIdea}
          />
        ))}
      </motion.div>

      <AnimatePresence>
        {savedIdea && (
          <div className="sticky bottom-8 w-full">
            <FloatingFolderBar
              isDarkMode={isDarkMode}
              onClose={() => setSavedIdea(null)}
              onSelectFolder={(folder) => handleAddToFolder(folder._id)}
              folders={folders}
              savedIdea={savedIdea}
            />
          </div>
        )}
      </AnimatePresence>
    </div>
  );
});

export default GeneratedIdeas; 