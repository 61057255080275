import React from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../../context/ThemeContext';

const AddToChat = ({ onClick, disabled, selectedCount, className = '' }) => {
  const { isDarkMode } = useTheme();

  return (
    <div className={`flex items-center justify-between ${className}`}>
      <span className={`text-xs ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
        {selectedCount} selected
      </span>
      <button
        onClick={onClick}
        className={`
          px-3 py-1.5 rounded-md text-xs font-medium
          transition-colors duration-200 flex items-center gap-1.5
          ${!disabled
            ? isDarkMode
              ? 'bg-[#1e1e1e] hover:bg-[#2a2a2a] text-blue-400 border border-[#333333]'
              : 'bg-black hover:bg-gray-900 text-white border border-black'
            : isDarkMode
              ? 'bg-[#1a1a1a] text-slate-400'
              : 'bg-gray-100 text-gray-400 border border-gray-200'
          }
        `}
        disabled={disabled}
      >
        <CheckIcon className="h-3.5 w-3.5" />
        Add to Chat
      </button>
    </div>
  );
};

export default AddToChat; 