import React, { useState, useEffect, useCallback } from 'react'
import { motion } from 'framer-motion'
import { useTheme } from '../context/ThemeContext'

const TypingAnimation = ({ 
  selectedPersona, 
  selectedTargetAudience,
  selectedTemplate,
  selectedVoiceNotes,
  selectedTextNotes,
  selectedAgent,
  onComplete
}) => {
  const { isDarkMode } = useTheme();
  const [messageIndex, setMessageIndex] = useState(0);
  const [isCompleting, setIsCompleting] = useState(false);
  const MESSAGE_DURATION = 1000; // Each message shows for 1 second minimum
  const MINIMUM_TOTAL_DURATION = 3000; // Minimum 3 seconds total animation
  
  const loadingMessages = [
    "Analysing your message...",
    selectedPersona ? "Considering profile info..." : null,
    selectedTargetAudience ? "Adapting for target audience..." : null,
    selectedTemplate ? "Applying template structure..." : null,
    selectedVoiceNotes?.length > 0 ? "Processing voice notes..." : null,
    selectedTextNotes?.length > 0 ? "Incorporating text notes..." : null,
    selectedAgent ? `Engaging ${selectedAgent.name}...` : null,
    "Generating response..."
  ].filter(Boolean);

  const completeAnimation = useCallback(() => {
    if (!isCompleting) {
      setIsCompleting(true);
      // Add a small delay before completing to ensure smooth transition
      setTimeout(() => {
        if (onComplete) {
          onComplete();
        }
      }, 300);
    }
  }, [onComplete, isCompleting]);

  useEffect(() => {
    const startTime = Date.now();
    let timeoutId;

    const showNextMessage = () => {
      if (messageIndex < loadingMessages.length - 1) {
        setMessageIndex(prev => prev + 1);
        timeoutId = setTimeout(showNextMessage, MESSAGE_DURATION);
      } else {
        const elapsedTime = Date.now() - startTime;
        const remainingTime = Math.max(MINIMUM_TOTAL_DURATION - elapsedTime, 0);
        
        // Ensure we show the last message for at least a second
        timeoutId = setTimeout(completeAnimation, Math.max(remainingTime, 1000));
      }
    };

    timeoutId = setTimeout(showNextMessage, MESSAGE_DURATION);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [messageIndex, loadingMessages.length, completeAnimation]);

  return (
    <div className={`w-full max-w-2xl mx-auto p-4 rounded-lg border ${
      isDarkMode 
        ? 'bg-[#121212]/95 border-[#333333] text-slate-200' 
        : 'bg-white border-gray-200'
    } transition-all duration-200`}>
      <div className="flex items-center space-x-2">
        <motion.div
          className={`h-2 w-2 rounded-full ${
            isDarkMode 
              ? 'bg-blue-400/80' 
              : 'bg-blue-600'
          }`}
          animate={{
            scale: [1, 1.5, 1],
            opacity: [1, 0.5, 1]
          }}
          transition={{
            duration: 1,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        />
        <span className={`text-xs ${
          isDarkMode 
            ? 'text-slate-400' 
            : 'text-gray-600'
        }`}>
          {loadingMessages[messageIndex]}
        </span>
      </div>
    </div>
  );
};

export default TypingAnimation;