import React, { useState, useMemo } from 'react';
import { ChevronRightIcon, FolderIcon } from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';

const TreeDropdown = ({ folders, onSelect, currentItemId, isDarkMode }) => {
  console.log('TreeDropdown rendered with folders:', folders);
  
  const TreeNode = ({ node, onSelect, level = 0, currentItemId, parentId }) => {
    console.log('Rendering TreeNode:', node);
    const [isExpanded, setIsExpanded] = useState(level === 0);

    const handleToggle = (e) => {
      e.stopPropagation();
      setIsExpanded(!isExpanded);
    };

    const handleSelect = (e) => {
      e.stopPropagation();
      console.log('Folder selected:', node._id);
      onSelect(node._id);
    };

    if (node._id === currentItemId || node._id === parentId) {
      return null;
    }

    const hasChildren = node.children && node.children.length > 0;
    const isCurrentLocation = node._id === folders.find(f => f._id === currentItemId)?.parentId;

    return (
      <div>
        <div 
          className={`flex items-center px-3 py-2 text-sm ${
            isDarkMode
              ? 'text-slate-200 hover:bg-[#1e1e1e] hover:text-slate-200'
              : 'text-gray-700 hover:bg-gray-100 hover:text-gray-900'
          } cursor-pointer ${isCurrentLocation ? (isDarkMode ? 'bg-[#1e1e1e]' : 'bg-blue-100') : ''}`}
          style={{ paddingLeft: `${level * 12 + 12}px` }}
          onClick={handleSelect}
        >
          {hasChildren && (
            <button onClick={handleToggle} className="mr-2 focus:outline-none">
              <motion.div
                initial={false}
                animate={{ rotate: isExpanded ? 90 : 0 }}
                transition={{ duration: 0.2 }}
              >
                <ChevronRightIcon className={`h-3 w-3 ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`} />
              </motion.div>
            </button>
          )}
          <FolderIcon className={`h-4 w-4 mr-2 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
          <span className="truncate">{node.name}</span>
          {isCurrentLocation && <span className={`ml-2 text-xs ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`}>(Current Location)</span>}
        </div>
        <AnimatePresence>
          {isExpanded && hasChildren && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.2 }}
            >
              {node.children.map(childNode => (
                <TreeNode
                  key={childNode._id}
                  node={childNode}
                  onSelect={onSelect}
                  level={level + 1}
                  currentItemId={currentItemId}
                  parentId={parentId}
                />
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  };

  const folderTree = useMemo(() => {
    const folderMap = new Map(folders.map(folder => [folder._id, { ...folder, children: [] }]));
    const rootFolders = [];

    folders.forEach(folder => {
      if (folder.parentId && folderMap.has(folder.parentId)) {
        const parent = folderMap.get(folder.parentId);
        parent.children.push(folderMap.get(folder._id));
      } else {
        rootFolders.push(folderMap.get(folder._id));
      }
    });

    const homeFolder = {
      _id: 'home',
      name: 'Home',
      children: rootFolders
    };

    console.log('Generated folderTree:', [homeFolder]);
    return [homeFolder];
  }, [folders]);

  const currentFolder = folders.find(folder => folder._id === currentItemId);
  const parentId = currentFolder ? currentFolder.parentId : null;

  return (
    <div className={`${
      isDarkMode ? 'bg-[#121212] border border-[#333333]' : 'bg-white'
    } rounded-md shadow-lg ring-1 ring-black ring-opacity-5 w-56 max-h-[300px] overflow-y-auto`}>
      {folderTree.map((folder) => (
        <TreeNode 
          key={folder._id}
          node={folder} 
          onSelect={onSelect} 
          currentItemId={currentItemId}
          parentId={parentId}
        />
      ))}
    </div>
  );
};

export default TreeDropdown;
